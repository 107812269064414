import React from "react"
import { Field, FormSection } from "redux-form"
import { Grid } from "@material-ui/core"
import FormCheckbox from "../../form/form-checkbox"
import { FormattedMessage } from "react-intl"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
    mainContainer: {
        width: 400,
        height: "100%",
    },
    titleContainer: {
        padding: 10,
        color: "white",
        height: 64,
    },
    filtersContainer: {
        height: "calc(100% - 110px)",
        overflowY: "scroll",
    },
    buttonContainer: {
        height: "46px",
        padding: "5px",
        borderTop: "Solid 2px #D4D4D4",
    },
    panelSummary: {
        "&:hover": {
            backgroundColor: "#D4D4D4",
        },
        width: "100%",
        paddingTop: 5,
        paddingTBottom: 5,
    },
    expansionPanelRoot: {
        width: "100%",
    },
    badgePadding: {
        paddingRight: "15px !important",
    },
})

const ArchiveFilter = ({ gaCategory }) => {
    const classes = useStyles()
    return (
        <Grid item container>
            <FormSection name="archiveFilter" className={classes.mainContainer}>
                <Grid
                    item
                    container
                    alignItems="center"
                    className={classes.filterRow}
                >
                    <Grid item container>
                        <Field
                            name="viewArchivedShipments"
                            label={
                                <FormattedMessage
                                    id="dashboard.filters.viewShipments"
                                    defaultMessage="Include Archived Shipments"
                                />
                            }
                            component={FormCheckbox}
                            category={gaCategory}
                        />
                        <Field
                            name="viewArchivedQuotes"
                            label={
                                <FormattedMessage
                                    id="dashboard.filters.viewQuotes"
                                    defaultMessage="Include Archived Quotes"
                                />
                            }
                            component={FormCheckbox}
                            category={gaCategory}
                        />
                    </Grid>
                </Grid>
            </FormSection>
        </Grid>
    )
}

export default ArchiveFilter

import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    setChannel,
    onChatViewChange,
    markRead,
    getChannelStatuses,
} from "../../actions/chat"

export class ChatSelectorPresentation extends React.Component {
    componentDidMount() {
        this.props.selectChat(this.props.shipmentId)
    }

    componentWillReceiveProps(newProps) {
        if (newProps.shipmentId !== this.props.shipmentId) {
            this.props.selectChat(newProps.shipmentId)
        }
    }

    render() {
        return null
    }
}

ChatSelectorPresentation.propTypes = {
    shipmentId: PropTypes.string,
    selectChat: PropTypes.func.isRequired,
}

ChatSelectorPresentation.defaultProps = {
    shipmentId: null,
}

const mapDispatchToProps = dispatch => ({
    selectChat: channel => {
        dispatch(setChannel(channel))
        dispatch(onChatViewChange(channel ? "chatStream" : "home"))
        if (channel) {
            dispatch(getChannelStatuses("DM"))
            dispatch(markRead(channel))
        }
    },
})

export const ChatSelector = connect(
    undefined,
    mapDispatchToProps
)(ChatSelectorPresentation)

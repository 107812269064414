import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import { logger } from "redux-logger"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { rootReducer } from "./reducers"

const middlewares = [thunk]
let composeEnhancers = compose
if (window._env_.REACT_APP_ENABLE_LOGGING === "enabled") {
    middlewares.push(logger)
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?? compose
}

const rootPersistConfig = {
    key: "root",
    storage,
    whitelist: [],
}

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export function configureStore(preloadedState) {
    const store = createStore(
        persistedReducer,
        preloadedState,
        composeEnhancers(applyMiddleware(...middlewares))
    )
    const persistor = persistStore(store)
    return { store, persistor }
}

import React from "react"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
    container: {
        padding: "5px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        padding: "10px",
    },
})

const AlertDialog = ({
    open,
    onCloseDialog,
    onContinue,
    dialogTitle,
    dialogContentText,
    closeDialogButtonText,
    confirmButtonText,
}) => {
    const classes = useStyles()
    return (
        <Dialog
            className={classes.container}
            open={open}
            onClose={onCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{dialogContentText}</DialogContentText>
            </DialogContent>

            <DialogActions className={classes.buttonContainer}>
                <Button
                    color="secondary"
                    onClick={onCloseDialog}
                    autoFocus
                    variant="outlined"
                >
                    {closeDialogButtonText}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={onContinue}
                >
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertDialog

export const styles = theme => ({
    location__container: {
        height: "85vh",
    },
    leftNav__container: {
        height: "88vh",
        paddingTop: "1%",
        paddingBottom: "1%",
    },
    leftNav__paper: {
        width: "100%",
        height: "100%",
        paddingTop: "10px",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "nowrap",
        boxSizing: "border-box",
        flexDirection: "column",
    },
    leftNav__locations: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "nowrap",
        boxSizing: "border-box",
        flexDirection: "column",
        overflow: "scroll",
    },
    leftNav__title: {
        padding: "10px 10px",
    },
    leftNav__location: {
        padding: "10px 10px",
        "&:hover": {
            cursor: "pointer",
        },
        "&:active": {
            backgroundColor: "#D4D4D4",
            cursor: "pointer",
        },
    },
    leftNav__location__active: {
        padding: "10px 10px",
        backgroundColor: "#F2F2F2",
        zIndex: 2,
    },
    right__content: {
        padding: "3px 20px 20px 20px",
        zIndex: 4,
    },
    shadow__hider: {
        width: "400px",
    },
    location__name: {
        fontWeight: 1000,
    },
    default__tag: {
        paddingLeft: "5px",
    },
})

import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import {
    FormGroup,
    FormControlLabel,
    Switch,
    Checkbox,
} from "@material-ui/core"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const FormCheckbox = ({
    input,
    label,
    meta: { touched, error },
    enableSwitch,
    onChange,
    FormLabelProps,
    CheckboxProps,
    dataTestId,
    category,
    ...rest
}) => {
    const { logGAEvent } = useGAContext()
    const Control = enableSwitch ? Switch : Checkbox
    const ControlType = enableSwitch ? "Switch" : "Checkbox"
    return (
        <div
            className={classnames({
                "checkbox-field": true,
                "form-field-touched": touched,
                "form-field-invalid": touched && error,
                "form-field-valid": !error,
            })}
        >
            <FormGroup row>
                <FormControlLabel
                    {...FormLabelProps}
                    control={
                        <Control
                            id={input.name}
                            inputProps={{ dataTestId }}
                            {...CheckboxProps}
                            checked={input.value}
                            {...input}
                            {...rest}
                            value={label}
                            onChange={e => {
                                logGAEvent(
                                    category ?? "Unknown",
                                    `Update Field ${ControlType} - ${e.target.checked}`,
                                    label?.props?.defaultMessage ?? input.name
                                )
                                input.onChange(e)
                                onChange(e)
                            }}
                        />
                    }
                    id={`${input.name}-label`}
                    label={label}
                />
            </FormGroup>
        </div>
    )
}

FormCheckbox.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    meta: PropTypes.shape({
        // eslint-disable-next-line react/no-unused-prop-types
        touched: PropTypes.bool,
        // eslint-disable-next-line react/no-unused-prop-types
        error: PropTypes.string,
    }),
    enableSwitch: PropTypes.bool,
    onChange: PropTypes.func,
}

FormCheckbox.defaultProps = {
    meta: {},
    label: undefined,
    input: undefined,
    enableSwitch: false,
    onChange: () => {},
}

export default FormCheckbox

import React, { Fragment } from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import { Field, formValues } from "redux-form"
import FormSelect from "../../form/form-select"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import GlobalSpinner from "../../common/GlobalSpinner"
import { attachmentOptions } from "../../../misc"
import { FormattedMessage, defineMessages } from "react-intl"
import FormField from "../../form/form-field"
import { withStyles } from "@material-ui/core/styles"
import {
    ShipmentOverwriteCheck,
    shipmentOverwriteCheckDocumentTypes,
} from "../checks/ShipmentOverwriteCheck"
import { ShipmentOnlyCheck } from "../checks/ShipmentOnlyCheck"

const profileFilenameCollisionCheckDocumentTypes = Object.freeze(["USMCA"])

const styles = theme => ({
    dialog__content: {
        padding: "10px",
    },
    dialog__section: {
        padding: "10px",
    },
    dialog__fileIcon: {
        marginLeft: "5px",
    },
    dialog__caption: {
        paddingLeft: "3px",
    },
})

export const documentFormMessages = defineMessages({
    documentCategory: {
        id: "documents.upload__selectFileCategory",
        defaultMessage: "File Category",
    },
    userFileName: {
        id: "documents.upload__userFileName",
        defaultMessage: "Custom File Name",
    },
})

export const UploadSuccessDialog = withStyles(styles)(
    formValues(
        "documentCategory",
        "customFilename",
        "shipmentOnly"
    )(
        ({
            shipmentId,
            onClose,
            stagedFile,
            classes,
            loading,
            documentCategory,
            attachmentOptionList = attachmentOptions,
            gaCategory,
        }) => {
            return (
                <Fragment>
                    {loading && <GlobalSpinner />}
                    <DialogTitle id="alert-dialog-title">
                        <Grid item container>
                            <FormattedMessage
                                id="documents.upload__title"
                                defaultMessage="Attach File"
                            />
                            <AttachFileIcon
                                fontSize="large"
                                className={classes.dialog__fileIcon}
                            />
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Grid
                            item
                            container
                            alignContent="flex-start"
                            justify="center"
                            className={classes.dialog__content}
                        >
                            <Grid
                                item
                                container
                                xs={12}
                                justify="center"
                                className={classes.dialog__section}
                            >
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    className={classes.dialog__caption}
                                >
                                    <Typography
                                        variant="body2"
                                        align="center"
                                        gutterBottom
                                    >
                                        {stagedFile.originalFileName}
                                    </Typography>
                                </Grid>
                                <Field
                                    name="documentCategory"
                                    label={
                                        <FormattedMessage
                                            {...documentFormMessages.documentCategory}
                                        />
                                    }
                                    component={FormSelect}
                                    options={attachmentOptionList}
                                    required
                                    category={gaCategory}
                                />
                                <Field
                                    name="customFilename"
                                    label={
                                        <FormattedMessage
                                            {...documentFormMessages.userFileName}
                                        />
                                    }
                                    component={FormField}
                                    required
                                    category={gaCategory}
                                />
                                {shipmentOverwriteCheckDocumentTypes.includes(
                                    documentCategory
                                ) && (
                                    <ShipmentOverwriteCheck
                                        gaCategory={gaCategory}
                                    />
                                )}
                                {shipmentId &&
                                    profileFilenameCollisionCheckDocumentTypes.includes(
                                        documentCategory
                                    ) && (
                                        <ShipmentOnlyCheck
                                            gaCategory={gaCategory}
                                        />
                                    )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={onClose}
                        >
                            <FormattedMessage
                                id="generalTerms__cancel"
                                defaultMessage="Cancel"
                            />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                        >
                            <FormattedMessage
                                id="generalTerms__save"
                                defaultMessage="Save"
                            />
                        </Button>
                    </DialogActions>
                </Fragment>
            )
        }
    )
)

import React from "react"
import AdminPage from "./../components"

const title = "Assembly"
const urlPath = `assembly`
const sampleFile = "import_assembly_discounts_sample.xlsx"

const tableHeaders = [
    {
        id: "accountNumber",
        type: "text",
        align: "left",
        label: "Account Number",
    },
    {
        id: "accountType",
        type: "text",
        align: "left",
        label: "Account Type",
    },
    {
        id: "assemblyFee",
        altId: "assemblyDiscount",
        type: "$",
        altType: "%",
        align: "right",
        label: "Assembly Fee/Discount",
    },
    {
        id: "listPrice",
        type: "$",
        align: "right",
        label: "List Price",
    },
    {
        id: "expirationDate",
        type: "date",
        align: "right",
        label: "Pricing Expiration Date",
    },
]

const AssemblyAdmin = () => {
    return (
        <>
            <AdminPage
                title={title}
                urlPath={urlPath}
                sampleFile={sampleFile}
                tableHeaders={tableHeaders}
            />
        </>
    )
}

export default AssemblyAdmin

import React from "react"
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button"

class FileUpload extends React.Component {
    constructor(props) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this)
    }
    onSubmit(e) {
        e.preventDefault()
        const file = e.target.files[0]
        this.props.onSubmit(file)
    }
    render() {
        const { labelText, accept } = this.props
        return (
            <form onSubmit={this.onSubmit}>
                <input
                    id="file"
                    type="file"
                    accept={accept}
                    onChange={this.onSubmit}
                    onClick={event => {
                        if (this.props.allowSameFile) event.target.value = null
                    }}
                    style={{
                        width: 0,
                        height: 0,
                        opacity: 0,
                        overflow: "hidden",
                        position: "absolute",
                        zIndex: 1,
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    component="label"
                    htmlFor="file"
                >
                    {labelText}
                </Button>
            </form>
        )
    }
}

FileUpload.propTypes = {
    labelText: PropTypes.string,
    accept: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
}

FileUpload.defaultProps = {
    labelText: "Choose File",
    accept: "",
}

export default FileUpload

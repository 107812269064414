import React, { useEffect } from "react"
import { Box, Button, MuiThemeProvider, Typography } from "@material-ui/core"
import { theme } from "../../app"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"
import { FormattedMessage } from "react-intl"
import history from "../../actions/history"

const ErrorRedirectScreen = ({ errorMessage }) => {
    const { logGAEvent } = useGAContext()
    useEffect(() => {
        logGAEvent("Error", errorMessage)
    })
    return (
        <MuiThemeProvider theme={theme}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90vh",
                }}
            >
                <Box
                    sx={{
                        maxWidth: "700px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography style={{ paddingBottom: "20px" }} variant="h6">
                        <FormattedMessage
                            defaultMessage="Oops! Something went wrong. We're sorry for the
                        inconvenience. Our team has been notified, and we're
                        working to fix the issue. Please try again later."
                            id="error.message"
                        />
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            history.push("/home")
                            window.location.reload()
                        }}
                    >
                        Go To Homescreen
                    </Button>
                </Box>
            </Box>
        </MuiThemeProvider>
    )
}

export default ErrorRedirectScreen

import React, { Fragment } from "react"
import Item from "./Item"

export const BookItems = ({ category, fields, ...props }) => (
    <Fragment>
        {fields.map((item, index) => (
            <Item
                category={category}
                {...props}
                key={index}
                itemIndex={index}
                prefix={item}
            />
        ))}
    </Fragment>
)

import React from "react"
import { get } from "lodash"
import { Grid, withStyles, Typography, Button, Modal } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    modal__container: {
        top: "50%",
        left: "50%",
        maxHeight: "90%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
        width: "900px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        overflowY: "auto",
    },
    title__container: {
        paddingBottom: "40px",
    },
})

const RequestAccess = ({
    classes,
    open,
    data,
    handleClose,
    handleRequestAccess,
}) => (
    <Modal open={open} onClose={handleClose}>
        <Grid container className={classes.modal__container} justify="center">
            <Grid
                item
                container
                justify="center"
                className={classes.title__container}
            >
                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="locations.requestAccess__alreadyExists"
                        defaultMessage="The location you entered already exists in our system, and it is owned by {name} ({email}). Would you like to request access to it?"
                        values={{
                            name: get(data, "name", "somebody else"),
                            email: get(data, "email", ""),
                        }}
                    />
                </Typography>
            </Grid>
            <Grid item container justify="space-between">
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleClose()}
                >
                    <FormattedMessage
                        id="generalTerms__cancel"
                        defaultMessage="Cancel"
                    />
                </Button>
                <Button
                    id="requestAccess"
                    variant="contained"
                    color="primary"
                    onClick={() => handleRequestAccess()}
                >
                    <FormattedMessage
                        id="locations.requestAccess__requestAccess"
                        defaultMessage="Request Access"
                    />
                </Button>
            </Grid>
        </Grid>
    </Modal>
)

export default withStyles(styles)(RequestAccess)

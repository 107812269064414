import React, { useEffect, useState } from "react"
import {
    Checkbox,
    Grid,
    FormControl,
    Input,
    InputLabel,
    FormHelperText,
    FormControlLabel,
    Typography,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"

const RateField = ({
    index,
    defaultValue,
    label,
    initialValue,
    setCurrentFormValues,
    currentFormValues,
    fieldRef,
    checkEditStatus,
    showRateCheckbox,
}) => {
    const [checked, setChecked] = useState(false)
    const [input, setInput] = useState("")
    const [error, setError] = useState("")

    useEffect(() => {
        if (initialValue?.isDisabled) {
            setChecked(true)
        }

        if (initialValue?.value) {
            setInput(initialValue.value)
        }
    }, [])

    const validateInput = value => {
        const sanitizedValue = value.replace(/[^0-9.]/g, "")
        const decimalCount = sanitizedValue.split(".").length - 1
        const finalValue =
            decimalCount <= 1 ? sanitizedValue : sanitizedValue.slice(0, -1)

        return finalValue
    }

    const stringToNumber = str => {
        if (str === "") {
            return str
        }
        if (str.includes(".")) {
            return parseFloat(str)
        } else {
            return parseInt(str)
        }
    }

    const onInputChange = evt => {
        const inputValue = validateInput(evt.target.value)

        setInput(inputValue)
        updateFormValues(inputValue, null)
        checkEditStatus()

        if (checked && inputValue > 100) {
            setError("The percentage off value must be less than 100%.")
        } else {
            setError("")
        }
    }

    const onCheckboxChange = evt => {
        setChecked(!checked)
        setInput("")
        updateFormValues("", !checked)
        checkEditStatus()
    }

    const updateFormValues = (value, isDisabled) => {
        const newFormValues = currentFormValues

        if (Number.isInteger(index)) {
            newFormValues[index][fieldRef] = {
                value: stringToNumber(value),
                isDisabled: isDisabled,
            }
        } else {
            newFormValues[fieldRef] = {
                value: stringToNumber(value),
                isDisabled: isDisabled,
            }
        }
        setCurrentFormValues(newFormValues)
    }

    return (
        <Grid style={{ paddingRight: "20px" }} item container>
            <Grid item container direction="column">
                <FormControl disabled={checked} error={error}>
                    <InputLabel>{label}</InputLabel>
                    <Input
                        value={input}
                        onChange={onInputChange}
                        autoComplete={false}
                        inputProps={{
                            min: 0,
                            style: { textAlign: checked ? "right" : "left" },
                            inputMode: "decimal",
                        }}
                        startAdornment={
                            <Typography style={{ paddingRight: "4px" }}>
                                $
                            </Typography>
                        }
                    />
                    {defaultValue ? (
                        <FormHelperText>
                            <FormattedMessage
                                id="location.offshore.default"
                                defaultMessage="Default: ${value}"
                                values={{ value: defaultValue }}
                            />
                        </FormHelperText>
                    ) : null}
                    {error ? <FormHelperText>{error}</FormHelperText> : null}
                </FormControl>
                {showRateCheckbox ? (
                    <FormControl style={{ width: "100px" }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={initialValue?.isDisabled}
                                    onClick={onCheckboxChange}
                                    value={checked}
                                />
                            }
                            label="N/A"
                        />
                    </FormControl>
                ) : null}
            </Grid>
        </Grid>
    )
}

export default RateField

import React from "react"
import { connect } from "react-redux"
import { findLastIndex } from "lodash"
import createSelector from "re-reselect"
import PropTypes from "prop-types"
import moment from "moment"
import { withStyles } from "@material-ui/core/styles"
import { Stepper, Step, StepLabel, Grid, Typography } from "@material-ui/core"
import { isGuaranteedQuote } from "../../quote/card/util"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    root: {
        width: "100%",
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    timeline_date: {
        fontWeight: "bold",
    },
    timeline: {
        height: "100%",
        width: "100%",
    },
    dashboard: {
        "box-shadow": "none",
    },
    serviceLevel_text: {
        color: "green",
    },
})
const HandlingUnitTimeline = ({
    classes,
    shipment,
    isGuaranteed,
    statusSteps,
    activeIndex,
    pickupDate,
}) => {
    const activeStepIndex =
        activeIndex === statusSteps.length - 1
            ? statusSteps.length
            : activeIndex

    return (
        <Stepper
            alternativeLabel
            activeIndex={activeStepIndex}
            className={classes.timeline}
        >
            {statusSteps.map((item, index) => {
                const { completed, statusIdentifier = "", time, event } =
                    item || {}
                const stepAddress = event?.terminal?.terminalLocation?.address

                let statusTitle = ""
                if (statusIdentifier === "DELIVERED" && !completed) {
                    statusTitle = (
                        <FormattedMessage
                            id="generalTerms__expectedDelivery"
                            defaultMessage="EXPECTED DELIVERY"
                        />
                    )
                } else if (statusIdentifier === "CANCELED") {
                    statusTitle = (
                        <FormattedMessage
                            id="generalTerms__canceled"
                            defaultMessage="CANCELED"
                        />
                    )
                } else {
                    statusTitle = statusIdentifier.replace(/_/g, " ")
                }

                return (
                    <Step key={index} completed={completed}>
                        <StepLabel>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="center"
                            >
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justifyContent="center"
                                >
                                    <Typography
                                        variant="body2"
                                        justify="center"
                                    >
                                        {statusTitle}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    justifyContent="center"
                                    direction="row"
                                >
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        xs={12}
                                        justifyContent="center"
                                    >
                                        {completed && (
                                            <Typography
                                                variant="caption"
                                                justify="center"
                                                className={
                                                    classes.timeline_date
                                                }
                                            >
                                                {moment
                                                    .utc(time)
                                                    .format("dddd MMM Do")}
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        xs={12}
                                        justify="center"
                                    >
                                        {stepAddress &&
                                            index === activeIndex &&
                                            statusIdentifier !==
                                                "DELIVERED" && (
                                                <Typography
                                                    variant="caption"
                                                    justify="center"
                                                >
                                                    {`${stepAddress?.city}, ${stepAddress?.state}`}
                                                </Typography>
                                            )}
                                    </Grid>
                                </Grid>
                                {statusIdentifier === "DELIVERED" &&
                                    !completed && (
                                        <Grid
                                            item
                                            container
                                            justify="center"
                                            xs={12}
                                        >
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                xs={12}
                                                justify="center"
                                            >
                                                {!shipment?.rate
                                                    ?.isDomesticOffshore && (
                                                    <Typography
                                                        variant="caption"
                                                        justify="center"
                                                        className={
                                                            classes.timeline_date
                                                        }
                                                    >
                                                        {moment
                                                            .utc(time)
                                                            .format(
                                                                "dddd MMM Do"
                                                            )}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                xs={12}
                                                justify="center"
                                            >
                                                {isGuaranteed && (
                                                    <Typography
                                                        justify="center"
                                                        variant={"caption"}
                                                        className={
                                                            classes.serviceLevel_text
                                                        }
                                                    >
                                                        <FormattedMessage
                                                            id="tracking__guaranteed"
                                                            defaultMessage="Guaranteed"
                                                        />
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                {statusIdentifier === "PICKED UP" &&
                                !completed ? (
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        xs={12}
                                        justify="center"
                                    >
                                        <Typography
                                            variant="caption"
                                            justify="center"
                                            className={classes.timeline_date}
                                        >
                                            <FormattedMessage
                                                id="scheduled___pickUpDate"
                                                defaultMessage="Scheduled "
                                            />
                                        </Typography>
                                        <Grid
                                            item
                                            container
                                            direction="row"
                                            xs={12}
                                            justifyContent="center"
                                        >
                                            <Typography
                                                variant="caption"
                                                justify="center"
                                                className={
                                                    classes.timeline_date
                                                }
                                            >
                                                {moment
                                                    .utc(pickupDate)
                                                    .format("dddd MMM Do")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ) : null}
                            </Grid>
                        </StepLabel>
                    </Step>
                )
            })}
        </Stepper>
    )
}

HandlingUnitTimeline.propTypes = {
    classes: PropTypes.object,
}

export const statusStepSelector = createSelector(
    (state, { shipmentId }) =>
        state?.shipment?.list?.[shipmentId]?.shipment ??
        state?.shipment?.activeShipment?.shipment,
    (state, { status }) => status,
    (shipment, status) => {
        let firstStepName
        if (shipment?.isPickupLater) {
            firstStepName = (
                <FormattedMessage
                    id="tracking__created"
                    defaultMessage="CREATED"
                />
            )
        } else if (status?.currentStatus?.code === "CANCELED") {
            firstStepName = (
                <FormattedMessage
                    id="tracking__canceled"
                    defaultMessage="CANCELED"
                />
            )
        } else {
            firstStepName = (
                <FormattedMessage
                    id="tracking__dispatched"
                    defaultMessage="DISPATCHED"
                />
            )
        }
        const bookedAt = shipment?.booked_at
        let deliveryDateTime =
            status?.actualDeliveryDateTime ||
            status?.estimatedDeliveryDateTime ||
            (shipment?.rate?.deliveryDateRange
                ? moment.utc(
                      shipment?.rate.deliveryDateRange?.latestEstimatedDate
                  )
                : moment.utc(shipment?.rate?.deliveryDateTime))
        const estimatedDeliveryDatePresent = shipment?.rate?.transitDays >= 0

        const steps = {
            [firstStepName]: {
                status: firstStepName,
                time: moment.utc(bookedAt),
                completed: true,
                statusIdentifier: firstStepName?.props?.defaultMessage,
            },
            PICKED_UP: {
                status: (
                    <FormattedMessage
                        id="tracking__pickedUp"
                        defaultMessage="PICKED UP"
                    />
                ),
                statusIdentifier: "PICKED UP",
            },
            IN_TRANSIT: {
                status: (
                    <FormattedMessage
                        id="tracking__inTransit"
                        defaultMessage="IN TRANSIT"
                    />
                ),
                statusIdentifier: "IN TRANSIT",
            },
            OUT_FOR_DELIVERY: {
                status: (
                    <FormattedMessage
                        id="tracking__outForDelivery"
                        defaultMessage="OUT FOR DELIVERY"
                    />
                ),
                statusIdentifier: "OUT FOR DELIVERY",
            },
            DELIVERED: {
                status: (
                    <FormattedMessage
                        id="tracking__delivered"
                        defaultMessage="DELIVERED"
                    />
                ),
                time: estimatedDeliveryDatePresent
                    ? moment.utc(deliveryDateTime)
                    : undefined,
                statusIdentifier: "DELIVERED",
            },
        }

        let activeIndex = 0

        if (shipment) {
            const history = [
                status?.currentStatus,
                ...(status?.statusUpdateHistory ?? []),
            ].filter(x => x)
            history.forEach(event => {
                let mappedCode = event.code
                const step = steps[mappedCode]
                if (!step) return
                let eventTime =
                    mappedCode !== "DELIVERED" && moment.utc(event.timestamp)
                if (
                    mappedCode === "DELIVERED" ||
                    !step.time ||
                    step.time <= eventTime
                ) {
                    steps[mappedCode] = {
                        ...step,
                        completed: true,
                        event,
                        description: event.description,
                        ...(eventTime && { time: eventTime }),
                    }
                }
            })
        }

        const statusSteps = [
            steps[firstStepName],
            steps["PICKED_UP"],
            steps["IN_TRANSIT"],
            steps["OUT_FOR_DELIVERY"],
            steps["DELIVERED"],
        ]
        activeIndex = findLastIndex(statusSteps, item => item?.completed)
        return { statusSteps, activeIndex }
    }
)((state, { shipmentId }) => `${shipmentId}`)

const isGuaranteedSelector = createSelector(
    (state, { shipmentId }) =>
        state?.shipment?.list?.[shipmentId]?.shipment?.rate?.serviceLevel?.code,
    state => state?.accessorials?.list?.guaranteed ?? [],
    (serviceCode, accessorials) => isGuaranteedQuote(serviceCode, accessorials)
)(
    (state, { shipmentId }) =>
        state?.shipment?.list?.[shipmentId]?.shipment?.rate?.serviceLevel
            ?.code ?? ""
)

const mapStateToProps = (state, props) => ({
    isGuaranteed: isGuaranteedSelector(state, props),
    ...statusStepSelector(state, props),
})

export default withStyles(styles)(
    connect(mapStateToProps)(HandlingUnitTimeline)
)

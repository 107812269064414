import React from "react"
import { Grid, Typography } from "@material-ui/core"
import HandlingUnitsTable from "../../track/result/HandlingUnitsTable"
import { displayFullPhone } from "../../../actions/contact"

export default function ProNumberResultSuccess({ shipment, classes }) {
    return (
        <Grid item container className={classes.proNumberSearchResultContainer}>
            <Grid
                item
                container
                xs={6}
                className={classes.proNumberSearchResultAddress}
            >
                <Grid
                    item
                    container
                    justify="flex-start"
                    direction="row"
                    xs={12}
                >
                    <Grid
                        item
                        className={classes.proNumberSearchResultAddressTitle}
                    >
                        <Typography
                            variant={"title"}
                            className={classes.section__title}
                        >
                            Shipper
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    justify="flex-start"
                    direction="column"
                    xs={12}
                >
                    <Grid item>
                        <Typography variant="subtitle1">
                            {shipment?.shipment?.origin?.name}
                        </Typography>
                    </Grid>
                    {shipment?.shipment?.origin?.address && (
                        <Grid item>
                            <Typography variant="body2">
                                {shipment?.shipment?.origin?.address?.street1}{" "}
                                {shipment?.shipment?.origin?.address?.street2}
                            </Typography>
                        </Grid>
                    )}
                    {shipment?.shipment?.origin?.address && (
                        <Grid item>
                            <Typography variant="body2">
                                {shipment?.shipment?.origin?.address?.city},{" "}
                                {shipment?.shipment?.origin?.address?.state}{" "}
                                {
                                    shipment?.shipment?.origin?.address
                                        ?.postalCode
                                }
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                {shipment?.shipment?.origin?.contact && (
                    <Grid
                        item
                        container
                        className={classes.proNumberSearchResultContact}
                        direction="column"
                        justify="flex-start"
                        xs={12}
                    >
                        <Grid item>
                            <Typography variant="caption">
                                {shipment?.shipment?.origin?.contact?.name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                {
                                    shipment?.shipment?.origin?.contact?.email
                                        ?.email_address
                                }
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                {displayFullPhone(
                                    shipment?.shipment?.origin?.contact?.phone
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid item container xs={6} className={classes.consignee}>
                <Grid
                    item
                    container
                    justify="flex-start"
                    direction="row"
                    xs={12}
                >
                    <Grid
                        item
                        className={classes.proNumberSearchResultAddressTitle}
                    >
                        <Typography
                            variant={"title"}
                            className={classes.section__title}
                        >
                            Consignee
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    justify="flex-start"
                    direction="column"
                    xs={12}
                >
                    <Grid item>
                        <Typography variant="subtitle1">
                            {shipment?.shipment?.destination?.name}
                        </Typography>
                    </Grid>
                    {shipment?.shipment?.destination?.address && (
                        <Grid item>
                            <Typography variant="body2">
                                {
                                    shipment?.shipment?.destination?.address
                                        ?.street1
                                }{" "}
                                {
                                    shipment?.shipment?.destination?.address
                                        ?.street2
                                }
                            </Typography>
                        </Grid>
                    )}
                    {shipment?.shipment?.destination?.address && (
                        <Grid item>
                            <Typography variant="body2">
                                {shipment?.shipment?.destination?.address?.city}
                                ,{" "}
                                {
                                    shipment?.shipment?.destination?.address
                                        ?.state
                                }{" "}
                                {
                                    shipment?.shipment?.destination?.address
                                        ?.postalCode
                                }
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                {shipment?.shipment?.destination?.contact && (
                    <Grid
                        item
                        container
                        className={classes.proNumberSearchResultContact}
                        direction="column"
                        justify="flex-start"
                        xs={12}
                    >
                        <Grid item>
                            <Typography variant="caption">
                                {shipment?.shipment?.destination?.contact?.name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                {
                                    shipment?.shipment?.destination?.contact
                                        ?.email?.email_address
                                }
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                {
                                    shipment?.shipment?.destination?.contact
                                        ?.phone?.phone_number
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid item container>
                <HandlingUnitsTable
                    preferredSystemOfMeasurement={
                        shipment?.query?.preferredSystemOfMeasurement
                    }
                    handlingUnits={shipment?.handlingUnits}
                />
            </Grid>
        </Grid>
    )
}

import FileSaver from "file-saver"
import { get } from "lodash"
import { goFetch } from "../http"
import { createGAEvent, errorMessage, USER_INPUT_ENDED_DELAY } from "./index"
import { attachPusher } from "./chat"
import { FormattedMessage } from "react-intl"

export const ARCHIVE_SHIPMENT = "ARCHIVE_SHIPMENT"
export const UNARCHIVE_SHIPMENT = "UNARCHIVE_SHIPMENT"
export const STAR_SHIPMENT = "STAR_SHIPMENT"
export const UNSTAR_SHIPMENT = "UNSTAR_SHIPMENT"
export const ADD_SHIPMENT = "ADD_SHIPMENT"
export const REMOVE_SHIPMENT = "REMOVE_SHIPMENT"
export const BACKFILL_SHIPMENT_LIST = "BACKFILL_SHIPMENT_LIST"
export const ACTIVE_SHIPMENT_DETAILS = "ACTIVE_SHIPMENT_DETAILS"
export const CHANGE_STARRED_ACTIVE_SHIPMENT = "CHANGE_STARRED_ACTIVE_SHIPMENT"
export const DISMISS_WARNING_ACTIVE_SHIPMENT = "DISMISS_WARNING_ACTIVE_SHIPMENT"
export const SHIPMENT_LIST_LOAD = "SHIPMENT_LIST_LOAD"
export const SHIPMENT_LIST_LOAD_ERROR = "SHIPMENT_LIST_LOAD_ERROR"
export const SHIPMENT_LIST_RESULT = "SHIPMENT_LIST_RESULT"
export const TRACK_FIELD_CHANGE = "TRACK_FIELD_CHANGE"
export const SHIPMENT_SEARCH_RESULT = "SHIPMENT_SEARCH_RESULT"
export const QUEUE_SHIPMENT_SEARCH = "QUEUE_SHIPMENT_SEARCH"
export const SHIPMENT_LIST_RESULT_FILTER_APPLIED =
    "SHIPMENT_LIST_RESULT_FILTER_APPLIED"

export function addShipment(result) {
    return { type: ADD_SHIPMENT, result }
}

export function removeShipmentAndBackfillList(shipmentId) {
    return async (dispatch, getState) => {
        dispatch({ type: REMOVE_SHIPMENT, shipmentId })
        try {
            const numberOfRequestedShipments =
                getState().shipment?.pagination?.page *
                getState().shipment?.pagination?.pageSize

            if (
                getState().shipment?.totalCount > numberOfRequestedShipments &&
                Object.keys(getState().shipment?.list).length <
                    numberOfRequestedShipments
            ) {
                const { data, status } = await fetchShipmentListAndCount(
                    numberOfRequestedShipments,
                    getState().form?.dashboardFilters?.values ?? {},
                    getState().dashboard?.activeDashboardTile,
                    getState().shipment?.pagination
                )
                if (status === 404) return
                dispatch({ type: BACKFILL_SHIPMENT_LIST, result: data })
            }
        } catch (error) {
            return dispatch(shipmentLoadError(error))
        }
    }
}

export function changeStarredActiveShipment(isFavorite) {
    return { type: CHANGE_STARRED_ACTIVE_SHIPMENT, isFavorite }
}

export function changeStarredStatus(
    shipmentId,
    isFavorite,
    starredFilterActive,
    openSnackbar,
    changeTileCount,
    revertChangeTileCount
) {
    return async (dispatch, getState) => {
        if (starredFilterActive) {
            dispatch({ type: UNSTAR_SHIPMENT, shipmentId })
        } else {
            if (isFavorite) {
                dispatch({ type: STAR_SHIPMENT, shipmentId })
            } else {
                dispatch({ type: UNSTAR_SHIPMENT, shipmentId })
            }
        }
        changeTileCount("starred")
        try {
            await goFetch(
                `/shipments/${shipmentId}/favorite`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        isFavorite,
                    },
                },
                true
            )

            if (starredFilterActive) {
                return dispatch(removeShipmentAndBackfillList(shipmentId))
            }
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="dashboard.starredError"
                    defaultMessage="Unable to star shipment"
                />
            )
            revertChangeTileCount("starred")
            if (isFavorite) {
                dispatch({ type: UNSTAR_SHIPMENT, shipmentId })
            } else {
                dispatch({ type: STAR_SHIPMENT, shipmentId })
            }
        }
    }
}

export function changeArchiveStatus(
    shipmentId,
    archive,
    archiveFilterOn,
    openSnackbar,
    selectedTiles,
    setTileCount
) {
    return async dispatch => {
        try {
            await goFetch(
                `/shipments/archive`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        archive: archive,
                        shipmentsToArchive: [shipmentId],
                    },
                },
                true
            )
            if (archive) {
                openSnackbar(
                    "success",
                    <FormattedMessage
                        id="dashboard.shipment.archiveSuccess"
                        defaultMessage="Shipment Archived"
                    />
                )
                dispatch(
                    createGAEvent(
                        "Archive Shipment",
                        "Shipment archived from dashboard"
                    )
                )
                if (archiveFilterOn) {
                    return dispatch({ type: ARCHIVE_SHIPMENT, shipmentId })
                } else {
                    dispatch(updateTileCounts(selectedTiles, setTileCount))
                    return dispatch(removeShipmentAndBackfillList(shipmentId))
                }
            } else {
                openSnackbar(
                    "success",
                    <FormattedMessage
                        id="dashboard.shipment.unarchiveSuccess"
                        defaultMessage="Shipment Unarchived"
                    />
                )
                dispatch(
                    createGAEvent(
                        "Archive Shipment",
                        "Shipment unarchived from dashboard"
                    )
                )
                return dispatch({ type: UNARCHIVE_SHIPMENT, shipmentId })
            }
        } catch (error) {
            if (archive) {
                openSnackbar(
                    "error",
                    <FormattedMessage
                        id="dashboard.shipment.archiveError"
                        defaultMessage="Shipment failed to archive"
                    />
                )
            } else {
                openSnackbar(
                    "error",
                    <FormattedMessage
                        id="dashboard.shipment.unarchiveError"
                        defaultMessage="Shipment failed to unarchive"
                    />
                )
            }
        }
    }
}

export const updateTileCounts = (selectedTiles, setTileCount) => {
    return async dispatch => {
        const promises = selectedTiles.map(tile =>
            dispatch(requestTileCountWithFilters(tile))
        )
        const counts = await Promise.all(promises)
        for (let i = 0; i < selectedTiles.length; i++) {
            setTileCount(selectedTiles[i], counts[i]?.data?.totalCount)
        }
    }
}

function shipmentLoad() {
    return { type: SHIPMENT_LIST_LOAD }
}

export function shipmentLoadError(error) {
    return dispatch => {
        dispatch(errorMessage(error))
        dispatch({
            type: SHIPMENT_LIST_LOAD_ERROR,
            error,
        })
    }
}

const shipmentListResult = result => ({ type: SHIPMENT_LIST_RESULT, result })
const shipmentListResultFilterApplied = result => ({
    type: SHIPMENT_LIST_RESULT_FILTER_APPLIED,
    result,
})

function shouldRequestShipments(state, prefix = "shipment") {
    return (
        !state[prefix].isFetching &&
        (!state[prefix].isLoaded ||
            Object.keys(state[prefix]?.list).length < state[prefix].totalCount)
    )
}

async function fetchShipmentListAndCount(
    targetSize,
    dashboardFilters,
    activeDashboardTile,
    pagination
) {
    targetSize = targetSize ?? pagination.pageSize
    let pageNumber = Math.round(targetSize / pagination.pageSize)
    let params =
        targetSize > 0
            ? { pageSize: pagination.pageSize, pageNumber }
            : { pageSize: 0 }

    //check dashboard filters
    if (dashboardFilters) {
        const {
            locationFilter,
            carrierFilter,
            archiveFilter,
        } = dashboardFilters

        if (locationFilter) {
            params.cpg = Object.keys(locationFilter).filter(
                key => !!locationFilter[key]
            )
        }

        if (carrierFilter) {
            params.scac = Object.keys(carrierFilter).filter(
                key => !!carrierFilter[key]
            )
        }

        if (archiveFilter?.viewArchivedShipments) {
            params.viewArchived = true
        }
    }

    //check dashboard tiles
    if (activeDashboardTile) {
        const userTimezoneOffset = new Date().getTimezoneOffset()

        switch (activeDashboardTile) {
            case "delivered":
                params.status = "DELIVERED"
                params.userTimezoneOffset = userTimezoneOffset
                break
            case "deliveredToday":
                params.status = "DELIVERED_TODAY"
                params.userTimezoneOffset = userTimezoneOffset
                break
            case "pickedUp":
                params.status = "PICKED_UP"
                params.userTimezoneOffset = userTimezoneOffset
                break
            case "pickingUpToday":
                params.status = "PICKING_UP_TODAY"
                params.userTimezoneOffset = userTimezoneOffset
                break
            case "pickingUpTomorrow":
                params.status = "PICKING_UP_TOMORROW"
                params.userTimezoneOffset = userTimezoneOffset
                break
            case "inTransit":
                params.status = "IN_TRANSIT"
                params.userTimezoneOffset = userTimezoneOffset
                break
            case "outForDelivery":
                params.status = "OUT_FOR_DELIVERY"
                params.userTimezoneOffset = userTimezoneOffset
                break
            case "canceled":
                params.status = "CANCELED"
                params.userTimezoneOffset = userTimezoneOffset
                break
            case "delayedPickup":
                params.status = "DELAYED_PICKUP"
                params.userTimezoneOffset = userTimezoneOffset
                break
            case "created":
                params.status = "CREATED"
                params.userTimezoneOffset = userTimezoneOffset
                break
            case "starred":
                params.favorite = true
                break
            case "inbound":
                params.direction = "INBOUND"
                break
            case "outbound":
                params.direction = "OUTBOUND"
                break
            case "thirdParty":
                params.direction = "THIRD_PARTY"
                break
            case "warning":
                params.warning = true
                break
            case "clearanceDelay":
                params.warningMsg = "CLEARANCE_DELAY"
                break
            case "damage":
                params.warningMsg = "DAMAGE"
                break
            case "reweigh":
                params.warningMsg = "REWEIGH"
                break
            case "delay":
                params.warningMsg = "DELAY"
                break
            case "deliveryOptionRequested":
                params.warningMsg = "DELIVERY_OPTION_REQUESTED"
                break
            case "missedDelivery":
                params.warningMsg = "MISSED_DELIVERY"
                break
            case "refusedDelivery":
                params.warningMsg = "REFUSED_DELIVERY"
                break
            case "viewInvoicedVariant":
                params.viewInvoicedVariant = true
                break
            default:
                break
        }
    }

    const { data, status } = await goFetch(
        "/shipments",
        {
            method: "GET",
            credentials: "same-origin",
            params,
            validErrorCodes: [404],
        },
        true
    )
    ;(data.list || []).forEach(shipment => {
        if (!shipment.shipment.origin.address) {
            shipment.shipment.origin.address = {}
        }
        if (!shipment.shipment.destination.address) {
            shipment.shipment.destination.address = {}
        }
    })
    return { data, status }
}

export function requestShipments(targetSize, force) {
    return async (dispatch, getState) => {
        if (!force) {
            const shipmentList = get(getState(), "shipment.list", {})
            //if we already have fetched ALL the shipments
            if (!shouldRequestShipments(getState())) return null
            //if we already have fetched enough shipments (i.e. navigating backwards)
            if (targetSize && targetSize <= Object.keys(shipmentList).length) {
                return null
            }
        }
        const dashboardFilters = get(
            getState(),
            "form.dashboardFilters.values",
            {}
        )
        dispatch(shipmentLoad())

        const activeDashboardTile = getState().dashboard?.activeDashboardTile

        try {
            const { data, status } = await fetchShipmentListAndCount(
                targetSize,
                dashboardFilters,
                activeDashboardTile,
                getState().shipment?.pagination
            )
            if (status === 404)
                return dispatch(shipmentListResult({ list: [] }))
            const retVal = dispatch(shipmentListResult(data))
            const activeShipments = get(retVal, "list", []).filter(
                item =>
                    get(item, "status.currentStatus.code", "CREATED") !==
                    "DELIVERED"
            )
            activeShipments.forEach(item => {
                const channel = get(item, "identifiers.internalTrackingNumber")
                dispatch(attachPusher(channel))
            })
            return retVal
        } catch (error) {
            return dispatch(shipmentLoadError(error))
        }
    }
}

export function requestShipmentsWithFilters(targetSize) {
    return async (dispatch, getState) => {
        const dashboardFilters = getState().dashboard?.dashboardFilters ?? {}

        dispatch(shipmentLoad())
        const activeDashboardTile = getState().dashboard?.activeDashboardTile
        try {
            const { data, status } = await fetchShipmentListAndCount(
                targetSize,
                dashboardFilters,
                activeDashboardTile,
                getState().shipment?.pagination
            )
            if (status === 404)
                return dispatch(shipmentListResult({ list: [] }))
            const retVal = dispatch(shipmentListResultFilterApplied(data))
            const activeShipments = get(retVal, "list", []).filter(
                item =>
                    get(item, "status.currentStatus.code", "CREATED") !==
                    "DELIVERED"
            )
            activeShipments.forEach(item => {
                const channel = get(item, "identifiers.internalTrackingNumber")
                dispatch(attachPusher(channel))
            })
            return retVal
        } catch (error) {
            return dispatch(shipmentLoadError(error))
        }
    }
}

export const requestTileCountWithFilters = dashboardTile => {
    return async (dispatch, getState) => {
        const dashboardFilters = getState().dashboard?.dashboardFilters ?? {}

        try {
            const result = await fetchShipmentListAndCount(
                0,
                dashboardFilters,
                dashboardTile,
                getState().shipment?.pagination
            )
            return result
        } catch (error) {
            console.log(error)
        }
    }
}

async function fetchShipment(shipmentId, shareKey) {
    const { data } = await goFetch(
        `/shipments/${shipmentId}`,
        {
            method: "GET",
            credentials: "same-origin",
        },
        true,
        shareKey
    )
    return data
}

export function populateTrackForm(shipmentId) {
    return async (dispatch, getState) => {
        try {
            if (!shipmentId) return
            let shipment = getState().shipment.list[shipmentId]
            const shareKey = getState().shareStatus.shareKey
            if (!shipment) {
                shipment = await fetchShipment(shipmentId, shareKey)
            }
            dispatch({ type: ACTIVE_SHIPMENT_DETAILS, shipment })
            return shipment
        } catch (err) {
            dispatch(errorMessage(err))
        }
    }
}

export const fieldChange = (field, value) => ({
    type: TRACK_FIELD_CHANGE,
    field,
    value,
})

export function proLinkClick(shipmentId) {
    return dispatch => {
        dispatch(
            createGAEvent("Shipment", "Tracking ID (FedEx) hyper link", "", {
                shipment: shipmentId,
            })
        )
    }
}

export function exportShipmentsAsCsv() {
    return async dispatch => {
        try {
            const { data } = await goFetch(
                "/shipments/csv",
                {
                    method: "GET",
                    credentials: "same-origin",
                    responseType: "text",
                },
                true,
                null,
                "text/csv"
            )
            const blob = new Blob([data], {
                type: "text/csv;charset=utf-8",
            })
            FileSaver.saveAs(blob, "shipments.csv")
            return blob
        } catch (error) {
            dispatch(errorMessage(error))
            throw error
        }
    }
}

export function getShipmentIndex(shipmentId, index, list) {
    return (dispatch, getState) => {
        const shipments = getState().shipment.list
        const shipmentArray = Object.keys(shipments).sort((a, b) =>
            shipments[b].created_at > shipments[a].created_at ? 1 : -1
        )
        const wantedIndex =
            shipmentArray.findIndex(s => s === shipmentId) + index
        if (wantedIndex < 0 || wantedIndex >= shipments.length) return null
        return shipmentArray[wantedIndex]
    }
}

const shipmentSearchResult = (value, data = []) => ({
    type: SHIPMENT_SEARCH_RESULT,
    value,
    data,
})

function innerSearchShipments(value) {
    return async dispatch => {
        try {
            const params = { q: value, pageSize: 5 }
            const { data, status } = await goFetch(
                "/shipments",
                { validErrorCodes: [404], params },
                true
            )
            if (status === 404) return dispatch(shipmentSearchResult(value))
            return dispatch(shipmentSearchResult(value, data.list))
        } catch (error) {
            return dispatch(errorMessage(error))
        }
    }
}

export function searchShipments(value) {
    return async (dispatch, getState) => {
        if (!value || value.length < 3) return
        const result = getState().shipment.search[value]
        if (result) return
        const { searchInProgress } = getState().shipment
        clearTimeout(searchInProgress)
        const timeoutId = setTimeout(
            () => dispatch(innerSearchShipments(value)),
            USER_INPUT_ENDED_DELAY
        )
        dispatch({ type: QUEUE_SHIPMENT_SEARCH, id: timeoutId })
    }
}

import React, { useState, createContext, useContext, useEffect } from "react"
import { goFetch } from "../../http"

export const AccessorialsContext = createContext()

export const useAccessorialsContext = () => {
    const accessorialsContext = useContext(AccessorialsContext)
    if (!accessorialsContext) {
        throw new Error(
            "Cannot use accessorals context ouside of AccessorialsProvider"
        )
    }
    return accessorialsContext
}

export default function AccessorialsProvider({ children }) {
    const [rawData, setRawData] = useState(null)
    const [accessorialList, setAccessorialList] = useState([])
    const [pickupAccessorialList, setPickupAccessorialList] = useState([])
    const [deliveryAccessorialList, setDeliveryAccessorialList] = useState([])

    useEffect(() => {
        setPickupAccessorialList(
            accessorialList.filter(item => item.category === "pickup")
        )
        setDeliveryAccessorialList(
            accessorialList.filter(item => item.category === "delivery")
        )
    }, [accessorialList])

    const getAccessorialsList = async (language = "en-us") => {
        if (!rawData) {
            try {
                const { data } = await goFetch(
                    "/accessorial/",
                    { method: "GET", credentials: "same-origin" },
                    true
                )
                setRawData(data)
                setAccessorialList(
                    data.map(item => {
                        return {
                            name: item?.name[language],
                            value: item?.key,
                            category: item?.category,
                        }
                    })
                )
            } catch (err) {}
        } else {
            if (accessorialList.length === 0) {
                setAccessorialList(
                    rawData.map(item => {
                        return {
                            name: item?.name[language],
                            value: item?.value,
                            category: item?.category,
                        }
                    })
                )
            }
        }
    }
    const contextData = {
        accessorialList,
        pickupAccessorialList,
        deliveryAccessorialList,
        getAccessorialsList,
    }
    return (
        <AccessorialsContext.Provider value={contextData}>
            {children}
        </AccessorialsContext.Provider>
    )
}

import React from "react"
import {
    Typography,
    Grid,
    FormControlLabel,
    Checkbox,
    Box,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"

const LocationListItem = ({ location, handleToggle, checked }) => {
    return (
        <Box
            sx={{
                paddingBottom: "5px",
                borderBottom: "solid 1px #d4d4d4",
                width: "100%",
            }}
        >
            <Grid item container>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            value={location.value}
                            onChange={e => handleToggle(location.value, e)}
                        />
                    }
                    label={location.label}
                />
            </Grid>
            <Grid item container direction="column">
                {location?.fedexFreightAccount && (
                    <Typography variant="caption">
                        {location?.isFreightDirect
                            ? [
                                  <FormattedMessage
                                      id="locations__FXFDShipperAccountNumber"
                                      defaultMessage="FXFD Shipper Account #"
                                  />,
                                  ": ",
                                  location?.fedexFreightAccount,
                              ]
                            : [
                                  <FormattedMessage
                                      id="locations__shipperAccountNumber"
                                      defaultMessage="Shipper Account #"
                                  />,
                                  ": ",
                                  location?.fedexFreightAccount,
                              ]}
                    </Typography>
                )}
                {location?.fedexBillToAccount && (
                    <Typography variant="caption">
                        {location?.isFreightDirect
                            ? [
                                  <FormattedMessage
                                      id="locations__FXFDThirdPartyAccountNumber"
                                      defaultMessage="FXFD Third Party Account #"
                                  />,
                                  ": ",
                                  location?.fedexBillToAccount,
                              ]
                            : [
                                  <FormattedMessage
                                      id="locations__thirdPartyAccountNumber"
                                      defaultMessage="Third Party Account #"
                                  />,
                                  ": ",
                                  location?.fedexBillToAccount,
                              ]}
                    </Typography>
                )}
            </Grid>
            {location?.isFreightDirect && (
                <Grid>
                    <Typography variant="caption" color="primary">
                        <FormattedMessage
                            id="dashboard.filters__freightDirect"
                            defaultMessage="FedEx Freight Direct"
                        />
                    </Typography>
                </Grid>
            )}
            {location?.isFreightBox && (
                <Grid>
                    <Typography variant="caption" color="primary">
                        <FormattedMessage
                            id="dashboard.filters__freightBox"
                            defaultMessage="FedEx Freight Box"
                        />
                    </Typography>
                </Grid>
            )}
        </Box>
    )
}

export default LocationListItem

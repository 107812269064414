import React, { useEffect, useState } from "react"
import { Grid, Divider, Box, MenuItem } from "@material-ui/core"
import ModalButtons from "../ModalButtons"
import GlobalSpinner from "../../../../common/GlobalSpinner"
import TitleField from "../TitleField"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import { FormControl } from "@material-ui/core"
import HandleUnsavedChangesModal from "../HandleUnsavedChangesModal"
import AlaskaOceanChargeField from "./AlaskaOceanChargeField"
import AlaskaRateField from "./AlaskaRateField"
import AlaskaDiscountField from "./AlaskaDiscountField"
import { FormattedMessage } from "react-intl"

const AlaskaOceanChargeForm = ({
    classes,
    titleField,
    setOpen,
    customPricing,
    dataFields,
    defaultValues,
    resetCustomPricing,
    saveCustomPricing,
    location,
    activeFilter,
    availableFreightClasses,
    trackGA,
}) => {
    const [
        currentMainlandOriginFreightClass,
        setCurrentMainlandOriginFreightClass,
    ] = useState(50)
    const [
        currentAlaskaOriginFreightClass,
        setCurrentAlaskaOriginFreightClass,
    ] = useState(50)
    const [currentFormValues, setCurrentFormValues] = useState(customPricing)
    const [displayDefaultFormValues, setDisplayDefaultFormValues] = useState([])
    const [displayCustomFormValues, setDisplayCustomFormValues] = useState([])
    const [saving, setSaving] = useState(false)
    const [isEdited, setIsEdited] = useState(false)
    const [openUnsavedModal, setOpenUnsavedModal] = useState(false)

    useEffect(() => {
        let initialCustomPricing = []
        if (!customPricing) {
            initialCustomPricing = [
                {
                    originCFS: "SEA/TAC",
                    destinationCFS: "Alaska",
                    highMinimumCharge: null,
                    lowMinimumCharge: null,
                    oceanGrossDiscount: null,
                    ratePerCwtByClasses: [],
                },
                {
                    originCFS: "Alaska",
                    destinationCFS: "SEA/TAC",
                    highMinimumCharge: null,
                    lowMinimumCharge: null,
                    oceanGrossDiscount: null,
                    ratePerCwtByClasses: [],
                },
            ]
            setCurrentFormValues([...initialCustomPricing])
            generateDefaultAndCustomRow(
                currentMainlandOriginFreightClass,
                currentAlaskaOriginFreightClass,
                initialCustomPricing
            )
        } else {
            generateDefaultAndCustomRow(
                currentMainlandOriginFreightClass,
                currentAlaskaOriginFreightClass,
                customPricing
            )
            setCurrentFormValues([...customPricing])
        }
    }, [])

    const handleCancel = () => {
        if (isEdited) {
            setOpenUnsavedModal(true)
        } else {
            setOpen(false)
        }
    }

    const handleSave = async () => {
        setSaving(true)
        await saveCustomPricing(currentFormValues, location._id, activeFilter)
        setSaving(false)
        setOpen(false)
    }

    const handleReset = async () => {
        setSaving(true)
        await resetCustomPricing(location._id, activeFilter)
        setSaving(false)
        setOpen(false)
    }

    const handleStaticFieldChange = (index, currentCustomPricing) => {
        if (index === 0) {
            setCurrentFormValues(
                new Array(currentCustomPricing, currentFormValues[1])
            )
        } else {
            setCurrentFormValues(
                new Array(currentFormValues[0], currentCustomPricing)
            )
        }
        setIsEdited(true)
    }

    const handleRatePerCwtFieldChange = (index, changedRates) => {
        if (index === 0) {
            setCurrentFormValues(
                new Array(
                    {
                        ...currentFormValues[0],
                        ratePerCwtByClasses: changedRates,
                    },
                    currentFormValues[1]
                )
            )
        } else {
            setCurrentFormValues(
                new Array(currentFormValues[0], {
                    ...currentFormValues[1],
                    ratePerCwtByClasses: changedRates,
                })
            )
        }
        setIsEdited(true)
    }

    const handleChange = (index, e) => {
        if (index == 0) {
            generateDefaultAndCustomRow(
                e.target.value,
                currentAlaskaOriginFreightClass,
                currentFormValues
            )
            setCurrentMainlandOriginFreightClass(e.target.value)
        }
        if (index == 1) {
            generateDefaultAndCustomRow(
                currentMainlandOriginFreightClass,
                e.target.value,
                currentFormValues
            )
            setCurrentAlaskaOriginFreightClass(e.target.value)
        }
    }
    const generateDefaultAndCustomRow = (
        mainlandOriginClass,
        alaskaOriginClass,
        currentCustomPricing
    ) => {
        let mainlandDefaultRow = getDisplayRow(
            defaultValues,
            "SEA/TAC",
            mainlandOriginClass
        )
        let alaskaDefaultRow = getDisplayRow(
            defaultValues,
            "Alaska",
            alaskaOriginClass
        )

        let mainlandCustomRow = getDisplayRow(
            currentCustomPricing,
            "SEA/TAC",
            mainlandOriginClass
        )
        let alaskaCustomRow = getDisplayRow(
            currentCustomPricing,
            "Alaska",
            alaskaOriginClass
        )
        setDisplayDefaultFormValues([mainlandDefaultRow, alaskaDefaultRow])
        setDisplayCustomFormValues([mainlandCustomRow, alaskaCustomRow])
    }

    const getDisplayRow = (pricing, origin, freightClass) => {
        let routePricing = origin === "SEA/TAC" ? pricing[0] : pricing[1]
        return buildDisplayRow(routePricing, freightClass)
    }

    const buildDisplayRow = (routePricing, freightClass) => {
        if (!routePricing) return null
        if (
            routePricing?.ratePerCwtByClasses === null ||
            routePricing?.ratePerCwtByClasses.length === 0
        ) {
            return routePricing
        }
        let ratePerCwtByClasses = routePricing.ratePerCwtByClasses.filter(
            classPricing => classPricing.freightClass === freightClass
        )
        return { ...routePricing, ratePerCwtByClasses }
    }
    return (
        <Grid container>
            <HandleUnsavedChangesModal
                location={location}
                handleSave={handleSave}
                openUnsavedModal={openUnsavedModal}
                currentFormValues={currentFormValues}
                setOpenUnsavedModal={setOpenUnsavedModal}
                setCurrentFormValues={setCurrentFormValues}
                setOpen={setOpen}
                initialFormValues={customPricing}
                classes={classes}
                saving={saving}
            />
            {displayDefaultFormValues?.map((defaultRowValues, index) => {
                let initRowValues
                if (displayCustomFormValues) {
                    initRowValues = displayCustomFormValues[index]
                }
                return (
                    <Grid container direction="column">
                        <Grid container item style={{ marginTop: "16px" }}>
                            {dataFields.map(field => {
                                return (
                                    <Box
                                        style={{
                                            display: "flex",
                                            width: `${100 /
                                                dataFields.length}%`,
                                            maxWidth: "33%",
                                        }}
                                    >
                                        {field?.type === "title" ? (
                                            <TitleField
                                                value={
                                                    defaultRowValues[field?.ref]
                                                }
                                                title={field.label}
                                            />
                                        ) : null}
                                        {field?.type === "dropdown" ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <FormControl>
                                                    <InputLabel>
                                                        <FormattedMessage
                                                            id="location.offshore.class"
                                                            defaultMessage="Class"
                                                        />
                                                    </InputLabel>
                                                    <Select
                                                        id="freightClass-select"
                                                        value={
                                                            index == 0
                                                                ? currentMainlandOriginFreightClass
                                                                : currentAlaskaOriginFreightClass
                                                        }
                                                        onChange={e =>
                                                            handleChange(
                                                                index,
                                                                e
                                                            )
                                                        }
                                                    >
                                                        {availableFreightClasses?.map(
                                                            freightClass => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            freightClass
                                                                        }
                                                                    >
                                                                        {
                                                                            freightClass
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        ) : null}
                                        {field?.type === "charge" ? (
                                            <AlaskaOceanChargeField
                                                index={index}
                                                label={field.label}
                                                defaultValue={
                                                    defaultRowValues[field?.ref]
                                                        ?.value
                                                }
                                                initialValue={
                                                    initRowValues
                                                        ? initRowValues[
                                                              field?.ref
                                                          ]
                                                            ? initRowValues[
                                                                  field?.ref
                                                              ]
                                                            : null
                                                        : null
                                                }
                                                fieldRef={field.ref}
                                                setCurrentFormValues={
                                                    setCurrentFormValues
                                                }
                                                currentPricing={
                                                    initRowValues
                                                        ? initRowValues
                                                        : null
                                                }
                                                currentFormValues={
                                                    currentFormValues
                                                        ? currentFormValues[
                                                              index
                                                          ]
                                                        : null
                                                }
                                                handleStaticFieldChange={
                                                    handleStaticFieldChange
                                                }
                                            />
                                        ) : null}
                                        {field?.type === "rate" ? (
                                            <AlaskaRateField
                                                index={index}
                                                label={field.label}
                                                defaultValue={
                                                    defaultRowValues[
                                                        field?.ref
                                                    ][0].ratePerCwt?.value
                                                }
                                                initialValue={
                                                    initRowValues
                                                        ? initRowValues[
                                                              field?.ref
                                                          ].length > 0
                                                            ? initRowValues[
                                                                  field?.ref
                                                              ][0].ratePerCwt
                                                            : null
                                                        : null
                                                }
                                                fieldRef={field.ref}
                                                setCurrentFormValues={
                                                    setCurrentFormValues
                                                }
                                                currentFormValues={
                                                    currentFormValues
                                                }
                                                freightClass={
                                                    index === 0
                                                        ? currentMainlandOriginFreightClass
                                                        : currentAlaskaOriginFreightClass
                                                }
                                                handleRatePerCwtFieldChange={
                                                    handleRatePerCwtFieldChange
                                                }
                                            />
                                        ) : null}
                                        {field?.type === "discount" ? (
                                            <AlaskaDiscountField
                                                index={index}
                                                label={field?.label}
                                                defaultValue={
                                                    defaultRowValues[field?.ref]
                                                        ?.value
                                                }
                                                initialValue={
                                                    initRowValues
                                                        ? initRowValues[
                                                              field?.ref
                                                          ]
                                                        : null
                                                }
                                                fieldRef={field.ref}
                                                setCurrentFormValues={
                                                    setCurrentFormValues
                                                }
                                                currentFormValues={
                                                    currentFormValues
                                                        ? currentFormValues[
                                                              index
                                                          ]
                                                        : null
                                                }
                                                freightClass={
                                                    index === 0
                                                        ? currentMainlandOriginFreightClass
                                                        : currentAlaskaOriginFreightClass
                                                }
                                                handleStaticFieldChange={
                                                    handleStaticFieldChange
                                                }
                                            />
                                        ) : null}
                                    </Box>
                                )
                            })}
                        </Grid>
                        <Divider />
                    </Grid>
                )
            })}
            <ModalButtons
                handleCancel={handleCancel}
                handleSave={handleSave}
                disableSave={!isEdited}
                handleReset={handleReset}
                offshoreLocation="Alaska"
                activeFilter={activeFilter}
                trackGA={trackGA}
            />
            {saving ? <GlobalSpinner /> : null}
        </Grid>
    )
}

export default AlaskaOceanChargeForm

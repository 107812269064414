import React from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import { DatePicker } from "@material-ui/pickers"
import { Controller } from "react-hook-form"

const FormDatePicker = ({
    autoFocus,
    required,
    minDate,
    maxDate,
    width = 100,
    name,
    control,
    label,
    defaultValue = "",
    rules = {},
    input,
    ...props
}) => {
    return (
        <Controller
            defaultValue={moment.utc(
                moment()
                    .tz("Pacific/Honolulu")
                    .format("YYYY-MM-DD")
            )}
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState: { error } }) => (
                <DatePicker
                    defaultValue={moment()
                        .tz("Pacific/Honolulu")
                        .format("YYYY-MM-DD")}
                    format="MM/DD/YYYY"
                    autoOk
                    autoFocus={autoFocus}
                    value={field?.value}
                    invalidLabel=""
                    id={field.name}
                    style={{
                        width: `${width}%`,
                        paddingRight: "10px",
                    }}
                    margin="none"
                    label={label}
                    required={required}
                    minDate={
                        minDate
                            ? moment.utc(
                                  moment()
                                      .tz("Pacific/Honolulu")
                                      .format("YYYY-MM-DD")
                              )
                            : null
                    }
                    maxDate={maxDate}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    onChange={value => {
                        const utcValue = moment.utc(
                            moment(value).format("YYYY-MM-DD")
                        )

                        field.onChange(utcValue)
                    }}
                />
            )}
        />
    )
}

FormDatePicker.propTypes = {
    autoFocus: PropTypes.bool,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    type: PropTypes.oneOf(["text", "phone", "ext"]),
    width: PropTypes.number,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    label: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    required: PropTypes.bool,
    rules: PropTypes.object,
}

export default FormDatePicker

import { AsYouType } from "libphonenumber-js"

const generalAsYouType = new AsYouType()
const countryAsYouType = {
    US: new AsYouType("US"),
    CA: new AsYouType("CA"),
    MX: new AsYouType("MX"),
}
const nonValueCharacterRegEx = /[^\d+]/g
const onlyNonValueCharactersRegEx = /^[^\d+]+$/

// Ideally, phone numbers should be local to:
// - address county (for contacts)
// - user language related country (for all other phone numbers)
const normalizePhone = (country = "US") => (value = "", previousValue = "") => {
    if (!value) {
        return value
    }
    let nonDisplayValue = value.replace(nonValueCharacterRegEx, "")
    if (
        previousValue &&
        previousValue.startsWith(value) &&
        onlyNonValueCharactersRegEx.test(previousValue.substring(value.length))
    ) {
        nonDisplayValue = nonDisplayValue.slice(0, -1)
    }
    let asYouType = nonDisplayValue.startsWith("+")
        ? generalAsYouType
        : countryAsYouType[country]
    if (!asYouType) {
        asYouType = new AsYouType(country)
        countryAsYouType[country] = asYouType
    }
    asYouType.reset()
    return asYouType.input(nonDisplayValue)
}

export default normalizePhone

import React from "react"
import { useStyles } from "./styles"
import { Grid, IconButton, Tooltip } from "@material-ui/core"
import BookViewButton from "./BookViewButton"
import { FormattedMessage } from "react-intl"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"
import UnarchiveIcon from "@material-ui/icons/Unarchive"
import ArchiveIcon from "@material-ui/icons/Archive"
import DeleteIcon from "@material-ui/icons/Delete"
import { messages } from "./i18n"

const OrderActionCellContent = ({ order, column, isArchiving }) => {
    const classes = useStyles()
    const { isFulfilled, isArchived } = order
    if (isArchived) {
        return (
            <Grid item className={classes.buttonGrouping}>
                <BookViewButton order={order} column={column} />
                <Tooltip
                    title={<FormattedMessage {...messages.unarchiveOrder} />}
                    placement="top"
                >
                    <IconButton
                        disabled={isArchiving}
                        onClick={() => column.actionArchiveDelete(order)}
                        className={classes.iconButton}
                        size="small"
                    >
                        <UnarchiveIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={<FormattedMessage {...messages.viewLabels} />}
                    placement="top"
                >
                    <IconButton
                        onClick={() => column.actionHandleOpenLabel(order)}
                        size="small"
                        className={classes.iconButton}
                    >
                        <InsertDriveFileIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        )
    } else {
        if (isFulfilled) {
            return (
                <Grid item className={classes.buttonGrouping}>
                    <BookViewButton order={order} column={column} />
                    <Tooltip
                        title={<FormattedMessage {...messages.archiveOrder} />}
                        placement="top"
                    >
                        <IconButton
                            disabled={isArchiving}
                            onClick={() => column.actionArchiveDelete(order)}
                            className={classes.iconButton}
                        >
                            <ArchiveIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={<FormattedMessage {...messages.viewLabels} />}
                        placement="top"
                    >
                        <IconButton
                            onClick={() => column.actionHandleOpenLabel(order)}
                            className={classes.iconButton}
                        >
                            <InsertDriveFileIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )
        } else {
            return (
                <Grid item className={classes.buttonGrouping}>
                    <BookViewButton order={order} column={column} />
                    <Tooltip
                        title={<FormattedMessage {...messages.deleteOrder} />}
                        placement="right"
                    >
                        <IconButton
                            onClick={() => column.actionArchiveDelete(order)}
                            className={classes.iconButton}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )
        }
    }
}

export default OrderActionCellContent

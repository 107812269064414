import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React, { Fragment } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
    change,
    Field,
    FieldArray,
    FormSection,
    formValues,
    resetSection,
} from "redux-form"
import FormDatePicker from "../../form/form-datepicker"
import FormField from "../../form/form-field"
import FormSwitch from "../../form/form-switch"
import ManageSignature from "../ManageSignature"
import {
    methodOfQualificationOptions,
    methodOfQualificationNotApplicableOptions,
    methodOfQualificationNotApplicableList,
    certificationIndicatorOptions,
    countryOfOriginOptions,
    FormSwitchGroup,
    originCriterionOptions,
} from "./USMCAForm"
import ContactDetails, { contactDetailsFormMessages } from "../ContactDetails"
import { usmcaFormMessages } from "./messages"
import FormSelect from "../../form/form-select"
import { provideLocaleSortedOptionsList } from "../../../misc"
import formName from "../../util/form-name"
import { useDispatch } from "react-redux"
import { useGAContext } from "../../../context/providers/GoogleAnalyticsProvider"

const useStyles = makeStyles({
    mainTitle: {
        padding: "12px 0",
    },
    subTitle: {
        padding: "16px 0px 8px 0px",
    },
    formSection: {
        width: "100%",
    },
    signatureContainer: {
        paddingTop: "12px",
    },
})

const GoodItemPresentation = formValues("originCriterion")(
    formName(
        ({
            form,
            sectionPrefix,
            originCriterion,
            fields,
            index,
            gaCategory,
        }) => {
            const intl = useIntl()
            const dispatch = useDispatch()

            const onOriginCriterionChange = (event, newValue, oldValue) => {
                if (methodOfQualificationNotApplicableList.includes(newValue)) {
                    dispatch(
                        change(
                            form,
                            `${sectionPrefix}.methodOfQualification`,
                            "NO"
                        )
                    )
                } else if (
                    methodOfQualificationNotApplicableList.includes(oldValue)
                ) {
                    dispatch(
                        resetSection(
                            form,
                            `${sectionPrefix}.methodOfQualification`
                        )
                    )
                }
            }
            return (
                <Grid item container xs={12}>
                    <Grid item container md={4}>
                        <Field
                            name="description"
                            required
                            component={FormField}
                            label={
                                <FormattedMessage
                                    {...usmcaFormMessages.goodDescription}
                                />
                            }
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container md={4}>
                        <Field
                            name="harmonizedCode"
                            required
                            component={FormField}
                            label={
                                <FormattedMessage
                                    {...usmcaFormMessages.goodHarmonizedTariffSchedule}
                                />
                            }
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container md={4}>
                        <Field
                            name="originCriterion"
                            required
                            component={FormSelect}
                            options={originCriterionOptions}
                            onChange={onOriginCriterionChange}
                            label={
                                <FormattedMessage
                                    {...usmcaFormMessages.goodOriginCriterion}
                                />
                            }
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container md={4}>
                        <Field
                            component={FormSelect}
                            name="certificationIndicator"
                            options={certificationIndicatorOptions}
                            required
                            label={
                                <FormattedMessage
                                    {...usmcaFormMessages.goodCertificationIndicator}
                                />
                            }
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container md={4}>
                        <Field
                            name="methodOfQualification"
                            required
                            component={FormSelect}
                            disabled={methodOfQualificationNotApplicableList.includes(
                                originCriterion
                            )}
                            options={
                                methodOfQualificationNotApplicableList.includes(
                                    originCriterion
                                )
                                    ? methodOfQualificationNotApplicableOptions
                                    : methodOfQualificationOptions
                            }
                            label={
                                <FormattedMessage
                                    {...usmcaFormMessages.goodMethodOfQualification}
                                />
                            }
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container md={4}>
                        <Field
                            name="countryOfOrigin"
                            required
                            component={FormSelect}
                            options={provideLocaleSortedOptionsList(
                                intl,
                                countryOfOriginOptions,
                                ["US", "CA", "MX"]
                            )}
                            label={
                                <FormattedMessage
                                    {...usmcaFormMessages.goodCountryOfOrigin}
                                />
                            }
                            category={gaCategory}
                        />
                    </Grid>
                </Grid>
            )
        }
    )
)

const GoodListPresentation = ({ form, fields, canAddGoods, ...props }) => (
    <Fragment>
        {fields.map((prefix, index) => (
            <FormSection name={prefix} key={prefix} component={Fragment}>
                <GoodItemPresentation
                    {...props}
                    fields={fields}
                    index={index}
                />
            </FormSection>
        ))}
    </Fragment>
)

const USMCAFormFinalize = formValues({ usmca: "usmca" })(
    ({ usmca = {}, gaCategory }) => {
        const classes = useStyles()
        const logGAEvent = useGAContext()
        const { producerInfo, exporterInfo, importerInfo } = usmca

        return (
            <FormSection name="usmca" component={Fragment}>
                <Grid item container spacing={2}>
                    <Grid
                        item
                        container
                        justify="center"
                        alignItems="center"
                        className={classes.mainTitle}
                    >
                        <Typography variant="h6">
                            <FormattedMessage
                                id="attachmentOptions__USMCA"
                                defaultMessage="USMCA / CUSMA / ACEUM / T-MEC"
                            />
                        </Typography>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid
                            item
                            container
                            justify="center"
                            className={classes.subTitle}
                        >
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="usmca.general__information__title"
                                    defaultMessage="General Information"
                                />
                            </Typography>
                        </Grid>
                        <Grid item container xs={6}>
                            <Field
                                name="blanketPeriodFrom"
                                component={FormDatePicker}
                                type="text"
                                dateFormat="ll"
                                noMinDate
                                label={
                                    <FormattedMessage
                                        id="usmca.blanket__period__from"
                                        defaultMessage="Blanket period from"
                                    />
                                }
                                required
                                category={gaCategory}
                            />
                        </Grid>
                        <Grid item container xs={6}>
                            <Field
                                name="blanketPeriodTo"
                                component={FormDatePicker}
                                type="text"
                                dateFormat="ll"
                                noMinDate
                                label={
                                    <FormattedMessage
                                        id="usmca.blanket__period__to"
                                        defaultMessage="Blanket period to"
                                    />
                                }
                                required
                                category={gaCategory}
                            />
                        </Grid>
                    </Grid>
                    <FormSection
                        name="certifierInfo"
                        component={Grid}
                        className={classes.formSection}
                    >
                        <Grid
                            item
                            container
                            justify="center"
                            className={classes.subTitle}
                        >
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="usmca.certifier__title"
                                    defaultMessage="Certifier"
                                />
                            </Typography>
                        </Grid>
                        <Grid item container>
                            <ContactDetails
                                includeContact
                                gaCategory={`${gaCategory} : Certifier`}
                            />
                        </Grid>
                    </FormSection>
                    <FormSection
                        name="producerInfo"
                        component={Grid}
                        className={classes.formSection}
                    >
                        <Grid
                            item
                            container
                            justify="center"
                            className={classes.subTitle}
                        >
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="usmca.producer__title"
                                    defaultMessage="Producer"
                                />
                            </Typography>
                        </Grid>
                        <Grid item container>
                            <FormSwitchGroup
                                optionsList={[
                                    {
                                        name: "variousProducers",
                                        label: (
                                            <FormattedMessage
                                                id="usmca.various__producers"
                                                defaultMessage="Various"
                                            />
                                        ),
                                    },
                                    {
                                        name: "availableUponRequest",
                                        label: (
                                            <FormattedMessage
                                                id="usmca.available__upon__request"
                                                defaultMessage="Available upon request"
                                            />
                                        ),
                                    },
                                    {
                                        name: "sameAsCertifier",
                                        label: (
                                            <FormattedMessage
                                                id="usmca.same__as__certifier"
                                                defaultMessage="Same as Certifier"
                                            />
                                        ),
                                    },
                                ]}
                                sizeOverride={4}
                                gaCategory={`${gaCategory} : Certifier`}
                            />
                        </Grid>

                        {!producerInfo?.variousProducers &&
                            !producerInfo?.availableUponRequest &&
                            !producerInfo?.sameAsCertifier && (
                                <Grid item container>
                                    <ContactDetails
                                        includeContact
                                        gaCategory={`${gaCategory} : Producer`}
                                    />
                                </Grid>
                            )}
                    </FormSection>
                    <FormSection
                        name="exporterInfo"
                        component={Grid}
                        className={classes.formSection}
                    >
                        <Grid
                            item
                            container
                            justify="center"
                            className={classes.subTitle}
                        >
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="usmca.exporter__title"
                                    defaultMessage="Exporter"
                                />
                            </Typography>
                        </Grid>
                        <Grid item container>
                            <FormSwitchGroup
                                optionsList={[
                                    {
                                        name: "unknown",
                                        label: (
                                            <FormattedMessage
                                                id="usmca.party__is__unknown"
                                                defaultMessage="Unknown"
                                            />
                                        ),
                                    },
                                    {
                                        name: "sameAsCertifier",
                                        label: (
                                            <FormattedMessage
                                                id="usmca.same__as__certifier"
                                                defaultMessage="Same as Certifier"
                                            />
                                        ),
                                    },
                                ]}
                                sizeOverride={6}
                                gaCategory={`${gaCategory} : Exporter`}
                            />
                            {!exporterInfo?.unknown &&
                                !exporterInfo?.sameAsCertifier && (
                                    <Grid item container>
                                        <ContactDetails
                                            includeContact
                                            gaCategory={`${gaCategory} : Exporter`}
                                        />
                                    </Grid>
                                )}
                        </Grid>
                    </FormSection>
                    <FormSection
                        name="importerInfo"
                        component={Grid}
                        className={classes.formSection}
                    >
                        <Grid
                            item
                            container
                            justify="center"
                            className={classes.subTitle}
                        >
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="usmca.importer__title"
                                    defaultMessage="Importer"
                                />
                            </Typography>
                        </Grid>
                        <Grid item container>
                            <Field
                                component={FormSwitch}
                                name="unknown"
                                label={
                                    <FormattedMessage
                                        id="usmca.party__is__unknown"
                                        defaultMessage="Unknown"
                                    />
                                }
                                category={`${gaCategory} : Importer`}
                            />
                        </Grid>
                        {!importerInfo?.unknown && (
                            <Grid item container>
                                <ContactDetails
                                    includeContact
                                    gaCategory={`${gaCategory} : Importer`}
                                />
                            </Grid>
                        )}
                    </FormSection>
                    <Grid
                        container
                        justify="center"
                        className={classes.subTitle}
                    >
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="usmca.goods__title"
                                defaultMessage="Goods"
                            />
                        </Typography>
                    </Grid>
                    <Grid container>
                        <FieldArray
                            name="goods"
                            component={GoodListPresentation}
                            gaCategory={gaCategory}
                        />
                    </Grid>
                    <FormSection
                        name="signatureInfo"
                        component={Grid}
                        className={classes.formSection}
                    >
                        <Grid
                            item
                            container
                            justify="center"
                            className={classes.subTitle}
                        >
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="usmca.signature__title"
                                    defaultMessage="Signature"
                                />
                            </Typography>
                        </Grid>
                        <Grid item container>
                            <Grid item container xs={12}>
                                <Grid item container xs={6}>
                                    <Field
                                        name="companyName"
                                        component={FormField}
                                        type="text"
                                        label={
                                            <FormattedMessage
                                                {...contactDetailsFormMessages.companyName}
                                            />
                                        }
                                        required
                                        category={`${gaCategory} : Signature`}
                                    />
                                </Grid>
                                <Grid item container xs={6}>
                                    <Field
                                        name="contactName"
                                        component={FormField}
                                        type="text"
                                        label={
                                            <FormattedMessage
                                                {...contactDetailsFormMessages.contactName}
                                            />
                                        }
                                        required
                                        category={`${gaCategory} : Signature`}
                                    />
                                </Grid>
                                <Grid item container xs={6}>
                                    <Field
                                        name="title"
                                        component={FormField}
                                        type="text"
                                        label={
                                            <FormattedMessage
                                                {...usmcaFormMessages.signatureTitle}
                                            />
                                        }
                                        required
                                        category={`${gaCategory} : Signature`}
                                    />
                                </Grid>

                                <Grid item container xs={6}>
                                    <Field
                                        name="phone.phone_number"
                                        component={FormField}
                                        type="text"
                                        label={
                                            <FormattedMessage
                                                {...contactDetailsFormMessages.phone}
                                            />
                                        }
                                        required
                                        category={`${gaCategory} : Signature`}
                                    />
                                </Grid>
                                <Grid item container xs={6}>
                                    <Field
                                        name="phone.extension"
                                        component={FormField}
                                        type="text"
                                        label={
                                            <FormattedMessage
                                                {...contactDetailsFormMessages.extension}
                                            />
                                        }
                                        category={`${gaCategory} : Signature`}
                                    />
                                </Grid>
                                <Grid item container xs={6}>
                                    <Field
                                        name="email.email_address"
                                        component={FormField}
                                        type="text"
                                        label={
                                            <FormattedMessage
                                                {...contactDetailsFormMessages.email}
                                            />
                                        }
                                        required
                                        category={`${gaCategory} : Signature`}
                                    />
                                </Grid>
                                <Grid item container xs={6}>
                                    <Field
                                        name="date"
                                        component={FormDatePicker}
                                        type="text"
                                        dateFormat="ll"
                                        noMinDate
                                        label={
                                            <FormattedMessage
                                                {...usmcaFormMessages.signatureDate}
                                            />
                                        }
                                        required
                                        category={`${gaCategory} : Signature`}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                className={classes.signatureContainer}
                                xs={6}
                            >
                                <ManageSignature
                                    fieldName="signatureImage"
                                    previewDimensions={{
                                        width: 200,
                                        height: 25,
                                    }}
                                    addTitle={
                                        <FormattedMessage
                                            id="locations.signatureManagment__addSignature"
                                            defaultMessage="Add Signature"
                                        />
                                    }
                                    replaceTitle={
                                        <FormattedMessage
                                            id="locations.signatureManagment__replaceSignature"
                                            defaultMessage="Replace Signature"
                                        />
                                    }
                                    gaCategory={gaCategory}
                                />
                            </Grid>
                        </Grid>
                    </FormSection>
                </Grid>
            </FormSection>
        )
    }
)

export default USMCAFormFinalize

import React, { forwardRef, useEffect, useState } from "react"
import {
    Button,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    withStyles,
} from "@material-ui/core"
import { FormattedMessage, useIntl } from "react-intl"
import { change, Field } from "redux-form"
import formSelectAutocompleteNew from "../form/form-select-autocomplete"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { useOrdersContext } from "../../context/providers/OrdersProvider"
import { useSnackbarContext } from "../../context/providers/snackbarProvider"
import { connect, useDispatch, useSelector } from "react-redux"
import InfoIcon from "@material-ui/icons/Info"
import CloseIcon from "@material-ui/icons/Close"
import { modeDictionary } from "../redesign/bookShipment/atoms/modeOptions"
import GlobalSpinner from "../common/GlobalSpinner"
import { constructRequestPayloads } from "../util/batchRating"
import { goFetchV2 } from "../../http"
import { useFlags } from "launchdarkly-react-client-sdk"
import { isPresentMatchingErrors } from "../util/ordersUtils"

const styles = theme => ({
    paper: {
        position: "absolute",
        left: 0,
        right: 0,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        top: 0,
        bottom: 0,
        width: "80%",
        height: "auto",
        maxHeight: "80%",
        marginTop: "auto",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "auto",
    },

    uploadButton: {
        marginLeft: "10px",
    },

    orderMappingTable: {
        flex: "1",
        overflowY: "scroll",
    },

    modalContentContainer: {
        height: "100%",
    },

    buttonContainer: {
        padding: "24px 0 0 0",
    },
})

const ImportMappingModal = forwardRef((props, ref) => {
    const {
        setImportMappingModal,
        classes,
        logGAEvent,
        formValues,
        setImportModalOpen,
        rateRequestValuesFromState,
    } = props
    const { ordersPhaseTwo } = useFlags()
    const [isLoading, setIsLoading] = useState(false)
    const {
        orderMappingData,
        createOrders,
        setErrors,
        setIsFetchingRates,
        markBulkOrdersAsUnfulfilled,
        handleOrderSearch,
        setOrderStatus,
    } = useOrdersContext()
    const intl = useIntl()
    const { openSnackbar } = useSnackbarContext()
    const dispatch = useDispatch()
    const [availableOptions, setAvailableOptions] = useState([])
    const [csvOrderMappings, setCsvOrderMappings] = useState([])
    const {
        mode,
        billToLocation: { label, subLabel },
        orderMappingFields,
    } = formValues

    useEffect(() => {
        if (orderMappingData) {
            // Create a shallow copy of the orderColumnMappings array
            const updatedOrderColumnMappings = [
                ...orderMappingData.orderColumnMappings,
            ]

            // Dispatch actions for each item
            updatedOrderColumnMappings.forEach(item => {
                dispatch(
                    change(
                        "orders",
                        `orderMappingFields.${item.userInputHeader}`,
                        item.associatedField
                    )
                )
            })

            // default values if found null
            const defaultValues = {
                stackable: "TRUE",
                inbond_shipment: "FALSE",
                preferred_currency: "USD",
                preferred_measurement: "IMPERIAL",
            }

            updatedOrderColumnMappings.forEach(item => {
                if (
                    item.firstRowData == null &&
                    defaultValues.hasOwnProperty(item.userInputHeader)
                ) {
                    item.firstRowData = defaultValues[item.userInputHeader]
                }
            })

            // Update the original object with the modified array
            setCsvOrderMappings(updatedOrderColumnMappings)
            setAvailableOptions(
                orderMappingData.orderFieldOptions.map(item => ({
                    label: item.title,
                    value: item.internalName,
                    multiSelectable: item.multiSelectable,
                    required: item.required,
                }))
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderMappingData])
    const handleCancelOrderUpload = () => {
        logGAEvent("Orders", "Cancel order upload")
        setImportMappingModal(false)
    }

    const errorsAndWarningsToMatch = [
        `${intl.formatMessage({
            id: "book.capLoad__dialogContent.first",
            defaultMessage:
                "This shipment could incur additional charges based on the size and your account-specific rates. Please contact Volume Services at 1.888.465.5646 to understand the precise cost of your shipment. For more information: ",
        })}`,
        `${intl.formatMessage({
            id: "rateResults__extremeLengthWarning",
            defaultMessage:
                "This shipment could incur additional charges based on the handling unit length and your account-specific rates.",
        })}`,
    ]

    const handleCreateOrders = async () => {
        const formattedOrderMappings = []

        for (let mapping of csvOrderMappings) {
            if (orderMappingFields[mapping.userInputHeader]) {
                formattedOrderMappings.push({
                    userInputHeader: mapping.userInputHeader,
                    associatedField:
                        orderMappingFields[mapping.userInputHeader],
                })
            }
        }

        const createOrdersObj = {
            fileReference: orderMappingData.fileReference,
            orderColumnMappings: formattedOrderMappings,
        }

        setIsLoading(true)
        // let createdOrders = null
        try {
            const createdOrders = await createOrders(createOrdersObj)
            setImportMappingModal(false)
            setErrors([])
            logGAEvent(
                "Orders",
                "Import Mapping Modal Success",
                createdOrders?.data?.totalRows
            )
            if (ordersPhaseTwo && createdOrders?.data?.orders?.length > 0) {
                setIsFetchingRates(true)
                const payloads = constructRequestPayloads(
                    createdOrders?.data?.orders,
                    rateRequestValuesFromState
                )

                const promises = payloads.map(payload =>
                    goFetchV2(
                        "/quotes",
                        {
                            method: "POST",
                            credentials: "same-origin",
                            headers: {
                                "cache-control": "no-cache",
                            },
                            data: payload,
                        },
                        true
                    )
                )
                const fileToUnfulfilledOrders = []
                let newOrderStatus
                Promise.allSettled(promises)
                    .then(async results => {
                        results
                            .filter(result => result.status === "fulfilled")
                            .forEach((result, index) => {
                                const ratesErrors =
                                    result?.value?.data?.result?.ratesError
                                if (
                                    isPresentMatchingErrors(
                                        ratesErrors,
                                        errorsAndWarningsToMatch
                                    )
                                ) {
                                    fileToUnfulfilledOrders.push(
                                        result.value.data.search.associatedOrder
                                    )
                                }
                            })
                        if (fileToUnfulfilledOrders.length > 0) {
                            await markBulkOrdersAsUnfulfilled(
                                fileToUnfulfilledOrders
                            )
                        }
                        if (
                            !results.some(
                                result =>
                                    result.status === "fulfilled" &&
                                    result.value?.data?.result?.rateQuotes
                                        ?.length >= 0
                            )
                        ) {
                            setOrderStatus("actionRequired")
                            newOrderStatus = "actionRequired"
                            openSnackbar(
                                "info",
                                <FormattedMessage
                                    id="orders__uploadSuccessNoRates"
                                    defaultMessage="{totalRows} Order{s} Successfully Imported But No Rates Returned"
                                    values={{
                                        totalRows:
                                            createdOrders?.data?.totalRows,
                                        s:
                                            createdOrders?.data?.totalRows > 1
                                                ? "s"
                                                : "",
                                    }}
                                />,
                                3000
                            )
                        } else {
                            setOrderStatus("readyToBook")
                            newOrderStatus = "readyToBook"
                            openSnackbar(
                                "success",
                                <FormattedMessage
                                    id="orders__uploadSuccess"
                                    defaultMessage="{totalRows} Order{s} Successfully Imported"
                                    values={{
                                        totalRows:
                                            createdOrders?.data?.totalRows,
                                        s:
                                            createdOrders?.data?.totalRows > 1
                                                ? "s"
                                                : "",
                                    }}
                                />,
                                3000
                            )
                        }
                    })
                    .finally(async () => {
                        await handleOrderSearch("", newOrderStatus)
                        setIsFetchingRates(false)
                    })
            }
        } catch (error) {
            setImportMappingModal(false)
            setImportModalOpen(true)
            setErrors(error?.response?.errors ?? [])
            logGAEvent("Orders", "Import Mapping Modal Error")
        } finally {
            setIsLoading(false)
        }
    }

    const validatorFunction = selectedOption => {
        for (let mapping of csvOrderMappings) {
            if (
                selectedOption === orderMappingFields[mapping.userInputHeader]
            ) {
                for (let option of availableOptions) {
                    if (
                        selectedOption === option.value &&
                        !option.multiSelectable
                    ) {
                        return dispatch(
                            change(
                                "orders",
                                `orderMappingFields.${mapping.userInputHeader}`,
                                ""
                            )
                        )
                    }
                }
            }
        }
    }

    return (
        <Grid container item className={classes.paper}>
            {isLoading ? <GlobalSpinner /> : null}
            <Grid
                item
                container
                direction="column"
                className={classes.modalContentContainer}
            >
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignContent="flex-start"
                >
                    <Grid
                        item
                        container
                        className={classes.title}
                        direction="column"
                    >
                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="h5">
                                <FormattedMessage
                                    id="orders.importMappingTitle"
                                    defaultMessage="Order Column Mapping"
                                />
                            </Typography>
                            <IconButton onClick={handleCancelOrderUpload}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <Typography variant="subtitle1">{label}</Typography>
                        <Typography variant="caption">{subLabel}</Typography>
                        <Typography variant="caption">
                            {modeDictionary[mode]}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item container className={classes.orderMappingTable}>
                    <Table
                        size="medium"
                        aria-label="Orders Import Field Mapping Table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="subtitle1">
                                        <FormattedMessage
                                            id="orders.headerMappingColumnTitleDescription"
                                            defaultMessage="Your CSV column title"
                                        />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Grid item container>
                                        <Typography variant="subtitle1">
                                            <FormattedMessage
                                                id="orders.headerMappingInfoDescription"
                                                defaultMessage="Which order detail is this?"
                                            />
                                        </Typography>
                                        <Tooltip
                                            title={
                                                <FormattedMessage
                                                    id="orders.descriptiveText"
                                                    defaultMessage="Review whether the uploaded CSV columns map to the intended order details and then press “Create Orders”. Your selections will be remembered for future imports."
                                                />
                                            }
                                            placement="top"
                                        >
                                            <InfoIcon />
                                        </Tooltip>
                                    </Grid>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1">
                                        <FormattedMessage
                                            id="orders.firstDataSetofCSVDescription"
                                            defaultMessage="First data entry of your CSV"
                                        />
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {csvOrderMappings.map((item, index) => (
                                <TableRow
                                    key={`${item.userInputHeader}-${index}`}
                                >
                                    <TableCell>
                                        <Typography variant="subtitle1">
                                            {item.userInputHeader}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            component={
                                                formSelectAutocompleteNew
                                            }
                                            name={`orderMappingFields.${item.userInputHeader}`}
                                            options={availableOptions}
                                            onChange={selectedOption =>
                                                validatorFunction(
                                                    selectedOption
                                                )
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {item.firstRowData}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>

                <Grid
                    item
                    container
                    className={classes.buttonContainer}
                    justifyContent="flex-end"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        htmlFor="contained-button-file"
                        component="label"
                        className={classes.uploadButton}
                        onClick={handleCreateOrders}
                    >
                        <FormattedMessage
                            id="orders.import.createOrders"
                            defaultMessage="Create Orders"
                        />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
})

const mapStateToProps = state => {
    return {
        formValues: state?.form?.orders?.values,
        rateRequestValuesFromState: {
            search: state?.search,
            profile: state?.user?.profile,
        },
    }
}

export default withStyles(styles)(
    connect(mapStateToProps, null, null, { forwardRef: true })(
        ImportMappingModal
    )
)

import React, { useEffect, useState } from "react"
import { Box, Button, Typography } from "@material-ui/core"
import GlobalSpinner from "../common/GlobalSpinner"
import ContactCard from "./ContactCard"
import PaginationNav from "./PaginationNav"
import ContactSearch from "./ContactSearch"
import { goFetch } from "../../http"
import { useSnackbarContext } from "../../context/providers/snackbarProvider"
import { FormattedMessage } from "react-intl"
import { contactsSearch } from "../dashboard/search/util"
import ContactEditModal from "./ContactEditModal"
import ContactAddModal from "./ContactAddModal"
import ImportCSVModal from "./ImportContactModal"
import { useAccessorialsContext } from "../../context/providers/AccessorialsProvider"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"
import { useFlags } from "launchdarkly-react-client-sdk"

const GACategory = "Contacts"

const ContactsPage = () => {
    const { openSnackbar } = useSnackbarContext()
    const { logGAEvent } = useGAContext()
    const { getAccessorialsList } = useAccessorialsContext()
    const { errorBoundaryForcedError } = useFlags()

    const [isLoaded, setLoaded] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [contacts, setContacts] = useState([])
    const [filteredContacts, setFilteredContacts] = useState([])
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [addModalOpen, setAddModalOpen] = useState(false)
    const [importModalOpen, setImportModalOpen] = useState(false)
    const [editContact, setEditContact] = useState([])
    const [searchValue, setSearchValue] = useState("")

    useEffect(() => {
        if (errorBoundaryForcedError) {
            //This is used to test the ErrorBoundary in the lower environments.
            throw Error("Fake error to test the error boundary.")
        }

        const fetchData = async () => {
            setLoaded(false)
            await fetchContacts()
            setLoaded(true)
        }
        fetchData()
        getAccessorialsList()
        logGAEvent(GACategory, "Page Mount")
    }, [])

    const addContactToList = newContact => {
        const newContactList = [...contacts, newContact]
        setContacts(newContactList)
        setFilteredContacts(contactsSearch(searchValue, newContactList))
    }

    const replaceContact = updatedContact => {
        const contactsWithRemoval = contacts.filter(
            contact => contact?.contact?._id !== updatedContact?.contact?._id
        )
        const updatedContactList = [...contactsWithRemoval, updatedContact]
        setContacts(updatedContactList)
        setFilteredContacts(contactsSearch(searchValue, updatedContactList))
    }

    const addModalAction = actionIsOpen => {
        if (actionIsOpen) {
            logGAEvent(GACategory, "Add button Click")
            setAddModalOpen(true)
        } else {
            logGAEvent(GACategory, "Add Modal Closed")
            setAddModalOpen(false)
        }
    }

    const editModalAction = actionIsOpen => {
        if (actionIsOpen) {
            logGAEvent(GACategory, "Edit Contact button Click")
            setEditModalOpen(true)
        } else {
            logGAEvent(GACategory, "Edit Contact Modal Closed")
            setEditModalOpen(false)
        }
    }

    const importModalAction = actionIsOpen => {
        if (actionIsOpen) {
            logGAEvent(GACategory, "Import button Click")
            setImportModalOpen(true)
        } else {
            logGAEvent(GACategory, "Import Modal Closed")
            setImportModalOpen(false)
        }
    }

    const fetchContacts = async () => {
        try {
            const { data } = await goFetch("/addresses/contacts", {}, true)
            setContacts(data)
            setFilteredContacts(contactsSearch(searchValue, data))
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="contacts_fetch_error"
                    defaultMessage="Error fetching contacts"
                />,
                4000
            )
        }
    }

    const deleteContact = async id => {
        logGAEvent(GACategory, "Delete Contact Click")
        try {
            await goFetch(
                `addresses/contact/${`${id}`}`,
                {
                    method: "DELETE",
                    credentials: "same-origin",
                    headers: { "cache-control": "no-cache" },
                },
                true
            )
            openSnackbar(
                "success",
                <FormattedMessage
                    id="contacts_delete_success"
                    defaultMessage="Contact deleted"
                />,
                4000
            )
            setContacts(contacts.filter(contact => contact.contact?._id !== id))
            setFilteredContacts(
                filteredContacts.filter(contact => contact.contact?._id !== id)
            )
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="contacts_delete_error"
                    defaultMessage="Error deleting contact"
                />,
                4000
            )
        }
    }

    return (
        <Box sx={{ padding: "2% 4%" }}>
            {!isLoaded ? <GlobalSpinner /> : null}
            <ContactEditModal
                replaceContact={replaceContact}
                setOpen={editModalAction}
                open={editModalOpen}
                contact={editContact}
                gaCategory={GACategory}
                logGAEvent={logGAEvent}
            />
            <ContactAddModal
                setOpen={addModalAction}
                open={addModalOpen}
                addContactToList={addContactToList}
                gaCategory={GACategory}
                logGAEvent={logGAEvent}
            />
            <ImportCSVModal
                open={importModalOpen}
                setOpen={importModalAction}
                gaCategory={GACategory}
                logGAEvent={logGAEvent}
            />
            <Box
                sx={{
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Typography variant="h4">
                    <FormattedMessage
                        id="contacts.title"
                        defaultMessage="Contacts"
                    />
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Button
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            importModalAction(true)
                        }}
                        variant="outlined"
                        color="primary"
                    >
                        <FormattedMessage
                            id="generalTerms__import"
                            defaultMessage="Import"
                        />
                    </Button>
                    <Button
                        onClick={() => addModalAction(true)}
                        variant="contained"
                        color="primary"
                    >
                        <FormattedMessage
                            id="generalTerms__add"
                            defaultMessage="Add"
                        />
                    </Button>
                </Box>
            </Box>
            <Box
                sx={{
                    marginBottom: "20px",
                }}
            >
                <ContactSearch
                    setFilteredContacts={setFilteredContacts}
                    contacts={contacts}
                    setCurrentPage={setCurrentPage}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    gaCategory={GACategory}
                    logGAEvent={logGAEvent}
                />
            </Box>
            <Box>
                {filteredContacts
                    .slice(
                        currentPage * pageSize - pageSize,
                        currentPage * pageSize
                    )
                    .map((contact, index) => {
                        return (
                            <ContactCard
                                deleteContact={deleteContact}
                                contact={contact}
                                key={`contact-${index + 1}`}
                                setEditModalOpen={editModalAction}
                                setEditContact={setEditContact}
                            />
                        )
                    })}
                <PaginationNav
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    totalItems={filteredContacts.length}
                    pageSize={pageSize}
                    currentPage={currentPage}
                />
            </Box>
        </Box>
    )
}

export default ContactsPage

import { toast } from "react-toastify"
import { goFetch } from "../http"
import { createGAEvent } from "./index"
import { closeModal } from "./modal"
import { emailSendError } from "../messages/error-constants"

export const EMAIL_CONTACT_FIELD_CHANGE = "EMAIL_CONTACT_FIELD_CHANGE"
export const EDIT_EMAIL_CONTACT = "EDIT_EMAIL_CONTACT"
export const EMAIL_CONTACT_SELECT = "EMAIL_CONTACT_SELECT"
export const SAVE_EMAIL_CONTACT = "SAVE_EMAIL_CONTACT"
export const REMOVE_EMAIL_CONTACT = "REMOVE_EMAIL_CONTACT"
export const IMAGE_EMAIL_SENDING = "IMAGE_EMAIL_SENDING"
export const IMAGE_EMAIL_SENT = "IMAGE_EMAIL_SENT"

export const emailContactFieldChange = (field, value, index) => ({
    type: EMAIL_CONTACT_FIELD_CHANGE,
    field,
    value,
    index,
})

export const editEmailContact = index => ({ type: EDIT_EMAIL_CONTACT, index })
export const selectEmailContact = (contact, index) => ({
    type: EMAIL_CONTACT_SELECT,
    contact,
    index,
})
export const saveEmailContact = index => ({ type: SAVE_EMAIL_CONTACT, index })
export const removeEmailContact = index => ({
    type: REMOVE_EMAIL_CONTACT,
    index,
})

function notifySuccess(shipmentId) {
    return dispatch => {
        toast.success("Email successfully sent.", {
            position: toast.POSITION.TOP_CENTER,
        })
        dispatch(
            createGAEvent("Shipment", "Image email sent", "", {
                shipment: shipmentId,
            })
        )
    }
}

function notifyError(shipmentId, error) {
    return dispatch => {
        toast.error(emailSendError, {
            position: toast.POSITION.TOP_CENTER,
        })
        dispatch(
            createGAEvent(
                "Shipment",
                "Image email send error",
                `Error: ${error}`,
                { shipment: shipmentId }
            )
        )
    }
}

export function sendEmail(shipmentId, selections) {
    return async (dispatch, getState) => {
        const { recipients } = getState().emailImage
        dispatch({ type: IMAGE_EMAIL_SENDING })
        try {
            await goFetch(
                `/documents/image/${shipmentId}/email`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        recipients: recipients
                            .map(({ id, name, email }) =>
                                id
                                    ? {
                                          id,
                                      }
                                    : {
                                          name,
                                          email,
                                      }
                            )
                            .filter(
                                ({ id, name, email }) => id || (name && email)
                            ),
                        selections,
                    },
                },
                true
            )
            dispatch(closeModal())
            dispatch(notifySuccess(shipmentId))
        } catch (error) {
            dispatch(notifyError(shipmentId, error))
        } finally {
            dispatch({ type: IMAGE_EMAIL_SENT })
        }
    }
}

import React from "react"
import { withStyles } from "@material-ui/styles"
import { Grid, Typography, Dialog } from "@material-ui/core"
import {
    updateCustomPuertoRicoPricing,
    resetCustomPricing,
} from "../../../../actions/offshoreRating"
import { connect } from "react-redux"
import { change, reduxForm } from "redux-form"
import { FormattedMessage } from "react-intl"
import NavigationDropdown from "./NavigationDropdown"
import { styles } from "./styles"
import PuertoRicoInitialValues from "./PuertoRicoInitialValues"
import PricingFormSimple from "./PricingFormSimple"
import PricingForm from "./PricingForm"

const PuertoRicoPricingModal = ({
    classes,
    open,
    setOpen,
    defaultPricing,
    customPricing,
    saveCustomPricing,
    resetCustomPricing,
    location,
    activeFilter,
    setActiveFilter,
    trackGA,
}) => {
    return (
        <Dialog maxWidth="lg" fullWidth={true} open={open}>
            <Grid className={classes.modal}>
                <Grid item container justifyContent="center">
                    <Grid item style={{ paddingBottom: "20px" }}>
                        <Typography variant="h5" align="center">
                            <FormattedMessage
                                id="locations.offShore_CustomDiscountsTitle"
                                defaultMessage="Enter your custom list rates and discounts"
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container justifyContent="flex-start" xs={12}>
                    <Grid item container xs={6}>
                        <NavigationDropdown
                            activeFilter={activeFilter}
                            setActiveFilter={setActiveFilter}
                            classes={classes}
                            offshoreState={"PR"}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        xs={6}
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        <Typography variant="h6" align="center">
                            <FormattedMessage
                                id="generalTerms__offshoreStatePR"
                                defaultMessage="Puerto Rico"
                            />
                        </Typography>
                    </Grid>
                </Grid>

                {activeFilter === "islandExtremeLengthCharges" ? (
                    <PricingForm
                        trackGA={trackGA}
                        offshoreLocation="Puerto Rico"
                        titleField={"zone"}
                        setOpen={setOpen}
                        defaultValues={
                            defaultPricing?.islandExtremeLengthCharges
                        }
                        customPricing={
                            customPricing?.islandExtremeLengthCharges
                        }
                        saveCustomPricing={saveCustomPricing}
                        resetCustomPricing={resetCustomPricing}
                        activeFilter={activeFilter}
                        location={location}
                        dataFields={[
                            {
                                label: (
                                    <FormattedMessage
                                        id="generalTerms.zone"
                                        defaultMessage="Zone"
                                    />
                                ),
                                ref: "zone",
                                type: "title",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="generalTerms.charge"
                                        defaultMessage="Charge"
                                    />
                                ),
                                ref: "charge",
                                type: "charge",
                            },
                        ]}
                    />
                ) : null}

                {activeFilter === "oceanExtremeLengthCharges" ? (
                    <PricingForm
                        trackGA={trackGA}
                        offshoreLocation="Puerto Rico"
                        titleField={"originCfs"}
                        classes={classes}
                        setOpen={setOpen}
                        defaultValues={
                            defaultPricing?.oceanExtremeLengthCharges
                        }
                        customPricing={customPricing?.oceanExtremeLengthCharges}
                        resetCustomPricing={resetCustomPricing}
                        saveCustomPricing={saveCustomPricing}
                        location={location}
                        activeFilter={activeFilter}
                        dataFields={[
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.originCFS"
                                        defaultMessage="Origin CFS"
                                    />
                                ),
                                ref: "originCfs",
                                type: "title",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.destinationCFS"
                                        defaultMessage="Destination CFS"
                                    />
                                ),
                                ref: "destinationCfs",
                                type: "title",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.minimumCharge"
                                        defaultMessage="Minimum Charge"
                                    />
                                ),
                                ref: "minimumCharge",
                                type: "charge",
                            },

                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.ratePerCuFt"
                                        defaultMessage="Rate per CuFt"
                                    />
                                ),
                                ref: "ratePerCuFt",
                                type: "rate",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.ratePerCwt"
                                        defaultMessage="Rate per CWT"
                                    />
                                ),
                                ref: "ratePerCwt",
                                type: "rate",
                            },
                        ]}
                    />
                ) : null}

                {activeFilter === "oceanCharges" ? (
                    <PricingForm
                        trackGA={trackGA}
                        offshoreLocation="Puerto Rico"
                        titleField={"gateway"}
                        classes={classes}
                        setOpen={setOpen}
                        defaultValues={defaultPricing?.oceanCharges}
                        customPricing={customPricing?.oceanCharges}
                        resetCustomPricing={resetCustomPricing}
                        saveCustomPricing={saveCustomPricing}
                        activeFilter={activeFilter}
                        location={location}
                        dataFields={[
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.gateway"
                                        defaultMessage="Gateway"
                                    />
                                ),
                                ref: "gateway",
                                type: "title",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.grossDiscount"
                                        defaultMessage="Gross Discount"
                                    />
                                ),
                                ref: "oceanGrossDiscount",
                                type: "discount",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.ratePerCuFt"
                                        defaultMessage="Rate per CuFt"
                                    />
                                ),
                                ref: "ratePerCuFt",
                                type: "rate",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.ratePerCwt"
                                        defaultMessage="Rate per CWT"
                                    />
                                ),
                                ref: "ratePerCwt",
                                type: "rate",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.minimumCharge"
                                        defaultMessage="Minimum Charge"
                                    />
                                ),
                                ref: "minimumCharge",
                                type: "charge",
                            },
                        ]}
                    />
                ) : null}

                {activeFilter === "absoluteMinimumCharge" ? (
                    <PricingFormSimple
                        trackGA={trackGA}
                        offshoreLocation="Puerto Rico"
                        setOpen={setOpen}
                        classes={classes}
                        defaultValues={defaultPricing?.absoluteMinimumCharge}
                        initialFormValues={customPricing?.absoluteMinimumCharge}
                        saveCustomPricing={saveCustomPricing}
                        resetCustomPricing={resetCustomPricing}
                        activeFilter={activeFilter}
                        location={location}
                        dataFields={[
                            {},
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.absoluteMinimumCharge"
                                        defaultMessage="Custom Absolute Minimum Charge"
                                    />
                                ),
                                ref: "charge",
                                type: "charge",
                            },

                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.grossDiscount"
                                        defaultMessage="Gross Discount"
                                    />
                                ),
                                ref: "grossPercentageDiscount",
                                type: "discount",
                            },
                        ]}
                    />
                ) : null}

                {activeFilter === "domesticDockDropFee" ? (
                    <PricingFormSimple
                        trackGA={trackGA}
                        offshoreLocation="Puerto Rico"
                        setOpen={setOpen}
                        classes={classes}
                        defaultValues={defaultPricing?.domesticDockDropFee}
                        initialFormValues={customPricing?.domesticDockDropFee}
                        saveCustomPricing={saveCustomPricing}
                        resetCustomPricing={resetCustomPricing}
                        activeFilter={activeFilter}
                        location={location}
                        dataFields={[
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.ratePerCwt"
                                        defaultMessage="Rate per CWT"
                                    />
                                ),
                                ref: "ratePerCwt",
                                type: "rate",
                            },

                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.minimumCharge"
                                        defaultMessage="Minimum Charge"
                                    />
                                ),
                                ref: "minimumCharge",
                                type: "charge",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.maximumCharge"
                                        defaultMessage="Maximum Charge"
                                    />
                                ),
                                ref: "maximumCharge",
                                type: "charge",
                            },
                        ]}
                    />
                ) : null}
                {activeFilter === "otherFees" ? (
                    <PricingFormSimple
                        trackGA={trackGA}
                        offshoreLocation="Puerto Rico"
                        setOpen={setOpen}
                        classes={classes}
                        defaultValues={{
                            documentationFee: defaultPricing?.documentationFee,
                            sedFee: defaultPricing?.sedFee,
                            hazMatFee: defaultPricing?.hazMatFee,
                        }}
                        initialFormValues={{
                            documentationFee: customPricing?.documentationFee,
                            sedFee: customPricing?.sedFee,
                            hazMatFee: customPricing?.hazMatFee,
                        }}
                        saveCustomPricing={saveCustomPricing}
                        resetCustomPricing={resetCustomPricing}
                        activeFilter={activeFilter}
                        location={location}
                        dataFields={[
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.documentationFee"
                                        defaultMessage="Documentation Fee"
                                    />
                                ),
                                ref: "documentationFee",
                                type: "charge",
                            },

                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.sedFee"
                                        defaultMessage="SED Fee"
                                    />
                                ),
                                ref: "sedFee",
                                type: "charge",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.hazMatFee"
                                        defaultMessage="Hazardous Materials Fee"
                                    />
                                ),
                                ref: "hazMatFee",
                                type: "charge",
                            },
                        ]}
                    />
                ) : null}
                {activeFilter === "customerPickupAndDeliveryCharge" ? (
                    <PricingFormSimple
                        trackGA={trackGA}
                        offshoreLocation="Puerto Rico"
                        setOpen={setOpen}
                        classes={classes}
                        defaultValues={
                            defaultPricing?.customerPickupAndDeliveryCharge
                        }
                        initialFormValues={
                            customPricing?.customerPickupAndDeliveryCharge
                        }
                        saveCustomPricing={saveCustomPricing}
                        resetCustomPricing={resetCustomPricing}
                        activeFilter={activeFilter}
                        location={location}
                        dataFields={[
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.ratePerCuFt"
                                        defaultMessage="Rate per CuFt"
                                    />
                                ),
                                ref: "ratePerCuFt",
                                type: "rate",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.ratePerCwt"
                                        defaultMessage="Rate per CWT"
                                    />
                                ),
                                ref: "ratePerCwt",
                                type: "rate",
                            },

                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.minimumCharge"
                                        defaultMessage="Minimum Charge"
                                    />
                                ),
                                ref: "minimumCharge",
                                type: "charge",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.grossDiscount"
                                        defaultMessage="Gross Discount"
                                    />
                                ),
                                ref: "grossPercentageDiscount",
                                type: "discount",
                            },
                        ]}
                    />
                ) : null}
            </Grid>
        </Dialog>
    )
}

const mapStateToProps = (state, props) => {
    return {
        formValues: state?.form?.offshoreDiscounts?.values,
        initialValues: PuertoRicoInitialValues(
            state?.form?.location?.initial?.customPuertoRicoPricing
        ),
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    resetCustomPricing: (locationId, activeFilter) =>
        dispatch(resetCustomPricing("puertoRico", locationId, activeFilter)),
    changeField: (field, value) =>
        dispatch(change("offshoreDiscounts", field, value)),
    saveCustomPricing: (updatedPricing, locationId) =>
        dispatch(
            updateCustomPuertoRicoPricing(
                updatedPricing,
                locationId,
                props?.activeFilter
            )
        ),
})

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "offshoreDiscounts", // a unique identifier for this form
            enableReinitialize: true,
        })(PuertoRicoPricingModal)
    )
)

import { Grid, Typography } from "@material-ui/core"
import { get } from "lodash"
import React from "react"

const OrderAddressPart = ({ data, title }) => {
    return data ? (
        <Grid item container xs={6} alignContent="flex-start">
            <Grid item container justify="flex-start" direction="row" xs={12}>
                <Grid item>
                    <Typography variant="caption">{title}</Typography>
                </Grid>
            </Grid>
            <Grid
                item
                container
                justify="flex-start"
                direction="column"
                xs={12}
            >
                <Grid item>
                    <Typography variant="body2">
                        {data.name
                            ? data.name
                            : data?.billingContact?.companyName}
                    </Typography>
                </Grid>
                <Grid item>
                    {get(data, "address.street1", "") !== "" && (
                        <Typography variant="body2">
                            {data.address.street1}
                        </Typography>
                    )}
                </Grid>
                <Grid item>
                    {get(data, "address", "") !== "" && (
                        <Typography variant="body2">
                            {data.address.city}, {data.address.state}{" "}
                            {data.address.postalCode}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    ) : null
}

export default OrderAddressPart

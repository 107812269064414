import React from "react"
import { Button, DialogActions, Typography } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { FormattedMessage, injectIntl } from "react-intl"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
    paper: {
        width: "500px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 2,
        backgroundColor: theme.palette.background.paper,
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
}))

const WarningReviewPUD = ({
    isDialogOpen,
    intl,
    cancelModal,
    proccedWithwarning,
    isPickUp = false,
    domesticOffshoreCustomerPud = false,
    containsPUDFacilityAccesorials = false,
}) => {
    const classes = useStyles()
    let accesorial
    let message
    if (containsPUDFacilityAccesorials) {
        accesorial = isPickUp
            ? intl.formatMessage({
                  id:
                      "getRates__accessorial__PUD__title_value_customerdockpickupOffshore",
                  defaultMessage: "Customer Dock Pickup at Offshore Facility",
              })
            : intl.formatMessage({
                  id:
                      "getRates__accessorial__PUD__title_value_customerdockdropOffshore",
                  defaultMessage: "Customer Dock Drop at Offshore Facility",
              })
        message = (
            <FormattedMessage
                id="getRates__accessorial__PUD__title_v1"
                defaultMessage="You have selected {value}.  FedEx is not responsible for the Offshore {mode} portion of this shipment."
                values={{
                    value: accesorial,
                    mode: !isPickUp
                        ? intl.formatMessage({
                              id:
                                  "getRates__accessorial__PUD__title_value_pickup",
                              defaultMessage: "pickup",
                          })
                        : intl.formatMessage({
                              id:
                                  "getRates__accessorial__PUD__title_value_delivery",
                              defaultMessage: "delivery",
                          }),
                }}
            />
        )
    } else {
        if (!domesticOffshoreCustomerPud) {
            accesorial = isPickUp
                ? intl.formatMessage({
                      id:
                          "getRates__accessorial__PUD__title_value_customerpickup",
                      defaultMessage: "Customer Pickup",
                  })
                : intl.formatMessage({
                      id:
                          "getRates__accessorial__PUD__title_value_customerdelivery",
                      defaultMessage: "Customer Delivery",
                  })
            message = (
                <FormattedMessage
                    id="getRates__accessorial__PUD__title"
                    defaultMessage="You have selected {value}. FedEx is not responsible for the {mode} of this shipment."
                    values={{
                        value: accesorial,
                        mode: !isPickUp
                            ? intl.formatMessage({
                                  id:
                                      "getRates__accessorial__PUD__title_value_pickup",
                                  defaultMessage: "pickup",
                              })
                            : intl.formatMessage({
                                  id:
                                      "getRates__accessorial__PUD__title_value_delivery",
                                  defaultMessage: "delivery",
                              }),
                    }}
                />
            )
        } else {
            accesorial = intl.formatMessage({
                id:
                    "getRates__accessorial__PUD__title_value_customerdockdropUSgateway",
                defaultMessage: "Customer Dock Drop at US Gateway",
            })

            message = (
                <FormattedMessage
                    id="getRates__accessorial__PUD__title_v2"
                    defaultMessage="You have selected {value}. FedEx is not responsible for the US Domestic portion of this shipment."
                    values={{
                        value: accesorial,
                    }}
                />
            )
        }
    }

    return (
        <Dialog
            open={isDialogOpen}
            classes={{
                paper: classes.paper,
            }}
        >
            <DialogTitle id="in-bond-dialog-title">
                <Grid container item direction="row">
                    <Grid item container xs={12} justifyContent="space-between">
                        <Grid item>
                            <Typography variant="subtitle1">
                                {message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={10}></Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container justifyContent="center" direction="row">
                    <Grid item container xs={12}>
                        <Grid item container></Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    item
                    container
                    alignItems="flex-end"
                    className={classes.buttonContainer}
                    justifyContent="space-between"
                >
                    <Button
                        id="cancelWarningDialog"
                        onClick={cancelModal}
                        color="secondary"
                        variant="outlined"
                    >
                        <FormattedMessage
                            id="generalTerms__cancel"
                            defaultMessage="Cancel"
                        />
                    </Button>
                    <Button
                        id="continueWarningDialog"
                        onClick={proccedWithwarning}
                        color="primary"
                        variant="contained"
                    >
                        <FormattedMessage
                            id="generalTerms__confirm"
                            defaultMessage="confirm"
                        />
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default injectIntl(WarningReviewPUD)

import React from "react"
import { Button, Typography, withStyles } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { Field, getFormValues } from "redux-form"
import FormField from "../../form/form-field"
import FormSwitch from "../../form/form-switch"
import { supportedCountryCodes } from "../../../misc"
import FormSelect from "../../form/form-select"
import FormSelectAutocomplete from "../../form/form-select-autocomplete"
import formZipCode from "../../form/form-zip-code"
import { connect } from "react-redux"
import { get } from "lodash"

const styles = theme => ({
    textField_right: {
        width: "100%",
    },
})

const ShipperLoadCount = props => {
    const {
        classes,
        dirty,
        handleSave,
        handleSubmit,
        cities = [],
        country,
        city,
        formValues,
        gaCategory,
    } = props

    const isEnableTrailerManifest =
        formValues?.shipperLoadCount?.isEnableTrailerManifest

    return (
        <Grid item container className={classes.location__card__header}>
            <Grid
                item
                container
                alignContent="flex-start"
                className={classes.location__card__header}
            >
                <Grid item container alignItems="left" xs={12}>
                    <Typography gutterBottom variant="h6">
                        <FormattedMessage
                            id="locations.shipperLoadCount__title"
                            defaultMessage="Shipper Load & Count"
                        />
                    </Typography>
                </Grid>
                <Grid item>
                    <Field
                        name={`shipperLoadCount.isEnableTrailerManifest`}
                        component={FormSwitch}
                        label={
                            <FormattedMessage
                                id="locations__enableTraierManifest"
                                defaultMessage="Enable Trailer Manifest"
                            />
                        }
                        category={gaCategory}
                    />
                </Grid>
            </Grid>
            {isEnableTrailerManifest && (
                <React.Fragment>
                    <Grid item container>
                        <Field
                            name="shipperLoadCount.companyName"
                            label={[
                                "* ",
                                <FormattedMessage
                                    id="generalTerms__companyName"
                                    defaultMessage="Company Name"
                                />,
                            ]}
                            component={FormField}
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container xs={6}>
                        <Field
                            name="shipperLoadCount.address.street1"
                            component={FormField}
                            type="text"
                            label={[
                                "* ",
                                <FormattedMessage
                                    id="generalTerms__address1"
                                    defaultMessage="Address 1"
                                />,
                            ]}
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container xs={6}>
                        <Field
                            name="shipperLoadCount.address.street2"
                            component={FormField}
                            type="text"
                            label={
                                <FormattedMessage
                                    id="generalTerms__address2"
                                    defaultMessage="Address 2"
                                />
                            }
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container xs={6}>
                        <Field
                            name="shipperLoadCount.address.postalCode"
                            component={formZipCode}
                            country={country}
                            type="text"
                            label={[
                                "* ",
                                <FormattedMessage
                                    id="generalTerms__zipPostalCode"
                                    defaultMessage="Zip/Postal Code"
                                />,
                            ]}
                            fieldPrefix="shipperLoadCount"
                            inputProps={{ maxLength: "7" }}
                            className={classes.textField_right}
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container xs={6}>
                        <Field
                            name="shipperLoadCount.address.city"
                            component={FormSelectAutocomplete}
                            customValue
                            label={[
                                "* ",
                                <FormattedMessage
                                    id="generalTerms__city__selectOrEnter"
                                    defaultMessage="City (Select or Enter)"
                                />,
                            ]}
                            options={[
                                { value: city, label: city },
                                ...(cities?.length > 0
                                    ? cities.map(value => ({
                                          value,
                                          label: value ?? "",
                                      }))
                                    : []),
                            ]}
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container xs={6}>
                        <Field
                            name="shipperLoadCount.address.state"
                            component={FormField}
                            type="text"
                            label={[
                                "* ",
                                <FormattedMessage
                                    id="generalTerms__stateProvince"
                                    defaultMessage="State/Province"
                                />,
                            ]}
                            disabled
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container xs={6}>
                        <Field
                            name="shipperLoadCount.address.country"
                            component={FormSelect}
                            label={[
                                "* ",
                                <FormattedMessage
                                    id="generalTerms__country"
                                    defaultMessage="Country"
                                />,
                            ]}
                            options={supportedCountryCodes}
                            maxLength="30"
                            required
                            category={gaCategory}
                        />
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    )
}
const mapStateToProps = (state, props) => {
    return {
        cities: get(
            state,
            `postalCode[${props.formValues?.shipperLoadCount?.address?.country}-${props.formValues?.shipperLoadCount?.address?.postalCode}].cities`,
            [props.city]
        ),
    }
}

export default withStyles(styles)(connect(mapStateToProps)(ShipperLoadCount))

import React, { Component, Fragment } from "react"
import { Chip, withStyles } from "@material-ui/core"
import { get } from "lodash"
import { FormattedMessage } from "react-intl"

const styles = {
    filterChip: {
        marginRight: "5px",
        marginTop: "1px",
        marginBottom: "1px",
    },
}

class CurrentlyAppliedFilters extends Component {
    handleDelete(filterCategory, filter) {
        const { resetFormField, applyDashboardFilters } = this.props

        resetFormField(`${filterCategory}.${filter}`, false)
        applyDashboardFilters()
    }

    render() {
        const { classes, dashboardFilters, locations } = this.props
        const locationFilterCpgs = get(dashboardFilters, "locationFilter", {})
        const carrierFilterCodes = get(dashboardFilters, "carrierFilter", {})
        const archiveFilters = dashboardFilters?.archiveFilter || {}

        return (
            <Fragment>
                {locations.map(loc => {
                    if (locationFilterCpgs[loc.cpgCode]) {
                        return (
                            <Chip
                                variant="outlined"
                                label={
                                    get(loc, "cpgName")
                                        ? loc.cpgName
                                        : get(loc, "shippingAddress.name")
                                }
                                color="primary"
                                className={classes.filterChip}
                            />
                        )
                    } else return null
                })}
                {Object.keys(carrierFilterCodes).map(carrier => {
                    if (carrierFilterCodes[carrier]) {
                        return (
                            <Chip
                                variant="outlined"
                                label={carrier}
                                color="primary"
                                className={classes.filterChip}
                            />
                        )
                    } else return null
                })}
                {Object.keys(archiveFilters).map(filter => {
                    switch (filter) {
                        case "viewArchivedShipments":
                            if (archiveFilters[filter]) {
                                return (
                                    <Chip
                                        variant="outlined"
                                        label={
                                            <FormattedMessage
                                                id="dashboard.filters.viewShipments"
                                                defaultMessage="Include Archived Shipments"
                                            />
                                        }
                                        color="primary"
                                        className={classes.filterChip}
                                    />
                                )
                            }
                        case "viewArchivedQuotes":
                            if (archiveFilters[filter]) {
                                return (
                                    <Chip
                                        variant="outlined"
                                        label={
                                            <FormattedMessage
                                                id="dashboard.filters.viewQuotes"
                                                defaultMessage="Include Archived Quotes"
                                            />
                                        }
                                        color="primary"
                                        className={classes.filterChip}
                                    />
                                )
                            }
                        default:
                            return null
                    }
                })}
            </Fragment>
        )
    }
}

export default withStyles(styles)(CurrentlyAppliedFilters)

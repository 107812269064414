import React, { useState } from "react"
import { Grid, Modal, Typography, Button } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import FileUpload from "../../util/file-upload"
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
} from "@material-ui/core"
import GlobalSpinner from "../../common/GlobalSpinner"
import { goFetch } from "../../../http"

const AdminUploadModal = ({
    classes,
    handleModal,
    uploadModalOpen,
    sampleFile,
    fetchData,
    urlPath,
}) => {
    const [importing, setImporting] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])

    const onCloseModal = () => {
        handleModal(false)
        setErrorMessages([])
    }

    const handleImport = async file => {
        setImporting(true)
        setErrorMessages([])
        try {
            const formData = new FormData()
            formData.append("file", file)
            const { data: result } = await goFetch(
                `/admin/freightdirect/${urlPath}`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: formData,
                },
                true
            )
            if (result.errorMessages && result.errorMessages.length) {
                setErrorMessages(result.errorMessages)
            } else {
                handleModal(false)
                fetchData()
            }
        } catch (error) {
            setErrorMessages([error])
        }
        setImporting(false)
    }

    return (
        <Modal open={uploadModalOpen} onClose={() => handleModal(false)}>
            <div className={classes.modal__container}>
                <IconButton
                    size="small"
                    className={classes.button__close}
                    onClick={onCloseModal}
                >
                    <CloseIcon />
                </IconButton>
                <Grid container className={classes.content}>
                    <Typography className={classes.modal__title} variant="h5">
                        Data Upload
                    </Typography>
                    <Typography variant="body2" className={classes.summary}>
                        For an example of how to correctly submit a new file,
                        please click DOWNLOAD SAMPLE. Your upload file needs to
                        be in the .xlsx format and use the same column headers.
                        This upload will overwrite the previous table data.
                    </Typography>
                    <Grid
                        item
                        container
                        justify="space-between"
                        alignItems="center"
                        className={classes.modal__buttonContainer}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() =>
                                window.open(`/static/${sampleFile}`, "_blank")
                            }
                        >
                            Download Sample
                        </Button>
                        {importing && <GlobalSpinner />}
                        <FileUpload
                            labelText="Upload file"
                            onSubmit={file => handleImport(file)}
                            accept=".xlsx"
                            allowSameFile
                        />
                    </Grid>
                    {errorMessages.length > 0 && (
                        <Grid container direction="column">
                            <Typography
                                variant="subtitle1"
                                id="ErrorTitle"
                                color="error"
                                className={classes.errorMessage}
                            >
                                Failure Rows:
                            </Typography>
                            <Paper className={classes.errorTable}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                Row
                                            </TableCell>
                                            <TableCell align="center">
                                                Cell
                                            </TableCell>
                                            <TableCell align="center">
                                                Error
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {errorMessages.map(row => (
                                            <TableRow>
                                                <TableCell align="center">
                                                    {row.row}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.cell}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.message}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            </div>
        </Modal>
    )
}

export default AdminUploadModal

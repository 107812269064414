import React, { Component } from "react"
import Grid from "@material-ui/core/Grid"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

export class Report extends Component {
    render() {
        const { data, mode } = this.props

        return (
            <Grid container>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{mode}</TableCell>
                            <TableCell align="right">Rate Quotes</TableCell>
                            <TableCell align="right">Shipments</TableCell>
                            <TableCell align="right">Active Shippers</TableCell>
                            <TableCell align="right">FedEx Shipments</TableCell>
                            <TableCell align="right">
                                Active FedEx Shippers
                            </TableCell>
                            <TableCell align="right">
                                Total FedEx Revenue ($)
                            </TableCell>
                            <TableCell align="right">New Profiles</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map(row => (
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">
                                    {row.rateQuotes}
                                </TableCell>
                                <TableCell align="right">
                                    {row.shipments}
                                </TableCell>
                                <TableCell align="right">
                                    {row.activeShippers}
                                </TableCell>
                                <TableCell align="right">
                                    {row.fedExShipments}
                                </TableCell>
                                <TableCell align="right">
                                    {row.activeFedExShippers}
                                </TableCell>
                                <TableCell align="right">
                                    {row.totalFedExRevenue}
                                </TableCell>
                                <TableCell align="right">
                                    {row.newProfiles}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        )
    }
}

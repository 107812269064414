import React, { Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"
import { Field } from "redux-form"
import FormField from "../form/form-field"
import normalizePhone from "../util/normalizePhone"
import { FormattedMessage } from "react-intl"
import { preferenceLanguageOptions } from "../constants/preferenceLanguageOptions"
import FormSelect from "../form/form-select"

const styles = theme => ({})

const GeneralInformation = ({ classes, country }) => (
    <Grid item container>
        <Grid item container xs={12}>
            <Typography className={classes.push__up} variant="h6">
                <FormattedMessage
                    id="userPreferences.generalInformation__title"
                    defaultMessage="General Information"
                />
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Field
                name={`firstname`}
                component={FormField}
                type="text"
                label={[
                    "* ",
                    <FormattedMessage
                        id="userPreferences.generalInformation__firstName"
                        defaultMessage="First Name"
                    />,
                ]}
            />
        </Grid>
        <Grid item xs={6}>
            <Field
                name={`lastname`}
                component={FormField}
                type="text"
                label={[
                    "* ",
                    <FormattedMessage
                        id="userPreferences.generalInformation__lastName"
                        defaultMessage="Last Name"
                    />,
                ]}
            />
        </Grid>
        <Grid item xs={8}>
            <Field
                name={`phone_number.phone_number`}
                component={FormField}
                type="tel"
                label={[
                    "* ",
                    <FormattedMessage
                        id="userPreferences.generalInformation__phoneNumber"
                        defaultMessage="Phone Number"
                    />,
                ]}
                normalize={normalizePhone(country)}
            />
        </Grid>
        <Grid item xs={4}>
            <Field
                name={`phone_number.extension`}
                component={FormField}
                type="tel"
                label={
                    <FormattedMessage
                        id="userPreferences.generalInformation__extension"
                        defaultMessage="Extension"
                    />
                }
            />
        </Grid>
        <Grid item xs={6}>
            <Field
                name={`phone_number.mobile`}
                component={FormField}
                type="tel"
                label={
                    <FormattedMessage
                        id="userPreferences.generalInformation__mobileNumber"
                        defaultMessage="Mobile Number"
                    />
                }
                normalize={normalizePhone(country)}
            />
        </Grid>
        <Grid item xs={6}>
            <Field
                name={`preferences.language`}
                label={[
                    "* ",
                    <FormattedMessage
                        id="userPreferences.generalInformation__language"
                        defaultMessage="Language"
                    />,
                ]}
                component={FormSelect}
                className={classes.textField}
                options={preferenceLanguageOptions}
            />
        </Grid>
        <Grid item xs={12}>
            <Field
                name={`email.email_address`}
                component={FormField}
                type="email"
                label={
                    <FormattedMessage
                        id="userPreferences.generalInformation__email"
                        defaultMessage="Email Address"
                    />
                }
                disabled
            />
        </Grid>
    </Grid>
)

export default withStyles(styles)(GeneralInformation)

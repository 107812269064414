import { pick, get } from "lodash"
import { goFetch } from "../http"
import {
    loadQuoteResult,
    quoteLoadError,
    updateQuoteResult,
} from "./quote-request"
import { isVolumeLTL } from "../misc"
import { changePath, createGAEvent, errorMessage, uniqueRequest } from "./index"
import { loadAddress } from "../reducers/address"
import { noRateResults } from "../messages/error-constants"

export const QUOTE_RESET = "QUOTE_RESET"
export const QUOTE_INVALIDATE = "QUOTE_INVALIDATE"
export const QUOTE_SELECT = "QUOTE_SELECT"
export const QUOTE_SELECT_ROW = "QUOTE_SELECT_ROW"
export const MARKUP_FIELD_CHANGE = "MARKUP_FIELD_CHANGE"
export const ADD_ITEM = "ADD_ITEM"
export const REMOVE_ITEM = "REMOVE_ITEM"
export const SET_QUOTE_MODE = "SET_QUOTE_MODE"
export const SELECT_ADDRESS_WITH_CONTACT = "SELECT_ADDRESS_WITH_CONTACT"
export const SEARCH_LOAD = "SEARCH_LOAD"
export const SEARCH_SAVE_SUCCESS = "SEARCH_SAVE_SUCCESS"
export const SEARCH_SAVE_ERROR = "SEARCH_SAVE_ERROR"
export const SET_EDIT_MODE = "SET_EDIT_MODE"
export const INIT_QUOTE = "INIT_QUOTE"
export const QUOTE_DELETED = "QUOTE_DELETED"
export const SET_SHIPPING_ADDRESSES = "SET_SHIPPING_ADDRESSES"

export function quoteSelect(
    shipmentId,
    carrier,
    quoteId,
    mode,
    direction,
    metadata
) {
    return (dispatch, getState) => {
        if (getState().share.isOpen) {
            return dispatch({
                type: QUOTE_SELECT,
                shipmentId,
                carrier,
                quoteId,
                mode,
                direction,
            })
        }
        const pathMode = mode === "VOLUME_LTL" ? "vltl" : "ltl"
        const path =
            quoteId !== undefined && quoteId !== null
                ? `/rate/${shipmentId}/${pathMode}/${direction}/${carrier}/${quoteId}/book`
                : `/rate/${shipmentId}/${pathMode}/${direction}/${carrier}/book`
        return dispatch(changePath(path))
    }
}

export const quoteSelectRow = (rates, carrierCode) => ({
    type: QUOTE_SELECT_ROW,
    rates,
    carrierCode,
})

const itemToHUItemMap = {
    weight: "weight",
    freightClass: "freightClass",
    description: "description",
    isHazMat: "isHazMat",
    unNumber: "hazMatUnNumber",
    pkgGrp: "hazMatPkgGrp",
    hazClass: "hazMatClass",
    hazContainer: "hazMatContainer",
    pieces: "pieces",
    freightDirectPieces: "freightDirectPieces",
    nmfc1: "nmfcCode",
    nmfc2: "nmfcSubclass",
    _id: "itemRef",
}

export const mapItemToHandlingUnit = (item, isMultiClass) => ({
    packageType: item.packageType,
    stackable: item.stackable,
    doNotStack: !item.stackable,
    length: item.length,
    width: item.width,
    height: item.height,
    isMultiClass,
    isIndividualHUWeight: isMultiClass,
    items: [mapItemToHUItem(item, isMultiClass)],
    count: item.count,
})

export const mapItemToHUItem = (item, isMultiClass) => {
    const huItem = {}
    Object.keys(itemToHUItemMap).forEach(key => {
        huItem[itemToHUItemMap[key]] = item[key]
    })
    let adjustedWeight = item.weight
    if (isMultiClass && !item.isIndividualWeight) {
        adjustedWeight = Math.ceil(item.weight / item.count)
    } else if (!isMultiClass && item.isIndividualWeight) {
        adjustedWeight = item.weight * item.count
    }
    return {
        ...huItem,
        weight: adjustedWeight,
        freightDirectPieces: huItem?.freightDirectPieces || [
            { count: huItem?.pieces, weight: undefined },
        ],
    }
}

export const selectAddressWithContact = (record, kind) => ({
    type: SELECT_ADDRESS_WITH_CONTACT,
    kind,
    contactId: get(record, "contact.id"),
    addressId: record.id,
    postalCode: get(record, "address.postalCode"),
    city: get(record, "address.city"),
    state: get(record, "address.state"),
    contactInfo: record.contact ? record : undefined,
    addressInfo: record.contact ? undefined : record,
})

export const isAddRowEnabled = items => items && items.length < 6

const quoteDeleted = shipmentId => ({ type: QUOTE_DELETED, shipmentId })

export function setEditMode(mode) {
    return { type: SET_EDIT_MODE, mode }
}

export function quoteInvalidate() {
    return { type: QUOTE_INVALIDATE }
}

export function markupFieldChange(field, value) {
    return { type: MARKUP_FIELD_CHANGE, field, value }
}

export function getUserLocations(state) {
    const locations = (state.user.profile || {}).locations
    if (locations) {
        return locations.filter(item => item.shippingAddress)
    }
    return []
}

export function getDefaultLocation(state) {
    const locations = getUserLocations(state)
    if (locations && locations.length) {
        return locations.find(l => l.isDefault) || locations[0]
    }
    return {}
}

const formatAddressInfo = address => ({
    name: address.companyName,
    address: {
        city: address.city,
        state: address.state,
    },
})

export const getCurrentShippingAddress = (state, cpg) => {
    const record = getUserLocations(state).find(item => item.cpgCode === cpg)
    return record
        ? {
              ...loadAddress(record.shippingAddress),
              cpgCode: record.cpgCode,
          }
        : {}
}

export function locationChange(value) {
    return (dispatch, getState) => {
        const state = getState()
        const address = getCurrentShippingAddress(state, value.value)
        const payload = {
            origin: address.postalCode,
            originCity: address.city,
            originState: address.state,
            originAddress: address.id,
            originAddressInfo: formatAddressInfo(address),
            originContact: undefined,
            originContactInfo: undefined,
            destination: "",
            destinationCity: "",
            destinationState: "",
            destinationAddress: undefined,
            destinationAddressInfo: undefined,
            destinationContact: undefined,
            destinationContactInfo: undefined,
            location: value,
            cpg: value.value,
        }
        dispatch({ type: SET_SHIPPING_ADDRESSES, payload })
    }
}

export function updateMarkup(values) {
    return async (dispatch, getState) => {
        const state = getState()
        const shipmentId = get(state, "identifiers.internalTrackingNumber")
        try {
            const { data: quotes } = await goFetch(
                `/quotes/${shipmentId}/markup`,
                {
                    method: "PUT",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        markupAmount: values.markupAmount || 0,
                        markupType: values.markupType,
                    },
                },
                true
            )
            if (!quotes.result || !quotes.result.rateQuotes)
                throw new Error(noRateResults)
            dispatch(loadQuoteResult(quotes, false))
            dispatch(updateQuoteResult(quotes, false))
        } catch (error) {
            dispatch(errorMessage(error))
        }
    }
}

export function deleteQuote(shipmentId) {
    return async dispatch => {
        try {
            dispatch(
                createGAEvent("Dashboard Quote", "Delete a Quote", "", {
                    quote: shipmentId,
                })
            )
            await goFetch(
                `/quotes/${shipmentId}`,
                {
                    method: "DELETE",
                    credentials: "same-origin",
                    headers: { "cache-control": "no-cache" },
                },
                true
            )
            dispatch(quoteDeleted(shipmentId))
        } catch (error) {
            dispatch(errorMessage(error))
        }
    }
}

export function setQuoteMode() {
    return (dispatch, getState) => {
        const { mode } = getState().search
        const newMode = isVolumeLTL(getState()) ? "VOLUME_LTL" : "LTL"
        if (newMode !== mode) {
            return dispatch({ type: SET_QUOTE_MODE, mode: newMode })
        }
        return null
    }
}

export function addItem() {
    return (dispatch, getState) => {
        const { items } = getState().search
        if (!isAddRowEnabled(items)) return
        dispatch({ type: ADD_ITEM })
    }
}

export function removeItem(index) {
    return (dispatch, getState) => {
        const { items } = getState().search
        if (!items || !items[index]) return
        dispatch({ type: REMOVE_ITEM, index })
        if (items[index].isDefault) return
        dispatch(quoteInvalidate())
        dispatch(setQuoteMode())
    }
}

export function getQuote(shipmentId) {
    return async dispatch => {
        const processData = async () => {
            try {
                const { data } = await goFetch(
                    `/quotes/${shipmentId}`,
                    {
                        method: "GET",
                        credentials: "same-origin",
                    },
                    true
                )
                return data
            } catch (error) {
                return dispatch(errorMessage(error))
            }
        }
        const id = `getQuote_${shipmentId}`
        return dispatch(uniqueRequest(id, processData))
    }
}

function internalPopulateQuoteForm(data) {
    return dispatch => {
        dispatch(updateQuoteResult(data))
        dispatch(setQuoteMode())
    }
}

export function initQuote() {
    return (dispatch, getState) => {
        const state = getState()
        const location = getDefaultLocation(state)
        const address = getCurrentShippingAddress(state, location.cpgCode)
        dispatch({
            type: INIT_QUOTE,
            data: {
                origin: address.postalCode,
                originCity: address.city,
                originState: address.state,
                originAddress: address.id,
                originAddressInfo: formatAddressInfo(address),
                cpg: location.cpgCode,
            },
        })
        dispatch(setQuoteMode())
    }
}

export function quoteReset() {
    return dispatch => {
        dispatch({ type: QUOTE_RESET })
        dispatch(setQuoteMode())
        dispatch(initQuote())
    }
}

export function populateQuoteForm(id) {
    return async (dispatch, getState) => {
        try {
            if (!id) {
                return dispatch(initQuote())
            }

            const data = getState().quotes.list.items[id]
            if (data) return dispatch(internalPopulateQuoteForm(data))
            const quotes = await dispatch(getQuote(id))
            dispatch(loadQuoteResult(quotes, false))
            return dispatch(internalPopulateQuoteForm(quotes))
        } catch (error) {
            dispatch(quoteLoadError(error))
        }
        return null
    }
}

export function setDefaults() {
    return async (dispatch, getState) => {
        try {
            const state = getState()

            const settings = pick(state.search, [
                "mode",
                "origin",
                "destination",
                "capacityProviderAccountGroup",
                "items",
                "pickupAccessorials",
                "deliveryAccessorials",
                "totalLinearFeet",
                "markupAmount",
                "uiDockPickup",
                "uiDockDelivery",
                "originAddress",
                "destinationAddress",
                "markupType",
            ])

            await goFetch(
                "/quotes/defaults",
                { data: settings, method: "POST" },
                true
            )
            dispatch({ type: SEARCH_SAVE_SUCCESS })
        } catch (error) {
            dispatch({ type: SEARCH_SAVE_ERROR, error })
        }
    }
}

export function selectQuote(quote) {
    return dispatch => {
        const { internalTrackingNumber: shipmentId } = quote.identifiers
        const path = `/rate/${shipmentId}`
        dispatch(changePath(path))
    }
}

import React from "react"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"
import { Checkbox } from "@material-ui/core"
import { calcDeliveryTime } from "../../../util"
import { isGuaranteedQuote } from "../../card/util"
import { quoteSelect } from "../../../../actions/quote"
import { isRateSelectedForShare } from "../../../../misc"
import { get } from "lodash"
import { FormattedMessage } from "react-intl"
import { currencyLabel } from "../../../util/currencyLabel"

const styles = theme => ({
    card: {
        display: "flex",
        padding: "3px",
        flexWrap: "wrap",
        margin: "9px 0 9px 0",
        width: "90%",
        cursor: "default",
    },
    carousel__card: {
        display: "flex",
        padding: "3px",
        flexWrap: "wrap",
        // margin: '9px 0 9px 0',
        width: "100%",
        cursor: "default",
        boxShadow: "none",
    },
    card__selected: {
        backgroundColor: theme.palette.secondary.light,
    },
    card__enabled: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#D3D3D3",
        },
        "&:active": {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    main__container: {
        height: "88px",
    },
    checkbox__root: {
        height: "auto",
    },
    expand: {
        transform: "rotate(0deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    serviceLevel_text: {
        color: "green",
    },
    quoteheader_total: {
        display: "flex",
        marginLeft: "4px",
        marginTop: "4px",
    },
    header: {
        "padding-bottom": "0px",
    },
    linear__feet__message: {
        marginTop: "auto",
        paddingLeft: "4px",
    },
})

const QuoteTile = props => {
    const {
        classes,
        quote,
        guaranteedList,
        shipmentId,
        carrierCode,
        mode,
        rateIndex,
        shippedRateQuote,
        cardIndex,
        onClick,
        share,
        disabled,
        gridSize,
        getBol,
        isCarousel,
        canViewRateAmounts,
    } = props
    const { index, direction } = quote
    const guaranteed = isGuaranteedQuote(
        quote.serviceLevel.code,
        guaranteedList
    )
    const selected = isRateSelectedForShare(
        share.selections,
        { index, mode, direction },
        carrierCode
    )
    const isSelectedRate =
        shippedRateQuote.mode === quote.mode &&
        carrierCode === shippedRateQuote.carrierCode &&
        quote.direction === shippedRateQuote.direction &&
        get(quote, "serviceLevel.code") ===
            get(shippedRateQuote, "serviceLevel.code")

    const checkboxClasses = {
        root: classes.checkbox__root,
    }

    return (
        <Grid
            item
            container
            xs={gridSize}
            justify="flex-end"
            className={classes.main__container}
        >
            <Card
                id={`quoteTile__${carrierCode}__${rateIndex}`}
                elevation={4}
                className={[
                    isCarousel ? classes.carousel__card : classes.card,
                    !disabled && !share.isOpen && classes.card__enabled,
                    isSelectedRate && classes.card__selected,
                ]
                    .filter(x => x)
                    .join(" ")}
                onClick={e => {
                    e.stopPropagation()
                    if (!disabled && !share.isOpen) {
                        onClick(
                            shipmentId,
                            carrierCode,
                            index,
                            mode,
                            direction,
                            { rateIndex, cardIndex }
                        )
                        getBol()
                    }
                }}
            >
                <Grid
                    item
                    container
                    direction="column"
                    alignItems="flex-start"
                    xs={6}
                >
                    {canViewRateAmounts && (
                        <Typography
                            variant="body2"
                            className={classes.quoteheader_total}
                        >
                            {currencyLabel(
                                quote?.preferredCurrencyCode,
                                get(
                                    quote,
                                    "rateQuoteDetail.totalInPreferredCurrency"
                                )
                                    ? get(
                                          quote,
                                          "rateQuoteDetail.totalInPreferredCurrency"
                                      )
                                    : get(quote, "rateQuoteDetail.total")
                            )}
                        </Typography>
                    )}
                    {share.isOpen && (
                        <Checkbox
                            classes={checkboxClasses}
                            checked={selected}
                            onClick={e => e.stopPropagation()}
                            onChange={() =>
                                onClick(
                                    shipmentId,
                                    carrierCode,
                                    index,
                                    mode,
                                    direction,
                                    {
                                        rateIndex,
                                        cardIndex,
                                    }
                                )
                            }
                        />
                    )}
                </Grid>
                <Grid item container xs={6} direction="column">
                    <Typography variant={"subtitle1"}>
                        {calcDeliveryTime(quote.deliveryDateTime)}
                    </Typography>
                    {guaranteed && (
                        <Typography
                            variant={"caption"}
                            className={classes.serviceLevel_text}
                        >
                            <FormattedMessage
                                id="generalTerms__guaranteed"
                                defaultMessage="Guaranteed"
                            />
                        </Typography>
                    )}
                    {!canViewRateAmounts && !guaranteed && (
                        <Typography variant={"caption"}>
                            <FormattedMessage
                                id="generalTerms__standardService"
                                defaultMessage="Standard Service"
                            />
                        </Typography>
                    )}
                </Grid>
            </Card>
        </Grid>
    )
}

const mapStateToProps = state => ({
    shipmentId: state.identifiers.internalTrackingNumber,
    guaranteedList: state.accessorials.list.guaranteed,
    share: state.share,
    totalLinearFeet: get(state, "search.totalLinearFeet"),
    volumeEnabled: get(state, "search.volumeType") === "NORMAL_AND_VOLUME",
})

const mapDispatchToProps = (dispatch, props) => ({
    onClick: (shipmentId, carrier, quoteId, mode, direction, metadata) => {
        dispatch(
            quoteSelect(shipmentId, carrier, quoteId, mode, direction, metadata)
        )
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(QuoteTile))

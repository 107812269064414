import React, { Fragment } from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import { FormattedMessage } from "react-intl"
import { documentIncompatibleError } from "../../../messages/error-constants"

export const UploadErrorDialog = ({ onClose }) => (
    <Fragment>
        <DialogTitle id="alert-dialog-title">
            <FormattedMessage
                id="attachments__attachmentFailed"
                defaultMessage="Attachment Failed"
            />
        </DialogTitle>
        <DialogContent>
            <Grid item container>
                <Typography variant="body2">
                    {documentIncompatibleError}
                </Typography>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button color="primary" onClick={onClose}>
                <FormattedMessage
                    id="generalTerms__continue"
                    defaultMessage="Continue"
                />
            </Button>
        </DialogActions>
    </Fragment>
)

import * as yup from "yup"

const phoneRegExp = /^(\+?\d{1,4}[\s-]?)?(\(?\d{3}\)?[\s-]?)?[\d\s-]{7,10}$/

const timeToMinutes = time => {
    const [timePart, period] = time.split(" ")
    let [hours, minutes] = timePart.split(":").map(Number)
    if (period === "PM" && hours !== 12) hours += 12
    if (period === "AM" && hours === 12) hours = 0
    return hours * 60 + minutes
}

export const schema = yup.object().shape({
    schedule: yup.array().of(
        yup.object().shape({
            pickupDate: yup
                .string()
                .required()
                .label("Pickup Date"),
            pickupInformation: yup.object().shape({
                closeTime: yup
                    .string()
                    .required()
                    .label("Close Time")
                    .test(
                        "is-valid-time-difference",
                        "Close Time must be at least 90 minutes after Ready Time",
                        function(value) {
                            const closeTimeMinutes = timeToMinutes(value)
                            const readyTimeMinutes = timeToMinutes(
                                this.parent.readyTime
                            )
                            if (closeTimeMinutes - readyTimeMinutes >= 90) {
                                this.createError({
                                    message:
                                        "Close Time must be after Ready Time",
                                })
                            }
                            return closeTimeMinutes - readyTimeMinutes >= 90
                        }
                    ),
                readyTime: yup
                    .string()
                    .required()
                    .label("Ready Time"),
            }),

            contact: yup.object().shape({
                name: yup
                    .string()
                    .required()
                    .label("Pickup Contact Name"),
                phone: yup.object().shape({
                    phone_number: yup
                        .string()
                        .required()
                        .matches(phoneRegExp, "Phone is not valid")
                        .label("Phone"),
                    ext: yup.string().label("Ext"),
                }),
                email: yup.object().shape({
                    email_address: yup
                        .string()
                        .email()
                        .required()
                        .label("Email"),
                }),
            }),
        })
    ),
})

import React, { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import {
    FormControl,
    Input,
    InputLabel,
    FormHelperText,
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { requestPostalCode } from "../../actions/postal-code"
import FormattedError from "./formatted-error"
import { trackGAEvent } from "../../actions/user"

const styles = theme => ({
    form__container: {
        width: "100%",
        padding: "3px",
    },
    input__error: {
        color: "red",
    },
    form__control: {
        width: "100%",
    },
})

class FormZipCode extends Component {
    state = {
        initialValue: "",
    }

    componentDidMount() {
        const {
            input: { value },
        } = this.props
        if (!value) return
    }

    render() {
        const {
            input,
            label,
            type,
            autoComplete,
            meta: { touched, error, form },
            placeholder,
            maxLength,
            classes,
            onChange,
            InputProps,
            required,
            dataTestId,
            category,
            trackGA,
            ...rest
        } = this.props
        return (
            <Grid
                item
                className={`${classnames({
                    "form-field": true,
                    "form-field-touched": touched,
                    "form-field-invalid": touched && error,
                    "form-field-valid": !error,
                })} ${classes.form__container}`}
            >
                <Grid item container direction="column">
                    <FormControl
                        error={!!(touched && error)}
                        className={classes.form__control}
                    >
                        <InputLabel
                            htmlFor={input.name}
                            required={required}
                            id={`${input.name}-label`}
                        >
                            {label}
                        </InputLabel>
                        <Input
                            {...input}
                            {...rest}
                            id={input.name}
                            type={type}
                            margin="none"
                            autoComplete={autoComplete}
                            maxLength={maxLength || undefined}
                            onChange={e => {
                                if (e.target?.value) {
                                    e.target.value = e.target.value.toUpperCase()
                                }
                                input.onChange(e)
                                onChange(e.target.value, form, input.name)
                            }}
                            onFocus={e => {
                                if (input.onFocus) {
                                    input.onFocus(e)
                                }
                                this.setState({ initialValue: e.target.value })
                            }}
                            onBlur={e => {
                                if (input.onBlur) {
                                    input.onBlur(e)
                                }
                                if (this.state.initialValue === "") {
                                    trackGA(
                                        category ?? "Unknown",
                                        "Form Zip Code",
                                        label?.props?.defaultMessage ??
                                            input.name
                                    )
                                } else if (
                                    input?.value !== this.state.initialValue
                                ) {
                                    trackGA(
                                        category ?? "Unknown",
                                        "Form Zip Code",
                                        label?.props?.defaultMessage ??
                                            input.name
                                    )
                                }
                            }}
                            inputProps={{ dataTestId }}
                            {...InputProps}
                        />
                        {touched && error && (
                            <FormHelperText>
                                <FormattedError error={error} />
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
        )
    }
}

FormZipCode.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    type: PropTypes.oneOf([
        "text",
        "email",
        "file",
        "number",
        "tel",
        "checkbox",
        "password",
    ]),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    autoComplete: PropTypes.string,
    meta: PropTypes.shape({
        // eslint-disable-next-line react/no-unused-prop-types
        touched: PropTypes.bool,
        // eslint-disable-next-line react/no-unused-prop-types
        error: PropTypes.string,
        form: PropTypes.string,
    }),
    classes: PropTypes.object,
    category: PropTypes.string,
}

FormZipCode.defaultProps = {
    meta: {},
    autoComplete: undefined,
    maxLength: undefined,
    placeholder: undefined,
    label: undefined,
    type: undefined,
    input: undefined,
    classes: {},
}

const mapDispatchToProps = (dispatch, { country }) => ({
    onChange: (value, form, field) =>
        dispatch(requestPostalCode(value, { form, field, country })),
    trackGA: (category, action, value) =>
        dispatch(trackGAEvent(category, action, value)),
})

export default withStyles(styles)(
    connect(undefined, mapDispatchToProps)(FormZipCode)
)

import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    SnackbarContent,
    Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React, { useEffect } from "react"
import { FormattedMessage } from "react-intl"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { amber } from "@material-ui/core/colors"
import WarningIcon from "@material-ui/icons/Warning"
import { IntercomAPI } from "react-intercom"

const useStyles = makeStyles({
    errorSnackbar: {
        backgroundColor: amber[700],
        maxWidth: "100%",
    },
    expansionPanel: {
        width: "100%",
    },
    warningIcon: {},
    warningText: {
        color: "black",
    },
})

export default function RatesError({
    ratesError,
    isPresentAnyCapacityOrCapLoadWarningOrExtremeLengthError = false,
}) {
    useEffect(() => {
        if (isPresentAnyCapacityOrCapLoadWarningOrExtremeLengthError) {
            IntercomAPI("trackEvent", "volume-services-load-capacity-error")
        }
    }, [isPresentAnyCapacityOrCapLoadWarningOrExtremeLengthError])
    const classes = useStyles()
    return (
        <Grid item container>
            <Grid item xs={12}>
                <ExpansionPanel
                    className={classes.expansionPanel}
                    defaultExpanded={
                        isPresentAnyCapacityOrCapLoadWarningOrExtremeLengthError
                    }
                >
                    <ExpansionPanelSummary
                        expandIcon={
                            <ExpandMoreIcon id="expandMoreIconErrorRates" />
                        }
                    >
                        <Grid item container xs={1} alignItems="center">
                            <WarningIcon className={classes.warningIcon} />
                        </Grid>
                        <Grid item container xs={11} alignItems="center">
                            <Typography
                                variant="body2"
                                id="carrierRatesErrorMessage"
                            >
                                <FormattedMessage
                                    id="rateResults__carrierRatesError"
                                    defaultMessage="At least one carrier is having trouble retrieving rates.  View more information"
                                />
                            </Typography>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid item container spacing={2}>
                            {ratesError
                                .sort((err1, err2) =>
                                    err1?.carrierCode && err2?.carrierCode
                                        ? err1.carrierCode.localeCompare(
                                              err2.carrierCode
                                          )
                                        : -1
                                )
                                .map((rateError, i) => {
                                    const errorCarrier =
                                        rateError.mode === "LTL_DIRECT"
                                            ? "FXFD"
                                            : rateError?.carrierCode ?? ""
                                    return (rateError?.errorMessages ?? []).map(
                                        (error, j) => {
                                            const errorDiagnostic =
                                                error?.diagnostic
                                            const errorMessage = error?.message
                                            const source =
                                                error?.source === "SYSTEM"
                                                    ? "Project 44"
                                                    : "Carrier Error"
                                            const errorText = errorDiagnostic
                                                ? `${errorCarrier}: ${errorMessage} (${source}) - ${errorDiagnostic}`
                                                : `${errorCarrier}: ${errorMessage} (${source})`
                                            return (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    key={`${i}_${j}`}
                                                >
                                                    <SnackbarContent
                                                        className={
                                                            classes.errorSnackbar
                                                        }
                                                        message={
                                                            <Typography
                                                                variant="body2"
                                                                id={`rateError${i}_errorMessages${j}`}
                                                                className={
                                                                    classes.warningText
                                                                }
                                                            >
                                                                {errorCarrier ===
                                                                    "FXFE" ||
                                                                errorCarrier ===
                                                                    "FXNL"
                                                                    ? React.createElement(
                                                                          "div",
                                                                          {
                                                                              dangerouslySetInnerHTML: {
                                                                                  __html: errorText,
                                                                              },
                                                                          }
                                                                      )
                                                                    : errorText}
                                                            </Typography>
                                                        }
                                                    />
                                                </Grid>
                                            )
                                        }
                                    )
                                })}
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
        </Grid>
    )
}

import React from "react"
import { Button, Typography, withStyles } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import FormSwitch from "../../form/form-switch"
import { Field } from "redux-form"
import FormCapacityField from "../../form/form-switch-with-capacity-field"
import { useFlags } from "launchdarkly-react-client-sdk"

const styles = theme => ({
    document__icon: {
        marginTop: 10,
    },
    spacer: {
        width: "16px",
        height: "44px",
    },
})

const InvoiceAudit = props => {
    const {
        classes,
        dirty,
        handleSave,
        handleSubmit,
        location,
        gaCategory,
    } = props

    return (
        <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            alignContent="flex-start"
            className={classes.location__card__header}
        >
            <Grid item container alignItems="center" xs={12}>
                <Typography gutterBottom variant="h6">
                    <FormattedMessage
                        id="locations.invoiceAudit__title"
                        defaultMessage="Invoice Audit"
                    />
                </Typography>
            </Grid>
            <Grid
                container
                direction="row"
                alignItems="flex-start"
                alignContent="center"
            >
                <Grid item container alignItems="center" xs={12}>
                    <Grid className={classes.spacer}></Grid>
                    <Field
                        name="invoiceVarianceThreshold"
                        location={location}
                        component={FormCapacityField}
                        min={1}
                        max={100}
                        label={
                            <FormattedMessage
                                id="locations.invoiceVarianceThreshold"
                                defaultMessage="Set your invoice variance threshold"
                            />
                        }
                        unit={"%"}
                        category={gaCategory}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(InvoiceAudit)

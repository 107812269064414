import React from "react"
import { withStyles, Grid, Typography } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    warning__pill: {
        color: "white",
        backgroundColor: "red",
    },
    invited__pill: {
        color: "white",
        backgroundColor: theme.palette.primary.light,
    },
    nonOwner__pill: {
        color: "white",
        backgroundColor: "grey",
    },
})

function RoleChip(props) {
    const { role, gridSize, isPrimary } = props

    return (
        <Grid item container xs={gridSize} alignItems="center">
            {role === "owner" && !isPrimary && (
                <Typography variant="body2" color="primary">
                    <FormattedMessage
                        id="locations.users__owner"
                        defaultMessage="Owner"
                    />
                </Typography>
            )}
            {role === "owner" && isPrimary && (
                <Typography variant="body2" color="primary">
                    <FormattedMessage
                        id="locations.users__primaryOwner"
                        defaultMessage="Primary Owner"
                    />
                </Typography>
            )}
            {role === "member" && (
                <Typography variant="body2">
                    <FormattedMessage
                        id="locations.users__companyUser"
                        defaultMessage="Company User"
                    />
                </Typography>
            )}
            {role === "thirdParty" && (
                <Typography variant="body2">
                    <FormattedMessage
                        id="locations.users__thirdPartyVendor"
                        defaultMessage="Third Party Vendor"
                    />
                </Typography>
            )}
            {role === "requested" && (
                <Typography variant="body2" color="secondary">
                    <FormattedMessage
                        id="locations.users__requestedAccess"
                        defaultMessage="Requested Access"
                    />
                </Typography>
            )}
        </Grid>
    )
}

export default withStyles(styles)(RoleChip)

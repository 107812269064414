import React, { Component } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Button } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

class UnsavedChanges extends Component {
    render() {
        const { open, handleClose, handleContinue, message } = this.props

        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle id="alert-dialog-title">
                    <FormattedMessage
                        id="generalTerms__unsavedChanges"
                        defaultMessage="Unsaved Changes"
                    />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <FormattedMessage
                            id="generalTerms__unsavedChangesMessage"
                            defaultMessage="You have unsaved changes on this page. If you continue, you will lose these changes."
                        />
                    </DialogContentText>
                    {message && (
                        <DialogContentText id="alert-dialog-description-2">
                            {message}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => handleClose()}
                        color="secondary"
                        autoFocus
                    >
                        <FormattedMessage
                            id="generalTerms__cancel"
                            defaultMessage="Cancel"
                        />
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleContinue()}
                    >
                        <FormattedMessage
                            id="generalTerms__continue"
                            defaultMessage="Continue"
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default UnsavedChanges

import React, { Component } from "react"
import { Grid, Typography, Button } from "@material-ui/core"
import Modal from "@material-ui/core/Modal"
import { get } from "lodash"
import { AddUserModal } from "./addUser"
import { ManageUserModal } from "./manageUser"
import RoleChip from "./roleChip"
import { FormattedMessage } from "react-intl"

const GACategory = "Location"

export class UserManagement extends Component {
    state = {
        addOpen: false,
        manageOpen: false,
        openIndex: null,
        currentUser: null,
    }

    handleAddOpen = () => {
        this.setState({ addOpen: true })
        this.props.trackGA(GACategory, "User Management - Add New User Click")
    }

    handleAddClose = () => {
        this.setState({ addOpen: false })
        this.props.trackGA(
            GACategory,
            "User Management - Add New User Modal Close"
        )
    }

    handleManageOpen = (user, index) => {
        this.setState({
            manageOpen: true,
            currentUser: user,
            openIndex: index,
        })
        this.props.trackGA(GACategory, "User Management - Manage User Click")
    }

    handleManageClose = () => {
        this.setState({
            manageOpen: false,
            currentUser: null,
            openIndex: null,
        })
        this.props.trackGA(
            GACategory,
            "User Management - Manage User Modal Close"
        )
    }

    calculateLabel = (user, index) => {
        if (get(user, "user.firstname") && get(user, "user.lastname")) {
            return [
                get(user, "user.firstname", ""),
                " ",
                get(user, "user.lastname", ""),
                index === 0
                    ? [
                          " (",
                          <FormattedMessage
                              id="locations.users__me"
                              defaultMessage="Me"
                          />,
                          ")",
                      ]
                    : "",
            ]
        }
        return `${get(user, "user.email.email_address", "")}`
    }

    render() {
        const {
            classes,
            gridSize,
            information,
            index,
            handleSnackbarOpen,
            trackGA,
        } = this.props
        const { addOpen, manageOpen, currentUser, openIndex } = this.state

        return (
            <Grid
                item
                container
                xs={gridSize}
                direction="column"
                id="manageUsers"
            >
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                    alignContent="center"
                    className={classes.location__card__header}
                >
                    <Grid item container alignItems="center" xs={4}>
                        <Typography gutterBottom variant="h6">
                            <FormattedMessage
                                id="locations.users__title"
                                defaultMessage="Users"
                            />
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        alignItems="center"
                        xs={8}
                        justify="flex-end"
                    >
                        <Button
                            color="primary"
                            onClick={() => this.handleAddOpen()}
                            id="userManagement__addUser"
                        >
                            <FormattedMessage
                                id="locations.users__addUser"
                                defaultMessage="Add Users"
                            />
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container className={classes.users__container}>
                    {get(information, "users", []).map((user, index) => (
                        <Grid
                            item
                            container
                            alignItems="center"
                            id={`userManagement__locationRow__${index}`}
                            key={index}
                            className={
                                index ===
                                get(information, "users", []).length - 1
                                    ? classes.location__row__last
                                    : classes.location__row
                            }
                            onClick={() => this.handleManageOpen(user, index)}
                        >
                            <Grid item container alignItems="center">
                                <Grid
                                    item
                                    container
                                    xs={5}
                                    id={`userManagement__locationRow__${index}__username`}
                                >
                                    <Typography variant="body2">
                                        {this.calculateLabel(user, index)}
                                    </Typography>
                                </Grid>
                                <RoleChip
                                    role={user.role}
                                    gridSize={4}
                                    isPrimary={user.isPrimary}
                                />
                                <Grid
                                    item
                                    container
                                    xs={3}
                                    justify="flex-end"
                                    className={classes.user__status__label}
                                >
                                    {get(
                                        user,
                                        "permissions.suspended.value"
                                    ) && (
                                        <Typography
                                            variant="body2"
                                            color="secondary"
                                        >
                                            <FormattedMessage
                                                id="locations.users__inactive"
                                                defaultMessage="Inactive"
                                            />
                                        </Typography>
                                    )}
                                    {!get(user, "access.granted") &&
                                        !get(user, "access.requested") && (
                                            <Typography
                                                variant="body2"
                                                color="primary"
                                                id={`userManagement__locationRow__${index}__invitedTag`}
                                            >
                                                <FormattedMessage
                                                    id="locations.users__invited"
                                                    defaultMessage="Invited"
                                                />
                                            </Typography>
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                <Modal open={addOpen} onClose={this.handleAddClose}>
                    <AddUserModal
                        handleClose={this.handleAddClose}
                        information={information}
                        index={index}
                        gaCategory={GACategory}
                    />
                </Modal>
                <Modal open={manageOpen} onClose={this.handleManageClose}>
                    <ManageUserModal
                        trackGA={trackGA}
                        handleSnackbarOpen={handleSnackbarOpen}
                        handleClose={this.handleManageClose}
                        information={information}
                        user={currentUser}
                        index={openIndex}
                        gaCategory={GACategory}
                    />
                </Modal>
            </Grid>
        )
    }
}

export const JAX = {
    location: "JAX",
    contact: {
        phone: "800-218-6126",
    },
}

export const SJU = {
    location: "SJU",
    contact: {
        phone: "844-285-0692",
    },
}

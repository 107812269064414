import React from "react"
import PropTypes from "prop-types"
import { Route, withRouter, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { userActivated } from "./private-route"
import { loggedIn, isParseParam } from "../../actions/authorization"
import GlobalSpinner from "../common/GlobalSpinner"

const styles = theme => ({
    progress: {
        margin: theme.spacing(2),
        top: "50%",
        right: "50%",
        position: "absolute",
    },
})

function renderRoute({ user, authorization, location, classes }) {
    const { initFinished, isFinished } = authorization || {}
    if (!loggedIn() && !isParseParam(location.pathname)) {
        return <Redirect to={{ pathname: "/" }} />
    }
    if (!initFinished || (!isFinished && isParseParam(location.pathname))) {
        return <GlobalSpinner />
    }
    const pathname = userActivated(user) ? "/home" : "/user/signup"
    return <Redirect to={{ pathname }} />
}

renderRoute.propTypes = {
    user: PropTypes.object.isRequired,
    authorization: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
}

export const CatchAllRouteView = withRouter(props => (
    <Route render={() => renderRoute(props)} />
))

// this is to make re-render upon user change
const mapStateToProps = ({ user, authorization }) => ({ user, authorization })

export const CatchAllRoute = withRouter(
    withStyles(styles)(connect(mapStateToProps)(CatchAllRouteView))
)

import {
    Box,
    IconButton,
    MenuItem,
    Select,
    Typography,
} from "@material-ui/core"
import { ChevronLeftTwoTone, ChevronRightTwoTone } from "@material-ui/icons"
import React from "react"
import { FormattedMessage } from "react-intl"

const pageSizeOptions = [5, 10, 20, 25, 50, 100]

const PaginationNav = ({
    totalItems,
    currentPage,
    pageSize,
    setPageSize,
    setCurrentPage,
}) => {
    const changePageSize = evt => {
        const newPageSize = evt.target.value
        const newPage = Math.ceil((pageSize / newPageSize) * currentPage)
        const newPageSizeMax = Math.ceil(totalItems / newPageSize)
        // This logic ensures that we keep the last viewable item in view when we change page size.
        if (newPage < 1) {
            setCurrentPage(1)
        } else if (newPage > newPageSizeMax) {
            setCurrentPage(newPageSizeMax)
        } else {
            setCurrentPage(newPage)
        }
        setPageSize(newPageSize)
    }
    const incrementPageNumber = evt => {
        setCurrentPage(currentPage + 1)
    }

    const decrementPageNumber = evt => {
        setCurrentPage(currentPage - 1)
    }
    return (
        <Box
            style={{
                marginTop: "6px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
            }}
        >
            <Select
                style={{ margin: "12px" }}
                id="page-size-select"
                value={pageSize}
                onChange={changePageSize}
            >
                {pageSizeOptions.map(option => {
                    return (
                        <MenuItem key={`${option}-page`} value={option}>
                            <Typography variant="body2">
                                <FormattedMessage
                                    id="pagination__perPage"
                                    defaultMessage="{option} per page"
                                    values={{ option }}
                                />
                            </Typography>
                        </MenuItem>
                    )
                })}
            </Select>

            <Typography
                style={{
                    fontSize: "12px",
                    color: "#757575",
                    margin: "6px",
                }}
                variant="body2"
            >
                <FormattedMessage
                    id="pagination__pageNumber"
                    defaultMessage="Page {currentPage} of {totalPages}"
                    values={{
                        currentPage,
                        totalPages: Math.ceil(totalItems / pageSize) || 1,
                    }}
                />
            </Typography>

            <Box style={{ margin: "6px" }}>
                <IconButton
                    onClick={decrementPageNumber}
                    disabled={currentPage === 1}
                >
                    <ChevronLeftTwoTone />
                </IconButton>

                <IconButton
                    onClick={incrementPageNumber}
                    disabled={currentPage === Math.ceil(totalItems / pageSize)}
                >
                    <ChevronRightTwoTone />
                </IconButton>
            </Box>
        </Box>
    )
}

export default PaginationNav

import React, { Component } from "react"
import { ErrorSnackbarContentWrapper } from "../errorSnackbar"
import { Snackbar, Typography } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

class ShipmentSnackbar extends Component {
    state = {
        content: null,
    }

    componentWillReceiveProps(newProps) {
        this.setState({ content: newProps.content })
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }

        this.setState({ content: null })
    }

    render() {
        const { variant } = this.props
        const { content } = this.state
        if (!content) return null
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={!!content}
                autoHideDuration={6000}
                onClose={this.handleSnackbarClose}
            >
                <ErrorSnackbarContentWrapper
                    variant={variant}
                    onClose={this.handleSnackbarClose}
                    message={
                        <span>
                            <Typography variant="body2">
                                {content.id && content.defaultMessage ? (
                                    <FormattedMessage {...content} />
                                ) : (
                                    content
                                )}
                            </Typography>
                        </span>
                    }
                />
            </Snackbar>
        )
    }
}

export default ShipmentSnackbar

import { Grid, Paper, Typography, withStyles } from "@material-ui/core"
import React from "react"
import { supportedTiles } from "./constants"
import { sortableContainer, sortableElement } from "react-sortable-hoc"

const styles = theme => ({
    mainContainer: {
        padding: "10px",
        "-webkit-touch-callout": "none" /* iOS Safari */,
        "-webkit-user-select": "none" /* Safari */,
        "-khtml-user-select": "none" /* Konqueror HTML */,
        "-moz-user-select": "none" /* Firefox */,
        "-ms-user-select": "none" /* Internet Explorer/Edge */,
        "user-select":
            "none" /* Non-prefixed version, currently
                                            supported by Chrome and Opera */,
    },
    paper: {
        "&:hover": {
            cursor: "move",

            backgroundColor: theme.palette.secondary.main,
            color: "white",
        },
        display: "flex",
        // cursor: "pointer",
        width: "100%",
        minHeight: "50px",
        position: "static",
        padding: "3px",

        pointerEvents: "auto !important",
    },
    helperClass: {
        zIndex: 9999,
        cursor: "move",
        "&:active": {
            backgroundColor: theme.palette.secondary.dark,
            color: "white",
            cursor: "move",
        },
    },
    grabbing: {
        cursor: "grabbing",
    },
    "grabbing *": {
        cursor: "grabbing",
    },
})

const SortableItem = sortableElement(({ tile, classes }) => {
    return (
        <Grid item container xs={2} className={classes.mainContainer}>
            <Paper className={classes.paper}>
                <Grid
                    item
                    container
                    xs={9}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >
                    <Grid item container alignItems="center">
                        <Typography color="inherit" variant="caption">
                            {tile.label}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    xs={3}
                    justify="flex-end"
                    alignItems="center"
                >
                    {tile.icon}
                </Grid>
            </Paper>
        </Grid>
    )
})

const SortableContainer = sortableContainer(({ children }) => {
    return (
        <Grid item container>
            {children}
        </Grid>
    )
})

const DraggableTileList = ({ currentTileOrder, onSortEnd, classes }) => {
    return (
        <SortableContainer
            onSortEnd={onSortEnd}
            helperClass={classes.helperClass}
            axis="xy"
            onSortStart={() => (document.body.className = classes.grabbing)}
        >
            {currentTileOrder.map((tileKey, index) => {
                const tile = supportedTiles.find(
                    supportedTile => supportedTile.value === tileKey
                )
                return (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={tile.value}
                        tile={tile}
                        classes={classes}
                    />
                )
            })}
        </SortableContainer>
    )
}

export default withStyles(styles)(DraggableTileList)

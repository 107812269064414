import {
    SHARE_MODE_CHANGE,
    SHARE_FIELD_CHANGE,
    REMOVE_SHARE_ITEM,
} from "../actions/share"
import { QUOTE_SELECT, QUOTE_SELECT_ROW, INIT_QUOTE } from "../actions/quote"
import { removeItemFromArray } from "./index"
import { isRateSelectedForShare } from "../misc"

// max number of quotes allowed to be shared
const maxShareSelectionSize = 100000

export function share(
    state = {
        resource: "",
        shipmentId: "",
        type: "",
        phoneNumber: "",
        emailAddress: "",
        referenceNumber: "",
        isOpen: false,
        selections: [],
        contactQuery: "",
    },
    action = {}
) {
    let newSelections
    switch (action.type) {
        case SHARE_MODE_CHANGE:
            return {
                ...share(),
                isOpen: action.value,
                ...action.payload,
            }
        case INIT_QUOTE:
            return share()
        case QUOTE_SELECT: {
            // see if this quote is already selected
            const indexInList = state.selections.findIndex(
                e =>
                    e.name === action.carrier &&
                    e.alternateRateQuote === action.quoteId &&
                    e.mode === action.mode &&
                    e.direction === action.direction
            )

            // if not already selected
            if (indexInList === -1) {
                // add it if max selection size not already reached
                return state.selections.length >= maxShareSelectionSize
                    ? state
                    : Object.assign({}, state, {
                          selections: state.selections.concat({
                              name: action.carrier,
                              alternateRateQuote: action.quoteId,
                              mode: action.mode,
                              direction: action.direction,
                          }),
                      })
            }

            // remove it
            newSelections = state.selections.slice(0)
            newSelections.splice(indexInList, 1)
            return Object.assign({}, state, {
                selections: newSelections,
            })
        }
        case QUOTE_SELECT_ROW: {
            const isRowSelected = action.rates.every(rate =>
                isRateSelectedForShare(
                    state.selections,
                    rate,
                    action.carrierCode
                )
            )
            const selectedRates = [...state.selections]
            if (isRowSelected) {
                newSelections = selectedRates.filter(
                    selected =>
                        !action.rates.find(
                            rate =>
                                selected.name === action.carrierCode &&
                                selected.alternateRateQuote === rate.index &&
                                selected.mode === rate?.mode &&
                                selected.direction === rate.direction
                        )
                )
            }
            if (!isRowSelected) {
                newSelections = selectedRates.concat(
                    action.rates.map(rate => ({
                        name: action.carrierCode,
                        alternateRateQuote: rate.index,
                        mode: rate?.mode,
                        direction: rate.direction,
                    }))
                )
            }
            return { ...state, selections: newSelections }
        }
        case SHARE_FIELD_CHANGE:
            if (typeof action.field === "string") {
                return Object.assign({}, state, {
                    [action.field]: action.value,
                })
            }
            newSelections = state.selections.slice()
            newSelections[action.field] = Object.assign(
                {},
                newSelections[action.field],
                { state: action.value }
            )
            return Object.assign({}, state, {
                selections: newSelections,
            })
        case REMOVE_SHARE_ITEM:
            return removeItemFromArray(state, "selections", action.index)
        default:
            return state
    }
}

import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import { CarrierLogo } from "../../../util"
import QuoteTile from "../QuoteTile"
import Checkbox from "@material-ui/core/Checkbox"
import { quoteSelectRow } from "../../../../actions/quote"
import Typography from "@material-ui/core/Typography"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMore from "@material-ui/icons/ExpandMore"
import { get } from "lodash"
import RateBreakdown from "../../../dashboard/components/rateBreakdown"
import { isRateSelectedForShare } from "../../../../misc"

const styles = theme => ({
    gridList: {
        flexWrap: "nowrap",
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: "translateZ(0)",
        width: "100%",
        overflowX: "hidden",
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    main__container: {
        paddingLeft: "10px",
        paddingRight: "10px",
        width: "100%",
        boxShadow: "none",
    },
    summary: {
        padding: 0,
    },
    details: {
        padding: "0 32px 0 0",
    },
    share__checkbox: {
        marginRight: "8px",
    },
    third__party__label: {
        marginLeft: "3px",
    },
    rateBreakdown__left: {
        width: "90%",
        marginLeft: "auto",
    },
    main__container__alt: {
        backgroundColor: "white",
    },
})

function QuoteRow(props) {
    const {
        classes,
        carrier,
        shareOpen,
        rowSelected,
        handleRowSelect,
        disabled,
        isThirdParty,
        isOpen,
        toggleInfo,
        index,
        getBol,
        shippedRateQuote,
        canViewRateAmounts,
    } = props

    const summaryClasses = {
        root: classes.summary,
    }

    const detailsClasses = {
        root: classes.details,
    }

    return (
        <Grid container>
            {canViewRateAmounts ? (
                <ExpansionPanel
                    className={classes.main__container}
                    expanded={isOpen}
                >
                    <ExpansionPanelSummary
                        classes={summaryClasses}
                        expandIcon={<ExpandMore />}
                        onClick={() => toggleInfo(index)}
                    >
                        <Grid container>
                            <Grid
                                item
                                container
                                alignItems="center"
                                justify="flex-start"
                                xs={2}
                            >
                                <Grid item container xs={1}>
                                    {shareOpen && (
                                        <Grid
                                            item
                                            container
                                            alignItems="flex-start"
                                            justify="center"
                                        >
                                            {" "}
                                            <Checkbox
                                                checked={rowSelected}
                                                onClick={e =>
                                                    e.stopPropagation()
                                                }
                                                onChange={() =>
                                                    handleRowSelect(
                                                        carrier.rateQuotes,
                                                        carrier.carrierCode
                                                    )
                                                }
                                                className={
                                                    classes.share__checkbox
                                                }
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item container xs={11}>
                                    {carrier && (
                                        <CarrierLogo
                                            className={classes.carrier_logo}
                                            carrierCode={carrier.carrierCode}
                                            serviceLevel={carrier.serviceLevel}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" xs={9}>
                                {carrier &&
                                    carrier.rateQuotes.map((quote, index) => (
                                        <QuoteTile
                                            getBol={getBol}
                                            gridSize={4}
                                            disabled={disabled}
                                            key={index}
                                            quote={quote}
                                            carrierCode={carrier.carrierCode}
                                            mode={carrier.rateQuotes[0].mode}
                                            cardIndex={props.index + 1}
                                            rateIndex={index + 1}
                                            isCarousel={false}
                                            shippedRateQuote={shippedRateQuote}
                                            canViewRateAmounts={
                                                canViewRateAmounts
                                            }
                                        />
                                    ))}
                            </Grid>
                            <Grid
                                item
                                container
                                xs={1}
                                alignItems="flex-start"
                                justify="center"
                            >
                                {isThirdParty && (
                                    <Typography
                                        variant="caption"
                                        className={classes.third__party__label}
                                    >
                                        3rd Party Pricing
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails classes={detailsClasses}>
                        <Grid container>
                            <Grid item container xs={2} />
                            <Grid item container direction="row" xs={9}>
                                {carrier &&
                                    carrier.rateQuotes.map((quote, index) => (
                                        <Grid item xs={4}>
                                            <Grid
                                                item
                                                className={
                                                    classes.rateBreakdown__left
                                                }
                                            >
                                                <RateBreakdown
                                                    charges={get(
                                                        quote,
                                                        "rateQuoteDetail.charges"
                                                    )}
                                                    totalInPreferredCurrency={
                                                        get(
                                                            quote,
                                                            "rateQuoteDetail.totalInPreferredCurrency"
                                                        )
                                                            ? get(
                                                                  quote,
                                                                  "rateQuoteDetail.totalInPreferredCurrency"
                                                              )
                                                            : get(
                                                                  quote,
                                                                  "rateQuoteDetail.total"
                                                              )
                                                    }
                                                    preferredCurrencyCode={get(
                                                        quote,
                                                        "preferredCurrencyCode"
                                                    )}
                                                    gridSize={12}
                                                    key={index}
                                                    listView
                                                    quoteID={
                                                        quote.capacityProviderQuoteNumber
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                            </Grid>
                            <Grid item container xs={1} />
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            ) : (
                <Grid container className={classes.main__container__alt}>
                    <Grid container>
                        <Grid
                            item
                            container
                            alignItems="center"
                            justify="flex-start"
                            xs={2}
                        >
                            <Grid item container xs={1}>
                                {shareOpen && (
                                    <Grid
                                        item
                                        container
                                        alignItems="flex-start"
                                        justify="center"
                                    >
                                        {" "}
                                        <Checkbox
                                            checked={rowSelected}
                                            onClick={e => e.stopPropagation()}
                                            onChange={() =>
                                                handleRowSelect(
                                                    carrier.rateQuotes,
                                                    carrier.carrierCode
                                                )
                                            }
                                            className={classes.share__checkbox}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item container xs={11}>
                                {carrier && (
                                    <CarrierLogo
                                        className={classes.carrier_logo}
                                        carrierCode={carrier.carrierCode}
                                        serviceLevel={carrier.serviceLevel}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" xs={9}>
                            {carrier &&
                                carrier.rateQuotes.map((quote, index) => (
                                    <QuoteTile
                                        getBol={getBol}
                                        gridSize={4}
                                        disabled={disabled}
                                        key={index}
                                        quote={quote}
                                        carrierCode={carrier.carrierCode}
                                        mode={carrier.rateQuotes[0].mode}
                                        cardIndex={props.index + 1}
                                        rateIndex={index + 1}
                                        isCarousel={false}
                                        shippedRateQuote={shippedRateQuote}
                                        canViewRateAmounts={canViewRateAmounts}
                                    />
                                ))}
                        </Grid>
                        <Grid
                            item
                            container
                            xs={1}
                            alignItems="flex-start"
                            justify="center"
                        >
                            {isThirdParty && (
                                <Typography
                                    variant="caption"
                                    className={classes.third__party__label}
                                >
                                    3rd Party Pricing
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

QuoteRow.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
    shareOpen: state.share.isOpen,
    rowSelected: ownProps.carrier.rateQuotes.every(rate =>
        isRateSelectedForShare(
            state.share.selections,
            rate,
            ownProps.carrier.carrierCode
        )
    ),
    shippedRateQuote: state.quotes.active.shippedRateQuote,
})

const mapDispatchToProps = dispatch => ({
    handleRowSelect: (rates, carrierCode) =>
        dispatch(quoteSelectRow(rates, carrierCode)),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(QuoteRow)
)

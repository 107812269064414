import React from "react"
import { goFetch } from "../http"

export const OPEN_MODAL = "OPEN_MODAL"
export const CLOSE_MODAL = "CLOSE_MODAL"
export const BOL_DOCUMENT_RESULT = "BOL_DOCUMENT_RESULT"
export const BOL_DOCUMENT_FETCH = "BOL_DOCUMENT_FETCH"
export const BOL_DOCUMENT_ERROR = "BOL_DOCUMENT_ERROR"
export const SHIPPING_LABEL_RESULT = "SHIPPING_LABEL_RESULT"
export const SHIPPING_LABEL_FETCH = "SHIPPING_LABEL_FETCH"
export const SHIPPING_LABEL_ERROR = "SHIPPING_LABEL_ERROR"

export const closeModal = () => ({ type: CLOSE_MODAL })
export const openModal = node => ({ type: OPEN_MODAL, node })

const bolResult = (shipmentId, data) => ({
    type: BOL_DOCUMENT_RESULT,
    shipmentId,
    data,
})
const labelResult = (shipmentId, data) => ({
    type: SHIPPING_LABEL_RESULT,
    shipmentId,
    data,
})
const bolFetch = shipmentId => ({ type: BOL_DOCUMENT_FETCH, shipmentId })
const labelFetch = shipmentId => ({ type: SHIPPING_LABEL_FETCH, shipmentId })
const bolError = shipmentId => ({ type: BOL_DOCUMENT_ERROR, shipmentId })
const labelError = shipmentId => ({ type: SHIPPING_LABEL_ERROR, shipmentId })

export function loadBoLImage(shipmentId) {
    return async (dispatch, getState) => {
        const bolDocument = getState().bolDocument[shipmentId]
        if (bolDocument && (bolDocument.data || bolDocument.isFetching))
            return bolDocument.data
        dispatch(bolFetch(shipmentId))
        try {
            const { data } = await goFetch(
                `/documents/image/${shipmentId}?type=bol`,
                {
                    method: "GET",
                    responseType: "blob",
                    headers: { Accept: "image/png" },
                },
                true
            )
            const bolData = await new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(data)
                reader.onloadend = () => resolve(reader.result)
                reader.onerror = reject
            })
            dispatch(bolResult(shipmentId, bolData))
            return bolData
        } catch (error) {
            console.error(error)
            dispatch(bolError(shipmentId))
        }
    }
}

export function loadShippingLabels(shipmentId) {
    return async (dispatch, getState) => {
        const labelDocument = getState().shippingLabelDocument[shipmentId]
        if (labelDocument && (labelDocument.data || labelDocument.isFetching))
            return labelDocument.data
        dispatch(labelFetch(shipmentId))
        try {
            const { data } = await goFetch(
                `/documents/image/${shipmentId}`,
                {
                    method: "GET",
                    responseType: "blob",
                    headers: { Accept: "image/png" },
                    params: { format: "png", type: "labels" },
                },
                true
            )

            const labelData = await new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(data)
                reader.onloadend = () => resolve(reader.result)
                reader.onerror = reject
            })
            dispatch(labelResult(shipmentId, labelData))
            return labelData
        } catch (error) {
            dispatch(labelError(shipmentId))
        }
    }
}

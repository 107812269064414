import React from "react"
import { FormattedMessage } from "react-intl"

export const invalidTrackingId = (
    <FormattedMessage
        id="validationConstants__invalidTrackingId"
        defaultMessage="Invalid Tracking ID."
    />
)
export const readyAndCloseTimeApartValue = (
    <FormattedMessage
        id="validationConstants__readyAndCloseTimeApartValue"
        defaultMessage="Ready time and close time needs to be at least 90 minutes apart."
    />
)
export const originCloseTimePast = (
    <FormattedMessage
        id="validationConstants__originCloseTimePast"
        defaultMessage="The origin close time is in the past in the origin time zone."
    />
)
export const originCloseTooEarly = (
    <FormattedMessage
        id="validationConstants__originCloseTooEarly"
        defaultMessage="The origin close time is less than 90 minutes from the current local time."
    />
)
export const pickupDatePast = (
    <FormattedMessage
        id="validationConstants__pickupDatePast"
        defaultMessage="The pickup date is in the past in the origin time zone."
    />
)
export const maximumWeightExceededVLTL = (
    <FormattedMessage
        id="validationConstants__maximumWeightExceededVLTL"
        defaultMessage="The maximum allowable Weight for Volume LTL is 44,000 lb"
    />
)
export const maximumWeightExceededLTL = (
    <FormattedMessage
        id="validationConstants__maximumWeightExceededLTL"
        defaultMessage="The maximum allowable Weight for LTL is 19,999 lbs"
    />
)
export const shipmentExceedLength = (
    <FormattedMessage
        id="validationConstants__shipmentExceedLength"
        defaultMessage="Shipments greater than or equal to 96 inches in Length are Over Length and may require an additional charge. Please select Over Length and try again."
    />
)
export const shipmentExtremeLength = (
    <FormattedMessage
        id="validationConstants__shipmentExtremeLength"
        defaultMessage="Shipments greater than 144 inches are Extreme Length and may require an additional charge. Please select Extreme Length and try again."
    />
)
export const fieldOverLimit = field => (
    <FormattedMessage
        id="validationConstants__fieldOverLimit"
        defaultMessage="{field} is over limit`"
        values={{ field: field.id ? <FormattedMessage {...field} /> : field }}
    />
)
export const hazMatUNLength = (
    <FormattedMessage
        id="validationConstants__hazMatUNLength"
        defaultMessage="Hazmat UN number must be 4 digit length."
    />
)
export const numberGreaterThanZero = field => (
    <FormattedMessage
        id="validationConstants__numberGreaterThanZero"
        defaultMessage="{field} must be greater than zero"
        values={{ field: field.id ? <FormattedMessage {...field} /> : field }}
    />
)
export const fieldRequired = field => (
    <FormattedMessage
        id="validationConstants__fieldRequired"
        defaultMessage="{field} is required"
        values={{ field: field.id ? <FormattedMessage {...field} /> : field }}
    />
)
export const fieldMustBeNumber = field => (
    <FormattedMessage
        id="validationConstants__fieldMustBeNumber"
        defaultMessage="{field} must be a number."
        values={{ field: field.id ? <FormattedMessage {...field} /> : field }}
    />
)
export const textLength = (field, length) => (
    <FormattedMessage
        id="validationConstants__textLength"
        defaultMessage="{field} can have a maximum of {length} characters"
        values={{
            field: field.id ? <FormattedMessage {...field} /> : field,
            length,
        }}
    />
)
export const phoneInvalid = field => (
    <FormattedMessage
        id="validationConstants__phoneInvalid"
        defaultMessage="{field} is not a valid phone number"
        values={{ field: field.id ? <FormattedMessage {...field} /> : field }}
    />
)
export const acceptTC = (
    <FormattedMessage
        id="validationConstants__acceptTC"
        defaultMessage="You have to accept terms and conditions"
    />
)

export const shipDateInvalid = field => (
    <FormattedMessage
        id="validationConstants__pickUpInvalid"
        defaultMessage="{field} is not a valid date"
        values={{ field: field.id ? <FormattedMessage {...field} /> : field }}
    />
)

export const customReferenceInvalid = field => (
    <FormattedMessage
        id="validationConstants__customReferencesInvalid"
        defaultMessage="{field} is required"
        values={{ field: field.id ? <FormattedMessage {...field} /> : field }}
    />
)

import {
    AUTHORIZATION_ERROR,
    AUTHORIZATION_FINISHED,
    INIT_FINISHED,
    TOKEN_PARSED,
    AUTHENTICATION_FINISHED,
} from "../actions/authorization"
import { USER_DISAUTHENTICATED } from "../actions/user"

export function authorization(
    state = {
        initFinished: false,
        authFinished: false,
        isFinished: false,
        isError: false,
        message: "",
        tokenParsed: false,
    },
    action = {}
) {
    switch (action.type) {
        case INIT_FINISHED:
            return { ...state, initFinished: true }
        case TOKEN_PARSED:
            return { ...state, tokenParsed: true }
        case AUTHORIZATION_ERROR:
            return {
                ...state,
                isError: true,
                message: action.error,
            }
        case AUTHORIZATION_FINISHED:
            return { ...state, isFinished: true }
        case AUTHENTICATION_FINISHED:
            return { ...state, authFinished: true }
        case USER_DISAUTHENTICATED:
            return {
                ...state,
                message: "",
                isError: false,
                tokenParsed: false,
                isFinished: false,
            }
        default:
            return state
    }
}

import React from "react"
import { Field } from "redux-form"
import { Grid, Button, Typography } from "@material-ui/core"
import { get } from "lodash"
import FormSwitch from "../../form/form-switch"
import { FormattedMessage } from "react-intl"

const LocationTitleBar = ({
    classes,
    isFreightDirect,
    isFreightBox,
    isRequested,
    isSuspended,
    location,
    owner,
    dirty,
    handleSubmit,
    handleSuspendProcessing,
    removeLocationProcessing,
    handleSave,
    gaCategory,
}) => {
    return (
        <Grid item container className={classes.location__section}>
            <Grid
                item
                container
                className={classes.location__card__title}
                justify="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Typography
                        className={classes.location__name}
                        color="inherit"
                        variant="h6"
                    >
                        {`${
                            get(location, "cpgName")
                                ? location.cpgName
                                : get(location, "shippingAddress.name")
                        }`}
                    </Typography>
                    {isFreightDirect && (
                        <Typography variant="subtitle1" color="primary">
                            <FormattedMessage
                                id="locations.users__freightDirect"
                                defaultMessage="FedEx Freight Direct"
                            />
                        </Typography>
                    )}
                    {isFreightBox && (
                        <Typography variant="subtitle1" color="primary">
                            <FormattedMessage
                                id="locations.users__freightBox"
                                defaultMessage="FedEx Freight Box"
                            />
                        </Typography>
                    )}
                    {isRequested && (
                        <Typography variant="subtitle1" color="secondary">
                            <FormattedMessage
                                id="locations.users__requestedAccess"
                                defaultMessage="Requested Access"
                            />
                        </Typography>
                    )}
                    {isSuspended && (
                        <Typography variant="subtitle1" color="secondary">
                            <FormattedMessage
                                id="locations.users__inactive"
                                defaultMessage="Inactive"
                            />
                        </Typography>
                    )}
                    {location?.fedexFreightAccount && (
                        <Typography variant="caption">
                            {isFreightDirect
                                ? [
                                      <FormattedMessage
                                          id="locations__FXFDShipperAccountNumber"
                                          defaultMessage="FXFD Shipper Account #"
                                      />,
                                      ": ",
                                      location?.fedexFreightAccount,
                                  ]
                                : [
                                      <FormattedMessage
                                          id="locations__shipperAccountNumber"
                                          defaultMessage="Shipper Account #"
                                      />,
                                      ": ",
                                      location?.fedexFreightAccount,
                                  ]}
                        </Typography>
                    )}
                    {location?.fedexBillToAccount && (
                        <Typography variant="caption">
                            {isFreightDirect
                                ? [
                                      <FormattedMessage
                                          id="locations__FXFDThirdPartyAccountNumber"
                                          defaultMessage="FXFD Third Party Account #"
                                      />,
                                      ": ",
                                      location?.fedexBillToAccount,
                                  ]
                                : [
                                      <FormattedMessage
                                          id="locations__thirdPartyAccountNumber"
                                          defaultMessage="Third Party Account #"
                                      />,
                                      ": ",
                                      location?.fedexBillToAccount,
                                  ]}
                        </Typography>
                    )}
                </Grid>
                {!isRequested && (
                    <Grid item>
                        <Grid item container justify="flex-end">
                            <Grid
                                item
                                container
                                justify="flex-end"
                                alignItems="flex-end"
                            ></Grid>
                            <Grid item>
                                <Field
                                    name={`isDefaultLocation`}
                                    component={FormSwitch}
                                    label={
                                        <FormattedMessage
                                            id="locations__setDefault"
                                            defaultMessage="Set default"
                                        />
                                    }
                                    id="setDefaultLocation"
                                    category={gaCategory}
                                />
                            </Grid>
                            <Button
                                id="locationUpdate"
                                className={classes.action__button}
                                variant="contained"
                                disabled={!dirty}
                                color="primary"
                                onClick={handleSubmit(handleSave)}
                            >
                                <FormattedMessage
                                    id="generalTerms__update"
                                    defaultMessage="Update"
                                />
                            </Button>
                            <Button
                                id="locationUnsubscribe"
                                className={classes.action__button}
                                color="secondary"
                                variant="outlined"
                                onClick={() => removeLocationProcessing()}
                            >
                                <FormattedMessage
                                    id="locations__unsubscribe"
                                    defaultMessage="Unsubscribe"
                                />
                            </Button>
                            {!owner &&
                                !isSuspended &&
                                get(
                                    location,
                                    "users[0].permissions.suspended.editable"
                                ) && (
                                    <Button
                                        className={classes.action__button}
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() =>
                                            handleSuspendProcessing(
                                                location,
                                                true
                                            )
                                        }
                                    >
                                        <FormattedMessage
                                            id="locations__deactivate"
                                            defaultMessage="Deactivate"
                                        />
                                    </Button>
                                )}
                            {!owner &&
                                isSuspended &&
                                get(
                                    location,
                                    "users[0].permissions.suspended.editable"
                                ) && (
                                    <Button
                                        className={classes.action__button}
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                            handleSuspendProcessing(
                                                location,
                                                false
                                            )
                                        }
                                    >
                                        <FormattedMessage
                                            id="locations__reactivate"
                                            defaultMessage="Reactivate"
                                        />
                                    </Button>
                                )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

export default LocationTitleBar

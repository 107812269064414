import {
    ADD_SAVED_ITEM,
    DELETE_SAVED_ITEM,
    ITEM_LIST_RESULT,
    UPDATE_ITEM_FIELD,
    INVALIDATE_ITEM_LIST,
    ITEM_FIELD_VALIDATION,
    ITEM_TOGGLE_EDIT,
    QUEUE_ITEM_SEARCH,
    ITEM_SEARCH_RESULT,
    ADD_NEW_EMPTY_ITEM,
    ITEM_TOGGLE_EXPAND,
    CANCEL_NEW_EMPTY_ITEM,
} from "../actions/item"

export function newItem(
    state = {
        freightClass: "",
        weight: "",
        length: "",
        width: "",
        height: "",
        packageType: "",
        pieces: "1",
        handlingUnits: "1",
        description: "",
        validation: {
            list: {},
            strict: false,
        },
        isDefault: true,
        sizeLocked: false,
        nmfc1: "",
        nmfc2: "",
        editMode: true,
        expanded: false,
        metadata: {},
    },
    action = {}
) {
    switch (action.type) {
        case ADD_SAVED_ITEM:
            return { ...state, ...action.record, editMode: false }
        case ITEM_TOGGLE_EDIT:
            return { ...state, editMode: !state.editMode }
        case UPDATE_ITEM_FIELD:
            return {
                ...state,
                [action.field]: action.value,
                isDefault: false,
                ...(action.field === "packageType" && {
                    sizeLocked: !!action.size,
                    ...action.size,
                }),
            }
        case ITEM_FIELD_VALIDATION:
            return {
                ...state,
                validation: {
                    list: action.validation,
                    strict: action.strict || state.validation.strict,
                },
            }
        default:
            return state
    }
}

function updateElements(list, action) {
    if (action.index == null || action.index === 0) {
        return [
            newItem(undefined),
            newItem(undefined, action),
            ...list.slice(1, list.length),
        ]
    }
    const result = [...list]
    result[action.index] = newItem(undefined, action)
    return result
}

export function item(
    state = {
        elements: [],
        search: {},
        searchInProgress: undefined,
        addingInProgress: false,
        isLoaded: false,
        validation: {
            list: {},
            strict: false,
        },
    },
    action = {}
) {
    switch (action.type) {
        case ADD_NEW_EMPTY_ITEM:
            return {
                ...state,
                addingInProgress: true,
                elements: [newItem({ expanded: true }), ...state.elements],
            }
        case CANCEL_NEW_EMPTY_ITEM:
            return {
                ...state,
                addingInProgress: false,
                elements: [...state.elements.slice(1)],
            }
        case ADD_SAVED_ITEM:
            return {
                ...state,
                addingInProgress: false,
                elements: updateElements(state.elements, action),
            }
        case DELETE_SAVED_ITEM:
            return {
                ...state,
                deleteInProgress: false,
                elements: state.elements.filter(d => d._id !== action.id),
            }
        case ITEM_LIST_RESULT:
            return {
                ...state,
                isLoaded: true,
                elements: [
                    ...action.items.map(record =>
                        newItem(undefined, {
                            type: ADD_SAVED_ITEM,
                            record,
                        })
                    ),
                ],
            }
        case QUEUE_ITEM_SEARCH:
            return { ...state, searchInProgress: action.id }
        case INVALIDATE_ITEM_LIST:
            return item()
        case ITEM_SEARCH_RESULT:
            return {
                ...state,
                searchInProgress: undefined,
                search: {
                    ...state.search,
                    [action.identifier]: action.items,
                },
            }
        case ITEM_TOGGLE_EDIT:
        case UPDATE_ITEM_FIELD:
        case ITEM_FIELD_VALIDATION: {
            const elements = [...state.elements]
            elements[action.index] = newItem(elements[action.index], action)
            return { ...state, elements }
        }
        case ITEM_TOGGLE_EXPAND: {
            return {
                ...state,
                elements: [...state.elements].map((record, index) =>
                    index === action.index
                        ? {
                              ...record,
                              expanded: !record.expanded,
                          }
                        : {
                              ...record,
                              expanded: false,
                          }
                ),
            }
        }
        default:
            return state
    }
}

import React from "react"
import Modal from "@material-ui/core/Modal"
import { useBookedShipmentContext } from "../providers/BookedShipmentProvider"
import { BookedShipment } from "./BookedShipment"
import { useAttachmentsContext } from "../providers/attachmentsProvider"

export const BookedShipmentModalWrapper = () => {
    const { open, closeModal, shipmentId } = useBookedShipmentContext()
    const { openDialog, printFile } = useAttachmentsContext()

    return (
        <Modal open={open} onClose={closeModal}>
            <BookedShipment
                closeModal={closeModal}
                shipmentId={shipmentId}
                openDialog={openDialog}
                printFile={file => {
                    printFile(file, shipmentId)
                }}
            />
        </Modal>
    )
}

import { errorMessage, uniqueRequest } from "./index"
import { goFetch } from "../http"

export const VAGUE_COMMODITY_LOAD = "VAGUE_COMMODITY_LOAD"
export const VAGUE_COMMODITY_LOAD_ERROR = "VAGUE_COMMODITY_LOAD_ERROR"
export const VAGUE_COMMODITY_RESULT = "VAGUE_COMMODITY_RESULT"

function vagueCommodityLoad() {
    return {
        type: VAGUE_COMMODITY_LOAD,
    }
}

function vagueCommodityLoadError(error) {
    return dispatch => {
        dispatch(errorMessage(error))
        dispatch({
            type: VAGUE_COMMODITY_LOAD_ERROR,
            error,
        })
    }
}

function vagueCommodityResult(vagueCommodities) {
    return {
        type: VAGUE_COMMODITY_RESULT,
        vagueCommodities,
    }
}

export function requestVagueCommodities() {
    return async (dispatch, getState) => {
        if (getState().vagueCommodities.isLoaded) return
        const processData = async () => {
            dispatch(vagueCommodityLoad())

            try {
                const { data: vagueCommodities } = await goFetch(
                    "/quotes/vague-commodities/",
                    {},
                    true
                )
                dispatch(vagueCommodityResult(vagueCommodities))
            } catch (error) {
                dispatch(vagueCommodityLoadError(error))
            }
        }
        const id = "vagueCommodities"
        return dispatch(uniqueRequest(id, processData))
    }
}

import React from "react"
import { FormattedMessage } from "react-intl"

export const allPaymentOptions = [
    {
        value: "PREPAID",
        label: (
            <FormattedMessage
                id="bookShipment.paymentTerms__prepaid"
                defaultMessage="Prepaid"
            />
        ),
    },
    {
        value: "COLLECT",
        label: (
            <FormattedMessage
                id="bookShipment.paymentTerms__collect"
                defaultMessage="Collect"
            />
        ),
    },
    {
        value: "COLLECT_SECTION_7",
        label: (
            <FormattedMessage
                id="bookShipment.paymentTerms__collectSection7"
                defaultMessage="Collect - Section 7 Signed"
            />
        ),
    },
    {
        value: "THIRD_PARTY",
        label: (
            <FormattedMessage
                id="bookShipment.paymentTerms__thirdParty"
                defaultMessage="Third Party"
            />
        ),
    },
]

export const inboundPaymentOptions = [
    {
        value: "COLLECT",
        label: (
            <FormattedMessage
                id="bookShipment.paymentTerms__collect"
                defaultMessage="Collect"
            />
        ),
    },
    {
        value: "COLLECT_SECTION_7",
        label: (
            <FormattedMessage
                id="bookShipment.paymentTerms__collectSection7"
                defaultMessage="Collect - Section 7 Signed"
            />
        ),
    },
]

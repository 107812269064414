import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Input from "@material-ui/core/Input"
import MenuItem from "@material-ui/core/MenuItem"
import {
    Grid,
    FormControl,
    InputLabel,
    FormHelperText,
} from "@material-ui/core"
import Select from "react-select"
import "react-select/dist/react-select.css"
import { get } from "lodash"
import FormattedError from "./formatted-error"
import { FormattedMessage } from "react-intl"

class Option extends React.Component {
    handleClick = event => {
        this.props.onSelect(this.props.option, event)
    }

    render() {
        const { children, isFocused, isSelected, onFocus, index } = this.props

        return (
            <MenuItem
                onFocus={onFocus}
                selected={isFocused}
                onClick={this.handleClick}
                dataTestId={`formSelectOption-${index}`}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400,
                }}
            >
                {get(children, "id") ? (
                    <FormattedMessage {...children} />
                ) : (
                    children
                )}
            </MenuItem>
        )
    }
}

function SelectWrapped(props) {
    const { selectClasses, options, classes, ...other } = props

    return (
        <Select
            {...other}
            classes={{ ...selectClasses, ...classes }}
            optionComponent={Option}
            noResultsText={
                <Typography variant="body2">
                    <FormattedMessage
                        id="generalTerms__noResults"
                        defaultMessage="No results found"
                    />
                </Typography>
            }
            options={options.map(option => ({
                value: option.value,
                label: option.label.id ? (
                    <FormattedMessage {...option.label} />
                ) : (
                    option.label
                ),
            }))}
        />
    )
}

const ITEM_HEIGHT = 48

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    chip: {
        margin: theme.spacing(0.25),
    },
    form__container: {
        padding: "3px",
        width: "100%",
    },
    "@global": {
        ".Select-arrow": {
            borderTopColor: "white !important",
            color: "white",
        },
        ".Select.is-open > .Select-control .Select-arrow": {
            borderBottomColor: "white !important",
            color: "white",
        },
        ".Select-control": {
            display: "flex",
            alignItems: "center",
            border: 0,
            height: "auto",
            background: "transparent",
            "&:hover": {
                boxShadow: "none",
            },
            color: "white",
        },
        ".Select-multi-value-wrapper": {
            flexGrow: 1,
            display: "flex",
            flexWrap: "wrap",
        },
        ".Select--multi .Select-input": {
            margin: 0,
        },
        ".Select.has-value.is-clearable.Select--single > .Select-control .Select-value": {
            padding: 0,
        },
        ".Select-noresults": {
            padding: theme.spacing(2),
        },
        ".Select-input": {
            display: "inline-flex !important",
            padding: 0,
            height: "auto",
        },
        ".Select--single > .Select-control .Select-value": {
            paddingLeft: "0px",
            color: "white",
        },
        ".Select-input input": {
            background: "transparent",
            border: 0,
            padding: 0,
            cursor: "default",
            display: "inline-block",
            fontFamily: "inherit",
            fontSize: "inherit",
            margin: 0,
            outline: 0,
            color: "white",
        },
        "#bill_to_location_picker": {
            color: "black !important",
        },
        ".Select-placeholder, .Select--single .Select-value": {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.pxToRem(16),
            padding: 0,
        },
        ".Select-placeholder": {
            opacity: 0.42,
            color: theme.palette.common.black,
        },
        ".Select-menu-outer": {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
            position: "absolute",
            left: 0,
            top: `calc(100% + ${theme.spacing(1)}px)`,
            width: "100%",
            maxHeight: ITEM_HEIGHT * 4.5,
        },
        ".Select.is-focused:not(.is-open) > .Select-control": {
            boxShadow: "none",
            background: "transparent",
        },
        ".Select.is-focused > .Select-control": {
            boxShadow: "none",
            background: "transparent",
        },
        ".Select-menu": {
            maxHeight: ITEM_HEIGHT * 4.5,
            overflowY: "auto",
        },
        ".Select-menu div": {
            boxSizing: "content-box",
        },
        ".Select.has-value.Select--single > .Select-control .Select-value .Select-value-label": {
            color: "white",
        },
        ".Select-value-label": {
            color: "white !important",
        },
        ".Select-arrow-zone, .Select-clear-zone": {
            cursor: "pointer",
            height: 21,
            width: 21,
            zIndex: 1,
            color: "white",
        },
        // Only for screen readers. We can't use display none.
        ".Select-aria-only": {
            position: "absolute",
            overflow: "hidden",
            clip: "rect(0 0 0 0)",
            height: 1,
            width: 1,
            margin: -1,
        },
        ".Select.is-disabled > .Select-control": {
            backgroundColor: "transparent",
        },
        ".Select.is-disabled.has-value.Select--single > .Select-control .Select-value .Select-value-label": {
            color: "currentColor",
        },
    },
})

const FormSelectAutocompleteDark = ({
    input,
    label,
    options,
    classes,
    InputProps,
    InputLabelProps,
    InputHelperProps,
    selectClasses,
    meta: { touched, error },
    required,
    dataTestId,
    ...rest
}) => (
    <Grid
        item
        className={`${classnames({
            "form-field": true,
            "form-field-touched": touched,
            "form-field-invalid": touched && error,
            "form-field-valid": !error,
        })} ${classes.form__container}`}
    >
        <Grid item container direction="column">
            <FormControl error={!!(touched && error)}>
                <InputLabel
                    {...InputLabelProps}
                    htmlFor={input.name}
                    required={required}
                >
                    {label}
                </InputLabel>
                <Input
                    placeholder=""
                    inputComponent={SelectWrapped}
                    selectClasses={selectClasses}
                    id="react-select-single"
                    inputProps={{
                        id: input.name,
                        classes,
                        selectClasses,
                        name: "react-select-single",
                        instanceId: "react-select-single",
                        simpleValue: true,
                        clearable: false,
                        options,
                        dataTestId,
                    }}
                    {...InputProps}
                    {...input}
                    {...rest}
                    onBlur={() => input.onBlur(input.value)}
                    dataTestId={dataTestId}
                />
                {touched && error && (
                    <FormHelperText {...InputHelperProps}>
                        <FormattedError error={error} />
                    </FormHelperText>
                )}
            </FormControl>
        </Grid>
    </Grid>
)

FormSelectAutocompleteDark.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FormSelectAutocompleteDark)

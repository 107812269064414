import {
    POSTAL_CODE_RESULT,
    POSTAL_CODE_ERROR,
    POSTAL_CODE_TIMEZONE,
} from "../actions/postal-code"

export function postalCode(state = {}, action) {
    switch (action.type) {
        case POSTAL_CODE_RESULT:
            return {
                ...state,
                [`${action.country}-${action.postalCode}`]: {
                    ...state[action.postalCode],
                    isError: false,
                    cities: action.cities || [],
                    state: action.state || "",
                    location: action.location,
                    timezone: action.timezone,
                },
            }
        case POSTAL_CODE_ERROR:
            return {
                ...state,
                [`${action.country}-${action.postalCode}`]: {
                    ...state[action.postalCode],
                    isError: true,
                },
            }
        case POSTAL_CODE_TIMEZONE:
            return {
                ...state,
                [`${action.country}-${action.postalCode}`]: {
                    ...state[action.postalCode],
                    timezone: action.value,
                },
            }
        default:
            return state
    }
}

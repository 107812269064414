import React, { useEffect, useState } from "react"
import ChargeField from "./ChargeField"
import DiscountField from "./DiscountField"
import { Box, Grid } from "@material-ui/core"
import RateField from "./RateField"
import ModalButtons from "./ModalButtons"
import GlobalSpinner from "../../../common/GlobalSpinner"
import { isEqual } from "lodash"
import HandleUnsavedChangesModal from "./HandleUnsavedChangesModal"

const PricingFormSimple = ({
    setOpen,
    classes,
    initialFormValues,
    dataFields,
    defaultValues,
    saveCustomPricing,
    resetCustomPricing,
    location,
    activeFilter,
    offshoreLocation,
    trackGA,
}) => {
    const [currentFormValues, setCurrentFormValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [isEdited, setEdited] = useState(false)
    const [openUnsavedModal, setOpenUnsavedModal] = useState(false)

    useEffect(() => {
        setCurrentFormValues({ ...initialFormValues })
    }, [])

    const handleCancel = () => {
        if (!isEdited) {
            setCurrentFormValues({ ...initialFormValues })
            setOpen(false)
        } else {
            setOpenUnsavedModal(true)
        }
    }

    const handleSave = async () => {
        setSaving(true)
        await saveCustomPricing(currentFormValues, location._id)
        setSaving(false)
        setOpen(false)
    }

    const handleReset = async () => {
        setSaving(true)
        await resetCustomPricing(location._id, activeFilter)
        setSaving(false)
        setOpen(false)
    }

    const checkEditStatus = () => {
        let isEdited = false
        if (!initialFormValues) {
            Object.keys(currentFormValues).forEach(key => {
                if (currentFormValues[key]) {
                    if (currentFormValues[key]?.value) {
                        isEdited = true
                    }
                    if (currentFormValues[key]?.isDisabled) {
                        isEdited = true
                    }
                }
            })
        } else if (!isEqual(currentFormValues, initialFormValues)) {
            isEdited = true
            if (
                (currentFormValues.grossPercentageDiscount?.isPercentage ===
                    true &&
                    currentFormValues.grossPercentageDiscount?.value === "") ||
                (currentFormValues.minimumCharge?.isPercentageOff === true &&
                    currentFormValues.minimumCharge.value === "")
            ) {
                isEdited = false
            }
        }
        setEdited(isEdited)
    }

    return (
        <Grid container>
            <HandleUnsavedChangesModal
                location={location}
                handleSave={handleSave}
                openUnsavedModal={openUnsavedModal}
                currentFormValues={currentFormValues}
                setOpenUnsavedModal={setOpenUnsavedModal}
                setCurrentFormValues={setCurrentFormValues}
                setOpen={setOpen}
                initialFormValues={initialFormValues}
                classes={classes}
                saving={saving}
            />
            <Grid container item style={{ marginTop: "16px" }}>
                {dataFields.map(field => {
                    return (
                        <Box
                            item
                            style={{
                                width: `${100 / dataFields.length}%`,
                                maxWidth: "33%",
                            }}
                        >
                            {field?.type === "charge" ? (
                                <ChargeField
                                    checkEditStatus={checkEditStatus}
                                    label={field?.label}
                                    defaultValue={
                                        defaultValues[field?.ref]
                                            ? defaultValues[field?.ref]?.value
                                            : null
                                    }
                                    initialValue={
                                        initialFormValues
                                            ? initialFormValues[field?.ref]
                                            : {}
                                    }
                                    fieldRef={field.ref}
                                    setCurrentFormValues={setCurrentFormValues}
                                    currentFormValues={currentFormValues}
                                />
                            ) : null}
                            {field?.type === "rate" ? (
                                <RateField
                                    checkEditStatus={checkEditStatus}
                                    label={field.label}
                                    defaultValue={
                                        defaultValues[field?.ref]
                                            ? defaultValues[field?.ref]?.value
                                            : null
                                    }
                                    initialValue={
                                        initialFormValues
                                            ? initialFormValues[field?.ref]
                                            : {}
                                    }
                                    fieldRef={field.ref}
                                    setCurrentFormValues={setCurrentFormValues}
                                    currentFormValues={currentFormValues}
                                />
                            ) : null}
                            {field?.type === "discount" ? (
                                <DiscountField
                                    checkEditStatus={checkEditStatus}
                                    label={field.label}
                                    defaultValue={
                                        defaultValues[field?.ref]
                                            ? defaultValues[field?.ref]?.value
                                            : null
                                    }
                                    initialValue={
                                        initialFormValues
                                            ? initialFormValues[field?.ref]
                                            : {}
                                    }
                                    fieldRef={field.ref}
                                    setCurrentFormValues={setCurrentFormValues}
                                    currentFormValues={currentFormValues}
                                />
                            ) : null}
                        </Box>
                    )
                })}
            </Grid>
            <ModalButtons
                handleCancel={handleCancel}
                handleSave={handleSave}
                disableSave={!isEdited}
                handleReset={handleReset}
                offshoreLocation={offshoreLocation}
                activeFilter={activeFilter}
                trackGA={trackGA}
            />
            {saving ? <GlobalSpinner /> : null}
        </Grid>
    )
}

export default PricingFormSimple

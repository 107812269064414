import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { get } from "lodash"
import { Field, formValues } from "redux-form"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import DeleteIcon from "@material-ui/icons/Delete"
import Button from "@material-ui/core/Button"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import QuoteItem from "./item"
import FormField from "../../form/form-field"
import FormSelectAutocompleteDark from "../../form/form-select-autocomplete-dark"
import { itemSearchHash } from "../../../actions/item"
import { join } from "../../../misc"
import { newFreightDirectItem } from "../../../reducers/search"
import FormSwitch from "../../form/form-switch"
import { FormattedMessage, injectIntl } from "react-intl"
import FormCheckbox from "../../form/form-checkbox"
import { lengthUnit, weightUnit } from "../../util/units"
import { Link } from "@material-ui/core"
import { packageTypeOptions } from "../../item/constants"

class HandlingUnitContainer extends Component {
    handlePackageType = value => {
        if (value === "PLT4848") {
            this.props.changeField("quote", `${this.props.prefix}.length`, 48)
            this.props.changeField("quote", `${this.props.prefix}.width`, 48)
        } else if (value === "PLT4840") {
            this.props.changeField("quote", `${this.props.prefix}.length`, 48)
            this.props.changeField("quote", `${this.props.prefix}.width`, 40)
        }
    }

    addItem = () => {
        this.props.changeField("quote", `${this.props.prefix}.items`, [
            ...this.props.items,
            newFreightDirectItem(),
        ])
        this.props.setHandlingUnitMode(false)
    }

    onRemoveItemClick = itemIndex => {
        const modifiedItems =
            this.props.items.length === 1
                ? [newFreightDirectItem()]
                : [...this.props.items].filter(
                      (value, index) => index !== itemIndex
                  )

        this.props.changeField(
            "quote",
            `${this.props.prefix}.items`,
            modifiedItems
        )
    }

    constructSubLabel = () => {
        const {
            items = [],
            count,
            preferredSystemOfMeasurement,
            intl,
        } = this.props
        const firstItemWeight = items?.[0]?.weight
            ? `${items?.[0]?.weight}${weightUnit(preferredSystemOfMeasurement)}`
            : ""
        const firstItemDescription = items?.[0]?.description
            ? `${items?.[0]?.description},  `
            : ""
        const huCount = intl.formatMessage(
            {
                id: "items.handlingUnit__huCount",
                defaultMessage: "H/U Count: {count}",
            },
            { count }
        )

        let itemInfo = `${firstItemDescription}${firstItemWeight}`

        items.forEach((item, index) => {
            const itemWeight = item.weight
                ? `${item.weight}${weightUnit(preferredSystemOfMeasurement)}`
                : ""
            const itemDescription = item.description
                ? `${item.description},  `
                : ""

            if (index !== 0) {
                itemInfo += `  |  ${itemDescription}${itemWeight}`
            }
        })

        return itemInfo ? `${itemInfo}  -  ${huCount}` : huCount
    }

    getHUWeight = (items = [], count = 1) => {
        const { prefix, changeField, isMultiClass } = this.props

        if (isMultiClass) {
            const result = items.reduce((acc, item) => {
                if (item.weight) {
                    return (acc += parseInt(get(item, "weight", 0), 10))
                } else return acc
            }, 0)

            const finalResult = result * count
            changeField(
                "quote",
                `${prefix}.totalHUWeight`,
                finalResult ? finalResult : 0
            )
            return finalResult ? finalResult : 0
        } else {
            const finalResult = parseInt(get(items[0], "weight", 0), 10)
            return finalResult ? finalResult : 0
        }
    }

    onMultiClassChange = value => {
        const { prefix, changeField, items, setHandlingUnitMode } = this.props

        if (!value) {
            changeField("quote", `${prefix}.items`, [items[0]])
            setHandlingUnitMode(true)
        } else {
            setHandlingUnitMode(false)
        }
    }

    onDoNotStackChange = e => {
        const { prefix, changeField } = this.props
        changeField("quote", `${prefix}.stackable`, !e.target.checked)
    }

    render() {
        const {
            isOpen,
            classes,
            inputHelperProps,
            inputSelectAutocompleteProps,
            inputLabelProps,
            selectClasses,
            inputProps,
            disabledInputProps,
            onRemoveClick,
            onExpandChange,
            prefix,
            index,
            preferredSystemOfMeasurement,
            items = [],
            formLabelProps,
            isMultiClass,
            count,
        } = this.props

        const subLabel = this.constructSubLabel()
        const huWeight = this.getHUWeight(items, count)

        const FormLabelProps = {
            classes: {
                root: classes.input__label,
                label: classes.input__label,
            },
        }
        const CheckboxProps = {
            classes: {
                root: classes.checkbox,
                colorSecondary: classes.checkbox,
                checked: classes.checkbox,
            },
        }

        return (
            <Fragment>
                <Grid
                    item
                    container
                    className={
                        index === 0
                            ? classes.quote__form__container__item__top
                            : classes.quote__form__container__item
                    }
                    xs={12}
                >
                    <ExpansionPanel
                        expanded={isOpen}
                        onChange={(_, open) => onExpandChange(open)}
                        className={classes.item__panel}
                    >
                        <ExpansionPanelSummary
                            expandIcon={
                                <ExpandMoreIcon
                                    className={classes.item__icon}
                                    dataTestId="toggleHu"
                                />
                            }
                            className={classes.item__panel}
                        >
                            <Grid
                                item
                                container
                                align-items="center"
                                justify="flex-start"
                                xs={10}
                            >
                                {!isOpen && (
                                    <Grid item container alignItems="center">
                                        <Grid
                                            item
                                            alignItems="center"
                                            className={classes.collapsed__info}
                                        >
                                            <Typography
                                                className={classes.quote__label}
                                                variant="caption"
                                            >
                                                {subLabel}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                {!isOpen && isMultiClass && (
                                    <Grid
                                        item
                                        container
                                        alignItems="center"
                                        justify="space-between"
                                    >
                                        {items.some(item => item.weight) && (
                                            <Typography
                                                variant="caption"
                                                className={classes.quote__label}
                                            >
                                                <FormattedMessage
                                                    id="getRates.items__totalHUWeight"
                                                    defaultMessage="Total Weight: {huWeight}{weightUnit}"
                                                    values={{
                                                        huWeight,
                                                        weightUnit: weightUnit(
                                                            preferredSystemOfMeasurement
                                                        ),
                                                    }}
                                                />
                                            </Typography>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                            <Grid
                                item
                                container
                                justify="flex-end"
                                alignItems="center"
                                xs={2}
                            >
                                <DeleteIcon
                                    onClick={e => onRemoveClick(e)}
                                    className={classes.item__icon}
                                    dataTestId="removeHu"
                                />
                            </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                            className={classes.item__panel__details}
                        >
                            <Grid xs={11} item container direction="column">
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    className={classes.huInfo}
                                >
                                    <Grid item xs={8}>
                                        <Field
                                            component={FormSwitch}
                                            name={`${prefix}.isMultiClass`}
                                            label={
                                                <FormattedMessage
                                                    id="getRates.items__multiClassHU"
                                                    defaultMessage="MultiClass H/U"
                                                />
                                            }
                                            formlabelprops={formLabelProps}
                                            onChange={(event, value) =>
                                                this.onMultiClassChange(value)
                                            }
                                            dataTestId="multiclassHu"
                                        />
                                    </Grid>
                                    <Grid item container xs={4}>
                                        <Field
                                            component={FormField}
                                            name={`${prefix}.count`}
                                            label={[
                                                "* ",
                                                <FormattedMessage
                                                    id="getRates.items__huCount"
                                                    defaultMessage="H/U Count"
                                                />,
                                            ]}
                                            InputProps={inputProps}
                                            InputLabelProps={inputLabelProps}
                                            InputHelperProps={inputHelperProps}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            component={
                                                FormSelectAutocompleteDark
                                            }
                                            name={`${prefix}.packageType`}
                                            label={[
                                                "* ",
                                                <FormattedMessage
                                                    id="getRates.items__packageType"
                                                    defaultMessage="Package Type"
                                                />,
                                            ]}
                                            options={packageTypeOptions}
                                            onChange={(e, value) =>
                                                this.handlePackageType(value)
                                            }
                                            InputProps={
                                                inputSelectAutocompleteProps
                                            }
                                            dataTestId="packageType"
                                            InputLabelProps={inputLabelProps}
                                            InputHelperProps={inputHelperProps}
                                            selectClasses={selectClasses}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={6}
                                        justify="center"
                                        alignItems="center"
                                    >
                                        {/* { !isMultiClass ? <Field
                                                component={FormField}
                                                name={`${prefix}.items[0].weight`}
                                                label={["* ", <FormattedMessage id="getRates.items__totalWeight" defaultMessage="Total Weight ({weightUnit})" values={{weightUnit}}/>]}
                                                InputProps={inputProps}
                                                InputLabelProps={inputLabelProps}
                                                InputHelperProps={inputHelperProps}
                                            /> : 
                                            <Typography className={classes.quote__label} variant="caption"> 
                                                <FormattedMessage id="getRates.items__huWeight" defaultMessage="H/U Weight: {huWeight}{weightUnit}" values={{huWeight, weightUnit}} />
                                            </Typography>
                                        } */}
                                        <Field
                                            component={FormField}
                                            name={
                                                isMultiClass
                                                    ? `${prefix}.totalHUWeight`
                                                    : `${prefix}.items[0].weight`
                                            }
                                            label={[
                                                "* ",
                                                <FormattedMessage
                                                    id="getRates.items__totalWeight"
                                                    defaultMessage="Total Weight ({weightUnit})"
                                                    values={{
                                                        weightUnit: weightUnit(
                                                            preferredSystemOfMeasurement
                                                        ),
                                                    }}
                                                />,
                                            ]}
                                            InputProps={
                                                isMultiClass
                                                    ? disabledInputProps
                                                    : inputProps
                                            }
                                            InputLabelProps={inputLabelProps}
                                            InputHelperProps={inputHelperProps}
                                            disabled={isMultiClass}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            component={FormField}
                                            name={`${prefix}.length`}
                                            label={[
                                                "* ",
                                                <FormattedMessage
                                                    id="getRates.items__length"
                                                    defaultMessage="Length ({lengthUnit})"
                                                    values={{
                                                        lengthUnit: lengthUnit(
                                                            preferredSystemOfMeasurement
                                                        ),
                                                    }}
                                                />,
                                            ]}
                                            InputProps={inputProps}
                                            InputLabelProps={inputLabelProps}
                                            InputHelperProps={inputHelperProps}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            component={FormField}
                                            name={`${prefix}.width`}
                                            label={[
                                                "* ",
                                                <FormattedMessage
                                                    id="getRates.items__width"
                                                    defaultMessage="Width ({lengthUnit})"
                                                    values={{
                                                        lengthUnit: lengthUnit(
                                                            preferredSystemOfMeasurement
                                                        ),
                                                    }}
                                                />,
                                            ]}
                                            InputProps={inputProps}
                                            InputLabelProps={inputLabelProps}
                                            InputHelperProps={inputHelperProps}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            component={FormField}
                                            name={`${prefix}.height`}
                                            label={[
                                                "* ",
                                                <FormattedMessage
                                                    id="getRates.items__height"
                                                    defaultMessage="Height ({lengthUnit})"
                                                    values={{
                                                        lengthUnit: lengthUnit(
                                                            preferredSystemOfMeasurement
                                                        ),
                                                    }}
                                                />,
                                            ]}
                                            InputProps={inputProps}
                                            InputLabelProps={inputLabelProps}
                                            InputHelperProps={inputHelperProps}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={3}
                                        justify="center"
                                        alignItems="flex-end"
                                        className={
                                            classes.stackableCheckboxContainer
                                        }
                                    >
                                        <Field
                                            name={`${prefix}.doNotStack`}
                                            label={
                                                <FormattedMessage
                                                    id="getRates.items__doNotStack"
                                                    defaultMessage="Do not stack"
                                                />
                                            }
                                            onChange={this.onDoNotStackChange}
                                            component={FormCheckbox}
                                            formlabelprops={FormLabelProps}
                                            CheckboxProps={CheckboxProps}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    className={classes.itemInfo}
                                >
                                    {items.map((item, itemIndex) => (
                                        <QuoteItem
                                            {...this.props}
                                            item={item}
                                            itemIndex={itemIndex}
                                            huIndex={index}
                                            formValues={formValues}
                                            prefix={`${prefix}.items[${itemIndex}]`}
                                            lastItem={
                                                itemIndex === items.length - 1
                                            }
                                            onRemoveItemClick={
                                                this.onRemoveItemClick
                                            }
                                            isMultiClass={isMultiClass}
                                        />
                                    ))}
                                </Grid>
                                <Grid
                                    item
                                    container
                                    className={classes.addItem__container}
                                >
                                    <Grid item container xs={6}>
                                        {isMultiClass && (
                                            <Button
                                                className={
                                                    classes.button__add_item
                                                }
                                                onClick={() => this.addItem()}
                                            >
                                                +{" "}
                                                <FormattedMessage
                                                    id="getRates.form__addItem"
                                                    defaultMessage="Add Item"
                                                />
                                            </Button>
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={6}
                                        alignItems="center"
                                        justify="flex-end"
                                    >
                                        <Link
                                            underline="hover"
                                            rel="noopener noreferrer"
                                            href="https://fedex.postclickmarketing.com/LTL-freight-classification-tool"
                                            target="_blank"
                                        >
                                            <Typography
                                                variant="caption"
                                                className={
                                                    classes.freightClass__helper
                                                }
                                            >
                                                <FormattedMessage
                                                    id="items__freightClassLink"
                                                    defaultMessage="What's my freight class?"
                                                />
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
            </Fragment>
        )
    }
}

const mapStateToProps = (
    state,
    { description, unNumber, origin, originAddress }
) => ({
    itemsDescription:
        state.item.search[itemSearchHash(description, origin, originAddress)],
    itemsUN: state.item.search[itemSearchHash(unNumber, origin, originAddress)],
    searchInProgress: state.item.searchInProgress,
})

const HandlingUnit = formValues(({ prefix }) => ({
    count: join(prefix, "count"),
    totalWeight: join(prefix, "totalWeight"),
    items: join(prefix, "items"),
    packageType: join(prefix, "packageType"),
    length: join(prefix, "length"),
    width: join(prefix, "width"),
    height: join(prefix, "height"),
    isMultiClass: join(prefix, "isMultiClass"),
    totalHUWeight: join(prefix, "totalHUWeight"),
}))(connect(mapStateToProps)(injectIntl(HandlingUnitContainer)))

export const QuoteHandlingUnits = ({
    fields,
    handlePanel,
    isOpen,
    origin,
    originAddress,
    setHandlingUnitMode,
    isFreightDirect,
    isFreightDirectReturns,
    ...props
}) => (
    <Fragment>
        {fields.map((item, index) => (
            <HandlingUnit
                {...props}
                isFreightDirect={isFreightDirect}
                isFreightDirectReturns={isFreightDirectReturns}
                prefix={item}
                key={index}
                index={index}
                origin={origin}
                originAddress={originAddress}
                onRemoveClick={e => {
                    e.stopPropagation()
                    fields.remove(index)
                    setHandlingUnitMode(true)
                }}
                onExpandChange={() => handlePanel(index)}
                setHandlingUnitMode={setHandlingUnitMode}
                isOpen={isOpen === index}
            />
        ))}
    </Fragment>
)

import { connect } from "react-redux"
import FormSelectAutocomplete from "./form-select-autocomplete"

const mapStateToProps = (state, props) => ({
    ...props,
    options: (
        state.postalCode[`${props.country}-${props.postalCode}`]?.cities ??
        [props.defaultCity].filter(x => x)
    ).map(x => ({ label: x, value: x })),
})

export default connect(mapStateToProps)(FormSelectAutocomplete)

import React, { useContext, useRef, useState } from "react"
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import SchedulePickupForm from "./SchedulePickupForm"
import { useEffect } from "react"
import { OrdersContext } from "../../../context/providers/OrdersProvider"
import { useDispatch } from "react-redux"
import { addShipment } from "../../../actions/track"
import { bulkCreateShipments } from "../../../actions/book-shipment-requests"
import { isFedExCarrier } from "../../../misc"

const SchedulePickupDialog = ({
    handleClose,
    open,
    successBookedShipments,
    setSuccessBookedShipments,
    setSendingBulkRequest,
    sendingBulkRequest,
    userLocations,
}) => {
    const [pickupOptionSelected, setPickupOptionSelected] = useState("")

    const [submitEnabled, setSubmitEnabled] = useState(false)
    const submitRef = useRef(null)
    const dispatch = useDispatch()

    const { savePickupSelection } = useContext(OrdersContext)

    const handleExternalSubmit = () => {
        if (submitRef.current) {
            submitRef.current.click()
        }
    }

    const handleSubmit = async () => {
        if (pickupOptionSelected === "scheduledPickup") {
            // this is needed because the submit button lives outside of the form
            handleExternalSubmit()
            savePickupSelection(pickupOptionSelected)
        } else {
            setSendingBulkRequest(true)
            await p44Proccess(
                successBookedShipments.filter(
                    rate =>
                        rate?.selectedRate?.carrierCode &&
                        !isFedExCarrier(rate?.selectedRate?.carrierCode)
                )
            )
            handleClose()
            savePickupSelection(pickupOptionSelected)
            setSendingBulkRequest(false)
        }
    }

    const p44Proccess = async group => {
        const getBookRequest = async payload => {
            const result = await bulkCreateShipments(payload, true)
            dispatch(addShipment(result))
            return result
        }

        const bookShipments = async rates => {
            await Promise.all(
                rates.map(async order => {
                    try {
                        const result = await getBookRequest(order)
                    } catch (err) {
                        console.error(err)
                    }
                    return null
                })
            )
        }

        return await bookShipments(group)
    }

    const handleCloseWrapper = async () => {
        setSendingBulkRequest(true)
        await p44Proccess(
            successBookedShipments.filter(
                rate =>
                    rate?.selectedRate?.carrierCode &&
                    !isFedExCarrier(rate?.selectedRate?.carrierCode)
            )
        )
        handleClose()
        savePickupSelection("cancelled")
        setSendingBulkRequest(false)
    }

    useEffect(() => {
        setPickupOptionSelected("")
    }, [open])

    const onChangeOption = event => {
        const value = event.target.value
        setPickupOptionSelected(value)
        setSubmitEnabled(value !== "")
    }
    return (
        <Dialog open={open} maxWidth="lg">
            <DialogTitle>
                <Typography variant="h6">
                    <FormattedMessage
                        id="orders.submit.pickup"
                        defaultMessage="Schedule Orders Pickup"
                    />
                </Typography>
            </DialogTitle>
            <DialogContent style={{ minWidth: "500px" }}>
                <FormControl>
                    <RadioGroup
                        value={pickupOptionSelected}
                        onChange={onChangeOption}
                    >
                        <FormControlLabel
                            value="standingPickup"
                            control={<Radio />}
                            label={
                                <FormattedMessage
                                    id="orders.pickup.standingPickup"
                                    defaultMessage="I have a standing pickup"
                                />
                            }
                        />
                        <FormControlLabel
                            value="pickupLater"
                            control={<Radio />}
                            label={
                                <FormattedMessage
                                    id="orders.pickup.later"
                                    defaultMessage="Schedule pickup later"
                                />
                            }
                        />
                        <FormControlLabel
                            value="scheduledPickup"
                            control={<Radio />}
                            label={
                                <FormattedMessage
                                    id="orders.pickup.schedulePickup"
                                    defaultMessage="Schedule pickup"
                                />
                            }
                        />
                    </RadioGroup>
                </FormControl>

                {pickupOptionSelected === "scheduledPickup" ? (
                    <SchedulePickupForm
                        submitRef={submitRef}
                        handleClose={handleClose}
                        successBookedShipments={successBookedShipments}
                        setSuccessBookedShipments={setSuccessBookedShipments}
                        setSendingBulkRequest={setSendingBulkRequest}
                        userLocations={userLocations}
                    />
                ) : null}
            </DialogContent>

            <DialogActions style={{ padding: "15px" }}>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Button
                        variant="outlined"
                        color="secondary"
                        htmlFor="contained-button-file"
                        onClick={() => {
                            handleCloseWrapper()
                        }}
                    >
                        <FormattedMessage
                            id="orders.pickup.cancel"
                            defaultMessage="Cancel"
                        />
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        disabled={!submitEnabled || sendingBulkRequest}
                        variant="contained"
                        color="secondary"
                    >
                        <FormattedMessage
                            id="orders.pickup.submit"
                            defaultMessage="Submit"
                        />
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default SchedulePickupDialog

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    ListItem,
    List,
    Box,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core"
import { LocalShipping } from "@material-ui/icons"
import React from "react"
import { FormattedMessage } from "react-intl"

const CancelPickupDialog = ({
    pickupCancel,
    open,
    shipmentsToCancel,
    handleNo,
    handleYes,
}) => {
    return (
        <Dialog open={open} onClose={() => {}}>
            <DialogTitle id="cancel-pickup-dialog-title">
                <FormattedMessage
                    id="dashboard.shipment.cancel.pickup.title"
                    defaultMessage="Are you sure you would like to cancel?"
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="cancel-pickup-dialog-description">
                    {shipmentsToCancel?.length > 1 && pickupCancel ? (
                        <Box>
                            <FormattedMessage
                                id="dashboard.shipment.cancel.multiple"
                                defaultMessage="We detected mulitiple shipments with the same pickup number: {pickupNumber}. Click yes to cancel all of the following shipments. Click no to keep your pickup."
                                values={{
                                    pickupNumber:
                                        shipmentsToCancel[0]?.identifiers
                                            ?.pickupNumber,
                                }}
                            />
                            <List>
                                {shipmentsToCancel.map(shipment => {
                                    return (
                                        <ListItem
                                            key={
                                                shipment?.identifiers
                                                    ?.internalTrackingNumber
                                            }
                                        >
                                            <ListItemIcon>
                                                <LocalShipping />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    shipment?.shipment?.origin
                                                        ?.name +
                                                    " → " +
                                                    shipment?.shipment
                                                        ?.destination?.name +
                                                    " (" +
                                                    shipment?.identifiers
                                                        ?.internalTrackingNumber +
                                                    ")"
                                                }
                                            />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Box>
                    ) : (
                        <FormattedMessage
                            id="dashboard.shipment__cancelShipmentMessage"
                            defaultMessage="Once you cancel the shipment this action can not be undone."
                        />
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions
                style={{
                    padding: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Button onClick={handleNo} variant="outlined" color="primary">
                    <FormattedMessage
                        id="generalTerms__no"
                        defaultMessage="No"
                    />
                </Button>
                <Button
                    onClick={handleYes}
                    variant="contained"
                    color="secondary"
                >
                    <FormattedMessage
                        id="generalTerms__yes"
                        defaultMessage="Yes"
                    />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CancelPickupDialog

import React, { Fragment } from "react"
import { Field } from "redux-form"
import Grid from "@material-ui/core/Grid"
import FormField from "../../form/form-field"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { FormattedMessage } from "react-intl"

export default function FreightDirectPiece({
    prefix,
    classes,
    inputLabelProps,
    inputProps,
    fields,
    weightUnit,
}) {
    return (
        <Fragment>
            <Grid
                item
                container
                xs={12}
                justify="space-between"
                alignItems="center"
            >
                <Typography className={classes.pieceTitle} variant="subtitle1">
                    <FormattedMessage
                        id="getRates.form__pieces"
                        defaultMessage="Pieces"
                    />
                </Typography>
                <Button
                    color="primary"
                    className={classes.button__add_item}
                    onClick={() => fields.push({ count: 1, weight: null })}
                >
                    +{" "}
                    <FormattedMessage
                        id="getRates.form__addPiece"
                        defaultMessage="Add Piece"
                    />
                </Button>
            </Grid>
            {fields.map((member, index) => {
                return (
                    <Fragment>
                        <Grid item xs={5}>
                            <Field
                                component={FormField}
                                name={`${prefix}.freightDirectPieces[${index}].count`}
                                label={
                                    <FormattedMessage
                                        id="getRates.form__freightDirectPiece__Count"
                                        defaultMessage="Count"
                                    />
                                }
                                InputProps={inputProps}
                                InputLabelProps={inputLabelProps}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <Field
                                component={FormField}
                                name={`${prefix}.freightDirectPieces[${index}].weight`}
                                label={
                                    <FormattedMessage
                                        id="getRates.form__freightDirectPiece__WeightPerPiece"
                                        defaultMessage="Weight per Piece ({weightUnit})"
                                        values={{ weightUnit }}
                                    />
                                }
                                InputProps={inputProps}
                                InputLabelProps={inputLabelProps}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            container
                            alignItems="center"
                            justify="center"
                            className={classes.piecesCloseButton}
                        >
                            <CloseIcon
                                className={classes.item__icon}
                                onClick={() => {
                                    if (fields.length > 1) {
                                        fields.remove(index)
                                    }
                                }}
                            />
                        </Grid>
                    </Fragment>
                )
            })}
        </Fragment>
    )
}

import React, { memo, useState } from "react"
import {
    Badge,
    Grid,
    IconButton,
    Popover,
    SnackbarContent,
    Typography,
} from "@material-ui/core"
import { FormattedMessage, useIntl } from "react-intl"
import CloseIcon from "@material-ui/icons/Close"
import WarningIcon from "@material-ui/icons/Warning"
import { filterRatesError } from "../../actions/quote-request"
import { isPresentMatchingErrors } from "../util/ordersUtils"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
    errorSnackbar: {
        backgroundColor: "#ffa000",
        maxWidth: "100%",
    },
    headerSnackbar: {
        backgroundColor: "#ffffff",
        maxWidth: "100%",
    },
    headerSnackbarIcon: {
        backgroundColor: "#ffa000",
    },
    warningText: {
        color: "black",
    },
    badge: {
        badge: {
            top: 1,
            right: -15,
        },
    },
    buttonGrouping: {
        padding: "0px 15px 0px 15px",
    },
})

const PopOverRatesError = props => {
    const { order, column } = props
    const ratesError = filterRatesError(
        order?.result?.rateQuotes,
        order?.result?.ratesError
    )

    if (ratesError?.length == 0) {
        return null
    }
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const id = `simple-popover-error-${order.id}`
    const classes = useStyles()
    const open = Boolean(anchorEl)

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="secondary"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    )
    const intl = useIntl()

    const errorsAndWarningsToMatch = [
        `${intl.formatMessage({
            id: "getRates.capacityError",
            defaultMessage: "shipment exceeds",
        })}`,
        `${intl.formatMessage({
            id: "book.capLoad__dialogContent.first",
            defaultMessage:
                "This shipment could incur additional charges based on the size and your account-specific rates. Please contact Volume Services at 1.888.465.5646 to understand the precise cost of your shipment. For more information: ",
        })}`,
        `${intl.formatMessage({
            id: "rateResults__extremeLengthWarning",
            defaultMessage:
                "This shipment could incur additional charges based on the handling unit length and your account-specific rates.",
        })}`,
    ]

    const isPresentAnyCapacityOrCapLoadWarningOrExtremeLengthError = isPresentMatchingErrors(
        ratesError,
        errorsAndWarningsToMatch
    )

    return (
        <Grid item container className={classes.buttonGrouping}>
            <Grid item>
                <Badge
                    badgeContent={ratesError.length}
                    color={
                        isPresentAnyCapacityOrCapLoadWarningOrExtremeLengthError
                            ? "error"
                            : "secondary"
                    }
                    classes={{
                        badge: classes.badge,
                    }}
                >
                    <IconButton
                        aria-describedby={id}
                        aria-label="expand row"
                        size="small"
                        onClick={handleClick}
                    >
                        <WarningIcon
                            color={
                                isPresentAnyCapacityOrCapLoadWarningOrExtremeLengthError
                                    ? "error"
                                    : "default"
                            }
                        />
                    </IconButton>
                </Badge>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <Grid>
                        <SnackbarContent
                            action={action}
                            className={classes.headerSnackbar}
                            message={
                                <Typography
                                    variant="body2"
                                    className={classes.warningText}
                                >
                                    <FormattedMessage
                                        id="rateResults__carrierRatesError"
                                        defaultMessage="At least one carrier is having trouble retrieving rates.  View more information"
                                    />
                                </Typography>
                            }
                        />
                        {ratesError
                            .sort((err1, err2) =>
                                err1?.carrierCode && err2?.carrierCode
                                    ? err1.carrierCode.localeCompare(
                                          err2.carrierCode
                                      )
                                    : -1
                            )
                            .map((rateError, i) => {
                                const errorCarrier =
                                    rateError.mode === "LTL_DIRECT"
                                        ? "FXFD"
                                        : rateError?.carrierCode ?? ""
                                return (rateError?.errorMessages ?? []).map(
                                    (error, j) => {
                                        const errorDiagnostic =
                                            error?.diagnostic
                                        const errorMessage = error?.message
                                        const source =
                                            error?.source === "SYSTEM"
                                                ? "Project 44"
                                                : "Carrier Error"
                                        const errorText = errorDiagnostic
                                            ? `${errorCarrier}: ${errorMessage} (${source}) - ${errorDiagnostic}`
                                            : `${errorCarrier}: ${errorMessage} (${source})`
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                key={`${i}_${j}`}
                                            >
                                                <SnackbarContent
                                                    className={
                                                        classes.errorSnackbar
                                                    }
                                                    message={
                                                        <Typography
                                                            variant="body2"
                                                            id={`rateError${i}_errorMessages${j}`}
                                                            className={
                                                                classes.warningText
                                                            }
                                                        >
                                                            {errorCarrier ===
                                                                "FXFE" ||
                                                            errorCarrier ===
                                                                "FXNL"
                                                                ? React.createElement(
                                                                      "div",
                                                                      {
                                                                          dangerouslySetInnerHTML: {
                                                                              __html: errorText,
                                                                          },
                                                                      }
                                                                  )
                                                                : errorText}
                                                        </Typography>
                                                    }
                                                />
                                            </Grid>
                                        )
                                    }
                                )
                            })}
                    </Grid>
                </Popover>
            </Grid>
        </Grid>
    )
}
export default memo(PopOverRatesError)

import { goFetch } from "../http"
import { LOCATION_UPDATE, updateUserLocation } from "./user"

export const PUERTO_RICO_PRICING_RECEIVED = "PUERTO_RICO_PRICING_RECEIVED"
export const ALASKA_PRICING_RECEIVED = "ALASKA_PRICING_RECEIVED"
export const HAWAII_PRICING_RECEIVED = "HAWAII_PRICING_RECEIVED"
export const ZIPCODE_SPECIAL_HANDLING_RECEIVED =
    "ZIPCODE_SPECIAL_HANDLING_RECEIVED"

export const getPuertoRicoPricing = state => {
    return async dispatch => {
        try {
            const { data } = await goFetch(
                `/locations/offshore/puertoRico/pricing`,
                {
                    method: "GET",
                    credentials: "same-origin",
                },
                true
            )
            dispatch({ type: PUERTO_RICO_PRICING_RECEIVED, data })
        } catch (error) {
            console.error(error)
        }
    }
}

export const getAlaskaPricing = state => {
    return async dispatch => {
        //this needs to be implemented
        try {
            const { data } = await goFetch(
                `/locations/offshore/alaska/pricing`,
                {
                    method: "GET",
                    credentials: "same-origin",
                },
                true
            )
            dispatch({ type: ALASKA_PRICING_RECEIVED, data })
        } catch (error) {
            console.error(error)
        }
    }
}

export const getHawaiiPricing = state => {
    return async dispatch => {
        //this needs to be implemented
        try {
            const { data } = await goFetch(
                `/locations/offshore/hawaii/defaultPricing`,
                {
                    method: "GET",
                    credentials: "same-origin",
                },
                true
            )
            dispatch({ type: HAWAII_PRICING_RECEIVED, data })
        } catch (error) {
            console.error(error)
        }
    }
}

export const updateCustomPuertoRicoPricing = (
    updatedPricing,
    locationId,
    activeFilter
) => {
    return async dispatch => {
        try {
            const { data } = await goFetch(
                `locations/offshore/puertoRico/${activeFilter}/${locationId}`,
                {
                    method: "PUT",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: updatedPricing,
                },
                true
            )
            await dispatch(updateUserLocation(data))
        } catch (error) {
            console.error(error)
        }
    }
}

export const updateCustomAlaskaPricing = (
    updatedPricing,
    locationId,
    activeFilter
) => {
    const pricing =
        activeFilter === "bolFee"
            ? updatedPricing[activeFilter]
            : updatedPricing
    return async dispatch => {
        try {
            const { data } = await goFetch(
                `locations/offshore/alaska/${activeFilter}/${locationId}`,
                {
                    method: "PUT",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: pricing,
                },
                true
            )
            dispatch({ type: LOCATION_UPDATE, data })
        } catch (error) {
            console.error(error)
        }
    }
}

export const resetCustomPricing = (state, locationId, activeFilter) => {
    return async dispatch => {
        try {
            const { data } = await goFetch(
                `locations/offshore/${state}/reset/${activeFilter}/${locationId}`,
                {
                    method: "PUT",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        locationId,
                    },
                },
                true
            )
            await dispatch(updateUserLocation(data))
        } catch (error) {
            console.error(error)
        }
    }
}

//TODO implement update endpoint
export const updateCustomHawaiiPricing = (
    updatedPricing,
    locationId,
    activeFilter
) => {
    return async dispatch => {
        try {
            const { data } = await goFetch(
                `locations/offshore/hawaii/${activeFilter}/${locationId}`,
                {
                    method: "PUT",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: updatedPricing,
                },
                true
            )
            dispatch({ type: LOCATION_UPDATE, data })
        } catch (error) {
            console.error(error)
        }
    }
}

export const updateCustomPickupAndDeliveryCharge = (newCharges, locationId) => {
    return async dispatch => {
        try {
            const { data } = await goFetch(
                `locations/offshore/puertoRico/customerPickupAndDeliveryCharge/${locationId}`,
                {
                    method: "PUT",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: newCharges,
                },
                true
            )
            dispatch({ type: LOCATION_UPDATE, data })
        } catch (error) {
            console.error(error)
        }
    }
}

export const getZipcodeSpecialHandling = (zipcode, state) => {
    return async dispatch => {
        try {
            let dataRaw
            switch (state) {
                case "HI": {
                    const { data } = await goFetch(
                        `/locations/hawaiiSpecialHandling/zipcode/${zipcode}`,
                        {
                            method: "GET",
                            credentials: "same-origin",
                        },
                        true
                    )
                    dataRaw = data
                    break
                }
                case "AK": {
                    const { data } = await goFetch(
                        `/locations/alaskaSpecialHandling/zipcode/${zipcode}`,
                        {
                            method: "GET",
                            credentials: "same-origin",
                        },
                        true
                    )
                    dataRaw = [data]
                    break
                }
                default:
                    console.warn("Invalid zipcode special handling")
            }

            if (dataRaw) {
                dispatch({
                    type: ZIPCODE_SPECIAL_HANDLING_RECEIVED,
                    data: { state, dataRaw },
                })
            }
        } catch (error) {
            console.error("Error retreiving zipcode special handling", error)
        }
    }
}

import { errorMessage } from "./index"
import { goFetch } from "../http"
import { setChannel } from "./chat"
import { trackGAEvent } from "./user"

export const SHARE_MODE_CHANGE = "SHARE_MODE_CHANGE"
export const SHARE_FIELD_CHANGE = "SHARE_FIELD_CHANGE"
export const SEND_TEXT_ERROR = "SEND_TEXT_ERROR"
export const SHARE_KEY = "SHARE_KEY"
export const SHARE_STATUS_RESULT = "SHARE_STATUS_RESULT"
export const SHARE_STATUS_LOAD = "SHARE_STATUS_LOAD"
export const SHARE_STATUS_ERROR = "SHARE_STATUS_ERROR"
export const REMOVE_SHARE_ITEM = "REMOVE_SHARE_ITEM"

export function shareModeChange(value, shipmentId = "") {
    return (dispatch, getState) => {
        const quote = getState().quotes.list.items[shipmentId]
        const payload = quote && {
            resource: "quotes",
            shipmentId: quote.identifiers.internalTrackingNumber,
            referenceNumber: quote.identifiers.referenceNumber,
        }
        return dispatch({ type: SHARE_MODE_CHANGE, value, payload })
    }
}

export function onFieldChange(field, value) {
    return {
        type: SHARE_FIELD_CHANGE,
        field,
        value,
    }
}

function sendTextError(error) {
    return dispatch => {
        dispatch(errorMessage(error))
        dispatch({
            type: SEND_TEXT_ERROR,
            error,
        })
    }
}

export function createShare(
    values,
    isBookShipmentWorkflow,
    selectedRates,
    shipmentId
) {
    return async (dispatch, getState) => {
        const shareState = getState().share
        const { cpg } = getState().search

        const selections = shareState.selections?.map(
            ({ name, alternateRateQuote, mode, direction }) => ({
                carrierCode: name,
                alternateRateQuote,
                mode,
                direction,
            })
        )

        const data = {
            recipients: values.mergedRecipients,
            markupAmount: values.markupAmount,
            markupType: values.markupType ? "markupPercentage" : "markupAmount",
            ...(isBookShipmentWorkflow
                ? {
                      internalTrackingNumber: shipmentId,
                      selectedRates: Array.from(selectedRates),
                  }
                : {
                      internalTrackingNumber: shareState.shipmentId,
                      selections,
                  }),
            customMessage: values.customMessage ?? null,
        }

        try {
            await goFetch(
                "/share",
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data,
                },
                true
            )

            if (values.recipients.some(r => r.value.type === "phone")) {
                dispatch(trackGAEvent("Quote", "Share a quote phone", cpg))
            }
            if (values.recipients.some(r => r.value.type === "email")) {
                dispatch(trackGAEvent("Quote", "Share a quote email", cpg))
            }

            dispatch(shareModeChange(false))
        } catch (error) {
            dispatch(sendTextError(error))
            throw new Error()
        }
    }
}

function shareStatusLoad() {
    return { type: SHARE_STATUS_LOAD }
}

function shareStatusError(error) {
    return dispatch => {
        dispatch(errorMessage(error))
        dispatch({ type: SHARE_STATUS_ERROR, error })
    }
}

function shareStatusResult(shareStatus) {
    return dispatch => {
        dispatch({ type: SHARE_STATUS_RESULT, shareStatus })
        dispatch(setChannel(shareStatus.channelId))
    }
}

const setShareKey = shareKey => ({ type: SHARE_KEY, shareKey })

export function requestShareStatus(shareKey) {
    return async dispatch => {
        try {
            dispatch(setShareKey(shareKey))
            dispatch(shareStatusLoad())
            if (shareKey) {
                const { data: shareStatus } = await goFetch(
                    `/share/${shareKey}`,
                    {
                        method: "GET",
                        credentials: "same-origin",
                    },
                    true,
                    shareKey
                )
                dispatch(shareStatusResult(shareStatus))
            }
        } catch (error) {
            dispatch(shareStatusError(error))
        }
    }
}

export function removeShareItem(index) {
    return { type: REMOVE_SHARE_ITEM, index }
}

import moment from "moment-timezone"
import axios from "axios"
import get from "lodash"
import qs from "qs"
import { loadData } from "./misc"

const BASE_URL = window._env_.REACT_APP_BACKEND_URL || "http://localhost:8080"
const VERSION = window._env_.REACT_APP_BACKEND_VERSION || "/api/1"
const BASE_URL_WITH_VERSION = `${BASE_URL}${VERSION}`

const VERSION2 = window._env_.REACT_APP_BACKEND_VERSION2 || "/api/2"
const BASE_URL_WITH_VERSION2 = `${BASE_URL}${VERSION2}`

const myAxios = axios.create({
    paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" }),
})

export class ResponseError extends Error {
    constructor(error, response, trackingLabel) {
        super(error)
        this.name = "ResponseError"
        this.response = response
        this.trackingLabel = trackingLabel
    }
}

export function authHeader(shareKey) {
    if (shareKey) return { "Auth-Share-Key": shareKey }
    const token = loadData("token")
    if (!token) return undefined
    return { Authorization: `Bearer ${token}` }
}

function composeErrorMessage(route, options, response) {
    let msg = "The request encountered an error, please try again later."
    let trackingLabel =
        `Location: ${window.location.href}, Endpoint: ${route},` +
        ` Method: ${options.method}`
    const { data, status } = response || {}
    if (data && data.message && data.message.errors) {
        const message = get(data, "message.errors[0].message", "").replace(
            "null",
            "empty"
        )
        msg = msg.concat(` ${message}`)
    }
    trackingLabel = trackingLabel.concat(`, Status code: ${status}`)
    throw new ResponseError(msg, data, trackingLabel)
}

export async function goFetch(route, options, sendAuth, shareKey, accept) {
    let startTime
    let endTime
    const { validErrorCodes = [], errorHandler, ...innerOptions } =
        options || {}
    try {
        const headers = {
            Accept: accept ? accept : "application/json",
            "Content-Type": "application/json",
            ...options.headers,
            ...(sendAuth && authHeader(shareKey)),
        }
        const sentOptions = {
            ...innerOptions,
            headers,
            baseURL: BASE_URL_WITH_VERSION,
        }
        startTime = moment()
        const response = await myAxios(route, sentOptions)
        endTime = moment()
        return response
    } catch (error) {
        endTime = endTime || moment()
        const response = error && error.response
        // This is not a request error, just pass it through
        if (!response) throw error
        if (validErrorCodes.includes(response.status)) return response
        if (errorHandler && errorHandler(response)) return response
        return composeErrorMessage(route, innerOptions, response)
    }
}

export async function goFetchV2(route, options, sendAuth, shareKey, accept) {
    let startTime
    let endTime
    const { validErrorCodes = [], errorHandler, ...innerOptions } =
        options || {}
    try {
        const headers = {
            Accept: accept ? accept : "application/json",
            "Content-Type": "application/json",
            ...options.headers,
            ...(sendAuth && authHeader(shareKey)),
        }
        const sentOptions = {
            ...innerOptions,
            headers,
            baseURL: BASE_URL_WITH_VERSION2,
        }
        startTime = moment()
        const response = await myAxios(route, sentOptions)
        endTime = moment()
        return response
    } catch (error) {
        endTime = endTime || moment()
        const response = error && error.response
        // This is not a request error, just pass it through
        if (!response) throw error
        if (validErrorCodes.includes(response.status)) return response
        if (errorHandler && errorHandler(response)) return response
        return composeErrorMessage(route, innerOptions, response)
    }
}

export async function goHealthFetch(route, options) {
    let startTime
    let endTime
    const { validErrorCodes = [], errorHandler, ...innerOptions } =
        options || {}
    try {
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...options.headers,
        }
        const sentOptions = {
            ...innerOptions,
            headers,
            baseURL: BASE_URL,
        }
        startTime = moment()
        const response = await axios(route, sentOptions)
        endTime = moment()
        return response
    } catch (error) {
        endTime = endTime || moment()
        const response = error && error.response
        // This is not a request error, just pass it through
        if (!response) throw error
        if (validErrorCodes.includes(response.status)) return response
        if (errorHandler && errorHandler(response)) return response
        return composeErrorMessage(route, innerOptions, response)
    }
}

import React, { Fragment } from "react"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import Grid from "@material-ui/core/Grid"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import { FormattedMessage } from "react-intl"
import { Field, FormSection, formValues } from "redux-form"
import FormSelect from "../../form/form-select"
import { MiscellaneousInfoFields, USMCAFields } from "../USMCA/USMCAForm"
import { withStyles } from "@material-ui/core/styles"
import { attachmentOptionsMessages } from "../../../misc"
import { Card, CardContent } from "@material-ui/core"

const styles = theme => ({
    formWrapper: {
        backgroundColor: "#F2F2F2",
        overflowY: "visible",
        padding: "16px",
    },
})

const generateAttachmentOptionList = [
    {
        value: "USMCA",
        label: attachmentOptionsMessages["USMCA"],
    },
]

let GenerateDocumentDialog = ({
    classes,
    documentCategory,
    onClose,
    submitting,
    locationId,
    shipmentId,
    gaCategory,
}) => (
    <Fragment>
        <DialogTitle id="alert-dialog-title">
            <FormattedMessage
                id="documents.add__new__document"
                defaultMessage="Add New Document"
            />
        </DialogTitle>
        <DialogContent>
            <Grid item container>
                <Field
                    name="documentCategory"
                    label={
                        <FormattedMessage
                            id="documents.upload__selectFileCategory"
                            defaultMessage="Select File Category"
                        />
                    }
                    component={FormSelect}
                    options={generateAttachmentOptionList}
                    required
                    category={gaCategory}
                />
            </Grid>
            <Grid item container className={classes.formWrapper}>
                <Fragment>
                    {documentCategory === "USMCA" && (
                        <FormSection name="usmca">
                            <USMCAFields
                                canAddGoods={!shipmentId}
                                gaCategory={gaCategory}
                            />
                        </FormSection>
                    )}
                    {documentCategory && (
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <MiscellaneousInfoFields
                                        shipmentId={shipmentId}
                                        locationId={locationId}
                                        gaCategory={gaCategory}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Fragment>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" color="secondary" onClick={onClose}>
                <FormattedMessage
                    id="generalTerms__cancel"
                    defaultMessage="Cancel"
                />
            </Button>
            <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={submitting}
            >
                <FormattedMessage
                    id="generalTerms.create__document"
                    defaultMessage="Create document"
                />
            </Button>
        </DialogActions>
    </Fragment>
)

GenerateDocumentDialog = withStyles(styles)(
    formValues("documentCategory")(GenerateDocumentDialog)
)

export { GenerateDocumentDialog }

import React, { useState } from "react"
import TileGroup from "./TileGroup"
import { Button, Grid, Modal } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import ManageTiles from "./ManageTiles"
import { connect } from "react-redux"
import { requestTileCountWithFilters } from "../../../actions/track"
import { trackGAEvent } from "../../../actions/user"

const DashboardTiles = ({
    tileSettings,
    setActiveTileInStore,
    fetchElementsOnFilterApply,
    setToShipmentsOnly,
    setIsLoading,
    adjustPage,
    activeTileInStore,
    getTileCount,
    shipmentsFetching,
    isShipmentsLoaded,
    trackGA,
}) => {
    const [manageTilesOpen, setManageTilesOpen] = useState(false)

    const activateTile = async value => {
        setIsLoading(true)
        setActiveTileInStore(value)
        await fetchElementsOnFilterApply()
        adjustPage(1)
        setToShipmentsOnly()
        setIsLoading(false)
    }

    const onClose = () => {
        setManageTilesOpen(false)
        trackGA("Dashboard Tiles", "Manage - Close Modal")
    }

    return (
        <Grid item container style={{ paddingTop: "15px" }}>
            <Grid item container xs={10}>
                <TileGroup
                    tileSettings={tileSettings}
                    activeTile={activeTileInStore}
                    activateTile={activateTile}
                    getTileCount={getTileCount}
                    shipmentsFetching={shipmentsFetching}
                    isShipmentsLoaded={isShipmentsLoaded}
                    trackGA={trackGA}
                />
            </Grid>
            <Grid item container xs={2} justify="flex-end" alignItems="center">
                <Button
                    color="primary"
                    onClick={() => {
                        setManageTilesOpen(true)
                        trackGA("Dashboard Tiles", "Manage - Open Modal")
                    }}
                >
                    <FormattedMessage
                        id="dashboard.tiles__manage"
                        defaultMessage="Manage Dashboard"
                    />
                </Button>
            </Grid>
            <Modal open={manageTilesOpen} onClose={onClose}>
                <ManageTiles
                    onClose={onClose}
                    activateTile={activateTile}
                    trackGA={trackGA}
                />
            </Modal>
        </Grid>
    )
}

const mapStateToProps = state => {
    const tileSettings = state?.user?.profile?.preferences?.tileSettings
    const activeTileInStore = state?.dashboard?.activeDashboardTile
    const shipmentsFetching = state?.shipment?.isFetching
    const isShipmentsLoaded = state?.shipment?.isLoaded

    return {
        tileSettings,
        activeTileInStore,
        shipmentsFetching,
        isShipmentsLoaded,
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    getTileCount: dashboardTile =>
        dispatch(requestTileCountWithFilters(dashboardTile)),
    trackGA: (category, action, label) =>
        dispatch(trackGAEvent(category, action, label)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTiles)

import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import MenuItem from "@material-ui/core/MenuItem"
import { Grid } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    billTo__label: {},
    billTo__menuItem: {
        padding: "10px",
        display: "flex",
        height: "unset",
        borderBottom: "solid 1px #D2D2D2",
    },
})

class FormBillToLocationOption extends React.Component {
    render() {
        const { classes, option = {}, index, onSelect, isFocused } = this.props
        const {
            fedexFreightAccount,
            fedexBillToAccount,
            label,
            disabled,
            value,
            isFreightDirect,
            isFreightDirectReturns,
            isFreightBox,
        } = option

        const isDirect = isFreightDirect || isFreightDirectReturns

        return (
            <MenuItem
                key={index}
                value={value}
                disabled={disabled}
                selected={isFocused}
                style={{
                    whiteSpace: "normal",
                }}
                onClick={event => onSelect(option, event)}
                className={classes.billTo__menuItem}
                dataTestId={`billToLocationOption-${index}`}
            >
                <Grid
                    item
                    container
                    alignItems="center"
                    alignContent="center"
                    className={classes.billTo__label}
                >
                    <Grid item container xs={12}>
                        <Typography color="inherit">{label}</Typography>
                    </Grid>
                    {!isDirect && option?.fedexFreightAccount && (
                        <Grid item container xs={12}>
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="getRates.billToLocation__shipperAccountNumber"
                                    defaultMessage="Shipper Acct #: {fedexFreightAccount}"
                                    values={{
                                        fedexFreightAccount,
                                    }}
                                />
                            </Typography>
                        </Grid>
                    )}
                    {!isDirect && option?.fedexBillToAccount && (
                        <Grid item container xs={12}>
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="getRates.billToLocation__thirdPartyAccountNumber"
                                    defaultMessage="Third Party Acct #: {fedexBillToAccount}"
                                    values={{
                                        fedexBillToAccount,
                                    }}
                                />
                            </Typography>
                        </Grid>
                    )}
                    {isDirect && option?.fedexFreightAccount && (
                        <Grid item container xs={12}>
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="getRates.billToLocation__FXFDShipperAccountNumber"
                                    defaultMessage="FXFD Shipper Acct #: {fedexFreightAccount}"
                                    values={{
                                        fedexFreightAccount,
                                    }}
                                />
                            </Typography>
                        </Grid>
                    )}
                    {isFreightDirect && option?.fedexBillToAccount && (
                        <Grid item container xs={12}>
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="getRates.billToLocation__FXFDThirdPartyAccountNumber"
                                    defaultMessage="FXFD Third Party Acct #: {fedexBillToAccount}"
                                    values={{
                                        fedexBillToAccount,
                                    }}
                                />
                            </Typography>
                        </Grid>
                    )}
                    {isFreightDirect && (
                        <Grid>
                            <Typography variant="caption" color="primary">
                                <FormattedMessage
                                    id="getRates.billToLocation__freightDirect"
                                    defaultMessage="FedEx Freight Direct"
                                />
                            </Typography>
                        </Grid>
                    )}
                    {isFreightDirectReturns && (
                        <Grid>
                            <Typography variant="caption" color="primary">
                                <FormattedMessage
                                    id="getRates.billToLocation__freightDirectReturns"
                                    defaultMessage="FedEx Freight Direct Returns"
                                />
                            </Typography>
                        </Grid>
                    )}
                    {isFreightBox && (
                        <Grid>
                            <Typography variant="caption" color="primary">
                                <FormattedMessage
                                    id="getRates.billToLocation__freightBox"
                                    defaultMessage="FedEx Freight Box"
                                />
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </MenuItem>
        )
    }
}

export default withStyles(styles)(FormBillToLocationOption)

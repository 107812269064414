import React, { useEffect, useState } from "react"
import ChargeField from "./ChargeField"
import DiscountField from "./DiscountField"
import { Grid, Divider, Box } from "@material-ui/core"
import RateField from "./RateField"
import ModalButtons from "./ModalButtons"
import GlobalSpinner from "../../../common/GlobalSpinner"
import TitleField from "./TitleField"
import HandleUnsavedChangesModal from "./HandleUnsavedChangesModal"

const PricingForm = ({
    classes,
    titleField,
    secondaryTitleField,
    setOpen,
    customPricing,
    dataFields,
    defaultValues,
    resetCustomPricing,
    saveCustomPricing,
    location,
    activeFilter,
    showChargeCheckbox = true,
    showRateCheckbox = true,
    trackGA,
    offshoreLocation,
}) => {
    const [currentFormValues, setCurrentFormValues] = useState([])
    const [saving, setSaving] = useState(false)
    const [isEdited, setEdited] = useState(false)
    const [openUnsavedModal, setOpenUnsavedModal] = useState(false)

    useEffect(() => {
        const initValues = []
        defaultValues?.forEach(defVal => {
            const title = defVal[titleField]
            let secondaryTitle
            if (secondaryTitleField) {
                secondaryTitle = defVal[secondaryTitleField]
            }

            if (customPricing) {
                const custRowPricing = customPricing.find(
                    item => item[titleField] === title
                )
                if (custRowPricing) {
                    initValues.push({ ...custRowPricing })
                } else {
                    const uneditedObj = {}
                    for (let field of dataFields) {
                        uneditedObj[field?.ref] = null
                    }
                    uneditedObj[titleField] = title
                    initValues.push(uneditedObj)
                }
            } else {
                const uneditedObj = {}
                dataFields.forEach(field => {
                    uneditedObj[field?.ref] = null
                })
                uneditedObj[titleField] = title
                if (secondaryTitleField) {
                    uneditedObj[secondaryTitleField] = secondaryTitle
                }
                initValues.push(uneditedObj)
            }
        })
        setCurrentFormValues([...initValues])
    }, [])

    const handleCancel = () => {
        if (!isEdited) {
            setCurrentFormValues([])
            setOpen(false)
        } else {
            setOpenUnsavedModal(true)
        }
    }

    const handleSave = async () => {
        setSaving(true)
        await saveCustomPricing(currentFormValues, location._id, activeFilter)
        setSaving(false)
        setOpen(false)
    }

    const handleReset = async () => {
        setSaving(true)
        await resetCustomPricing(location._id, activeFilter)
        setSaving(false)
        setOpen(false)
    }

    const checkEditStatus = () => {
        setEdited(true)
    }

    return (
        <Grid container>
            <HandleUnsavedChangesModal
                location={location}
                handleSave={handleSave}
                openUnsavedModal={openUnsavedModal}
                currentFormValues={currentFormValues}
                setOpenUnsavedModal={setOpenUnsavedModal}
                setCurrentFormValues={setCurrentFormValues}
                setOpen={setOpen}
                initialFormValues={customPricing}
                classes={classes}
                saving={saving}
            />
            {defaultValues?.map((defaultRowValues, index) => {
                let initRowValues = null
                if (customPricing) {
                    initRowValues = customPricing.find(row => {
                        return row[titleField] === defaultRowValues[titleField]
                    })
                }
                return (
                    <Grid container direction="column">
                        <Grid container item style={{ marginTop: "16px" }}>
                            {dataFields.map(field => {
                                return (
                                    <Box
                                        key={field.label}
                                        style={{
                                            display: "flex",
                                            width: `${100 /
                                                dataFields.length}%`,
                                            maxWidth: "33%",
                                        }}
                                    >
                                        {field?.type === "title" ? (
                                            <TitleField
                                                value={
                                                    defaultRowValues[field?.ref]
                                                }
                                                title={field.label}
                                            />
                                        ) : null}
                                        {field?.type === "charge" &&
                                        defaultRowValues[field?.ref]?.value ? (
                                            <ChargeField
                                                index={index}
                                                checkEditStatus={
                                                    checkEditStatus
                                                }
                                                label={field.label}
                                                defaultValue={
                                                    defaultRowValues[field?.ref]
                                                        ?.value
                                                }
                                                initialValue={
                                                    initRowValues
                                                        ? initRowValues[
                                                              field?.ref
                                                          ]
                                                        : null
                                                }
                                                fieldRef={field.ref}
                                                setCurrentFormValues={
                                                    setCurrentFormValues
                                                }
                                                currentFormValues={
                                                    currentFormValues
                                                }
                                                showChargeCheckbox={
                                                    showChargeCheckbox
                                                }
                                            />
                                        ) : null}
                                        {field?.type === "rate" ? (
                                            <RateField
                                                index={index}
                                                checkEditStatus={
                                                    checkEditStatus
                                                }
                                                label={field.label}
                                                defaultValue={
                                                    defaultRowValues[field?.ref]
                                                        ?.value
                                                }
                                                initialValue={
                                                    initRowValues
                                                        ? initRowValues[
                                                              field?.ref
                                                          ]
                                                        : null
                                                }
                                                fieldRef={field.ref}
                                                setCurrentFormValues={
                                                    setCurrentFormValues
                                                }
                                                currentFormValues={
                                                    currentFormValues
                                                }
                                                showRateCheckbox={
                                                    showRateCheckbox
                                                }
                                            />
                                        ) : null}
                                        {field?.type === "discount" ? (
                                            <DiscountField
                                                index={index}
                                                checkEditStatus={
                                                    checkEditStatus
                                                }
                                                label={field?.label}
                                                defaultValue={
                                                    defaultRowValues[field?.ref]
                                                        ?.value
                                                }
                                                initialValue={
                                                    initRowValues
                                                        ? initRowValues[
                                                              field?.ref
                                                          ]
                                                        : null
                                                }
                                                fieldRef={field.ref}
                                                setCurrentFormValues={
                                                    setCurrentFormValues
                                                }
                                                currentFormValues={
                                                    currentFormValues
                                                }
                                            />
                                        ) : null}
                                        {field?.type === "value" ? (
                                            <DiscountField
                                                index={index}
                                                checkEditStatus={
                                                    checkEditStatus
                                                }
                                                label={field?.label}
                                                defaultValue={
                                                    defaultRowValues[field?.ref]
                                                        ?.value
                                                }
                                                initialValue={
                                                    initRowValues
                                                        ? initRowValues[
                                                              field?.ref
                                                          ]
                                                        : null
                                                }
                                                fieldRef={field.ref}
                                                setCurrentFormValues={
                                                    setCurrentFormValues
                                                }
                                                currentFormValues={
                                                    currentFormValues
                                                }
                                            />
                                        ) : null}
                                    </Box>
                                )
                            })}
                        </Grid>
                        <Divider />
                    </Grid>
                )
            })}
            <ModalButtons
                handleCancel={handleCancel}
                handleSave={handleSave}
                disableSave={!isEdited}
                handleReset={handleReset}
                trackGA={trackGA}
                offshoreLocation={offshoreLocation}
                activeFilter={activeFilter}
            />
            {saving ? <GlobalSpinner /> : null}
        </Grid>
    )
}

export default PricingForm

import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles/index"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import { FormattedMessage, useIntl } from "react-intl"
import { isFedExCarrier } from "../../misc"

const isVolumeService = mode =>
    mode === "VOLUME_LTL" ? (
        <FormattedMessage
            id="carrierName.volumeServicesSuffix"
            defaultMessage="Volume Services"
        />
    ) : null

const styles = theme => ({
    carrier_logo_label: {
        "text-align": "left",
        color: "#484848",
        "font-family": "Roboto",
        "font-size": "10px",
        "font-weight": "300",
        "white-space": "nowrap",
    },
    relative: {
        position: "relative",
        height: "55px",
    },
    carrier__logo: {
        position: "absolute",
        maxWidth: "93px",
        left: "-12px",
    },
})

export const CarrierLogoView = ({
    carrier,
    carrierCode,
    isDomesticOffshore,
    mode,
    serviceLevel,
    classes,
    largeText,
    isFXFD,
    containsCombinedOtherRatesAndFedexOceanRates = false,
}) => {
    const intl = useIntl()
    const fedexCarrierCode = isFedExCarrier(carrierCode)
    const carrierName = containsCombinedOtherRatesAndFedexOceanRates
        ? carrier?.name + " - FedEx "
        : carrier?.name

    const fedexCarrierCodeMsg = fedexCarrierCode
        ? intl.formatMessage(fedexCarrierCode)
        : ""
    return (
        <Grid container>
            {carrier ? (
                <Grid item container justify="flex-start" alignItems="center">
                    {fedexCarrierCode ? (
                        isFXFD ? (
                            <Grid item container direction="column">
                                <Typography
                                    variant={
                                        largeText ? "subtitle1" : "caption"
                                    }
                                    className={
                                        largeText
                                            ? classes.carrier_logo_large
                                            : classes.carrier_logo_label
                                    }
                                >
                                    <FormattedMessage
                                        id="generalTerms__fedExFreightDirect"
                                        defaultMessage="FedEx Freight Direct &reg; {code}"
                                        values={{
                                            code: fedexCarrierCodeMsg,
                                        }}
                                    />
                                </Typography>
                            </Grid>
                        ) : (
                            <Grid item container direction="column">
                                <div className={classes.relative}>
                                    <img
                                        className={classes.carrier__logo}
                                        src={
                                            intl.locale === "fr-ca"
                                                ? "static/fedexLogoFR.png"
                                                : carrier.logo
                                        }
                                        alt={carrierCode}
                                    />
                                </div>
                                <Typography
                                    variant={
                                        largeText ? "subtitle1" : "caption"
                                    }
                                    className={
                                        largeText
                                            ? classes.carrier_logo_large
                                            : classes.carrier_logo_label
                                    }
                                >
                                    <FormattedMessage
                                        id="generalTerms__fedExFreight"
                                        defaultMessage="FedEx Freight &reg;"
                                    />
                                    {isVolumeService(mode) ?? (
                                        <FormattedMessage
                                            {...isFedExCarrier(carrierCode)}
                                        />
                                    )}
                                </Typography>
                                {isDomesticOffshore && (
                                    <Typography
                                        variant={
                                            largeText ? "subtitle1" : "caption"
                                        }
                                        className={
                                            largeText
                                                ? classes.carrier_logo_large
                                                : classes.carrier_logo_label
                                        }
                                    >
                                        <FormattedMessage
                                            id="generalTerms__domesticOffshore"
                                            defaultMessage="Domestic Offshore"
                                        />
                                    </Typography>
                                )}
                            </Grid>
                        )
                    ) : (
                        <Typography variant={"subtitle1"}>
                            <div className="carrier-logo truncated">
                                {carrierName} {isVolumeService(mode)}
                            </div>
                        </Typography>
                    )}

                    {isVolumeService(mode) &&
                        ["TLX", "TLS", "EXCL"].includes(serviceLevel) && (
                            <Typography
                                variant={largeText ? "subtitle1" : "caption"}
                                className={
                                    largeText
                                        ? classes.carrier_logo_large
                                        : classes.carrier_logo_label
                                }
                            >
                                {serviceLevel === "EXCL" ? (
                                    <FormattedMessage
                                        id="orderDetails.serviceType__volumeExclusive"
                                        defaultMessage="Exclusive Use"
                                    />
                                ) : (
                                    serviceLevel
                                )}
                            </Typography>
                        )}
                </Grid>
            ) : (
                <div className={classes.carrier_logo_label}>{carrierCode}</div>
            )}
        </Grid>
    )
}

CarrierLogoView.propTypes = {
    carrier: PropTypes.object,
    classes: PropTypes.object.isRequired,
    carrierCode: PropTypes.string.isRequired,
    serviceLevel: PropTypes.string,
    className: PropTypes.string,
    mode: PropTypes.string,
}

CarrierLogoView.defaultProps = {
    carrier: null,
    serviceLevel: null,
    className: "",
    mode: "LTL",
}

function carrierOf(state, carrierCode) {
    if (carrierCode === "domestic_offshore") {
        return state.carriers.carriers.FXFE
    }
    return state.carriers.carriers[carrierCode]
}

const mapStateToProps = (state, props) => ({
    carrier: carrierOf(state, props.carrierCode),
})

export const CarrierLogo = connect(mapStateToProps)(
    withStyles(styles)(CarrierLogoView)
)

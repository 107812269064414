import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/styles"
import React, { useContext } from "react"
import FXFDRateRow from "./FXFDRateRow"
import { useFlags } from "launchdarkly-react-client-sdk"
import InfoIcon from "@material-ui/icons/Info"
import { useGAContext } from "../../../../../context/providers/GoogleAnalyticsProvider"

const useStyles = makeStyles({
    FXFDGroup: {
        borderTop: "solid 2px #969696",
    },
})

const FXFDTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.primary.light,
        color: "white",
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip)

export default function FXFDRates({
    category,
    rateBuckets,
    selections,
    isSharing,
    setIsSelected,
    selectRate,
    canViewRateAmounts,
    expired,
    shippedRateQuote,
    shipped,
    paymentType,
    isSingleWorkflow,
}) {
    const classes = useStyles()
    const { singleWorkflowLtlAndLtlDirect } = useFlags()
    const { logGAEvent } = useGAContext()

    return (
        <Grid item container>
            {Object.keys(rateBuckets).map((serviceLevel, serviceLevelIndex) => {
                const { label, title, rates = [], info } = rateBuckets[
                    serviceLevel
                ]
                if (rates.length === 0) return null

                return (
                    <Grid
                        key={serviceLevel}
                        item
                        container
                        className={
                            singleWorkflowLtlAndLtlDirect && isSingleWorkflow
                                ? ""
                                : classes.FXFDGroup
                        }
                        alignItems="center"
                    >
                        <Grid item container xs={4} alignItems="center">
                            <Grid item container direction="column">
                                <Typography variant="subtitle1">
                                    {title}
                                    {info != null && isSingleWorkflow && (
                                        <FXFDTooltip
                                            title={info}
                                            placement="right"
                                        >
                                            <IconButton aria-label="info">
                                                <InfoIcon />
                                            </IconButton>
                                        </FXFDTooltip>
                                    )}
                                </Typography>
                                <Typography variant="caption">
                                    {label}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container xs={8}>
                            {rates.map(
                                (rate = { rateQuoteDetail: {} }, rateIndex) => {
                                    const isShippedRate =
                                        shippedRateQuote?._id === rate._id
                                    return (
                                        <FXFDRateRow
                                            category={category}
                                            key={rate?._id}
                                            logGAEvent={logGAEvent}
                                            rate={rate}
                                            rateIndex={rateIndex}
                                            canViewRateAmounts={
                                                canViewRateAmounts
                                            }
                                            isSharing={isSharing}
                                            setIsSelected={setIsSelected}
                                            selections={selections}
                                            selectRate={selectRate}
                                            serviceLevelIndex={
                                                serviceLevelIndex
                                            }
                                            expired={expired}
                                            isShippedRate={isShippedRate}
                                            shipped={shipped}
                                            paymentType={paymentType}
                                            isSingleWorkflow={isSingleWorkflow}
                                        />
                                    )
                                }
                            )}
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

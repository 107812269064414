import moment from "moment-timezone"
import createSelector from "re-reselect"
import { get } from "lodash"
import { extractAddress } from "../../actions/book"
import {
    isDirectionConsignee,
    isDirectionShipper,
    isFedExCarrier,
} from "../../misc"
import { billToLocationSelector } from "../quotesPage/selectors"
import {
    isInternationalShipment,
    isMexicoToUsaInternationalShipment,
    isUsaMainlandToMexicoInternationalShipment,
    isUSDomesticOffshoreShipment,
    isUSDomesticOffshoreShipmentWithItemCustoms,
} from "../../actions/validation"
import { createHandlingUnitInitialValues } from "../documents/certificateOfOrigin/selectors"
import { weightUnitSingularCapital } from "../util/units"

const transformContact = contact => ({
    _id: contact?._id,
    name: contact?.name,
    phone: contact?.phone,
    email: contact?.email?.email_address,
    extension: 123,
})

export function lookupContact(address, id) {
    const contact = get(address, "contacts", []).find(item => item._id === id)
    return transformContact(contact)
}

const lookupPickUpContact = (address, id) => {
    const contact = get(address, "contacts", []).find(item => item._id === id)
    return contact?.pickupContact
        ? transformContact(contact?.pickupContact)
        : transformContact(contact)
}

export const extractTerminalAddress = terminal => {
    return {
        address: { ...terminal.address, name: terminal?.location },
    }
}

const extracTerminalContact = terminal => {
    return terminal.contact
}

const roundToHalfHour = time =>
    moment(time)
        .add(30 - (time.minute() % 30), "minutes")
        .format("h:mm A")

export const isPastPickupCutoffTime = (
    startDate,
    timezone,
    country = "US",
    momentProvider = () => moment()
) => {
    const cutOffTimeHour = country === "MX" ? 13 : 15
    const localOriginTime = timezone
        ? momentProvider().tz(timezone)
        : momentProvider()
    const startTimeString = moment(startDate).format("YYYY-MM-DD")
    const localTimeString = localOriginTime.format("YYYY-MM-DD")
    const cutOffTime = moment
        .tz(localOriginTime, timezone)
        .set({ h: cutOffTimeHour, m: 0 })
    return (
        localOriginTime &&
        localTimeString === startTimeString &&
        localOriginTime.isAfter(cutOffTime)
    )
}

export const getDayOfTheWeek = startDate => {
    return moment(startDate).isoWeekday()
}

export const sensibleReadyTime = (
    readyTime,
    shipDate,
    timeZone,
    momentProvider = () => moment()
) => {
    const currentOriginTime = timeZone
        ? momentProvider().tz(timeZone)
        : momentProvider()
    const shipDateTime = timeZone
        ? moment.tz(shipDate.split("T")[0], timeZone)
        : moment(shipDate.split("T")[0])

    if (!currentOriginTime.isSame(shipDateTime, "day")) return readyTime

    const readyTime24hr = (readyTime
        ? moment(readyTime, "hh:mm a")
        : currentOriginTime
    ).format("HH:mm")
    const [readyHour, readyMinute] = readyTime24hr
        .split(":")
        .map(timeString => parseInt(timeString, 10))
    const originsReadyTimeToday = (timeZone
        ? momentProvider().tz(timeZone)
        : momentProvider()
    )
        .startOf("day")
        .hour(readyHour)
        .minute(readyMinute)
    if (originsReadyTimeToday.isBefore(currentOriginTime)) {
        return roundToHalfHour(currentOriginTime)
    }

    return readyTime
}

export const rateSelector = createSelector(
    (state, props) => state.shipment.list[props.shipmentId],
    (state, props) => state.quotes.list.items[props.shipmentId],
    (state, props) => props.shipMode,
    (state, props) => props.carrierCode,
    (state, props) => props.direction,
    (state, props) => props.alternateQuote,
    (shipment, quote, shipMode, carrierCode, direction, alternateQuote) => {
        if (shipment) return get(shipment, "shipment.rate")
        if (!quote) return {}

        const realMode =
            quote?.result?.rateQuotes?.[0]?.mode === "LTL_DIRECT"
                ? "LTL_DIRECT"
                : quote?.result?.rateQuotes?.[0]?.mode === "LTL_DIRECT_RETURNS"
                ? "LTL_DIRECT_RETURNS"
                : /vltl/i.test(shipMode)
                ? "VOLUME_LTL"
                : "LTL"
        const rateQuote = get(quote, "result.rateQuotes", []).find(
            item =>
                item?.carrierCode === carrierCode &&
                item?.mode === realMode &&
                item?.direction === direction.toUpperCase()
        )

        return alternateQuote
            ? get(rateQuote, `alternateRateQuotes[${alternateQuote}]`)
            : rateQuote
    }
)((state, props) => props.shipmentId || "")

const getPickupTime = (
    isShipper,
    defaultLocationTime,
    keyObject,
    originAddress,
    originContact
) => {
    if (!isShipper) {
        const contact = (originAddress?.contacts ?? []).find(
            contact => contact._id === originContact
        )
        if (contact?.pickupContact?.[keyObject]) {
            return contact.pickupContact[keyObject]
        }
    }
    return defaultLocationTime
}

export const bookSelector = createSelector(
    (state, props) => state.quotes.list.items[props.shipmentId],
    state => state.user.profile,
    rateSelector,
    billToLocationSelector,
    (state, props) => props,
    state => state.contact,
    state => state.form.bolEntry,
    state => state.postalCode,
    (quote, user, rate, location, props, contact, form, postalCode) => {
        if (!quote) return { isFetching: true }
        const { search, handlingUnits, identifiers } = quote

        const { direction } = props

        if (!search || !rate || !handlingUnits || !identifiers) return {}

        const {
            cpg,
            originAddress,
            originContact,
            destinationAddress,
            destinationContact,
            pickupDate,
            originTimezone,
            isInBondShipment,
            preferredSystemOfMeasurement,
            preferredCurrencyCode,
        } = search

        const shippingContact = {
            name: [user?.firstname, user?.lastname].filter(x => x).join(" "),
            phone: user?.phone_number,
            email: user?.email?.email_address,
        }

        const extremeLengthCharges = get(rate, "rateQuoteDetail.charges", [])
            .filter(({ code }) => code.startsWith("ELS"))
            .map(({ code }) => {
                if (
                    isFedExCarrier(rate.carrierCode) &&
                    code.startsWith("ELS")
                ) {
                    const maxLength = Math.max(
                        ...handlingUnits.map(item => item.length)
                    )
                    return maxLength > 96 && maxLength < 144
                        ? {
                              label: "Over Length Shipment",
                          }
                        : {
                              label: "Extreme Length Shipment",
                          }
                }
                return { value: code }
            })

        const isHazMat = handlingUnits.some(hu => {
            return hu.items.some(item => item.isHazMat)
        })

        const pickupTime = sensibleReadyTime(
            getPickupTime(
                isDirectionShipper(direction),
                get(location, "readyTime"),
                "readyTime",
                originAddress,
                originContact
            ),
            pickupDate,
            originTimezone
        )

        const closePickupTime = getPickupTime(
            isDirectionShipper(direction),
            get(location, "closeTime"),
            "closeTime",
            originAddress,
            originContact
        )

        const contactSearchInProgress = get(contact, "searchInProgress")

        const isIntl = isInternationalShipment(
            get(form, "values.origin.address.country"),
            get(form, "values.destination.address.country")
        )

        const isMXToUSIntl = isMexicoToUsaInternationalShipment(
            get(form, "values.origin.address.country"),
            get(form, "values.destination.address.country")
        )

        const isUSMainlandToMXIntl = isUsaMainlandToMexicoInternationalShipment(
            get(form, "values.origin.address.country"),
            get(form, "values.destination.address.country"),
            get(form, "values.origin.address.state")
        )

        const isUSDomesticOffshore = isUSDomesticOffshoreShipment(
            get(form, "values.origin.address.country"),
            get(form, "values.destination.address.country"),
            get(form, "values.origin.address.state"),
            get(form, "values.destination.address.state")
        )

        const isUSDomesticOffshoreWithItemCustoms = isUSDomesticOffshoreShipmentWithItemCustoms(
            get(form, "values.origin.address.country"),
            get(form, "values.destination.address.country"),
            get(form, "values.origin.address.state"),
            get(form, "values.destination.address.state")
        )

        const isFreightDirectReturns =
            location?.locationType === "FEDEX_DIRECT_RETURNS"

        const canDoThirdParty =
            location?.fedexBillToAccount ||
            location?.pickupAndDestroyLocation?.fedexBillToAccount

        const serviceLevel = rate.serviceLevel
        const carrierCode = rate.carrierCode

        const isPickAndDestroyRate =
            serviceLevel?.code === "BASIC_PICKUP_AND_DESTROY"

        const changeToTerminal = isPickAndDestroyRate && canDoThirdParty

        const certificateOfOriginLineItems = createHandlingUnitInitialValues(
            handlingUnits,
            weightUnitSingularCapital(preferredSystemOfMeasurement)
        )
        const pickUpContact = isDirectionShipper(direction)
            ? transformContact(location.pickupContact)
            : isFreightDirectReturns
            ? lookupContact(originAddress, originContact)
            : lookupPickUpContact(originAddress, originContact)

        const hazmatEmergency = get(
            user,
            "preferences.hazmatEmergencyContact",
            {}
        )

        return {
            searchInProgress: contactSearchInProgress,
            originCompanyName: get(form, "values.origin.address.name"),
            destinationCompanyName: get(
                form,
                "values.destination.address.name"
            ),
            originFixed: rate.direction === "SHIPPER",
            destinationFixed:
                rate.direction === "CONSIGNEE" || isPickAndDestroyRate,
            shipped: get(quote, "shipped"),
            terminal: rate.terminal,
            destinationTerminal: rate.destinationTerminal,
            mode: rate.mode,
            changeToTerminal,
            cf7512ModalNumbers: [],
            cf7512: [],
            serviceLevel,
            preferredSystemOfMeasurement,
            preferredCurrencyCode,
            isIntl,
            isInBondShipment,
            isMXToUSIntl,
            isUSMainlandToMXIntl,
            isUSDomesticOffshore,
            isUSDomesticOffshoreWithItemCustoms,
            handlingUnitsBolForm: get(form, "values.handlingUnits"),
            carrierCode,
            pickupDate,
            originTimezone,
            initialValues: {
                handlingUnits,
                origin: {
                    ...extractAddress(search, "origin"),
                    contact: isDirectionShipper(direction)
                        ? shippingContact
                        : lookupContact(originAddress, originContact),
                    pickup: pickUpContact,
                    startDate: pickupDate,
                    isPastPickup: isPastPickupCutoffTime(
                        pickupDate,
                        originTimezone,
                        get(form, "values.origin.address.country")
                    ),
                    readyTime: isFreightDirectReturns ? "7:00 AM" : pickupTime,
                    closeTime: isFreightDirectReturns
                        ? "8:00 PM"
                        : closePickupTime,
                    accessorials: [
                        ...get(search, "pickupAccessorials", []),
                        ...get(search, "commodityAccessorials", []),
                        ...extremeLengthCharges,
                        ...(isHazMat ? [{ label: "Hazmat" }] : []),
                    ],
                    commercialInvoice: {
                        naftaDeclaration: false,
                        partiesRelated: false,
                        importerIsNotConsignee: false,
                    },
                },
                destination: {
                    ...(!changeToTerminal
                        ? extractAddress(search, "destination")
                        : extractTerminalAddress(rate.terminal)),

                    contact: changeToTerminal
                        ? extracTerminalContact(rate.terminal)
                        : isDirectionConsignee(direction)
                        ? shippingContact
                        : lookupContact(destinationAddress, destinationContact),
                    startDate: get(rate, "deliveryDateTime"),
                    endDate: get(rate, "deliveryDateTime"),
                    accessorials: get(search, "deliveryAccessorials", []),
                },
                search,
                rate,
                cpg,
                identifiers,
                ...(isHazMat && { hazmatEmergency }),
                ...(isMXToUSIntl && {
                    exportCustomsBrokerageInfo: get(
                        user,
                        "preferences.exportCustomsBrokerageInfo",
                        {}
                    ),
                }),
                ...((isIntl || isUSDomesticOffshore) && {
                    importCustomsBrokerageInfo: get(
                        user,
                        "preferences.importCustomsBrokerageInfo",
                        {}
                    ),
                }),
                selectedRecipients: [],
                alerts: {
                    user: get(user, "preferences.alerts"),
                },
                commercialInvoice: {
                    naftaDeclaration: false,
                    partiesRelated: false,
                    importerIsNotConsignee: false,
                },
                certificateOfOrigin: {
                    certificateOfOriginLineItems,
                },
            },
            handlingUnits,
            isHazMat,
            location,
            originState: get(form, "values.origin.address.state"),
            destinationState: get(form, "values.destination.address.state"),
            commercialInvoice: get(form, "values.requiresCommercialInvoice"),
            hazmatEmergency: get(form, "values.hazmatEmergency"),
        }
    }
)((state, props) => props.shipmentId || "")

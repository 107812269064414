import React from "react"
import Intercom from "react-intercom"
import { connect } from "react-redux"

const IntercomPresentation = props =>
    props.shouldDisplay && (
        <div id="intercom__container">
            <Intercom {...props} />
        </div>
    )

const stateToProps = (state, props) => {
    const user = state.user.profile
    let name
    if (user) {
        name = `${user.firstname} ${user.lastname}`
    }

    const email = user && user.email && user.email.email_address

    return {
        shouldDisplay:
            !state.impersonation.enabled &&
            window._env_.REACT_APP_HIDE_INTERCOM !== "true",
        appID: props.appID,
        user_id: email,
        email,
        name,
        alignment: "left",
        vertical_padding: 95,
        custom_launcher_selector: "#intercom-trigger",
    }
}

export const IntercomContainer = connect(stateToProps)(IntercomPresentation)

import React, { Fragment } from "react"
import { Grid, Typography } from "@material-ui/core"
import { Field, FieldArray, FormSection, formValues } from "redux-form"
import FormSelectAutocomplete from "../../../form/form-select-autocomplete"
import { formattedPurposeOfShipment } from "../../../book/SupportDocumentationForm"
import { FormattedMessage, injectIntl } from "react-intl"
import FormField from "../../../form/form-field"
import FormDatePicker from "../../../form/form-datepicker"
import { makeStyles } from "@material-ui/styles"
import ManageSignature from "../../../documents/ManageSignature"
import { weightUnitSingularCapital } from "../../../util/units"

const useStyles = makeStyles({
    underline: {
        textDecoration: "underline",
        paddingBottom: "10px",
    },
    signature_button: {
        marginTop: "12px",
    },
    mainTitle: {
        padding: "12px 0",
    },
})

const CertificateOfOriginBOLForm = formValues({
    preferredSystemOfMeasurement: "preferredSystemOfMeasurement",
    notaryStamp: "notaryStamp",
})(({ preferredSystemOfMeasurement, notaryStamp, gaCategory }) => {
    const classes = useStyles()

    return (
        <Grid xs={12} item alignContent="center" alignItems="center">
            <Grid>
                <Grid
                    item
                    container
                    justify="center"
                    className={classes.mainTitle}
                >
                    <Typography variant="h6">
                        <FormattedMessage
                            id="orderDetails.certificate__of__origin__title"
                            defaultMessage="Certificate of Origin"
                        />
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid container>
                        <FieldArray
                            name="certificateOfOrigin.certificateOfOriginLineItems"
                            component={HandlingUnitListPresentation}
                            props={{
                                weightUnit: weightUnitSingularCapital(
                                    preferredSystemOfMeasurement
                                ),
                            }}
                            gaCategory={gaCategory}
                        />
                        <Grid item container xs={4}>
                            <Field
                                component={FormSelectAutocomplete}
                                name="certificateOfOrigin.shipmentPurpose"
                                options={formattedPurposeOfShipment}
                                label={
                                    <FormattedMessage
                                        id="certificateOfOrigin.select_purpose"
                                        defaultMessage="Select Purpose Of Shipment:"
                                    />
                                }
                                required={notaryStamp}
                                category={`${gaCategory} : Certificate of Origin`}
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                name="certificateOfOrigin.datedAt"
                                component={FormField}
                                type="text"
                                label={
                                    <FormattedMessage
                                        id="certificateOfOrigin.datedAt"
                                        defaultMessage="Dated at:"
                                    />
                                }
                                required={notaryStamp}
                                category={`${gaCategory} : Certificate of Origin`}
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                name="certificateOfOrigin.swornDate"
                                component={FormDatePicker}
                                dateFormat="ll"
                                noMinDate
                                disableFuture
                                label={
                                    <FormattedMessage
                                        id="certificateOfOrigin.swornDate"
                                        defaultMessage="Sworn on:"
                                    />
                                }
                                required={notaryStamp}
                                category={`${gaCategory} : Certificate of Origin`}
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                name="certificateOfOrigin.chamberOfCommerce.name"
                                type="text"
                                component={FormField}
                                label={
                                    <FormattedMessage
                                        id="certificateOfOrigin.chamberOfCommerce__name"
                                        defaultMessage="Chamber of Commerce Name:"
                                    />
                                }
                                required={notaryStamp}
                                category={`${gaCategory} : Certificate of Origin`}
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                name="certificateOfOrigin.chamberOfCommerce.state"
                                type="text"
                                component={FormField}
                                label={
                                    <FormattedMessage
                                        id="certificateOfOrigin.chamberOfCommerce__state"
                                        defaultMessage="Chamber of Commerce State:"
                                    />
                                }
                                required={notaryStamp}
                                category={`${gaCategory} : Certificate of Origin`}
                            />
                        </Grid>
                        <SignatureDetails
                            gaCategory={`${gaCategory} : Certificate of Origin`}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
})

const SignatureDetails = ({ gaCategory }) => {
    const classes = useStyles()

    return (
        <Grid
            className={classes.signature_button}
            item
            container
            alignItems="flex-start"
            spacing={2}
        >
            <Grid item xs={4}>
                <ManageSignature
                    fieldName="certificateOfOrigin.exporterSignatureImage"
                    previewDimensions={{
                        width: 223,
                        height: 44,
                    }}
                    addTitle={
                        <FormattedMessage
                            id="locations.signatureManagment__addExporterSignature"
                            defaultMessage="Add Exporter Signature"
                        />
                    }
                    replaceTitle={
                        <FormattedMessage
                            id="locations.signatureManagment__replaceExporterSignature"
                            defaultMessage="Replace Exporter Signature"
                        />
                    }
                    gaCategory={`${gaCategory} : Exporter`}
                />
            </Grid>
            <Grid item xs={4}>
                <ManageSignature
                    fieldName="certificateOfOrigin.notaryStamp"
                    previewDimensions={{
                        width: 188,
                        height: 38,
                    }}
                    addTitle={
                        <FormattedMessage
                            id="locations.signatureManagment__addNotaryStamp"
                            defaultMessage="Add Notary Stamp"
                        />
                    }
                    replaceTitle={
                        <FormattedMessage
                            id="locations.signatureManagment__replaceNotaryStamp"
                            defaultMessage="Replace Notary Stamp"
                        />
                    }
                    gaCategory={`${gaCategory} : Notary Stamp`}
                />
            </Grid>
            <Grid item xs={4}>
                <ManageSignature
                    fieldName="certificateOfOrigin.secretarySignatureImage"
                    previewDimensions={{
                        width: 186,
                        height: 42,
                    }}
                    addTitle={
                        <FormattedMessage
                            id="locations.signatureManagment__addSecretarySignature"
                            defaultMessage="Add Secretary Signature"
                        />
                    }
                    replaceTitle={
                        <FormattedMessage
                            id="locations.signatureManagment__replaceSecretarySignature"
                            defaultMessage="Replace Secretary Signature"
                        />
                    }
                    gaCategory={`${gaCategory} : Secretary`}
                />
            </Grid>
        </Grid>
    )
}

const HandlingUnitItemFields = formValues({ itemName: "description" })(
    injectIntl(({ intl, itemName, weightUnit, gaCategory }) => (
        <Grid item container direction="row">
            <Grid item container xs={4}>
                <Field
                    name="numberOfPackages"
                    type="number"
                    component={FormField}
                    label={
                        <FormattedMessage
                            id="certificateOfOrigin.no__of__packages_w_item_name"
                            defaultMessage="{itemName} No. of Packages:"
                            values={{ itemName }}
                        />
                    }
                    category={`${gaCategory} : Certificate of Origin`}
                />
            </Grid>
            <Grid item container xs={4}>
                <Field
                    name="grossWeight.amount"
                    type="number"
                    component={FormField}
                    label={
                        <FormattedMessage
                            id="gross__weight_w_item_name"
                            defaultMessage="{itemName} Gross Weight ({weightUnit}):"
                            values={{
                                itemName,
                                weightUnit,
                            }}
                        />
                    }
                    category={`${gaCategory} : Certificate of Origin`}
                />
            </Grid>
            <Grid item container xs={4}>
                <Field
                    name="netWeight.amount"
                    type="number"
                    component={FormField}
                    label={
                        <FormattedMessage
                            id="certificateOfOrigin.net__weight_w_item_name"
                            defaultMessage="{itemName} Net Weight ({weightUnit}):"
                            values={{
                                itemName,
                                weightUnit,
                            }}
                        />
                    }
                    category={`${gaCategory} : Certificate of Origin`}
                />
            </Grid>
        </Grid>
    ))
)

const HandlingUnitListPresentation = ({ fields, weightUnit, gaCategory }) => (
    <Fragment>
        {fields.map(prefix => (
            <FormSection name={`${prefix}`} key={prefix} component={Fragment}>
                <HandlingUnitItemFields
                    weightUnit={weightUnit}
                    gaCategory={gaCategory}
                />
            </FormSection>
        ))}
    </Fragment>
)

export default CertificateOfOriginBOLForm

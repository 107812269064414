import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactGA from "react-ga4"
import Divider from "@material-ui/core/Divider"
import { Field, reduxForm, change, formValues } from "redux-form"
import { changePath } from "../../actions"
import { connect } from "react-redux"
import { combineValidators } from "revalidate"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Snackbar from "@material-ui/core/Snackbar"
import { Grid, withStyles, Typography, Button } from "@material-ui/core"
import { formValidation } from "./validation"
import GlobalSpinner from "../common/GlobalSpinner"
import { ErrorSnackbarContentWrapper } from "../errorSnackbar"
import { addLocation, requestAccessToLocation } from "../../actions/user"
import RequestAccess from "../locations/request-access"
import { requestAccessSuccess } from "../../messages/confirmation-constants"
import {
    requestAccessError,
    userSignUpError,
} from "../../messages/error-constants"
import PickUpInformation from "../user/pick-up-information"
import ShippingInformation from "../user/shipping-information"
import BillingInformation from "../user/billing-information"
import { FormattedMessage, defineMessages } from "react-intl"
import { supportedLanguagesCountryMap } from "../../misc"
import FormRadio from "../form/form-radio"
import { IntercomAPI } from "react-intercom"

const styles = theme => ({
    form__container: {
        padding: theme.spacing(3),
    },
    login__information: {
        marginBottom: "40px",
    },
    signup__form: {
        padding: "15px",
    },
    signup__button: {
        marginLeft: "10px",
    },
    decision__container: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 5,
        color: "white",
        padding: "25px",
    },
    input__label: {
        color: "white !important",
    },
    cards__container: {
        marginTop: "20px",
        width: "100%",
    },
    signup__decision__text: {
        paddingRight: "20px",
    },
    signup__section: {
        padding: "1%",
    },
    signup__card: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "20px 20px",
        minHeight: "330px",
    },
    signup__card__variableSize: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "20px 20px",
    },
    signup__card__top: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "20px 20px",
    },
    terms__container: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "10px 20px",
    },
    acceptTerms__text: {
        paddingLeft: "40px",
    },
    whiteOverride: {
        color: "white",
    },
    error__entry: {
        padding: "15px",
    },
    dialog: {
        minWidth: "350px",
    },
    decision__divider: {
        height: "20px",
        borderTop: "solid 1px white",
        width: "100%",
    },
    decision__titleContainer: {
        padding: "20px",
    },
    field__root: {
        "background-color": theme.palette.primary.light,
    },
    input__field: {
        color: "white !important",
        textShadow: "none !important",
        padding: "6px 18px 6px 6px",
        minHeight: "20px",
        fontFamily: theme.typography.fontFamily,
    },

    error__title: {
        backgroundColor: theme.palette.primary.main,
    },
})

const locationTypesMessages = defineMessages({
    direct: {
        id: "locations.type__direct",
        defaultMessage: "Freight Direct",
    },
    ltlFreight: {
        id: "locations.type__ltlFreight",
        defaultMessage: "Freight",
    },
    freightBox: {
        id: "locations.type__freightBox",
        defaultMessage: "FedEx Freight Box",
    },
})

export const shipperLocationTypes = [
    {
        value: "FEDEX_FREIGHT",
        label: locationTypesMessages.ltlFreight,
    },
    {
        value: "FEDEX_DIRECT",
        label: locationTypesMessages.direct,
    },
    {
        value: "FEDEX_FREIGHT_BOX",
        label: locationTypesMessages.freightBox,
    },
]

export const thirdPartyLocationTypes = [
    {
        value: "FEDEX_FREIGHT",
        label: locationTypesMessages.ltlFreight,
    },
    {
        value: "FEDEX_DIRECT",
        label: locationTypesMessages.direct,
    },
]

class AddLocation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            requestAccessOpen: false,
            duplicate: null,
            sbOpen: false,
            sbMessage: "",
            sbVariant: "",
            open: false,
            modalError: null,
            modalSuccess: false,
            loading: false,
        }
    }

    componentDidMount() {
        ReactGA.event("Add Location", "Add Location Page Viewed")
    }

    handleRequestAccessClose() {
        this.setState({ requestAccessOpen: false, duplicate: null })
    }

    handleClickOpen = () => {
        this.setState({ open: true })
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    async handleRequestAccess() {
        try {
            await this.props.requestAccessToLocation(this.state.duplicate)
            this.setState({
                sbOpen: true,
                sbMessage: requestAccessSuccess,
                sbVariant: "success",
            })
        } catch (error) {
            this.setState({
                sbOpen: true,
                sbMessage: requestAccessError,
                sbVariant: "error",
            })
        } finally {
            this.handleRequestAccessClose()
        }
    }

    async handleAddLocation(values, activeUser) {
        this.setState({
            loading: true,
            modalError: null,
            modalSuccess: false,
        })
        try {
            const { duplicate } = await this.props.addLocation(values)
            if (duplicate) {
                this.setState({
                    duplicate,
                    requestAccessOpen: !!duplicate,
                })
            } else {
                this.setState({
                    modalSuccess: true,
                })
                this.handleClickOpen()
                this.props.reset()
            }
        } catch (error) {
            this.setState({
                modalError: userSignUpError(activeUser?.firstname),
            })
            IntercomAPI("trackEvent", "account-validation-error-add-location")
            this.handleClickOpen()
            throw error
        } finally {
            this.setState({
                loading: false,
            })
        }
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") return
        this.setState({ sbOpen: false })
    }

    handleAccountTypeSelect = () => {
        this.props.resetSection("location")
    }

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            invalid,
            onBackClick,
            classes,
            accountType,
            locationType,
            shippingCountry,
            shippingState,
            changeField,
            activeUser,
        } = this.props

        const {
            open,
            loading,
            modalError,
            modalSuccess,
            requestAccessOpen,
            duplicate,
        } = this.state

        const isShipper = accountType === "isShipper"
        const isThirdParty = accountType === "isThirdParty"

        const FormLabelProps = {
            classes: {
                root: classes.input__label,
                label: classes.input__label,
            },
        }
        const radioClasses = {
            root: classes.whiteOverride,
            colorSecondary: classes.whiteOverride,
            checked: classes.whiteOverride,
            label: classes.whiteOverride,
        }

        const isQuebecShipperAccount =
            isShipper && shippingState === "QC" && shippingCountry === "CA"

        return (
            <Grid item container className={classes.signup__form}>
                {loading && <GlobalSpinner />}
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={this.state.sbOpen}
                    autoHideDuration={6000}
                    onClose={this.handleSnackbarClose}
                >
                    <ErrorSnackbarContentWrapper
                        variant={this.state.sbVariant}
                        onClose={this.handleSnackbarClose}
                        message={
                            <Typography variant="body2">
                                {this.state.sbMessage}
                            </Typography>
                        }
                    />
                </Snackbar>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        className={modalSuccess ? null : classes.error__title}
                    >
                        {modalSuccess ? (
                            <FormattedMessage
                                id="addLocation__locationSuccessfullyAdded"
                                defaultMessage="Location Successfully Added"
                            />
                        ) : (
                            <img
                                alt="LTL Select"
                                src="static/LTLselect-logo-type-new.png"
                            />
                        )}
                    </DialogTitle>
                    <Divider />
                    <DialogContent className={classes.dialog}>
                        {modalError ? (
                            <Grid item container>
                                <Typography
                                    variant="subtitle1"
                                    className={classes.error__entry}
                                >
                                    {modalError}
                                </Typography>
                            </Grid>
                        ) : (
                            <Grid
                                item
                                container
                                justifyContent="center"
                                alignItems="center"
                            >
                                {locationType === "FEDEX_DIRECT" && (
                                    <Grid
                                        item
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography
                                            variant="body2"
                                            color="secondary"
                                        >
                                            <FormattedMessage
                                                id="locations__freightDirectMessage"
                                                defaultMessage="The account entered allows you to rate and ship with FedEx Freight Direct only. To be able to get rates with other carriers, please sign up using your FedEx Freight shipper or third party account."
                                            />
                                        </Typography>
                                    </Grid>
                                )}
                                {isQuebecShipperAccount && (
                                    <Grid
                                        item
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography variant="body2">
                                            <FormattedMessage
                                                id="locations__quebecShipperAccountWarning"
                                                defaultMessage="The account entered allows you to rate and ship with FedEx Freight only at this point."
                                            />
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {modalError && (
                            <Button
                                variant="contained"
                                onClick={this.handleClose}
                                color="primary"
                                autoFocus
                            >
                                <FormattedMessage
                                    id="generalTerms__close"
                                    defaultMessage="Close"
                                />
                            </Button>
                        )}
                        {modalSuccess && (
                            <Button
                                variant="outlined"
                                color="secondary"
                                id="addLocation__back"
                                onClick={() => onBackClick()}
                                autoFocus
                            >
                                <FormattedMessage
                                    id="addLocation__backToLocations"
                                    defaultMessage="Back to Locations"
                                />
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
                <RequestAccess
                    open={requestAccessOpen}
                    data={duplicate}
                    handleClose={() => this.handleRequestAccessClose()}
                    handleRequestAccess={() => this.handleRequestAccess()}
                />
                <Grid item container className={classes.decision__container}>
                    <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid
                            item
                            container
                            justifyContent="center"
                            className={classes.decision__titleContainer}
                        >
                            <img
                                alt="LTL Select"
                                src="static/LTLselect-logo-type-new.png"
                            />
                        </Grid>
                        <Grid
                            item
                            justifyContent="center"
                            className={classes.decision__divider}
                        />
                        <Grid
                            item
                            container
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid
                                item
                                container
                                xs={4}
                                justifyContent="center"
                                alignItems="center"
                                className={classes.signup__decision__text}
                            >
                                <Typography variant="h6" color="inherit">
                                    <FormattedMessage
                                        id="signUp__title"
                                        defaultMessage="I want to sign up with:"
                                    />
                                </Typography>
                            </Grid>
                            <Grid item container xs={6} alignItems="center">
                                <Field
                                    onClick={() =>
                                        this.handleAccountTypeSelect()
                                    }
                                    formlabelprops={FormLabelProps}
                                    component={FormRadio}
                                    radioClasses={radioClasses}
                                    formControlLabelClasses={radioClasses}
                                    id="addLocation__accountType"
                                    name="accountType"
                                    options={[
                                        {
                                            label: (
                                                <FormattedMessage
                                                    id="signUp__fedExShipperAccount"
                                                    defaultMessage="FedEx Shipper Account"
                                                />
                                            ),
                                            value: "isShipper",
                                        },
                                        {
                                            label: (
                                                <FormattedMessage
                                                    id="signUp__fedExThirdPartyAccount"
                                                    defaultMessage="FedEx Third Party Account"
                                                />
                                            ),
                                            value: "isThirdParty",
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <form
                    onSubmit={handleSubmit(values =>
                        this.handleAddLocation(values, activeUser)
                    )}
                    className={classes.cards__container}
                >
                    <Grid item container>
                        {isShipper && (
                            <Grid
                                item
                                xs={6}
                                className={classes.signup__section}
                            >
                                <Grid
                                    item
                                    container
                                    className={classes.signup__card}
                                >
                                    <ShippingInformation
                                        locationTypes={shipperLocationTypes}
                                        changeField={changeField}
                                        formName={"addLocation"}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {isShipper && (
                            <Grid
                                item
                                xs={6}
                                className={classes.signup__section}
                            >
                                <Grid
                                    item
                                    container
                                    className={classes.signup__card}
                                >
                                    <PickUpInformation
                                        country={shippingCountry}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {(isShipper || isThirdParty) && (
                            <Grid
                                item
                                xs={6}
                                className={classes.signup__section}
                            >
                                <Grid
                                    item
                                    container
                                    className={
                                        classes.signup__card__variableSize
                                    }
                                >
                                    <BillingInformation
                                        isShipper={isShipper}
                                        isThirdParty={isThirdParty}
                                        locationTypes={thirdPartyLocationTypes}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item className={classes.signup__section}>
                        <Grid
                            item
                            container
                            className={classes.terms__container}
                            justifyContent="space-between"
                        >
                            <Grid item container xs={6}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => onBackClick("success")}
                                >
                                    <FormattedMessage
                                        id="generalTerms__back"
                                        defaultMessage="Back"
                                    />
                                </Button>
                            </Grid>
                            {(isShipper || isThirdParty) && (
                                <Grid
                                    item
                                    container
                                    xs={6}
                                    justifyContent="flex-end"
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={
                                            pristine || invalid || submitting
                                        }
                                        className={classes.signup__button}
                                        id="addLocation__add"
                                    >
                                        <FormattedMessage
                                            id="generalTerms__add"
                                            defaultMessage="Add"
                                        />
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        )
    }
}

AddLocation.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSignUpSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    onLogOut: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onSameAsShipping: PropTypes.func.isRequired,
    onSameAsUserInfo: PropTypes.func.isRequired,
    error: PropTypes.string,
}

AddLocation.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: true,
    error: "",
}

const mapStateToProps = state => {
    const defaultCountry =
        supportedLanguagesCountryMap[
            state?.user?.profile?.preferences?.language
        ] || "US"
    return {
        activeUser: state?.user?.profile,
        initialValues: {
            location: {
                readyTime: "6:00 AM",
                closeTime: "1:00 PM",
                address: {
                    country: defaultCountry,
                },
                shippingAddress: {
                    address: {
                        country: defaultCountry,
                    },
                },
            },
        },
    }
}

const mapDispatchToProps = dispatch => ({
    addLocation: values => dispatch(addLocation(values)),
    onBackClick: () => dispatch(changePath("/locations")),
    requestAccessToLocation: locationId =>
        dispatch(requestAccessToLocation(locationId)),
    changeField: (form, field, value) => dispatch(change(form, field, value)),
})

const locationValidation = combineValidators({
    location: formValidation,
})

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "addLocation", // a unique identifier for this form
            validate: locationValidation,
        })(
            formValues({
                locationType: "location.locationType",
                shippingState: "location.shippingAddress.address.state",
                shippingCountry: "location.shippingAddress.address.country",
                accountType: "accountType",
            })(AddLocation)
        )
    )
)

import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { loggedIn, logOut } from "../../actions/authorization"
import { changePath } from "../../actions"
import { trackGAEvent } from "../../actions/user"

export const UserHeaderPresentation = ({ user, handleClick }) => {
    if (!user) return <span />
    return (
        <div
            className="user-credentials"
            onClick={handleClick}
            role="button"
            tabIndex="-1"
        >
            <div id="user-name">
                {user.firstname} {user.lastname}
            </div>
            <div id="user-email">{user.email && user.email.email_address}</div>
        </div>
    )
}

UserHeaderPresentation.propTypes = {
    user: PropTypes.object,
    handleClick: PropTypes.func.isRequired,
}

UserHeaderPresentation.defaultProps = {
    user: undefined,
}

const userStateToProps = state => ({ user: state.user.profile })
const userDispatchToProps = dispatch => ({
    handleClick: () => {
        dispatch(trackGAEvent("Profile", "Profile (view)"))
        dispatch(changePath("/user"))
    },
})

export const UserHeader = connect(
    userStateToProps,
    userDispatchToProps
)(UserHeaderPresentation)

export const LogOutPresentation = ({ onClick, authorization }) => {
    if (!authorization.isFinished || !loggedIn()) return <span />
    return (
        <button className="user-logout-button" onClick={onClick}>
            LOGOUT
        </button>
    )
}

LogOutPresentation.propTypes = {
    authorization: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
}

const logOutStateToProps = ({ user, authorization }) => ({
    user,
    authorization,
})

const logOutDispatchToProps = (dispatch, props) => ({
    onClick: () => dispatch(logOut()),
})

export const LogOut = withRouter(
    connect(logOutStateToProps, logOutDispatchToProps)(LogOutPresentation)
)

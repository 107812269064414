import {
    CREATED_MANIFEST_RECIEVED,
    GET_CREATE_STATUS_SHIPMENTS,
    TABLE_SHIPMENTS_LOADING_CHANGE,
    TRAILER_MANIFEST_LOADING_CHANGE,
    CLEAR_ACTIVE_MANIFEST,
} from "../actions/trailerManifestActions"

export function trailerManifest(
    state = {
        tableShipments: [],
        activeManifestFile: null,
        shipmentsLoading: false,
        trailerManifestLoading: false,
    },
    action = {}
) {
    switch (action.type) {
        case GET_CREATE_STATUS_SHIPMENTS:
            return {
                ...state,
                tableShipments: action.shipments,
            }
        case CREATED_MANIFEST_RECIEVED:
            return {
                ...state,
                activeManifestFile: action.file,
            }
        case TABLE_SHIPMENTS_LOADING_CHANGE:
            return {
                ...state,
                shipmentsLoading: action.isLoading,
            }
        case TRAILER_MANIFEST_LOADING_CHANGE:
            return {
                ...state,
                trailerManifestLoading: action.isLoading,
            }
        case CLEAR_ACTIVE_MANIFEST:
            return {
                ...state,
                activeManifestFile: null,
            }
        default:
            return state
    }
}

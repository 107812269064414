import React from "react"
import { Field } from "redux-form"
import { FormattedMessage } from "react-intl"
import FormCheckbox from "../../form/form-checkbox"
import { usmcaFormMessages } from "../USMCA/messages"

export const ShipmentOnlyCheck = ({ gaCategory }) => (
    <Field
        name="shipmentOnly"
        component={FormCheckbox}
        category={gaCategory}
        label={<FormattedMessage {...usmcaFormMessages.shipmentOnly} />}
    />
)

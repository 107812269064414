export const isGuaranteedQuote = (serviceCode, guaranteedList) =>
    guaranteedList.find(item => item.value === serviceCode)

export const calculateMarkupPrice = (price, markup) => {
    if (!markup || !markup.markupAmount) return price
    if (!markup.markupType) {
        return Number(price) + Number(markup.markupAmount)
    }
    return Number(price) * (1 + Number(markup.markupAmount) / 100)
}

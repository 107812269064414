import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { get } from "lodash"
import { Field, formValues, resetSection } from "redux-form"
import { withStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"
import FormField from "../form/form-field"
import FormSelect from "../form/form-select"
import FormSelectAutocomplete from "../form/form-select-autocomplete"
import FormZipCode from "../form/form-zip-code"
import { FormattedMessage } from "react-intl"
import { supportedCountryCodes } from "../../misc"
import formName from "../util/form-name"

const styles = theme => ({})

const ShippingInformation = ({
    classes,
    cities,
    country,
    form,
    locationTypes,
    changeField,
    formName,
    otherCarrier = false,
}) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(
            resetSection(
                form,
                "location.shippingAddress.address.postalCode",
                "location.shippingAddress.address.state",
                "location.shippingAddress.address.city",
                "location.locationType"
            )
        )
    }, [dispatch, country, form])

    return (
        <Fragment>
            <Grid item container>
                <Grid item container xs={12}>
                    <Typography variant="h6" className={classes.section__title}>
                        <FormattedMessage
                            id="signUp.shipping__title"
                            defaultMessage="Shipping Information"
                        />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Field
                        name="location.shippingAddress.name"
                        label={
                            <FormattedMessage
                                id="locations.shippingInformation__shipperName"
                                defaultMessage="Shipper Name"
                            />
                        }
                        className={classes.textField}
                        component={FormField}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        name="location.shippingAddress.address.street1"
                        component={FormField}
                        type="text"
                        label={
                            <FormattedMessage
                                id="generalTerms__address1"
                                defaultMessage="Address 1"
                            />
                        }
                        maxLength="30"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        name="location.shippingAddress.address.street2"
                        component={FormField}
                        type="text"
                        label={
                            <FormattedMessage
                                id="generalTerms__address2"
                                defaultMessage="Address 2"
                            />
                        }
                        maxLength="30"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        name="location.shippingAddress.address.country"
                        component={FormSelect}
                        type="text"
                        label={
                            <FormattedMessage
                                id="generalTerms__country"
                                defaultMessage="Country"
                            />
                        }
                        options={supportedCountryCodes}
                        maxLength="30"
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        name="location.shippingAddress.address.postalCode"
                        component={FormZipCode}
                        type="text"
                        label={
                            <FormattedMessage
                                id="generalTerms__zipPostalCode"
                                defaultMessage="Zip/Postal Code"
                            />
                        }
                        country={country}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        name="location.shippingAddress.address.city"
                        component={FormSelectAutocomplete}
                        customValue
                        label={
                            <FormattedMessage
                                id="generalTerms__city__selectOrEnter"
                                defaultMessage="City (Select or Enter)"
                            />
                        }
                        options={cities.map(c => ({
                            value: c,
                            label: c,
                        }))}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        name="location.shippingAddress.address.state"
                        component={FormField}
                        type="text"
                        label={
                            <FormattedMessage
                                id="generalTerms__stateProvince"
                                defaultMessage="State/Province"
                            />
                        }
                        inputProps={{ maxLength: 2 }}
                        required
                    />
                </Grid>
                {!otherCarrier && (
                    <Grid item xs={6}>
                        <Field
                            name="location.fedexFreightAccount"
                            label={
                                <FormattedMessage
                                    id="addLocation.shipping__fedExShipperAccountNumber"
                                    defaultMessage="FedEx Shipper Account #:"
                                />
                            }
                            component={FormField}
                            required
                        />
                    </Grid>
                )}
                {!otherCarrier && (
                    <Grid item xs={6}>
                        <Field
                            name="location.locationType"
                            component={FormSelectAutocomplete}
                            label={
                                <FormattedMessage
                                    id="addLocation.shipping_locationType"
                                    defaultMessage="FedEx Account Type :"
                                />
                            }
                            options={locationTypes}
                            required
                            onChange={locationType => {
                                if (locationType === "FEDEX_FREIGHT_BOX") {
                                    changeField(formName, "isThirdParty", false)
                                }
                            }}
                        />
                    </Grid>
                )}
            </Grid>
        </Fragment>
    )
}

ShippingInformation.propTypes = {
    classes: PropTypes.object.isRequired,
    cities: PropTypes.array.isRequired,
}

const mapStateToProps = (state, { postalCode, country }) => ({
    cities: get(state, `postalCode[${country}-${postalCode}].cities`, []),
})

export default withStyles(styles)(
    formValues({
        postalCode: "location.shippingAddress.address.postalCode",
        country: "location.shippingAddress.address.country",
    })(connect(mapStateToProps)(formName(ShippingInformation)))
)

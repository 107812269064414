import { get } from "lodash"
import { INIT_ADDRESS_MODAL, CLOSE_ADDRESS_MODAL } from "../actions/address"

export function loadContact(item) {
    if (!item) return {}
    return {
        id: get(item, "id") || get(item, "_id"),
        name: get(item, "name"),
        phone:
            get(item, "phone.phone_number") || get(item, "phone.phoneNumber"),
        extension: get(item, "phone.extension"),
        email:
            get(item, "email.email_address") || get(item, "email.emailAddress"),
    }
}

export function checkPostalCode(postalCode) {
    return postalCode && postalCode.toString().padStart(5, "0")
}

export function loadAddress(item) {
    if (!item || !item.address) return false
    if (item.id) {
        return item.address
            ? {
                  ...item.address,
                  id: item.id,
                  companyName: item.name,
              }
            : item
    }
    return {
        street1: item.address.street1,
        street2: item.address.street2 ?? "",
        city: item.address.city,
        state: item.address.state,
        postalCode: checkPostalCode(item.address.postalCode),
        companyName: item.name || "",
        id: item._id || "",
    }
}

export function addressModal(
    state = {
        groups: [],
        shown: false,
    },
    action = {}
) {
    switch (action.type) {
        case INIT_ADDRESS_MODAL:
            return { groups: action.groups, shown: true }
        case CLOSE_ADDRESS_MODAL:
            return addressModal()
        default:
            return state
    }
}

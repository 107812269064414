import React, { createContext, useContext } from "react"
import { goFetch } from "../../http"

export const UsersContext = createContext()

export const useUsersContext = () => {
    const usersContext = useContext(UsersContext)
    if (!usersContext) {
        throw new Error("Cannot use order context ouside of OrderProvider")
    }
    return usersContext
}

export default function UsersProvider({ children }) {
    const updateUserPreferences = async filters => {
        try {
            const { data: resp } = await goFetch(
                `/users`,
                {
                    method: "PUT",
                    credentials: "same-origin",
                    headers: { "cache-control": "no-cache" },
                    data: {
                        "preferences.ordersPageFilters": filters,
                    },
                },
                true
            )
        } catch (error) {}
        return null
    }

    const getUserPreferences = async () => {
        try {
            const { data: resp } = await goFetch(
                `/users/locations`,
                {
                    method: "Get",
                    credentials: "same-origin",
                    headers: { "cache-control": "no-cache" },
                },
                true
            )
            return resp?.preferences
        } catch (error) {}
    }

    const contextData = {
        updateUserPreferences,
        getUserPreferences,
    }

    return (
        <UsersContext.Provider value={contextData}>
            {children}
        </UsersContext.Provider>
    )
}

import { combineReducers } from "redux"
import {
    CHAT_RECEIVED,
    CHAT_EVENT_RECEIVED,
    CHAT_HISTORY_RECEIVED,
    CHAT_SET_CHANNEL,
    CHAT_MARK_READ,
    CHAT_MESSAGE_CHANGE,
    CHANNEL_LIST_RECEIVED,
    CHAT_QUOTE_RECEIVED,
    TRACKING_EVENTS_RECEIVED,
    CHAT_ADD_PHONE_NUMBER,
    CHAT_REMOVE_CHANNEL,
    ADD_CONTACT_CHANNEL,
    ON_CHAT_VIEW_CHANGE,
    CHAT_CHANNEL_STATUS_RECEIVED,
    PUSHER_SUBSCRIPTION,
    CHAT_SENDING_MESSAGE,
    CHAT_MARK_REMOTE_READ,
    CHAT_ONLINE_STATE_CHANGE,
} from "../actions/chat"
import { USER_DISAUTHENTICATED } from "../actions/user"

export function active(state = "", action) {
    switch (action.type) {
        case CHAT_SET_CHANNEL:
            return action.channel
        case USER_DISAUTHENTICATED:
            return ""
        case CHAT_ADD_PHONE_NUMBER:
            return action.activeChannel
        case ADD_CONTACT_CHANNEL:
            return action.contact.contact.name
        default:
            return state
    }
}

function dedupeMessages(oldMessages, message) {
    const result = oldMessages.slice()
    if (!message._id) {
        result.push(message)
        return result.sort(
            (a, b) => new Date(a.created_at) - new Date(b.created_at)
        )
    }
    return oldMessages
        .map(m => (m._id === message._id ? message : m))
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
}

function removeChannel(state, channelToRemove) {
    const newState = { ...state }
    delete newState[channelToRemove]
    return newState
}

function findChannelUser(users) {
    return ["phoneNumber", "contactId"].map(key =>
        users.find(user => user[key] !== undefined && user[key] !== null)
    )
}

function buildChannelName(action) {
    if (action.channel.indexOf("DM") > -1) {
        const [phoneNumberUser, contactUser] = findChannelUser(action.users)
        if (phoneNumberUser) return phoneNumberUser.phoneNumber
        if (contactUser) return contactUser.contactId.name
    }
    return action.channel
}

function channel(
    state = {
        messages: [],
        unread: { message: 0, event: 0, alert: 0 },
        users: {},
        statusFetched: false,
        pusherSubscribed: false,
        historyReceived: false,
    },
    action = {}
) {
    switch (action.type) {
        case CHAT_RECEIVED:
        case CHAT_EVENT_RECEIVED:
        case CHAT_QUOTE_RECEIVED:
            if (!state.historyReceived) return state
            return {
                ...state,
                messages: dedupeMessages(state.messages, action.message),
                unread: {
                    ...state.unread,
                    [action.message.type]:
                        (state.unread[action.message.type] || 0) + 1,
                },
            }
        case CHAT_HISTORY_RECEIVED:
            return {
                ...state,
                messages: action.messages,
                historyReceived: true,
            }
        case TRACKING_EVENTS_RECEIVED:
            return {
                ...state,
                trackingEvents: action.trackingEvents,
            }
        case CHAT_CHANNEL_STATUS_RECEIVED:
            return {
                ...state,
                channelName: buildChannelName(action),
                unread: action.unread,
                statusFetched: true,
                ...(!state.historyReceived &&
                    action.message && {
                        messages: [action.message],
                    }),
                users: action.users.reduce(
                    (obj, item) => ({
                        ...obj,
                        [item._id]: item,
                    }),
                    {}
                ),
                selfId: action.selfId,
            }
        case CHAT_ONLINE_STATE_CHANGE:
            return {
                ...state,
                users: {
                    ...state.users,
                    ...action.ids.reduce((prev, id) => {
                        prev[id] = {
                            ...state.users[id],
                            online: action.online,
                        }
                        return prev
                    }, {}),
                },
            }
        case CHAT_MARK_READ:
            return Object.assign({}, state, {
                unread: Object.keys(state.unread).reduce(
                    (obj, type) => ({ ...obj, [type]: 0 }),
                    {}
                ),
            })
        case CHAT_MARK_REMOTE_READ:
            return {
                ...state,
                messages: state.messages.map(message => ({
                    ...message,
                    state: "received",
                })),
            }
        case PUSHER_SUBSCRIPTION:
            return { ...state, pusherSubscribed: action.value }
        default:
            return state
    }
}

export function contactChannel(
    state = {
        contactId: "",
    },
    action = {}
) {
    switch (action.type) {
        case ADD_CONTACT_CHANNEL:
            return {
                ...state,
                contactId: action.contact.contact.id,
                name: action.contact.contact.name,
            }
        case CHAT_SET_CHANNEL:
        default:
            return state
    }
}

export function view(
    state = {
        active: "home",
    },
    action = {}
) {
    switch (action.type) {
        case ON_CHAT_VIEW_CHANGE:
            return {
                active: action.active,
            }
        case TRACKING_EVENTS_RECEIVED:
            return {
                active: "track",
            }
        default:
            return state
    }
}

export function channels(state = {}, action = {}) {
    switch (action.type) {
        case CHAT_RECEIVED:
        case CHAT_EVENT_RECEIVED:
        case CHAT_HISTORY_RECEIVED:
        case TRACKING_EVENTS_RECEIVED:
        case CHAT_MARK_READ:
        case CHAT_QUOTE_RECEIVED:
        case CHAT_CHANNEL_STATUS_RECEIVED:
        case PUSHER_SUBSCRIPTION:
        case CHAT_ONLINE_STATE_CHANGE:
            return {
                ...state,
                [action.channel]: channel(state[action.channel], action),
            }
        case USER_DISAUTHENTICATED:
            return channels()
        case CHAT_REMOVE_CHANNEL:
            return removeChannel(state, action.channel)
        default:
            return state
    }
}

function current(
    state = {
        message: "",
        inProgress: false,
    },
    action = {}
) {
    switch (action.type) {
        case CHAT_MESSAGE_CHANGE:
            return Object.assign({}, state, {
                message: action.message,
            })
        case CHAT_SENDING_MESSAGE:
            return {
                ...state,
                inProgress: action.inProgress,
                message: action.message || "",
            }
        default:
            return state
    }
}

function metadata(
    state = {
        listFetched: {},
    },
    action = {}
) {
    switch (action.type) {
        case CHANNEL_LIST_RECEIVED:
            return {
                ...state,
                listFetched: {
                    ...state.listFetched,
                    [action.kind]: true,
                },
            }
        default:
            return state
    }
}

export const chat = combineReducers({
    active,
    channels,
    current,
    contactChannel,
    view,
    metadata,
})

import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { FormattedMessage } from "react-intl"
import { Field } from "redux-form"
import FormField from "../../../form/form-field"
import normalizePhone from "../../../util/normalizePhone"
import FormSwitch from "../../../form/form-switch"
import FormSelectAutocomplete from "../../../form/form-select-autocomplete"
import {
    generateTimeList,
    StandardOrBasicwApptTimeList,
} from "../../../book/pickup/form"
import TerminalInfo from "../../../common/TerminalInfo"
import PickupWarning from "../../../../components/book/pickup/warning"

export default function PickUpForm({
    category,
    country,
    pickupLater,
    terminal,
    isPastPickup,
    carrierCode,
    rateMode,
    isFreightDirectReturns,
    serviceLevel,
    isIntraMexico,
    dayOfTheWeek,
}) {
    const standardOrBasicwAppt =
        isFreightDirectReturns &&
        (serviceLevel?.code === "BASIC_APPT_PICKUP" ||
            serviceLevel?.code === "STANDARD_PICKUP")

    return (
        <Grid xs={12} item>
            <Grid>
                <Grid item container justify="center">
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="orderDetails.pickup__requirements"
                            defaultMessage="Pickup Requirements"
                        />
                    </Typography>
                </Grid>
                <Grid item container>
                    <PickupWarning
                        isPastPickup={isPastPickup}
                        pickupLater={pickupLater}
                        carrierCode={carrierCode}
                        rateMode={rateMode}
                        country={country}
                    />

                    <Grid item container>
                        <Field
                            name="pickupLater"
                            label={
                                <FormattedMessage
                                    id="orderDetails.label__bolOnly"
                                    defaultMessage="Schedule a pickup later (BOL Only)"
                                />
                            }
                            component={FormSwitch}
                            category={category}
                        />
                    </Grid>
                    {!pickupLater && (
                        <Grid item container>
                            {standardOrBasicwAppt ? (
                                <Grid xs={12} item container justify="center">
                                    <Field
                                        name="origin.pickUpWindow"
                                        label={
                                            <FormattedMessage
                                                id="generalTerms.pickUpInformation__readyTimeCloseTime"
                                                defaultMessage="Schedule Pickup Window"
                                            />
                                        }
                                        component={FormSelectAutocomplete}
                                        required={!pickupLater}
                                        options={StandardOrBasicwApptTimeList}
                                    />
                                </Grid>
                            ) : (
                                <>
                                    <Grid xs={6} item container>
                                        <Field
                                            category={category}
                                            name="origin.readyTime"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms.pickUpInformation__readyTime"
                                                    defaultMessage="Ready Time"
                                                />
                                            }
                                            component={FormSelectAutocomplete}
                                            required={!pickupLater}
                                            options={generateTimeList()}
                                            disabled={isFreightDirectReturns}
                                        />
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Field
                                            category={category}
                                            name="origin.closeTime"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms.pickUpInformation__closeTime"
                                                    defaultMessage="Close Time"
                                                />
                                            }
                                            component={FormSelectAutocomplete}
                                            required={!pickupLater}
                                            options={generateTimeList()}
                                            disabled={isFreightDirectReturns}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item container>
                                <Field
                                    category={category}
                                    name="origin.pickupContact.name"
                                    label={
                                        <FormattedMessage
                                            id="generalTerms__pickukpContactName"
                                            defaultMessage="Pickup Contact Name"
                                        />
                                    }
                                    component={FormField}
                                />
                            </Grid>
                            <Grid item container xs={4}>
                                <Field
                                    category={category}
                                    name="origin.pickupContact.phone.phone_number"
                                    label={
                                        <FormattedMessage
                                            id="generalTerms__pickupContactPhone"
                                            defaultMessage="Pickup Contact Phone"
                                        />
                                    }
                                    component={FormField}
                                    normalize={normalizePhone(country)}
                                />
                            </Grid>
                            <Grid item container xs={2}>
                                <Field
                                    category={category}
                                    name="origin.pickupContact.phone.extension"
                                    label={
                                        <FormattedMessage
                                            id="generalTerms__extension"
                                            defaultMessage="Extension"
                                        />
                                    }
                                    component={FormField}
                                />
                            </Grid>
                            <Grid item container xs={6}>
                                <Field
                                    category={category}
                                    name="origin.pickupContact.email.email_address"
                                    label={
                                        <FormattedMessage
                                            id="generalTerms__pickupContactEmail"
                                            defaultMessage="Pickup Contact Email"
                                        />
                                    }
                                    component={FormField}
                                />
                            </Grid>
                            <Grid item container>
                                <Field
                                    category={category}
                                    name="origin.note"
                                    label={
                                        <FormattedMessage
                                            id="orderDetails.pickup__pickupRemarks"
                                            defaultMessage="Pickup Remarks"
                                        />
                                    }
                                    component={FormField}
                                    multiline
                                />
                            </Grid>
                        </Grid>
                    )}
                    {pickupLater && terminal && (
                        <TerminalInfo
                            isPickup
                            terminal={terminal}
                            isIntraMexico={isIntraMexico}
                            dayOfTheWeek={dayOfTheWeek}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

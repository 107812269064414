import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"

const styles = theme => ({
    progress: {
        position: "fixed",
        marginLeft: "auto",
        marginRight: "auto",
        left: 0,
        right: 0,
        top: "50%",
        zIndex: "8000",
    },
    progressLocal: {
        margin: "auto",
    },
    spinnerOverlay: {
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,.3)",
        zIndex: 99999,
    },
})

const GlobalSpinner = ({ classes, size, color, isGlobal = true }) => (
    <Fragment>
        <div className={isGlobal ? classes.spinnerOverlay : ""} />
        <CircularProgress
            className={isGlobal ? classes.progress : classes.progressLocal}
            size={size || 80}
            color={color || "secondary"}
        />
    </Fragment>
)

GlobalSpinner.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(GlobalSpinner)

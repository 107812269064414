import React, { Component } from "react"
import ReactIdleTimer from "react-idle-timer"
import { ReloadNotificationContainer } from "./reload-notification"
import { withToken } from "./withToken"

class InactivityTimerPresentation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isInactive: false,
        }
    }

    render() {
        return (
            <ReactIdleTimer
                timeout={6 * 60 * 60 * 1000}
                onIdle={() => this.setState({ isInactive: true })}
            >
                {this.state.isInactive && (
                    <ReloadNotificationContainer type="inactivity" isOpen />
                )}
            </ReactIdleTimer>
        )
    }
}

export const InactivityTimer = withToken(InactivityTimerPresentation)

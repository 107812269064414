import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { Field } from "redux-form"
import { Grid } from "@material-ui/core"
import { composeValidators } from "revalidate"
import { FormattedMessage, defineMessages } from "react-intl"
import FormField from "../form/form-field"
import {
    emailValidator,
    countryPhoneValidator,
    isRequired,
    isNumeric,
} from "../../actions/validation"
import normalizePhone from "../util/normalizePhone"
import MaskedInput from "react-text-mask"

const styles = theme => ({
    card: {
        height: "100%",
    },
    content: {
        "margin-left": "60px",
    },
})

const messages = defineMessages({
    nameField: {
        id: "generalTerms__contactName",
        defaultMessage: "Contact Name",
    },
    phoneField: {
        id: "generalTerms__contactPhone",
        defaultMessage: "Contact Phone",
    },
    extensionField: {
        id: "generalTerms__contactExtension",
        defaultMessage: "Contact Extension",
    },
    emailField: {
        id: "generalTerms__contactEmail",
        defaultMessage: "Contact Email",
    },
})

export const contactValidator = country => ({
    name: isRequired({ field: messages.nameField }),
    phone: {
        phone_number: composeValidators(
            isRequired,
            countryPhoneValidator(country)
        )({
            field: messages.phoneField,
        }),
        extensionField: isNumeric({
            field: messages.extensionField,
        }),
    },
    email: composeValidators(
        isRequired,
        emailValidator
    )({
        field: messages.emailField,
    }),
})

const ContactForm = ({
    classes,
    disabled,
    isRequired,
    country,
    mode,
    serviceLevel,
    changeToTerminal,
}) => {
    const isDestination = mode === "destination"
    const hideEmail =
        serviceLevel?.code === "BASIC_PICKUP_AND_DESTROY" && changeToTerminal
    return (
        <Grid container>
            <Grid item container xs={6}>
                <Field
                    name="name"
                    label={
                        <FormattedMessage
                            id="generalTerms__contactName"
                            defaultMessage="Contact Name"
                        />
                    }
                    component={FormField}
                    disabled={disabled}
                    required={isRequired && !disabled}
                />
            </Grid>
            <Grid item container xs={4}>
                <Field
                    name="phone.phone_number"
                    label={
                        <FormattedMessage
                            id="generalTerms__contactPhone"
                            defaultMessage="Contact Phone"
                        />
                    }
                    component={FormField}
                    disabled={disabled}
                    required={isRequired && !disabled}
                    normalize={normalizePhone(country)}
                />
            </Grid>
            <Grid item container xs={2}>
                <Field
                    name="phone.extension"
                    label={
                        <FormattedMessage
                            id="generalTerms__extension"
                            defaultMessage="Extension"
                        />
                    }
                    component={FormField}
                    disabled={disabled}
                />
            </Grid>
            <Field
                name="email"
                label={
                    <FormattedMessage
                        id="generalTerms__contactEmail"
                        defaultMessage="Contact Email"
                    />
                }
                component={FormField}
                disabled={disabled || hideEmail}
                required={isRequired && !disabled}
                InputProps={
                    isDestination && hideEmail
                        ? {
                              inputComponent: TextMaskEmail,
                          }
                        : {}
                }
            />
        </Grid>
    )
}

const TextMaskEmail = props => {
    const { inputRef, ...other } = props

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null)
            }}
            mask={function(rawValue) {
                let maskid = rawValue.replace(
                    /^(.)(.*)(.@.*)$/,
                    (_, a, b, c) => a + b.replace(/./g, "*") + c
                )
                return maskid.split("")
            }}
            maskChar="*"
            showMask
        />
    )
}

ContactForm.propTypes = {
    classes: PropTypes.object,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
}

ContactForm.defaultProps = {
    classes: {},
    disabled: false,
    isRequired: true,
}

export default withStyles(styles)(ContactForm)

import React, { createContext, useContext, useEffect } from "react"
import ReactGA from "react-ga4"

export const GAContext = createContext()

export const useGAContext = () => {
    const gaContext = useContext(GAContext)
    if (!gaContext) {
        throw new Error(
            "Cannot use order context ouside of Google Analytics Provider"
        )
    }
    return gaContext
}

const initializeGA = () => {
    const trackingId = window._env_.REACT_APP_GA_TRACKING_ID
    if (trackingId) {
        // When initialized with testMode = true, we are not actually logging events.
        const testMode = window._env_.REACT_APP_ENABLE_LOGGING === "enabled"
        ReactGA.initialize(trackingId, { testMode })
    } else {
        console.warn("Failed to initialize Google Analytics. No tracking ID.")
    }
}

const GAProvider = ({ children }) => {
    useEffect(() => initializeGA(), [])
    const testMode = window._env_.REACT_APP_ENABLE_LOGGING === "enabled"

    const setUserProperties = async () => {
        ReactGA.set({
            user_properties: {
                selected_language: "",
            },
        })
    }

    const logGAEvent = async (category, action, label) => {
        if (testMode) {
            console.groupCollapsed(`Google Analytics Event: ${action}`)
            console.log(`Event: ${action}`)
            console.log(`Category: ${category}`)
            if (label) {
                console.log(`Label: ${label}`)
            }
            console.groupEnd()
        } else {
            ReactGA.event({
                category,
                action,
                label,
            })
        }
    }

    const logGAError = async (action, error) => {
        if (testMode) {
            console.groupCollapsed(`Google Analytics Error: ${action}`)
            console.log(`Message: ${error}`)
            console.log(`Event: ${action}`)
            console.log(`Category: Error`)
            console.groupEnd()
        } else {
            ReactGA.event({
                category: "Error",
                action: error,
                label: "",
            })
        }
    }

    return (
        <GAContext.Provider
            value={{ setUserProperties, logGAEvent, logGAError }}
        >
            {children}
        </GAContext.Provider>
    )
}

export default GAProvider

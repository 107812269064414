import decode from "jwt-decode"
import ReactGA from "react-ga4"
import moment from "moment"
import qs from "qs"
import { v4 as uuidv4 } from "uuid"
import { get, capitalize } from "lodash"
import {
    authenticated,
    disauthenticated,
    initSignup,
    retrieveUser,
    trackGAEvent,
    userReceived,
} from "./user"
import { changePath, errorMessage, typedError } from "./index"
import { loadData, saveData } from "../misc"
import Auth0Lock from "auth0-lock"

if (
    !window._env_.REACT_APP_AUTH0_CLIENT_ID ||
    !window._env_.REACT_APP_AUTH0_DOMAIN
) {
    throw new Error(
        "Please define `REACT_APP_AUTH0_CLIENT_ID` and `REACT_APP_AUTH0_DOMAIN` " +
            "in your .env file"
    )
}

export const AUTHORIZATION_ERROR = "AUTHORIZATION_ERROR"
export const AUTHORIZATION_FINISHED = "AUTHORIZATION_FINISHED"
export const AUTHENTICATION_FINISHED = "AUTHENTICATION_FINISHED"
export const SHOW_LOCK = "SHOW_LOCK"
export const HIDE_LOCK = "HIDE_LOCK"
export const INIT_FINISHED = "INIT_FINISHED"
export const TOKEN_PARSED = "TOKEN_PARSED"
export const IMPERSONATION_WARNING = "IMPERSONATION_WARNING"
export const IMPERSONATION_ENABLED = "IMPERSONATION_ENABLED"

export const authenticationFinished = () => ({ type: AUTHENTICATION_FINISHED })
export const authorizationFinished = () => ({ type: AUTHORIZATION_FINISHED })
export const authorizationError = error =>
    typedError(AUTHORIZATION_ERROR, error)
export const initializationFinished = () => ({ type: INIT_FINISHED })

export function getUserId() {
    const token = loadData("token")
    if (!token) return undefined
    const decoded = decode(token)
    return get(decoded, "sub")
}

export function getTokenExpirationDate(token) {
    if (!token) return undefined
    const decoded = decode(token)
    if (!decoded.exp) return undefined
    const date = new Date(0)
    date.setUTCSeconds(decoded.exp)
    return date
}

function isTokenExpired(token) {
    const date = getTokenExpirationDate(token)
    if (!date) return false
    return !(date.valueOf() > new Date().valueOf())
}

function clearStorage() {
    saveData("user", undefined, true)
    saveData("user_info", undefined)
    saveData("access_token", undefined)
    saveData("token", undefined)
    saveData("impersonatedEmail", undefined)
}

export function loggedIn() {
    const token = loadData("token")
    if (isTokenExpired(token)) {
        clearStorage()
        return false
    }
    return token
}

export const onTokenParsed = () => {
    return dispatch => {
        dispatch({ type: TOKEN_PARSED })
        dispatch(changePath("/", true))
    }
}

function fetchUser(profile, sync = false) {
    return async dispatch => {
        const fetchedUser = await retrieveUser(profile.email, sync)
        if (fetchedUser) {
            dispatch(userReceived(fetchedUser))
            return dispatch(authenticated(true))
        }
        return dispatch(initSignup(profile))
    }
}

export const registeredUser = () => {
    if (!loggedIn()) return false
    return loadData("user", true)
}

export function onRefreshToken(authResult) {
    saveData("access_token", authResult.accessToken)
    saveData("token", authResult.idToken)
}

export function setImpersonationListener(path) {
    return dispatch => {
        const params = qs.parse(path?.substring(1) ?? "")
        const impersonatedEmail = loadData("impersonatedEmail")
        if (impersonatedEmail || params.impersonateNonce) {
            dispatch({ type: IMPERSONATION_ENABLED, enabled: true })
            if (impersonatedEmail) {
                dispatch({
                    type: IMPERSONATION_WARNING,
                    email: impersonatedEmail,
                })
            }
        }

        if (params.impersonateNonce) {
            const state = uuidv4()
            let isSuccessful = false
            const receiveToken = async event => {
                if (
                    !window._env_.REACT_APP_ALLOWED_PARENT_ORIGIN ||
                    event.origin !==
                        window._env_.REACT_APP_ALLOWED_PARENT_ORIGIN
                )
                    return

                if (event.data?.type !== "token") return

                if (event.source !== window.opener) return

                if (event.data?.state !== state) return

                window.removeEventListener("message", receiveToken, false)

                if (
                    !event.data?.payload?.id_token ||
                    !event.data?.payload?.email
                )
                    return

                const idToken = event.data.payload.id_token
                const idTokenData = decode(idToken)

                if (!idTokenData?.impersonatorEmail) return

                clearStorage()
                onRefreshToken({ idToken })
                saveData("impersonatedEmail", event.data.payload.email)
                const profile = { email: event.data.payload.email }
                saveData("user_info", JSON.stringify(profile))
                isSuccessful = true
                dispatch({
                    type: IMPERSONATION_WARNING,
                    email: event.data.payload.email,
                })
                await dispatch(fetchUser(profile, true))
            }
            setTimeout(() => {
                if (!isSuccessful) {
                    dispatch({ type: IMPERSONATION_ENABLED, enabled: false })
                    window.removeEventListener("message", receiveToken, false)
                }
            }, 10000)
            window.addEventListener("message", receiveToken, false)

            window.opener.postMessage(
                {
                    type: "requestToken",
                    payload: { state, nonce: params.impersonateNonce },
                },
                window._env_.REACT_APP_ALLOWED_PARENT_ORIGIN
            )
        }
    }
}

export function onAuthenticated(authResult, ldClient) {
    return async dispatch => {
        try {
            onRefreshToken(authResult)
            const profile = get(authResult, "idTokenPayload")
            const provider = capitalize(profile.sub.split(/\||-/)[0])
            try {
                if (["Google", "Facebook"].includes(provider)) {
                    dispatch(trackGAEvent("System", "Social Login", provider))
                } else {
                    dispatch(trackGAEvent("System", "Login", provider))
                }
                if (ldClient) {
                    ldClient.identify({
                        key: profile?.email,
                        email: profile?.email,
                    })
                }
            } catch (error) {
                dispatch(errorMessage(error))
            }
            dispatch(authenticationFinished())
            saveData("user_info", JSON.stringify(profile))
            await dispatch(fetchUser(profile, true))
        } catch (error) {
            dispatch(authorizationError(error))
        }
    }
}

export function changeLock(language) {
    let newLock
    let adjustedLanguage
    if (language === "fr-ca") {
        adjustedLanguage = "fr"
    } else if (language === "es-mx") {
        adjustedLanguage = "es"
    } else if (language === "en-us") {
        adjustedLanguage = "en"
    }
    const options = {
        closable: false,
        theme: {
            logo: "static/LTLselect_logo-icon-only.png",
            primaryColor: "#f60",
        },
        languageDictionary: {
            title: "LTL Select",
        },
        autoFocus: false,
        auth: { autoParseHash: false },
        container: `auth0-lock-container-${adjustedLanguage}`,
        configurationBaseUrl: "https://cdn.auth0.com",
    }
    if (language) {
        options.language = adjustedLanguage.toString()
    }
    newLock = new Auth0Lock(
        window._env_.REACT_APP_AUTH0_CLIENT_ID,
        window._env_.REACT_APP_AUTH0_DOMAIN,
        options
    )
    return newLock
}

export const isParseParam = (path = "") =>
    path.includes("access_token") && path.includes("id_token")

const createUncaughtErrorHandler = () => {
    window.onerror = (errorMsg, source, lineno, colno, error) => {
        const description = [
            moment.utc().format(),
            get(error, "stack", "").toString(),
        ].join(" ")
        ReactGA.event("Error", description)
        return false
    }
}

export function initUserRecord(ldClient) {
    return async (dispatch, getState) => {
        const profileString = loadData("user_info")
        const profile = profileString && JSON.parse(profileString)

        if (getState().user.profile) return
        const user = registeredUser()
        if (user) {
            dispatch(userReceived(user))
            dispatch(authenticated())
        } else {
            if (profile) await dispatch(fetchUser(profile))
        }
        if (ldClient) {
            ldClient.identify({
                key: profile?.email,
                email: profile?.email,
            })
        }
    }
}

export function initApp(router, path) {
    return dispatch => {
        createUncaughtErrorHandler()
        dispatch(setImpersonationListener(path))
        dispatch(initializationFinished())
    }
}

export function logOut() {
    return dispatch => {
        clearStorage()
        dispatch(disauthenticated())
    }
}

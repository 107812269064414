import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Field } from "redux-form"
import { Typography, Grid, withStyles, Paper } from "@material-ui/core"
import { updateMarkup } from "../../../../../actions/quote"
import FormField from "../../../../form/form-field"
import FormCheckbox from "../../../../form/form-checkbox"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    form__container: {
        padding: theme.spacing(2),
    },
    checkbox: {
        marginLeft: "25px",
    },
    markup__container: {
        // maxWidth: "120px",
    },
})

const MarkupPresentation = ({
    markupAmount,
    onChange,
    markupType,
    onMarkupBlur,
    classes,
    currencyCode,
    gaCategory,
}) => (
    <Paper className={classes.form__container}>
        <Grid item container xs={12} alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h5">
                    <FormattedMessage
                        id="rateResults.share__upcharge"
                        defaultMessage="Upcharge"
                    />
                </Typography>
            </Grid>
            <Grid item xs={4} className={classes.input__container}>
                <Field
                    name="markupAmount"
                    label=""
                    type="number"
                    inputProps={{ min: 0 }}
                    component={FormField}
                    category={gaCategory}
                />
            </Grid>
            <Grid
                item
                container
                xs={6}
                className={classes.markup__container}
                alignItems="center"
            >
                <Grid
                    item
                    container
                    xs={4}
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Typography>{currencyCode}</Typography>
                </Grid>
                <Grid item container xs={4} justifyContent="center">
                    <Field
                        className={classes.checkbox}
                        name="markupType"
                        component={FormCheckbox}
                        category={gaCategory}
                        enableSwitch
                    />
                </Grid>
                <Grid
                    item
                    container
                    xs={4}
                    alignItems="center"
                    justifyContent="flex-start"
                >
                    <Typography>%</Typography>
                </Grid>
            </Grid>
        </Grid>
    </Paper>
)

MarkupPresentation.propTypes = {
    markupAmount: PropTypes.number,
    markupType: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onMarkupBlur: PropTypes.func.isRequired,
    gaCategory: PropTypes.string,
}

MarkupPresentation.defaultProps = {
    markupAmount: 0,
    markupType: "markupAmount",
}

const mapDispatchToProps = dispatch => ({
    onMarkupBlur: () => dispatch(updateMarkup()),
})

export const Markup = connect(
    undefined,
    mapDispatchToProps
)(withStyles(styles)(MarkupPresentation))

import React, { Fragment, useEffect, useState } from "react"
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Typography,
    withStyles,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import PricingTable from "../PricingTable"
import AlaskaPricingModal from "./AlaskaPricingModal"
import { getAlaskaPricing } from "../../../../../actions/offshoreRating"
import PricingTableSimple from "../PricingTableSimple"
import { styles } from "../styles"
import NavigationDropdown from "../NavigationDropdown"

const AlaskaPricingTile = ({
    classes,
    getAlaskaPricing,
    defaultPricing,
    customPricing,
    location,
    trackGA,
}) => {
    const [activeFilter, setActiveFilter] = useState("oceanCharges")
    const [editPricingOpen, setEditPricingOpen] = useState(false)
    const [availableFreightClasses, setAvailableFreightClasses] = useState([
        50,
        55,
        60,
        65,
        70,
        77.5,
        85,
        92.5,
        100,
        110,
        125,
        150,
        175,
        200,
        250,
        300,
        400,
        500,
    ])
    useEffect(() => {
        getAlaskaPricing()
    }, [])

    return (
        <Fragment>
            <AlaskaPricingModal
                classes={classes}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
                open={editPricingOpen}
                setOpen={setEditPricingOpen}
                defaultPricing={defaultPricing}
                customPricing={customPricing}
                location={location}
                availableFreightClasses={availableFreightClasses}
                trackGA={trackGA}
            />
            <Card className={classes.card}>
                <CardHeader
                    classes={{ action: classes.card__action }}
                    title={
                        <Typography variant="h6">
                            <FormattedMessage
                                id="locations.alaskaPricing"
                                defaultMessage="Alaska Pricing"
                            />
                        </Typography>
                    }
                    action={
                        <Button
                            color="primary"
                            id="puertoRicoCard__editRates"
                            onClick={() => {
                                setEditPricingOpen(true)
                                trackGA(
                                    "Alaska",
                                    "Opened Alaska Pricing",
                                    "Edit Pricing"
                                )
                            }}
                        >
                            <FormattedMessage
                                id="locations.puertoRicoCard__editPricing"
                                defaultMessage="Edit Pricing"
                            />
                        </Button>
                    }
                />
                <CardContent classes={{ root: classes.card__content }}>
                    <NavigationDropdown
                        activeFilter={activeFilter}
                        setActiveFilter={setActiveFilter}
                        classes={classes}
                        offshoreState={"AK"}
                    />
                    {activeFilter === "oceanCharges" ? (
                        <PricingTable
                            open={editPricingOpen}
                            classes={classes}
                            titleColumn={"freightClass"}
                            defaultPricing={defaultPricing?.oceanCharges}
                            customPricing={
                                location?.customAlaskaPricing?.oceanCharges
                            }
                            availableFreightClasses={availableFreightClasses}
                            columns={[
                                { title: "Class", value: "freightClass" },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.originCFS"
                                            defaultMessage="Origin CFS"
                                        />
                                    ),
                                    value: "originCFS",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.destinationCFS"
                                            defaultMessage="Destination CFS"
                                        />
                                    ),
                                    value: "destinationCFS",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.ratePerCwt"
                                            defaultMessage="Rate per CWT"
                                        />
                                    ),
                                    value: "ratePerCwt",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.grossDiscount"
                                            defaultMessage="Gross Discount"
                                        />
                                    ),
                                    value: "oceanGrossDiscount",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.minimumChargeWithLimit"
                                            defaultMessage="Minimum Charge {amount}"
                                            values={{ amount: "(0-150lbs)" }}
                                        />
                                    ),
                                    value: "lowMinimumCharge",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.minimumChargeWithLimit"
                                            defaultMessage="Minimum Charge {amount}"
                                            values={{ amount: "(150+lbs)" }}
                                        />
                                    ),
                                    value: "highMinimumCharge",
                                },
                            ]}
                        />
                    ) : null}
                    {activeFilter === "bolFee" ? (
                        <PricingTableSimple
                            classes={classes}
                            defaultPricing={{ bolFee: defaultPricing?.bolFee }}
                            customPricing={{ bolFee: customPricing?.bolFee }}
                            rowValues={[
                                {
                                    title: (
                                        <FormattedMessage
                                            id="domesticOffShore.filters.BillOfLadingFee"
                                            defaultMessage="Bill of Lading Fee"
                                        />
                                    ),
                                    value: "bolFee",
                                },
                            ]}
                        />
                    ) : null}
                    {activeFilter === "hazardousMaterialsFee" ? (
                        <PricingTable
                            open={editPricingOpen}
                            classes={classes}
                            defaultPricing={
                                defaultPricing?.hazardousMaterialsFees
                            }
                            customPricing={
                                customPricing?.hazardousMaterialsFees
                            }
                            titleColumn={"zoneRangeStart"}
                            secondaryTitleColumn={"zoneRangeEnd"}
                            columns={[
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.zoneRangeStart"
                                            defaultMessage="Zone Range Start"
                                        />
                                    ),
                                    value: "zoneRangeStart",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.zoneRangeEnd"
                                            defaultMessage="Zone Range End"
                                        />
                                    ),
                                    value: "zoneRangeEnd",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.grossDiscount"
                                            defaultMessage="Gross Discount"
                                        />
                                    ),
                                    value: "grossDiscount",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.ratePerShipment"
                                            defaultMessage="Rate per Shipment"
                                        />
                                    ),
                                    value: "hazmatCharge",
                                },
                            ]}
                        />
                    ) : null}
                    {activeFilter === "protectFromFreezing" ? (
                        <PricingTable
                            open={editPricingOpen}
                            classes={classes}
                            defaultPricing={defaultPricing?.protectFromFreezing}
                            customPricing={customPricing?.protectFromFreezing}
                            titleColumn={"zoneRangeStart"}
                            secondaryTitleColumn={"zoneRangeEnd"}
                            columns={[
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.zoneRangeStart"
                                            defaultMessage="Zone Range Start"
                                        />
                                    ),
                                    value: "zoneRangeStart",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.zoneRangeEnd"
                                            defaultMessage="Zone Range End"
                                        />
                                    ),
                                    value: "zoneRangeEnd",
                                },

                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.grossDiscount"
                                            defaultMessage="Gross Discount"
                                        />
                                    ),
                                    value: "grossDiscount",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.minimumCharge"
                                            defaultMessage="Minimum Charge"
                                        />
                                    ),
                                    value: "minimumValue",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.ratePerCwt"
                                            defaultMessage="Rate per CWT"
                                        />
                                    ),
                                    value: "rate",
                                },
                            ]}
                        />
                    ) : null}
                    {activeFilter === "absoluteMinimumCharge" ? (
                        <PricingTableSimple
                            classes={classes}
                            defaultPricing={
                                defaultPricing?.absoluteMinimumCharge
                            }
                            customPricing={customPricing?.absoluteMinimumCharge}
                            rowValues={[
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.absoluteMinimumCharge"
                                            defaultMessage="Custom Absolute Minimum Charge"
                                        />
                                    ),
                                    value: "charge",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.grossDiscount"
                                            defaultMessage="Gross Discount"
                                        />
                                    ),
                                    value: "grossPercentageDiscount",
                                },
                            ]}
                        />
                    ) : null}
                </CardContent>
            </Card>
        </Fragment>
    )
}

const mapStateToProps = (state, props) => {
    const defaultPricing = state?.offshoreRating?.alaskaPricing ?? {}
    const customPricing =
        state?.form?.location?.values?.customAlaskaPricing ?? {}
    return {
        defaultPricing,
        customPricing,
    }
}

const mapDispatchToProps = dispatch => ({
    getAlaskaPricing: state => dispatch(getAlaskaPricing(state)),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(AlaskaPricingTile)
)

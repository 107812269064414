import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"
import { destroy, Field, FormSection, formValues } from "redux-form"
import CommercialInvoiceForm from "./CommercialInvoiceForm"
import CertificateOfOriginBOLForm from "../documents/certificateOfOrigin/CertificateOfOriginBOLForm"
import FormSwitch from "../form/form-switch"
import { useCustomsProfileContext } from "../../context/providers/CustomsProfileProvider"
import { CustomsDocumentList } from "../locations/components/customsProfile/CustomsDocumentsCard"
import { Button, Chip, Dialog, Divider } from "@material-ui/core"
import { AddDocumentWizard } from "../documents/upload/AddDocumentWizard"

const styles = theme => ({
    card: {
        height: "100%",
    },
    content: {
        marginLeft: "60px",
    },
    scrolling__list: {
        maxHeight: "333px",
        overflowY: "auto",
    },
    select__container: {
        margin: "0 0 10px 0",
        width: "100%",
    },
    chip: {
        margin: "16px 8px",
    },
    chipTitle: {
        marginLeft: "16px",
    },
    divider: {
        margin: "16px 0",
    },
})

export const formattedPriorityDocuments = [
    {
        value: "none",
        label: (
            <FormattedMessage
                id="orderDetails.documents__None"
                defaultMessage="None"
            />
        ),
    },
    {
        value: "invoice",
        label: (
            <FormattedMessage
                id="orderDetails.documents__Invoice"
                defaultMessage="Commercial Invoice"
            />
        ),
    },
    {
        value: "origin",
        label: (
            <FormattedMessage
                id="orderDetails.documents__Origin"
                defaultMessage="Certificate Of Origin"
            />
        ),
    },
    {
        value: "nafta",
        label: (
            <FormattedMessage
                id="orderDetails.documents__NAFTA"
                defaultMessage="NAFTA Agreement"
            />
        ),
    },
    {
        value: "all",
        label: (
            <FormattedMessage
                id="orderDetails.documents__All"
                defaultMessage="All Trade Documentation"
            />
        ),
    },
]

export const formattedPurposeOfShipment = [
    {
        value: "SOLD",
        label: (
            <FormattedMessage
                id="orderDetails.documents__Sold"
                defaultMessage="Sold"
            />
        ),
    },
    {
        value: "GIFT",
        label: (
            <FormattedMessage
                id="orderDetails.documents__Gift"
                defaultMessage="Gift"
            />
        ),
    },
    {
        value: "NOT_SOLD",
        label: (
            <FormattedMessage
                id="orderDetails.documents__Not__Sold"
                defaultMessage="Not Sold"
            />
        ),
    },
    {
        value: "PERSONAL_EFFECTS",
        label: (
            <FormattedMessage
                id="orderDetails.documents__Personal__Effects"
                defaultMessage="Personal Effects"
            />
        ),
    },
    {
        value: "REPAIR_AND_RETURN",
        label: (
            <FormattedMessage
                id="orderDetails.documents__Repair__And__Return"
                defaultMessage="Repair And Return"
            />
        ),
    },
]

export const formattedTaxIdentificationType = [
    {
        value: "BUSINESS_NATIONAL",
        label: (
            <FormattedMessage
                id="orderDetails.documents__BUSINESS__NATIONAL"
                defaultMessage="BUSINESS NATIONAL"
            />
        ),
    },
    {
        value: "BUSINESS_STATE",
        label: (
            <FormattedMessage
                id="orderDetails.documents__BUSINESS__STATE"
                defaultMessage="BUSINESS STATE"
            />
        ),
    },
    {
        value: "BUSINESS_UNION",
        label: (
            <FormattedMessage
                id="orderDetails.documents__BUSINESS__UNION"
                defaultMessage="BUSINESS UNION"
            />
        ),
    },
    {
        value: "PERSONAL_NATIONAL",
        label: (
            <FormattedMessage
                id="orderDetails.documents__PERSONAL__NATIONAL"
                defaultMessage="PERSONAL NATIONAL"
            />
        ),
    },
    {
        value: "PERSONAL_STATE",
        label: (
            <FormattedMessage
                id="orderDetails.documents__PERSONAL_STATE"
                defaultMessage="PERSONAL STATE"
            />
        ),
    },
]

export const formattedItemName = item => {
    const maxLabelSize = 20
    return item?.description?.length > maxLabelSize
        ? `${item?.description?.substring(0, maxLabelSize)}...`
        : item?.description
}

const SupportDocumentationForm = formValues({
    commercialInvoice: "requiresCommercialInvoice",
    certificateOfOrigin: "requiresCertificateOfOrigin",
    selectedCustomsDocuments: "selectedCustomsDocuments",
})(
    ({
        classes,
        commercialInvoice,
        certificateOfOrigin,
        changeField,
        locationId,
        shipmentId,
        selectedCustomsDocuments,
    }) => {
        const [documentModalOpen, setDocumentModalOpen] = useState(false)
        const [newDocuments, setNewDocuments] = useState([])
        const [linkedDocuments, setLinkedDocuments] = useState([])
        const { documentList, setLocationId } = useCustomsProfileContext()

        useEffect(() => {
            setLocationId(locationId)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [locationId])

        const onLinkToShipment = (document, isNew = false) => {
            if (
                !selectedCustomsDocuments ||
                selectedCustomsDocuments.length === 0
            ) {
                changeField("deferredTradeDocuments", true)
            }
            changeField("selectedCustomsDocuments", [
                ...(selectedCustomsDocuments ?? []),
                document,
            ])
            if (isNew) {
                setNewDocuments(prev => [...prev, document])
            } else {
                setLinkedDocuments(prev => [...prev, document])
            }
        }

        const onUnlinkFromShipment = (document, isNew = false) => {
            const newSelected = selectedCustomsDocuments?.filter(
                x =>
                    !(
                        x.documentCategory === document.documentCategory &&
                        x.customFilename === document.customFilename
                    )
            )
            if (newSelected.length === 0) {
                changeField("deferredTradeDocuments", false)
            }
            changeField("selectedCustomsDocuments", newSelected)
            const localDocuments = (isNew
                ? newDocuments
                : linkedDocuments
            )?.filter(
                x =>
                    !(
                        x.documentCategory === document.documentCategory &&
                        x.customFilename === document.customFilename
                    )
            )
            if (isNew) {
                setNewDocuments(localDocuments)
            } else {
                setLinkedDocuments(localDocuments)
            }
        }

        const onCreateNewDocument = values => {
            const document = {
                documentCategory: values?.documentCategory,
                shipmentOnly: values?.shipmentOnly,
                customFilename: values?.customFilename,
                locationId,
                ...(values?.shipmentOnly && {
                    internalTrackingNumber: shipmentId,
                }),
                ...(values?.documentCategory === "USMCA" && values?.usmca),
            }
            onLinkToShipment(document, true)
            setDocumentModalOpen(false)
        }

        const onCloseDialog = () => {
            setDocumentModalOpen(false)
            destroy("newCustomsDocument")
        }

        return (
            <Fragment>
                <Dialog
                    open={documentModalOpen}
                    maxWidth="lg"
                    onSubmit={e => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                >
                    <AddDocumentWizard
                        initialStep="generateDocument"
                        onGenerate={onCreateNewDocument}
                        onClose={onCloseDialog}
                        locationId={locationId}
                        shipmentId={shipmentId}
                        enableProfileOverwrite={false}
                        enableShipmentOverwrite={false}
                        customShipmentList={[
                            ...newDocuments,
                            ...linkedDocuments,
                        ]}
                    />
                </Dialog>
                <Grid xs={12} item>
                    <Card className={classes.card}>
                        <CardHeader
                            className={classes.header}
                            title={
                                <Typography variant="h5">
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__title"
                                        defaultMessage="Supporting Documentation"
                                    />
                                </Typography>
                            }
                            action={
                                <Button
                                    color="primary"
                                    onClick={() => setDocumentModalOpen(true)}
                                >
                                    <FormattedMessage
                                        id="orderDetails.addNewDocument"
                                        defaultMessage="Add New Document"
                                    />
                                </Button>
                            }
                        />
                        <CardContent className={classes.content}>
                            {(documentList?.length > 0 ||
                                newDocuments?.length > 0) && (
                                <Fragment>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="subtitle1"
                                                gutterBottom
                                            >
                                                <FormattedMessage
                                                    id="documents.linkDocuments"
                                                    defaultMessage="Link document"
                                                />
                                            </Typography>
                                        </Grid>
                                        {documentList?.length > 0 && (
                                            <Grid
                                                item
                                                container
                                                xs={4}
                                                className={
                                                    classes.scrolling__list
                                                }
                                            >
                                                <Grid item xs={12}>
                                                    <CustomsDocumentList
                                                        attachable
                                                        customsDocuments={
                                                            documentList
                                                        }
                                                        attachedDocuments={
                                                            selectedCustomsDocuments ??
                                                            []
                                                        }
                                                        locationId={locationId}
                                                        onLinkToShipment={
                                                            onLinkToShipment
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid
                                            item
                                            container
                                            xs={documentList?.length ? 8 : 12}
                                            alignContent="flex-start"
                                        >
                                            {newDocuments?.length > 0 && (
                                                <Fragment>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className={
                                                            classes.chipTitle
                                                        }
                                                    >
                                                        <Typography variant="body2">
                                                            <FormattedMessage
                                                                id="documents.newDocuments"
                                                                defaultMessage="New documents"
                                                            />
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        container
                                                        xs={12}
                                                    >
                                                        {newDocuments?.map(
                                                            (
                                                                selectedDocument,
                                                                idx
                                                            ) => (
                                                                <Chip
                                                                    key={idx}
                                                                    color="primary"
                                                                    className={
                                                                        classes.chip
                                                                    }
                                                                    label={`${selectedDocument?.documentCategory} - ${selectedDocument?.customFilename}`}
                                                                    onDelete={() =>
                                                                        onUnlinkFromShipment(
                                                                            selectedDocument,
                                                                            true
                                                                        )
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </Grid>
                                                </Fragment>
                                            )}

                                            {linkedDocuments?.length > 0 && (
                                                <Fragment>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className={
                                                            classes.chipTitle
                                                        }
                                                    >
                                                        <Typography variant="body2">
                                                            <FormattedMessage
                                                                id="documents.linkedDocuments"
                                                                defaultMessage="Linked documents"
                                                            />
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        container
                                                        xs={12}
                                                    >
                                                        {linkedDocuments?.map(
                                                            (
                                                                selectedDocument,
                                                                idx
                                                            ) => (
                                                                <Chip
                                                                    key={idx}
                                                                    color="primary"
                                                                    className={
                                                                        classes.chip
                                                                    }
                                                                    label={`${selectedDocument?.documentCategory} - ${selectedDocument?.customFilename}`}
                                                                    onDelete={() =>
                                                                        onUnlinkFromShipment(
                                                                            selectedDocument,
                                                                            false
                                                                        )
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </Grid>
                                                </Fragment>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Divider
                                        variant="middle"
                                        className={classes.divider}
                                    />
                                </Fragment>
                            )}
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        <FormattedMessage
                                            id="documents.generateNewDocument"
                                            defaultMessage="Generate new document"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="caption">
                                        <FormattedMessage
                                            id="documents.tradeDocumentationInfoPreBook"
                                            defaultMessage="Trade documentation will be sent to Customs on your behalf as a single document after a signature & notary stamp have been applied. FedEx requires trade documentation to be signed. You can add your signature by uploading it directly or by printing the trade documents first, signing them manually, and then uploading the signed trade documents to LTL Select. Your documents will automatically be shared with FedEx."
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item container xs={12} md={3}>
                                    <Field
                                        component={FormSwitch}
                                        name="requiresCommercialInvoice"
                                        label={
                                            <Typography variant="subtitle1">
                                                <FormattedMessage
                                                    id="orderDetails.commercial__invoice__title"
                                                    defaultMessage="Commercial Invoice"
                                                />
                                            </Typography>
                                        }
                                    />
                                </Grid>
                                <Grid item container xs={12} md={3}>
                                    <Field
                                        component={FormSwitch}
                                        name="requiresCertificateOfOrigin"
                                        label={
                                            <Typography variant="subtitle1">
                                                <FormattedMessage
                                                    id="orderDetails.certificate__of__origin__title"
                                                    defaultMessage="Certificate Of Origin"
                                                />
                                            </Typography>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                {commercialInvoice && <CommercialInvoiceForm />}
                {certificateOfOrigin && (
                    <FormSection name="certificateOfOrigin">
                        <CertificateOfOriginBOLForm />
                    </FormSection>
                )}
            </Fragment>
        )
    }
)

SupportDocumentationForm.propTypes = {
    classes: PropTypes.object,
}

SupportDocumentationForm.defaultProps = {
    classes: {},
}

export default withStyles(styles)(SupportDocumentationForm)

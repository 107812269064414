import { IconButton, Tooltip } from "@material-ui/core"
import { Info } from "@material-ui/icons"
import React from "react"

const InfoTooltip = ({ text }) => {
    return (
        <Tooltip fontSize="small" title={text}>
            <IconButton fontSize="small">
                <Info />
            </IconButton>
        </Tooltip>
    )
}

export default InfoTooltip

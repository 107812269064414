import React from "react"
import { Grid, Paper, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
    paper: {
        width: "100%",
        display: "flex",
        padding: "15px",
        flexWrap: "wrap",
    },
})

export default function BookAuxiliarySquare({ msg }) {
    const classes = useStyles()

    return (
        <Paper className={classes.paper}>
            <Grid item container alignContent="flex-start">
                <Typography variant="subtitle2">{msg} </Typography>
            </Grid>
        </Paper>
    )
}

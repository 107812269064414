import { TextField, Grid, IconButton } from "@material-ui/core"
import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { Remove, Add } from "@material-ui/icons"

const styles = theme => ({
    text_field: {
        width: "50px",
    },
    inc_btn: {
        height: "20px",
        padding: "2px",
        maxWidth: "20px",
    },
    left_btn: {
        marginRight: "-15px",
        height: "20px",
        padding: "2px",
        maxWidth: "20px",
        zIndex: 1000,
    },
    right_btn: {
        marginLeft: "-15px",
        height: "20px",
        padding: "2px",
        maxWidth: "20px",
        zIndex: 1000,
    },
    btn_group: {
        display: "flex",
        flexDirection: "column",
    },
    main: {
        display: "flex",
        margin: " 0 -3px",
        padding: "0 5px",
        alignItems: "center",
        justifyContent: "center",
    },
    hidden_left_btn: {
        visibility: "hidden",
        marginRight: "-15px",
        height: "20px",
        padding: "2px",
        maxWidth: "20px",
    },
    hidden_right_btn: {
        visibility: "hidden",
        marginLeft: "-15px",
        height: "20px",
        padding: "2px",
        maxWidth: "20px",
    },
})

export const NumberIncrementField = ({
    classes,
    disabled,
    value,
    min,
    max,
    onChange,
}) => {
    return (
        <Grid className={classes.main}>
            <IconButton
                className={
                    disabled ? classes.hidden_left_btn : classes.left_btn
                }
                variant="outlined"
                color="primary"
                disabled={value <= min}
                onClick={() => onChange(value - 1)}
            >
                <Remove fontSize="small" />
            </IconButton>
            <TextField
                className={classes.text_field}
                disabled={disabled}
                value={value}
                inputProps={{
                    style: {
                        textAlign: "center",
                    },
                }}
            />
            <IconButton
                className={
                    disabled ? classes.hidden_right_btn : classes.right_btn
                }
                variant="outlined"
                disabled={value >= max}
                color="primary"
                onClick={() => onChange(++value)}
            >
                <Add fontSize="small" />
            </IconButton>
        </Grid>
    )
}

export default withStyles(styles)(NumberIncrementField)

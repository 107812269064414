import moment from "moment"
import { get } from "lodash"
import { isGuaranteedQuote } from "../components/quote/card"

export const internalGroupBy = quotes =>
    quotes.reduce((buckets, rate, i) => {
        const delivery = moment(rate.deliveryDateTime)
        const date = delivery.format("YYYY-MM-DD")
        buckets[date] = buckets[date] || []
        // NOTE: Project44 represents end of day as 00:00:00
        if (delivery.format("HHmmss") === "000000") {
            if (rate.guaranteed) delivery.set(rate.guaranteed.time)
            if (delivery.format("HHmmss") === "000000") {
                delivery.set({ hour: 23, minute: 59 })
            }
        }
        buckets[date].push({ ...rate, deliveryDateTime: delivery })
        return buckets
    }, {})

export const groupByDate = (quotes, guaranteed) =>
    quotes.rateQuotes.reduce((prev, quote) => {
        const {
            alternateRateQuotes = [],
            capacityProviderAccountGroup,
            carrierCode,
            ...tempQuote
        } = quote
        const list = [{ carrierCode, ...tempQuote }]
            .concat(
                alternateRateQuotes.map((rate, i) => ({
                    ...rate,
                    index: i,
                })) || []
            )
            .map(item => ({
                ...item,
                guaranteed: isGuaranteedQuote(
                    item.serviceLevel && item.serviceLevel.code,
                    guaranteed
                ),
            }))
        const buckets = internalGroupBy(list)

        for (const dateBucket of Object.keys(buckets)) {
            prev[dateBucket] = prev[dateBucket] || []
            const sortedBucket = buckets[dateBucket].sort((a, b) => {
                if (
                    (b.guaranteed && a.guaranteed) ||
                    (!b.guaranteed && !a.guaranteed)
                ) {
                    return a.deliveryDateTime.isAfter(b.deliveryDateTime)
                        ? 1
                        : -1
                }
                return b.guaranteed ? 1 : -1
            })
            const prices = sortedBucket.map(item =>
                Number(get(item, "rateQuoteDetail.total", 0))
            )
            const minPrice = Math.min(...prices)
            const minDate = sortedBucket.reduce(
                (min, item) =>
                    item.deliveryDateTime.isBefore(min)
                        ? item.deliveryDateTime
                        : min,
                sortedBucket[0].deliveryDateTime
            )
            const code =
                carrierCode || capacityProviderAccountGroup.accounts[0].code
            prev[dateBucket].push({
                carrierCode: code,
                minPrice,
                minDate,
                rateQuotes: sortedBucket,
                groupDate: dateBucket,
            })
        }
        return prev
    }, {})

// Steps:
//   1. Move rate quotes of a provider to daily buckets
//   2. Sort offers first by guaranteed/estimated, then by date
//   3. Sort the combined buckets first by date, then by provider code
export const groupQuotes = (quotes, guaranteedList) => {
    if (!quotes || !quotes.rateQuotes || !guaranteedList) return []
    const groupedQuotes = groupByDate(quotes, guaranteedList)
    const list = []
    for (const date of Object.keys(groupedQuotes).sort()) {
        list.push(
            ...groupedQuotes[date].sort((a, b) => {
                if (a.minDate.isSame(b.minDate)) {
                    return a.minPrice > b.minPrice ? 1 : -1
                }
                return a.minDate.isAfter(b.minDate) ? 1 : -1
            })
        )
    }
    if (list.length) list[0].fastest = true
    return list
}

/*
function isDomesticOffshore(zipCode) {
    if (!zipCode) return false;
    zipCode = `${zipCode}`;
    if (zipCode.length !== 5) return false; // Non-USA
    if (zipCode.startsWith('99')) return true; // Alaska
    if (zipCode.startsWith('96')) return true; // Hawaii
    if (zipCode.startsWith('00')) return true; // Puerto Rico and US Virgin Islands
    return false;
}
*/

export function transformRates(state, quotes) {
    const result = get(quotes, "result")
    /* const destination = get(quotes, 'search.destination'); */
    const guaranteed = get(state, "accessorials.list.guaranteed", [])
    const list = groupQuotes(result, guaranteed)
    /* if (isDomesticOffshore(destination)) {
        list.unshift({ fedexDomesticOffshore: true });
    } */
    return list
}

export function groupRatesByCarrier(rates) {
    const groupedByCarrier = {}
    rates.forEach(quote => {
        const key = ["FXFE", "FXNL"].includes(quote.carrierCode)
            ? "FEDEX"
            : quote.carrierCode
        if (groupedByCarrier[key]) groupedByCarrier[key].push(quote)
        else groupedByCarrier[key] = [quote]
    })
    return Object.values(groupedByCarrier).reduce(
        (prev, curr) => prev.concat(...curr),
        []
    )
}

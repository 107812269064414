import { makeStyles } from "@material-ui/styles"

export const useStyles = makeStyles({
    baseContainer: {
        paddingTop: "72px",
        paddingBottom: "142px",
    },
    reviewContainer: {
        padding: "0px 5% 72px 5%",
    },
    stepperContainer: {
        position: "fixed",
        top: 64,
        zIndex: "1000",
    },
    workflowContainer: {
        padding: "80px 10% 25px 10%",
        maxWidth: "1500px",
    },
    input__field: {
        cursor: "pointer",
    },
    textInput__field: {
        cursor: "text",
    },
    stepContainer: {
        paddingBottom: "45px",
    },
    containerForm: {
        display: "flex",
        width: "100%",
    },
})

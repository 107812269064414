import {
    RATE_LOAD,
    INVALID_ACTIVE_RATE,
} from "../actions/book-shipment-requests"
import { SET_IS_BOOKING_QUICK_RATE } from "../actions/redesign"

export function redesign(
    state = {
        isBookingQuickRate: false,
        activeRates: {},
    },
    action = {}
) {
    switch (action.type) {
        case SET_IS_BOOKING_QUICK_RATE:
            return {
                ...state,
                isBookingQuickRate: action.value,
            }
        case RATE_LOAD:
            return {
                ...state,
                activeRates: {
                    ...state.activeRates,
                    [`${action?.payload?.identifiers?.internalTrackingNumber}`]: action.payload,
                },
            }
        case INVALID_ACTIVE_RATE:
            return {
                ...state,
                isBookingQuickRate: false,
                activeRates: {},
            }
        default:
            return state
    }
}

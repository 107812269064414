import React, { useState } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import {
    FormControl,
    Input,
    InputLabel,
    InputAdornment,
    FormHelperText,
} from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormattedError from "./formatted-error"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const styles = theme => ({
    form__container: {
        padding: "3px",
        width: "100%",
    },
    form__control: {
        width: "100%",
    },
})

const FormField = ({
    input,
    label,
    type,
    category,
    autoComplete,
    meta: { touched, error, asyncValidating },
    placeholder,
    maxLength,
    classes,
    InputProps,
    InputLabelProps,
    InputHelperProps,
    helperText,
    required,
    datatestid,
    disableBrowserAutocomplete,
    inputRef,
    helperTextAlwaysShow,
    disabled,
    ...rest
}) => {
    const { logGAEvent } = useGAContext()

    const [initialValue, setInitialValue] = useState("")
    return (
        <Grid
            item
            container
            className={`${classnames({
                "form-field": true,
                "form-field-touched": touched,
                "form-field-invalid": touched && error,
                "form-field-valid": !error,
            })} ${classes.form__container}`}
        >
            <Grid item container direction="column">
                <FormControl
                    error={!!(touched && error)}
                    className={classes.form__control}
                    onFocus={() => {
                        setInitialValue(input?.value)
                    }}
                    onBlur={() => {
                        if (initialValue === "") {
                            logGAEvent(
                                category ?? "Unknown",
                                "Add Field Text",
                                label?.props?.defaultMessage ?? input.name
                            )
                        } else if (input?.value !== initialValue) {
                            logGAEvent(
                                category ?? "Unknown",
                                "Edit Field Text",
                                label?.props?.defaultMessage ?? input.name
                            )
                        }
                    }}
                >
                    <InputLabel
                        htmlFor={input.name}
                        {...InputLabelProps}
                        required={required}
                        id={`${input.name}-label`}
                    >
                        {label}
                    </InputLabel>
                    <Input
                        placeholder={placeholder}
                        id={input.name}
                        type={type}
                        disabled={disabled}
                        inputProps={{
                            datatestid,
                            autoComplete: disableBrowserAutocomplete
                                ? "off"
                                : null,
                        }}
                        margin="none"
                        inputRef={inputRef}
                        autoComplete={autoComplete}
                        maxLength={maxLength || undefined}
                        endAdornment={
                            asyncValidating && (
                                <InputAdornment position="end">
                                    <CircularProgress
                                        size={24}
                                        color="secondary"
                                    />
                                </InputAdornment>
                            )
                        }
                        {...InputProps}
                        {...input}
                        {...rest}
                    />
                    {helperText && helperTextAlwaysShow ? (
                        <FormHelperText {...InputHelperProps}>
                            {helperText}
                        </FormHelperText>
                    ) : null}
                    {helperText && touched && !helperTextAlwaysShow ? (
                        <FormHelperText {...InputHelperProps}>
                            {helperText}
                        </FormHelperText>
                    ) : null}
                    {touched && error ? (
                        <FormHelperText {...InputHelperProps}>
                            <FormattedError error={error} />
                        </FormHelperText>
                    ) : null}
                </FormControl>
            </Grid>
        </Grid>
    )
}

FormField.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    type: PropTypes.oneOf([
        "text",
        "email",
        "file",
        "number",
        "tel",
        "checkbox",
        "password",
        "date",
    ]),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    autoComplete: PropTypes.string,
    meta: PropTypes.shape({
        // eslint-disable-next-line react/no-unused-prop-types
        touched: PropTypes.bool,
        // eslint-disable-next-line react/no-unused-prop-types
        error: PropTypes.object,
    }),
    classes: PropTypes.object,
    helperText: PropTypes.string,
    InputProps: PropTypes.object,
    InputLabelProps: PropTypes.object,
    InputHelperProps: PropTypes.object,
    required: PropTypes.bool,
    category: PropTypes.string,
}

FormField.defaultProps = {
    meta: { error: undefined },
    autoComplete: undefined,
    maxLength: undefined,
    placeholder: undefined,
    label: undefined,
    type: undefined,
    input: undefined,
    classes: {},
    helperText: undefined,
    required: false,
    category: undefined,
}

export default withStyles(styles)(FormField)

export const styles = {
    conactLabel: {
        fontSize: "12px",
        color: "#757575",
        marginBottom: "1px",
    },
    button__container: {
        marginBottom: "20px",
    },
    button__close: {
        marginLeft: "auto",
    },
    content: {
        padding: "24px",
        borderRadius: "6px",
        backgroundColor: "#fff",
        overflowY: "scroll",
    },
    header: {
        paddingBottom: "24px",
    },
    error__container: {
        margin: "35px 0",
    },
    error__item: {
        backgroundColor: "#D4D4D4",
        padding: "8px",
        marginTop: "5px",
    },
    error__line: {
        marginRight: "10px",
    },
    table__container: {
        height: "200px",
        position: "relative",
        // overflowX: "scroll",
    },
    table__row: {
        display: "flex",
        flexDirection: "row",
        boxSizing: "border-box",
    },

    error__field: {
        color: "#D40000",
    },
    summary: {
        margin: "16px",
    },
    headingTableTitle: {
        fontWeight: 600,
    },
}

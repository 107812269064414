import React, { useEffect, useState } from "react"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core"
import numeral from "numeral"
import { get } from "lodash"
import { rateSchema, chargeSchema, discountSchema, matchSchema } from "./schema"

const PricingTable = ({
    classes,
    defaultPricing,
    customPricing,
    titleColumn,
    secondaryTitleColumn,
    columns,
    availableFreightClasses,
}) => {
    const [tableData, setTableData] = useState([])
    useEffect(() => {
        if (!defaultPricing) return
        const table = []
        if (defaultPricing[0]?.ratePerCwtByClasses) {
            let flatMapMainlandToAlaskaDefaultPricing = handleAlaskaPricing(
                defaultPricing[0]
            ).sort((a, b) => a.freightClass - b.freightClass)
            let mainlandToAlaskaCustomPricing = customPricing?.filter(
                pricing =>
                    pricing.originCFS === "SEA/TAC" &&
                    pricing.destinationCFS === "Alaska"
            )
            let flatMapMainlandToAlaskaCustomPricing = mainlandToAlaskaCustomPricing
                ? mainlandToAlaskaCustomPricing.length > 0
                    ? handleAlaskaPricing(mainlandToAlaskaCustomPricing[0])
                    : null
                : null
            if (flatMapMainlandToAlaskaCustomPricing) {
                let objToInject = {}
                let currentCharges = flatMapMainlandToAlaskaCustomPricing[0]
                objToInject.highMinimumCharge = currentCharges.highMinimumCharge
                objToInject.lowMinimumCharge = currentCharges.lowMinimumCharge
                objToInject.oceanGrossDiscount =
                    currentCharges.oceanGrossDiscount
                objToInject.destinationCFS = "Alaska"
                objToInject.originCFS = "SEA/TAC"
                if (!currentCharges.freightClass) {
                    flatMapMainlandToAlaskaCustomPricing = defaultPricing[0].ratePerCwtByClasses.map(
                        item => ({
                            ...objToInject,
                            freightClass: item.freightClass,
                        })
                    )
                } else {
                    let customFreightClasses = flatMapMainlandToAlaskaCustomPricing.map(
                        item => item.freightClass
                    )
                    let classesForInjection = availableFreightClasses.filter(
                        freightClass =>
                            !customFreightClasses.includes(freightClass)
                    )
                    classesForInjection.forEach(freightClass => {
                        flatMapMainlandToAlaskaCustomPricing.push({
                            ...objToInject,
                            freightClass,
                        })
                    })
                    flatMapMainlandToAlaskaCustomPricing.sort(
                        (a, b) => a.freightClass - b.freightClass
                    )
                }
            }
            for (
                let i = 0;
                i < flatMapMainlandToAlaskaDefaultPricing.length;
                i++
            ) {
                const rowData = getRowValues(
                    flatMapMainlandToAlaskaDefaultPricing[i],
                    flatMapMainlandToAlaskaCustomPricing
                )
                table.push(rowData)
            }

            let flatMapAlaskaToMainlandDefaultPricing = handleAlaskaPricing(
                defaultPricing[1]
            ).sort((a, b) => a.freightClass - b.freightClass)
            let alaskaToMainlandCustomPricing = customPricing?.filter(
                pricing =>
                    pricing.originCFS === "Alaska" &&
                    pricing.destinationCFS === "SEA/TAC"
            )
            let flatMapAlaskaToMainlandCustomPricing = alaskaToMainlandCustomPricing
                ? alaskaToMainlandCustomPricing.length > 0
                    ? handleAlaskaPricing(alaskaToMainlandCustomPricing[0])
                    : null
                : null
            if (flatMapAlaskaToMainlandCustomPricing) {
                let objToInject = {}
                let currentCharges = flatMapAlaskaToMainlandCustomPricing[0]
                objToInject.highMinimumCharge = currentCharges.highMinimumCharge
                objToInject.lowMinimumCharge = currentCharges.lowMinimumCharge
                objToInject.oceanGrossDiscount =
                    currentCharges.oceanGrossDiscount
                objToInject.destinationCFS = "SEA/TAC"
                objToInject.originCFS = "Alaska"
                if (!currentCharges.freightClass) {
                    flatMapAlaskaToMainlandCustomPricing = defaultPricing[1].ratePerCwtByClasses.map(
                        item => ({
                            ...objToInject,
                            freightClass: item.freightClass,
                        })
                    )
                } else {
                    let customFreightClasses = flatMapAlaskaToMainlandCustomPricing.map(
                        item => item.freightClass
                    )
                    let classesForInjection = availableFreightClasses.filter(
                        freightClass =>
                            !customFreightClasses.includes(freightClass)
                    )
                    classesForInjection.forEach(freightClass => {
                        flatMapAlaskaToMainlandCustomPricing.push({
                            ...objToInject,
                            freightClass,
                        })
                    })
                    flatMapAlaskaToMainlandCustomPricing.sort(
                        (a, b) => a.freightClass - b.freightClass
                    )
                }
            }
            for (
                let i = 0;
                i < flatMapAlaskaToMainlandDefaultPricing.length;
                i++
            ) {
                const rowData = getRowValues(
                    flatMapAlaskaToMainlandDefaultPricing[i],
                    flatMapAlaskaToMainlandCustomPricing
                )
                table.push(rowData)
            }
        } else {
            for (let i = 0; i < defaultPricing.length; i++) {
                const rowData = getRowValues(defaultPricing[i], customPricing)
                table.push(rowData)
            }
        }
        setTableData(table)
    }, [defaultPricing, customPricing])

    const handleAlaskaPricing = pricing => {
        if (
            pricing &&
            ((pricing.ratePerCwtByClasses &&
                pricing.ratePerCwtByClasses.length) > 0 ||
                pricing.highMinimumCharge ||
                pricing.lowMinimumCharge ||
                pricing.oceanGrossDiscount)
        ) {
            if (pricing.ratePerCwtByClasses.length > 0) {
                let pricingRows = pricing.ratePerCwtByClasses?.map(item => {
                    let staticVals = {
                        destinationCFS: pricing.destinationCFS,
                        highMinimumCharge: pricing.highMinimumCharge,
                        lowMinimumCharge: pricing.lowMinimumCharge,
                        oceanGrossDiscount: pricing.oceanGrossDiscount,
                        originCFS: pricing.originCFS,
                    }
                    staticVals.freightClass = item.freightClass
                    staticVals.ratePerCwt = item.ratePerCwt
                    return staticVals
                })
                return pricingRows
            }
            return [
                {
                    destinationCFS: pricing.destinationCFS,
                    highMinimumCharge: pricing.highMinimumCharge,
                    lowMinimumCharge: pricing.lowMinimumCharge,
                    oceanGrossDiscount: pricing.oceanGrossDiscount,
                    originCFS: pricing.originCFS,
                },
            ]
        }
        return null
    }

    const getRowValues = (defaultRowValues, customPricing) => {
        const rowData = []
        const customRowValues = customPricing?.find(customRow => {
            return (
                get(customRow, titleColumn) ===
                get(defaultRowValues, titleColumn)
            )
        })

        for (let col of columns) {
            const cellData = {}
            let cellValue = ""
            let isDisabled = false
            if (
                col.value === titleColumn ||
                col.value === secondaryTitleColumn
            ) {
                cellValue = get(defaultRowValues, col.value)

                cellData.isEdited = false
            } else {
                const customCellValue = get(customRowValues, col.value)

                if (customCellValue?.value || customCellValue?.isDisabled) {
                    if (customCellValue?.isPercentageOff) {
                        const defaultValue = get(defaultRowValues, col.value)
                        cellValue = {
                            isPercentageOff: false,
                            value:
                                defaultValue?.value *
                                (1 - customCellValue.value / 100),
                        }
                    } else {
                        cellValue = customCellValue
                    }
                    cellData.isEdited = true
                } else {
                    cellValue = get(defaultRowValues, col.value)

                    cellData.isEdited = false
                }
                if (customCellValue?.isDisabled) {
                    isDisabled = true
                    cellData.isEdited = true
                }
            }
            cellData.value = formatCellValue(cellValue, isDisabled)
            rowData.push(cellData)
        }
        return rowData
    }

    const formatCellValue = (cellObj, isDisabled) => {
        if (typeof cellObj === "string" || typeof cellObj === "number") {
            return cellObj
        } else if (typeof cellObj === "object") {
            if (matchSchema(rateSchema, cellObj)) {
                if (isDisabled) {
                    return "N/A"
                } else {
                    return numeral(cellObj?.value).format("$0,0.00")
                }
            } else if (matchSchema(chargeSchema, cellObj)) {
                return numeral(cellObj?.value).format("$0,0.00")
            } else if (matchSchema(discountSchema, cellObj)) {
                if (!cellObj?.value) {
                    return "---"
                } else if (cellObj?.isPercentage) {
                    return numeral(cellObj?.value / 100).format("0.0%")
                } else {
                    return numeral(cellObj?.value).format("$0,0.00")
                }
            }
            return "---"
        } else {
            return "---"
        }
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {columns.map(header => (
                        <TableCell align="center">
                            <Typography variant="body">
                                {header.title}
                            </Typography>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {tableData.map(rowData => {
                    return (
                        <TableRow
                            item
                            container
                            xs={10}
                            className={classes.table_row}
                        >
                            {rowData.map(tableCell => {
                                return (
                                    <TableCell align={"center"}>
                                        <Typography
                                            variant="body"
                                            className={
                                                tableCell.isEdited
                                                    ? classes.cell_edited
                                                    : null
                                            }
                                        >
                                            {tableCell.value}
                                        </Typography>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}

export default PricingTable

import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import { get } from "lodash"
import moment from "moment"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    navAlert__container: {
        width: "100%",
        padding: "12px",
        borderBottom: "1px solid #D4D4D4",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#D4D4D4",
        },
        "&:active": {
            backgroundColor: "#D8D8D8",
        },
    },
    alert__message: {
        color: "red",
    },
    dismiss__button: {
        "&:hover": {
            cursor: "pointer",
            color: theme.palette.secondary.main,
        },
        "&:active": {
            color: theme.palette.secondary.light,
        },
    },
})

class NavAlert extends React.Component {
    render() {
        const { classes, dismissAlert, onClick, alert = {} } = this.props

        const originAddress = get(alert, "shipment.origin.address", undefined)
        const destinationAddress = get(
            alert,
            "shipment.destination.address",
            undefined
        )

        const trackingId = get(alert, "proNumber")
            ? get(alert, "proNumber")
            : get(alert, "shipment.identifiers.proNumber")

        return (
            <Grid
                container
                className={classes.navAlert__container}
                onClick={() => onClick(get(alert, "shipmentId"))}
            >
                <Grid item container>
                    <Grid
                        item
                        container
                        xs={10}
                        alignItems="center"
                        justify="flex-start"
                    >
                        <Typography variant="subtitle1">
                            {moment(alert.created_at).format("LLLL")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={2}
                        alignItems="center"
                        justify="flex-end"
                    >
                        <Typography
                            color="primary"
                            className={classes.dismiss__button}
                            onClick={e => {
                                e.stopPropagation()
                                dismissAlert(
                                    get(alert, "shipmentId"),
                                    alert._id
                                )
                            }}
                        >
                            <FormattedMessage
                                id="alertBox__dismiss"
                                defaultMessage="Dismiss"
                            />
                        </Typography>
                    </Grid>
                    <Grid item container xs={5} direction="column">
                        {get(alert, "shipment.origin.name") && (
                            <Typography variant="body2">
                                {alert.shipment.origin.name}
                            </Typography>
                        )}
                        {originAddress && (
                            <Typography variant="caption">{`${originAddress.city}, ${originAddress.state} ${originAddress.postalCode}`}</Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        container
                        xs={2}
                        alignItems="center"
                        justify="center"
                    >
                        <ArrowForwardIcon />
                    </Grid>
                    <Grid item container xs={5} direction="column">
                        {get(alert, "shipment.destination.name") && (
                            <Typography variant="body2">
                                {alert?.shipment?.destination?.name}
                            </Typography>
                        )}
                        {destinationAddress && (
                            <Typography variant="caption">{`${destinationAddress.city}, ${destinationAddress.state} ${destinationAddress.postalCode}`}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid item container>
                    {alert.type === "warning" ? (
                        <Typography
                            variant="subtitle1"
                            className={classes.alert__message}
                        >
                            {get(alert, "description")}
                        </Typography>
                    ) : (
                        <Typography variant="subtitle1">
                            {get(alert, "code", "").replace(/_/g, " ")}
                        </Typography>
                    )}
                </Grid>
                <Grid item container direction="column">
                    {get(alert, "shipment.identifiers.bolNumber") && (
                        <Typography variant="caption">
                            <FormattedMessage
                                id="navAlert__BOL"
                                defaultMessage="BOL: {BOL}"
                                values={{
                                    BOL:
                                        alert?.shipment?.identifiers?.bolNumber,
                                }}
                            />
                        </Typography>
                    )}
                    {trackingId && (
                        <Typography variant="caption">
                            <FormattedMessage
                                id="navAlert__trackingId"
                                defaultMessage="Tracking ID: {trackingId}"
                                values={{
                                    trackingId,
                                }}
                            />
                        </Typography>
                    )}
                    {get(alert, "shipment.identifiers.referenceNumber") && (
                        <Typography variant="caption">
                            <FormattedMessage
                                id="navAlert__referenceId"
                                defaultMessage="Reference ID: {referenceId}"
                                values={{
                                    referenceId:
                                        alert?.shipment?.identifiers
                                            ?.referenceNumber,
                                }}
                            />
                        </Typography>
                    )}
                    {get(alert, "shipment.identifiers.orderNumber") && (
                        <Typography variant="caption">
                            <FormattedMessage
                                id="navAlert__poNumber"
                                defaultMessage="PO #: {poNumber}"
                                values={{
                                    poNumber:
                                        alert?.shipment?.identifiers
                                            ?.orderNumber,
                                }}
                            />
                        </Typography>
                    )}
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(NavAlert)

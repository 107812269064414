import { makeStyles } from "@material-ui/styles"

export const useStyles = makeStyles({
    root: { padding: "30px" },
    paper: { overflowX: "auto" },
    errorTable: { overflowY: "auto", height: "300px" },
    table: { width: "90%", maxWidth: "1000px" },
    title: {},
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    discount__container: {
        padding: "1% 6%",
    },
    upload__button: {
        marginBottom: 10,
    },
    modal__container: {
        width: "50vw",
        maxWidth: "700px",
        maxHeight: "600px",
        padding: "30px",
        paddingBottom: "10px",
        borderRadius: "6px",
        backgroundColor: "#fff",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
    },
    modal__buttonContainer: {
        margin: "20px 0 10px 0",
    },
    modal__title: { marginBottom: "10px" },
    button__close: {
        position: "absolute",
        right: "5px",
        top: "5px",
    },
    fixed__column: {
        minWidth: 175,
        paddingRight: 15,
    },
    reset__button: {
        height: "35px",
        margin: "10px 0 0 5px",
    },
    input__grid: {
        margin: "20px auto",
        width: "90%",
        maxWidth: "725px",
    },
})

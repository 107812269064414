import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Input from "@material-ui/core/Input"
import MenuItem from "@material-ui/core/MenuItem"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import {
    Grid,
    FormControl,
    InputLabel,
    FormHelperText,
} from "@material-ui/core"
import Select from "react-select"
import "react-select/dist/react-select.css"
import FormattedError from "./formatted-error"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

class Option extends React.Component {
    handleClick = (event, type) => {
        const option = {
            value: {
                value: this.props.option.values.find(v => v.type === type)
                    .value,
                type,
            },
            label: this.props.option.label,
        }
        this.props.onSelect(option, event)
    }

    render() {
        const { children } = this.props
        return (
            <MenuItem
                component="div"
                onClick={e => this.handleClick(e, "email")}
            >
                <span style={{ marginRight: "10px" }}>{children}</span>
            </MenuItem>
        )
    }
}

function SelectWrapped(props) {
    const { classes, placeholder, isLoading, ...other } = props
    return (
        <Select
            optionComponent={Option}
            noResultsText={
                <Typography variant="body2">
                    {isLoading ? "Loading..." : "No results found"}
                </Typography>
            }
            arrowRenderer={arrowProps =>
                arrowProps.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
            }
            valueComponent={valueProps => <span />}
            placeholder={placeholder}
            tabSelectsValue={false}
            {...other}
        />
    )
}

const ITEM_HEIGHT = 48

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    chip: {
        margin: theme.spacing(0.25),
    },
    "@global": {
        ".Select-control": {
            display: "flex",
            alignItems: "center",
            border: 0,
            height: "auto",
            background: "transparent",
            "&:hover": {
                boxShadow: "none",
            },
        },
        ".Select-multi-value-wrapper": {
            flexGrow: 1,
            display: "flex",
            flexWrap: "wrap",
        },
        ".Select--multi .Select-input": {
            margin: 0,
        },
        ".Select.has-value.is-clearable.Select--single > .Select-control .Select-value": {
            padding: 0,
        },
        ".Select-noresults": {
            padding: theme.spacing(2),
        },
        ".Select-input": {
            display: "inline-flex !important",
            padding: 0,
            height: "auto",
        },
        ".Select--single > .Select-control .Select-value": {
            paddingLeft: "0px",
        },
        ".Select-input input": {
            background: "transparent",
            border: 0,
            padding: 0,
            cursor: "default",
            display: "inline-block",
            fontFamily: "inherit",
            fontSize: "inherit",
            margin: 0,
            outline: 0,
        },
        ".Select-placeholder, .Select--single .Select-value": {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.pxToRem(16),
            padding: 0,
        },
        ".Select-placeholder": {
            opacity: 0.42,
            color: theme.palette.common.black,
        },
        ".Select-menu-outer": {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
            position: "absolute",
            left: 0,
            top: `calc(100% + ${theme.spacing(1)}px)`,
            width: "100%",
            zIndex: 9999,
            maxHeight: ITEM_HEIGHT * 4.5,
        },
        ".Select.is-focused:not(.is-open) > .Select-control": {
            boxShadow: "none",
        },
        ".Select-menu": {
            maxHeight: ITEM_HEIGHT * 4.5,
            overflowY: "auto",
        },
        ".Select-menu div": {
            boxSizing: "content-box",
        },
        ".Select-arrow-zone, .Select-clear-zone": {
            color: theme.palette.action.active,
            cursor: "pointer",
            height: 21,
            width: 21,
            zIndex: 1,
        },
        // Only for screen readers. We can't use display none.
        ".Select-aria-only": {
            position: "absolute",
            overflow: "hidden",
            clip: "rect(0 0 0 0)",
            height: 1,
            width: 1,
            margin: -1,
        },
        ".Select.is-disabled > .Select-control": {
            backgroundColor: "transparent",
        },
        ".Select.is-disabled.has-value.Select--single > .Select-control .Select-value .Select-value-label": {
            color: "currentColor",
        },
        ".recipient__icon": {
            "&:hover": {
                color: theme.palette.secondary.main,
                cursor: "pointer",
            },
        },
    },
})

const FormSelectRecipients = ({
    input,
    label,
    options,
    classes,
    placeholder,
    meta: { touched, error },
    inputProps,
    dataTestId,
    category,
    ...rest
}) => {
    const { logGAEvent } = useGAContext()
    return (
        <Grid
            item
            className={`${classnames({
                "form-field": true,
                "form-field-touched": touched,
                "form-field-invalid": touched && error,
                "form-field-valid": !error,
            })} ${classes.form__container}`}
        >
            <Grid item container direction="column">
                <FormControl error={!!(touched && error)}>
                    <InputLabel htmlFor={input.name}>{label}</InputLabel>
                    <Input
                        placeholder={placeholder}
                        inputComponent={SelectWrapped}
                        inputProps={{
                            id: input.name,
                            classes,
                            multi: true,
                            simpleValue: false,
                            clearable: false,
                            options,
                            dataTestId,
                            ...inputProps,
                        }}
                        {...input}
                        {...rest}
                        onChange={e => {
                            if (input.onChange) {
                                input.onChange(e)
                            }
                            logGAEvent(
                                category,
                                `Change ${label?.props?.defaultMessage ??
                                    input.name}`
                            )
                        }}
                    />
                    {touched && error && (
                        <FormHelperText>
                            <FormattedError error={error} />
                        </FormHelperText>
                    )}
                </FormControl>
            </Grid>
        </Grid>
    )
}

FormSelectRecipients.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FormSelectRecipients)

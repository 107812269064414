import en_us from "./en-us.json"
import es_mx from "./es-mx.json"
import fr_ca from "./fr-ca.json"

export const getTranslationObject = () => {
    const keys = Object.keys(en_us)

    return keys.map(key => {
        return {
            key,
            en_us: en_us[key],
            es_mx: es_mx[key],
            fr_ca: fr_ca[key],
        }
    })
}

import React from "react"
import { Field } from "redux-form"
import { Grid, Typography } from "@material-ui/core"
import FormSwitch from "../../form/form-switch"
import { get } from "lodash"
import { FormattedMessage } from "react-intl"

const Permissions = ({
    classes,
    gridSize,
    information,
    me,
    location,
    prefix,
    defaultMode,
    editMode,
    checkEditable,
    gaCategory,
}) => {
    const calculateDisabled = editable => {
        if (defaultMode) {
            return false
        }
        if (editMode) {
            if (checkEditable && !editable) {
                return true
            }
            return false
        }
        return true
    }

    const createFormName = fieldName => {
        if (prefix) {
            return `${prefix}.${fieldName}`
        }
        return fieldName
    }

    const isFreightBoxLocation = location?.locationType === "FEDEX_FREIGHT_BOX"
    return (
        <Grid
            item
            container
            xs={gridSize}
            direction="column"
            className={classes.location__section}
        >
            <Typography className={classes.title} variant="h6">
                <FormattedMessage
                    id="locations.permissions__title"
                    defaultMessage="Views/Permissions"
                />
            </Typography>
            {me ? (
                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="locations.permissions__selfTitle"
                        defaultMessage="I am able to..."
                    />
                </Typography>
            ) : (
                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="locations.permissions__userTitle"
                        defaultMessage="Allow this user to..."
                    />
                </Typography>
            )}
            <Grid item container alignItems="center" justify="space-between">
                <Grid item container xs={8} alignItems="center">
                    <Typography variant="body2">
                        <FormattedMessage
                            id="locations.permissions__viewRateAmountsAndInvoices"
                            defaultMessage="View rate amounts and invoices"
                        />
                    </Typography>
                </Grid>
                <Grid item container xs={4} alignItems="center">
                    <Grid item container xs={2} justify="center">
                        <Typography variant="caption">
                            <FormattedMessage
                                id="generalTerms__no"
                                defaultMessage="No"
                            />
                        </Typography>
                    </Grid>
                    <Field
                        name={createFormName(
                            "permissions.viewRateAmounts.value"
                        )}
                        component={FormSwitch}
                        category={gaCategory}
                        color="primary"
                        centered
                        disabled={calculateDisabled(
                            get(
                                information,
                                "permissions.viewRateAmounts.editable",
                                true
                            )
                        )}
                    />
                    <Grid item container xs={2} justify="center">
                        <Typography variant="caption">
                            <FormattedMessage
                                id="generalTerms__yes"
                                defaultMessage="Yes"
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container alignItems="center" justify="space-between">
                <Grid item container xs={8} alignItems="center">
                    <Typography variant="body2">
                        <FormattedMessage
                            id="locations.permissions__createARate"
                            defaultMessage="Create a rate"
                        />
                    </Typography>
                </Grid>
                <Grid item container xs={4} alignItems="center">
                    <Grid item container xs={2} justify="center">
                        <Typography variant="caption">
                            <FormattedMessage
                                id="generalTerms__no"
                                defaultMessage="No"
                            />
                        </Typography>
                    </Grid>
                    <Field
                        name={createFormName("permissions.createRates.value")}
                        component={FormSwitch}
                        category={gaCategory}
                        color="primary"
                        centered
                        disabled={calculateDisabled(
                            get(
                                information,
                                "permissions.createRates.editable",
                                true
                            )
                        )}
                    />
                    <Grid item container xs={2} justify="center">
                        <Typography variant="caption">
                            <FormattedMessage
                                id="generalTerms__yes"
                                defaultMessage="Yes"
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container alignItems="center" justify="space-between">
                <Grid item container xs={8} alignItems="center">
                    <Typography variant="body2">
                        <FormattedMessage
                            id="locations.permissions__createAShipment"
                            defaultMessage="Create a shipment"
                        />
                    </Typography>
                </Grid>
                <Grid item container xs={4} alignItems="center">
                    <Grid item container xs={2} justify="center">
                        <Typography variant="caption">
                            <FormattedMessage
                                id="generalTerms__no"
                                defaultMessage="No"
                            />
                        </Typography>
                    </Grid>
                    <Field
                        name={createFormName(
                            "permissions.createShipments.value"
                        )}
                        component={FormSwitch}
                        category={gaCategory}
                        color="primary"
                        centered
                        disabled={calculateDisabled(
                            get(
                                information,
                                "permissions.createShipments.editable",
                                true
                            )
                        )}
                    />
                    <Grid item container xs={2} justify="center">
                        <Typography variant="caption">
                            <FormattedMessage
                                id="generalTerms__yes"
                                defaultMessage="Yes"
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {!isFreightBoxLocation ? (
                <Grid
                    item
                    container
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid item container xs={8} alignItems="center">
                        <Typography variant="body2">
                            <FormattedMessage
                                id="locations.permissions__manageOrders"
                                defaultMessage="View and manage orders"
                            />
                        </Typography>
                    </Grid>
                    <Grid item container xs={4} alignItems="center">
                        <Grid item container xs={2} justify="center">
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="generalTerms__no"
                                    defaultMessage="No"
                                />
                            </Typography>
                        </Grid>
                        <Field
                            name={createFormName(
                                "permissions.manageOrders.value"
                            )}
                            component={FormSwitch}
                            category={gaCategory}
                            color="primary"
                            centered
                            disabled={calculateDisabled(
                                get(
                                    information,
                                    "permissions.manageOrders.editable",
                                    true
                                )
                            )}
                        />
                        <Grid item container xs={2} justify="center">
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="generalTerms__yes"
                                    defaultMessage="Yes"
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}

            <Grid item container alignItems="center" justify="space-between">
                <Grid item container xs={8} alignItems="center">
                    <Typography variant="body2">
                        <FormattedMessage
                            id="locations.permissions__viewAllRates"
                            defaultMessage="View all carrier rates by other users"
                        />
                    </Typography>
                </Grid>
                <Grid item container xs={4} alignItems="center">
                    <Grid item container xs={2} justify="center">
                        <Typography variant="caption">
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="locations.permissions__self"
                                    defaultMessage="Self"
                                />
                            </Typography>
                        </Typography>
                    </Grid>
                    <Field
                        name={createFormName("permissions.viewAllRates.value")}
                        component={FormSwitch}
                        category={gaCategory}
                        color="primary"
                        centered
                        disabled={calculateDisabled(
                            get(
                                information,
                                "permissions.viewAllRates.editable",
                                true
                            )
                        )}
                    />
                    <Grid item container xs={2} justify="center">
                        <Typography variant="caption">
                            <FormattedMessage
                                id="locations.permissions__all"
                                defaultMessage="All"
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container alignItems="center" justify="space-between">
                <Grid item container xs={8} alignItems="center">
                    <Typography variant="body2">
                        <FormattedMessage
                            id="locations.permissions__viewAllShipments"
                            defaultMessage="View all shipments booked with this location"
                        />
                    </Typography>
                </Grid>
                <Grid item container xs={4} alignItems="center">
                    <Grid item container xs={2} justify="center">
                        <Typography variant="caption">
                            <FormattedMessage
                                id="locations.permissions__self"
                                defaultMessage="Self"
                            />
                        </Typography>
                    </Grid>
                    <Field
                        name={createFormName(
                            "permissions.viewAllShipments.value"
                        )}
                        component={FormSwitch}
                        category={gaCategory}
                        color="primary"
                        centered
                        disabled={calculateDisabled(
                            get(
                                information,
                                "permissions.viewAllShipments.editable",
                                true
                            )
                        )}
                    />
                    <Grid item container xs={2} justify="center">
                        <Typography variant="caption">
                            <FormattedMessage
                                id="locations.permissions__all"
                                defaultMessage="All"
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container alignItems="center" justify="space-between">
                <Grid item container xs={8} alignItems="center">
                    <Typography variant="body2">
                        <FormattedMessage
                            id="locations.permissions__viewAllContacts"
                            defaultMessage="View all contacts associated with this location"
                        />
                    </Typography>
                </Grid>
                <Grid item container xs={4} alignItems="center">
                    <Grid item container xs={2} justify="center">
                        <Typography variant="caption">
                            <FormattedMessage
                                id="locations.permissions__self"
                                defaultMessage="Self"
                            />
                        </Typography>
                    </Grid>
                    <Field
                        name={createFormName(
                            "permissions.viewAllContacts.value"
                        )}
                        component={FormSwitch}
                        category={gaCategory}
                        color="primary"
                        centered
                        disabled={calculateDisabled(
                            get(
                                information,
                                "permissions.viewAllContacts.editable",
                                true
                            )
                        )}
                    />
                    <Grid item container xs={2} justify="center">
                        <Typography variant="caption">
                            <FormattedMessage
                                id="locations.permissions__all"
                                defaultMessage="All"
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container alignItems="center" justify="space-between">
                <Grid item container xs={8} alignItems="center">
                    <Typography variant="body2">
                        <FormattedMessage
                            id="locations.permissions__viewAllItems"
                            defaultMessage="View all items associated with this location"
                        />
                    </Typography>
                </Grid>
                <Grid item container xs={4} alignItems="center">
                    <Grid item container xs={2} justify="center">
                        <Typography variant="caption">
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="locations.permissions__self"
                                    defaultMessage="Self"
                                />
                            </Typography>
                        </Typography>
                    </Grid>
                    <Field
                        name={createFormName("permissions.viewAllItems.value")}
                        component={FormSwitch}
                        category={gaCategory}
                        color="primary"
                        centered
                        disabled={calculateDisabled(
                            get(
                                information,
                                "permissions.viewAllItems.editable",
                                true
                            )
                        )}
                    />
                    <Grid item container xs={2} justify="center">
                        <Typography variant="caption">
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="locations.permissions__all"
                                    defaultMessage="All"
                                />
                            </Typography>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Permissions

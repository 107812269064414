import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import { Typography, Grid } from "@material-ui/core"
import NumberIncrementField from "../util/NumberIncrementField"
import { FormattedMessage } from "react-intl"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const styles = theme => ({
    form__container: {
        width: "auto",
        marginLeft: -14,
    },
    centered: {
        margin: 0,
    },

    spacer: {
        width: "64px",
        height: "44px",
    },
})

const FormCapacityField = ({
    input,
    label,
    unit,
    color,
    value,
    classes,
    centered,
    meta: { touched, error },
    dataTestId,
    location,
    min,
    max,
    category,
    ...rest
}) => {
    const { logGAEvent } = useGAContext()
    return (
        <Grid
            container
            alignItems="center"
            className={`${classnames({
                "form-switch": true,
                "form-field-touched": touched,
                "form-field-invalid": touched && error,
                "form-field-valid": !error,
            })} ${classes.form__container}`}
        >
            <Typography variant="body2">{label}</Typography>
            <NumberIncrementField
                id={input.name}
                value={input.value}
                min={min}
                max={max}
                onChange={e => {
                    input.onChange(e)
                    logGAEvent(
                        category,
                        `Increament Field ${label?.props?.defaultMessage ??
                            input.name}`
                    )
                }}
            />
            <Typography variant="body2">{unit}</Typography>
        </Grid>
    )
}

FormCapacityField.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    meta: PropTypes.shape({
        // eslint-disable-next-line react/no-unused-prop-types
        touched: PropTypes.bool,
        // eslint-disable-next-line react/no-unused-prop-types
        error: PropTypes.string,
    }),
    category: PropTypes.string,
}

FormCapacityField.defaultProps = {
    meta: {},
    label: undefined,
    input: undefined,
}

export default withStyles(styles)(FormCapacityField)

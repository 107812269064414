import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { populateTrackForm } from "../../actions/track"

export const TrackLoader = ({ shipmentId, loadShipment }) => {
    useEffect(() => {
        loadShipment(shipmentId)
    }, [shipmentId])

    return null
}

TrackLoader.propTypes = {
    shipmentId: PropTypes.string,
    shipment: PropTypes.object,
    loadShipment: PropTypes.func.isRequired,
}

TrackLoader.defaultProps = {
    shipmentId: null,
    shipment: null,
}

const mapStateToProps = (state, props) => ({
    shipmentId: props.match.params.shipmentId,
})

const mapDispatchToProps = dispatch => ({
    loadShipment: shipmentId => dispatch(populateTrackForm(shipmentId)),
})

export const TrackLoaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TrackLoader)

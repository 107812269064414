import { goFetch } from "../http"
import { ALERT_DISMISS } from "./chat"
import { DISMISS_WARNING_ACTIVE_SHIPMENT } from "./track"

export const FETCH_ALERTS = "FETCH_ALERTS"

export function dismissAlert(shipmentId, alertId, fromshipmentDetails) {
    return async dispatch => {
        await goFetch(
            `/alert/${shipmentId}/event/${alertId}/dismiss`,
            {
                method: "POST",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
            },
            true
        )
        dispatch({ type: ALERT_DISMISS, payload: { shipmentId, alertId } })
        if (fromshipmentDetails) {
            // in the track page we use active shipment details object instead of the shipment list
            dispatch({
                type: DISMISS_WARNING_ACTIVE_SHIPMENT,
                payload: { alertId },
            })
        }
    }
}

export function fetchAlerts() {
    return async dispatch => {
        try {
            const { data: alerts, status } = await goFetch(
                "/alert",
                {
                    method: "GET",
                    credentials: "same-origin",
                    headers: { "cache-control": "no-cache" },
                },
                true
            )
            dispatch({
                type: FETCH_ALERTS,
                payload: status === 200 ? alerts : [],
            })
        } catch (error) {}
    }
}

import React, { Fragment } from "react"
import DialogTitle from "@material-ui/core/DialogTitle"
import Grid from "@material-ui/core/Grid"
import { FormattedMessage } from "react-intl"
import DialogContent from "@material-ui/core/DialogContent"
import Button from "@material-ui/core/Button"
import { UploadDropzone } from "./UploadDropzone"

export const ChooseDocumentMethodDialog = ({ onStage, profileDocs }) => {
    let accept = profileDocs
        ? "image/png, application/pdf"
        : "image/png, application/pdf, image/jpeg"
    return (
        <Fragment>
            <DialogTitle id="alert-dialog-title">
                <Grid item container>
                    <FormattedMessage
                        id="documents.add__new__document"
                        defaultMessage="Add New Document"
                    />
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid
                    item
                    container
                    direction="column"
                    alignContent="flex-start"
                    justify="center"
                >
                    <UploadDropzone onStage={onStage} accept={accept} />
                    <Button variant="contained" color="primary" type="submit">
                        <FormattedMessage
                            id="documents.generate__new__document"
                            defaultMessage="Generate New Document"
                        />
                    </Button>
                </Grid>
            </DialogContent>
        </Fragment>
    )
}

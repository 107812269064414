import { goFetch } from "../http"
import { stringLocation, typedError } from "./index"
import { snakesToCamelsDeep } from "./util/sanitization"

export const INIT_ADDRESS_MODAL = "INIT_ADDRESS_MODAL"
export const CLOSE_ADDRESS_MODAL = "CLOSE_ADDRESS_MODAL"
export const CONTACT_ADDRESS_RESULT = "CONTACT_ADDRESS_RESULT"
export const CONTACT_LOAD_ERROR = "CONTACT_LOAD_ERROR"
export const SELECT_CONTACT = "SELECT_CONTACT"
export const CONTACT_FIELD_CHANGE = "CONTACT_FIELD_CHANGE"

export const selectContact = selected => ({ type: SELECT_CONTACT, selected })
const contactAddressResult = result => ({
    type: CONTACT_ADDRESS_RESULT,
    result,
})

const contactLoadError = error => typedError(CONTACT_LOAD_ERROR, error)

export const fieldChange = (field, value, prefix) => ({
    type: CONTACT_FIELD_CHANGE,
    field,
    value,
    prefix,
})

export function requestContactAddresses() {
    return async dispatch => {
        try {
            const { data: addresses, status } = await goFetch(
                "/addresses/contacts",
                { validErrorCodes: [404] },
                true
            )
            if (status === 404) return dispatch(contactAddressResult([]))
            return dispatch(contactAddressResult(snakesToCamelsDeep(addresses)))
        } catch (error) {
            return dispatch(contactLoadError(error))
        }
    }
}

export function addressBody({
    addressId,
    street1,
    street2,
    postalCode,
    state,
    city,
    country,
    companyName,
}) {
    const body = {
        _id: !addressId ? undefined : addressId,
        name: companyName,
        address: {
            street1,
            street2,
            postalCode,
            state,
            city,
            country,
        },
    }

    return body
}

/*
export function createAddress(addr) {
    return async (dispatch) => {
        try {
            const data = addressBody(addr);
            const { data: retVal } = await goFetch(
                addr.addressId ? `/address/${addr.addressId}` : '/address',
                {
                    method: addr.addressId ? 'PUT' : 'POST',
                    credentials: 'same-origin',
                    headers: { 'cache-control': 'no-cache' },
                    data,
                }, true);
            return snakesToCamelsDeep(retVal);
        } catch (error) {
            return dispatch(errorMessage(error));
        }
    };
}
*/

const matchAddress = (address1, address2) =>
    address1.street1 === address2.street1 &&
    address1.city === address2.city &&
    address1.state === address2.state &&
    address1.postalCode.toString() === address2.postalCode.toString()

export const filterSuggestions = (suggestions, postalCode) =>
    suggestions.filter(
        item => item.raw.postalCode.toString() === postalCode.toString()
    )

export const transformResults = suggestions =>
    suggestions
        .filter(
            ({ raw }) =>
                (raw?.street1 || raw?.street2) &&
                raw?.city &&
                raw?.state &&
                raw?.postalCode &&
                raw?.country
        )
        .map(({ raw, ...rest }) => ({
            ...rest,
            raw: {
                street1: raw.street1,
                street2: raw.street2,
                city: raw.city,
                state: raw.state,
                postalCode: raw.postalCode,
                country: raw.country,
            },
        }))

const getAddressSuggestions = async address => {
    const { data } = await goFetch(
        "/address/services/suggestions",
        { params: { address } },
        true
    )
    return data
}

export async function fetchAddressSuggestions(
    enteredAddress,
    filterPostalCode = false
) {
    let suggestions
    try {
        const results = await getAddressSuggestions(
            stringLocation(enteredAddress)
        )
        suggestions = transformResults(results)
    } catch (error) {
        return false
    }
    const found = suggestions.some(item =>
        matchAddress(enteredAddress, item.raw)
    )
    if (found) return false
    if (!filterPostalCode) return suggestions
    return filterSuggestions(suggestions, enteredAddress?.postalCode)
}

import React from "react"
import { Grid, withStyles, Typography } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import HandlingUnitTimeline from "../../shipmentTimeline/handlingUnitTimeline"
import { weightUnit } from "../../util/units"
import { styles } from "./styles"

const IndividualHandlingUnitTimeline = withStyles(styles)(
    ({ classes, shipment, handlingUnit, query, index, shipmentId }) => (
        <Grid item container className={classes.handlingUnitTimeline}>
            <Grid item container alignItems="center">
                <Grid item container xs={3} alignItems="center">
                    {index === 0 ? (
                        <Typography variant="caption">
                            <FormattedMessage
                                id="tracking__masterTrackingID"
                                defaultMessage="Master Tracking ID"
                            />
                            {" #:"}
                        </Typography>
                    ) : (
                        <Typography variant="caption">
                            <FormattedMessage
                                id="tracking__trackingID"
                                defaultMessage="Tracking ID"
                            />
                            {" #:"}
                        </Typography>
                    )}
                    <span>&nbsp;</span>
                    <Typography
                        variant="subtitle1"
                        className={classes.handlingUnitProNumber}
                    >
                        {handlingUnit.proNumber}
                    </Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item container xs={6} alignItems="center">
                    {handlingUnit.items?.map(item => (
                        <Grid item container>
                            <Typography
                                variant="caption"
                                className={classes.handlingUnitItemDetail}
                            >
                                {item.description}
                            </Typography>
                            <Typography
                                variant="caption"
                                className={classes.handlingUnitItemDetail}
                            >{`Pieces: ${item.pieces}`}</Typography>
                            <Typography
                                variant="caption"
                                className={classes.handlingUnitItemDetail}
                            >
                                <FormattedMessage
                                    id="items__itemWeight"
                                    defaultMessage="Item Weight"
                                />
                                {": "}
                                {Math.ceil(item.weight)}{" "}
                                {weightUnit(
                                    query?.preferredSystemOfMeasurement
                                )}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
                <Grid
                    item
                    container
                    xs={2}
                    alignItems="center"
                    justify="flex-end"
                >
                    <Typography variant="caption">
                        <FormattedMessage
                            id="items__HUWeight"
                            defaultMessage="H/U Weight"
                        />
                        {`: ${handlingUnit?.handlingUnitWeight} ${weightUnit(
                            query?.preferredSystemOfMeasurement
                        )}`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container>
                <HandlingUnitTimeline
                    status={handlingUnit?.status}
                    shipment={shipment}
                    shipmentId={shipmentId}
                    pickupDate={query?.pickupDate}
                />
            </Grid>
        </Grid>
    )
)

export default IndividualHandlingUnitTimeline

export const APPLY_DASHBOARD_FILTERS = "APPLY_DASHBOARD_FILTERS"
export const APPLY_ACTIVE_DASHBOARD_TILE = "APPLY_ACTIVE_DASHBOARD_TILE"

export function applyDashboardFilters() {
    return (dispatch, getState) => {
        const dashboardFilters = getState().form.dashboardFilters.values

        return dispatch({
            type: APPLY_DASHBOARD_FILTERS,
            data: dashboardFilters,
        })
    }
}

export function applyActiveDashboardTile(value) {
    return (dispatch, getState) => {
        return dispatch({
            type: APPLY_ACTIVE_DASHBOARD_TILE,
            value,
        })
    }
}

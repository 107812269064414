import React, { useState, Fragment } from "react"
import { useSnackbarContext } from "../../../context/providers/snackbarProvider"
import { _arrayBufferToBase64 } from "../../track/result/attachments/attachmentsCard"
import { formValues, reduxForm } from "redux-form"
import { FormattedMessage } from "react-intl"
import Dialog from "@material-ui/core/Dialog"
import { UploadSuccessDialog } from "./UploadSuccessDialog"
import { UploadErrorDialog } from "./UploadErrorDialog"
import { UploadDropzone } from "./UploadDropzone"
import { useAttachmentsContext } from "../../../context/providers/attachmentsProvider"
import { useCustomsProfileContext } from "../../../context/providers/CustomsProfileProvider"
import { FilenameCollisionCheckStep } from "../checks/FilenameCollisionCheckStep"
import { documentFieldValidation } from "../USMCA/validation"
import GlobalSpinner from "../../common/GlobalSpinner"
import { UploadProcessingDialog } from "./UploadProcessingDialog"
import { useGAContext } from "../../../context/providers/GoogleAnalyticsProvider"
import { attachmentOptions } from "../../../misc"

const customsProfileDocuments = ["USMCA"]
const initialDocumentCategoryValue = () =>
    attachmentOptions.find(o => o.default).value

let UploadDocument = formValues({
    attachmentType: "documentCategory",
    customFilename: "customFilename",
    shipmentOnly: "shipmentOnly",
})(
    ({
        shipmentId,
        customFilename,
        attachmentType,
        onFinish,
        locationId,
        shipmentOnly,
        handleSubmit,
        gaCategory,
    }) => {
        const { logGAEvent } = useGAContext()
        const { openSnackbar } = useSnackbarContext()
        const { uploadFile } = useAttachmentsContext()
        const { uploadFile: uploadCustomsFile } = useCustomsProfileContext()
        const [step, setStep] = useState(null)
        const [isLoading, setIsLoading] = useState(false)
        const [stagedFile, setStagedFile] = useState({})

        const onStageFile = file => {
            if (file) {
                const reader = new FileReader()
                reader.onabort = () => console.log("file reading was aborted")
                reader.onerror = () => console.log("file reading has failed")
                reader.onload = () => {
                    const result = reader.result
                    const stagedFile = {
                        content: _arrayBufferToBase64(result),
                        mimeType: file.type,
                        originalFileName: file.name,
                    }
                    setStagedFile(stagedFile)
                }
                reader.readAsArrayBuffer(file)
                setStep("uploadStaged")
            } else {
                setStep("uploadError")
            }
        }

        const submitFile = async () => {
            const payload = {
                ...stagedFile,
                userFileName: customFilename,
                attachmentType,
                customFilename,
                locationId,
                shipmentOnly,
                internalTrackingNumber: shipmentId,
            }
            setStep("processing")
            setIsLoading(true)
            try {
                if (customsProfileDocuments.includes(attachmentType)) {
                    await uploadCustomsFile(attachmentType, payload)
                } else {
                    await uploadFile(payload)
                }
                openSnackbar(
                    "success",
                    <FormattedMessage
                        id="documents.manage__addSuccess"
                        defaultMessage="File successfully added"
                    />
                )
            } catch (error) {
                openSnackbar(
                    "error",
                    <FormattedMessage
                        id="documents.manage__addError"
                        defaultMessage="Unable to add file"
                    />
                )
            }
            await onFinish()
            setIsLoading(false)
            setStagedFile({})
            setStep(null)
        }

        const internalSubmitFile = values => {
            logGAEvent(gaCategory, "Upload Dialog Save button Click")
            if (values?.documentCategory === "USMCA") {
                setStep("filenameCollisionCheck")
            } else {
                return submitFile(values)
            }
        }

        return (
            <Fragment>
                {isLoading && <GlobalSpinner />}
                <Dialog open={step} onClose={() => setStep(null)}>
                    {step === "uploadStaged" && (
                        <form onSubmit={handleSubmit(internalSubmitFile)}>
                            <UploadSuccessDialog
                                loading={isLoading}
                                onClose={() => {
                                    logGAEvent(
                                        gaCategory,
                                        "Upload Dialog Cancel"
                                    )
                                    setStep(null)
                                }}
                                stagedFile={stagedFile}
                                submitFile={submitFile}
                                shipmentId={shipmentId}
                                gaCategory={gaCategory}
                            />
                        </form>
                    )}
                    {step === "uploadError" && (
                        <UploadErrorDialog onClose={() => setStep(null)} />
                    )}
                    {step === "filenameCollisionCheck" && (
                        <form onSubmit={handleSubmit(submitFile)}>
                            <FilenameCollisionCheckStep
                                shipmentId={shipmentId}
                                onNoCollision={submitFile}
                                onBack={() => setStep("uploadStaged")}
                                enableProfileOverwrite={false}
                            />
                        </form>
                    )}
                    {step === "processing" && <UploadProcessingDialog />}
                </Dialog>
                <UploadDropzone onStage={onStageFile} />
            </Fragment>
        )
    }
)

UploadDocument = reduxForm({
    form: "attachment",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: documentFieldValidation,
    initialValues: {
        documentCategory: initialDocumentCategoryValue(), // Default value for documentCategory
    },
})(UploadDocument)

export { UploadDocument }

import { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { initBook } from "../../actions/book"

class BookLoader extends Component {
    componentDidMount() {
        const {
            shipmentId,
            carrierCode,
            alternateQuote,
            loadBook,
            shipMode,
            direction,
        } = this.props
        loadBook(shipmentId, carrierCode, shipMode, direction, alternateQuote)
    }

    componentWillReceiveProps({
        shipmentId,
        carrierCode,
        shipMode,
        direction,
        alternateQuote,
    }) {
        if (
            shipmentId !== this.props.shipmentId ||
            carrierCode !== this.props.carrierCode ||
            alternateQuote !== this.props.alternateQuote ||
            shipMode !== this.props.shipMode ||
            direction !== this.props.direction
        ) {
            this.props.loadBook(
                shipmentId,
                carrierCode,
                shipMode,
                alternateQuote
            )
        }
    }

    render() {
        return null
    }
}

BookLoader.propTypes = {
    shipMode: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
    shipmentId: PropTypes.string.isRequired,
    carrierCode: PropTypes.string.isRequired,
    alternateQuote: PropTypes.string,
    loadBook: PropTypes.func.isRequired,
}

BookLoader.defaultProps = {
    alternateQuote: null,
}

const loadMapStateToProps = (state, props) => ({
    shipmentId: props.match.params.shipmentId,
    shipMode: props.match.params.shipMode,
    direction: props.match.params.direction,
    carrierCode: props.match.params.carrierCode,
    alternateQuote: props.match.params.alternateQuote,
})

const loadMapDispatchToProps = dispatch => ({
    loadBook: (
        shipmentId,
        carrierCode,
        shipMode,
        direction,
        alternateQuote
    ) => {
        dispatch(
            initBook(
                shipmentId,
                carrierCode,
                shipMode,
                direction,
                alternateQuote
            )
        )
    },
})

export default connect(loadMapStateToProps, loadMapDispatchToProps)(BookLoader)

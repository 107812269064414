import {
    ALASKA_PRICING_RECEIVED,
    PUERTO_RICO_PRICING_RECEIVED,
    HAWAII_PRICING_RECEIVED,
    ZIPCODE_SPECIAL_HANDLING_RECEIVED,
} from "../actions/offshoreRating"

export function offshoreRating(
    state = {
        puertoRicoPricing: [],
        zipcodeSpecialHandling: {},
    },
    action = {}
) {
    switch (action.type) {
        case PUERTO_RICO_PRICING_RECEIVED:
            return {
                ...state,
                puertoRicoPricing: action.data,
            }
        case ZIPCODE_SPECIAL_HANDLING_RECEIVED:
            return {
                ...state,
                zipcodeSpecialHandling: {
                    ...state.zipcodeSpecialHandling,
                    [action?.data?.state]: [
                        ...(state.zipcodeSpecialHandling[action?.data?.state] ??
                            []),
                        ...action?.data?.dataRaw,
                    ],
                },
            }
        case ALASKA_PRICING_RECEIVED:
            return {
                ...state,
                alaskaPricing: action.data,
            }
        case HAWAII_PRICING_RECEIVED:
            return {
                ...state,
                hawaiiPricing: action.data,
            }
        default:
            return state
    }
}

import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"
import { weightUnit } from "../util/units"

const styles = {
    description: {
        width: "50%",
    },
    section__title: {
        textDecoration: "underline",
    },
    totalsContainer: {
        paddingLeft: "23px",
        paddingTop: "8px",
    },
}

class ItemsDisplay extends React.Component {
    render() {
        const {
            preferredSystemOfMeasurement,
            classes,
            handlingUnits,
            totalWeight,
            totalHandlingUnits,
        } = this.props

        return (
            <Grid item container>
                <Grid item container alignItems="center">
                    <Typography
                        variant="subtitle1"
                        className={classes.section__title}
                    >
                        {" "}
                        <FormattedMessage
                            id="dashboard.handlingUnits__handlingUnits"
                            defaultMessage="Handling Units"
                        />{" "}
                    </Typography>
                </Grid>
                <Grid item container>
                    <Table size="small" aria-label="Items Table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.description}>
                                    <FormattedMessage
                                        id="dashboard.handlingUnits__items"
                                        defaultMessage="Items"
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <FormattedMessage
                                        id="dashboard.handlingUnits__individualHUWeight"
                                        defaultMessage="Individual H/U Weight"
                                    />
                                    {`(${weightUnit(
                                        preferredSystemOfMeasurement
                                    )})`}
                                </TableCell>
                                <TableCell align="right">
                                    <FormattedMessage
                                        id="items__HUCount"
                                        defaultMessage="H/U Count"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {handlingUnits.map((hu, index) => (
                                <TableRow key={index + 1}>
                                    <TableCell>
                                        {hu.items.map((item, index) => {
                                            return index === 0
                                                ? `${item.description} `
                                                : ` | ${item.description}`
                                        })}
                                    </TableCell>
                                    <TableCell align="right">
                                        {hu.handlingUnitWeight}
                                    </TableCell>
                                    <TableCell align="right">
                                        {hu.count}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Grid
                        item
                        container
                        direction="column"
                        alignItems="flex-end"
                        className={classes.totalsContainer}
                    >
                        <Typography variant="body2">
                            <FormattedMessage
                                id="dashboard.handlingUnits__totalShipmentWeight"
                                defaultMessage="Total Shipment Weight"
                            />
                            {`: ${totalWeight}${weightUnit(
                                preferredSystemOfMeasurement
                            )}`}
                        </Typography>
                        <Typography variant="body2">
                            <FormattedMessage
                                id="dashboard.handlingUnits__totalHUCount"
                                defaultMessage="Total H/U Count"
                            />
                            : {totalHandlingUnits}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(ItemsDisplay)

import React from "react"
import {
    TableBody,
    TableCell,
    TableRow,
    Typography,
    makeStyles,
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    centered: {
        textAlign: "center",
    },
    row: {
        backgroundColor: theme.palette.action.hover,
    },
}))

const EmptyTable = ({ tableColumns }) => {
    const classes = useStyles()

    const rows = Array(10)
        .fill(0)
        .map((_, index) => {
            if (index === 3) {
                return (
                    <TableRow height={50} key={index} className={classes.row}>
                        <TableCell colSpan={tableColumns.length + 1}>
                            <Typography
                                variant="subtitle1"
                                className={classes.centered}
                            >
                                No data available.
                            </Typography>
                        </TableCell>
                    </TableRow>
                )
            } else {
                return (
                    <TableRow height={50} key={index} className={classes.row}>
                        <TableCell colSpan={tableColumns.length + 1} />
                    </TableRow>
                )
            }
        })

    return <TableBody>{rows}</TableBody>
}

export default EmptyTable

import React, { useState } from "react"
import Grid from "@material-ui/core/Grid"
import { change } from "redux-form"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import { FormattedMessage } from "react-intl"
import InBondDialog from "./InBondDialog"
import { connect } from "react-redux"
import { trackGAEvent } from "../../actions/user"
import { Button, Chip } from "@material-ui/core"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const styles = theme => ({
    card: {
        width: "100%",
        overflow: "visible",
    },
    chip: {
        margin: "20px 10px",
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: "white",
    },
    underline: {
        textDecoration: "underline",
        paddingBottom: "10px",
    },
})

function InBondCard({
    classes,
    changeField,
    cf7512 = [],
    bookShipmentWorkflow,
    formName,
    gaCategory,
}) {
    const { logGAEvent } = useGAContext()
    const [modalOpen, setModalOpen] = useState(false)

    const handleDelete = number => {
        const cf7512Copy = [...cf7512]
        changeField(
            "cf7512",
            cf7512Copy.filter(x => x !== number)
        )
    }

    if (bookShipmentWorkflow) {
        return (
            <Grid item container>
                <Grid item container>
                    <Grid item container justify="center">
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="book.inBond__title"
                                defaultMessage="CF7512 Numbers & Transportation Type"
                            />
                        </Typography>
                    </Grid>
                    <Grid item container xs={3} alignItems="center">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                changeField("cf7512ModalNumbers", cf7512)
                                setModalOpen(true)
                                logGAEvent(
                                    gaCategory,
                                    "Add Number button click"
                                )
                            }}
                        >
                            <FormattedMessage
                                id="book.inBond__addNumbers"
                                defaultMessage="Add Numbers"
                            />
                        </Button>
                    </Grid>
                    <Grid item container xs={9}>
                        {cf7512.map((number, i) => (
                            <Chip
                                label={number}
                                key={i}
                                className={classes.chip}
                                backgroundColor="#F4F4F4"
                                tabIndex={-1}
                                onDelete={() => {
                                    handleDelete(number)
                                    logGAEvent(
                                        gaCategory,
                                        "Delete Number click"
                                    )
                                }}
                            />
                        ))}
                    </Grid>
                </Grid>
                <InBondDialog
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    formName="bookShipment"
                    gaCategory={gaCategory}
                />
            </Grid>
        )
    } else {
        return (
            <Grid item container xs={12}>
                <Card className={classes.card}>
                    <CardHeader
                        className={classes.header}
                        title={
                            <Typography variant="h6">
                                <FormattedMessage
                                    id="book.inBond__title"
                                    defaultMessage="CF7512 Numbers & Transportation Type"
                                />
                            </Typography>
                        }
                    />
                    <CardContent className={classes.content}>
                        <Grid item container>
                            <Grid item container xs={3} alignItems="center">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        changeField(
                                            "cf7512ModalNumbers",
                                            cf7512
                                        )
                                        setModalOpen(true)
                                    }}
                                >
                                    <FormattedMessage
                                        id="book.inBond__addNumbers"
                                        defaultMessage="Add Numbers"
                                    />
                                </Button>
                            </Grid>
                            <Grid item container xs={9}>
                                {cf7512.map((number, i) => (
                                    <Chip
                                        label={number}
                                        key={i}
                                        className={classes.chip}
                                        backgroundColor="#F4F4F4"
                                        tabIndex={-1}
                                        onDelete={() => handleDelete(number)}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <InBondDialog
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    formName={formName}
                />
            </Grid>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        cf7512: state?.form[props.formName]?.values?.cf7512,
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    changeField: (field, value) =>
        dispatch(change(props.formName, field, value)),
    GAEvent: (category, action) => dispatch(trackGAEvent(category, action)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(InBondCard))

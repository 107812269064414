import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { useOrdersContext } from "../../context/providers/OrdersProvider"

const styles = theme => ({
    progress: {
        margin: "20px auto",
    },
    progressLocal: {
        margin: "auto",
    },
    spinnerOverlay: {
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,.3)",
        zIndex: 99999,
    },
    description: {
        textAlign: "center",
        color: theme.palette.primary.main,
        margin: theme.spacing(2, 0),
    },
    paper: {
        padding: theme.spacing(3),
        textAlign: "center",
        width: "425px",
        height: "238px",
    },
})

const GlobalSpinnerWithMessage = ({
    classes,
    size,
    color,
    isGlobal = true,
    sendingBulkRequest = false,
}) => {
    const { isBookingOrder, isFetchingRates, loading } = useOrdersContext()

    const [spinnerMessage, setSpinnerMessage] = useState("")
    useEffect(() => {
        if (isBookingOrder || sendingBulkRequest) {
            setSpinnerMessage("Please wait, booking shipment(s)...")
        } else {
            if (isFetchingRates) {
                setSpinnerMessage("Please wait, fetching rates...")
            }
        }

        return () => {
            if (!loading) {
                setSpinnerMessage("")
            }
        }
    }, [isBookingOrder, isFetchingRates])

    return (
        <Fragment>
            <div className={isGlobal ? classes.spinnerOverlay : ""}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ height: "100vh" }}
                >
                    <Grid item>
                        <Paper elevation={3} className={classes.paper}>
                            <CircularProgress
                                className={classes.progress}
                                size={size || 80}
                                color="secondary"
                            />
                            <Typography
                                variant="h5"
                                className={classes.description}
                            >
                                {spinnerMessage}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    )
}

GlobalSpinnerWithMessage.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(GlobalSpinnerWithMessage)

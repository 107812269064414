import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { get } from "lodash"
import { Field, reduxForm } from "redux-form"
import {
    Grid,
    Button,
    Snackbar,
    Typography,
    Divider,
    withStyles,
    Box,
    Paper,
} from "@material-ui/core"
import {
    updateUser,
    contactSelected,
    setDefaultLocation,
    trackGAEvent,
} from "../../actions/user"
import normalizePhone from "../util/normalizePhone"
import FormField from "../form/form-field"
import FormSelect from "../form/form-select"
import {
    emailValidator,
    countryPhoneValidator,
    isRequired,
    isNumeric,
} from "../../actions/validation"
import { ErrorSnackbarContentWrapper } from "../errorSnackbar"
import { userUpdateSuccess } from "../../messages/confirmation-constants"
import { userUpdateError } from "../../messages/error-constants"
import AlertsPreferences from "../alerts/alertsPreferences"
import { combineValidators, composeValidators } from "revalidate"
import { FormattedMessage } from "react-intl"
import { preferenceLanguageOptions } from "../constants/preferenceLanguageOptions"
import { supportedLanguagesCountryMap } from "../../misc"
import { useFlags } from "launchdarkly-react-client-sdk"

const GACategory = "UserPreferences"

const styles = theme => ({
    form__itemContainer: {
        padding: theme.spacing(3),
    },
    action_zone: {
        marginTop: theme.spacing(3),
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        padding: theme.spacing(1.5),
    },
})

const UserPreferences = ({
    handleUpdate,
    handleSubmit,
    invalid,
    submitting,
    classes,
    error,
    language,
    trackGA,
}) => {
    const [snackbarState, setSnackbarState] = useState({
        sbOpen: false,
        sbMessage: "",
        sbVariant: "",
    })

    const { featureFlagTestFe } = useFlags()

    useEffect(() => {
        if (snackbarState.sbMessage === userUpdateSuccess) {
            setSnackbarState({
                sbOpen: true,
                sbMessage: userUpdateSuccess,
                sbVariant: "success",
            })
        } else if (snackbarState.sbMessage === userUpdateError) {
            setSnackbarState({
                sbOpen: true,
                sbMessage: userUpdateError,
                sbVariant: "error",
            })
        }
    }, [snackbarState.sbMessage])

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") return
        setSnackbarState(prevState => ({ ...prevState, sbOpen: false }))
    }

    const country = supportedLanguagesCountryMap[language] || "US"

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={snackbarState.sbOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <ErrorSnackbarContentWrapper
                    variant={snackbarState.sbVariant}
                    onClose={handleSnackbarClose}
                    message={
                        <span>
                            <Typography variant="body2">
                                {snackbarState.sbMessage}
                            </Typography>
                        </span>
                    }
                />
            </Snackbar>
            <form onSubmit={handleSubmit(handleUpdate)}>
                {error && (
                    <Typography color="error" gutterBottom>
                        {error}
                    </Typography>
                )}
                <Grid
                    container
                    justifyContent="space-between"
                    className={classes.form__itemContainer}
                >
                    <Grid
                        item
                        container
                        xs={7}
                        className={classes.cardContainer}
                        spacing={2}
                    >
                        <Grid component={Paper} container item xs={12}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    <FormattedMessage
                                        id="userPreferences.generalInformation__title"
                                        defaultMessage="General Information"
                                    />
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="firstname"
                                    label={
                                        <FormattedMessage
                                            id="userPreferences.generalInformation__firstName"
                                            defaultMessage="First Name"
                                        />
                                    }
                                    component={FormField}
                                    category={GACategory}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="lastname"
                                    label={
                                        <FormattedMessage
                                            id="userPreferences.generalInformation__lastName"
                                            defaultMessage="Last Name"
                                        />
                                    }
                                    component={FormField}
                                    category={GACategory}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="phone_number.phone_number"
                                    label={
                                        <FormattedMessage
                                            id="userPreferences.generalInformation__phoneNumber"
                                            defaultMessage="Phone Number"
                                        />
                                    }
                                    component={FormField}
                                    normalize={normalizePhone(country)}
                                    category={GACategory}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="phone_number.extension"
                                    label={
                                        <FormattedMessage
                                            id="userPreferences.generalInformation__extension"
                                            defaultMessage="Extension"
                                        />
                                    }
                                    component={FormField}
                                    category={GACategory}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="email.email_address"
                                    label={
                                        <FormattedMessage
                                            id="userPreferences.generalInformation__email"
                                            defaultMessage="Email Address"
                                        />
                                    }
                                    component={FormField}
                                    category={GACategory}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="preferences.language"
                                    label={
                                        <FormattedMessage
                                            id="userPreferences.generalInformation__language"
                                            defaultMessage="Language"
                                        />
                                    }
                                    component={FormSelect}
                                    options={preferenceLanguageOptions}
                                    category={GACategory}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        spacing={2}
                        container
                        item
                        xs={5}
                        className={classes.cardContainer}
                    >
                        <Grid xs={12} component={Paper} item>
                            <AlertsPreferences
                                chooseWarnings
                                subject="user"
                                gaCategory={GACategory}
                            />
                        </Grid>
                    </Grid>
                    {featureFlagTestFe ? (
                        <Box component={Paper} className={classes.action_zone}>
                            <Typography
                                style={{
                                    padding: "3px",
                                    color: "white",
                                    background: "green",
                                }}
                            >
                                FE Feature Flag Test ON
                            </Typography>
                        </Box>
                    ) : null}
                    <Box component={Paper} className={classes.action_zone}>
                        <Button
                            item
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={invalid || submitting}
                            onClick={() =>
                                trackGA(GACategory, "Update Button Click")
                            }
                        >
                            {
                                <FormattedMessage
                                    id="userPreferences__update"
                                    defaultMessage="Update"
                                />
                            }
                        </Button>
                    </Box>
                </Grid>
                <Divider />
            </form>
        </div>
    )
}

UserPreferences.propTypes = {
    handleUpdate: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    error: PropTypes.string,
    language: PropTypes.string,
    trackGA: PropTypes.func,
}

UserPreferences.defaultProps = {
    submitting: false,
    invalid: true,
}

const userValidation = combineValidators({
    firstname: isRequired({
        field: {
            id: "userPreferences.generalInformation__firstName",
            defaultMessage: "First Name",
        },
    }),
    lastname: isRequired({
        field: {
            id: "userPreferences.generalInformation__lastName",
            defaultMessage: "Last Name",
        },
    }),
    email: {
        email_address: composeValidators(
            isRequired,
            emailValidator
        )({
            field: {
                id: "userPreferences.generalInformation__email",
                defaultMessage: "Email Address",
            },
        }),
    },
    phone_number: {
        phone_number: composeValidators(
            isRequired,
            countryPhoneValidator(undefined, "preferences.language")
        )({
            field: {
                id: "userPreferences.generalInformation__phoneNumber",
                defaultMessage: "Phone Number",
            },
        }),
        extension: isNumeric({
            field: {
                id: "userPreferences.generalInformation__extension",
                defaultMessage: "Extension",
            },
        }),
        mobile: countryPhoneValidator(
            undefined,
            "preferences.language"
        )({
            field: {
                id: "userPreferences.generalInformation__mobileNumber",
                defaultMessage: "Mobile Number",
            },
        }),
    },
    "preferences.language": isRequired({
        field: {
            id: "userPreferences.generalInformation__language",
            defaultMessage: "Language",
        },
    }),
})

const mapStateToProps = state => {
    const user = get(state, "user.profile")
    const locations = get(state, "user.profile.locations", [])
    const { locations: _, ...innerUser } = user

    return {
        initialValues: {
            ...innerUser,
            alerts: { user: get(user, "preferences.alerts") },
        },
        locations,
        formValues: {
            language: "preferences.language",
        },
        testprop: 2,
    }
}

const mapDispatchToProps = dispatch => ({
    onContactSelect: contact => dispatch(contactSelected(contact)),
    trackGA: (category, action, label) =>
        dispatch(trackGAEvent(category, action, label)),
    handleUpdate: values => dispatch(updateUser(values)),
    handleSetDefault: (index, length) =>
        dispatch(setDefaultLocation(index, length)),
})

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "userPreferences", // a unique identifier for this form
            enableReinitialize: true,
            validate: userValidation,
        })(UserPreferences)
    )
)

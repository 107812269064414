import React from "react"
import PropTypes from "prop-types"
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    Button,
    Avatar,
    Typography,
    makeStyles,
} from "@material-ui/core"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import { withStyles } from "@material-ui/core/styles"
import { Map } from "../../util/map"
import DisplayContact from "../../common/DisplayContact"
import DisplayAddress from "../../common/DisplayAddress"

const styles = theme => ({
    card: {
        height: "100%",
    },
    content: {
        "margin-left": "60px",
        "padding-top": "0px !important",
        minWidth: "200px",
    },
    map_content: {
        paddingLeft: "60px",
        paddingRight: "10px",
    },
})

const useStyles = makeStyles({
    mapContainer: {
        width: "100%",
        height: "200px",
    },
})

const VisualVerification = ({ address }) => {
    const classes = useStyles()

    return (
        <div className={classes.mapContainer}>
            <Map addresses={[address]} />
        </div>
    )
}

const AddressCard = ({
    onChangeClick,
    address,
    contact,
    classes,
    title,
    visualVerification,
    gridSize,
    fixed,
    hideEmail,
}) => (
    <Grid xs={gridSize || 6} item>
        <Card className={classes.card}>
            <CardHeader
                className={classes.header}
                title={<Typography variant="h5">{title}</Typography>}
                avatar={
                    <Avatar>
                        <LocationOnIcon variant="contained" color="secondary" />
                    </Avatar>
                }
                action={
                    fixed ? null : (
                        <Button color="primary" onClick={onChangeClick}>
                            {"Change"}
                        </Button>
                    )
                }
            />
            <CardContent className={classes.content}>
                <Grid container>
                    <Grid xs={visualVerification ? 5 : 12} item>
                        <DisplayAddress address={address?.address ?? address} />
                        <DisplayContact
                            contact={address?.contact ?? contact}
                            hideEmail={hideEmail}
                        />
                    </Grid>
                    {visualVerification && (
                        <Grid xs={7} item className={classes.map_content}>
                            <VisualVerification
                                address={address?.address ?? address}
                            />
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    </Grid>
)

AddressCard.propTypes = {
    classes: PropTypes.object,
    visualVerification: PropTypes.bool,
}

AddressCard.defaultProps = {
    classes: {},
    visualVerification: false,
}

export default withStyles(styles)(AddressCard)

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import React from "react"
import { FormattedMessage } from "react-intl"

const NavigationDropdown = ({
    activeFilter,
    setActiveFilter,
    classes,
    offshoreState,
}) => {
    const isPR = offshoreState === "PR"
    const isAK = offshoreState === "AK"
    const isHI = offshoreState === "HI"

    return (
        <FormControl className={classes.toggleFilter}>
            <InputLabel id="dropdown-label">
                <FormattedMessage
                    id="locations.offshore.select"
                    defaultMessage="Select an option"
                />
            </InputLabel>
            <Select
                labelId="dropdown-label"
                id="dropdown"
                value={activeFilter}
                onChange={event => {
                    setActiveFilter(event.target.value)
                }}
            >
                {(isPR || isAK || isHI) && (
                    <MenuItem value="oceanCharges">
                        <FormattedMessage
                            id="domesticOffShore.filters.OceanCharges"
                            defaultMessage="Ocean Charges"
                        />
                    </MenuItem>
                )}

                {(isPR || isAK || isHI) && (
                    <MenuItem value="absoluteMinimumCharge">
                        <FormattedMessage
                            id="domesticOffShore.filters.absoluteMinimumCharge"
                            defaultMessage="Ocean Charge - Absolute Minimum Charge (AMC)"
                        />
                    </MenuItem>
                )}

                {isPR && (
                    <MenuItem value="islandExtremeLengthCharges">
                        <FormattedMessage
                            id="domesticOffShore.filters.IslandExtremeLength"
                            defaultMessage="Island Extreme Length"
                        />
                    </MenuItem>
                )}

                {isPR && (
                    <MenuItem value="oceanExtremeLengthCharges">
                        <FormattedMessage
                            id="domesticOffShore.filters.OceanExtremeLength"
                            defaultMessage="Ocean Extreme Length"
                        />
                    </MenuItem>
                )}

                {isPR && (
                    <MenuItem value="customerPickupAndDeliveryCharge">
                        <FormattedMessage
                            id="domesticOffShore.filters.CustomerPickupAndDelivery"
                            defaultMessage="Customer Pickup And Delivery"
                        />
                    </MenuItem>
                )}

                {isPR && (
                    <MenuItem value="domesticDockDropFee">
                        <FormattedMessage
                            id="domesticOffShore.filters.DomesticDockDropFee"
                            defaultMessage="Domestic Dock Drop Fee"
                        />
                    </MenuItem>
                )}

                {(isPR || isHI) && (
                    <MenuItem value="otherFees">
                        <FormattedMessage
                            id="domesticOffShore.filters.OtherFees"
                            defaultMessage="Other Fees"
                        />
                    </MenuItem>
                )}
                {isAK && (
                    <MenuItem value="bolFee">
                        <FormattedMessage
                            id="domesticOffShore.filters.BillOfLadingFee"
                            defaultMessage="Bill of Lading Fee"
                        />
                    </MenuItem>
                )}
                {(isAK || isHI) && (
                    <MenuItem value="hazardousMaterialsFee">
                        <FormattedMessage
                            id="domesticOffShore.filters.HazardousMaterials"
                            defaultMessage="Hazardous Materials"
                        />
                    </MenuItem>
                )}

                {isAK && (
                    <MenuItem value="protectFromFreezing">
                        <FormattedMessage
                            id="domesticOffShore.filters.ProtectFromFreezing"
                            defaultMessage="Protect From Freezing"
                        />
                    </MenuItem>
                )}
                {isHI && (
                    <MenuItem value="interIslandTransferFee">
                        <FormattedMessage
                            id="domesticOffShore.filters.InterIslandTransferFee"
                            defaultMessage="Inter-Island Transfer Fee"
                        />
                    </MenuItem>
                )}
                {isHI && (
                    <MenuItem value="dockDeliveryPickupCharge">
                        <FormattedMessage
                            id="domesticOffShore.filters.DockDeliveriesAndPickups"
                            defaultMessage="Dock Deliveries & Pickups"
                        />
                    </MenuItem>
                )}
                {isHI && (
                    <MenuItem value="insideDeliveryPickupCharge">
                        <FormattedMessage
                            id="domesticOffShore.filters.insideDeliveryPickupFee"
                            defaultMessage="Inside Delivery/Pickup Charge"
                        />
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    )
}

export default NavigationDropdown

import parsePhoneNumberFromString from "libphonenumber-js"

const intlCountries = ["MX", "CA"]

export const formatPhoneNumber = (phone, ext) => {
    if (!phone) return
    const parsedPhoneNumber = parsePhoneNumberFromString(phone, "US")
    if (parsedPhoneNumber?.country === "US") {
        return parsedPhoneNumber.formatNational() + (ext ? ` Ext. ${ext}` : "")
    } else if (intlCountries.includes(parsedPhoneNumber?.country)) {
        return (
            parsedPhoneNumber.formatInternational() +
            (ext ? ` Ext. ${ext}` : "")
        )
    } else {
        return phone + (ext ? ` Ext. ${ext}` : "")
    }
}

export const getCityStateZipFromAddress = address => {
    return `${address?.city}, ${address?.state} ${address?.postalCode}`
}

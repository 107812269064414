import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { FormattedMessage } from "react-intl"
import Button from "@material-ui/core/Button"
import { downloadJSONAsCSV } from "../../actions/util"

const DownloadCSVButtonPresentation = ({ data, name, sortBy, onClick }) => (
    <Button
        variant="outlined"
        color="primary"
        onClick={() => onClick(data, name, sortBy)}
    >
        <FormattedMessage
            id="generalTerms__download_CSV"
            defaultMessage="Download CSV"
        />
    </Button>
)

DownloadCSVButtonPresentation.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    name: PropTypes.string.isRequired,
    sortBy: PropTypes.string,
    onClick: PropTypes.func.isRequired,
}

DownloadCSVButtonPresentation.defaultProps = {
    sortBy: undefined,
}

const mapDispatchToProps = dispatch => ({
    onClick: (data, name, sortBy) => {
        const dataCopy = [...data]
        const sorted = sortBy
            ? dataCopy.sort((a, b) => b[sortBy] - a[sortBy])
            : dataCopy
        dispatch(downloadJSONAsCSV(sorted, name))
    },
})

export const DownloadCSVButton = connect(
    undefined,
    mapDispatchToProps
)(DownloadCSVButtonPresentation)

import React from "react"
import AdminPage from "../components"

const title = "US Dim Weight"
const urlPath = `usdimweight`
const sampleFile = "import_usdimweight_sample.xlsx"

const tableHeaders = [
    {
        id: "accountNumber",
        type: "text",
        align: "left",
        label: "Account Number",
    },
    {
        id: "dimFactor",
        type: "text",
        align: "right",
        label: "Dim Factor",
    },
    {
        id: "freightClass",
        type: "text",
        align: "right",
        label: "Class",
    },
    {
        id: "expirationDate",
        type: "date",
        align: "right",
        label: "Pricing Expiration Date",
    },
]

const USDimWeightAdmin = () => {
    return (
        <>
            <AdminPage
                title={title}
                urlPath={urlPath}
                sampleFile={sampleFile}
                tableHeaders={tableHeaders}
            />
        </>
    )
}

export default USDimWeightAdmin

import { Grid, Typography, Dialog, Button } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

const HazMatModal = ({ classes, open, setClose }) => {
    return (
        <Dialog maxWidth="lg" open={open}>
            <Grid className={classes.modal}>
                <Grid item container justifyContent="center">
                    <Grid item style={{ padding: "1.5em" }}>
                        <Typography align="center">
                            <FormattedMessage
                                id="staticHazMatMsg"
                                defaultMessage="Consult {link} for additional information on Alaska hazardous shipping"
                                values={{
                                    link: (
                                        <a
                                            href="https://www.fedex.com/content/dam/fedex/us-united-states/services/FXF_100_Series_Rules_Tariff.pdf#page=47"
                                            target="_blank"
                                        >
                                            <FormattedMessage
                                                id="link"
                                                defaultMessage="100 Rules Tariff"
                                            />
                                        </a>
                                    ),
                                }}
                            />
                        </Typography>
                        <Grid
                            style={{
                                paddingTop: "2em",
                            }}
                            align="center"
                            className={classes.buttonGroup}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => setClose()}
                            >
                                <FormattedMessage
                                    id="generalTerms__okay"
                                    defaultMessage="Okay"
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default HazMatModal

import {
    EMAIL_FIELD_CHANGE,
    INIT_EMAIL_FORM,
    EMAIL_SENDING,
    EMAIL_SENT,
} from "../actions/email-shipment"

export function email(
    state = {
        shipmentId: "",
        toName: "",
        toEmail: "",
        ccName: "",
        ccEmail: "",
        isSendLabels: true,
        isSendBol: true,
        sendingProgress: false,
        sentSuccess: true,
    },
    action = {}
) {
    switch (action.type) {
        case INIT_EMAIL_FORM:
            return { ...email(), ...action }
        case EMAIL_FIELD_CHANGE:
            return {
                ...state,
                [action.field]: action.value,
            }
        case EMAIL_SENDING:
            return {
                ...state,
                sendingProgress: true,
            }
        case EMAIL_SENT:
            return {
                ...state,
                sentSuccess: true,
                sendingProgress: false,
            }
        default:
            return state
    }
}

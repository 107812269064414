import { FETCH_ALERTS } from "../actions/alerts"
import { ALERT_DISMISS, ALERT_EVENT } from "../actions/chat"

export function alerts(
    state = {
        alertList: [],
    },
    action = {}
) {
    switch (action.type) {
        case FETCH_ALERTS:
            return {
                ...state,
                alertList: action.payload,
            }
        case ALERT_DISMISS:
            return {
                ...state,
                alertList: state.alertList.filter(
                    alert => alert._id !== action.payload.alertId
                ),
            }
        case ALERT_EVENT:
            return {
                ...state,
                alertList: [action.payload, ...state.alertList],
            }
        default:
            return state
    }
}

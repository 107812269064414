import React, { useState } from "react"
import { Modal, withStyles, Typography, Grid, Button } from "@material-ui/core"
import { closeModal } from "../../actions/modal"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { FormattedMessage } from "react-intl"
import GlobalSpinner from "./GlobalSpinner"
import { useSnackbarContext } from "../../context/providers/snackbarProvider"
import {
    updateUserLocation,
    updateCustomLocationPreferences,
    trackGAEvent,
} from "../../actions/user"

const styles = theme => ({
    button__container: {
        display: "flex",
        justifyContent: "space-between",
    },
    modal__container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
        width: theme.spacing(60),
        minHeight: "200px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: "20px",
    },
    heading: {
        padding: "2px",
        marginBottom: "10px",
    },
    body: {
        padding: "2px",
        marginBottom: "10px",
    },
})

const ConfirmActionModal = ({
    onCloseClick,
    classes,
    open,
    handleConfirm,
    message,
    title,
    gaCategory,
    modalName,
    trackGA,
}) => {
    const [isProcessing, setIsProcessing] = useState(false)

    return (
        <Modal open={open}>
            <Grid item xs={12} className={classes.modal__container} container>
                {isProcessing && <GlobalSpinner />}
                <Grid>
                    <Grid item className={classes.heading}>
                        <Typography color="inherit" variant="h6">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.body}>
                        <Typography variant="body2">{message}</Typography>
                    </Grid>
                </Grid>
                <Grid item className={classes.button__container}>
                    <Button
                        onClick={e => {
                            e.stopPropagation()
                            onCloseClick()
                            if (gaCategory) {
                                trackGA(
                                    gaCategory,
                                    `${
                                        modalName
                                            ? modalName
                                            : title?.props?.defaultMessage ??
                                              "Confirm Action Modal"
                                    }: Close Click`
                                )
                            }
                        }}
                        color="secondary"
                        variant="outlined"
                    >
                        <FormattedMessage
                            id="generalTerms__cancel"
                            defaultMessage="Cancel"
                        />
                    </Button>
                    <Button
                        onClick={async () => {
                            setIsProcessing(true)
                            trackGA(
                                gaCategory,
                                `${
                                    modalName
                                        ? modalName
                                        : title?.props?.defaultMessage ??
                                          "Confirm Action Modal"
                                }: Confirm Click`
                            )
                            await handleConfirm()
                            setIsProcessing(false)
                        }}
                        color="primary"
                        variant="contained"
                    >
                        <FormattedMessage
                            id="generalTerms__confirm"
                            defaultMessage="Confirm"
                        />
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    )
}

ConfirmActionModal.propTypes = {
    onCloseClick: PropTypes.func.isRequired,
    classes: PropTypes.object,
    open: PropTypes.bool.isRequired,
    gaCategory: PropTypes.string,
    modalName: PropTypes.string,
}
ConfirmActionModal.defaultProps = {
    gaCategory: "Confirm Action Modal",
    modalName: "",
}

const mapStateToProps = (state, props) => {
    return {
        open: state.modal.isOpen,
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    handleUpdate: async location => dispatch(updateUserLocation(location)),
    updateCustomLocationPreferences: async location =>
        dispatch(updateCustomLocationPreferences(location)),
    onCloseClick: () => {
        dispatch(closeModal())
    },
    trackGA: (category, action, value) =>
        dispatch(trackGAEvent(category, action, value)),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(ConfirmActionModal)
)

import { Grid } from "@material-ui/core"
import React from "react"
import Tile from "./Tile"
import { withStyles } from "@material-ui/core/styles"
import { supportedTiles } from "./constants"
import { useFlags } from "launchdarkly-react-client-sdk"

const styles = theme => ({
    tileGroupContainer: {
        padding: "5px 0",
    },
    tileSection: {
        padding: "10px",
    },
})

const TileGroup = ({
    tileSettings = [],
    classes,
    activeTile,
    activateTile,
    getTileCount,
    shipmentsFetching,
    isShipmentsLoaded,
    trackGA,
}) => {
    const { invoiceVariance } = useFlags()

    if (!invoiceVariance) {
        tileSettings = tileSettings.filter(
            setting => setting !== "viewInvoicedVariant"
        )
    }
    return (
        <Grid item container className={classes.tileGroupContainer}>
            {tileSettings.map(tile => {
                const { label, icon, value } = supportedTiles.find(
                    supportedTile => tile === supportedTile.value
                )
                return (
                    <Grid item container xs={2} className={classes.tileSection}>
                        <Tile
                            shipmentsFetching={shipmentsFetching}
                            label={label}
                            icon={icon}
                            value={value}
                            activateTile={activateTile}
                            active={activeTile === value}
                            getTileCount={getTileCount}
                            trackGA={trackGA}
                            isShipmentsLoaded={isShipmentsLoaded}
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default withStyles(styles)(TileGroup)

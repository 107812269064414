import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage, injectIntl } from "react-intl"
import { weightUnit } from "../util/units"

const styles = {
    description: {
        width: "50%",
    },
    section__title: {
        textDecoration: "underline",
    },
    totalsContainer: {
        paddingLeft: "23px",
        paddingTop: "8px",
    },
}

const QuoteHUDisplay = ({ search, classes, handlingUnits = [], intl }) => (
    <Grid item container>
        <Grid item container alignItems="center">
            <Typography variant="subtitle1" className={classes.section__title}>
                <FormattedMessage
                    id="dashboard.handlingUnits__handlingUnits"
                    defaultMessage="Handling Units"
                />
            </Typography>
        </Grid>
        <Grid item container>
            <Table size="small" aria-label="Items Table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.description}>
                            <FormattedMessage
                                id="dashboard.handlingUnits__items"
                                defaultMessage="Items"
                            />
                        </TableCell>
                        <TableCell align="right">
                            <FormattedMessage
                                id="dashboard.handlingUnits__individualHUWeight"
                                defaultMessage="Individual H/U Weight"
                            />
                            {` (${weightUnit(
                                search?.preferredSystemOfMeasurement
                            )})`}
                        </TableCell>
                        <TableCell align="right">
                            <FormattedMessage
                                id="items__HUCount"
                                defaultMessage="H/U Count"
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {handlingUnits &&
                        handlingUnits.map((hu, index) => (
                            <TableRow key={index + 1}>
                                <TableCell>
                                    {hu?.items
                                        ?.map(
                                            item =>
                                                item?.description ??
                                                intl.formatMessage({
                                                    id:
                                                        "dashboard.handlingUnits__noDescriptionEntered",
                                                    defaultMessage:
                                                        "No Description Entered",
                                                })
                                        )
                                        .join(" | ")}
                                </TableCell>
                                <TableCell align="right">
                                    {hu.handlingUnitWeight}
                                </TableCell>
                                <TableCell align="right">{hu.count}</TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </Grid>
    </Grid>
)

export default withStyles(styles)(injectIntl(QuoteHUDisplay))

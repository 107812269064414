import {
    Grid,
    Button,
    IconButton,
    Collapse,
    Typography,
    Checkbox,
    withStyles,
} from "@material-ui/core"
import React, { useState } from "react"
import RateBreakdown from "../../../dashboard/components/rateBreakdown"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import { FormattedMessage } from "react-intl"
import {
    RateDeliveryTime,
    RateGuaranteed,
    rateTotalAmount,
} from "../summaryCards/RatesSummary"

const styles = theme => ({
    section: {
        paddingTop: "10px",
    },
    rateRow: {
        padding: "10px 0",
    },
    iconButton: {
        paddingLeft: "10px",
    },
    rateBreakdown: {
        paddingTop: "10px",
    },
    rateBreakdownInner: {
        width: "100%",
    },
    serviceLevel_text: {
        color: "green",
    },
    isShippedRate: {
        backgroundColor: theme.palette.secondary.light,
        padding: "10px 0",
    },
})

const RateRow = ({
    canViewRateAmounts,
    category,
    trackGAEvent,
    rate = { rateQuoteDetail: {}, serviceLevel: {} },
    isSharing,
    setIsSelected,
    selections,
    selectRate,
    index,
    expired,
    shipped,
    isShippedRate,
    classes,
    isOffshoreCalculatedFedexRate,
}) => {
    const { _id } = rate

    const [isExpanded, setIsExpanded] = useState(false)

    const rateTotal = rateTotalAmount(rate, canViewRateAmounts)

    const isSelected = selections.has(_id)

    return (
        <Grid
            item
            container
            className={isShippedRate ? classes.isShippedRate : classes.rateRow}
        >
            <Grid item container justify="flex-end">
                <Grid item container xs={2}>
                    {isSharing && (
                        <Checkbox
                            id={`checkbox-rate-${index}`}
                            onChange={(e, value) => {
                                setIsSelected(rate._id, value)
                            }}
                            checked={isSelected}
                        />
                    )}
                </Grid>
                <Grid item container xs={10}>
                    <Grid item container xs={7} alignItems="center">
                        <Grid item container direction="column">
                            <RateDeliveryTime
                                rate={rate}
                                isFreightDirect={false}
                                id={`deliverytime-rate-${index}`}
                                variant="subtitle1"
                            />
                            <RateGuaranteed
                                rate={rate}
                                canViewRateAmounts={canViewRateAmounts}
                                id={`guaranteed-label-${index}`}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={5}
                        justify="flex-end"
                        alignItems="center"
                        wrap="nowrap"
                    >
                        <Grid item>
                            <Grid
                                item
                                container
                                direction="column"
                                alignItems="center"
                            >
                                {rate?.direction === "THIRD_PARTY" && (
                                    <Typography
                                        id={`thirdPartyPricing-label-${index}`}
                                        variant="caption"
                                    >
                                        <FormattedMessage
                                            id="rateResults__thirdPartyPricing"
                                            defaultMessage="3rd Party Pricing"
                                        />
                                    </Typography>
                                )}

                                {expired || shipped ? (
                                    <Typography variant="subtitle1">
                                        {rateTotal ?? (
                                            <FormattedMessage
                                                id="rateResults__bookNow"
                                                defaultMessage="Book Now"
                                            />
                                        )}
                                    </Typography>
                                ) : (
                                    <Button
                                        id={`select-rate-button-${index}`}
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => {
                                            selectRate(rate)
                                        }}
                                    >
                                        {rateTotal ?? (
                                            <FormattedMessage
                                                id="rateResults__bookNow"
                                                defaultMessage="Book Now"
                                            />
                                        )}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item className={classes.iconButton}>
                            {!!rateTotal && (
                                <IconButton
                                    onClick={() => {
                                        setIsExpanded(!isExpanded)
                                        trackGAEvent(
                                            category,
                                            isExpanded
                                                ? "Unexpand Pricing"
                                                : "Expand Pricing"
                                        )
                                    }}
                                >
                                    {isExpanded ? (
                                        <ExpandLessIcon
                                            id={`select-rate-expandLessIcon-${index}`}
                                        />
                                    ) : (
                                        <ExpandMoreIcon
                                            id={`select-rate-expandMoreIcon-${index}`}
                                        />
                                    )}
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    justify="flex-end"
                    className={classes.rateBreakdown}
                >
                    <Collapse
                        in={isExpanded}
                        className={classes.rateBreakdownInner}
                    >
                        <RateBreakdown
                            listView={false}
                            charges={rate?.rateQuoteDetail?.charges}
                            usePreferredCurrency
                            totalInPreferredCurrency={
                                rate?.rateQuoteDetail
                                    ?.totalInPreferredCurrency ??
                                rate?.rateQuoteDetail?.total
                            }
                            preferredCurrencyCode={rate?.preferredCurrencyCode}
                            gridSize={12}
                            quoteID={
                                isOffshoreCalculatedFedexRate
                                    ? null
                                    : rate?.capacityProviderQuoteNumber
                            }
                            isBookShipment
                            rateIndex={index}
                        />
                    </Collapse>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(RateRow)

import { isFedExCarrier } from "../../misc"

const states = ["PR"]

const containsCombinedOtherRatesAndFedexOceanRates = rate =>
    !isFedExCarrier(rate?.carrierCode) &&
    rate?.rateQuoteDetail?.charges?.some(
        charge =>
            (charge?.code === "DDDFEE" &&
                charge?.description?.includes("Dock Drop")) ||
            (charge?.code === "BFEE" &&
                charge?.description?.includes("BOL Fee")) ||
            (charge?.code === "DDDFEE" &&
                charge?.description?.includes("Dock Delivery & Pickup Charge"))
    )

const isMainlandToOffshore = destinationState =>
    states.includes(destinationState)

export { containsCombinedOtherRatesAndFedexOceanRates, isMainlandToOffshore }

import React, { Component, Fragment, useContext } from "react"
import { connect } from "react-redux"
import { get } from "lodash"
import {
    reduxForm,
    formValueSelector,
    change,
    Field,
    getFormValues,
} from "redux-form"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Chip from "@material-ui/core/Chip"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CancelIcon from "@material-ui/icons/Cancel"
import AccessorialsField from "../form/form-select-accessorial"
import { Map } from "../util"
import { loadContact } from "../../reducers/address"
import FormSwitch from "../form/form-switch"
import { FormattedMessage } from "react-intl"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"
import DisplayAddress from "../common/DisplayAddress"
import DisplayContact from "../common/DisplayContact"

const styles = theme => ({
    card: {
        height: "100%",
        overflow: "visible",
        width: "100%",
    },
    infoIcon: {
        backgroundColor: "red",
    },
    expansionSummary: {
        backgroundColor: "blue",
    },
    address: {},
    contact: {
        height: "60px",
        paddingBottom: "15px",
    },
    services: {
        marginTop: "15px",
    },
    select__container: {
        margin: "0 0 10px 0",
        width: "100%",
    },
    chipButton: {
        margin: "5px 5px",
    },
    chip: {
        margin: "5px 5px",
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: "white",
    },
    visualVerification__container: {
        marginTop: "10px",
    },
    visualVerification__icon: {
        marginLeft: "5px",
    },
    visualVerification__caption: {
        display: "flex",
    },
    visualVerification__details: {
        paddingTop: "5px",
        paddingLeft: "5px",
    },
    map__container: {
        width: "100%",
        padding: "5px",
    },
    header: {},
    button: {
        marginTop: "10px",
    },
    icon__arrow: {
        position: "absolute",
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
    },
    commodities__container: {
        paddingRight: "10px",
    },
    additionalServices__form: {
        display: "flex",
        width: "100%",
    },
    additionalServices__header: {
        backgroundColor: theme.palette.primary.main,
    },
    additionalServices__details: {
        height: "100%",
    },
    expand: {
        transform: "rotate(0deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: "auto",
        bottom: "15px",
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    services__popover: {
        padding: "5px",
        minHeight: "355px",
        width: "550px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    accessorials__card: {
        paddingBottom: "10px",
    },
    addServicesPanel__container: {
        padding: "5px",
    },
    accessorials__select__text: {
        marginBottom: "10px",
    },
})

export const formattedPriorityPickupAccessorials = [
    {
        value: "CUSTPU",
    },
    {
        value: "DOCKPU",
    },
    {
        value: "LGPU",
    },
    {
        value: "RESPU",
    },
    {
        value: "LTDPU",
    },
    {
        value: "INPU",
    },
]

export const formattedPriorityDeliveryAccessorials = [
    {
        value: "CUSTDEL",
    },
    {
        value: "CUSTDELOFF",
    },
    {
        value: "CUSTPUOFF",
    },
    {
        value: "DOCKDEL",
    },
    {
        value: "LGDEL",
    },
    {
        value: "RESDEL",
    },
    {
        value: "LTDDEL",
    },
    {
        value: "INDEL",
    },
    {
        value: "NOTIFY",
    },
]

export const sortByPriority = (list, priorityList) => {
    const priorityAccessorials = priorityList.map(item =>
        list.find(item2 => item2?.value === item?.value)
    )
    const generalAccessorials = list.filter(
        accessorial =>
            !priorityList.map(x => x?.value).includes(accessorial?.value)
    )

    return [...priorityAccessorials, ...generalAccessorials]
}

let VisualVerification = ({ address, classes }) => (
    <Grid item xs={12} className={classes.map__container}>
        <Map addresses={[address]} />
    </Grid>
)

VisualVerification = withStyles(styles)(VisualVerification)

let SelectedAccessorials = ({
    category,
    selected,
    accessorials,
    classes,
    changeField,
    name,
    isInBondShipment,
}) => {
    const { logGAEvent } = useGAContext()

    let selectedArr

    if (typeof selected === "string") {
        selectedArr = selected.split(",")
    } else {
        selectedArr = selected
    }

    return (
        <Fragment>
            {selected &&
                selectedArr.map((accessorial, index) => (
                    <Grid key={accessorial} item>
                        <Chip
                            id={`${name}-chip-${index}`}
                            tabIndex={-1}
                            label={
                                accessorials.find(
                                    it => it?.value === accessorial
                                )?.label
                            }
                            className={classes.chip}
                            deleteIcon={
                                <CancelIcon
                                    id={`${name}-chip-${index}-remove`}
                                />
                            }
                            backgroundColor="#F4F4F4"
                            onDelete={
                                ["DOCKDEL", "DOCKPU"].includes(accessorial)
                                    ? null
                                    : () => {
                                          changeField(
                                              name,
                                              selectedArr.filter(
                                                  x => x !== accessorial
                                              )
                                          )
                                          logGAEvent(
                                              category,
                                              "Delete Accessorial",
                                              accessorial
                                          )
                                      }
                            }
                        />
                    </Grid>
                ))}
            {isInBondShipment && (
                <Grid item>
                    <Chip
                        tabIndex={-1}
                        label={
                            <FormattedMessage
                                id="accessorials.panel__freightMovingInBond"
                                defaultMessage="Freight Moving In-Bond"
                            />
                        }
                        className={classes.chip}
                        backgroundColor="#F4F4F4"
                    />
                </Grid>
            )}
        </Fragment>
    )
}

SelectedAccessorials = withStyles(styles)(SelectedAccessorials)

let AccessorialsSelect = ({
    category,
    placeholder,
    name,
    accessorials,
    classes,
    onAccessorialSelect,
    bookShipmentWorkflow,
    bookShipmentLabel,
}) => {
    const { logGAEvent } = useGAContext()
    return (
        <Grid
            item
            className={classes.select__container}
            justifyContent="center"
        >
            {!bookShipmentWorkflow && (
                <Typography
                    variant="subtitle1"
                    className={classes.accessorials__select__text}
                >
                    <FormattedMessage
                        id="accessorials.panel__additionalOptions"
                        defaultMessage="Additional Options (Accessorials)"
                    />
                </Typography>
            )}
            <Field
                component={AccessorialsField}
                name={name}
                options={accessorials}
                label={bookShipmentLabel ?? placeholder}
                onChange={(e, value) => {
                    onAccessorialSelect(e, value, name)
                    const items = value.split(",")
                    const newValue = items[items.length - 1]
                    logGAEvent(category, "Selected Accessorial", newValue)
                }}
            />
        </Grid>
    )
}

AccessorialsSelect = withStyles(styles)(AccessorialsSelect)

export { SelectedAccessorials, AccessorialsSelect }

class AddServicesPanel extends Component {
    render() {
        const {
            classes,
            selected,
            accessorials,
            name,
            changeField,
            onAccessorialSelect,
            language,
            isInBondShipment,
        } = this.props

        return (
            <Grid
                item
                container
                xs={12}
                justify="flex-start"
                className={classes.addServicesPanel__container}
            >
                <AccessorialsSelect
                    name={name}
                    onAccessorialSelect={onAccessorialSelect}
                    accessorials={accessorials}
                    placeholder={
                        <FormattedMessage
                            id="generalTerms__select"
                            defaultMessage="Select"
                        />
                    }
                />
                <SelectedAccessorials
                    selected={selected}
                    accessorials={accessorials}
                    name={name}
                    changeField={changeField}
                    language={language}
                    isInBondShipment={isInBondShipment}
                />
            </Grid>
        )
    }
}

AddServicesPanel = withStyles(styles)(AddServicesPanel)

let AccessorialsCard = ({
    classes,
    accessorials,
    name,
    selected,
    address,
    contact,
    changeField,
    defaultHeader,
    onAccessorialSelect,
    isFreightDirect,
    isFreightDirectReturns,
    language,
    isInBondShipment,
}) => (
    <Card className={classes.card}>
        <CardContent>
            <Grid container>
                <Grid item container xs={7}>
                    <Typography gutterBottom variant="h6">
                        {get(address, "name") || defaultHeader}
                    </Typography>
                    <Grid
                        item
                        container
                        xs={12}
                        className={classes.address}
                        alignItems="flex-end"
                    >
                        <DisplayAddress
                            address={get(address, "address", address)}
                            showName={false}
                        />
                    </Grid>
                    {get(contact, "contact", contact) && (
                        <Grid
                            item
                            container
                            xs={12}
                            className={classes.contact}
                            alignItems="flex-end"
                        >
                            <DisplayContact
                                contact={get(contact, "contact", contact)}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} className={classes.services}>
                        {!isFreightDirect && !isFreightDirectReturns && (
                            <AddServicesPanel
                                type={defaultHeader}
                                accessorials={accessorials}
                                name={name}
                                selected={selected}
                                address={address}
                                changeField={changeField}
                                onAccessorialSelect={onAccessorialSelect}
                                language={language}
                                isInBondShipment={isInBondShipment}
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid item container xs={5}>
                    {get(address, "street1") && (
                        <VisualVerification
                            address={get(address, "address", address)}
                        />
                    )}
                </Grid>
            </Grid>
        </CardContent>
    </Card>
)

AccessorialsCard = withStyles(styles)(AccessorialsCard)

export const defaultAccessorialLogic = (
    name,
    value = [],
    changeField,
    country,
    permanentList
) => {
    let accessorialArray
    const mutuallyExclusiveAccessorials = [
        "LGPU",
        "RESPU",
        "LTDPU",
        "CUSTPU",
        "LGDEL",
        "RESDEL",
        "LTDDEL",
        "DOCKPU",
        "DOCKDEL",
        "CUSTDEL",
        "CUSTPUOFF",
        "CUSTDELOFF",
    ]

    if (typeof value === "string") {
        accessorialArray = value.split(",")
    } else {
        accessorialArray = value
    }

    if (country === "MX") {
        accessorialArray = accessorialArray.filter(x =>
            [
                ...formattedPriorityPickupAccessorials,
                ...formattedPriorityDeliveryAccessorials,
            ].some(y => y?.value === x)
        )
    }

    const accessorialCode = accessorialArray[accessorialArray?.length - 1]

    if (accessorialArray?.length !== 1) {
        if (accessorialCode === "DOCKPU") {
            if (
                permanentList?.some(x => ["LGPU", "RESPU", "LTDPU"].includes(x))
            ) {
                accessorialArray = accessorialArray.filter(
                    x => !["DOCKPU"].includes(x)
                )
            } else {
                accessorialArray = accessorialArray.filter(
                    x => !["LGPU", "RESPU", "LTDPU"].includes(x)
                )
            }
        } else if (accessorialCode === "DOCKDEL") {
            if (
                permanentList?.some(x =>
                    ["LGDEL", "RESDEL", "LTDDEL"].includes(x)
                )
            ) {
                accessorialArray = accessorialArray.filter(
                    x => !["DOCKDEL"].includes(x)
                )
            } else {
                accessorialArray = accessorialArray.filter(
                    x => !["LGDEL", "RESDEL", "LTDDEL"].includes(x)
                )
            }
        } else if (["LGPU", "RESPU", "LTDPU"].includes(accessorialCode)) {
            if (permanentList?.some(x => ["DOCKPU"].includes(x))) {
                accessorialArray = accessorialArray.filter(
                    x => !["LGPU", "RESPU", "LTDPU"].includes(x)
                )
            } else {
                accessorialArray = accessorialArray.filter(x => x !== "DOCKPU")
            }
        } else if (["LGDEL", "RESDEL", "LTDDEL"].includes(accessorialCode)) {
            if (permanentList?.some(x => ["DOCKDEL"].includes(x))) {
                accessorialArray = accessorialArray.filter(
                    x => !["LGDEL", "RESDEL", "LTDDEL"].includes(x)
                )
            } else {
                accessorialArray = accessorialArray.filter(x => x !== "DOCKDEL")
            }
        } else if (accessorialCode === undefined) {
            // if all accessorials are deleted, populate with dock
            const defaultAccessorialArray = []
            if (name === "pickupAccessorials") {
                defaultAccessorialArray.unshift("DOCKPU")
            } else if (name === "deliveryAccessorials") {
                defaultAccessorialArray.unshift("DOCKDEL")
            } else if (name === "selectedResiAccessorials") {
                defaultAccessorialArray.unshift("DOCKDEL")
            } else if (name === "destination.selectedResiAccessorials") {
                defaultAccessorialArray.unshift("DOCKDEL")
            }
            accessorialArray = defaultAccessorialArray
        }
    }

    if (accessorialCode !== "CUSTPU") {
        accessorialArray = accessorialArray.filter(x => x !== "CUSTPU")
    } else {
        accessorialArray = accessorialArray.filter(x => x === "CUSTPU")
    }

    if (accessorialCode !== "CUSTDEL") {
        accessorialArray = accessorialArray.filter(x => x !== "CUSTDEL")
    } else {
        accessorialArray = accessorialArray.filter(x => x === "CUSTDEL")
    }

    if (accessorialCode !== "CUSTDELOFF") {
        accessorialArray = accessorialArray.filter(x => x !== "CUSTDELOFF")
    } else {
        accessorialArray = accessorialArray.filter(x => x === "CUSTDELOFF")
    }

    if (accessorialCode !== "CUSTPUOFF") {
        accessorialArray = accessorialArray.filter(x => x !== "CUSTPUOFF")
    } else {
        accessorialArray = accessorialArray.filter(x => x === "CUSTPUOFF")
    }

    // If a mutually exclusive accessorial is no longer in the list, make sure dock will be added
    if (
        !mutuallyExclusiveAccessorials.some(el => accessorialArray.includes(el))
    ) {
        if (name === "pickupAccessorials") {
            accessorialArray = ["DOCKPU", ...accessorialArray]
        } else if (name === "deliveryAccessorials") {
            accessorialArray = ["DOCKDEL", ...accessorialArray]
        } else if (name === "selectedResiAccessorials") {
            accessorialArray = ["DOCKDEL", ...accessorialArray]
        } else if (name === "destination.selectedResiAccessorials") {
            accessorialArray.unshift("DOCKDEL")
        }
    }

    changeField(name, accessorialArray.join(","))
}

class Accessorials extends Component {
    submitForm = () => {
        this.props.submitForm()
        this.handleClose()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.formValues === undefined) {
            this.setState(state => ({
                protectFromFreezing: this.props.initialValues
                    .protectFromFreezing,
            }))
        }

        if (
            prevProps.originCountry !== this.props.originCountry ||
            prevProps?.formValues?.pickupAccessorials !==
                this.props.formValues.pickupAccessorials
        ) {
            defaultAccessorialLogic(
                "pickupAccessorials",
                this.props.formValues.pickupAccessorials,
                this.props.changeField,
                this.props.originCountry,
                this.props.permanentList
            )
        }

        if (
            prevProps.destinationCountry !== this.props.destinationCountry ||
            prevProps?.formValues?.deliveryAccessorials !==
                this.props.formValues.deliveryAccessorials
        ) {
            defaultAccessorialLogic(
                "deliveryAccessorials",
                this.props.formValues.deliveryAccessorials,
                this.props.changeField,
                this.props.destinationCountry,
                this.props.permanentList
            )
        }
    }

    toggleProtectFromFreezing = () => {
        this.setState(state => ({
            protectFromFreezing: !this.state.protectFromFreezing,
        }))
    }

    onAccessorialSelect = (e = {}, value, name) => {
        let accessorialArray

        if (typeof value === "string") {
            accessorialArray = value.split(",")
        } else {
            accessorialArray = value
        }

        this.props.changeField(name, accessorialArray)
    }

    render() {
        const {
            classes,
            pickupAccessorialsList = [],
            deliveryAccessorialsList = [],
            selectedPickup,
            selectedDelivery,
            changeField,
            origin,
            destination,
            isFreightDirect,
            isFreightDirectReturns,
            language,
            accessorialsLoaded,
            isInBondShipment,
            permanentList,
        } = this.props

        return accessorialsLoaded ? (
            <form className={classes.additionalServices__form}>
                <Grid
                    item
                    container
                    xs={12}
                    className={classes.additionalServices__details}
                >
                    <Grid item container className={classes.accessorials__card}>
                        <AccessorialsCard
                            accessorials={pickupAccessorialsList}
                            selected={selectedPickup}
                            address={origin.address}
                            contact={origin.contact}
                            name={"pickupAccessorials"}
                            defaultHeader="Origin"
                            changeField={changeField}
                            alignment="left"
                            onAccessorialSelect={this.onAccessorialSelect}
                            language={language}
                            isFreightDirect={isFreightDirect}
                            isFreightDirectReturns={isFreightDirectReturns}
                            isInBondShipment={isInBondShipment}
                        />
                    </Grid>
                    <Grid item container className={classes.accessorials__card}>
                        <AccessorialsCard
                            accessorials={deliveryAccessorialsList}
                            selected={selectedDelivery}
                            address={destination.address}
                            contact={destination.contact}
                            name={"deliveryAccessorials"}
                            defaultHeader="Destination"
                            changeField={changeField}
                            alignment="left"
                            onAccessorialSelect={this.onAccessorialSelect}
                            isFreightDirect={isFreightDirect}
                            isFreightDirectReturns={isFreightDirectReturns}
                            language={language}
                            isInBondShipment={isInBondShipment}
                        />
                    </Grid>
                    {!isFreightDirectReturns && (
                        <Grid item xs={12} alignItems="center" justify="center">
                            <Field
                                component={FormSwitch}
                                name="protectFromFreezing"
                                label={
                                    <FormattedMessage
                                        id="accessorials.protectFromFreezing"
                                        defaultMessage="Protect From Freezing"
                                    />
                                }
                                onChange={() =>
                                    this.toggleProtectFromFreezing()
                                }
                            />
                        </Grid>
                    )}
                </Grid>
            </form>
        ) : (
            <Fragment />
        )
    }
}

const accessorialsSelector = formValueSelector("accessorials")
const quoteSelector = formValueSelector("quote")

const transformAddress = from => ({
    street1: from?.address?.street1,
    street2: from?.address?.street2,
    city: from?.address?.city,
    state: from?.address?.state,
    country: from?.address?.country,
    postalCode: from?.address?.postalCode,
    name: from?.companyName ?? from?.name,
})

const determineLocationInfo = (state, prefix) => {
    /* const shippingContact = {
        name: `${get(state, 'user.profile.firstname', '')} ${get(state, 'user.profile.lastname', '')}`,
        phone: get(state, 'user.profile.phone_number.phone_number'),
        email: get(state, 'user.profile.email.email_address'),
    }; */
    const contactInfo = quoteSelector(state, `${prefix}ContactInfo`)
    if (contactInfo) {
        return {
            address: transformAddress(contactInfo),
            contact: loadContact(contactInfo?.contact),
        }
    }
    const postalCode = quoteSelector(state, prefix)
    const city = quoteSelector(state, `${prefix}City`)
    const addressState = quoteSelector(state, `${prefix}State`)
    const name = quoteSelector(state, `${prefix}Name`)
    const street = quoteSelector(state, `${prefix}Street`)
    return {
        address: {
            name,
            city,
            state: addressState,
            postalCode,
            street1: street,
        },
    }
}

const mapStateToProps = state => {
    determineLocationInfo(state, "origin")

    const quoteFormValues = getFormValues("quote")(state)

    const pickupAccessorials =
        state?.search?.pickupAccessorials?.map(x => x?.value) ?? []
    const deliveryAccessorials =
        state?.search?.deliveryAccessorials?.map(x => x?.value) ?? []
    const mutuallyExclusiveAccessorials = [
        "CUSTPU",
        "LGPU",
        "RESPU",
        "LTDPU",
        "LGDEL",
        "RESDEL",
        "LTDDEL",
        "DOCKPU",
        "DOCKDEL",
        "CUSTDEL",
        "CUSTPUOFF",
        "CUSTDELOFF",
    ]

    const language = state.user.profile.preferences.language

    if (
        !mutuallyExclusiveAccessorials.some(el =>
            pickupAccessorials.includes(el)
        )
    )
        pickupAccessorials.unshift("DOCKPU")
    if (
        !mutuallyExclusiveAccessorials.some(el =>
            deliveryAccessorials.includes(el)
        )
    )
        deliveryAccessorials.unshift("DOCKDEL")

    const accessorialsLoaded = state.accessorials.isLoaded

    const basePickupAccessorialList = state.accessorials.list.pickup.filter(
        x =>
            quoteFormValues?.originCountry !== "MX" ||
            formattedPriorityPickupAccessorials.some(y => y?.value === x?.value)
    )

    const baseDeliveryAccessorialList = state.accessorials.list.delivery.filter(
        x =>
            quoteFormValues?.destinationCountry !== "MX" ||
            formattedPriorityDeliveryAccessorials.some(
                y => y?.value === x?.value
            )
    )

    return {
        ...state.search,
        accessorialsLoaded,
        pickupAccessorialsList: sortByPriority(
            basePickupAccessorialList.map(entry => ({
                value: entry?.value,
                label: entry?.label[language],
            })),
            formattedPriorityPickupAccessorials
        ),
        deliveryAccessorialsList: sortByPriority(
            baseDeliveryAccessorialList.map(entry => ({
                value: entry?.value,
                label: entry?.label[language],
            })),
            formattedPriorityDeliveryAccessorials
        ),
        nonspecificAccessorialsList: state.accessorials.list.nonspecific,
        commodityOptionsAccessorials: state.search.commodityOptionsAccessorials,
        selectedPickup: accessorialsSelector(state, "pickupAccessorials"),
        selectedDelivery: accessorialsSelector(state, "deliveryAccessorials"),
        initialValues: {
            pickupAccessorials,
            deliveryAccessorials,
            protectFromFreezing: !!state.search.commodityAccessorials.length,
        },
        formValues: state?.form?.accessorials?.values,
        origin: determineLocationInfo(state, "origin"),
        destination: determineLocationInfo(state, "destination"),
        language,
        isInBondShipment: quoteFormValues?.isInBondShipment,
        originCountry: quoteFormValues?.originCountry,
        destinationCountry: quoteFormValues?.destinationCountry,
    }
}

const mapDispatchToProps = dispatch => ({
    changeField: (field, value) =>
        dispatch(change("accessorials", field, value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "accessorials",
        enableReinitialize: true,
    })(withStyles(styles)(Accessorials))
)

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import Slider from "react-slick"
import { QuoteCardSelector } from "./quoteCard"
import { trackGAEvent } from "../../../../actions/user"
import CircularProgress from "@material-ui/core/CircularProgress"
import { get } from "lodash"
import NoRateOnlyDispatchCard from "../noRateOnlyDispatchCard"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        // backgroundColor: theme.palette.background.paper,
    },
    paper_root: {
        ...theme.mixins.gutters(),
        paddingTop: "10px", // theme.spacing.unit * 2,
        paddingBottom: "10px", // theme.spacing.unit * 2,
    },
    quoteResult__container: {
        margin: "0",
        padding: "10px 50px",
        minHeight: "450px",
        height: "100%",
    },
    prevArrow__container: {
        left: "-60px",
        top: "50%",
        position: "absolute",
        transform: "translateY(-50%)",
        background: "LightGrey",
        "&.disabled": {
            opacity: ".3",
        },
    },
    arrow__icon: {
        width: "30px",
        height: "30px",
        color: "white",
    },
    nextArrow__container: {
        right: "-60px",
        top: "50%",
        position: "absolute",
        transform: "translateY(-50%)",
        background: "LightGrey",
        "&.disabled": {
            opacity: ".3",
        },
    },
    progress: {
        margin: theme.spacing(2),
        top: "50%",
        right: "50%",
        position: "absolute",
    },
    largeIcon: {
        width: 40,
        height: 40,
    },
    card__container_border: {
        borderLeftWidth: "1px",
        borderLeftColor: "black",
        borderLeftStyle: "solid",
        outline: "none",
    },
})

let CarouselPrevArrow = ({ classes, className, onClick }) => (
    <IconButton
        className={classNames(classes.prevArrow__container, {
            disabled: className.split(" ").includes("slick-disabled"),
        })}
        onClick={onClick}
        iconStyle={styles.largeIcon}
    >
        <ChevronLeftIcon className={classes.arrow__icon} />
    </IconButton>
)

CarouselPrevArrow = withStyles(styles)(CarouselPrevArrow)

let CarouselNextArrow = ({ classes, className, style, onClick }) => (
    <IconButton
        className={classNames(classes.nextArrow__container, {
            disabled: className.split(" ").includes("slick-disabled"),
        })}
        onClick={onClick}
        iconStyle={styles.largeIcon}
    >
        <ChevronRightIcon className={classes.arrow__icon} />
    </IconButton>
)

CarouselNextArrow = withStyles(styles)(CarouselNextArrow)

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
    prevArrow: <CarouselPrevArrow />,
    nextArrow: <CarouselNextArrow />,
    left_border: {
        height: "100%",
        borderLeftWidth: "2px",
        borderLeftColor: "black",
        borderLeftStyle: "solid",
    },
}

const QuoteResultCarousel = ({
    rates,
    isFetching,
    trackChange,
    classes,
    isExpired,
    isShipped,
    getBol,
    canCreateShipments,
    isLoaded,
    canViewRateAmounts,
}) => {
    if (isFetching)
        return (
            <CircularProgress
                className={classes.progress}
                size={100}
                color="secondary"
            />
        )
    const rateLengths = rates.map(a => get(a, "rateQuotes.length", 0))
    const maxRateLength =
        rateLengths[rateLengths.indexOf(Math.max(...rateLengths))]
    if (isLoaded && (!rates || !rates.length)) {
        return (
            <div className="error-message">
                <FormattedMessage
                    id="rateResults__noRates"
                    defaultMessage="No available rate quote."
                />
            </div>
        )
    }

    return (
        <Grid item className={classes.quoteResult__container}>
            <Slider
                {...settings}
                beforeChange={(oldIndex, newIndex) =>
                    trackChange(oldIndex, newIndex)
                }
            >
                {rates.map((carrier, i) => (
                    <div
                        key={i}
                        className={
                            (i === 0 ||
                                carrier.groupDate !== rates[i - 1].groupDate) &&
                            classes.card__container_border
                        }
                    >
                        {!!carrier?.rateQuotes?.[0]?.isNonRateable ? (
                            <NoRateOnlyDispatchCard
                                getBol={getBol}
                                isThirdParty={
                                    get(carrier, "rateQuotes[0].direction") ===
                                    "THIRD_PARTY"
                                }
                                carrier={carrier}
                                disabled={
                                    isExpired ||
                                    isShipped ||
                                    !canCreateShipments
                                }
                            />
                        ) : (
                            <QuoteCardSelector
                                cardIndex={i + 1}
                                getBol={getBol}
                                carrier={carrier}
                                disabled={
                                    isExpired ||
                                    isShipped ||
                                    !canCreateShipments
                                }
                                isThirdParty={
                                    get(carrier, "rateQuotes[0].direction") ===
                                    "THIRD_PARTY"
                                }
                                previousDate={
                                    i === 0
                                        ? carrier.groupDate
                                        : rates[i - 1].groupDate
                                }
                                maxRateLength={maxRateLength}
                                canViewRateAmounts={canViewRateAmounts}
                            />
                        )}
                    </div>
                ))}
            </Slider>
        </Grid>
    )
}

QuoteResultCarousel.propTypes = {
    classes: PropTypes.object.isRequired,
    rates: PropTypes.array.isRequired,
}

const mapDispatchToProps = dispatch => ({
    trackChange: (oldIndex, newIndex) =>
        dispatch(
            trackGAEvent(
                "Quote",
                "Carousel",
                `Index ${oldIndex + 1} to ${newIndex + 1}`
            )
        ),
})

export default withStyles(styles)(
    connect(undefined, mapDispatchToProps)(QuoteResultCarousel)
)

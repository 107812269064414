import React, { useContext } from "react"
import InputAdornment from "@material-ui/core/InputAdornment"
import { Grid, IconButton, TextField } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import Search from "@material-ui/icons/Search"
import { OrdersContext } from "../../context/providers/OrdersProvider"
import { Cancel, Clear } from "@material-ui/icons"

const OrderSearch = ({ startDate, endDate }) => {
    const {
        setSearchQuery,
        searchQuery,
        debouncedHandleOrderSearch,
        orderStatus,
    } = useContext(OrdersContext)

    return (
        <Grid>
            <TextField
                name="orders.searchOrder"
                value={searchQuery}
                autoFocus
                onChange={e => {
                    setSearchQuery(e?.target?.value)
                    e.persist()
                    debouncedHandleOrderSearch(e?.target?.value)
                }}
                label={
                    <FormattedMessage
                        id="orders.search"
                        defaultMessage="Search for an order"
                    />
                }
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                    endAdornment: searchQuery ? (
                        <InputAdornment>
                            <IconButton
                                onClick={() => {
                                    setSearchQuery("")
                                    debouncedHandleOrderSearch("")
                                }}
                                size="small"
                            >
                                <Clear />
                            </IconButton>
                        </InputAdornment>
                    ) : null,
                }}
            />
        </Grid>
    )
}

export default OrderSearch

import { defineMessages } from "react-intl"

const messages = defineMessages({
    enus: {
        id: "preferenceLanguage__english",
        defaultMessage: "English",
    },
    frca: {
        id: "preferenceLanguage__french",
        defaultMessage: "Français",
    },
    esmx: {
        id: "preferenceLanguage__spanish",
        defaultMessage: "Español",
    },
})

export const preferenceLanguageOptions = [
    {
        value: "en-us",
        label: messages.enus,
    },
    {
        value: "fr-ca",
        label: messages.frca,
    },
    {
        value: "es-mx",
        label: messages.esmx,
    },
]

export const defaultLanguage = "en-us"

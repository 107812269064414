import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    reduxForm,
    formValueSelector,
    arrayRemove,
    SubmissionError,
    Field,
} from "redux-form"
import { createShare } from "../../../../../actions/share"
import { Markup } from "./markup"
import ShareRecipients from "./recipients"
import SharedQuotesPanel from "./quotesPanel"
import SnackbarContentWrapper from "./snackbar"
import {
    Dialog,
    withStyles,
    Grid,
    Button,
    Snackbar,
    Typography,
    Paper,
} from "@material-ui/core"
import { requestContactAddresses } from "../../../../../actions/address"
import { shareQuoteSuccess } from "../../../../../messages/confirmation-constants"
import { shareQuoteError } from "../../../../../messages/error-constants"
import { emailValidator } from "../../../../../actions/validation"
import { combineValidators } from "revalidate"
import { FormattedMessage } from "react-intl"
import { defaultLanguage } from "../../../../constants/preferenceLanguageOptions"
import GlobalSpinner from "../../../../common/GlobalSpinner"
import { currencyLabelWithoutAmount } from "../../../../util/currencyLabel"
import FormField from "../../../../form/form-field"
import { trackGAEvent } from "../../../../../actions/user"

const styles = theme => ({
    modal__container: {
        minWidth: "720px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
    },
    button__container: {
        marginTop: "10px",
    },
    margin: {
        margin: theme.spacing(1),
    },
    form__container: {
        padding: theme.spacing(2),
    },
    section__container: {
        marginBottom: theme.spacing(5),
    },
})

const getNonDuplicateContacts = (contactList, selectedContacts) => {
    const newContacts = []
    contactList.forEach(c => {
        const emailSelected = selectedContacts.find(
            s => s.emailAddress === c.emailAddress && s.label === c.label
        )
        const newOption = {
            values: [
                ...(!emailSelected
                    ? [
                          {
                              value: c.emailAddress,
                              type: "email",
                          },
                      ]
                    : []),
            ],
            label: c.label,
        }
        if (newOption.values.length) newContacts.push(newOption)
    })
    return newContacts
}

class RateShareModalPresentation extends Component {
    state = {
        snackbarOpen: false,
        snackbarVariant: "",
        isLoading: false,
    }

    componentDidMount() {
        this.props.requestContactAddresses()
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        this.setState({ snackbarOpen: false })
    }

    handleSnackbarOpen = variant => {
        this.setState({ snackbarOpen: true, snackbarVariant: variant })
    }

    handleDelete = (contact, i) => {
        const recipientIndex = this.props.recipients.indexOf(
            c =>
                contact.value.type === c.value.type && c.label === contact.label
        )
        this.props.handleDelete(recipientIndex, i)
    }

    sendShare = async values => {
        this.setState({ isLoading: true })
        this.props.trackGA(
            this.props.gaCategory,
            "Rate Share Modal: Share button click"
        )
        try {
            await this.props.createShare(values, true, this.props.selectedRates)
            this.props.reset()
            this.props.handleClose()
            this.handleSnackbarOpen("success")
        } catch (err) {
            this.props.handleClose()
            this.handleSnackbarOpen("error")
        } finally {
            this.setState({ isLoading: false })
        }
    }

    render() {
        const {
            selections,
            selectedContacts = [],
            recipients = [],
            contacts,
            classes,
            open,
            handleClose,
            markup,
            handleSubmit,
            handleDelete,
            form,
            error,
            gaCategory,
            trackGA,
        } = this.props
        const { snackbarOpen, snackbarVariant, isLoading } = this.state
        const newContacts =
            recipients && getNonDuplicateContacts(contacts, recipients)
        const currencyCode = currencyLabelWithoutAmount(
            selections?.[0]?.preferredCurrencyCode
        )
        return (
            <Fragment>
                {isLoading && <GlobalSpinner />}
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={snackbarOpen}
                    autoHideDuration={2000}
                    onClose={this.handleSnackbarClose}
                >
                    <SnackbarContentWrapper
                        variant={snackbarVariant}
                        className={classes.margin}
                        message={
                            snackbarVariant === "success"
                                ? shareQuoteSuccess
                                : shareQuoteError
                        }
                        onClose={this.handleSnackbarClose}
                    />
                </Snackbar>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    className={classes.modal}
                    fullWidth
                    maxWidth={"md"}
                >
                    <div className={`${classes.modal__container} share-modal`}>
                        <form onSubmit={handleSubmit(this.sendShare)}>
                            <Grid
                                container
                                className={classes.button__container}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.section__container}
                                >
                                    <ShareRecipients
                                        selected={selectedContacts}
                                        contacts={newContacts}
                                        onDelete={handleDelete}
                                        formName={form}
                                        gaCategory={gaCategory}
                                    />
                                </Grid>
                                <Grid
                                    className={classes.section__container}
                                    container
                                    item
                                    xs={12}
                                    justifyContent="space-between"
                                >
                                    <Grid
                                        item
                                        container
                                        xs={6}
                                        alignItems="center"
                                        className={classes.subsection}
                                    >
                                        <Paper
                                            className={classes.form__container}
                                        >
                                            <Grid container xs={12}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5">
                                                        <FormattedMessage
                                                            id="documents.share__personalMessage"
                                                            defaultMessage="Add a personal message"
                                                        />
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.input__container
                                                    }
                                                >
                                                    <Field
                                                        name="customMessage"
                                                        label=""
                                                        component={FormField}
                                                        category={gaCategory}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>

                                    <Grid item xs={5}>
                                        <Markup
                                            currencyCode={currencyCode}
                                            gaCategory={gaCategory}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.section__container}
                                >
                                    <SharedQuotesPanel
                                        selections={selections}
                                        markup={markup}
                                        currencyCode={currencyCode}
                                    />
                                </Grid>

                                {error && (
                                    <Typography variant="body2" color="error">
                                        {error}
                                    </Typography>
                                )}
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    justifyContent="space-between"
                                    className={classes.button__container}
                                >
                                    <Button
                                        id="cancelShare"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => {
                                            this.props.reset()
                                            this.props.handleClose()
                                            this.props.trackGA(
                                                gaCategory,
                                                "Rate Share Modal: Cancel Click"
                                            )
                                        }}
                                        className={classes.leftButton}
                                    >
                                        <FormattedMessage
                                            id="generalTerms__cancel"
                                            defaultMessage="Cancel"
                                        />
                                    </Button>
                                    <Button
                                        id="submitShare"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={selectedContacts.length < 1}
                                    >
                                        <FormattedMessage
                                            id="rateResults.share__share"
                                            defaultMessage="Share"
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Dialog>
            </Fragment>
        )
    }
}

RateShareModalPresentation.propTypes = {
    onRemoveClick: PropTypes.func.isRequired,
    referenceNumber: PropTypes.string.isRequired,
    onReferenceNumberChange: PropTypes.func.isRequired,
    showUpcharge: PropTypes.bool.isRequired,
    gaCategory: PropTypes.string,
}

const selector = formValueSelector("shareQuote")

const mapRecipients = contacts => {
    const recipients = []
    contacts.forEach(contact => {
        const contactInfo = contact.contact
        recipients.push({
            emailAddress: contactInfo?.email?.emailAddress,
            label: contactInfo?.name,
        })
    })
    return recipients
}

const validation = combineValidators({
    newEmailRecipient: emailValidator({
        field: {
            id: "generalTerms__emailAddress",
            defaultMessage: "Email Address",
        },
    }),
})

const mapStateToProps = (state, props) => {
    const setToArray = Array.from(props.selectedRates)
    return {
        selections: setToArray.map(id => {
            return props.activeQuote?.result?.rateQuotes.find(
                rateQuote => rateQuote._id === id
            )
        }),
        contacts: mapRecipients(state.addressBook.addresses),
        recipients: selector(state, "recipients"),
        selectedContacts: selector(state, "mergedRecipients"),
        markup: selector(state, "markupType", "markupAmount"),
        initialValues: {
            markupAmount: 0,
            markupType: false,
            recipients: [],
            language: defaultLanguage,
        },
        referenceNumber: state.share.referenceNumber,
        contactQuery: state.share.contactQuery,
        showUpcharge: !!state.markup.markupAmount,
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    trackGA: (category, action, label) =>
        dispatch(trackGAEvent(category, action, label)),
    requestContactAddresses: () => dispatch(requestContactAddresses()),
    handleDelete: (recipientIndex, i) => {
        if (recipientIndex >= 0)
            dispatch(arrayRemove("shareQuote", "recipients", recipientIndex))
        dispatch(arrayRemove("shareQuote", "mergedRecipients", i))
    },
    createShare: async (values, isBookShipmentWorkflow, selectedRates) => {
        const { mergedRecipients, newEmailRecipient } = values
        if (!mergedRecipients?.length) {
            throw new SubmissionError({
                _error: (
                    <FormattedMessage
                        id="rateResults.share__error1"
                        defaultMessage="Please add at least one user"
                    />
                ),
            })
        }
        if (newEmailRecipient?.length) {
            throw new SubmissionError({
                newEmailRecipient: (
                    <FormattedMessage
                        id="locations.addUserModal__lastEmailError"
                        defaultMessage="It looks like you forgot to add the last email address"
                    />
                ),
            })
        }
        return dispatch(
            createShare(
                values,
                isBookShipmentWorkflow,
                selectedRates,
                props?.shipmentId
            )
        )
    },
})

export const RateShareModal = withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "shareQuote", // a unique identifier for this form
            enableReinitialize: true,
            validate: validation,
        })(RateShareModalPresentation)
    )
)

import { Field, formValues } from "redux-form"
import React, { Fragment } from "react"
import { Grid } from "@material-ui/core"
import FormField from "../form/form-field"
import { FormattedMessage, defineMessages } from "react-intl"
import FormSelectAutocomplete from "../form/form-select-autocomplete"
import { formattedTaxIdentificationType } from "../book/SupportDocumentationForm"
import FormSelect from "../form/form-select"
import { supportedCountryCodes } from "../../misc"
import FormZipCode from "../form/form-zip-code"
import FormSelectCity from "../form/form-select-city"
import normalizePhone from "../util/normalizePhone"
import { combineValidators, composeValidators } from "revalidate"
import {
    countryPhoneValidator,
    emailValidator,
    isRequired,
    isRequiredIf,
} from "../../actions/validation"

export const contactDetailsFormMessages = defineMessages({
    taxId: {
        id: "commercialInvoice.tax__id",
        defaultMessage: "Tax ID#",
    },
    taxType: {
        id: "commercialInvoice.importer__select__tax__type",
        defaultMessage: "Tax Identification Type",
    },
    country: {
        id: "commercialInvoice.country",
        defaultMessage: "Country",
    },
    postalCode: {
        id: "commercialInvoice.zipPostalCode",
        defaultMessage: "Zip/Postal Code",
    },
    city: {
        id: "certificateOfOrigin.city",
        defaultMessage: "City",
    },
    state: {
        id: "commercialInvoice.state",
        defaultMessage: "State/Province",
    },
    contactName: {
        id: "commercialInvoice.contact__name",
        defaultMessage: "Contact Name",
    },
    phone: {
        id: "commercialInvoice.phone",
        defaultMessage: "Phone",
    },
    extension: {
        id: "commercialInvoice.extension",
        defaultMessage: "Extension",
    },
    email: {
        id: "commercialInvoice.email",
        defaultMessage: "Email",
    },
    companyName: {
        id: "commercialInvoice.company__name",
        defaultMessage: "Company Name",
    },
    street1: {
        id: "commercialInvoice.address1",
        defaultMessage: "Address 1",
    },
    street2: {
        id: "commercialInvoice.address2",
        defaultMessage: "Address 2",
    },
})

export const contactDetailsValidation = combineValidators({
    taxIdentification: {
        taxIdentificationType: isRequiredIf(
            values => values?.taxIdentification?.number
        )({
            field: contactDetailsFormMessages.taxType,
        }),
        number: isRequiredIf(
            values => values?.taxIdentification?.taxIdentificationType
        )({
            field: contactDetailsFormMessages.taxId,
        }),
    },
    name: isRequired({
        field: contactDetailsFormMessages.companyName,
    }),
    address: {
        street1: isRequiredIf(values => !values?.address?.street2)({
            field: contactDetailsFormMessages.street1,
        }),
        street2: isRequiredIf(values => !values?.address?.street1)({
            field: contactDetailsFormMessages.street2,
        }),
        postalCode: isRequired({
            field: contactDetailsFormMessages.postalCode,
        }),
        city: isRequired({
            field: contactDetailsFormMessages.city,
        }),
        country: isRequired({
            field: contactDetailsFormMessages.country,
        }),
        state: isRequired({
            field: contactDetailsFormMessages.state,
        }),
    },
    contact: {
        name: isRequired({
            field: contactDetailsFormMessages.contactName,
        }),
        phone: {
            phone_number: composeValidators(
                isRequired,
                countryPhoneValidator("address.country")
            )({
                field: contactDetailsFormMessages.phone,
            }),
        },
        email: {
            email_address: composeValidators(
                isRequired,
                emailValidator
            )({
                field: contactDetailsFormMessages.email,
            }),
        },
    },
})

const ContactDetails = ({
    city,
    postalCode,
    country,
    taxIdentification,
    address1,
    address2,
    includeContact,
    gaCategory,
}) => (
    <Fragment>
        <Grid item container sm={12} md={6}>
            <Field
                name="taxIdentification.number"
                component={FormField}
                type="text"
                label={
                    <FormattedMessage {...contactDetailsFormMessages.taxId} />
                }
                required={taxIdentification?.taxIdentificationType}
                category={gaCategory}
            />
        </Grid>
        <Grid item container sm={12} md={6}>
            <Field
                component={FormSelectAutocomplete}
                name="taxIdentification.taxIdentificationType"
                options={formattedTaxIdentificationType}
                label={
                    <FormattedMessage {...contactDetailsFormMessages.taxType} />
                }
                required={taxIdentification?.number}
                category={gaCategory}
            />
        </Grid>
        <Grid item container sm={12} md={6}>
            <Field
                name="address.country"
                component={FormSelect}
                type="text"
                label={
                    <FormattedMessage {...contactDetailsFormMessages.country} />
                }
                options={supportedCountryCodes}
                maxLength="30"
                required
                category={gaCategory}
            />
        </Grid>

        <Grid item container sm={12} md={6}>
            <Field
                name="address.postalCode"
                component={FormZipCode}
                type="text"
                label={
                    <FormattedMessage
                        {...contactDetailsFormMessages.postalCode}
                    />
                }
                country={country}
                required
                category={gaCategory}
            />
        </Grid>
        <Grid item container sm={12} md={6}>
            <Field
                name="address.city"
                component={FormSelectCity}
                label={
                    <FormattedMessage {...contactDetailsFormMessages.city} />
                }
                country={country}
                postalCode={postalCode}
                defaultCity={city}
                required
                category={gaCategory}
            />
        </Grid>
        <Grid item container sm={12} md={6}>
            <Field
                name="address.state"
                component={FormField}
                type="text"
                label={
                    <FormattedMessage {...contactDetailsFormMessages.state} />
                }
                required
                disabled
                category={gaCategory}
            />
        </Grid>
        {includeContact && (
            <Fragment>
                <Grid item container sm={12} md={6}>
                    <Field
                        name="contact.name"
                        component={FormField}
                        type="text"
                        label={
                            <FormattedMessage
                                {...contactDetailsFormMessages.contactName}
                            />
                        }
                        required
                        category={gaCategory}
                    />
                </Grid>
                <Grid item container sm={12} md={6}>
                    <Field
                        name="contact.phone.phone_number"
                        component={FormField}
                        type="text"
                        label={
                            <FormattedMessage
                                {...contactDetailsFormMessages.phone}
                            />
                        }
                        normalize={normalizePhone(country)}
                        required
                        category={gaCategory}
                    />
                </Grid>
                <Grid item container sm={12} md={6}>
                    <Field
                        name="contact.email.email_address"
                        component={FormField}
                        type="email"
                        label={
                            <FormattedMessage
                                {...contactDetailsFormMessages.email}
                            />
                        }
                        required
                        category={gaCategory}
                    />
                </Grid>
            </Fragment>
        )}
        <Grid item container sm={12} md={6}>
            <Field
                name="name"
                component={FormField}
                type="text"
                label={
                    <FormattedMessage
                        {...contactDetailsFormMessages.companyName}
                    />
                }
                required
                category={gaCategory}
            />
        </Grid>
        <Grid item container sm={12} md={6}>
            <Field
                name="address.street1"
                component={FormField}
                type="text"
                label={
                    <FormattedMessage {...contactDetailsFormMessages.street1} />
                }
                required={!address2}
                category={gaCategory}
            />
        </Grid>
        <Grid item container sm={12} md={6}>
            <Field
                name="address.street2"
                component={FormField}
                type="text"
                label={
                    <FormattedMessage {...contactDetailsFormMessages.street2} />
                }
                required={!address1}
                category={gaCategory}
            />
        </Grid>
    </Fragment>
)

export default formValues({
    country: "address.country",
    city: "address.city",
    taxIdentification: "taxIdentification",
    postalCode: "address.postalCode",
    address1: "address.street1",
    address2: "address.street2",
})(ContactDetails)

import { Component } from "react"
import moment from "moment"
import { get } from "lodash"
import ErrorRedirectScreen from "./ErrorRedirectScreen"

export class OuterErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true })

        const description = [
            moment.utc().format(),
            error,
            get(info, "componentStack"),
        ]
            .filter(x => x)
            .join(" ")
    }

    render() {
        if (this.state.hasError) {
            return <ErrorRedirectScreen />
        }
        return this.props.children
    }
}

import {
    CHANGE_DASHBOARD_TAB,
    REMOVE_SEARCH,
    SAVE_SEARCH,
    SEARCH_TERM_CHANGE,
    TOGGLE_TABLE_FILTER,
    SET_TABLE_FILTER_TO_SHIPMENTS,
} from "../actions/index"
import {
    APPLY_ACTIVE_DASHBOARD_TILE,
    APPLY_DASHBOARD_FILTERS,
} from "../actions/dashboard"
import { removeItemFromArray } from "./index"

export function dashboard(
    state = {
        active: "",
        activeIndex: null,
        filters: {
            shipments: true,
            quotes: false,
        },
        search: {
            current: "",
            list: [],
        },
    },
    action
) {
    switch (action.type) {
        case CHANGE_DASHBOARD_TAB:
            return {
                ...state,
                active: action.active,
                activeIndex: action.index,
            }
        case SEARCH_TERM_CHANGE:
            return {
                ...state,
                search: {
                    ...state.search,
                    current: action.value,
                },
            }
        case SAVE_SEARCH:
            return {
                ...state,
                active: "search",
                activeIndex: state.search.list.length,
                search: {
                    ...state.search,
                    list: [...state.search.list, state.search.current],
                    current: "",
                },
            }
        case REMOVE_SEARCH:
            return {
                ...state,
                activeIndex:
                    Number.isInteger(state.activeIndex) &&
                    state.search.list.length > 1
                        ? Math.min(state.search.list.length - 2, action.index)
                        : null,
                search: removeItemFromArray(state.search, "list", action.index),
            }
        case TOGGLE_TABLE_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.kind]: !state.filters[action.kind],
                },
            }
        case SET_TABLE_FILTER_TO_SHIPMENTS:
            return {
                ...state,
                filters: {
                    quotes: false,
                    shipments: true,
                },
            }
        case APPLY_DASHBOARD_FILTERS:
            return {
                ...state,
                dashboardFilters: action.data,
            }
        case APPLY_ACTIVE_DASHBOARD_TILE:
            return {
                ...state,
                activeDashboardTile: action.value,
            }
        default:
            return state
    }
}

import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { get } from "lodash"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import { Select, MenuItem } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    form__container: {
        padding: "3px",
        width: "100%",
        overflow: "-moz-hidden-unscrollable",
    },
    "@global": {
        ".Select-menu-outer": {
            zIndex: 999,
        },
    },
    form__control: {
        width: "100%",
    },
})

const FormSelectLanguage = ({
    input,
    label,
    options,
    classes,
    meta: { touched, error },
    required,
    id,
    formControlId,
    InputProps,
    InputLabelProps,
    InputHelperProps,
    selectClasses,
    inputClasses,
    billTo,
    MenuProps,
    dataTestId,
    ...rest
}) => (
    <Grid
        item
        container
        className={`${classnames({
            "form-field": true,
            "form-field-touched": touched,
            "form-field-invalid": touched && error,
            "form-field-valid": !error,
        })} ${classes.form__container}`}
    >
        <Grid item container direction="column">
            <Select
                style={{
                    backgroundColor: "#D4D4D4",
                    paddingLeft: "5px",
                }}
                dataTestId={dataTestId}
                {...rest}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                    PopoverClasses: {
                        styles: {
                            padding: "90px",
                        },
                    },
                    PaperProps: {
                        style: {
                            zIndex: "9999",
                        },
                    },
                }}
            >
                {options.map((option, index) => (
                    <MenuItem
                        key={index}
                        value={option.value}
                        disabled={option.disabled}
                        id={`${id}-${option.value}`}
                    >
                        {get(option, "label.id") ? (
                            <FormattedMessage {...option.label} />
                        ) : (
                            option.label
                        )}
                    </MenuItem>
                ))}
            </Select>
        </Grid>
    </Grid>
)

FormSelectLanguage.propTypes = {
    label: PropTypes.string,
    meta: PropTypes.shape({
        // eslint-disable-next-line react/no-unused-prop-types
        touched: PropTypes.bool,
        // eslint-disable-next-line react/no-unused-prop-types
        error: PropTypes.string,
    }),
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.object,
}

FormSelectLanguage.defaultProps = {
    meta: {},
    classes: {},
    label: undefined,
    input: undefined,
}

export default withStyles(styles)(FormSelectLanguage)

import React from "react"
import { Grid, Typography, Paper, Button } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

const LocationsNav = ({
    classes,
    onAddLocationSelect,
    defaultLocationId,
    locations,
    handleActiveLocationChange,
    activeLocationIndex,
}) => {
    return (
        <Grid container xs={2} className={classes.leftNav__container}>
            <Paper className={classes.leftNav__paper} elevation={2}>
                <Grid item container className={classes.leftNav__title}>
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="locations.menu__overview"
                            defaultMessage="Location Overview"
                        />
                    </Typography>
                </Grid>
                <Grid className={classes.leftNav__locations} elevation={2}>
                    <Grid item container id="locationLeft">
                        <Grid item container>
                            {locations.map((location, i) => {
                                const isFreightDirect =
                                    location?.locationType === "FEDEX_DIRECT"

                                const isFreightDirectReturns =
                                    location?.locationType ===
                                    "FEDEX_DIRECT_RETURNS"

                                const isFreightBox =
                                    location?.locationType ===
                                    "FEDEX_FREIGHT_BOX"

                                return (
                                    <Grid
                                        item
                                        container
                                        alignContent="center"
                                        wrap={
                                            i === activeLocationIndex
                                                ? "nowrap"
                                                : "wrap"
                                        }
                                        id={`leftNavLocation__${i}`}
                                        className={
                                            i === activeLocationIndex
                                                ? classes.leftNav__location__active
                                                : classes.leftNav__location
                                        }
                                        onClick={() =>
                                            handleActiveLocationChange(i)
                                        }
                                    >
                                        <Grid
                                            item
                                            className={
                                                i === activeLocationIndex &&
                                                classes.shadow__hider
                                            }
                                        >
                                            <Grid
                                                item
                                                container
                                                alignItems="center"
                                            >
                                                <Typography
                                                    variant="body2"
                                                    className={
                                                        classes.location__name
                                                    }
                                                    color="inherit"
                                                    id={`leftNavLocation__${i}__name`}
                                                >
                                                    {`${
                                                        location?.cpgName
                                                            ? location.cpgName
                                                            : location
                                                                  ?.shippingAddress
                                                                  ?.name
                                                    }`}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    className={
                                                        classes.default__tag
                                                    }
                                                >
                                                    {defaultLocationId ===
                                                        location?._id && (
                                                        <FormattedMessage
                                                            id="locations__default"
                                                            defaultMessage="(Default)"
                                                        />
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="column">
                                                {location?.fedexFreightAccount && (
                                                    <Typography
                                                        id={`leftNavLocation__${i}__freightAccount`}
                                                        variant="caption"
                                                    >
                                                        {isFreightDirect ||
                                                        isFreightDirectReturns
                                                            ? [
                                                                  <FormattedMessage
                                                                      id="locations__FXFDShipperAccountNumber"
                                                                      defaultMessage="FXFD Shipper Account #"
                                                                  />,
                                                                  ": ",
                                                                  location?.fedexFreightAccount,
                                                              ]
                                                            : [
                                                                  <FormattedMessage
                                                                      id="locations__shipperAccountNumber"
                                                                      defaultMessage="Shipper Account #"
                                                                  />,
                                                                  ": ",
                                                                  location?.fedexFreightAccount,
                                                              ]}
                                                    </Typography>
                                                )}
                                                {location?.fedexBillToAccount && (
                                                    <Typography
                                                        id={`leftNavLocation__${i}__billToAccount`}
                                                        variant="caption"
                                                    >
                                                        {isFreightDirect ||
                                                        isFreightDirectReturns
                                                            ? [
                                                                  <FormattedMessage
                                                                      id="locations__FXFDThirdPartyAccountNumber"
                                                                      defaultMessage="FXFD Third Party Account #"
                                                                  />,
                                                                  ": ",
                                                                  location?.fedexBillToAccount,
                                                              ]
                                                            : [
                                                                  <FormattedMessage
                                                                      id="locations__thirdPartyAccountNumber"
                                                                      defaultMessage="Third Party Account #"
                                                                  />,
                                                                  ": ",
                                                                  location?.fedexBillToAccount,
                                                              ]}
                                                    </Typography>
                                                )}
                                                {isFreightDirect && (
                                                    <Typography
                                                        variant="caption"
                                                        color="primary"
                                                    >
                                                        <FormattedMessage
                                                            id="locations.users__freightDirect"
                                                            defaultMessage="FedEx Freight Direct"
                                                        />
                                                    </Typography>
                                                )}
                                                {isFreightBox && (
                                                    <Typography
                                                        variant="caption"
                                                        color="primary"
                                                    >
                                                        <FormattedMessage
                                                            id="locations.users__freightBox"
                                                            defaultMessage="FedEx Freight Box"
                                                        />
                                                    </Typography>
                                                )}
                                                {isFreightDirectReturns && (
                                                    <Typography
                                                        variant="caption"
                                                        color="primary"
                                                    >
                                                        <FormattedMessage
                                                            id="locations.users__freightDirectReturns"
                                                            defaultMessage="FedEx Freight Direct Returns"
                                                        />
                                                    </Typography>
                                                )}
                                                {location?.users[0]?.role ===
                                                    "requested" && (
                                                    <Typography
                                                        variant="caption"
                                                        color="secondary"
                                                    >
                                                        <FormattedMessage
                                                            id="locations.users__requestedAccess"
                                                            defaultMessage="Requested Access"
                                                        />
                                                    </Typography>
                                                )}
                                                {location?.users[0]?.permissions
                                                    ?.suspended?.value && (
                                                    <Typography
                                                        variant="caption"
                                                        color="secondary"
                                                    >
                                                        <FormattedMessage
                                                            id="locations.users__inactive"
                                                            defaultMessage="Inactive"
                                                        />
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    justify="center"
                    className={classes.addLocation__button}
                >
                    <Button
                        color="primary"
                        onClick={onAddLocationSelect}
                        id="addLocationButton"
                    >
                        +{" "}
                        <FormattedMessage
                            id="locations.menu__addLocation"
                            defaultMessage="Add Location"
                        />
                    </Button>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default LocationsNav

import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepButton from "@material-ui/core/StepButton"
import Typography from "@material-ui/core/Typography"
import Tooltip from "@material-ui/core/Tooltip"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    root: {
        height: "60px",
        padding: "4px 8px 4px 8px",
        width: "100%",
        // margin: '0 3%'
    },
    button: {
        marginRight: theme.spacing(1),
        display: "none",
    },
    step__button: {
        "&:hover": {
            backgroundColor: "#F4F4F4",
        },
    },
    lightTooltip: {
        background: theme.palette.common.white,
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    change__button: {
        color: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.secondary.main,
        },
    },
})

class QuoteStepper extends React.Component {
    constructor(props) {
        super(props)
        this.state = { steps: this.calculateSteps(props) }
    }

    componentWillReceiveProps(newProps) {
        this.setState({ steps: this.calculateSteps(newProps) })
    }

    calculateSteps = props => [
        {
            label: props.isLoaded ? (
                <FormattedMessage
                    id="stepper__editDetails"
                    defaultMessage="Edit shipment details"
                />
            ) : (
                <FormattedMessage
                    id="stepper__enterDetails"
                    defaultMessage="Enter your shipment details"
                />
            ),
            disabled: false,
        },
        {
            label: (
                <FormattedMessage id="stepper__rates" defaultMessage="Rates" />
            ),
            disabled:
                props.isQuickQuote || !props.isLoaded || props.isReturnMode,
        },
        {
            label: <FormattedMessage id="stepper__bol" defaultMessage="BOL" />,
            disabled: true,
        },
    ]

    render() {
        const { classes, currentStep, onStepClick } = this.props
        const { steps } = this.state
        const stepperClasses = { root: classes.root }

        return (
            <Card className={classes.root}>
                <Stepper
                    classes={stepperClasses}
                    nonLinear
                    activeStep={currentStep}
                >
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepButton
                                className={classes.step__button}
                                completed={false}
                                onClick={() => onStepClick(index)}
                                disabled={step.disabled}
                            >
                                <Grid item container justify="center">
                                    <Typography variant="body2">
                                        {step.label}
                                    </Typography>
                                </Grid>
                                {index === 1 && currentStep >= 1 && (
                                    <Tooltip
                                        title={this.props.children}
                                        classes={{
                                            tooltip: classes.lightTooltip,
                                        }}
                                    >
                                        <Grid item container justify="center">
                                            <Typography
                                                variant="caption"
                                                color="primary"
                                                className={
                                                    classes.change__button
                                                }
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    onStepClick(index - 1)
                                                }}
                                            >
                                                <FormattedMessage
                                                    id="quoteStepper__change"
                                                    defaultMessage="Change"
                                                />
                                            </Typography>
                                        </Grid>
                                    </Tooltip>
                                )}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </Card>
        )
    }
}

QuoteStepper.propTypes = {
    classes: PropTypes.object,
    step: PropTypes.number,
    onStepClick: PropTypes.func,
}

export default withStyles(styles)(QuoteStepper)

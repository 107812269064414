import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import { Field } from "redux-form"
import FormCheckbox from "../../../form/form-checkbox"
import FormField from "../../../form/form-field"
import { currencyLabel } from "../../../util/currencyLabel"

const useStyles = makeStyles({
    underline: {
        textDecoration: "underline",
        paddingBottom: "10px",
    },
    totalGrid: {
        marginTop: "12px",
    },
})

const OptionalIdentifiers = ({
    category,
    intl,
    proEnabled,
    selectedRate = {},
    changeField,
    shouldShowAES,
    totalPrice = {},
    selectedLocation = {},
}) => {
    const { carrierCode } = selectedRate
    const classes = useStyles()

    const handleProSwitch = value => {
        if (value) return
        changeField("identifiers.proNumber", "")
    }

    return (
        <Grid item container>
            <Grid item container justify="center">
                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="book.optionalIdentifiers__title"
                        defaultMessage="Review Shipment Identifiers"
                    />
                </Typography>
            </Grid>
            <Grid item container alignItems="center">
                {carrierCode !== "FXFE" && carrierCode !== "FXNL" && (
                    <Grid item container xs={4} alignItems="center">
                        <Field
                            category={category}
                            name="identifiers.proEnabled"
                            label={
                                <FormattedMessage
                                    id="generalTerms__trackingId__pre"
                                    defaultMessage="Pre-assign Tracking ID"
                                />
                            }
                            component={FormCheckbox}
                            enableSwitch
                            onChange={(e, value) => handleProSwitch(value)}
                        />
                    </Grid>
                )}
                {carrierCode !== "FXFE" && carrierCode !== "FXNL" && (
                    <Grid item container xs={8} alignItems="center">
                        <Field
                            category={category}
                            name="identifiers.proNumber"
                            label={
                                <FormattedMessage
                                    id="generalTerms__trackingId"
                                    defaultMessage="Tracking ID"
                                />
                            }
                            component={FormField}
                            disabled={!proEnabled}
                        />
                    </Grid>
                )}
            </Grid>

            <Grid item container xs={4}>
                <Field
                    category={category}
                    name="identifiers.bolNumber"
                    label={
                        <FormattedMessage
                            id="orderDetails.label__BOL"
                            defaultMessage="Bill of Lading ID"
                        />
                    }
                    placeholder={intl.formatMessage({
                        id: "orderDetails.placeholder__BOL",
                        defaultMessage: "Enter Bill of Lading ID",
                    })}
                    component={FormField}
                    required={true}
                    autoFocus
                />
            </Grid>
            <Grid item container xs={4}>
                <Field
                    category={category}
                    name="identifiers.orderNumber"
                    label={[
                        <FormattedMessage
                            id="generalTerms__PO"
                            defaultMessage="PO"
                        />,
                        " #",
                    ]}
                    placeholder={
                        intl.formatMessage({
                            id: "orderDetails.placeholder__PO",
                            defaultMessage: "Enter PO",
                        }) + " #"
                    }
                    component={FormField}
                    required={
                        selectedLocation?.shipmentRestrictions
                            ?.requirePoNumber ?? false
                    }
                />
            </Grid>
            <Grid item container xs={4}>
                <Field
                    category={category}
                    name="identifiers.referenceNumber"
                    label={
                        <FormattedMessage
                            id="generalTerms__referenceId"
                            defaultMessage="Reference ID"
                        />
                    }
                    placeholder={intl.formatMessage({
                        id: "orderDetails.placeholder__referenceId",
                        defaultMessage: "Enter Reference ID",
                    })}
                    component={FormField}
                    required={
                        selectedLocation?.shipmentRestrictions?.requireRefId ??
                        false
                    }
                />
            </Grid>

            {shouldShowAES && (
                <Grid item container xs={12}>
                    <Field
                        category={category}
                        name="identifiers.aesCitationNumber"
                        label={
                            <FormattedMessage
                                id="orderDetails.label__AES"
                                defaultMessage="AES Citation Number"
                            />
                        }
                        placeholder={intl.formatMessage({
                            id: "orderDetails.placeholder__AES",
                            defaultMessage: "Enter AES Citation Number",
                        })}
                        component={FormField}
                    />
                </Grid>
            )}
            {totalPrice?.display && (
                <Grid item container xs={12} className={classes.totalGrid}>
                    <Typography
                        id="itemsTotalPriceIdentifiersLabel"
                        variant="subtitle1"
                    >
                        <FormattedMessage
                            id="items__total"
                            defaultMessage="Total Price: {totalPrice}"
                            values={{
                                totalPrice: currencyLabel(
                                    totalPrice?.preferredCurrencyCode,
                                    totalPrice?.amount
                                ),
                            }}
                        />
                    </Typography>
                </Grid>
            )}
        </Grid>
    )
}

export default injectIntl(OptionalIdentifiers)

import React, { Fragment, useEffect, useState } from "react"
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Typography,
    withStyles,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import PricingTable from "../PricingTable"
import HawaiiPricingModal from "./HawaiiPricingModal"
import { getHawaiiPricing } from "../../../../../actions/offshoreRating"
import PricingTableSimple from "../PricingTableSimple"
import { styles } from "../styles"
import NavigationDropdown from "../NavigationDropdown"

const HawaiiPricingTile = ({
    classes,
    getHawaiiPricing,
    defaultPricing,
    customPricing,
    location,
    trackGA,
}) => {
    const [activeFilter, setActiveFilter] = useState("oceanCharges")
    const [editPricingOpen, setEditPricingOpen] = useState(false)

    useEffect(() => {
        getHawaiiPricing()
    }, [])

    return (
        <Fragment>
            <HawaiiPricingModal
                classes={classes}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
                open={editPricingOpen}
                setOpen={setEditPricingOpen}
                defaultPricing={defaultPricing}
                customPricing={customPricing}
                location={location}
                trackGA={trackGA}
            />
            <Card className={classes.card}>
                <CardHeader
                    classes={{ action: classes.card__action }}
                    title={
                        <Typography variant="h6">
                            <FormattedMessage
                                id="locations.hawaiiPricing"
                                defaultMessage="Hawaii Pricing"
                            />
                        </Typography>
                    }
                    action={
                        <Button
                            color="primary"
                            id="puertoRicoCard__editRates"
                            onClick={() => {
                                setEditPricingOpen(true)
                                trackGA(
                                    "Hawaii",
                                    "Opened Hawaii Pricing",
                                    "Edit Pricing"
                                )
                            }}
                        >
                            <FormattedMessage
                                id="locations.puertoRicoCard__editPricing"
                                defaultMessage="Edit Pricing"
                            />
                        </Button>
                    }
                />
                <CardContent classes={{ root: classes.card__content }}>
                    <NavigationDropdown
                        activeFilter={activeFilter}
                        setActiveFilter={setActiveFilter}
                        classes={classes}
                        offshoreState={"HI"}
                    />
                    {activeFilter === "oceanCharges" ? (
                        <PricingTable
                            open={editPricingOpen}
                            classes={classes}
                            defaultPricing={
                                defaultPricing?.oceanCharges
                                    ? [defaultPricing?.oceanCharges]
                                    : null
                            }
                            customPricing={[customPricing?.oceanCharges]}
                            columns={[
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.grossDiscount"
                                            defaultMessage="Gross Discount"
                                        />
                                    ),
                                    value: "grossDiscount",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.minimumCharge"
                                            defaultMessage="Minimum Charge"
                                        />
                                    ),
                                    value: "minimumCharge",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.ratePerCuFt"
                                            defaultMessage="Rate per CuFt"
                                        />
                                    ),
                                    value: "ratePerCuFt",
                                },
                            ]}
                        />
                    ) : null}
                    {activeFilter === "interIslandTransferFee" ? (
                        <PricingTable
                            open={editPricingOpen}
                            classes={classes}
                            titleColumn={"island"}
                            defaultPricing={
                                defaultPricing?.interIslandTransferFee
                            }
                            customPricing={
                                customPricing?.interIslandTransferFee
                            }
                            columns={[
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.island"
                                            defaultMessage="Island"
                                        />
                                    ),
                                    value: "island",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.grossDiscount"
                                            defaultMessage="Gross Discount"
                                        />
                                    ),
                                    value: "grossDiscount",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.minimumCharge"
                                            defaultMessage="Minimum Charge"
                                        />
                                    ),
                                    value: "minimumCharge",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.ratePerCuFt"
                                            defaultMessage="Rate per CuFt"
                                        />
                                    ),
                                    value: "ratePerCuFt",
                                },
                            ]}
                        />
                    ) : null}
                    {activeFilter === "hazardousMaterialsFee" ? (
                        <PricingTable
                            open={editPricingOpen}
                            classes={classes}
                            titleColumn={"name"}
                            defaultPricing={[
                                {
                                    name: "PUD Charge",
                                    ...defaultPricing?.hazardousMaterialsCharge,
                                },
                                {
                                    name: "Ocean Charge",
                                    ...defaultPricing?.hazardousMaterialsOceanCharge,
                                },
                            ]}
                            customPricing={[
                                {
                                    name: "PUD Charge",
                                    ...customPricing?.hazardousMaterialsCharge,
                                },
                                {
                                    name: "Ocean Charge",
                                    ...customPricing?.hazardousMaterialsOceanCharge,
                                },
                            ]}
                            columns={[
                                {
                                    title: (
                                        <FormattedMessage
                                            id="domesticOffShore.filters.HazardousMaterials"
                                            defaultMessage="Hazardous Materials"
                                        />
                                    ),
                                    value: "name",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.minimumCharge"
                                            defaultMessage="Minimum Charge"
                                        />
                                    ),
                                    value: "minimumCharge",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.grossDiscount"
                                            defaultMessage="Gross Discount"
                                        />
                                    ),
                                    value: "oceanGrossDiscount",
                                },
                            ]}
                        />
                    ) : null}
                    {activeFilter === "insideDeliveryPickupCharge" ? (
                        <PricingTable
                            open={editPricingOpen}
                            classes={classes}
                            defaultPricing={[
                                defaultPricing?.insideDeliveryPickupCharge,
                            ]}
                            customPricing={[
                                customPricing?.insideDeliveryPickupCharge,
                            ]}
                            columns={[
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.grossDiscount"
                                            defaultMessage="Gross Discount"
                                        />
                                    ),
                                    value: "grossDiscount",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.ratePerCwt"
                                            defaultMessage="Rate per CWT"
                                        />
                                    ),
                                    value: "rate",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.minimumCharge"
                                            defaultMessage="Minimum Charge"
                                        />
                                    ),
                                    value: "minimumValue",
                                },
                            ]}
                        />
                    ) : null}

                    {activeFilter === "dockDeliveryPickupCharge" ? (
                        <PricingTable
                            open={editPricingOpen}
                            classes={classes}
                            defaultPricing={[
                                defaultPricing?.dockDeliveryPickupCharge,
                            ]}
                            customPricing={[
                                customPricing?.dockDeliveryPickupCharge,
                            ]}
                            columns={[
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.grossDiscount"
                                            defaultMessage="Gross Discount"
                                        />
                                    ),
                                    value: "grossDiscount",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.ratePerCuFt"
                                            defaultMessage="Rate per CuFt"
                                        />
                                    ),
                                    value: "rate",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.minimumCharge"
                                            defaultMessage="Minimum Charge"
                                        />
                                    ),
                                    value: "minimumValue",
                                },
                            ]}
                        />
                    ) : null}

                    {activeFilter === "otherFees" ? (
                        <PricingTableSimple
                            classes={classes}
                            defaultPricing={{
                                limitedAccessSurcharge:
                                    defaultPricing?.limitedAccessSurcharge,
                                privateResidenceSurcharge:
                                    defaultPricing?.privateResidenceSurcharge,
                            }}
                            customPricing={{
                                limitedAccessSurcharge:
                                    customPricing?.limitedAccessSurcharge,
                                privateResidenceSurcharge:
                                    customPricing?.privateResidenceSurcharge,
                            }}
                            rowValues={[
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.limitedAccessSurcharge"
                                            defaultMessage="Limited Access Surcharge"
                                        />
                                    ),
                                    value: "limitedAccessSurcharge",
                                },

                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.privateResidenceSurcharge"
                                            defaultMessage="Private Residence Surcharge"
                                        />
                                    ),
                                    value: "privateResidenceSurcharge",
                                },
                            ]}
                        />
                    ) : null}
                    {activeFilter === "absoluteMinimumCharge" ? (
                        <PricingTableSimple
                            classes={classes}
                            defaultPricing={
                                defaultPricing?.absoluteMinimumCharge
                            }
                            customPricing={customPricing?.absoluteMinimumCharge}
                            rowValues={[
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.absoluteMinimumCharge"
                                            defaultMessage="Custom Absolute Minimum Charge"
                                        />
                                    ),
                                    value: "charge",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.grossDiscount"
                                            defaultMessage="Gross Discount"
                                        />
                                    ),
                                    value: "grossPercentageDiscount",
                                },
                            ]}
                        />
                    ) : null}
                </CardContent>
            </Card>
        </Fragment>
    )
}

const mapStateToProps = (state, props) => {
    const defaultPricing = state?.offshoreRating?.hawaiiPricing ?? {}
    const customPricing =
        state?.form?.location?.values?.customHawaiiPricing ?? {}
    return {
        defaultPricing,
        customPricing,
    }
}

const mapDispatchToProps = dispatch => ({
    getHawaiiPricing: state => dispatch(getHawaiiPricing(state)),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(HawaiiPricingTile)
)

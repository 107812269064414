import React, { useRef, useState, useEffect } from "react"
import { Button, TextField, Typography, withStyles } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { _arrayBufferToBase64 } from "../../track/result/attachments/attachmentsCard"
import Dropzone from "react-dropzone"
import { UploadErrorDialog } from "../../documents/upload/UploadErrorDialog"
import SignaturePad from "react-signature-canvas"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import { Field } from "redux-form"
import formField from "../../form/form-field"

const styles = theme => ({
    dropZone: {
        padding: "30px",
        border: "1px dashed #D4D4D4",
        borderRadius: "5px",
        "&:hover": {
            cursor: "pointer",
        },
        width: "100%",
        height: "30vh",
    },
    dropZone__root: {
        marginTop: "2%",
        outline: "none",
    },
    draw_container: {
        marginTop: "2%",
        height: "30vh",
    },
    draw_box: {
        width: "80%",
        height: "80%",
        margin: "0 auto",
        backgroundColor: "#fff",
        "&:hover": {
            cursor: "pointer",
        },
        border: "1px dashed #D4D4D4",
        borderRadius: "5px",
    },
    sigPad: {
        width: "100%",
        height: "100%",
    },
    draw_buttons: {
        marginTop: "5px",
        marginRight: "5px",
    },
    canvas: {
        padding: "0",
        margin: "auto",
        display: "block",
        border: "1px dashed #D4D4D4",
    },
    dialog__content: {
        padding: "10px",
    },
    dialog__section: {
        padding: "10px",
    },
    dialog__fileIcon: {
        marginLeft: "5px",
    },
    dialog__caption: {
        paddingLeft: "3px",
    },
    dialog__overflowVisible: {
        overflowY: "visible",
    },
})

const Canvas = props => {
    const canvas = useRef()

    let signature = props.signature
    // initialize the canvas context
    useEffect(() => {
        // assign the width and height to canvas
        const canvasEle = canvas.current
        canvasEle.width = 300
        canvasEle.height = 50

        // get context of the canvas
        let ctx = canvasEle.getContext("2d")

        writeText({ ctx: ctx, text: signature, x: 10, y: 10 })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signature])

    // write a text on the ctx
    const writeText = (info, style = {}) => {
        const { ctx, text, x, y } = info
        const {
            fontSize = 25,
            fontFamily = "'Brush Script MT', cursive",
            color = "black",
            textAlign = "left",
            textBaseline = "top",
        } = style

        ctx.beginPath()
        ctx.font = fontSize + "px " + fontFamily
        ctx.textAlign = textAlign
        ctx.textBaseline = textBaseline
        ctx.fillStyle = color
        ctx.fillText(text, x, y)
        ctx.stroke()
    }

    return <canvas id="signatureTypeCanvas" ref={canvas} {...props} />
}

const AddAttachedSignature = props => {
    const { classes, handleAddClose, mode, changeField } = props
    const [errorModalOpen, setErrorModalOpen] = useState(false)
    const [successModalOpen, setSuccessModalOpen] = useState(false)
    const [stagedFile, setStagedFile] = useState(null)

    const [typedSignatureValue, setTypedSignatureValue] = useState("")

    let sigPad = {}

    const clear = () => {
        sigPad.clear()
    }
    const openLocalDialog = type => {
        if (type === "error") {
            setErrorModalOpen(true)
        } else {
            setSuccessModalOpen(true)
        }
    }

    const closeLocalDialog = () => {
        setErrorModalOpen(false)
        setSuccessModalOpen(false)
    }

    const stageFile = file => {
        if (file) {
            const reader = new FileReader()
            reader.onabort = () => console.log("file reading was aborted")
            reader.onerror = () => console.log("file reading has failed")
            reader.onload = () => {
                const result = reader.result
                setStagedFile({
                    content: _arrayBufferToBase64(result),
                    mimeType: file.type,
                    originalFileName: file.name,
                    customName: "",
                })
            }
            reader.readAsArrayBuffer(file)
            openLocalDialog("success")
        } else {
            openLocalDialog("error")
        }
    }

    const buildStageField = trimmedData => {
        let index = trimmedData.indexOf("base64,")
        if (index >= 0) {
            openLocalDialog("success")
        } else {
            openLocalDialog("error")
        }
    }

    const trim = () => {
        if (!sigPad.isEmpty()) {
            let trimmedData = sigPad.getTrimmedCanvas().toDataURL("image/png")
            buildStageField(trimmedData)
        }
    }

    const generateSignatureFromCanvas = () => {
        if (typedSignatureValue) {
            const canvasSave = document.getElementById("signatureTypeCanvas")
            const trimmedData = canvasSave.toDataURL("image/png")
            buildStageField(trimmedData)
        }
    }

    const continueAction = () => {
        changeField(stagedFile)
        handleAddClose()
    }

    return (
        <Grid container>
            {mode === "upload" && (
                <Dropzone
                    onDrop={acceptedFiles => stageFile(acceptedFiles[0])}
                    accept="image/png, image/jpeg"
                    maxFiles={1}
                    multiple={false}
                    maxSize={5000000}
                >
                    {({ getRootProps, getInputProps }) => {
                        return (
                            <Grid container>
                                <Grid
                                    container
                                    {...getRootProps()}
                                    className={classes.dropZone__root}
                                    alignContent="center"
                                    alignItems="center"
                                >
                                    <Grid
                                        container
                                        className={classes.dropZone}
                                        justify="center"
                                        alignContent="center"
                                        alignItems="center"
                                    >
                                        <input {...getInputProps()} />
                                        <Grid>
                                            <Typography variant="body2">
                                                <FormattedMessage
                                                    id="documents__attachmentInstructions__signature"
                                                    defaultMessage="Click Here, or Drag and Drop, to add images (.jpg or .png)"
                                                />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }}
                </Dropzone>
            )}

            {mode === "draw" && (
                <Grid
                    container
                    direction="column"
                    alignContent="center"
                    alignItems="center"
                    className={classes.draw_container}
                >
                    <Grid container className={classes.draw_box}>
                        <SignaturePad
                            canvasProps={{ className: classes.sigPad }}
                            ref={ref => {
                                sigPad = ref
                            }}
                        />
                    </Grid>

                    <Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={trim}
                            className={classes.draw_buttons}
                        >
                            <FormattedMessage
                                id="documents__attachmentSignature__generate"
                                defaultMessage="Generate"
                            />
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={clear}
                            className={classes.draw_buttons}
                        >
                            <FormattedMessage
                                id="documents__attachmentSignature__clear"
                                defaultMessage="Clear"
                            />
                        </Button>
                    </Grid>
                </Grid>
            )}

            {mode === "type" && (
                <Grid
                    container
                    direction="column"
                    alignContent="center"
                    alignItems="center"
                    className={classes.draw_container}
                >
                    <Grid
                        alignContent="center"
                        alignItems="center"
                        container
                        direction="column"
                    >
                        <Grid item container xs={6} className={classes.canvas}>
                            <Canvas signature={typedSignatureValue}></Canvas>
                        </Grid>
                        <Grid
                            item
                            container
                            alignContent="center"
                            alignItems="center"
                            xs={2}
                        >
                            <TextField
                                id="signature-field"
                                label={[
                                    <FormattedMessage
                                        id="documents__attachmentSignature__label"
                                        defaultMessage="Sign Here"
                                    />,
                                ]}
                                onChange={event =>
                                    setTypedSignatureValue(event.target.value)
                                }
                                value={typedSignatureValue}
                            />
                        </Grid>
                    </Grid>
                    <Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={generateSignatureFromCanvas}
                            className={classes.draw_buttons}
                        >
                            <FormattedMessage
                                id="documents__attachmentSignature__generate"
                                defaultMessage="Generate"
                            />
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setTypedSignatureValue("")}
                            className={classes.draw_buttons}
                        >
                            <FormattedMessage
                                id="documents__attachmentSignature__clear"
                                defaultMessage="Clear"
                            />
                        </Button>
                    </Grid>
                </Grid>
            )}

            {errorModalOpen && (
                <UploadErrorDialog
                    onClose={closeLocalDialog}
                    open={errorModalOpen}
                />
            )}

            {successModalOpen && (
                <UploadSucessDialog
                    onClose={closeLocalDialog}
                    open={successModalOpen}
                    classes={classes}
                    stagedFile={stagedFile}
                    setStagedFile={setStagedFile}
                    continueAction={continueAction}
                />
            )}
        </Grid>
    )
}

export default withStyles(styles)(AddAttachedSignature)

export const UploadSucessDialog = ({
    open,
    onClose,
    classes,
    stagedFile,
    setStagedFile,
    continueAction,
}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle id="alert-dialog-title">
                <Grid item container>
                    <FormattedMessage
                        id="documents.upload__title"
                        defaultMessage="Attach File"
                    />
                    <AttachFileIcon
                        fontSize="large"
                        className={classes.dialog__fileIcon}
                    />
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.dialog__overflowVisible}>
                <Grid
                    item
                    container
                    alignContent="flex-start"
                    justify="center"
                    className={classes.dialog__content}
                >
                    <Grid
                        item
                        container
                        xs={12}
                        justify="center"
                        className={classes.dialog__section}
                    >
                        <Grid
                            item
                            container
                            xs={12}
                            className={classes.dialog__caption}
                        >
                            <Typography align="center" gutterBottom>
                                {stagedFile?.originalFileName}
                            </Typography>
                        </Grid>

                        <Field
                            name="userFileName"
                            label={
                                <FormattedMessage
                                    id="documents.upload__userFileName"
                                    defaultMessage="Custom File Name"
                                />
                            }
                            component={formField}
                            onChange={event =>
                                setStagedFile({
                                    ...stagedFile,
                                    customName: event.target.value,
                                })
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => onClose("success")}
                >
                    <FormattedMessage
                        id="generalTerms__cancel"
                        defaultMessage="Cancel"
                    />
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={continueAction}
                >
                    <FormattedMessage
                        id="generalTerms__continue"
                        defaultMessage="Continue"
                    />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { get } from "lodash"
import { Typography, withStyles, Paper, Grid } from "@material-ui/core"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { quoteSelect } from "../../../actions/quote"
import { CarrierLogo } from "../../util"
import RateBreakdown from "../../dashboard/components/rateBreakdown"
import { trackGAEvent } from "../../../actions/user"
import { Checkbox } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { currencyLabel } from "../../util/currencyLabel"

const styles = theme => ({
    logoTile: {
        maxWidth: 80,
        "padding-right": "20px",
        marginRight: "40px",
        height: "auto !important",
    },
    carrier_logo: {
        height: "90px",
        "& $img": {
            height: "60px",
            padding: "10px !important",
            margin: "0px !important",
        },
    },
    card__container: {
        marginRight: theme.spacing(2),
        paddingBottom: "20px",
        paddingLeft: "10px",
    },
    card__container_border: {
        marginRight: theme.spacing(2),
    },
    gridList: {
        // width: '350px',
    },
    gridListTile: {
        borderBottom: "1px solid lightgrey",
        padding: "10px",
        width: "100%",
        minHeight: "77px",
    },
    cardContent: {
        paddingTop: "20px",
        paddingLeft: "10px",
    },
    iconButton: {
        height: "auto",
    },
    shareCheckbox: {
        height: "auto",
    },
    popover: {
        zIndex: 100,
    },
    breakdown__container: {
        minWidth: "325px",
    },
    card__header: {
        paddingLeft: "12px",
    },
    left_border: {
        borderLeftWidth: "5px",
        borderLeftColor: "black",
    },
    empty_header: {
        minHeight: "24px",
    },
    fastest: {
        paddingRight: "5px",
    },
    expansion__panel: {
        width: "100%",
        padding: 0,
    },
    blank_expansion_panel: {
        paddingLeft: "0px",
        height: "88px",
        opacity: 0,
    },
    summary__content: {
        margin: "0px !important",
    },
    third__party__label: {
        marginLeft: "3px",
        padding: "3px",
    },
    hrcNote: {
        marginLeft: "3px",
        padding: "3px",
    },
    alternate__quote__card: {
        borderBottom: "solid 1px #D2D2D2",
    },
    grid__paper: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
    },
    paperContainer: {
        padding: "12px",
    },
    individualRate: {
        borderTop: "solid 1px #F5F5F5",
    },
    card__enabled: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#D3D3D3",
        },
        "&:active": {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    card: {
        padding: "12px !important",
        minHeight: "92px",
        width: "82%",
    },
    card__disabled: {
        cursor: "default",
    },
    card__vendor: {
        minHeight: "92px",
        padding: "12px !important",
    },
    summary_content: {
        margin: "0px",
    },
    typography__rightAlign: {
        width: "100%",
    },
    card__selected: {
        backgroundColor: theme.palette.secondary.light,
    },
    exception__container: {
        padding: "15px",
        minHeight: "92px",
    },
    exception__errorMessage: {
        padding: "10px 0",
    },
    expansionPanel__root: {
        width: "100%",
    },
    header__label: {
        padding: "0 25px",
    },
})

let QuoteCardCarrier = ({ carrierCode, mode, serviceLevel, classes }) => (
    <Grid className={classes.card__header}>
        <CarrierLogo
            className={classes.carrier_logo}
            carrierCode={carrierCode}
            mode={mode}
            serviceLevel={serviceLevel}
        />
    </Grid>
)

QuoteCardCarrier.propTypes = {
    carrierCode: PropTypes.string,
    mode: PropTypes.string,
    serviceLevel: PropTypes.string,
    onPin: PropTypes.func,
}

QuoteCardCarrier.defaultProps = {
    carrierCode: null,
    serviceLevel: null,
    mode: "LTL",
    onPin: () => {},
}

QuoteCardCarrier = withStyles(styles)(QuoteCardCarrier)

class FreightDirectQuoteCardPresentation extends Component {
    state = {
        anchorEl: null,
        anchorIndex: null,
    }

    toggleDetails = (e, index) => {
        e.stopPropagation()
        this.setState({ anchorEl: e.currentTarget, anchorIndex: index })
    }

    handleClose = e => {
        e.stopPropagation()
        this.setState({
            anchorEl: null,
        })
    }

    addBlanks(quotes, maxRateLength) {
        const blanks = []
        for (
            let i = get(quotes, "rateQuotes.length", 0);
            i < maxRateLength;
            i++
        )
            blanks.push({ blank: true })
        return blanks
    }

    render() {
        const {
            classes,
            disabled,
            share,
            onClick,
            shipmentId,
            shippedRateQuote,
            canViewRateAmounts,
            serviceLevel,
            cardIndex,
            getBol,
        } = this.props

        const { rates, title, label } = serviceLevel

        const checkboxClasses = {
            root: classes.checkbox__root,
        }

        const summaryClasses = {
            content: classes.summary__content,
        }

        const { isOpen, selected } = share

        return (
            <Grid item container className={classes.paperContainer}>
                <Paper className={classes.grid__paper}>
                    <Grid item container className={classes.gridListTile}>
                        <Grid item container justify="center">
                            <Grid item container justify="center">
                                <Typography variant="subtitle1">
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                container
                                justify="center"
                                className={classes.header__label}
                            >
                                <Typography variant="caption">
                                    {label}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {rates.map((rate, rateIndex) => {
                        const {
                            rateQuoteDetail,
                            errorMessages,
                            mode,
                            direction,
                            carrierCode,
                            serviceLevel,
                            transitDays,
                            deliveryDateTime,
                        } = rate

                        const deliveryDate =
                            Number.isInteger(transitDays) && deliveryDateTime

                        const isSelectedRate =
                            shippedRateQuote?.mode === mode &&
                            carrierCode === shippedRateQuote?.carrierCode &&
                            direction === shippedRateQuote?.direction &&
                            serviceLevel?.code ===
                                shippedRateQuote?.serviceLevel?.code

                        const isException =
                            errorMessages?.length &&
                            !rateQuoteDetail?.totalInPreferredCurrency &&
                            !rateQuoteDetail?.total

                        return (
                            <Grid
                                item
                                container
                                className={
                                    rateIndex !== 0
                                        ? classes.individualRate
                                        : ""
                                }
                            >
                                {isException ? (
                                    <Grid
                                        item
                                        container
                                        justify="center"
                                        className={classes.exception__container}
                                    >
                                        {errorMessages.map(error => (
                                            <Grid
                                                item
                                                container
                                                className={
                                                    classes.exception__errorMessage
                                                }
                                                justify="center"
                                                alignItems="center"
                                            >
                                                <Typography
                                                    variant="caption"
                                                    color="secondary"
                                                >
                                                    {error?.message}
                                                </Typography>
                                            </Grid>
                                        ))}
                                    </Grid>
                                ) : rate?.accountType === "VENDOR" ||
                                  !canViewRateAmounts ? (
                                    <Grid item container>
                                        <Grid
                                            item
                                            container
                                            className={`${
                                                classes.card__vendor
                                            } ${isSelectedRate &&
                                                classes.card__selected} ${
                                                isOpen || disabled
                                                    ? classes.card__disabled
                                                    : classes.card__enabled
                                            }`}
                                            onClick={e => {
                                                e.stopPropagation()
                                                if (!disabled && !isOpen) {
                                                    onClick(
                                                        shipmentId,
                                                        rate.carrierCode,
                                                        rate.index,
                                                        rate.mode,
                                                        rate.direction,
                                                        {
                                                            rateIndex,
                                                            cardIndex,
                                                        }
                                                    )

                                                    getBol()
                                                }
                                            }}
                                            alignContent="flex-start"
                                        >
                                            <Grid item container xs={4}>
                                                <Typography
                                                    variant="body2"
                                                    color="primary"
                                                >
                                                    <FormattedMessage
                                                        id="rateResults__bookNow"
                                                        defaultMessage="Book Now"
                                                    />
                                                </Typography>
                                            </Grid>

                                            <Grid item container xs={8}>
                                                <Typography
                                                    variant="body2"
                                                    align="right"
                                                    className={
                                                        classes.typography__rightAlign
                                                    }
                                                >
                                                    {rate.carrierCode ===
                                                    "FXFE" ? (
                                                        <FormattedMessage
                                                            id="generalTerms__FedExPriority"
                                                            defaultMessage="FedEx Priority"
                                                        />
                                                    ) : rate.carrierCode ===
                                                      "FXNL" ? (
                                                        <FormattedMessage
                                                            id="generalTerms__FedExEconomy"
                                                            defaultMessage="FedEx Economy"
                                                        />
                                                    ) : (
                                                        rate.carrierCode
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item container xs={4}>
                                                {isOpen && (
                                                    <Checkbox
                                                        classes={
                                                            checkboxClasses
                                                        }
                                                        checked={selected}
                                                        onClick={e =>
                                                            e.stopPropagation()
                                                        }
                                                        onChange={() =>
                                                            onClick(
                                                                shipmentId,
                                                                rate.carrierCode,
                                                                rate.index,
                                                                rate.mode,
                                                                rate.direction,
                                                                {
                                                                    rateIndex,
                                                                    cardIndex,
                                                                }
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item container xs={8}>
                                                <Typography
                                                    variant="caption"
                                                    align="right"
                                                    className={
                                                        classes.typography__rightAlign
                                                    }
                                                >
                                                    {!!deliveryDate &&
                                                        deliveryDate.format(
                                                            "dddd, MMM Do"
                                                        )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <ExpansionPanel
                                        className={classes.expansionPanel__root}
                                    >
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            className={classes.expansion__panel}
                                            classes={summaryClasses}
                                        >
                                            <Grid
                                                item
                                                container
                                                className={`${
                                                    classes.card
                                                } ${isSelectedRate &&
                                                    classes.card__selected} ${
                                                    isOpen || disabled
                                                        ? classes.card__disabled
                                                        : classes.card__enabled
                                                }`}
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    if (!disabled && !isOpen) {
                                                        onClick(
                                                            shipmentId,
                                                            rate.carrierCode,
                                                            rate.index,
                                                            rate.mode,
                                                            rate.direction,
                                                            {
                                                                rateIndex,
                                                                cardIndex,
                                                            }
                                                        )

                                                        getBol()
                                                    }
                                                }}
                                                alignContent="flex-start"
                                            >
                                                <Grid item container xs={4}>
                                                    {canViewRateAmounts && (
                                                        <Typography variant="body2">
                                                            {currencyLabel(
                                                                rate?.preferredCurrencyCode,
                                                                rate
                                                                    ?.rateQuoteDetail
                                                                    ?.totalInPreferredCurrency
                                                                    ? rate
                                                                          ?.rateQuoteDetail
                                                                          ?.totalInPreferredCurrency
                                                                    : rate
                                                                          ?.rateQuoteDetail
                                                                          ?.total
                                                            )}
                                                        </Typography>
                                                    )}
                                                </Grid>

                                                <Grid item container xs={8}>
                                                    <Typography
                                                        variant="body2"
                                                        align="right"
                                                        className={
                                                            classes.typography__rightAlign
                                                        }
                                                    >
                                                        {rate.carrierCode ===
                                                        "FXFE" ? (
                                                            <FormattedMessage
                                                                id="generalTerms__FedExPriority"
                                                                defaultMessage="FedEx Priority"
                                                            />
                                                        ) : rate.carrierCode ===
                                                          "FXNL" ? (
                                                            <FormattedMessage
                                                                id="generalTerms__FedExEconomy"
                                                                defaultMessage="FedEx Economy"
                                                            />
                                                        ) : (
                                                            rate.carrierCode
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item container xs={4}>
                                                    {isOpen && (
                                                        <Checkbox
                                                            classes={
                                                                checkboxClasses
                                                            }
                                                            checked={selected}
                                                            onClick={e =>
                                                                e.stopPropagation()
                                                            }
                                                            onChange={() =>
                                                                onClick(
                                                                    shipmentId,
                                                                    rate.carrierCode,
                                                                    rate.index,
                                                                    rate.mode,
                                                                    rate.direction,
                                                                    {
                                                                        rateIndex,
                                                                        cardIndex,
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                                <Grid item container xs={8}>
                                                    <Typography
                                                        variant="caption"
                                                        align="right"
                                                        className={
                                                            classes.typography__rightAlign
                                                        }
                                                    >
                                                        {!!deliveryDate &&
                                                            deliveryDate.format(
                                                                "dddd, MMM Do"
                                                            )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <RateBreakdown
                                                listView={false}
                                                charges={get(
                                                    rate,
                                                    "rateQuoteDetail.charges"
                                                )}
                                                totalInPreferredCurrency={
                                                    get(
                                                        rate,
                                                        "rateQuoteDetail.totalInPreferredCurrency"
                                                    )
                                                        ? get(
                                                              rate,
                                                              "rateQuoteDetail.totalInPreferredCurrency"
                                                          )
                                                        : get(
                                                              rate,
                                                              "rateQuoteDetail.total"
                                                          )
                                                }
                                                preferredCurrencyCode={get(
                                                    rate,
                                                    "preferredCurrencyCode"
                                                )}
                                                gridSize={12}
                                                paddingRight={"3%"}
                                                quoteID={
                                                    rate.capacityProviderQuoteNumber
                                                }
                                            />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )}
                            </Grid>
                        )
                    })}
                </Paper>
            </Grid>
        )
    }
}

const selectorMapStateToProps = state => ({
    shipmentId: state.identifiers.internalTrackingNumber,
    isExpired: state.quotes.active.isExpired,
    isViewMode: state.quotes.active.shipped,
    shippedRateQuote: state.quotes.active.shippedRateQuote,
    share: state.share,
    cpg: state.search.cpg,
    guaranteedList: state.accessorials.list.guaranteed,
})

const selectorMapDispatchToProps = (dispatch, props) => ({
    onClick: (shipmentId, carrier, quoteId, mode, metadata) => {
        dispatch(quoteSelect(shipmentId, carrier, quoteId, mode, metadata))
    },
    onInfoClick: cpg =>
        dispatch(trackGAEvent("Quote", "Rate details popup", cpg)),
})

export const FreightDirectQuoteCard = connect(
    selectorMapStateToProps,
    selectorMapDispatchToProps
)(withStyles(styles)(FreightDirectQuoteCardPresentation))

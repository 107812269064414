export const traverseSyncError = syncError => {
    if (
        syncError &&
        Object.keys(syncError).length === 0 &&
        Object.getPrototypeOf(syncError) === Object.prototype
    ) {
        return undefined
    }
    if (
        (syncError.id && syncError.defaultMessage) ||
        (syncError.props?.id && syncError.props?.defaultMessage)
    ) {
        return true
    }
    if (Array.isArray(syncError)) {
        return syncError.some(error => traverseSyncError(error))
    }

    for (const key in syncError) {
        if (traverseSyncError(syncError[key])) {
            return true
        }
    }
}

export const getCurrentBSStep = async ({
    bookShipmentFormValidation,
    formValues,
    BSProps,
    featureTransition,
    getRates,
    feature,
}) => {
    const bookShipmentSteps = [
        {
            stepName: "Basic Info",
            stepValue: 0,
            requiredFields: ["cpg", "shipDate", "direction", "paymentTerms"],
        },
        {
            stepName: "Origin",
            stepValue: 1,
            requiredFields: ["origin", "shippingAddress", "contact"],
        },
        {
            stepName: "Destination",
            stepValue: 2,
            requiredFields: ["destination", "shippingAddress", "contact"],
        },
        {
            stepName: "Line Items",
            stepValue: 3,
            requiredFields: [
                "handlingUnits",
                "packageType",
                "length",
                "width",
                "height",
            ],
        },
    ]

    const syncErrors = bookShipmentFormValidation(
        formValues,
        BSProps,
        featureTransition
    )

    for (const step of bookShipmentSteps) {
        for (const requiredField of step.requiredFields) {
            if (syncErrors[requiredField]) {
                if (traverseSyncError(syncErrors[requiredField])) {
                    return step
                }
            }
        }
    }

    if (feature === "quickRate") {
        return await getRates("quickRate")
    }
    if (feature === "orders") {
        return await getRates("orders")
    }
}

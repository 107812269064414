import React, { useState, useEffect } from "react"
import InputAdornment from "@material-ui/core/InputAdornment"
import { Box, TextField } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import Search from "@material-ui/icons/Search"
import { contactsSearch } from "../dashboard/search/util"

const ContactSearch = ({
    setFilteredContacts,
    contacts,
    setCurrentPage,
    searchValue,
    setSearchValue,
    gaCategory,
    logGAEvent,
}) => {
    const [initialValue, setInitialValue] = useState("")
    const handleContactsSearch = evt => {
        setCurrentPage(1)
        const searchText = evt.target.value
        setSearchValue(searchText)
        setFilteredContacts(contactsSearch(searchText, contacts))
    }
    const onFocus = () => {
        setInitialValue(searchValue)
    }

    return (
        <Box>
            <TextField
                name="contacts.searchOrder"
                value={searchValue}
                onFocus={onFocus}
                autoFocus
                onChange={handleContactsSearch}
                label={
                    <FormattedMessage
                        id="contacts.search"
                        defaultMessage="Search for a contact"
                    />
                }
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                }}
                onBlur={evt => {
                    if (evt.target.value !== initialValue) {
                        logGAEvent(
                            gaCategory,
                            "Search Field",
                            evt.target.value !== ""
                                ? evt.target.value
                                : "Field Empty"
                        )
                    }
                    setInitialValue("")
                }}
            />
        </Box>
    )
}

export default ContactSearch

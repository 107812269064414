import {
    SHARE_STATUS_RESULT,
    SHARE_KEY,
    SHARE_STATUS_LOAD,
    SHARE_STATUS_ERROR,
} from "../actions/share"

export function shareStatus(
    state = {
        isFetching: false,
        isLoaded: false,
        shareKey: null,
        channelId: null,
        currentStatus: null,
    },
    action
) {
    switch (action.type) {
        case SHARE_KEY:
            return { ...state, shareKey: action.shareKey }
        case SHARE_STATUS_RESULT:
            return {
                ...state,
                isFetching: false,
                isLoaded: true,
                ...action.shareStatus,
            }
        case SHARE_STATUS_ERROR:
            return { ...state, isFetching: false }
        case SHARE_STATUS_LOAD:
            return { ...state, isFetching: true }
        default:
            return state
    }
}

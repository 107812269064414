import { withStyles } from "@material-ui/styles"
import { styles } from "./styles"
import { Grid, Typography, Dialog, Button } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import GlobalSpinner from "../../../common/GlobalSpinner"

const HandleUnsavedChangesModal = ({
    location,
    openUnsavedModal,
    setOpenUnsavedModal,
    setOpen,
    setCurrentFormValues,
    currentFormvalues,
    handleSave,
    classes,
    initialFormValues,
    saving,
}) => {
    const saveUnsavedProgress = async () => {
        await handleSave(currentFormvalues, location.id)
        setOpenUnsavedModal(false)
    }

    const handleCancel = () => {
        setOpenUnsavedModal(false)
        setOpen(false)
        setCurrentFormValues({ ...initialFormValues })
    }
    return (
        <Dialog maxWidth="lg" open={openUnsavedModal}>
            <Grid className={classes.modal}>
                <Grid item container justifyContent="center">
                    <Grid item style={{ padding: "5px" }}>
                        <Typography variant="h5" align="center">
                            <FormattedMessage
                                id="locations.offshore_UnsavedChanges"
                                defaultMessage="You have unsaved changes, save before exiting?"
                            />
                        </Typography>
                        <Grid
                            style={{ paddingTop: "5%" }}
                            className={classes.buttonGroup}
                        >
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={handleCancel}
                            >
                                <FormattedMessage
                                    id="generalTerms__cancel"
                                    defaultMessage="Cancel"
                                />
                            </Button>
                            <Button
                                onClick={saveUnsavedProgress}
                                color="primary"
                                variant="contained"
                            >
                                <FormattedMessage
                                    id="generalTerms__save"
                                    defaultMessage="Save"
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {saving ? <GlobalSpinner /> : null}
            </Grid>
        </Dialog>
    )
}

export default withStyles(styles)(HandleUnsavedChangesModal)

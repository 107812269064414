import React, { useEffect, useState } from "react"
import {
    Checkbox,
    Grid,
    FormControl,
    Input,
    InputLabel,
    FormHelperText,
    FormControlLabel,
    Typography,
} from "@material-ui/core"
import { validateInput, stringToNumber } from "./AlaskaUtils"
import { FormattedMessage } from "react-intl"

const AlaskaRateField = ({
    index,
    defaultValue,
    label,
    initialValue,
    freightClass,
    currentFormValues,
    handleRatePerCwtFieldChange,
}) => {
    const [checked, setChecked] = useState(false)
    const [input, setInput] = useState("")
    const [error, setError] = useState("")

    useEffect(() => {
        if (initialValue?.isDisabled) {
            setChecked(true)
        }

        if (initialValue?.value) {
            setInput(initialValue.value)
        }

        if (initialValue === null) {
            setInput("")
        }
    }, [freightClass, initialValue])

    const onInputChange = evt => {
        const inputValue = validateInput(evt.target.value)
        setInput(inputValue)
        const ratePerCwtByClass = {
            freightClass,
            ratePerCwt: {
                value:
                    evt.target.value !== "" ? stringToNumber(inputValue) : null,
                isDisabled: initialValue ? initialValue.isDisabled : false,
            },
        }

        const atIndex = currentFormValues[index].ratePerCwtByClasses.findIndex(
            item => item.freightClass === freightClass
        )
        let changedRates
        if (atIndex !== -1) {
            changedRates = currentFormValues[
                index
            ].ratePerCwtByClasses.map((rate, index) =>
                index === atIndex ? ratePerCwtByClass : rate
            )
        } else {
            changedRates = [
                ...currentFormValues[index].ratePerCwtByClasses,
                ratePerCwtByClass,
            ]
        }
        handleRatePerCwtFieldChange(index, changedRates)

        if (checked && inputValue > 100) {
            setError("The percentage off value must be less than 100%.")
        } else {
            setError("")
        }
    }

    const onCheckboxChange = evt => {
        setChecked(!checked)
        setInput("")
        const newFormValues = {
            freightClass,
            ratePerCwt: {
                value: null,
                isDisabled: !checked,
            },
        }
        handleRatePerCwtFieldChange(index, freightClass, newFormValues)
    }

    return (
        <Grid style={{ paddingRight: "20px" }} item container>
            <Grid item container direction="column">
                <FormControl disabled={checked} error={error}>
                    <InputLabel>{label}</InputLabel>
                    <Input
                        value={input}
                        onChange={onInputChange}
                        autoComplete={false}
                        inputProps={{
                            min: 0,
                            style: { textAlign: checked ? "right" : "left" },
                            inputMode: "decimal",
                        }}
                        startAdornment={
                            <Typography style={{ paddingRight: "4px" }}>
                                $
                            </Typography>
                        }
                    />
                    {defaultValue ? (
                        <FormHelperText>
                            <FormattedMessage
                                id="location.offshore.default"
                                defaultMessage="Default: ${value}"
                                values={{ value: defaultValue }}
                            />
                        </FormHelperText>
                    ) : null}
                    {error ? <FormHelperText>{error}</FormHelperText> : null}
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default AlaskaRateField

import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Field, FormSection, formValues } from "redux-form"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage, injectIntl } from "react-intl"
import PickupWarning from "./warning"
import FormCheckbox from "../../form/form-checkbox"
import ContactForm from "../contactForm"
import FormField from "../../form/form-field"
import FormSelectAutocomplete from "../../form/form-select-autocomplete"
import { AccessorialsDisplay } from "../../util/accessorialsDisplay"
import TerminalInfo from "../../common/TerminalInfo"

const styles = theme => ({
    card: {
        height: "100%",
    },
    content: {
        "margin-left": "60px",
    },
    done: {
        "margin-left": "auto",
    },
    loader: {
        position: "relative",
        "background-color": "rgba(0, 0, 0, 0.3)",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        color: "black",
        "text-align": "center",
    },
})

export const generateTimeList = () => {
    const hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    const minutes = ["00", "30"]
    const postfix = ["AM", "PM"]
    return postfix.reduce((times, item) => {
        hours.forEach(hour =>
            minutes.forEach(minute => {
                const time = `${hour}:${minute} ${item}`
                times.push({ label: time, value: time })
            })
        )
        return times
    }, [])
}

export const StandardOrBasicwApptTimeList = [
    {
        label: "12:00 PM - 2:00 PM",
        value: "12:00 PM - 2:00 PM",
    },
    {
        label: "1:00 PM - 3:00 PM",
        value: "1:00 PM - 3:00 PM",
    },
    {
        label: "2:00 PM - 4:00 PM",
        value: "2:00 PM - 4:00 PM",
    },
    {
        label: "3:00 PM - 5:00 PM",
        value: "3:00 PM - 5:00 PM",
    },
]

const PickupForm = ({
    classes,
    pickupLater,
    isPastPickup,
    shipmentId,
    country,
    terminal,
    mode,
    isInBondShipment,
    carrierCode,
    rateMode,
}) => (
    <Grid xs={6} item>
        <Card className={classes.card}>
            <CardHeader
                className={classes.header}
                title={
                    <Typography variant="h5">
                        <FormattedMessage
                            id="orderDetails.pickup__title"
                            defaultMessage="Pickup Requirements"
                        />
                    </Typography>
                }
            />
            <CardContent className={classes.content}>
                <PickupWarning
                    isPastPickup={isPastPickup}
                    pickupLater={pickupLater}
                    carrierCode={carrierCode}
                    rateMode={rateMode}
                    country={country}
                />
                {mode !== "LTL_DIRECT_RETURNS" && (
                    <AccessorialsDisplay
                        isPickup
                        shipmentId={shipmentId}
                        includeCalculated
                        isInBondShipment={isInBondShipment}
                    />
                )}
                <Field
                    name="pickupLater"
                    label={
                        <FormattedMessage
                            id="orderDetails.label__bolOnly"
                            defaultMessage="Schedule a pickup later (BOL Only)"
                        />
                    }
                    component={FormCheckbox}
                    enableSwitch
                />
                {pickupLater ? (
                    terminal && <TerminalInfo isPickup terminal={terminal} />
                ) : (
                    <Grid container>
                        <Grid xs={6} sm={3} item>
                            <Field
                                name="readyTime"
                                label={
                                    <FormattedMessage
                                        id="generalTerms.pickUpInformation__readyTime"
                                        defaultMessage="Ready Time"
                                    />
                                }
                                component={FormSelectAutocomplete}
                                disabled={pickupLater}
                                required={!pickupLater}
                                options={generateTimeList()}
                            />
                        </Grid>
                        <Grid xs={6} sm={3} item>
                            <Field
                                name="closeTime"
                                label={
                                    <FormattedMessage
                                        id="generalTerms.pickUpInformation__closeTime"
                                        defaultMessage="Close Time"
                                    />
                                }
                                component={FormSelectAutocomplete}
                                disabled={pickupLater}
                                required={!pickupLater}
                                options={generateTimeList()}
                            />
                        </Grid>
                    </Grid>
                )}
                {!pickupLater && (
                    <FormSection name="pickup" component={Fragment}>
                        <ContactForm disabled={pickupLater} country={country} />
                    </FormSection>
                )}
                {!pickupLater && (
                    <Field
                        name="note"
                        label={
                            <FormattedMessage
                                id="orderDetails.pickup__pickupRemarks"
                                defaultMessage="Pickup Remarks"
                            />
                        }
                        component={FormField}
                        multiline
                    />
                )}
            </CardContent>
        </Card>
    </Grid>
)

PickupForm.propTypes = {
    classes: PropTypes.object,
    onCancel: PropTypes.func,
    onDone: PropTypes.func,
    pickupLater: PropTypes.bool.isRequired,
}

PickupForm.defaultProps = {
    classes: {},
    onCancel: () => {},
    onDone: () => {},
}

export default withStyles(styles)(
    formValues({
        readyTime: "readyTime",
        closeTime: "closeTime",
        pickupLater: "pickupLater",
        pickup: "pickup",
        isPastPickup: "isPastPickup",
    })(injectIntl(PickupForm))
)

import React, { useState, useEffect } from "react"
import { Field, reduxForm, change } from "redux-form"
import AddIcon from "@material-ui/icons/Add"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CancelIcon from "@material-ui/icons/Cancel"
import Chip from "@material-ui/core/Chip"
import CloseIcon from "@material-ui/icons/Close"
import { combineValidators } from "revalidate"
import { connect } from "react-redux"
import EmailIcon from "@material-ui/icons/Email"
import { emailValidator } from "../../../../actions/validation"
import FormField from "../../../form/form-field"
import { FormattedMessage } from "react-intl"
import FormSelectAutocomplete from "../../../form/form-select-autocomplete"
import FormSelect from "../../../form/form-select"
import GlobalSpinner from "../../../common/GlobalSpinner"
import { goFetch } from "../../../../http"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import { preferenceLanguageOptions } from "../../../constants/preferenceLanguageOptions"
import { requestContactAddresses } from "../../../../actions/address"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import { trackGAEvent } from "../../../../actions/user"
import { useOrdersContext } from "../../../../context/providers/OrdersProvider"
import { useSnackbarContext } from "../../../../context/providers/snackbarProvider"
import { Box, Checkbox, FormControlLabel } from "@material-ui/core"

const styles = theme => ({
    paper: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        top: "50%",
        left: "50%",
        width: "900px",
        height: "100%",
        maxHeight: "600px",
        outline: "none",
    },
    chip: {
        margin: "5px",
        backgroundColor: `${theme.palette.primary.light} !important`,
        color: "white",
    },
    chip__avatar: {
        color: "white",
        backgroundColor: "transparent",
    },
    actions: { paddingTop: "15px" },
    actions__button: {
        paddingLeft: "15px",
    },
    header: {
        borderBottom: "solid 1px #D4D4D4",
    },
    attachmentRow: {
        padding: "0px 10px",
    },
    attachmentRow__selectAll: {
        padding: "5px 10px",
        borderBottom: "solid 1px #D4D4D4",
    },
    attachments: {
        overflowY: "scroll",
        maxHeight: "370px",
    },
    noDocsAvailable: {
        padding: "10px 10px",
    },
    contactsSelect: {
        width: "100%",
    },
    recipients__container: {
        padding: "22px 10px 10px 10px",
    },
    input__label: {
        fontSize: "21px",
    },
    or__text: {
        paddingTop: "8px",
    },
    recipient__chips: {
        paddingTop: "10px",
        overflowX: "auto",
        maxHeight: "150px",
    },
    addRecipients__title: {
        paddingBottom: "14px",
        paddingLeft: "4px",
    },
    textField: {
        minHeight: "35px",
        maxHeight: "50px",
    },
})

const MassShareAttachmentsPresentation = ({
    // list of closed shipments
    classes,
    onClose,
    attachments = [],
    requestContactAddresses,
    contacts = [],
    newEmailRecipient,
    newEmailRecipientError,
    changeField,
    formDocuments = {},
    shareLanguage,
    notes,
    GAEvent,
    gaCategory,
    pickUpContactEmailList,
    originContactEmailList,
}) => {
    const [contactsLoaded, setIsContactsLoaded] = useState(contacts?.length > 0)
    const [checked, setChecked] = useState([false, false])
    const recipientList = []
    if (pickUpContactEmailList) {
        recipientList.push(pickUpContactEmailList)
    }
    if (originContactEmailList) {
        recipientList.push(originContactEmailList)
    }

    const [recipients, setRecipients] = useState(recipientList.flat())
    const [isLoading, setIsLoading] = useState(false)

    const { openSnackbar } = useSnackbarContext()
    const {
        bulkOrders,
        setBulkOrders,
        selectAllClicked,
        setSelectAllClicked,
    } = useOrdersContext()

    const addRecipient = value => {
        changeField("contactsRecipients", null)
        changeField("newEmailRecipient", "")
        setRecipients(recipients.concat(value))
    }

    useEffect(() => {
        let anyFalse = false

        attachments.forEach(attachment => {
            if (
                !formDocuments[attachment.attachmentFileName] &&
                !formDocuments[attachment.attachmentFileType]
            ) {
                anyFalse = true
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formDocuments])

    useEffect(() => {
        let current = true
        const fetchData = async current => {
            try {
                await requestContactAddresses()
                if (current) {
                    setIsContactsLoaded(true)
                }
            } catch (error) {}
        }

        if (!contactsLoaded) {
            fetchData(current)
        }

        return () => (current = false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const FormLabelProps = {
        classes: {
            label: classes.input__label,
        },
    }

    const checkAll = event => {
        setChecked([event.target.checked, event.target.checked])
    }

    const checkLabels = event => {
        setChecked([event.target.checked, checked[1]])
    }

    const checkBol = event => {
        setChecked([checked[0], event.target.checked])
    }

    const sendEmail = async () => {
        const selectedDocuments = []

        if (checked[0]) {
            selectedDocuments.push({ type: "labels" })
        }

        if (checked[1]) {
            selectedDocuments.push({ type: "bol" })
        }

        let adjustedRecipients = [...recipients]

        if (newEmailRecipient && !newEmailRecipientError) {
            adjustedRecipients = [...adjustedRecipients, newEmailRecipient]
            setRecipients(recipients.concat(newEmailRecipient))
        }

        const payload = {
            selectedDocuments,
            notes,
            emails: adjustedRecipients.map(recipient => ({
                emailAddress: recipient,
                language: shareLanguage,
            })),
        }

        changeField("contactsRecipients", null)
        changeField("newEmailRecipient", "")
        setIsLoading(true)

        try {
            await Promise.all(
                bulkOrders.map(async selectedOrder => {
                    await goFetch(
                        `/documents/email/${selectedOrder?.associatedShipmentTrackingNumber}`,
                        {
                            method: "POST",
                            responseType: "blob",
                            data: payload,
                        },
                        true
                    )
                    return selectedOrder
                })
            )
            openSnackbar(
                "success",
                <FormattedMessage
                    id="documents.share__success"
                    defaultMessage="Documents successfully shared"
                />
            )
            onClose()
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="documents.share__error"
                    defaultMessage="Unable to share documents"
                />
            )
        } finally {
            if (selectAllClicked) {
                setSelectAllClicked(false)
            }
            setBulkOrders([])
            setIsLoading(false)
        }
    }

    const deleteRecipient = index => {
        const emailToDelete = recipients[index]
        const filteredRecipients = recipients.filter(
            email => email !== emailToDelete
        )
        setRecipients(filteredRecipients)
    }

    const disabled =
        !checked.includes(true) ||
        (recipients?.length === 0 &&
            newEmailRecipient &&
            newEmailRecipientError) ||
        (recipients?.length === 0 && !newEmailRecipient)

    return (
        <Grid item container className={classes.paper}>
            {isLoading && <GlobalSpinner />}
            <Grid item container className={classes.header}>
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography variant="h4">
                            <FormattedMessage
                                id="documents.share__title"
                                defaultMessage="Share Attachments"
                            />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => onClose("share")}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item container xs={4} alignContent="flex-start">
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <FormControlLabel
                        label="Select All Documents"
                        control={
                            <Checkbox
                                checked={checked[0] && checked[1]}
                                indeterminate={checked[0] !== checked[1]}
                                onChange={checkAll}
                            />
                        }
                    />
                    <Box
                        sx={{ display: "flex", flexDirection: "column", ml: 3 }}
                    >
                        <FormControlLabel
                            label="Labels"
                            control={
                                <Checkbox
                                    checked={checked[0]}
                                    onChange={checkLabels}
                                />
                            }
                        />
                        <FormControlLabel
                            label="Paper BOL"
                            control={
                                <Checkbox
                                    checked={checked[1]}
                                    onChange={checkBol}
                                />
                            }
                        />
                    </Box>
                </Box>
            </Grid>
            <Grid
                item
                container
                direction="column"
                xs={8}
                className={classes.recipients__container}
            >
                <Grid item container alignContent="flex-start">
                    <Grid
                        item
                        container
                        className={classes.addRecipients__title}
                    >
                        <Typography variant="h6">
                            <FormattedMessage
                                id="documents.share__recipients"
                                defaultMessage="Add Recipients"
                            />
                        </Typography>
                    </Grid>
                    <Grid item container xs={10}>
                        <Field
                            id="addUser__addNewEmail"
                            component={FormField}
                            name="newEmailRecipient"
                            label={
                                <FormattedMessage
                                    id="locations.addRecipients__addNewEmail"
                                    defaultMessage="Add New Email Address"
                                />
                            }
                            onKeyPress={e => {
                                if (
                                    e.key === "Enter" &&
                                    !newEmailRecipientError
                                ) {
                                    addRecipient(e.target.value)
                                }
                            }}
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        xs={2}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <IconButton
                            aria-label="Add"
                            onClick={() => {
                                addRecipient(newEmailRecipient)
                                GAEvent(
                                    gaCategory,
                                    "Add New Email Address Add Icon Click"
                                )
                            }}
                            disabled={
                                !newEmailRecipient || newEmailRecipientError
                            }
                        >
                            <AddIcon />
                        </IconButton>
                    </Grid>
                    <Grid item container>
                        <Typography
                            variant="body2"
                            className={classes.or__text}
                        >
                            <FormattedMessage
                                id="generalTerms__or"
                                defaultMessage="Or"
                            />
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        className={classes.contactsSelect}
                        xs={12}
                    >
                        <Field
                            className={classes.contactsSelect}
                            component={FormSelectAutocomplete}
                            name="contactsRecipients"
                            label={
                                <FormattedMessage
                                    id="locations.addRecipients__selectFromContact"
                                    defaultMessage="Select From Contact"
                                />
                            }
                            isLoading={!contactsLoaded}
                            options={contacts}
                            onChange={(e, value) => addRecipient(value)}
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container xs={4}>
                        <Grid item container>
                            <Field
                                name="language"
                                label={
                                    <FormattedMessage
                                        id="userPreferences.generalInformation__language"
                                        defaultMessage="Language"
                                    />
                                }
                                component={FormSelect}
                                className={classes.textField}
                                options={preferenceLanguageOptions}
                                category={gaCategory}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={8}>
                        <Grid
                            item
                            container
                            className={classes.recipient__chips}
                        >
                            {recipients
                                .filter(
                                    (email, index) =>
                                        recipients.indexOf(email) === index
                                )
                                .map((recipient, index) => (
                                    <Chip
                                        key={index}
                                        tabIndex={-1}
                                        avatar={
                                            <Avatar
                                                className={classes.chip__avatar}
                                            >
                                                <EmailIcon />
                                            </Avatar>
                                        }
                                        label={recipient}
                                        className={classes.chip}
                                        deleteIcon={<CancelIcon />}
                                        onDelete={() => {
                                            deleteRecipient(index)
                                            GAEvent(
                                                gaCategory,
                                                "Contact Chip Delete Click"
                                            )
                                        }}
                                    />
                                ))}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent="space-between"
                        className={classes.textField}
                    >
                        <Grid item container xs={12}>
                            <Field
                                name="notes"
                                label={
                                    <FormattedMessage
                                        id="documents.share__personalMessage"
                                        defaultMessage="Add a personal message"
                                    />
                                }
                                InputProps={{
                                    multiline: true,
                                    maxRows: 3,
                                }}
                                component={FormField}
                                category={gaCategory}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            className={classes.actions}
                            justifyContent="flex-end"
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={values => sendEmail(values)}
                                disabled={disabled}
                            >
                                <FormattedMessage
                                    id="documents.share__send"
                                    defaultMessage="Send"
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const validation = combineValidators({
    newEmailRecipient: emailValidator({
        field: {
            id: "generalTerms__emailAddress",
            defaultMessage: "Email Address",
        },
    }),
})

const mapStateToProps = (state, props) => {
    const contacts = []
    const { addresses = [] } = state?.addressBook

    const pickUpContactEmailList = []

    const originContactEmailList = []

    addresses.forEach(contact => {
        const contactInfo = contact?.contact
        contacts.push({
            value: contactInfo?.email?.emailAddress || "",
            label: contactInfo?.name,
        })
    })
    return {
        pickUpContactEmailList,
        originContactEmailList,
        contacts,
        newEmailRecipient:
            state?.form?.massShareAttachments?.values?.newEmailRecipient,
        newEmailRecipientError:
            state?.form?.massShareAttachments?.syncErrors?.newEmailRecipient,
        contactsRecipients:
            state?.form?.massShareAttachments?.values?.contactsRecipients,
        formDocuments: state?.form?.massShareAttachments?.values?.documents,
        shareLanguage: state?.form?.massShareAttachments?.values?.language,
        notes: state?.form?.massShareAttachments?.values?.notes,
    }
}

const mapDispatchToProps = dispatch => ({
    requestContactAddresses: () => dispatch(requestContactAddresses()),
    changeField: (field, value) =>
        dispatch(change("massShareAttachments", field, value)),
    GAEvent: (category, action) => dispatch(trackGAEvent(category, action)),
})

export const MassShareAttachments = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withStyles(styles)(
        reduxForm({
            form: "massShareAttachments", // a unique identifier for this form
            enableReinitialize: true,
            validate: validation,
        })(MassShareAttachmentsPresentation)
    )
)

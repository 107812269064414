import React from "react"
import { FormattedMessage } from "react-intl"

export const modeOptions = {
    shipperAndThirdParty: [
        {
            value: "outbound",
            label: (
                <FormattedMessage
                    id="bookShipment.mode__outbound"
                    defaultMessage="Outbound"
                />
            ),
        },
        {
            value: "inbound",
            label: (
                <FormattedMessage
                    id="bookShipment.mode__inbound"
                    defaultMessage="Inbound"
                />
            ),
        },
        {
            value: "thirdParty",
            label: (
                <FormattedMessage
                    id="bookShipment.mode__thirdParty"
                    defaultMessage="Third Party"
                />
            ),
        },
    ],
    shipperOnly: [
        {
            value: "outbound",
            label: (
                <FormattedMessage
                    id="bookShipment.mode__outbound"
                    defaultMessage="Outbound"
                />
            ),
        },
        {
            value: "inbound",
            label: (
                <FormattedMessage
                    id="bookShipment.mode__inbound"
                    defaultMessage="Inbound"
                />
            ),
        },
    ],
    doubleAccountFreightDirect: [
        {
            value: "outbound",
            label: (
                <FormattedMessage
                    id="bookShipment.mode__outbound"
                    defaultMessage="Outbound"
                />
            ),
        },
        {
            value: "thirdParty",
            label: (
                <FormattedMessage
                    id="bookShipment.mode__thirdParty"
                    defaultMessage="Third Party"
                />
            ),
        },
    ],
    doubleAccountFreightDirectReturns: [
        {
            value: "inbound",
            label: (
                <FormattedMessage
                    id="bookShipment.mode__inbound"
                    defaultMessage="Inbound"
                />
            ),
        },
        {
            value: "thirdParty",
            label: (
                <FormattedMessage
                    id="bookShipment.mode__thirdParty"
                    defaultMessage="Third Party"
                />
            ),
        },
    ],
}

export const modeDictionary = {
    inbound: (
        <FormattedMessage
            id="bookShipment.mode__inbound"
            defaultMessage="Inbound"
        />
    ),
    outbound: (
        <FormattedMessage
            id="bookShipment.mode__outbound"
            defaultMessage="Outbound"
        />
    ),
    thirdParty: (
        <FormattedMessage
            id="bookShipment.mode__thirdParty"
            defaultMessage="Third Party"
        />
    ),
}

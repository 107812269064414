import { Field, FieldArray, reduxForm } from "redux-form"
import { bookShipmentFormValidation } from ".."
import CloseIcon from "@material-ui/icons/Close"
import { Button, Grid, IconButton, Typography } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import FormRadio from "../../../form/form-radio"
import { Fragment } from "react"
import formField from "../../../form/form-field"
import ConfirmActionModal from "../../../common/ConfirmActionModal"

const ITNSection = ({ fields, classes, changeField }) => {
    if (fields.length === 0) {
        fields.push()
    }
    return (
        <Grid container item>
            <Grid
                item
                container
                className={classes.section}
                xs={12}
                alignItems="center"
            >
                <Button
                    className={classes.itemIcon}
                    onClick={() => {
                        if (fields.length < 5) {
                            fields.push()
                        }
                    }}
                    disabled={fields.length >= 5}
                    color="primary"
                >
                    +{" "}
                    <FormattedMessage
                        id="bookShipment.basicInfo__destination_AddItn"
                        defaultMessage="Add Internal Transaction Number"
                    ></FormattedMessage>
                </Button>
            </Grid>
            {fields.map((field, index) => (
                <Fragment>
                    <Grid xs={4} item>
                        <Field
                            name={`itns[${index}]`}
                            label={
                                <FormattedMessage
                                    id="bookShipment_ITN"
                                    defaultMessage="ITN #"
                                />
                            }
                            component={formField}
                        />
                    </Grid>
                    {fields.length > 1 && (
                        <Grid item xs={2} container alignItems="center">
                            <IconButton onClick={() => fields.remove(index)}>
                                <CloseIcon className={classes.itemIcon} />
                            </IconButton>
                        </Grid>
                    )}
                </Fragment>
            ))}
        </Grid>
    )
}

export const openEEIConfirmationModal = (openModal, handleConfirm) => {
    openModal(
        <ConfirmActionModal
            classes
            handleConfirm={handleConfirm}
            message={
                <FormattedMessage
                    id={"bookShipment.basicInfo__destination_ItnModal"}
                    defaultMessage="To ensure smooth shipment processing, email the required ITNs to offshore-aes@fedex.com promptly."
                ></FormattedMessage>
            }
            title="ITN Requirement"
        ></ConfirmActionModal>
    )
}

const ElectronicExportInformation = ({ formValues, changeField, classes }) => {
    const EEIRadioOptions = [
        {
            value: true,
            label: (
                <FormattedMessage
                    id={"bookShipment.EEI_radio_fedexFile"}
                    defaultMessage="FedEx will file my EEI"
                ></FormattedMessage>
            ),
        },
        {
            value: false,
            label: (
                <FormattedMessage
                    id={"bookShipment.EEI_radio_customerFile"}
                    defaultMessage="I will file my own EEI"
                ></FormattedMessage>
            ),
        },
    ]

    const normalizeBoolean = value => value === "true"

    return (
        <Grid
            alignContent="center"
            justifyContent="center"
            item
            container
            className={classes.section}
        >
            <Grid xs={12} item>
                <Grid
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    container
                    item
                    xs={12}
                >
                    <Grid container item>
                        <Typography>
                            <FormattedMessage
                                id="bookShipment.basicInfo__destination_EEI"
                                defaultMessage="Electronic Export Information (EEI)"
                            />
                        </Typography>
                    </Grid>
                    <Grid container item>
                        <Typography variant="caption">
                            <FormattedMessage
                                id="bookShipment.EEI_notice"
                                defaultMessage="Commodities valued more than $2,500 may require EEI filing please contact offshore-aes@fedex.com for more information"
                            ></FormattedMessage>
                        </Typography>
                    </Grid>
                </Grid>
                <Field
                    name="isFedexFileEEI"
                    component={FormRadio}
                    options={EEIRadioOptions}
                    normalize={normalizeBoolean}
                />
            </Grid>
            {formValues.isFedexFileEEI === false ? (
                <Grid container direction="row" item>
                    <FieldArray
                        name="itns"
                        component={ITNSection}
                        classes={classes}
                        changeField={changeField}
                    />
                </Grid>
            ) : null}
        </Grid>
    )
}

export default ElectronicExportInformation

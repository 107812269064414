import { Tooltip } from "@material-ui/core"
import React from "react"

const TooltipWrapper = ({ children, message }) => {
    return <Tooltip title={message}>{children}</Tooltip>
}

export const conditionalToolTip = (showToolTip, message, component) => {
    if (showToolTip) {
        return <TooltipWrapper message={message}>{component}</TooltipWrapper>
    } else {
        return component
    }
}

import React, { useState } from "react"
import {
    Box,
    Grid,
    IconButton,
    Popover,
    Radio,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core"
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons"
import { currencyLabel } from "../util/currencyLabel"
import { useBookedShipmentContext } from "../../context/providers/BookedShipmentProvider"
import OrdersRateBreakdown from "./OrdersRateBreakdown"
import { conditionalToolTip } from "./TooltipWrapper"
import { FormattedMessage } from "react-intl"

const OrderRate = ({
    canShip,
    rate,
    handleSelectRate,
    selectedRate,
    orderId,
    order,
}) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const theme = useTheme()
    const { generateOrdersToBookList } = useBookedShipmentContext()

    const handleDropdownClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "simple-popover" : undefined

    const displayBookNow = !rate?.showRateAmounts
    return (
        <Box
            key={rate?._id}
            item
            sx={{
                height: "100%",
                margin: "0 5px",
                flex: "0 0 auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                border:
                    selectedRate === rate?._id
                        ? `2px solid ${theme.palette.primary.main}`
                        : `2px solid transparent`,
                backgroundColor: "transparent",
                borderRadius: theme.shape.borderRadius,
            }}
        >
            {(displayBookNow ||
                (rate?.rateQuoteDetail?.totalInPreferredCurrency &&
                    !displayBookNow)) &&
                conditionalToolTip(
                    !canShip,
                    <FormattedMessage
                        defaultMessage="You do not have permission to book orders with this account"
                        id="orders.shipmentPermissions"
                    />,
                    <Grid item>
                        <Radio
                            disabled={!canShip}
                            checked={selectedRate === rate?._id}
                            onClick={() => {
                                handleSelectRate(orderId, rate?._id)
                                generateOrdersToBookList(order, rate)
                            }}
                        />
                    </Grid>
                )}
            <Grid item style={{ textAlign: "center" }}>
                <Typography>
                    {!displayBookNow &&
                    rate?.rateQuoteDetail?.totalInPreferredCurrency ? (
                        currencyLabel(
                            rate?.preferredCurrencyCode,
                            rate.rateQuoteDetail.totalInPreferredCurrency ??
                                "--"
                        )
                    ) : displayBookNow ? (
                        <FormattedMessage
                            id="rateResults__bookNow"
                            defaultMessage="Book Now"
                        />
                    ) : (
                        "--"
                    )}
                </Typography>
            </Grid>

            {!displayBookNow &&
                rate?.rateQuoteDetail?.totalInPreferredCurrency && (
                    <Grid item>
                        <IconButton
                            aria-describedby={id}
                            aria-label="expand row"
                            size="small"
                            onClick={handleDropdownClick}
                        >
                            {anchorEl ? (
                                <KeyboardArrowUp />
                            ) : (
                                <KeyboardArrowDown />
                            )}
                        </IconButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                        >
                            <OrdersRateBreakdown
                                charges={rate?.rateQuoteDetail?.charges}
                                quoteId={rate?.capacityProviderQuoteNumber}
                                totalInPreferredCurrency={
                                    rate?.rateQuoteDetail
                                        ?.totalInPreferredCurrency
                                        ? rate?.rateQuoteDetail
                                              ?.totalInPreferredCurrency
                                        : rate?.rateQuoteDetail?.total
                                }
                                preferredCurrencyCode={
                                    rate?.preferredCurrencyCode
                                }
                            />
                        </Popover>
                    </Grid>
                )}
        </Box>
    )
}

export default OrderRate

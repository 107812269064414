import React, { useState, createContext, useContext, useMemo } from "react"
import { FormattedMessage } from "react-intl"
import { goFetch } from "../../http"
import { useSnackbarContext } from "./snackbarProvider"
import { useGAContext } from "./GoogleAnalyticsProvider"
import { debounce } from "@material-ui/core"

export const ItemsContext = createContext()

export const useItemsContext = () => {
    const itemsContext = useContext(ItemsContext)
    if (!itemsContext) {
        throw new Error("Cannot use items context ouside of ItemsProvider")
    }
    return itemsContext
}

export const initItemsData = {
    content: [],
    pageable: {
        sort: {
            empty: true,
            sorted: false,
            unsorted: true,
        },
        offset: 0,
        pageSize: 10,
        pageNumber: 1,
        paged: true,
        unpaged: false,
    },
    totalPages: 0,
    totalElements: 0,
    last: false,
    size: 0,
    number: 0,
    sort: {
        empty: true,
        sorted: false,
        unsorted: true,
    },
    numberOfElements: 0,
    first: false,
    empty: true,
}

export default function ItemsProvider({ children }) {
    const [tableData, setTableData] = useState(initItemsData)
    const [itemToEdit, setItemToEdit] = useState(null)
    const [loading, setLoading] = useState(false)
    const [addingItem, setAddingItem] = useState(false)
    const [measurementSystem, setMeasurementSystem] = useState("IMPERIAL")
    const [isHazmat, setIsHazmat] = useState(false)
    const [deleting, setDeleting] = useState(null)
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchValue, setSearchValue] = useState("")

    const { openSnackbar } = useSnackbarContext()
    const { logGAEvent } = useGAContext()

    const getItemById = async itemId => {
        setLoading(true)
        try {
            const { data } = await goFetch(
                `/item/${itemId}`,
                { validErrorCodes: [404] },
                true
            )
            setLoading(false)
            return data
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="item.fetch.item.error"
                    defaultMessage="There was an error fetching item."
                />,
                2000
            )
            setLoading(false)
        }
    }

    const getPaginatedItemsData = async (pageNumber, pageSize, searchQuery) => {
        const params = {
            pageNumber,
            pageSize,
        }
        if (searchQuery) {
            params["q"] = searchQuery
        } else if (searchValue) {
            params["q"] = searchValue
        }
        try {
            const { data } = await goFetch(
                "/item/list",
                {
                    method: "GET",
                    credentials: "same-origin",
                    params,
                },
                true
            )
            setTableData(data)
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="item.fetch.list.error"
                    defaultMessage="There was an error fetching items."
                />,
                2000
            )
        }
    }

    const saveItem = async data => {
        setLoading(true)
        logGAEvent("Items", `Item Added`)
        try {
            const response = await goFetch(
                "/item",
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data,
                },
                true
            )

            await getPaginatedItemsData(1, rowsPerPage)

            openSnackbar(
                "success",
                <FormattedMessage
                    id="items.add.success"
                    defaultMessage="{name} added"
                    values={{ name: response.data.description }}
                />,
                4000
            )
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="items.add.error"
                    defaultMessage="Error adding {name}"
                    values={{ name: error.data.description }}
                />,
                4000
            )
        }
        setLoading(false)
    }

    const updateItem = async (data, id) => {
        logGAEvent("Items", `Item Updated`)
        try {
            const response = await goFetch(
                `/item/${id}`,
                {
                    method: "PUT",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        ...data,
                        _id: id,
                    },
                },
                true
            )
            getPaginatedItemsData(page, rowsPerPage)

            openSnackbar(
                "success",
                <FormattedMessage
                    id="items.edit.success"
                    defaultMessage="{name} updated"
                    values={{ name: response?.data?.description }}
                />,
                4000
            )
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="items.edit.error"
                    defaultMessage="Error updating item"
                />,
                4000
            )
        } finally {
            setLoading(false)
        }
    }

    const editItem = async itemId => {
        const item = await getItemById(itemId)
        setItemToEdit(item)
    }

    const deleteItem = async itemToDelete => {
        setDeleting(itemToDelete._id)
        try {
            await goFetch(
                `/item/${itemToDelete._id}`,
                {
                    method: "DELETE",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                },
                true
            )
            logGAEvent("Items", "Item Deleted")
            await getPaginatedItemsData(page, rowsPerPage)

            openSnackbar(
                "success",
                <FormattedMessage
                    id="item.delete.delete.success"
                    defaultMessage="{field} has been removed from your item list."
                    values={{ field: itemToDelete?.description }}
                />,
                2000
            )
        } catch (e) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="item.delete.delete.failure"
                    defaultMessage="Failure deleting item {field}"
                    values={{ field: itemToDelete?.description }}
                />,
                2000
            )
        }
        setDeleting(null)
    }

    const handleItemSearch = searchQuery => {
        getPaginatedItemsData(1, rowsPerPage, searchQuery)
    }

    const debouncedHandleItemSearch = useMemo(() => {
        return debounce(handleItemSearch, 300)
    }, [rowsPerPage])

    const contextData = {
        loading,
        setLoading,
        deleteItem,
        addingItem,
        setAddingItem,
        saveItem,
        updateItem,
        measurementSystem,
        setMeasurementSystem,
        isHazmat,
        setIsHazmat,
        getPaginatedItemsData,
        editItem,
        tableData,
        deleting,
        setDeleting,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        debouncedHandleItemSearch,
        searchValue,
        setSearchValue,
        itemToEdit,
        setItemToEdit,
    }

    return (
        <ItemsContext.Provider value={contextData}>
            {children}
        </ItemsContext.Provider>
    )
}

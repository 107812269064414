import React from "react"
import { SubmissionError } from "redux-form"
import qs from "qs"
import { get } from "lodash"
import { closeModal, openModal } from "./modal"
import { changePath, errorMessage } from "./index"
import { addressBody, requestContactAddresses, selectContact } from "./address"
import { goFetch } from "../http"
import { trackGAEvent } from "./user"
import { snakesToCamelsDeep } from "./util/sanitization"
import { constructPostalCode } from "./validation"

export const REQUEST_NEW_CONTACT = "REQUEST_NEW_CONTACT"
export const CONTACT_SEARCH_RESULT = "CONTACT_SEARCH_RESULT"
export const QUEUE_CONTACT_SEARCH = "QUEUE_CONTACT_SEARCH"
export const CONTACT_SEARCH_RETURN = "CONTACT_SEARCH_RETURN"
export const CONTACT_DELETED = "CONTACT_DELETED"

export function clickNewBookContact(dispatch, resolve) {
    return () => {
        dispatch(closeModal())
        dispatch({ type: REQUEST_NEW_CONTACT })
        resolve()
    }
}

export function clickUpdateBookContact(dispatch, resolve) {
    return () => {
        dispatch(closeModal())
        resolve()
    }
}

export function shipToContact(id) {
    return (dispatch, getState) => {
        dispatch(trackGAEvent("Contact", "Ship To"))
        const q = qs.stringify({ to: id })
        dispatch(changePath(`/book?${q}`))
    }
}

const contactPayload = ({
    name,
    phone,
    extension,
    mobile,
    mail,
    pickupContact,
}) => ({
    name,
    phone: {
        phone_number: phone,
        extension,
        mobile,
    },
    email: {
        email_address: mail,
    },
    pickupContact,
})

export const displayFullPhone = phone =>
    [phone?.phone_number, phone?.extension].filter(x => x).join(", ext. ")

export function saveContact(data) {
    return async dispatch => {
        const { pickupAccessorials = [], deliveryAccessorials = [], id } = data

        const contact = contactPayload(data)
        const address = addressBody(data)

        let adjustedPickupAccessorials
        let adjustedDeliveryAccessorials

        if (typeof pickupAccessorials === "string") {
            adjustedPickupAccessorials = pickupAccessorials.split(",")
        } else {
            adjustedPickupAccessorials = pickupAccessorials
        }

        if (typeof deliveryAccessorials === "string") {
            adjustedDeliveryAccessorials = deliveryAccessorials.split(",")
        } else {
            adjustedDeliveryAccessorials = deliveryAccessorials
        }

        if (pickupAccessorials?.length > 0) {
            adjustedPickupAccessorials = adjustedPickupAccessorials.map(
                entry => {
                    return { value: entry }
                }
            )
        }
        if (deliveryAccessorials?.length > 0) {
            adjustedDeliveryAccessorials = adjustedDeliveryAccessorials.map(
                entry => {
                    return { value: entry }
                }
            )
        }
        try {
            const { data: retVal } = await goFetch(
                `/addresses/contact${id ? `/${id}` : ""}`,
                {
                    method: id ? "PUT" : "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        address,
                        contact,
                        pickupAccessorials: adjustedPickupAccessorials,
                        deliveryAccessorials: adjustedDeliveryAccessorials,
                    },
                },
                true
            )
            return retVal
        } catch (error) {
            dispatch(errorMessage(error))
            throw SubmissionError
        }
    }
}

export function saveSelectedContact() {
    return async (dispatch, getState) => {
        try {
            const selected = getState().form.contact.values
            const savedContact = await dispatch(saveContact(selected))
            await dispatch(requestContactAddresses())
            return dispatch(selectContact(get(savedContact, "contact._id")))
        } catch (error) {
            dispatch(errorMessage(error))
            throw SubmissionError
        }
    }
}

const contactSearchResult = (value, kind, data = []) => ({
    type: CONTACT_SEARCH_RESULT,
    kind,
    value,
    contacts: data,
})

export const getContactKey = (value, country) =>
    country ? `${country}_${value}` : value

function innerSearchContacts(value, kind, postalCode, country) {
    return async dispatch => {
        try {
            const params = {
                ...(country && { country }),
                ...(["quote", "email_name", "dashboard"].includes(kind) && {
                    contactName: value,
                }),
                ...([
                    "dashboard",
                    "quote",
                    "book_origin",
                    "book_destination",
                ].includes(kind) && { companyName: value }),
                ...(kind === "quote" && {
                    postalCode: constructPostalCode(value, country),
                }),
                ...(kind === "email_email" && { email: value }),
                ...(kind === "bolEntry" && {
                    postalCode,
                    companyName: value,
                    strict: true,
                }),
                limit: 10,
                ...(kind === "dashboard" && { limit: 5 }),
            }
            const { data, status } = await goFetch(
                "/addresses/search",
                { validErrorCodes: [404], params },
                true
            )
            const formattedContacts = snakesToCamelsDeep(data)
            if (status === 404) return dispatch(contactSearchResult(value))
            return dispatch(
                contactSearchResult(
                    getContactKey(value, country),
                    kind,
                    formattedContacts
                )
            )
        } catch (error) {
            return dispatch(errorMessage(error))
        }
    }
}

export function searchContacts(value, kind, postalCode, country) {
    return (dispatch, getState) => {
        const { timeoutId } = getState().contact
        clearTimeout(timeoutId)
        if (!value || value.length < 3) return
        const result = getState().contact.search[kind][
            getContactKey(value, country)
        ]
        if (result) {
            dispatch({ type: CONTACT_SEARCH_RETURN })
            return getContactKey(value, country)
        }
        const newTimeoutId = setTimeout(
            () =>
                dispatch(
                    innerSearchContacts(
                        value,
                        kind,
                        constructPostalCode(postalCode, country),
                        country
                    )
                ),
            1000
        )
        dispatch({ type: QUEUE_CONTACT_SEARCH, id: newTimeoutId })
        return getContactKey(value, country)
    }
}

function determinePostalCode(postalCode, country) {
    if (country === "US") {
        return `00000${postalCode}`.slice(-5)
    } else return postalCode
}

export function importContacts(data) {
    return async dispatch => {
        const transformedData = data.map(item => ({
            address: {
                name: item.address_name,
                address: {
                    street1: item.address_1,
                    street2: item.address_2,
                    postalCode: determinePostalCode(
                        item.postal_code,
                        item.country
                    ),
                    state: item.state,
                    city: item.city,
                    country: item.country,
                },
            },
            contact: {
                name: item.name,
                phone: {
                    phone_number: item.phone,
                    extension: item.extension,
                },
                email: {
                    email_address: item.email,
                },
                pickupContact: {
                    name: item.pickupContact_name,
                    phone: {
                        phone_number: item.pickupContact_phone,
                        extension: item.pickupContact_extension,
                    },
                    email: {
                        email_address: item.pickupContact_email,
                    },
                    readyTime: item.pickupContact_readyTime,
                    closeTime: item.pickupContact_closeTime,
                },
            },
        }))
        return goFetch(
            "/addresses/import",
            {
                method: "POST",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
                data: transformedData,
            },
            true
        )
    }
}

export function deleteContact() {
    return async (dispatch, getState) => {
        try {
            const selected = getState().addressBook.selected
            const response = await goFetch(
                `addresses/contact/${`${selected.id}`}`,
                {
                    method: "DELETE",
                    credentials: "same-origin",
                    headers: { "cache-control": "no-cache" },
                },
                true
            )
            if (!response) throw new Error()
            dispatch(requestContactAddresses())
            return dispatch({ type: CONTACT_DELETED })
        } catch (error) {
            dispatch(errorMessage(error))
            throw SubmissionError
        }
    }
}

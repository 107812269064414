import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
    modal__window: {
        "max-width": "800px",
        "min-height": "300px",
        margin: "0 auto",
        padding: "30px",
        "border-radius": "6px",
        "background-color": "#fff",
    },
    modal__backdrop: {
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        padding: "15px",
        "background-color": "rgba(0,0,0,.5)",
    },
    modal_btn: {
        padding: "10px",
    },
})

export function ModalPresentation(props) {
    const { isOpen, node, Wrapper, classes } = props

    return (
        isOpen && (
            <div
                className="modal-backdrop"
                role="presentation"
                onClick={e => e.stopPropagation()}
            >
                <div className={classes.modal__window}>
                    <Wrapper>{node}</Wrapper>
                </div>
            </div>
        )
    )
}

ModalPresentation.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    node: PropTypes.node,
    Wrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
}

ModalPresentation.defaultProps = {
    node: null,
    props: {},
    Wrapper: "div",
}

const mapStateToProps = state => state.modal

export const Modal = withStyles(styles)(
    connect(mapStateToProps)(ModalPresentation)
)

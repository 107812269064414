import React from "react"
import {
    Typography,
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Badge,
    Box,
} from "@material-ui/core"
import { ExpandMore } from "@material-ui/icons"
import { FormattedMessage } from "react-intl"
import LocationListItem from "./LocationRow"
import ListItem from "./ListItem"

const ExpandableListFilter = ({
    title,
    options,
    classes,
    isLocationFilter,
    selectedFilters,
    setSelectedFilters,
}) => {
    const handleToggle = value => {
        const index = selectedFilters.indexOf(value)
        if (index === -1) {
            setSelectedFilters([...selectedFilters, value])
        } else {
            selectedFilters.splice(index, 1)
            setSelectedFilters([...selectedFilters])
        }
    }

    return (
        <Box sx={{ width: "100%" }}>
            <ExpansionPanel elevation={0}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="locationFilter"
                    className={classes.panelSummary}
                >
                    <Typography
                        variant="h6"
                        color={selectedFilters.length > 0 ? "primary" : ""}
                    >
                        {title}
                    </Typography>
                    {selectedFilters.length > 0 && (
                        <Badge
                            badgeContent={selectedFilters.length}
                            color="primary"
                            className={classes.badgePadding}
                        />
                    )}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Box sx={{ width: "100%" }}>
                        {options?.map(item => {
                            if (isLocationFilter) {
                                return (
                                    <LocationListItem
                                        checked={selectedFilters.includes(
                                            item.value
                                        )}
                                        handleToggle={handleToggle}
                                        location={item}
                                        key={item?.value}
                                    />
                                )
                            } else {
                                return (
                                    <ListItem
                                        key={item?.value}
                                        handleToggle={handleToggle}
                                        item={item}
                                        checked={selectedFilters.includes(
                                            item.value
                                        )}
                                    />
                                )
                            }
                        })}
                    </Box>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Box>
    )
}

export default ExpandableListFilter

import React, { useState } from "react"
import Grid from "@material-ui/core/Grid"
import WarningIcon from "@material-ui/icons/Warning"
import { withStyles } from "@material-ui/core/styles"
import { CircularProgress, Typography } from "@material-ui/core"
import moment from "moment"
import { FormattedMessage } from "react-intl"
import { useSnackbarContext } from "../../context/providers/snackbarProvider"
import { connect } from "react-redux"
import { supportedTiles } from "./tiles/constants"
import { dismissAlert } from "../../actions/alerts"
import { updateTileCounts } from "../../actions/track"
import { useTileCountContext } from "../../context/providers/TileCountProvider"

const styles = theme => ({
    alert__box__container: {
        backgroundColor: "#D4D4D4",
        padding: "10px",
        borderBottom: "1px solid #A9A9A9",
    },
    dismiss__button: {
        "&:hover": {
            cursor: "pointer",
            color: theme.palette.secondary.main,
        },
        "&:active": {
            color: theme.palette.secondary.light,
        },
    },
    whiteSpace: {
        whiteSpace: "pre-wrap",
    },
    created_at: {
        paddingRight: "2px",
    },
})

const DashboardItemAlertBox = ({
    classes,
    dismissAlert,
    event,
    shipmentId,
    proNumber,
    activeDashboardTiles,
    trackGA,
    updateTileCounts,
}) => {
    const { openSnackbar } = useSnackbarContext()
    const [isLoading, setIsLoading] = useState(false)
    const { setTileCount } = useTileCountContext()

    const activeAlertTiles = []
    const allAlertTiles = supportedTiles.filter(tile => tile.isAlertRelated)
    if (activeDashboardTiles) {
        for (let tile of allAlertTiles) {
            if (activeDashboardTiles.includes(tile.value)) {
                activeAlertTiles.push(tile.value)
            }
        }
    }

    return (
        <Grid
            container
            className={classes.alert__box__container}
            alignItems="center"
        >
            <Grid item container xs={1} justify="flex-start">
                <WarningIcon />
            </Grid>
            <Grid item container xs={3} justify="flex-start">
                {proNumber && (
                    <Typography variant="body2">{`#${proNumber}`}</Typography>
                )}
            </Grid>
            <Grid
                item
                container
                xs={7}
                alignItems="center"
                justify="flex-start"
                className={classes.whiteSpace}
            >
                {event.created_at ? (
                    <Typography
                        className={classes.created_at}
                        variant="caption"
                    >{`${moment(event.created_at).format(
                        "dddd MMM-Do h:mm A"
                    )}: `}</Typography>
                ) : null}
                <Typography variant="body2">{event.description}</Typography>
            </Grid>
            {!event.dismissed ? (
                <Grid
                    item
                    container
                    xs={1}
                    alignItems="center"
                    justify="flex-end"
                >
                    {isLoading ? (
                        <CircularProgress size={20} color="secondary" />
                    ) : (
                        <Typography
                            variant="body2"
                            color="primary"
                            className={classes.dismiss__button}
                            disabled={isLoading}
                            onClick={async () => {
                                setIsLoading(true)
                                try {
                                    await dismissAlert(shipmentId, event._id)
                                    if (activeAlertTiles.length) {
                                        updateTileCounts(
                                            activeAlertTiles,
                                            setTileCount
                                        )
                                    }
                                    trackGA(
                                        "Dashboard Item Alert Box",
                                        "Dismiss Alert Success"
                                    )
                                } catch (error) {
                                    openSnackbar(
                                        "error",
                                        <FormattedMessage
                                            id="dashboard.item.Alertbox__dismissError"
                                            defaultMessage="Error dismissing warning"
                                        />,
                                        4000
                                    )
                                    trackGA(
                                        "Dashboard Item Alert Box",
                                        "Dismiss Alert Failed"
                                    )
                                } finally {
                                    setIsLoading(false)
                                }
                            }}
                        >
                            <FormattedMessage
                                id="alertBox__dismiss"
                                defaultMessage="Dismiss"
                            />
                            {isLoading && (
                                <CircularProgress size={20} color="secondary" />
                            )}
                        </Typography>
                    )}
                </Grid>
            ) : null}
        </Grid>
    )
}

function mapStateToProps(state) {
    const activeDashboardTiles = state?.user?.profile?.preferences?.tileSettings
    return {
        activeDashboardTiles,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dismissAlert: (shipmentId, alertId) =>
            dispatch(dismissAlert(shipmentId, alertId)),
        updateTileCounts: (selectedTiles, setTileCount) =>
            dispatch(updateTileCounts(selectedTiles, setTileCount)),
    }
}

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(DashboardItemAlertBox)
)

import React, { useContext } from "react"
import { FormName, ReduxFormContext } from "redux-form"

const formName = Comp => props => (
    <FormName children={formProps => <Comp {...props} {...formProps} />} />
)

export const useReduxFormContext = () => {
    const reduxFormContext = useContext(ReduxFormContext)
    if (!reduxFormContext) {
        throw new Error("Cannot use redux-form context outside of Redux Form")
    }
    return reduxFormContext
}

export default formName

import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Route, withRouter } from "react-router-dom"
import { acceptedTerms, componentType } from "./private-route"

const routePropTypes = {
    Comp: componentType().isRequired,
    user: PropTypes.object.isRequired,
    props: PropTypes.object,
}

export const Chat = ({ Comp, user, props, terms }) => {
    if (user.preferences.isLoaded && acceptedTerms(user, terms)) {
        return <Comp {...props} />
    }
    return <div className={"hide"} />
}

Chat.propTypes = {
    ...routePropTypes,
    terms: PropTypes.object.isRequired,
}

Chat.defaultProps = {
    props: { terms: {} },
}

export class UnauthenticatedPresentation extends Component {
    render() {
        const { Type, component, user, terms, ...rest } = this.props
        return <Type Comp={component} user={user} props={rest} terms={terms} />
    }
}

UnauthenticatedPresentation.propTypes = {
    component: componentType().isRequired,
    user: PropTypes.object,
    terms: PropTypes.object,
    Type: componentType().isRequired,
}

UnauthenticatedPresentation.defaultProps = {
    component: undefined,
    user: {},
    terms: {},
}

const mapStateToProps = ({ user, terms }) => ({ user, terms })

const UnauthenticatedInnerRoute = withRouter(
    connect(mapStateToProps)(UnauthenticatedPresentation)
)

const UnauthenticatedRoute = ({ component, Type, ...rest }) => (
    <Route
        {...rest}
        render={() => (
            <UnauthenticatedInnerRoute
                {...{ component, ...rest }}
                Type={Type}
            />
        )}
    />
)

UnauthenticatedRoute.propTypes = {
    component: componentType().isRequired,
    Type: componentType().isRequired,
}

export const ChatRoute = props => (
    <UnauthenticatedRoute {...props} Type={Chat} />
)

import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { FormattedMessage } from "react-intl"
import { Field } from "redux-form"
import FormField from "../../../form/form-field"
import FormCheckbox from "../../../form/form-checkbox"

export default function TaxIdInfo({
    unknownOriginTaxId,
    unknownDestinationTaxId,
    changeField,
    originCountry,
    destinationCountry,
    gaCategory,
}) {
    const getDefaultTaxId = country => {
        return country === "MX" ? "XAXX010101000" : "XEXX010101000"
    }
    return (
        <Grid xs={12} item>
            <Grid>
                <Grid item container justify="center">
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="bookedShipment.tax_id_info"
                            defaultMessage="Tax Identification Information"
                        />
                    </Typography>
                </Grid>
                <Grid item container>
                    <Typography variant="subtitle2">
                        <FormattedMessage
                            id="bookedShipment.tax_id_msg"
                            defaultMessage="Carta Porte are required on the shipment please enter below:"
                        />
                    </Typography>
                </Grid>
                <Grid xs={12} item container>
                    <Grid xs={7} item container>
                        <Field
                            name="origin.taxIdentification.number"
                            label={
                                <FormattedMessage
                                    id="generalTerms__taxIdShipper"
                                    defaultMessage="Shipper Tax ID"
                                />
                            }
                            required={!unknownOriginTaxId}
                            component={FormField}
                            disabled={unknownOriginTaxId}
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid xs={5} alignItems="flex-end" item container>
                        <Field
                            name="unknownOriginTaxId"
                            label={
                                <FormattedMessage
                                    id="bookedShipment.unknown_tax_id_shipper"
                                    defaultMessage="I do not know my Shipper Tax ID"
                                />
                            }
                            component={FormCheckbox}
                            onChange={() => {
                                changeField(
                                    "origin.taxIdentification.number",
                                    getDefaultTaxId(originCountry)
                                )
                            }}
                            category={gaCategory}
                        />
                    </Grid>
                </Grid>
                <Grid item container>
                    <Grid xs={7} item container>
                        <Field
                            name="destination.taxIdentification.number"
                            label={
                                <FormattedMessage
                                    id="generalTerms__taxIdConsignee"
                                    defaultMessage="Consignee Tax Id"
                                />
                            }
                            required={!unknownDestinationTaxId}
                            component={FormField}
                            disabled={unknownDestinationTaxId}
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid xs={5} alignItems="flex-end" item container>
                        <Field
                            name="unknownDestinationTaxId"
                            label={
                                <FormattedMessage
                                    id="bookedShipment.unknown_tax_id_consignee"
                                    defaultMessage="I do not know my Consignee Tax ID"
                                />
                            }
                            component={FormCheckbox}
                            onChange={() => {
                                changeField(
                                    "destination.taxIdentification.number",
                                    getDefaultTaxId(destinationCountry)
                                )
                            }}
                            category={gaCategory}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

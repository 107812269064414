import React, { useEffect, useState } from "react"
import {
    Checkbox,
    Grid,
    FormControl,
    Input,
    InputLabel,
    FormHelperText,
    FormControlLabel,
    Typography,
} from "@material-ui/core"
import { validateInput, stringToNumber } from "./AlaskaUtils"
import { FormattedMessage } from "react-intl"

const AlaskaOceanChargeField = ({
    index,
    defaultValue,
    label,
    initialValue,
    currentFormValues,
    fieldRef,
    handleStaticFieldChange,
}) => {
    const [checked, setChecked] = useState(false)
    const [input, setInput] = useState("")
    const [error, setError] = useState("")
    useEffect(() => {
        if (initialValue?.isPercentageOff) {
            setChecked(true)
        }
        if (initialValue?.value) {
            setInput(initialValue.value)
        }
    }, [initialValue])

    const onInputChange = evt => {
        const inputValue = validateInput(evt.target.value)
        setInput(inputValue)
        const currentCharge = {
            value: evt.target.value !== "" ? stringToNumber(inputValue) : null,
            isPercentageOff: currentFormValues[fieldRef]
                ? currentFormValues[fieldRef].isPercentageOff
                : false,
        }
        const newFormValues = {
            ...currentFormValues,
            [fieldRef]: currentCharge,
        }
        handleStaticFieldChange(index, newFormValues)
    }

    const onCheckboxChange = evt => {
        setChecked(!checked)
        setInput("")
        const currentCharge = {
            value: null,
            isPercentageOff: !checked,
        }
        const newFormValues = {
            ...currentFormValues,
            [fieldRef]: currentCharge,
        }
        handleStaticFieldChange(index, newFormValues)
    }

    return (
        <Grid style={{ paddingRight: "20px" }} item container>
            <Grid item container direction="column">
                <FormControl error={error}>
                    <InputLabel>{label}</InputLabel>
                    <Input
                        value={input}
                        onChange={onInputChange}
                        autoComplete={false}
                        inputProps={{
                            min: 0,
                            style: { textAlign: checked ? "right" : "left" },
                            inputMode: "decimal",
                        }}
                        endAdornment={
                            checked ? (
                                <Typography style={{ paddingLeft: "4px" }}>
                                    %
                                </Typography>
                            ) : null
                        }
                        startAdornment={
                            !checked ? (
                                <Typography style={{ paddingRight: "4px" }}>
                                    $
                                </Typography>
                            ) : null
                        }
                    />
                    {defaultValue ? (
                        <FormHelperText>
                            <FormattedMessage
                                id="location.offshore.default"
                                defaultMessage="Default: ${value}"
                                values={{ value: defaultValue }}
                            />
                        </FormHelperText>
                    ) : null}
                    {error ? <FormHelperText>{error}</FormHelperText> : null}
                </FormControl>
                <FormControl style={{ width: "100px" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={initialValue?.isPercentageOff}
                                onClick={onCheckboxChange}
                                value={checked}
                            />
                        }
                        label={
                            <FormattedMessage
                                id="generalTerms__percentageOff"
                                defaultMessage="% off"
                            />
                        }
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default AlaskaOceanChargeField

import React from "react"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    withStyles,
} from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { Field, getFormValues } from "redux-form"
import FormField from "../../form/form-field"
import FormSwitch from "../../form/form-switch"
import { supportedCountryCodes } from "../../../misc"
import FormSelect from "../../form/form-select"
import FormSelectAutocomplete from "../../form/form-select-autocomplete"
import formZipCode from "../../form/form-zip-code"
import { connect } from "react-redux"
import { get } from "lodash"
import FormCheckbox from "../../form/form-checkbox"

const styles = theme => ({
    checkbox__container: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
})

const RedirectP44Dialog = props => {
    const { classes, gaCategory, dialogOpen, handleClose, onContinue } = props

    return (
        <Dialog open={dialogOpen} onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">
                <FormattedMessage
                    id="locations.carriers__redirectNotice"
                    defaultMessage="Redirect Notice"
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage
                        id="locations.carriers__redirectDetails"
                        defaultMessage="LTL Select connects shippers to LTL carriers. This platform is provided by FedEx but run by a third party.  To enter your other carriers, you will be taken to project44 (p44).  FedEx does not see your accounts or transactions with any other carriers."
                    />
                </DialogContentText>
                <Field
                    name="formDNA"
                    component={FormCheckbox}
                    label={
                        <FormattedMessage
                            id="locations.carriers__doNotAsk"
                            defaultMessage="Do not ask me again"
                        />
                    }
                    containerStyle={classes.checkbox__container}
                    enableSwitch
                    category={gaCategory}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={handleClose}
                    color="secondary"
                    autoFocus
                >
                    <FormattedMessage
                        id="generalTerms__cancel"
                        defaultMessage="Cancel"
                    />
                </Button>
                <Button
                    onClick={onContinue}
                    variant="contained"
                    color="primary"
                    autoFocus
                >
                    <FormattedMessage
                        id="generalTerms__continue"
                        defaultMessage="Continue"
                    />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withStyles(styles)(RedirectP44Dialog)

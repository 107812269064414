import React, { useEffect, useState } from "react"
import {
    Box,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core"
import { useAccessorialsContext } from "../../context/providers/AccessorialsProvider"
import { FormattedMessage } from "react-intl"

const nonDockList = ["RESPU", "RESDEL", "LTDPU", "LTDDEL", "LGDEL", "LGPU"]

const AccessorialSelector = ({
    selected = [],
    setSelected,
    isPickup = true,
    gaCategory,
    logGAEvent,
}) => {
    const {
        pickupAccessorialList,
        deliveryAccessorialList,
    } = useAccessorialsContext()

    const [list, setList] = useState([])
    const [inputValue, setInputValue] = useState("")
    const dockValue = isPickup ? "DOCKPU" : "DOCKDEL"
    const docktype = isPickup ? "PickUp Accessorials" : "Delivery Accessorials"

    useEffect(() => {
        if (selected === null) {
            setSelected([dockValue])
        }
        if (isPickup) {
            setList(pickupAccessorialList)
        } else {
            setList(deliveryAccessorialList)
        }
    }, [])

    useEffect(() => {
        if (selected.length === 0) {
            setSelected([dockValue])
        }
    }, [selected])

    const onSelect = evt => {
        const value = evt.target.value
        logGAEvent(gaCategory, `Update ${docktype}`, value)

        if (nonDockList.includes(value)) {
            setSelected([...selected.filter(item => item !== dockValue), value])
        } else if (value === dockValue) {
            setSelected([
                ...selected.filter(item => !nonDockList.includes(item)),
                value,
            ])
        } else {
            setSelected([...selected, value])
        }

        setInputValue("")
    }

    const removeAccessorial = value => {
        logGAEvent(gaCategory, `Remove ${docktype}`, value)
        if (nonDockList.includes(value)) {
            setSelected([dockValue, ...selected.filter(item => item !== value)])
        } else {
            setSelected(selected.filter(item => item !== value))
        }
    }

    return (
        <FormControl
            style={{
                width: "100%",
                paddingRight: "10px",
            }}
        >
            <InputLabel>
                {isPickup ? (
                    <FormattedMessage
                        id="generalTerms__selectPickup"
                        defaultMessage="Select Pickup Accessorials"
                    />
                ) : (
                    <FormattedMessage
                        id="generalTerms__selectDelivery"
                        defaultMessage="Select Delivery Accessorials"
                    />
                )}
            </InputLabel>
            <Select
                onChange={onSelect}
                variant="standard"
                label={"Select Accessorial"}
                value={inputValue}
                MenuProps={{ style: { maxHeight: "300px" } }}
            >
                {list
                    .filter(item => !selected.includes(item.value))
                    .map(item => {
                        return (
                            <MenuItem key={item.value} value={item.value}>
                                {item.name}
                            </MenuItem>
                        )
                    })}
            </Select>
            <Box>
                {selected.map(selectedAccessorial => {
                    return (
                        <Chip
                            onDelete={
                                selectedAccessorial !== "DOCKPU" &&
                                selectedAccessorial !== "DOCKDEL"
                                    ? () =>
                                          removeAccessorial(selectedAccessorial)
                                    : null
                            }
                            color="primary"
                            style={{ marginTop: "5px", marginRight: "5px" }}
                            value={selectedAccessorial}
                            key={selectedAccessorial}
                            label={
                                list.find(
                                    accessorial =>
                                        accessorial.value ===
                                        selectedAccessorial
                                )?.name
                            }
                        />
                    )
                })}
            </Box>
        </FormControl>
    )
}

export default AccessorialSelector

import { setQuoteMode } from "./quote"
import { validateItem } from "./validation"

export const QUOTE_ITEM_FIELD_VALIDATION = "QUOTE_ITEM_FIELD_VALIDATION"
export const UPDATE_QUOTE_ITEM_FIELD = "UPDATE_QUOTE_ITEM_FIELD"
export const UPDATE_LINE_ITEM = "UPDATE_LINE_ITEM"
export const SEARCH_ITEMS_START = "SEARCH_ITEMS_START"
export const SEARCH_TEXT_CHANGE = "SEARCH_TEXT_CHANGE"

export function quoteItemFieldValidation(validation, index, strict) {
    return { type: QUOTE_ITEM_FIELD_VALIDATION, validation, index, strict }
}

export const searchTextChange = text => ({ type: SEARCH_TEXT_CHANGE, text })

export function validateItemFields(index, strict = false) {
    return (dispatch, getState) => {
        const state = getState()
        const item = state.search.items[index]
        if (!item) return false
        if (item.isDefault) return true
        const volume = state.search.volumeType === "NORMAL_AND_VOLUME"
        const validation = validateItem(item, { volume, hazmat: false })
        dispatch(quoteItemFieldValidation(validation, index, strict))
        return Object.keys(validation).length === 0
    }
}

export function updateQuoteItemField(field, value, index, size = null) {
    return (dispatch, getState) => {
        if (field === "unNumber" && isNaN(value)) {
            return
        }
        dispatch({
            type: UPDATE_QUOTE_ITEM_FIELD,
            field,
            value,
            index,
            size,
        })
        dispatch(validateItemFields(index))
        dispatch(setQuoteMode())
    }
}

export function updateLineItem(item, index) {
    return dispatch => {
        dispatch({ type: UPDATE_LINE_ITEM, item, index })
        dispatch(validateItemFields(index))
    }
}

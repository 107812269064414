import {
    VAGUE_COMMODITY_LOAD,
    VAGUE_COMMODITY_LOAD_ERROR,
    VAGUE_COMMODITY_RESULT,
} from "../actions/vague-commodity"

export function vagueCommodities(
    state = {
        isFetching: false,
        isLoaded: false,
        isError: false,
        list: [],
    },
    action
) {
    switch (action.type) {
        case VAGUE_COMMODITY_RESULT:
            return Object.assign({}, state, {
                isLoaded: true,
                list: action.vagueCommodities,
            })
        case VAGUE_COMMODITY_LOAD:
            return Object.assign({}, state, { isFetching: true })
        case VAGUE_COMMODITY_LOAD_ERROR:
            return Object.assign({}, state, { isFetching: false })
        default:
            return state
    }
}

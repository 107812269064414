import React from "react"
import { withStyles } from "@material-ui/styles"
import { Grid, Typography, Dialog } from "@material-ui/core"
import {
    updateCustomAlaskaPricing,
    resetCustomPricing,
} from "../../../../../actions/offshoreRating"
import { connect } from "react-redux"
import { change, reduxForm } from "redux-form"
import { FormattedMessage } from "react-intl"
import NavigationDropdown from "../NavigationDropdown"
import { styles } from "../styles"
import PricingFormSimple from "../PricingFormSimple"
import PricingForm from "../PricingForm"
import AlaskaOceanChargeForm from "./AlaskaOceanChargeForm"

const AlaskaPricingModal = ({
    classes,
    open,
    setOpen,
    defaultPricing,
    customPricing,
    saveCustomPricing,
    resetCustomPricing,
    location,
    activeFilter,
    setActiveFilter,
    availableFreightClasses,
    trackGA,
}) => {
    return (
        <Dialog maxWidth="lg" fullWidth={true} open={open}>
            <Grid className={classes.modal}>
                <Grid item container justifyContent="center">
                    <Grid item style={{ paddingBottom: "20px" }}>
                        <Typography variant="h5" align="center">
                            <FormattedMessage
                                id="locations.offShore_CustomDiscountsTitle"
                                defaultMessage="Enter your custom list rates and discounts"
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container justifyContent="flex-start" xs={12}>
                    <Grid item container xs={6}>
                        <NavigationDropdown
                            activeFilter={activeFilter}
                            setActiveFilter={setActiveFilter}
                            classes={classes}
                            offshoreState={"AK"}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        xs={6}
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        <Typography variant="h6" align="center">
                            <FormattedMessage
                                id="generalTerms__offshoreStateAK"
                                defaultMessage="Alaska"
                            />
                        </Typography>
                    </Grid>
                </Grid>

                {activeFilter === "oceanCharges" ? (
                    <AlaskaOceanChargeForm
                        trackGA={trackGA}
                        titleField={"originCFS"}
                        setOpen={setOpen}
                        defaultValues={defaultPricing?.oceanCharges}
                        customPricing={customPricing?.oceanCharges}
                        saveCustomPricing={saveCustomPricing}
                        resetCustomPricing={resetCustomPricing}
                        activeFilter={activeFilter}
                        location={location}
                        availableFreightClasses={availableFreightClasses}
                        dataFields={[
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.originCFS"
                                        defaultMessage="Origin CFS"
                                    />
                                ),
                                ref: "originCFS",
                                type: "title",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.destinationCFS"
                                        defaultMessage="Destination CFS"
                                    />
                                ),
                                ref: "destinationCFS",
                                type: "title",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.grossDiscount"
                                        defaultMessage="Gross Discount"
                                    />
                                ),
                                ref: "oceanGrossDiscount",
                                type: "discount",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.minimumChargeWithLimit"
                                        defaultMessage="Minimum Charge {amount}"
                                        values={{ amount: "(0-150lbs)" }}
                                    />
                                ),
                                ref: "lowMinimumCharge",
                                type: "charge",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.minimumChargeWithLimit"
                                        defaultMessage="Minimum Charge {amount}"
                                        values={{ amount: "(150+lbs)" }}
                                    />
                                ),
                                ref: "highMinimumCharge",
                                type: "charge",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.class"
                                        defaultMessage="Class"
                                    />
                                ),
                                ref: "freightClass",
                                type: "dropdown",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.ratePerCwt"
                                        defaultMessage="Rate per CWT"
                                    />
                                ),
                                ref: "ratePerCwtByClasses",
                                type: "rate",
                            },
                        ]}
                    />
                ) : null}

                {activeFilter === "protectFromFreezing" ? (
                    <PricingForm
                        trackGA={trackGA}
                        offshoreLocation="Alaska"
                        titleField={"zoneRangeStart"}
                        secondaryTitleField={"zoneRangeEnd"}
                        classes={classes}
                        setOpen={setOpen}
                        defaultValues={defaultPricing?.protectFromFreezing}
                        customPricing={customPricing?.protectFromFreezing}
                        resetCustomPricing={resetCustomPricing}
                        saveCustomPricing={saveCustomPricing}
                        location={location}
                        activeFilter={activeFilter}
                        showRateCheckbox={false}
                        dataFields={[
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.zoneRangeStart"
                                        defaultMessage="Zone Range Start"
                                    />
                                ),
                                ref: "zoneRangeStart",
                                type: "title",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.zoneRangeEnd"
                                        defaultMessage="Zone Range End"
                                    />
                                ),
                                ref: "zoneRangeEnd",
                                type: "title",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.grossDiscount"
                                        defaultMessage="Gross Discount"
                                    />
                                ),
                                ref: "grossDiscount",
                                type: "discount",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.minimumCharge"
                                        defaultMessage="Minimum Charge"
                                    />
                                ),
                                ref: "minimumValue",
                                type: "charge",
                            },

                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.ratePerCwt"
                                        defaultMessage="Rate per CWT"
                                    />
                                ),
                                ref: "rate",
                                type: "rate",
                            },
                        ]}
                    />
                ) : null}

                {activeFilter === "hazardousMaterialsFee" ? (
                    <PricingForm
                        trackGA={trackGA}
                        offshoreLocation="Alaska"
                        titleField={"zoneRangeStart"}
                        secondaryTitleField={"zoneRangeEnd"}
                        classes={classes}
                        setOpen={setOpen}
                        defaultValues={defaultPricing?.hazardousMaterialsFees}
                        customPricing={customPricing?.hazardousMaterialsFees}
                        resetCustomPricing={resetCustomPricing}
                        saveCustomPricing={saveCustomPricing}
                        activeFilter={activeFilter}
                        location={location}
                        showChargeCheckbox={false}
                        dataFields={[
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.zoneRangeStart"
                                        defaultMessage="Zone Range Start"
                                    />
                                ),
                                ref: "zoneRangeStart",
                                type: "title",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.zoneRangeEnd"
                                        defaultMessage="Zone Range End"
                                    />
                                ),
                                ref: "zoneRangeEnd",
                                type: "title",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.grossDiscount"
                                        defaultMessage="Gross Discount"
                                    />
                                ),
                                ref: "grossDiscount",
                                type: "discount",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.ratePerShipment"
                                        defaultMessage="Rate per Shipment"
                                    />
                                ),
                                ref: "hazmatCharge",
                                type: "charge",
                            },
                        ]}
                    />
                ) : null}

                {activeFilter === "bolFee" ? (
                    <PricingFormSimple
                        trackGA={trackGA}
                        offshoreLocation="Alaska"
                        setOpen={setOpen}
                        classes={classes}
                        defaultValues={{ bolFee: defaultPricing?.bolFee }}
                        initialFormValues={{ bolFee: customPricing?.bolFee }}
                        saveCustomPricing={saveCustomPricing}
                        resetCustomPricing={resetCustomPricing}
                        activeFilter={activeFilter}
                        location={location}
                        dataFields={[
                            {
                                label: (
                                    <FormattedMessage
                                        id="domesticOffShore.filters.BillOfLadingFee"
                                        defaultMessage="Bill of Lading Fee"
                                    />
                                ),
                                ref: "bolFee",
                                type: "charge",
                            },
                        ]}
                    />
                ) : null}
                {activeFilter === "absoluteMinimumCharge" ? (
                    <PricingFormSimple
                        trackGA={trackGA}
                        offshoreLocation="Alaska"
                        setOpen={setOpen}
                        classes={classes}
                        defaultValues={defaultPricing?.absoluteMinimumCharge}
                        initialFormValues={customPricing?.absoluteMinimumCharge}
                        saveCustomPricing={saveCustomPricing}
                        resetCustomPricing={resetCustomPricing}
                        activeFilter={activeFilter}
                        location={location}
                        dataFields={[
                            {},
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.absoluteMinimumCharge"
                                        defaultMessage="Custom Absolute Minimum Charge"
                                    />
                                ),
                                ref: "charge",
                                type: "charge",
                            },

                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.grossDiscount"
                                        defaultMessage="Gross Discount"
                                    />
                                ),
                                ref: "grossPercentageDiscount",
                                type: "discount",
                            },
                        ]}
                    />
                ) : null}
            </Grid>
        </Dialog>
    )
}

const mapStateToProps = (state, props) => {
    return {
        formValues: state?.form?.offshoreDiscountsAK?.values,
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    saveCustomPricing: (updatedPricing, locationId) =>
        dispatch(
            updateCustomAlaskaPricing(
                updatedPricing,
                locationId,
                props?.activeFilter
            )
        ),
    resetCustomPricing: locationId => {
        dispatch(resetCustomPricing("alaska", locationId, props?.activeFilter))
    },
})

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "offshoreDiscountsAK", // a unique identifier for this form
            enableReinitialize: true,
        })(AlaskaPricingModal)
    )
)

import React, { Fragment } from "react"
import FreightBoxHandlingUnit from "./FreightBoxHandlingUnit"
import HandlingUnit from "./HandlingUnit"

export const BookHandlingUnits = ({
    fields,
    handlePanel,
    isQuickRate,
    isOpen,
    origin,
    originAddress,
    setHandlingUnitMode,
    determineEstimatedLinearFeet,
    isFreightDirect,
    isFreightDirectReturns,
    isFreightBox,
    selectedMXSATCommodity,
    setSelectedMXSATCommodity,
    editMode,
    ...props
}) => (
    <Fragment>
        {isFreightBox
            ? fields.map((hu, index) => (
                  <FreightBoxHandlingUnit
                      {...props}
                      isFreightDirect={isFreightDirect}
                      isFreightDirectReturns={isFreightDirectReturns}
                      isQuickRate={isQuickRate}
                      prefix={hu}
                      key={index}
                      index={index}
                      origin={origin}
                      originAddress={originAddress}
                      onRemoveClick={e => {
                          e.stopPropagation()
                          fields.remove(index)
                          // setHandlingUnitMode(true)
                          // determineEstimatedLinearFeet()
                      }}
                      handlePanel={() => handlePanel(index)}
                      setHandlingUnitMode={setHandlingUnitMode}
                      isOpen={isOpen === index}
                  />
              ))
            : fields.map((hu, index) => (
                  <HandlingUnit
                      canBeDeleted={index !== 0}
                      {...props}
                      isFreightDirect={isFreightDirect}
                      isQuickRate={isQuickRate}
                      isFreightDirectReturns={isFreightDirectReturns}
                      prefix={hu}
                      key={index}
                      index={index}
                      origin={origin}
                      originAddress={originAddress}
                      onRemoveClick={e => {
                          e.stopPropagation()
                          fields.remove(index)
                          // setHandlingUnitMode(true)
                          // determineEstimatedLinearFeet()
                      }}
                      handlePanel={() => handlePanel(index)}
                      setHandlingUnitMode={setHandlingUnitMode}
                      isOpen={isOpen === index}
                      selectedMXSATCommodity={selectedMXSATCommodity}
                      setSelectedMXSATCommodity={setSelectedMXSATCommodity}
                      editMode={editMode}
                  />
              ))}
    </Fragment>
)

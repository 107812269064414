import { errorMessage, uniqueRequest } from "./index"
import { goFetch } from "../http"

export const CARRIER_LOAD = "CARRIER_LOAD"
export const CARRIER_LOAD_ERROR = "CARRIER_LOAD_ERROR"
export const CARRIER_RESULT = "CARRIER_RESULT"

function carrierLoad() {
    return {
        type: CARRIER_LOAD,
    }
}

function carrierLoadError(error) {
    return dispatch => {
        dispatch(errorMessage(error))
        dispatch({
            type: CARRIER_LOAD_ERROR,
            error,
        })
    }
}

function carrierResult(carriers) {
    return {
        type: CARRIER_RESULT,
        carriers,
    }
}

function shouldRequestCarriers(state) {
    return !state.carriers.isLoaded || state.carriers.isInvalidated
}

export function requestCarriers() {
    return async (dispatch, getState) => {
        if (!shouldRequestCarriers(getState())) return
        const processData = async () => {
            dispatch(carrierLoad())

            try {
                const { data: carriers } = await goFetch("/carrier", {}, true)
                const carrierMap = carriers.reduce(
                    (prev, item) => ({
                        ...prev,
                        [item.carrier_id]: item,
                    }),
                    {}
                )
                dispatch(carrierResult(carrierMap))
            } catch (error) {
                dispatch(carrierLoadError(error))
            }
        }
        const id = "carriers"
        return dispatch(uniqueRequest(id, processData))
    }
}

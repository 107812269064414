import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Chip from "@material-ui/core/Chip"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"

const styles = theme => ({
    chip: {
        margin: "10px 5px",
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
    section__title: {
        textDecoration: "underline",
    },
})

const AccessorialsDisplay = ({
    classes,
    originAccessorials = [],
    deliveryAccessorials = [],
    id,
    accessorialsList = [],
}) => {
    return (
        <Grid item container>
            <Grid container>
                {originAccessorials.length > 0 ? (
                    <Grid item container className={classes.title__container}>
                        <Typography
                            variant="subtitle2"
                            className={classes.section__title}
                            id={`${id}__pickup`}
                        >
                            <FormattedMessage
                                id="dashboard__pickupAccessorials"
                                defaultMessage="Pickup Accessorials"
                            />
                        </Typography>
                    </Grid>
                ) : null}
                <Grid item container>
                    {originAccessorials.map((entry, i) =>
                        entry ? (
                            <Chip
                                id={`${id}__${i}__pickup__${entry}`}
                                label={
                                    accessorialsList.find(
                                        accessorial =>
                                            accessorial?.value === entry
                                    )?.label
                                }
                                key={i}
                                className={classes.chip}
                                tabIndex={-1}
                            />
                        ) : null
                    )}
                </Grid>
            </Grid>
            <Grid container>
                {deliveryAccessorials.length > 0 ? (
                    <Grid item container className={classes.title__container}>
                        <Typography
                            variant="subtitle2"
                            className={classes.section__title}
                            id={`${id}__delivery`}
                        >
                            <FormattedMessage
                                id="dashboard__deliveryAccessorials"
                                defaultMessage="Delivery Accessorials"
                            />
                        </Typography>
                    </Grid>
                ) : null}
                <Grid item container>
                    {deliveryAccessorials.map((entry, i) =>
                        entry ? (
                            <Chip
                                id={`${id}__${i}__delivery__${entry}`}
                                label={
                                    accessorialsList.find(
                                        accessorial =>
                                            accessorial?.value === entry
                                    )?.label
                                }
                                key={i}
                                className={classes.chip}
                                tabIndex={-1}
                            />
                        ) : null
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state, props) => {
    const pickup = state.accessorials.list.pickup ?? []
    const delivery = state.accessorials.list.delivery ?? []
    const commodity = state.accessorials.list.commodity ?? []
    const language = state.user.profile.preferences.language

    const accessorialsList = pickup
        ? [...pickup]
              .map(entry => ({
                  value: entry.value,
                  label: entry.label[language],
              }))
              .concat(
                  delivery.map(entry => ({
                      value: entry.value,
                      label: entry.label[language],
                  })),
                  commodity.map(entry => ({
                      value: entry.value,
                      label: entry.label[language],
                  }))
              )
        : []

    return {
        accessorialsList,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(AccessorialsDisplay))

import { Grid, Typography, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React, { useState, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import BookSquare from "../atoms/BookSquare"
import BasicInfoSummary from "../summaryCards/BasicInfoSummary"
import DestinationSummary from "../summaryCards/DestinationSummary"
import FinalizeSummary from "../summaryCards/FinalizeSummary"
import ItemsSummary from "../summaryCards/ItemsSummary"
import OriginSummary from "../summaryCards/OriginSummary"
import Origin from "../atoms/review/Origin"
import Destination from "../atoms/review/Destination"
import { Element, scroller } from "react-scroll"
import { weightUnit } from "../../../util/units"
import BookAuxiliarySquare from "../atoms/BookAuxiliarySquare"
import { useFlags } from "launchdarkly-react-client-sdk"
import {
    isUSDomesticOffshoreShipment,
    isUSDomesticShipment,
} from "../../../../actions/validation"
import WarningReviewPUD from "../atoms/WarningReviewPUDModal"
import { isFedExCarrier } from "../../../../misc"
import { containsCombinedOtherRatesAndFedexOceanRates } from "../../../util/carrierLogoUtils"
import ConfirmActionModal from "../../../common/ConfirmActionModal"
import { isOffshorePRShipmentFedexValid } from "../../../util/offshoreUtils."

const useStyles = makeStyles({
    titleContainer: {
        padding: "15px 0",
    },
    cardsContainer: {
        paddingRight: "2%",
    },
    summaryCard: {
        paddingBottom: "12px",
    },
    bookSquareContainer: {
        alignContent: "flex-start",
        position: "fixed",
        left: "66.666%",
        top: "129px",
        right: "72px",
    },
    bookAuxiliarySquareContainer: {
        alignContent: "flex-start",
        position: "fixed",
        left: "66.666%",
        top: "400px",
        right: "72px",
    },
})

const GA_CATEGORY = "Book Shipment - Review"

export default function Review({
    formValues = {},
    handleEdit,
    pickupAccessorialsList,
    deliveryAccessorialsList,
    permissions = {},
    currentStep,
    originCities,
    destinationCities,
    changeField,
    trackGA,
    invalid,
    onBookShipment,
    isLoading,
    IntercomAPI,
    locations,
    isCanadaMexico,
    checkLoadedHISpecialCases,
    openModal,
    closeModal,
}) {
    useEffect(() => {
        if (currentStep === 6) {
            IntercomAPI("trackEvent", "book-shipment-review")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const classes = useStyles()
    const [isEditingOrigin, setIsEditingOrigin] = useState(false)
    const [isEditingDestination, setIsEditingDestination] = useState(false)
    const [oldOrigin, setOldOrigin] = useState({})
    const [oldDestination, setOldDestination] = useState({})
    const [displayOffshoreWarningPUD, setDisplayOffshoreWarningPUD] = useState(
        false
    )
    const [isExpandedRateDetails, setIsExpandedRateDetails] = useState(false)

    const {
        selectedRate = { rateQuoteDetail: {}, serviceLevel: {} },
        preferredSystemOfMeasurement,
        isFreightDirect,
        isFreightDirectReturns,
        origin,
        destination,
        deliveryAccessorials = [],
        pickupAccessorials = [],
    } = formValues

    const canViewRateAmounts = permissions?.viewRateAmounts?.value

    const isUSDomestic = isUSDomesticShipment(
        origin?.shippingAddress?.address?.country,
        destination?.shippingAddress?.address?.country
    )

    const isDomesticOffshoreShipment = isUSDomesticOffshoreShipment(
        origin?.shippingAddress?.address?.country,
        destination?.shippingAddress?.address?.country,
        origin?.shippingAddress?.address?.state,
        destination?.shippingAddress?.address?.state
    )

    const isHawaiiShipment =
        origin?.shippingAddress?.address?.state === "HI" ||
        (destination?.shippingAddress?.address?.state === "HI" && isUSDomestic)

    const isPRShipmentFedexValid = isOffshorePRShipmentFedexValid(
        origin?.shippingAddress?.address?.country,
        origin?.shippingAddress?.address?.state,
        destination?.shippingAddress?.address?.country,
        destination?.shippingAddress?.address?.state
    )

    const {
        domesticOffshoreRating,
        domesticOffshoreHiRating,
        domesticOffshoreCustomerPud,
    } = useFlags()

    const containsPUDAccesorials =
        deliveryAccessorials.includes("CUSTDEL") ||
        pickupAccessorials.includes("CUSTPU")

    const containsPUDFacilityAccesorials =
        deliveryAccessorials.includes("CUSTPUOFF") ||
        deliveryAccessorials.includes("CUSTDELOFF")

    const isPickUpFlagWaning =
        (deliveryAccessorials.includes("CUSTDEL") &&
            !domesticOffshoreCustomerPud) ||
        (domesticOffshoreCustomerPud &&
            deliveryAccessorials.includes("CUSTPUOFF"))

    const isOriginHISpecialCase = [
        checkLoadedHISpecialCases(origin?.shippingAddress?.address),
    ].some(specialCase => specialCase?.specialHandling === "Will Call Only")

    const isDestinationHISpecialCase = [
        checkLoadedHISpecialCases(destination?.shippingAddress?.address),
    ].some(specialCase => specialCase?.specialHandling === "Will Call Only")

    const isHISpecialCase = isOriginHISpecialCase || isDestinationHISpecialCase

    const openWillCallOnlyConfirmationModal = (openModal, handleConfirm) => {
        openModal(
            <ConfirmActionModal
                message={
                    <FormattedMessage
                        id="specialHandling_willCallOnly_msg"
                        defaultMessage="Your shipment {value} is ‘Will Call Only’. FedEx will not be responsible for the {mode}. For more information, call 1-800-351-5187 Ext. 6."
                        values={{
                            value: isOriginHISpecialCase
                                ? "Origin"
                                : "Destination",
                            mode: isOriginHISpecialCase ? "Pickup" : "Delivery",
                        }}
                    />
                }
                title={
                    <FormattedMessage
                        id="specialHandling_willCallOnly_title"
                        defaultMessage="Will Call Only"
                    />
                }
                handleConfirm={handleConfirm}
            />
        )
    }

    useEffect(() => {
        if (currentStep === 6) {
            scroller.scrollTo("reviewTitle", {
                offset: -140,
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid item container alignContent="flex-start">
            <WarningReviewPUD
                isDialogOpen={displayOffshoreWarningPUD}
                cancelModal={() => setDisplayOffshoreWarningPUD(false)}
                proccedWithwarning={() => onBookShipment()}
                isPickUp={isPickUpFlagWaning}
                containsPUDFacilityAccesorials={containsPUDFacilityAccesorials}
                domesticOffshoreCustomerPud={domesticOffshoreCustomerPud}
            />
            <Grid
                item
                container
                className={classes.titleContainer}
                alignItems="center"
            >
                <Grid item container xs={4}>
                    <Button
                        id="changeRateReview"
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                            trackGA(GA_CATEGORY, "Back To Rates")
                            handleEdit(4)
                        }}
                    >
                        <FormattedMessage
                            id="bookShipment.review__backToRates"
                            defaultMessage="Back to Rates"
                        />
                    </Button>
                </Grid>
                <Grid item container xs={4} justify="center">
                    <Typography variant="h6">
                        <FormattedMessage
                            id="bookShipment.reviewAndBook"
                            defaultMessage="Review and book."
                        />
                    </Typography>
                    <Element name="reviewTitle" />
                </Grid>
            </Grid>
            <Grid
                item
                container
                xs={8}
                direction="column"
                className={classes.cardsContainer}
            >
                <Grid item container className={classes.summaryCard}>
                    <BasicInfoSummary
                        handleEdit={handleEdit}
                        formValues={formValues}
                        isReview
                        locations={locations}
                    />
                </Grid>
                <Grid item container className={classes.summaryCard}>
                    {!isEditingOrigin ? (
                        <OriginSummary
                            pickupAccessorialsList={pickupAccessorialsList}
                            handleReviewEdit={() => {
                                setOldOrigin(formValues.origin)
                                setIsEditingOrigin(true)
                                trackGA(GA_CATEGORY, "Begin Edit Origin")
                            }}
                            formValues={formValues}
                            isReview
                        />
                    ) : (
                        <Origin
                            formValues={formValues}
                            cities={originCities}
                            handleCancelEdit={() => {
                                changeField("origin", oldOrigin)
                                setIsEditingOrigin(false)
                                trackGA(GA_CATEGORY, "Cancel Edit Origin")
                            }}
                            handleUpdate={() => {
                                setIsEditingOrigin(false)
                                trackGA(GA_CATEGORY, "Confirm Edit Origin")
                            }}
                            invalid={invalid}
                            editableCity={!isCanadaMexico}
                        />
                    )}
                </Grid>
                <Grid item container className={classes.summaryCard}>
                    {!isEditingDestination ? (
                        <DestinationSummary
                            handleReviewEdit={() => {
                                setOldDestination(formValues.destination)
                                setIsEditingDestination(true)
                                trackGA(GA_CATEGORY, "Begin Edit Destination")
                            }}
                            formValues={formValues}
                            deliveryAccessorialsList={deliveryAccessorialsList}
                            isReview
                        />
                    ) : (
                        <Destination
                            formValues={formValues}
                            cities={destinationCities}
                            handleCancelEdit={() => {
                                changeField("destination", oldDestination)
                                setIsEditingDestination(false)
                                trackGA(GA_CATEGORY, "Cancel Edit Destination")
                            }}
                            handleUpdate={() => {
                                setIsEditingDestination(false)
                                trackGA(GA_CATEGORY, "Confirm Edit Destination")
                            }}
                            invalid={invalid}
                            editableCity={!isCanadaMexico}
                        />
                    )}
                </Grid>
                <Grid item container className={classes.summaryCard}>
                    <ItemsSummary
                        handleEdit={handleEdit}
                        formValues={formValues}
                        weightUnit={weightUnit(preferredSystemOfMeasurement)}
                        isReview
                    />
                </Grid>
                <Grid item container className={classes.summaryCard}>
                    <FinalizeSummary
                        handleEdit={handleEdit}
                        formValues={formValues}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4} className={classes.bookSquareContainer}>
                <BookSquare
                    trackGA={trackGA}
                    disabled={
                        isEditingOrigin || isEditingDestination || isLoading
                    }
                    selectedRate={selectedRate}
                    isFreightDirect={isFreightDirect}
                    isFreightDirectReturns={isFreightDirectReturns}
                    canViewRateAmounts={canViewRateAmounts}
                    onBookShipment={() => {
                        if (
                            (isPRShipmentFedexValid ||
                                isDomesticOffshoreShipment) &&
                            domesticOffshoreRating &&
                            (containsCombinedOtherRatesAndFedexOceanRates(
                                selectedRate
                            ) ||
                                isFedExCarrier(selectedRate?.carrierCode)) &&
                            (containsPUDAccesorials ||
                                containsPUDFacilityAccesorials)
                        ) {
                            setDisplayOffshoreWarningPUD(true)
                        } else if (
                            isHawaiiShipment &&
                            domesticOffshoreHiRating &&
                            isHISpecialCase &&
                            (containsCombinedOtherRatesAndFedexOceanRates(
                                selectedRate
                            ) ||
                                isFedExCarrier(selectedRate?.carrierCode))
                        ) {
                            openWillCallOnlyConfirmationModal(openModal, () => {
                                closeModal()
                                onBookShipment()
                            })
                        } else {
                            onBookShipment()
                        }
                    }}
                    isExpanded={isExpandedRateDetails}
                    setIsExpanded={setIsExpandedRateDetails}
                    destination={destination}
                />
            </Grid>
            {!isExpandedRateDetails &&
                isPRShipmentFedexValid &&
                domesticOffshoreRating &&
                (containsCombinedOtherRatesAndFedexOceanRates(selectedRate) ||
                    isFedExCarrier(selectedRate?.carrierCode)) && (
                    <Grid
                        item
                        xs={4}
                        className={classes.bookAuxiliarySquareContainer}
                    >
                        <BookAuxiliarySquare
                            msg={
                                <FormattedMessage
                                    id="bookShipment.review__msg__auxiliary"
                                    defaultMessage="In order to request Island Expedited Services (IES), the customer should contact the San Juan service center at 787-791-0339 where individual shipment eligibility will be determined. Carrier reserves the right to refuse Island Expedited Services (IES) when the Carrier cannot provide the service as requested."
                                />
                            }
                        />
                    </Grid>
                )}
        </Grid>
    )
}

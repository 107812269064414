import { SET_CPG, USER_DISAUTHENTICATED } from "../actions/user"

export function cpg(
    state = {
        cpgCode: "",
        quoteCpgCode: "",
    },
    action = {}
) {
    switch (action.type) {
        case SET_CPG:
            return {
                ...state,
                cpgCode: action.cpgCode,
                quoteCpgCode: action.quoteCpgCode || action.cpgCode,
            }
        case USER_DISAUTHENTICATED:
            return cpg()
        default:
            return state
    }
}

import React from "react"
import { FormattedMessage } from "react-intl"

export const emailSentSuccess = (
    <FormattedMessage
        id="confirmation__emailSentSuccess"
        defaultMessage="Email sent successfully."
    />
)
export const shareQuoteSuccess = (
    <FormattedMessage
        id="confirmation__shareQuoteSuccess"
        defaultMessage="Share successful."
    />
)
export const userUpdated = (
    <FormattedMessage
        id="confirmation__userUpdated"
        defaultMessage="User successfully updated."
    />
)
export const accountCreationSuccess = (
    <FormattedMessage
        id="confirmation__accountCreationSuccess"
        defaultMessage="Your account has been created successfully"
    />
)
export const accountCreationPartialSuccess = type => (
    <FormattedMessage
        id="confirmation__accountCreationPartialSuccess"
        defaultMessage="Your account has been created. However, we are unable to activate your {type} account."
        values={{ type }}
    />
)
export const contactSaveSuccess = (
    <FormattedMessage
        id="confirmation__contactSaveSuccess"
        defaultMessage="Contact saved successfully."
    />
)
export const contactDeleteSuccess = (
    <FormattedMessage
        id="confirmation__contactDeleteSuccess"
        defaultMessage="Contact deleted successfully."
    />
)
export const userUpdateSuccess = (
    <FormattedMessage
        id="confirmation__userUpdateSuccess"
        defaultMessage="User successfully updated."
    />
)
export const locationAddSuccess = (
    <FormattedMessage
        id="confirmation__locationAddSuccess"
        defaultMessage="Location successfully added."
    />
)
export const locationAddPartialSuccess = type => (
    <FormattedMessage
        id="confirmation__locationAddPartialSuccess"
        defaultMessage="Location successfully added. However, we are unable to activate your {type} account."
        values={{ type }}
    />
)
export const requestAccessSuccess = (
    <FormattedMessage
        id="confirmation__requestAccessSuccess"
        defaultMessage="Location successfully requested."
    />
)
export const contactsImportSuccess = (
    <FormattedMessage
        id="confirmation__contactsImportSuccess"
        defaultMessage="Contacts imported successfully."
    />
)
export const itemSaveSuccess = (
    <FormattedMessage
        id="confirmation__itemSaveSuccess"
        defaultMessage="Item saved successfully."
    />
)
export const itemDeleteSuccess = (
    <FormattedMessage
        id="confirmation__itemDeleteSuccess"
        defaultMessage="Item deleted successfully."
    />
)
export const locationUpdateConfirm = (
    <FormattedMessage
        id="confirmation__locationUpdateConfirm"
        defaultMessage="To save your updated changes, please click 'Update' at the top right of the page."
    />
)

export const documentSaveSuccess = documentType => (
    <FormattedMessage
        id="confirmation__documentSaveSuccess"
        defaultMessage="{documentType} saved successfully."
        values={{ documentType }}
    />
)

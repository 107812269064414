import React from "react"
import {
    Grid,
    Typography,
    Card,
    CardHeader,
    CardContent,
    Avatar,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import CropSquare from "@material-ui/icons/CropSquare"
import { FormattedMessage } from "react-intl"
import HandlingUnitsTable from "./HandlingUnitsTable"

const useStyles = makeStyles({
    description: {
        width: "40%",
    },
    sectionTitle: {
        textDecoration: "underline",
    },
    card: {
        width: "100%",
    },
    topTableRow: {
        borderTop: "solid 1px #D4D4D4",
    },
})

const HandlingUnitsDisplay = ({
    handlingUnits,
    preferredSystemOfMeasurement,
}) => {
    const classes = useStyles()

    return (
        <Grid item container xs={12}>
            <Card className={classes.card}>
                <CardHeader
                    className={classes.header}
                    title={
                        <Typography variant="h6">
                            <FormattedMessage
                                id="orderDetails__handlingUnits"
                                defaultMessage="Handling Units"
                            />
                        </Typography>
                    }
                    avatar={
                        <Avatar>
                            <CropSquare />
                        </Avatar>
                    }
                />
                <CardContent className={classes.content}>
                    <HandlingUnitsTable
                        preferredSystemOfMeasurement={
                            preferredSystemOfMeasurement
                        }
                        handlingUnits={handlingUnits}
                    />
                </CardContent>
            </Card>
        </Grid>
    )
}

export default HandlingUnitsDisplay

import React from "react"
import PropTypes from "prop-types"
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    Typography,
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"
import { AccessorialsDisplay } from "../../util/accessorialsDisplay"
import TerminalInfo from "../../common/TerminalInfo"
import PickupWarning from "../../book/pickup/warning"
import moment from "moment"
import DisplayContact from "../../common/DisplayContact"

const styles = theme => ({
    card: {
        height: "100%",
        minHeight: "325px",
    },
    content: {
        "margin-left": "60px",
        "padding-top": "0px !important",
    },
})

const PickupCard = ({
    list,
    pickupLater,
    readyTime,
    closeTime,
    contact,
    note,
    status,
    pickedUp,
    classes,
    gridSize,
    shipmentId,
    terminal,
    shipment,
    isInBondShipment,
    pickupDate,
    isIntraMexico,
}) => (
    <Grid xs={gridSize || 6} item>
        <Card className={classes.card}>
            <CardHeader
                className={classes.header}
                title={
                    <Typography variant="h6">
                        <FormattedMessage
                            id="orderResults.pickup__title"
                            defaultMessage="Pickup Requirements"
                        />
                    </Typography>
                }
            />
            <CardContent className={classes.content}>
                <Typography variant="body2">
                    <FormattedMessage
                        id="scheduled___pickUpDate"
                        defaultMessage="Scheduled date: "
                    />
                    {`${moment.utc(pickupDate).format("dddd MMM Do")}`}
                </Typography>
                {pickupLater ? (
                    pickedUp && (
                        <PickupWarning
                            pickupLater={pickupLater}
                            carrierCode={shipment?.carrier}
                            rateMode={shipment?.rate?.mode}
                        />
                    )
                ) : (
                    <Typography variant="body2">
                        <FormattedMessage
                            id="generalTerms.pickUpInformation__pickupWindow"
                            defaultMessage="Pickup window"
                        />
                        {`: ${readyTime} - ${closeTime}`}
                    </Typography>
                )}
                {contact && <DisplayContact contact={contact} />}
                {note && (
                    <Typography variant="body2">
                        <FormattedMessage
                            id="orderResults.delivery__notes"
                            defaultMessage="Notes"
                        />
                        {`: ${note}`}
                    </Typography>
                )}
                {shipment?.rate?.mode !== "LTL_DIRECT_RETURNS" && (
                    <React.Fragment>
                        <Typography variant={"subtitle1"}>
                            <FormattedMessage
                                id="orderResults.pickup__pickupAccessorials"
                                defaultMessage="Pickup Accessorials"
                            />
                        </Typography>
                        <AccessorialsDisplay
                            shipmentId={shipmentId}
                            isPickup
                            includeCalculated
                            isInBondShipment={isInBondShipment}
                        />
                    </React.Fragment>
                )}
                {terminal && (
                    <TerminalInfo
                        isPickup
                        terminal={terminal}
                        isIntraMexico={isIntraMexico}
                    />
                )}
            </CardContent>
        </Card>
    </Grid>
)

PickupCard.propTypes = {
    classes: PropTypes.object,
}

PickupCard.defaultProps = {
    classes: {},
}

export default withStyles(styles)(PickupCard)

import { Box, Chip, Typography } from "@material-ui/core"
import React from "react"
import { styles } from "./styles"

const ChipDataDisplay = ({ title, chipData, listTrimCutoff }) => {
    const trimAccessorialList = list => {
        if (list.length > listTrimCutoff) {
            const extraAccessorials = list.length - listTrimCutoff + 1
            const newList = list.splice(0, listTrimCutoff - 1)
            newList.push(`+${extraAccessorials}`)
            return newList
        } else {
            return list
        }
    }

    return (
        <Box
            sx={{
                width: "20%",
                padding: "2px",
            }}
        >
            <Typography variant="subtitle2" style={styles.conactLabel}>
                {title}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                }}
            >
                {trimAccessorialList(chipData).map(accessorial => {
                    return (
                        <Chip
                            key={accessorial}
                            style={{
                                marginBottom: "4px",
                                marginRight: "4px",
                            }}
                            color="primary"
                            label={`${accessorial}`}
                        />
                    )
                })}
            </Box>
        </Box>
    )
}

export default ChipDataDisplay

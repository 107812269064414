import { defineMessages } from "react-intl"

export const messages = defineMessages({
    shareOrder: {
        id: "orders.share",
        defaultMessage: "Share",
    },
    printLabels: {
        id: "orders.printLabels",
        defaultMessage: "Print Labels",
    },
    viewLabels: {
        id: "orders.viewLabels",
        defaultMessage: "View Labels",
    },
    archiveOrder: {
        id: "generalTerms__Archive",
        defaultMessage: "Archive",
    },
    unarchiveOrder: {
        id: "generalTerms__Unarchive",
        defaultMessage: "Unarchive",
    },
    deleteOrder: {
        id: "generalTerms__delete",
        defaultMessage: "Delete",
    },
    orderNumber: {
        id: "orders.orderNumber",
        defaultMessage: "Order ID",
    },
    locationName: {
        id: "orders.locationName",
        defaultMessage: "Location",
    },
    uploadDate: {
        id: "orders.uploadDate",
        defaultMessage: "Upload Date",
    },
    originCompanyName: {
        id: "orders.originCompanyName",
        defaultMessage: "Origin",
    },
    destinationCompanyName: {
        id: "orders.destinationCompanyName",
        defaultMessage: "Destination",
    },
    itemDescription: {
        id: "orders.itemDescription",
        defaultMessage: "Description",
    },
    shipped: {
        id: "orders.shipped",
        defaultMessage: "Shipped",
    },
    bookShipment: {
        id: "generalTerms__book",
        defaultMessage: "Book",
    },
    viewShipment: {
        id: "generalTerms__view",
        defaultMessage: "View",
    },
    blankHeader: {
        id: "generalTerms__blankSpace",
        defaultMessage: " ",
    },
})

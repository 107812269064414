import React, { Component } from "react"
import {
    Chip,
    withStyles,
    Popover,
    Typography,
    Grid,
    Switch,
    IconButton,
    Card,
    CardHeader,
    CardContent,
} from "@material-ui/core"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import CloseIcon from "@material-ui/icons/Close"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    paper: {
        minWidth: "550px",
        minHeight: "350px",
        padding: theme.spacing(2),
        backgroundColor: "lightgrey",
    },
    filters__container: {},
    chip: {
        color: "white",
    },
    popover: {
        zIndex: 100,
    },
    card: {
        maxWidth: "400px",
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main,
    },
    cardHeaderTitle: {
        color: "white",
    },
    cardIcon: {
        color: "white",
    },
})

const getFiltersLabel = filters => {
    const { shipments, quotes } = filters
    if (shipments && quotes) {
        return (
            <FormattedMessage
                id="dashboard.filters__shipmentsAndQuotes"
                defaultMessage="Shipments and Quotes"
            ></FormattedMessage>
        )
    } else if (shipments && !quotes) {
        return (
            <FormattedMessage
                id="dashboard.filters__shipments"
                defaultMessage="Shipments"
            ></FormattedMessage>
        )
    } else if (!shipments && quotes) {
        return (
            <FormattedMessage
                id="dashboard.filters__quotes"
                defaultMessage="Quotes"
            ></FormattedMessage>
        )
    }
}

const FilterRow = ({ type, checked, onChange }) => (
    <Grid item container>
        <Grid item xs={9}>
            <Typography variant="body2">
                {type === "quotes" && (
                    <FormattedMessage
                        id="dashboard.filters__viewQuotes"
                        defaultMessage="View quotes"
                    />
                )}
                {type === "shipments" && (
                    <FormattedMessage
                        id="dashboard.filters__viewShipments"
                        defaultMessage="View shipments"
                    />
                )}
            </Typography>
            <Typography variant="caption">
                {type === "quotes" && (
                    <FormattedMessage
                        id="dashboard.filters__quotesDescription"
                        defaultMessage="Include quotes in the list of shipments by date"
                    />
                )}
                {type === "shipments" && (
                    <FormattedMessage
                        id="dashboard.filters__shipmentsDescription"
                        defaultMessage="Include shipments in the list of shipments by date"
                    />
                )}
            </Typography>
        </Grid>
        <Grid item xs={3}>
            <Switch
                checked={checked}
                onChange={() => onChange(type)}
                color="primary"
                id={`dashboard__filters__${type}`}
            />
        </Grid>
    </Grid>
)

let FiltersCard = ({ filters, handleClose, classes, onChange }) => (
    <Grid container justify="center">
        <Card className={classes.card}>
            <CardHeader
                title={
                    <Typography
                        variant="body1"
                        className={classes.cardHeaderTitle}
                    >
                        <FormattedMessage
                            id="dashboard.filters__filters"
                            defaultMessage="Filters"
                        />
                    </Typography>
                }
                action={
                    <IconButton
                        onClick={handleClose}
                        className={classes.cardIcon}
                    >
                        <CloseIcon id="dashboard__filtersClose" />
                    </IconButton>
                }
                className={classes.cardHeader}
            />
            <CardContent>
                <Grid container spacing={3}>
                    <FilterRow
                        type="quotes"
                        checked={filters.quotes}
                        onChange={onChange}
                    />
                    <FilterRow
                        type="shipments"
                        checked={filters.shipments}
                        onChange={onChange}
                    />
                </Grid>
            </CardContent>
        </Card>
    </Grid>
)

FiltersCard = withStyles(styles)(FiltersCard)

class DashboardTableFilters extends Component {
    constructor(props) {
        super(props)
        this.anchorRef = React.createRef()
    }

    state = {
        anchorEl: null,
    }

    handleOpen = e => {
        this.setState({ anchorEl: this.anchorRef.current })
        this.props.trackGA(this.props.gaCategory, "Filter PopOver Open")
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
        this.props.trackGA(this.props.gaCategory, "Filter PopOver Close")
    }

    handleChangeFilter = filter => {
        const actualFilters = this.props.filters
        if (!actualFilters.shipments && filter === "quotes") return
        if (!actualFilters.quotes && filter === "shipments") return
        this.props.trackGA(this.props.gaCategory, `Filter ${filter} Click`)
        this.props.onChange(filter)
    }

    render() {
        const { classes, filters, gaCategory, trackGA } = this.props
        const { anchorEl } = this.state
        return (
            <div className={classes.filters__container} ref={this.anchorRef}>
                <Chip
                    onClick={this.handleOpen}
                    className={classes.chip}
                    color="primary"
                    label={getFiltersLabel(filters)}
                    deleteIcon={<ArrowDropDownIcon />}
                    onDelete={this.handleOpen}
                    id="dashboard__filtersButton"
                />
                <Popover
                    open={Boolean(anchorEl)}
                    className={classes.popover}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <FiltersCard
                        filters={filters}
                        handleClose={this.handleClose}
                        onChange={this.handleChangeFilter}
                    />
                </Popover>
            </div>
        )
    }
}

export default withStyles(styles)(DashboardTableFilters)

import React from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { Chip } from "@material-ui/core"

const styles = theme => {
    return {
        destinationSummaryContainer: {
            padding: "0 15%",
        },
        paper: {
            width: "100%",
            display: "flex",
            padding: "15px",
            flexWrap: "wrap",
        },
        header: {
            paddingBottom: "15px",
        },
        section: {
            paddingTop: "15px",
        },
        underline: {
            textDecoration: "underline",
        },
        chip: {
            margin: "5px 10px 5px 0px",
            backgroundColor: theme.palette.primary.main,
            color: "white",
        },
    }
}

const DestinationSummary = ({
    handleReviewEdit,
    handleEdit,
    formValues = {},
    classes,
    deliveryAccessorialsList,
    isReview,
}) => {
    const {
        destination,
        deliveryAccessorials,
        isInBondShipment,
        isFreightDirect,
        isFreightDirectReturns,
    } = formValues

    let selectedArr = []
    if (typeof deliveryAccessorials === "string") {
        selectedArr = deliveryAccessorials.split(",")
    } else {
        selectedArr = deliveryAccessorials ?? []
    }

    return (
        <Grid
            item
            container
            className={!isReview ? classes.destinationSummaryContainer : ""}
        >
            <Paper className={classes.paper}>
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.header}
                >
                    <Typography variant="h6">
                        <FormattedMessage
                            id="bookShipment.destinationSummary__title"
                            defaultMessage="Destination"
                        />
                    </Typography>
                    <Button
                        id="editDestination"
                        color="primary"
                        variant="outlined"
                        onClick={
                            isReview
                                ? () => handleReviewEdit("destination")
                                : () => handleEdit(2)
                        }
                    >
                        <FormattedMessage
                            id="generalTerms__edit"
                            defaultMessage="Edit"
                        />
                    </Button>
                </Grid>
                <Grid item container direction="column" xs={6}>
                    <Typography variant="body2">
                        <FormattedMessage
                            id="generalTerms__address"
                            defaultMessage="Address"
                        />
                    </Typography>
                    <Typography variant="caption">
                        {destination?.shippingAddress?.name}
                    </Typography>
                    <Typography variant="caption">
                        {destination?.shippingAddress?.address?.street1}
                    </Typography>
                    <Typography variant="caption">
                        {destination?.shippingAddress?.address?.street2}
                    </Typography>
                    <Typography variant="caption">{`${destination?.shippingAddress?.address?.city}, ${destination?.shippingAddress?.address?.state} ${destination?.shippingAddress?.address?.postalCode}`}</Typography>
                    <Typography
                        variant="body2"
                        className={`${classes.section}`}
                    >
                        <FormattedMessage
                            id="orderResults.contact"
                            defaultMessage="Contact"
                        />
                    </Typography>
                    <Typography variant="caption">
                        {destination?.contact?.name}
                    </Typography>
                    <Typography variant="caption">
                        {`${destination?.contact?.phone?.phone_number}${
                            destination?.contact?.phone?.extension
                                ? ` - ${destination?.contact?.phone?.extension}`
                                : ""
                        }`}
                    </Typography>
                    <Typography variant="caption">
                        {destination?.contact?.email?.email_address}
                    </Typography>
                </Grid>
                <Grid item container direction="column" xs={6}>
                    {!isFreightDirect && !isFreightDirectReturns && (
                        <Grid item container direction="column">
                            <Typography variant="body2">
                                <FormattedMessage
                                    id="generalTerms__accessorials"
                                    defaultMessage="Accessorials"
                                />
                            </Typography>
                            <Grid item container>
                                {selectedArr.map((accessorial, i) => (
                                    <Chip
                                        label={
                                            (
                                                deliveryAccessorialsList.find(
                                                    it =>
                                                        it?.value ===
                                                        accessorial
                                                ) || {}
                                            ).label
                                        }
                                        key={i}
                                        className={classes.chip}
                                        tabIndex={-1}
                                    />
                                ))}
                                {isInBondShipment && (
                                    <Grid item>
                                        <Chip
                                            tabIndex={-1}
                                            label={
                                                <FormattedMessage
                                                    id="accessorials.panel__freightMovingInBond"
                                                    defaultMessage="Freight Moving In-Bond"
                                                />
                                            }
                                            className={classes.chip}
                                            backgroundColor="#F4F4F4"
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    )}

                    {destination?.note && (
                        <Grid
                            item
                            container
                            direction="column"
                            className={`${
                                !isFreightDirect && !isFreightDirectReturns
                                    ? classes.section
                                    : ""
                            }`}
                        >
                            <Typography variant="body2">
                                <FormattedMessage
                                    id="orderResults.delivery__title"
                                    defaultMessage="Special Instructions"
                                />
                            </Typography>
                            <Typography variant="caption">
                                {destination?.note}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </Grid>
    )
}

export default withStyles(styles)(DestinationSummary)

export const stringToNumber = str => {
    if (str.includes(".")) {
        return parseFloat(str)
    } else {
        return parseInt(str)
    }
}

export const validateInput = value => {
    const sanitizedValue = value.replace(/[^0-9.]/g, "")
    const decimalCount = sanitizedValue.split(".").length - 1
    const finalValue =
        decimalCount <= 1 ? sanitizedValue : sanitizedValue.slice(0, -1)

    return finalValue
}

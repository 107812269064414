import React from "react"
import { Button, CircularProgress, Grid } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
    submitLoader: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
})

const DocumentActions = withStyles(styles)(
    ({ classes, onBackClick, submitting, documentType }) => (
        <Grid item container direction="row" justify="space-between">
            <Grid item>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={onBackClick}
                >
                    <FormattedMessage
                        id="commercialInvoice.back"
                        defaultMessage="Back"
                    />
                </Button>
            </Grid>
            <Grid item>
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={submitting}
                >
                    <FormattedMessage
                        id="commercialInvoice.generate__submit"
                        defaultMessage="Generate {documentType}"
                        values={{ documentType }}
                    />
                </Button>
                {submitting && (
                    <CircularProgress
                        className={classes.submitLoader}
                        size={24}
                        color="secondary"
                    />
                )}
            </Grid>
        </Grid>
    )
)

export default DocumentActions

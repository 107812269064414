import moment from "moment-timezone"
import { get } from "lodash"
import { getQuote } from "./quote"
import { trackGAEvent } from "./user"

export { createShipment, SAVE_SHIPMENT_ERROR } from "./book-shipment"

export const CLEAR_BOOK = "CLEAR_BOOK"
export const INIT_BOOK = "INIT_BOOK"
export const INIT_BOOK_TIMEZONE = "INIT_BOOK_TIMEZONE"
export const BOOK_FIELD_CHANGE = "BOOK_FIELD_CHANGE"
export const NEW_SHIPMENT_FLASH_MESSAGE = "NEW_SHIPMENT_FLASH_MESSAGE"
export const DISMISS_SHIPMENT_FLASH_MESSAGE = "DISMISS_SHIPMENT_FLASH_MESSAGE"
export const ACCEPT_ADDRESS = "ACCEPT_ADDRESS"
export const CONTACT_SELECTED = "CONTACT_SELECTED"
export const PICKUP_LATER_CHANGE = "PICKUP_LATER_CHANGE"

export function acceptAddressSuggestion(originAddress, destinationAddress) {
    return { type: ACCEPT_ADDRESS, originAddress, destinationAddress }
}

/* eslint-disable camelcase */
export const extractAddress = (search, direction) => {
    const postalCode = get(search, direction)
    const city = get(search, `${direction}City`)
    const state = get(search, `${direction}State`)
    const country = get(search, `${direction}Country`)
    const address = get(search, `${direction}Address`)
    return {
        _id: get(address, "_id"),
        address: {
            postalCode,
            city,
            state,
            country,
            ...get(address, "address"),
            name: get(address, "name"),
        },
    }
}
/* eslint-enable camelcase */

export function initBook(
    shipmentId,
    carrierCode,
    shipMode,
    direction,
    alternateQuote
) {
    return async (dispatch, getState) => {
        if (getState().quotes.list.items[shipmentId]) return null
        return dispatch(getQuote(shipmentId))
    }
}

export function fieldChange(field, value, prefix) {
    return (dispatch, getState) => {
        const state = getState().book
        const { cpg } = state
        if (field === "isSaveAddress" && state[prefix].isSaveAddress) {
            dispatch(trackGAEvent("BOL Entry", "Contact (save unchecked)", cpg))
        }
        if (field === "proEnabled" && value) {
            dispatch(
                trackGAEvent(
                    "BOL Entry",
                    "Pre-assigned Tracking ID (checked)",
                    cpg
                )
            )
        }
        if (field === "setAsDefault" && prefix === "hazmatEmergency" && value) {
            dispatch(
                trackGAEvent("Create Quote", "Contact (save default)", cpg)
            )
        }
        return dispatch({
            type: BOOK_FIELD_CHANGE,
            field,
            value,
            prefix,
        })
    }
}

export function timeChange(field, value, prefix) {
    return (dispatch, getState) => {
        const prevDate = moment(getState().book[prefix][field]).format("L")
        const newDate = moment.utc(`${prevDate} ${value}`, "L LT")
        if (newDate.isValid()) {
            dispatch(fieldChange(field, newDate, prefix))
        }
    }
}

export const onContactSelect = (contact, isOrigin) =>
    !isOrigin && { type: CONTACT_SELECTED, ...contact }

export function newShipmentFlashMessage() {
    return { type: NEW_SHIPMENT_FLASH_MESSAGE }
}

export function dismissShipmentFlashMessage() {
    return (dispatch, getState) => {
        const { flashMessage } = getState().shipment
        if (flashMessage) dispatch({ type: DISMISS_SHIPMENT_FLASH_MESSAGE })
    }
}

export const pickupLaterChanged = () => (dispatch, getState) => {
    const book = getState().book
    const pickupLater = !book.pickupLater
    if (pickupLater) {
        dispatch(
            trackGAEvent(
                "BOL Entry",
                "Schedule a pickup later (checked)",
                book.cpg
            )
        )
    }
    return dispatch({ type: PICKUP_LATER_CHANGE, value: pickupLater })
}

export const trackInputBlur = field => (dispatch, getState) => {
    const book = getState().book
    dispatch(trackGAEvent("BOL Entry", field, book.cpg))
}

import { DISCOUNTS_RESULT } from "../actions/freightdirect-discount"
import { CHANGE_PAGE_SIZE } from "../actions/pagination"

export function discounts(
    state = {
        discounts: [],
        pageSize: 10,
        totalCount: 0,
    },
    action = {}
) {
    switch (action.type) {
        case CHANGE_PAGE_SIZE:
            return {
                ...state,
                pageSize: action.pageSize,
            }
        case DISCOUNTS_RESULT:
            return {
                ...state,
                discounts: action.result.discounts,
                totalCount: action.result.totalCount,
            }
        default:
            return state
    }
}

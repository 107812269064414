export const MXSATPackagingCodeOptions = [
    {
        value: "Tu",
        label: "Tu - Contenedor externo",
    },
    {
        value: "X1A",
        label: "X1A - Tambor de acero",
    },
    {
        value: "X1B",
        label: "X1B - Tambor de aluminio",
    },
    {
        value: "X1D",
        label: "X1D - Tambor contrachapado",
    },
    {
        value: "X1F",
        label: "X1F - Contenedor flexible",
    },
    {
        value: "X1G",
        label: "X1G - Tambor de fibra",
    },
    {
        value: "X1w",
        label: "X1w - Tambor de madera",
    },
    {
        value: "X2C",
        label: "X2C - Barril de madera",
    },
    {
        value: "X3A",
        label: "X3A - Bidón de acero",
    },
    {
        value: "X3H",
        label: "X3H - Bidón de plástico",
    },
    {
        value: "X43",
        label: "X43 - Bolsa de gran tamaño",
    },
    {
        value: "X44",
        label: "X44 - Bolsa de plástico",
    },
    {
        value: "X4A",
        label: "X4A - Caja de acero",
    },
    {
        value: "X4B",
        label: "X4B - Caja de aluminio",
    },
    {
        value: "X4C",
        label: "X4C - Caja de  madera natural",
    },
    {
        value: "X4D",
        label: "X4D - Caja de contrachapado",
    },
    {
        value: "X4F",
        label: "X4F - Caja de madera reconstituida",
    },
    {
        value: "X4G",
        label: "X4G - Caja de cartón",
    },
    {
        value: "X4H",
        label: "X4H - Caja de plástico",
    },
    {
        value: "X5H",
        label: "X5H - Bolsa de plástico tejido",
    },
    {
        value: "X5L",
        label: "X5L - Bolsa textil",
    },
    {
        value: "X5M",
        label: "X5M - Bolsa de papel",
    },
    {
        value: "X6H",
        label: "X6H - Recipiente de plástico, Contenedor compuesto.",
    },
    {
        value: "X7B",
        label: "X7B - Estuche de madera",
    },
    {
        value: "X8A",
        label: "X8A - Pallet de madera",
    },
    {
        value: "X8B",
        label: "X8B - Cajón de madera",
    },
    {
        value: "X8C",
        label: "X8C - Madera flejada",
    },
    {
        value: "XAA",
        label: "XAA - Contenedor intermedio  para gráneles de plástico rígido",
    },
    {
        value: "XAB",
        label: "XAB - Contenedor de fibra",
    },
    {
        value: "XAC",
        label: "XAC - Contenedor de papel",
    },
    {
        value: "XAD",
        label: "XAD - Contenedor de madera",
    },
    {
        value: "XAE",
        label: "XAE - Aerosol",
    },
    {
        value: "XAF",
        label: "XAF - Pallet modular con collares,  80cms * 60cms",
    },
    {
        value: "XAG",
        label: "XAG - Pallet o empaquetado",
    },
    {
        value: "XAH",
        label: "XAH - Pallet, 100cms X 110cms",
    },
    {
        value: "XAI",
        label: "XAI - Contenedor tipo concha",
    },
    {
        value: "XAJ",
        label: "XAJ - Cono",
    },
    {
        value: "XAL",
        label: "XAL - Esfera",
    },
    {
        value: "XAM",
        label: "XAM - Ampolleta no protegida",
    },
    {
        value: "XAP",
        label: "XAP - Ampolleta protegida",
    },
    {
        value: "XAT",
        label: "XAT - Atomizador",
    },
    {
        value: "XAV",
        label: "XAV - Cápsula",
    },
    {
        value: "XB4",
        label: "XB4 - Cinturón",
    },
    {
        value: "XBA",
        label: "XBA - Barril",
    },
    {
        value: "XBB",
        label: "XBB - Bobina",
    },
    {
        value: "XBC",
        label: "XBC - Cajón para botellas / Estante para botellas",
    },
    {
        value: "XBD",
        label: "XBD - Tablero",
    },
    {
        value: "XBE",
        label: "XBE - Flejado",
    },
    {
        value: "XBF",
        label: "XBF - Globo no protegido",
    },
    {
        value: "XBG",
        label: "XBG - Bolso",
    },
    {
        value: "XBH",
        label: "XBH - Manojo",
    },
    {
        value: "XBI",
        label: "XBI - Compartimiento",
    },
    {
        value: "XBJ",
        label: "XBJ - Cubeta",
    },
    {
        value: "XBK",
        label: "XBK - Cesta",
    },
    {
        value: "XBL",
        label: "XBL - Paca comprimida",
    },
    {
        value: "XBM",
        label: "XBM - Cuenco",
    },
    {
        value: "XBN",
        label: "XBN - Paca no comprimida",
    },
    {
        value: "XBO",
        label: "XBO - Botella no-protegida y cilíndrica",
    },
    {
        value: "XBP",
        label: "XBP - Globo protegido",
    },
    {
        value: "XBQ",
        label: "XBQ - Botella cilíndrica protegida",
    },
    {
        value: "XBR",
        label: "XBR - Barra",
    },
    {
        value: "XBS",
        label: "XBS - Botella, no-protegida en forma de bulbo",
    },
    {
        value: "XBT",
        label: "XBT - Rollo de tela",
    },
    {
        value: "XBU",
        label: "XBU - Butt",
    },
    {
        value: "XBV",
        label: "XBV - Botella de bulbo protegido",
    },
    {
        value: "XBW",
        label: "XBW - Caja para líquidos",
    },
    {
        value: "XBX",
        label: "XBX - Caja",
    },
    {
        value: "XBY",
        label: "XBY - Tablero, con fleje/ agrupados/ armados",
    },
    {
        value: "XBZ",
        label: "XBZ - Barras, con fleje/ agrupados/ armados",
    },
    {
        value: "XCA",
        label: "XCA - Lata rectangular",
    },
    {
        value: "XCB",
        label: "XCB - Cajón para cerveza",
    },
    {
        value: "XCC",
        label: "XCC - Mantequera",
    },
    {
        value: "XCD",
        label: "XCD - Lata con mango y boquilla",
    },
    {
        value: "XCE",
        label: "XCE - Cesto tejido",
    },
    {
        value: "XCF",
        label: "XCF - Cofre",
    },
    {
        value: "XCG",
        label: "XCG - Contenedor tipo Jaula",
    },
    {
        value: "XCH",
        label: "XCH - Cajonera",
    },
    {
        value: "XCI",
        label: "XCI - Frasco",
    },
    {
        value: "XCJ",
        label: "XCJ - Ataúd",
    },
    {
        value: "XCK",
        label: "XCK - Barrica",
    },
    {
        value: "XCL",
        label: "XCL - Espiral",
    },
    {
        value: "XCM",
        label: "XCM - Paquete de tarjetas",
    },
    {
        value: "XCN",
        label: "XCN - Contenedor, no especificado como equipo de transporte",
    },
    {
        value: "XCO",
        label: "XCO - Garrafón no protegido",
    },
    {
        value: "XCP",
        label: "XCP - Garrafón protegido",
    },
    {
        value: "XCQ",
        label: "XCQ - Cartucho",
    },
    {
        value: "XCR",
        label: "XCR - Cajón",
    },
    {
        value: "XCS",
        label: "XCS - Estuche",
    },
    {
        value: "XCT",
        label: "XCT - Cartón",
    },
    {
        value: "XCU",
        label: "XCU - Vaso",
    },
    {
        value: "XCV",
        label: "XCV - Cubierta",
    },
    {
        value: "XCW",
        label: "XCW - Jaula estilo rodillo",
    },
    {
        value: "XCX",
        label: "XCX - Lata cilíndrica",
    },
    {
        value: "XCY",
        label: "XCY - Cilindro",
    },
    {
        value: "XCZ",
        label: "XCZ - Lona",
    },
    {
        value: "XDA",
        label: "XDA - Cajón multicapa de plástico",
    },
    {
        value: "XDB",
        label: "XDB - Cajón de varias capas de madera",
    },
    {
        value: "XDC",
        label: "XDC - Cajón multicapa de cartón",
    },
    {
        value: "XDG",
        label:
            "XDG - Jaula, Según la clasificación de la compañía (Commonwealth Handling Equipment Pool (CHEP))",
    },
    {
        value: "XDH",
        label:
            "XDH - Caja, Según la clasificación de la compañía (CHEP), Eurobox",
    },
    {
        value: "XDI",
        label: "XDI - Tambor de hierro",
    },
    {
        value: "XDJ",
        label: "XDJ - damajuana o garrafa, no protegido",
    },
    {
        value: "XDK",
        label: "XDK - Cajón a granel, cartón",
    },
    {
        value: "XDL",
        label: "XDL - Cajas de plástico",
    },
    {
        value: "XDM",
        label: "XDM - Cajones a granel de madera",
    },
    {
        value: "XDN",
        label: "XDN - Dispensador",
    },
    {
        value: "XDP",
        label: "XDP - damajuana o garrafa, protegido",
    },
    {
        value: "XDR",
        label: "XDR - Tambor",
    },
    {
        value: "XDS",
        label: "XDS - Bandeja de una capa sin cubierta y de plástico",
    },
    {
        value: "XDT",
        label: "XDT - Bandeja de una capa sin cubierta y de madera",
    },
    {
        value: "XDU",
        label: "XDU - Bandeja de una capa sin cubierta y  poliestireno",
    },
    {
        value: "XDV",
        label: "XDV - Bandeja de una capa sin cubierta y de cartón",
    },
    {
        value: "XDW",
        label: "XDW - Bandeja de dos capas sin tapa y con bandeja de plástico",
    },
    {
        value: "XDX",
        label: "XDX - Bandeja de dos capas sin cubierta y de madera",
    },
    {
        value: "XDY",
        label: "XDY - Bandeja de dos capas sin cubierta y de cartón",
    },
    {
        value: "XEC",
        label: "XEC - Bolsa de plástico",
    },
    {
        value: "XED",
        label: "XED - Estuche, con pallet de base",
    },
    {
        value: "XEE",
        label: "XEE - Estuche, con pallet base de madera",
    },
    {
        value: "XEF",
        label: "XEF - Estuche, con pallet base de cartón",
    },
    {
        value: "XEG",
        label: "XEG - Estuche, con pallet base de plástico",
    },
    {
        value: "XEH",
        label: "XEH - Estuche, con pallet base de metal",
    },
    {
        value: "XEI",
        label: "XEI - Estuche isotérmico",
    },
    {
        value: "XEN",
        label: "XEN - Sobre",
    },
    {
        value: "XFB",
        label: "XFB - Bolsa flexible",
    },
    {
        value: "XFC",
        label: "XFC - Cajón para fruta",
    },
    {
        value: "XFD",
        label: "XFD - Cajón enmarcado",
    },
    {
        value: "XFE",
        label: "XFE - Tanque flexible",
    },
    {
        value: "XFI",
        label: "XFI - Firkin",
    },
    {
        value: "XFL",
        label: "XFL - Matraz",
    },
    {
        value: "XFO",
        label: "XFO - Cajón para zapatos",
    },
    {
        value: "XFP",
        label: "XFP - Caja auxiliar para película fotográfica",
    },
    {
        value: "XFR",
        label: "XFR - Marco",
    },
    {
        value: "XFT",
        label: "XFT - Contenedor para alimentos",
    },
    {
        value: "XFW",
        label: "XFW - Carro de cama plana",
    },
    {
        value: "XFX",
        label: "XFX - Bolsa flexible tipo contenedor",
    },
    {
        value: "XGB",
        label: "XGB - Botella para gas",
    },
    {
        value: "XGI",
        label: "XGI - Viga",
    },
    {
        value: "XGL",
        label: "XGL - Contenedor tipo galón",
    },
    {
        value: "XGR",
        label: "XGR - Recipiente de vidrio",
    },
    {
        value: "XGU",
        label:
            "XGU - Bandeja contenedor para apilar horizontalmente objetos planos",
    },
    {
        value: "XGY",
        label: "XGY - Costal de Yute",
    },
    {
        value: "XGZ",
        label: "XGZ - Vigas con correas o agrupadas",
    },
    {
        value: "XHA",
        label: "XHA - Cesta con mango y de plástico",
    },
    {
        value: "XHB",
        label: "XHB - Cesta con mango y de madera",
    },
    {
        value: "XHC",
        label: "XHC - Cesta  con asa y de  cartón",
    },
    {
        value: "XHG",
        label: "XHG - Hogshead",
    },
    {
        value: "XHN",
        label: "XHN - Gancho",
    },
    {
        value: "XHR",
        label: "XHR - Cesto",
    },
    {
        value: "XIA",
        label: "XIA - Paquete con pantalla y de madera",
    },
    {
        value: "XIB",
        label: "XIB - Paquete  con pantalla y de cartón",
    },
    {
        value: "XIC",
        label: "XIC - Paquete con pantalla y de plástico",
    },
    {
        value: "XID",
        label: "XID - Paquete con pantalla y de metal",
    },
    {
        value: "XIE",
        label: "XIE - Paquete de mostrador.",
    },
    {
        value: "XIF",
        label: "XIF - Envase para alimentos",
    },
    {
        value: "XIG",
        label: "XIG - Paquete envuelto en papel",
    },
    {
        value: "XIH",
        label: "XIH - Tambor de plástico",
    },
    {
        value: "XIK",
        label: "XIK - Paquete de cartón con los agujeros para botellas",
    },
    {
        value: "XIL",
        label: "XIL - Bandeja rígida con tapa y apilable (CEN TS 14482: 2002)",
    },
    {
        value: "XIN",
        label: "XIN - Lingote",
    },
    {
        value: "XIZ",
        label: "XIZ - Lingotes  con correas/ agrupados",
    },
    {
        value: "XJB",
        label: "XJB - Bolsa jumbo",
    },
    {
        value: "XJC",
        label: "XJC - Bidón rectangular",
    },
    {
        value: "XJG",
        label: "XJG - Jarra",
    },
    {
        value: "XJR",
        label: "XJR - Tarro",
    },
    {
        value: "XJT",
        label: "XJT - Bolsa de yute",
    },
    {
        value: "XJY",
        label: "XJY - Bidón, cilíndrico",
    },
    {
        value: "XKG",
        label: "XKG - Barrilete",
    },
    {
        value: "XKI",
        label: "XKI - Kit (Conjunto de piezas)",
    },
    {
        value: "XLE",
        label: "XLE - Valijas",
    },
    {
        value: "XLG",
        label: "XLG - Bitácora",
    },
    {
        value: "XLT",
        label: "XLT - Lote",
    },
    {
        value: "XLU",
        label: "XLU - Caja de arrastre",
    },
    {
        value: "XLV",
        label: "XLV - Contenedor pequeño",
    },
    {
        value: "XLZ",
        label: "XLZ - Registros  con fleje/ agrupados/ armados",
    },
    {
        value: "XMA",
        label: "XMA - Cajón metálico",
    },
    {
        value: "XMB",
        label: "XMB - Múltiplo de bolsas",
    },
    {
        value: "XMC",
        label: "XMC - Cajón para leche",
    },
    {
        value: "XME",
        label: "XME - Contenedor de metal",
    },
    {
        value: "XMR",
        label: "XMR - Recipiente de metal",
    },
    {
        value: "XMS",
        label: "XMS - Saco milti-pared",
    },
    {
        value: "XMT",
        label: "XMT - Tapete",
    },
    {
        value: "XMW",
        label: "XMW - Contenedor envuelto en plástico",
    },
    {
        value: "XMX",
        label: "XMX - Caja pequeña de cerillos",
    },
    {
        value: "XNA",
        label: "XNA - No disponible",
    },
    {
        value: "XNE",
        label: "XNE - Sin empaque o no empaquetado",
    },
    {
        value: "XNF",
        label: "XNF - Sin empaque o no empaquetado, unidad simple",
    },
    {
        value: "XNG",
        label: "XNG - Sin empaque o no empaquetado, unidades múltiples",
    },
    {
        value: "XNS",
        label: "XNS - Caja nido",
    },
    {
        value: "XNT",
        label: "XNT - Red",
    },
    {
        value: "XNU",
        label: "XNU - Red de plástico con tubo",
    },
    {
        value: "XNV",
        label: "XNV - Red textil con tubo",
    },
    {
        value: "XOA",
        label:
            "XOA - Pallet, Según la clasificación de la compañía (Commonwealth Handling Equipment Pool (CHEP) 40 cm x 60 cm",
    },
    {
        value: "XOB",
        label:
            "XOB - Pallet, Según la clasificación de la compañía (Commonwealth Handling Equipment Pool (CHEP) 80 cm x 120 cm",
    },
    {
        value: "XOC",
        label:
            "XOC - Pallet, Según la clasificación de la compañía (Commonwealth Handling Equipment Pool (CHEP) 100 cm x 120 cm",
    },
    {
        value: "XOD",
        label: "XOD - Pallet, AS 4068-1993",
    },
    {
        value: "XOE",
        label: "XOE - Pallet, ISO T11",
    },
    {
        value: "XOF",
        label: "XOF - Plataforma, peso o dimensión no especificada",
    },
    {
        value: "XOK",
        label: "XOK - Bloque",
    },
    {
        value: "XOT",
        label: "XOT - Octabin",
    },
    {
        value: "XP2",
        label: "XP2 - Charola",
    },
    {
        value: "XPA",
        label: "XPA - Cajetilla",
    },
    {
        value: "XPB",
        label: "XPB - Pallet, Caja combinada y abierta con caja y pallet.",
    },
    {
        value: "XPC",
        label: "XPC - Paquete postal",
    },
    {
        value: "XPD",
        label: "XPD - Pallet modular con collares (80cms * 100cms)",
    },
    {
        value: "XPE",
        label: "XPE - Pallet modular con collares (80cms * 120cms)",
    },
    {
        value: "XPF",
        label: "XPF - Corral",
    },
    {
        value: "XPG",
        label: "XPG - Placa",
    },
    {
        value: "XPH",
        label: "XPH - Cantaro",
    },
    {
        value: "XPI",
        label: "XPI - Pleca",
    },
    {
        value: "XPJ",
        label: "XPJ - Canastilla",
    },
    {
        value: "XPK",
        label: "XPK - Paquete",
    },
    {
        value: "XPL",
        label: "XPL - Balde",
    },
    {
        value: "XPN",
        label: "XPN - Tablón",
    },
    {
        value: "XPO",
        label: "XPO - Bolsa pequeña",
    },
    {
        value: "XPR",
        label: "XPR - Contenedor de plástico",
    },
    {
        value: "XPT",
        label: "XPT - Maceta",
    },
    {
        value: "XPU",
        label: "XPU - Cacerola",
    },
    {
        value: "XPV",
        label: "XPV - Tubos, con fleje/ agrupados/ armados",
    },
    {
        value: "XPX",
        label: "XPX - Pallet",
    },
    {
        value: "XPY",
        label: "XPY - Placas con fleje/ agrupados/ armados",
    },
    {
        value: "XPZ",
        label: "XPZ - Tablones con fleje/ agrupados/ armados",
    },
    {
        value: "XQA",
        label: "XQA - Tambor de acero con cabeza no desmontable",
    },
    {
        value: "XQB",
        label: "XQB - Tambor de  acero con cabeza extraíble",
    },
    {
        value: "XQC",
        label: "XQC - Tambor de aluminio con cabeza no extraíble",
    },
    {
        value: "XQD",
        label: "XQD - Tambor de aluminio con cabeza extraíble",
    },
    {
        value: "XQF",
        label: "XQF - Tambor, plástico con cabeza no desmontable",
    },
    {
        value: "XQG",
        label: "XQG - Tambor, plástico, cabeza extraíble",
    },
    {
        value: "XQH",
        label: "XQH - Barril de madera con tapón",
    },
    {
        value: "XQJ",
        label: "XQJ - Barril de madera con cabeza desprendible",
    },
    {
        value: "XQK",
        label: "XQK - Bidón de acero con cabeza no desmontable",
    },
    {
        value: "XQL",
        label: "XQL - Bidón de acero con cabeza desmontable",
    },
    {
        value: "XQM",
        label: "XQM - Bidón de plástico con cabeza no desmontable",
    },
    {
        value: "XQN",
        label: "XQN - Bidón de plástico con cabeza extraíble",
    },
    {
        value: "XQP",
        label: "XQP - Caja de madera natural estándar",
    },
    {
        value: "XQQ",
        label:
            "XQQ - Caja de madera natural con muros a prueba de filtraciones",
    },
    {
        value: "XQR",
        label: "XQR - Caja de plástico expandida",
    },
    {
        value: "XQS",
        label: "XQS - Caja de plástico sólida",
    },
    {
        value: "XRD",
        label: "XRD - Rod",
    },
    {
        value: "XRG",
        label: "XRG - Anillo",
    },
    {
        value: "XRJ",
        label: "XRJ - Estante, Perchero para ropa",
    },
    {
        value: "XRK",
        label: "XRK - Estante",
    },
    {
        value: "XRL",
        label: "XRL - Carrete",
    },
    {
        value: "XRO",
        label: "XRO - Rollo",
    },
    {
        value: "XRT",
        label: "XRT - Red Roja",
    },
    {
        value: "XRZ",
        label: "XRZ - Varillas  con fleje/ agrupados/ armados",
    },
    {
        value: "XSA",
        label: "XSA - Saco",
    },
    {
        value: "XSB",
        label: "XSB - Losa",
    },
    {
        value: "XSC",
        label: "XSC - Cajón poco profundo",
    },
    {
        value: "XSD",
        label: "XSD - Huso",
    },
    {
        value: "XSE",
        label: "XSE - Baúl",
    },
    {
        value: "XSH",
        label: "XSH - Bolsa pequeña hermética",
    },
    {
        value: "XSI",
        label: "XSI - Patín",
    },
    {
        value: "XSK",
        label: "XSK - Carcasa esqueleto",
    },
    {
        value: "XSL",
        label: "XSL - Hoja de deslizamiento",
    },
    {
        value: "XSM",
        label: "XSM - Hoja de metal",
    },
    {
        value: "XSO",
        label: "XSO - Carrete pequeño",
    },
    {
        value: "XSP",
        label: "XSP - Hoja de empaque de plástico",
    },
    {
        value: "XSS",
        label: "XSS - Cajón de acero",
    },
    {
        value: "XSU",
        label: "XSU - Maleta",
    },
    {
        value: "XSV",
        label: "XSV - Sobre de acero",
    },
    {
        value: "XSW",
        label: "XSW - Envoltorio",
    },
    {
        value: "XSY",
        label: "XSY - Manga",
    },
    {
        value: "XSZ",
        label: "XSZ - Hojas  con fleje/ agrupados/ armados",
    },
    {
        value: "XT1",
        label: "XT1 - Tableta",
    },
    {
        value: "XTB",
        label: "XTB - Tina",
    },
    {
        value: "XTC",
        label: "XTC - Caja para té",
    },
    {
        value: "XTD",
        label: "XTD - Tubo plegable",
    },
    {
        value: "XTG",
        label: "XTG - Contenedor de tanque genérico",
    },
    {
        value: "XTI",
        label: "XTI - Tierce",
    },
    {
        value: "XTK",
        label: "XTK - Tanque rectangular",
    },
    {
        value: "XTL",
        label: "XTL - Tina con tapa",
    },
    {
        value: "XTN",
        label: "XTN - Hojalata",
    },
    {
        value: "XTO",
        label: "XTO - Tonel",
    },
    {
        value: "XTR",
        label: "XTR - Maletero",
    },
    {
        value: "XTS",
        label: "XTS - Estructura",
    },
    {
        value: "XTT",
        label: "XTT - Bolsa de mano",
    },
    {
        value: "XTU",
        label: "XTU - Tubo",
    },
    {
        value: "XTV",
        label: "XTV - Tubo con boquilla",
    },
    {
        value: "XTW",
        label: "XTW - Pallet tricapa",
    },
    {
        value: "XTY",
        label: "XTY - Tanque cilíndrico",
    },
    {
        value: "XTZ",
        label: "XTZ - Tubos  con fleje/ agrupados/ armados",
    },
    {
        value: "XUC",
        label: "XUC - Sin empaque",
    },
    {
        value: "XUN",
        label: "XUN - Unidad",
    },
    {
        value: "XVA",
        label: "XVA - Tanque",
    },
    {
        value: "XVG",
        label: "XVG - Tanque de gas (a 1,031 mbar y 15° C)",
    },
    {
        value: "XVI",
        label: "XVI - Frasco pequeño",
    },
    {
        value: "XVK",
        label: "XVK - Paquete transportable",
    },
    {
        value: "XVL",
        label: "XVL - Contenedor para líquidos a granel",
    },
    {
        value: "XVN",
        label: "XVN - Vehículo",
    },
    {
        value: "XVO",
        label:
            "XVO - Contenedor para sólido de partículas grandes a granel (nódulos)",
    },
    {
        value: "XVP",
        label: "XVP - Envasado al vacío",
    },
    {
        value: "XVQ",
        label:
            "XVQ - Tanque para Gas licuado (a temperatura / presión anormal)",
    },
    {
        value: "XVR",
        label:
            "XVR - Contenedor para sólidos de partículas granulares a granel (Granos)",
    },
    {
        value: "XVS",
        label: "XVS - Contenedor de chatarra a granel",
    },
    {
        value: "XVY",
        label:
            "XVY - Contenedor para sólido de partículas finas a granel ('polvos')",
    },
    {
        value: "XWA",
        label: "XWA - Contenedor de granel intermedio",
    },
    {
        value: "XWB",
        label: "XWB - Botella de mimbre",
    },
    {
        value: "XWC",
        label: "XWC - Contenedor intermedio para gráneles y de acero",
    },
    {
        value: "XWD",
        label: "XWD - Contenedor intermedio para gráneles y de aluminio",
    },
    {
        value: "XWF",
        label: "XWF - Contenedor intermedio para gráneles y de metal",
    },
    {
        value: "XWG",
        label:
            "XWG - Contenedor intermedio para gráneles y de acero presurizado menor a 10 kpa",
    },
    {
        value: "XWH",
        label:
            "XWH - Contenedor intermedio para gráneles y de aluminio, presurizado menor a 10 kpa",
    },
    {
        value: "XWJ",
        label:
            "XWJ - Contenedor intermedio para gráneles y de metal con una presión de 10 kpa",
    },
    {
        value: "XWK",
        label:
            "XWK - Contenedor intermedio para gráneles y de acero para líquido",
    },
    {
        value: "XWL",
        label:
            "XWL - Contenedor intermedio para gráneles y de aluminio para líquido",
    },
    {
        value: "XWM",
        label:
            "XWM - Contenedor intermedio para gráneles y de metal para líquido",
    },
    {
        value: "XWN",
        label:
            "XWN - Contenedor intermedio para gráneles con tejido plástico sin capa con revestimiento",
    },
    {
        value: "XWP",
        label:
            "XWP - Contenedor intermedio para gráneles con tejido plástico y recubierto",
    },
    {
        value: "XWQ",
        label:
            "XWQ - Contenedor intermedio para gráneles con tejido plástico con revestimiento",
    },
    {
        value: "XWR",
        label:
            "XWR - Contenedor intermedio para gráneles con tejido plástico, revestido y con forro",
    },
    {
        value: "XWS",
        label:
            "XWS - Contenedor intermedio para gráneles con película de plástico",
    },
    {
        value: "XWT",
        label:
            "XWT - Contenedor intermedio para gráneles textil sin capa / forro",
    },
    {
        value: "XWU",
        label:
            "XWU - Contenedor intermedio para gráneles de madera natural con forro interior",
    },
    {
        value: "XWV",
        label: "XWV - Contenedor intermedio para gráneles textil recubierto",
    },
    {
        value: "XWW",
        label:
            "XWW - Contenedor intermedio para gráneles textil con revestimiento",
    },
    {
        value: "XWX",
        label:
            "XWX - Contenedor intermedio para gráneles textil recubierto y con forro",
    },
    {
        value: "XWY",
        label:
            "XWY - Contenedor intermedio para gráneles contrachapado con revestimiento interior",
    },
    {
        value: "XWZ",
        label:
            "XWZ - Contenedor intermedio para gráneles de madera reconstituida con revestimiento interior",
    },
    {
        value: "XXA",
        label: "XXA - Bolsa de tejido plástico, sin abrigo interior ni forro",
    },
    {
        value: "XXB",
        label: "XXB - Bolsa de tejido plástico a prueba de filtraciones",
    },
    {
        value: "XXC",
        label: "XXC - Bolsa de tejido plástico resistente al agua",
    },
    {
        value: "XXD",
        label: "XXD - Bolsa con película de plástico",
    },
    {
        value: "XXF",
        label: "XXF - Bolsa textil sin capa ni forro interior",
    },
    {
        value: "XXG",
        label: "XXG - Bolsa textil a prueba de filtraciones",
    },
    {
        value: "XXH",
        label: "XXH - Bolsa textil resistente al agua",
    },
    {
        value: "XXJ",
        label: "XXJ - Bolsa de papel multi-pared",
    },
    {
        value: "XXK",
        label: "XXK - Bolsa de papel multi-pared, resistente al agua",
    },
    {
        value: "XYA",
        label:
            "XYA - Empaque compuesto, recipiente de plástico en tambor de acero",
    },
    {
        value: "XYB",
        label:
            "XYB - Empaque compuesto, recipiente de plástico en cajas de acero",
    },
    {
        value: "XYC",
        label:
            "XYC - Empaque compuesto, recipiente de plástico en tambor de aluminio",
    },
    {
        value: "XYD",
        label:
            "XYD - Empaque compuesto, recipiente de plástico en cajón de aluminio",
    },
    {
        value: "XYF",
        label:
            "XYF - Empaque compuesto, recipiente de plástico en caja de madera",
    },
    {
        value: "XYG",
        label:
            "XYG - Empaque compuesto, recipiente de plástico en tambor de madera contrachapada",
    },
    {
        value: "XYH",
        label:
            "XYH - Empaque compuesto, recipiente de plástico en caja de madera contrachapada",
    },
    {
        value: "XYJ",
        label:
            "XYJ - Empaque compuesto, recipiente de plástico en tambor de fibra",
    },
    {
        value: "XYK",
        label:
            "XYK - Empaque compuesto, recipiente de plástico en caja de cartón",
    },
    {
        value: "XYL",
        label:
            "XYL - Empaque compuesto, recipiente de plástico en el tambor de plástico",
    },
    {
        value: "XYM",
        label:
            "XYM - Empaque compuesto, recipiente de plástico en caja de plástico sólido",
    },
    {
        value: "XYN",
        label:
            "XYN - Empaque compuesto, receptáculo de vidrio en tambor de acero",
    },
    {
        value: "XYP",
        label:
            "XYP - Empaque compuesto, receptáculo de vidrio en caja de cajas de acero",
    },
    {
        value: "XYQ",
        label:
            "XYQ - Empaque compuesto, recipiente de vidrio en tambor de aluminio",
    },
    {
        value: "XYR",
        label:
            "XYR - Empaque compuesto, receptáculo de vidrio en caja de aluminio",
    },
    {
        value: "XYS",
        label:
            "XYS - Empaque compuesto, recipiente de vidrio en caja de madera",
    },
    {
        value: "XYT",
        label:
            "XYT - Empaque compuesto, recipiente de vidrio en tambor de madera contrachapada",
    },
    {
        value: "Xyv",
        label:
            "Xyv - Empaque compuesto, recipiente de vidrio en el cesto de mimbre",
    },
    {
        value: "XYW",
        label:
            "XYW - Empaque compuesto, recipiente de vidrio en tambor de fibra",
    },
    {
        value: "XYX",
        label:
            "XYX - Empaque compuesto, recipiente de vidrio en caja de cartón",
    },
    {
        value: "XYY",
        label:
            "XYY - Empaque compuesto, recipiente de vidrio en paquete de plástico expandible",
    },
    {
        value: "XYZ",
        label:
            "XYZ - Empaque compuesto, recipiente de vidrio en paquete de plástico sólido",
    },
    {
        value: "XZA",
        label: "XZA - Contenedor de granel intermedio, papel, multi-pared",
    },
    {
        value: "XZB",
        label: "XZB - Bolsa grande",
    },
    {
        value: "XZC",
        label:
            "XZC - Contenedor intermedio para gráneles de papel, multi-pared y resistente al agua",
    },
    {
        value: "XZD",
        label:
            "XZD - Contenedor intermedio para gráneles de plástico rígido, con equipo estructural para sólidos",
    },
    {
        value: "XZF",
        label:
            "XZF - Contenedor intermedio para gráneles de plástico rígido, autoportante para sólidos",
    },
    {
        value: "XZG",
        label:
            "XZG - Contenedor intermedio para gráneles de plástico rígido, con equipo estructural, presurizado",
    },
    {
        value: "XZH",
        label:
            "XZH - Contenedor intermedio para gráneles de plástico rígido, autoportante y presurizado",
    },
    {
        value: "XZJ",
        label:
            "XZJ - Contenedor intermedio para gráneles de plástico rígido, con equipo estructural para líquidos",
    },
    {
        value: "XZK",
        label:
            "XZK - Contenedor intermedio para gráneles de plástico rígido, autoportante, líquidos",
    },
    {
        value: "XZL",
        label:
            "XZL - Contenedor intermedio para gráneles, compuesto y de plástico rígido, sólidos",
    },
    {
        value: "XZM",
        label:
            "XZM - Contenedor intermedio para gráneles, compuesto y de plástico flexible, sólidos",
    },
    {
        value: "XZN",
        label:
            "XZN - Contenedor intermedio para gráneles, compuesto y de plástico rígido, presurizado",
    },
    {
        value: "XZP",
        label:
            "XZP - Contenedor intermedio para gráneles, compuesto y de plástico flexible, presurizado",
    },
    {
        value: "XZQ",
        label:
            "XZQ - Contenedor intermedio para gráneles, compuesto y de plástico rígido, líquidos",
    },
    {
        value: "XZR",
        label:
            "XZR - Contenedor intermedio para gráneles, compuesto y de plástico flexible para líquidos",
    },
    {
        value: "XZS",
        label: "XZS - Contenedor intermedio para gráneles, compuesto",
    },
    {
        value: "XZT",
        label:
            "XZT - Contenedor intermedio para gráneles con tablero de fibras",
    },
    {
        value: "XZU",
        label: "XZU - Contenedor intermedio para gráneles flexible",
    },
    {
        value: "XZV",
        label:
            "XZV - Contenedor intermedio para gráneles de metal, distinto del acero",
    },
    {
        value: "XZW",
        label: "XZW - Contenedor intermedio para gráneles, de madera natural",
    },
    {
        value: "XZX",
        label: "XZX - Contenedor intermedio para gráneles, de contrachapado",
    },
    {
        value: "XZY",
        label:
            "XZY - Contenedor intermedio para gráneles, de madera reconstituida",
    },
    {
        value: "KGM",
        label: "KGM - Kilogramo",
    },
    {
        value: "MC",
        label: "MC - Microgramo",
    },
    {
        value: "DJ",
        label: "DJ - Decagramo",
    },
    {
        value: "DG",
        label: "DG - Decigramo",
    },
    {
        value: "GRM",
        label: "GRM - Gramo",
    },
    {
        value: "CGM",
        label: "CGM - Centigramo",
    },
    {
        value: "TNE",
        label: "TNE - Tonelada (tonelada métrica)",
    },
    {
        value: "DTN",
        label: "DTN - Decitonelada métrica",
    },
    {
        value: "MGM",
        label: "MGM - Miligramo",
    },
    {
        value: "HGM",
        label: "HGM - Hectogramo",
    },
    {
        value: "KTN",
        label: "KTN - Kilotonelada Métrica",
    },
    {
        value: "2U",
        label: "2U - Megagramo",
    },
    {
        value: "LBR",
        label: "LBR - Libra",
    },
    {
        value: "GRN",
        label: "GRN - Grano",
    },
    {
        value: "ONZ",
        label: "ONZ - Onza (avoirdupois)",
    },
    {
        value: "CWI",
        label: "CWI - Hundredweight",
    },
    {
        value: "CWA",
        label: "CWA - Hundred pound",
    },
    {
        value: "LTN",
        label: "LTN - Tonelada (UK) o tonelada larga (estados unidos)",
    },
    {
        value: "STI",
        label: "STI - Estone (UK)",
    },
    {
        value: "STN",
        label:
            "STN - Tonelada (estados unidos) o tonelada corta (UK y estados unidos)",
    },
    {
        value: "APZ",
        label: "APZ - Onza troy u onza farmacéutica ",
    },
    {
        value: "F13",
        label: "F13 - Slug",
    },
    {
        value: "K64",
        label: "K64 - Libra (avoirdupois) por grado fahrenheit",
    },
    {
        value: "L69",
        label: "L69 - Tonelada por kelvin",
    },
    {
        value: "L87",
        label: "L87 - Tonelada corta por grado fahrenheit",
    },
    {
        value: "M85",
        label: "M85 - Tonelada, ensayo",
    },
    {
        value: "M86",
        label: "M86 - Libra Alemana",
    },
    {
        value: "J33",
        label: "J33 - Microgramo por kilogramo",
    },
    {
        value: "L32",
        label: "L32 - Nanogramo por kilogramo",
    },
    {
        value: "NA",
        label: "NA - Miligramo por kilogramo",
    },
    {
        value: "M29",
        label: "M29 - Kilogramo por kilogramo",
    },
    {
        value: "M91",
        label: "M91 - Libra por libra",
    },
    {
        value: "Q29",
        label: "Q29 - Microgramo por hectogramo",
    },
    {
        value: "MTQ",
        label: "MTQ - Metro cúbico",
    },
    {
        value: "MAL",
        label: "MAL - Megalitro",
    },
    {
        value: "LTR",
        label: "LTR - Litro",
    },
    {
        value: "MMQ",
        label: "MMQ - Milímetro cúbico",
    },
    {
        value: "CMQ",
        label: "CMQ - Centímetro cúbico",
    },
    {
        value: "DMQ",
        label: "DMQ - Decímetro cúbico",
    },
    {
        value: "MLT",
        label: "MLT - Mililitro",
    },
    {
        value: "HLT",
        label: "HLT - Hectolitro",
    },
    {
        value: "CLT",
        label: "CLT - Centilitro",
    },
    {
        value: "DMA",
        label: "DMA - Decámetro cúbico",
    },
    {
        value: "H19",
        label: "H19 - Hectómetro cúbico",
    },
    {
        value: "H20",
        label: "H20 - Kilómetro cúbico",
    },
    {
        value: "M71",
        label: "M71 - Metro cúbico por pascal (joules)",
    },
    {
        value: "DLT",
        label: "DLT - Decilitro",
    },
    {
        value: "4G",
        label: "4G - Microlitro",
    },
    {
        value: "K6",
        label: "K6 - Kilolitro",
    },
    {
        value: "A44",
        label: "A44 - Decalitro",
    },
    {
        value: "G94",
        label: "G94 - Centímetro cúbico por bar",
    },
    {
        value: "G95",
        label: "G95 - Litro por bar",
    },
    {
        value: "G96",
        label: "G96 - Metro cúbico por bar",
    },
    {
        value: "G97",
        label: "G97 - Mililitro por bar ",
    },
    {
        value: "5I",
        label: "5I - Pies cúbicos estándar",
    },
    {
        value: "INQ",
        label: "INQ - Pulgada cúbica",
    },
    {
        value: "FTQ",
        label: "FTQ - Pie cúbico",
    },
    {
        value: "YDQ",
        label: "YDQ - Yarda cúbica",
    },
    {
        value: "GLI",
        label: "GLI - Galón (UK)",
    },
    {
        value: "GLL",
        label: "GLL - Galón (EUA)",
    },
    {
        value: "PT",
        label: "PT - Pinta (US)",
    },
    {
        value: "PTI",
        label: "PTI - Pint (uk)",
    },
    {
        value: "QTI",
        label: "QTI - Cuarto (UK)",
    },
    {
        value: "PTL",
        label: "PTL - Pinta líquida (estados unidos)",
    },
    {
        value: "QTL",
        label: "QTL - Cuarto de líquido (estadis unidos)",
    },
    {
        value: "PTD",
        label: "PTD - Pinta seca (estados unidos)",
    },
    {
        value: "OZI",
        label: "OZI - Onza líquida (UK)",
    },
    {
        value: "QT",
        label: "QT - Cuarto (EUA)",
    },
    {
        value: "J57",
        label: "J57 - Barril (uk petróleo)",
    },
    {
        value: "K21",
        label: "K21 - Pie cúbico por grado fahrenheit",
    },
    {
        value: "K23",
        label: "K23 - Pie cúbico por psi (libra por pulgada cuadrada)",
    },
    {
        value: "L43",
        label: "L43 - Peck (UK)",
    },
    {
        value: "L61",
        label: "L61 - Pinta (US seco)",
    },
    {
        value: "L62",
        label: "L62 - Cuarto de galón (seco de los EUA)",
    },
    {
        value: "L84",
        label: "L84 - Tonelada (flota UK)",
    },
    {
        value: "L86",
        label: "L86 - Tonelada (flota estados unidos)",
    },
    {
        value: "M11",
        label: "M11 - Yarda cúbica por grado fahrenheit",
    },
    {
        value: "M14",
        label: "M14 - Yarda cúbica por psi (libra por pulgada cuadrada)",
    },
    {
        value: "OZA",
        label: "OZA - Onza líquida (estados unidos)",
    },
    {
        value: "BUI",
        label: "BUI - Bushel (UK)",
    },
    {
        value: "BUA",
        label: "BUA - Bushel (EUA)",
    },
    {
        value: "BLL",
        label: "BLL - Barril (EUA)",
    },
    {
        value: "BLD",
        label: "BLD - Barril seco (EUA)",
    },
    {
        value: "GLD",
        label: "GLD - Galón seco (EUA)",
    },
    {
        value: "QTD",
        label: "QTD - Cuarto seco (estados unidos)",
    },
    {
        value: "G26",
        label: "G26 - Estere",
    },
    {
        value: "G21",
        label: "G21 - Taza (unidad de volumen)",
    },
    {
        value: "G24",
        label: "G24 - Cucharada (estados unidos)",
    },
    {
        value: "G25",
        label: "G25 - Cucharilla (estados unidos)",
    },
    {
        value: "G23",
        label: "G23 - Peck",
    },
    {
        value: "M67",
        label: "M67 - Acre-pie",
    },
    {
        value: "M68",
        label: "M68 - Cordón",
    },
    {
        value: "M69",
        label: "M69 - Milla cúbica (reino unido)",
    },
    {
        value: "M70",
        label: "M70 - Unidad tradicional de capacidad de carga",
    },
    {
        value: "Q32",
        label: "Q32 - Femtolitro",
    },
    {
        value: "Q33",
        label: "Q33 - Picolitro",
    },
    {
        value: "Q34",
        label: "Q34 - Nanolitro",
    },
    {
        value: "NM3",
        label: "NM3 - Metro cúbico normalizado",
    },
    {
        value: "SM3",
        label: "SM3 - Metro cúbico estándar",
    },
]

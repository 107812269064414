import React from "react"
import { connect } from "react-redux"
import { TrackLoaderContainer } from "../track/loader"
import DocumentForm from "./DocumentForm"
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
    details__container: {
        margin: "0 96px",
        minWidth: "1024px",
    },
})

const DocumentSelectorPresentation = withStyles(styles)(
    ({ classes, documentType, match, shipmentId }) => {
        return (
            <div>
                <TrackLoaderContainer match={match} />
                <div className={classes.details__container}>
                    <DocumentForm
                        shipmentId={shipmentId}
                        documentType={documentType}
                    />
                </div>
            </div>
        )
    }
)

export const documentTypes = Object.freeze([
    { documentCategory: "USMCA", urlPrefix: "usmca", frontEndUrl: "usmca" },
    {
        documentCategory: "commercialInvoice",
        urlPrefix: "commercialInvoice",
        frontEndUrl: "commercial-invoice",
    },
    {
        documentCategory: "certificateOfOrigin",
        urlPrefix: "certificateOfOrigin",
        frontEndUrl: "certificate-of-origin",
    },
])

const mapStateToProps = (state, props) => {
    const shipmentId = props?.computedMatch?.params?.shipmentId
    const { shipment } = state?.shipment?.list[shipmentId] ?? {}
    let documentType = documentTypes.find(
        x => x.frontEndUrl === props.computedMatch?.params?.documentType
    )
    return {
        match: props.computedMatch,
        shipment,
        shipmentId,
        documentType,
    }
}

export const DocumentContainer = connect(mapStateToProps)(
    DocumentSelectorPresentation
)

import React, { Fragment, useEffect, useRef, useState } from "react"
import { change, formValues } from "redux-form"
import { useDispatch } from "react-redux"
import {
    Button,
    Grid,
    IconButton,
    Modal,
    Typography,
    withStyles,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import AddSignatureModal from "../locations/components/AddSignatureModal"
import formName from "../util/form-name"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const styles = theme => ({
    signatureBox: {
        border: "2px solid black",
        maxWidth: "100%",
    },
})

const ManageSignature = formValues(({ fieldName }) => ({
    signature: fieldName,
}))(
    formName(
        ({
            signature,
            fieldName,
            sectionPrefix,
            addTitle,
            replaceTitle,
            form,
            previewDimensions,
            gaCategory,
        }) => {
            const dispatch = useDispatch()
            const { logGAEvent } = useGAContext()

            const [isModalOpen, setIsModalOpen] = useState(false)

            const handleAddClose = () => {
                setIsModalOpen(false)
                logGAEvent(gaCategory, "Add Signature Modal Close")
            }

            const handleAddOpen = () => {
                setIsModalOpen(true)
                if (signature) {
                    logGAEvent(gaCategory, "Replace Signature Click")
                } else {
                    logGAEvent(gaCategory, "Add Signature Click")
                }
            }

            const title = (signature ? replaceTitle : addTitle) ?? addTitle

            const fullFieldName = sectionPrefix
                ? `${sectionPrefix}.${fieldName}`
                : fieldName

            return (
                <Fragment>
                    <Modal open={isModalOpen} onClose={handleAddClose}>
                        <AddSignatureModal
                            handleAddClose={handleAddClose}
                            title={title}
                            changeField={value => {
                                dispatch(change(form, fullFieldName, value))
                                logGAEvent(gaCategory, "Signature Added")
                            }}
                        />
                    </Modal>
                    <Grid item container alignItems="baseline" spacing={2}>
                        <Grid item xs={8}>
                            <Button
                                id={title?.props?.id}
                                variant="outlined"
                                color="primary"
                                onClick={() => handleAddOpen()}
                            >
                                {title}
                            </Button>
                        </Grid>
                        {signature && (
                            <Fragment>
                                <Grid item xs={4}>
                                    <IconButton
                                        id="signatureDelete"
                                        onClick={() => {
                                            dispatch(
                                                change(
                                                    form,
                                                    fullFieldName,
                                                    null
                                                )
                                            )
                                            logGAEvent(
                                                gaCategory,
                                                "Generate Document - Signature Delete Click"
                                            )
                                        }}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">
                                        {signature?.customName
                                            ? signature.customName
                                            : signature.originalFileName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <SigaturePreview
                                        signature={signature}
                                        {...previewDimensions}
                                    />
                                </Grid>
                            </Fragment>
                        )}
                    </Grid>
                </Fragment>
            )
        }
    )
)

const SigaturePreview = withStyles(styles)(
    ({ classes, signature, width, height }) => {
        const canvas = useRef()
        // initialize the canvas context
        useEffect(() => {
            // assign the width and height to canvas
            const canvasElem = canvas.current
            canvasElem.width = width
            canvasElem.height = height

            // get context of the canvas
            const ctx = canvasElem.getContext("2d")
            //ctx.fillStyle = "lightgray"
            ctx.clearRect(0, 0, width, height)
            //ctx.fillRect(0, 0, width, height)

            if (signature != null) {
                const image = new Image()
                image.src = `data:${signature.mimeType};base64,${signature.content}`

                image.onload = () => {
                    const scaleFactor = Math.min(
                        width / image.naturalWidth,
                        height / image.naturalHeight
                    )

                    ctx.drawImage(
                        image,
                        0,
                        0,
                        image.naturalWidth * scaleFactor,
                        image.naturalHeight * scaleFactor
                    )
                }
            }
        }, [signature, width, height])

        return <canvas ref={canvas} className={classes.signatureBox} />
    }
)

export default ManageSignature

import React from "react"
import Highlighter from "react-highlight-words"
import moment from "moment"
import { FormattedMessage } from "react-intl"
export { Modal } from "./modal"
export { ChatSelector } from "./chat-selector"
export { ValidatedField } from "./validated-field"
export { CarrierLogo } from "./carrier-logo"
export { Map } from "./map"
export { DownloadCSVButton } from "./download-csv-button"
export { DeploymentReloadContainer } from "./deployment-reload"
export { InactivityTimer } from "./inactivity-timer"
export { FooterRow } from "./footer-row"
export { withToken } from "./withToken"

export const truncateText = (text, length = 30) =>
    text && text.length > length ? `${text.substr(0, length - 3)}...` : text

export const calcDeliveryTime = date => {
    if (!date) return undefined
    const innerDate = date instanceof moment ? date : moment.utc(date)
    return (
        innerDate &&
        (innerDate.format("H") === "23" ? (
            <FormattedMessage
                id="generalTerms__closeOfBusiness"
                defaultMessage="Close of Business"
            />
        ) : (
            innerDate.format("LT")
        ))
    )
}

export const dateIsInPFFRange = date => {
    return (
        date.month() > 9 ||
        (date.month() == 9 && date.date() >= 15) ||
        date.month() < 3 ||
        (date.month() == 3 && date.date() <= 30)
    )
}

export const highlightTerm = (term, value) => (
    <Highlighter
        highlightClassName="highlight-term"
        searchWords={[term]}
        autoEscape
        textToHighlight={value}
    />
)

export const constructLabel = (cpgName, defaultNickname) =>
    cpgName || defaultNickname

export const constructSubLabel = (
    fedexFreightAccount,
    fedexBillToAccount,
    intl,
    isFreightDirect
) => {
    if (fedexFreightAccount && !fedexBillToAccount) {
        if (!isFreightDirect) {
            return `${intl.formatMessage({
                id: "getRates.form__shipperAcct",
                defaultMessage: "Shipper Acct",
            })}: ${fedexFreightAccount}`
        } else {
            return `${intl.formatMessage({
                id: "getRates.form__FXFDShipperAcct",
                defaultMessage: "FXFD Shipper Acct",
            })}: ${fedexFreightAccount}`
        }
    } else if (fedexFreightAccount && fedexBillToAccount) {
        if (!isFreightDirect) {
            return `${intl.formatMessage({
                id: "getRates.form__shipperAcct",
                defaultMessage: "Shipper Acct",
            })}: ${fedexFreightAccount} | ${intl.formatMessage({
                id: "getRates.form__thirdPartyAcct",
                defaultMessage: "Third Party Acct",
            })}: ${fedexBillToAccount}`
        } else {
            return `${intl.formatMessage({
                id: "getRates.form__FXFDShipperAcct",
                defaultMessage: "FXFD Shipper Acct",
            })}: ${fedexFreightAccount} | ${intl.formatMessage({
                id: "getRates.form__FXFDThirdPartyAcct",
                defaultMessage: "FXFD Third Party Acct",
            })}: ${fedexBillToAccount}`
        }
    } else if (!fedexFreightAccount && fedexBillToAccount) {
        if (!isFreightDirect) {
            return `${intl.formatMessage({
                id: "getRates.form__thirdPartyAcct",
                defaultMessage: "Third Party Acct",
            })}: ${fedexBillToAccount}`
        } else {
            return `${intl.formatMessage({
                id: "getRates.form__FXFDThirdPartyAcct",
                defaultMessage: "FXFD Third Party Acct",
            })}: ${fedexBillToAccount}`
        }
    }
}

export const freightClassCheck = (
    formValues,
    multiCarrierSpacePaceWorkflow
) => {
    let newValues = { ...formValues }
    if (
        (newValues?.origin?.shippingAddress?.address?.country === "MX" &&
            newValues?.destination?.shippingAddress?.address?.country ===
                "MX") ||
        (multiCarrierSpacePaceWorkflow
            ? false
            : newValues?.selectedLocation?.usDimWeight === true)
    ) {
        newValues.handlingUnits.forEach(hu => {
            hu.items.forEach(item => {
                if (!item?.freightClass) {
                    //default to mexico, the freight class does not change the shipment value for intramexico.
                    item.freightClass = 50
                }
            })
        })
    }
    return newValues
}

import React from "react"
import { Button, DialogActions, Typography } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { FormattedMessage } from "react-intl"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
    paper: {
        width: "500px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 2,
        backgroundColor: theme.palette.background.paper,
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
}))

const WarningCommercialnvoice = ({
    isCAtoMX,
    isDialogOpen,
    cancelModal,
    proccedWithwarning,
}) => {
    const classes = useStyles()

    return (
        <Dialog
            open={isDialogOpen}
            classes={{
                paper: classes.paper,
            }}
        >
            <DialogTitle id="in-bond-dialog-title">
                <Grid container item direction="row">
                    <Grid item container xs={12} justifyContent="space-between">
                        <Grid item>
                            <Typography variant="subtitle1">
                                {isCAtoMX ? (
                                    <FormattedMessage
                                        id="getRates__canada__export_declaration__title"
                                        defaultMessage="If your shipment requires Canadian Export Declaration, please email your documents to fispaps@fedex.com."
                                    />
                                ) : (
                                    <FormattedMessage
                                        id="getRates__commercial__invoice__title"
                                        defaultMessage="To ensure smooth shipment processing, email the Commercial Invoice to offshore-aes@fedex.com promptly."
                                    />
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={10}></Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container justifyContent="center" direction="row">
                    <Grid item container xs={12}>
                        <Grid item container></Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    item
                    container
                    alignItems="flex-end"
                    className={classes.buttonContainer}
                    justifyContent="space-between"
                >
                    <Button
                        id="cancelWarningDialog"
                        onClick={cancelModal}
                        color="secondary"
                        variant="outlined"
                    >
                        <FormattedMessage
                            id="generalTerms__cancel"
                            defaultMessage="Cancel"
                        />
                    </Button>
                    <Button
                        id="continueWarningDialog"
                        onClick={proccedWithwarning}
                        color="primary"
                        variant="contained"
                    >
                        <FormattedMessage
                            id="generalTerms__continue"
                            defaultMessage="Continue"
                        />
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default WarningCommercialnvoice

import { clone } from "lodash"
import { SubmissionError } from "redux-form"
import { goFetch, goFetchV2 } from "../http"
import { changePath } from "./index"
import {
    noRateResults,
    rateRequestError,
    unsupportedCountryRateRequestError,
} from "../messages/error-constants"
import { invalidateItemList } from "./item"
import { filterRatesError, quoteLoad } from "./quote-request"
import moment from "moment"
import {
    updateUserCustomsBrokeragePreferences,
    updateUserDeclaredValueExpandedPreference,
    updateUserHazMatPreferences,
} from "./user"
import { requestContactAddresses } from "./address"
import { addShipment, populateTrackForm } from "./track"
import { cleanupObject, cleanupObjectDeep } from "../misc"
import {
    createCertificateOfOrigin,
    createAndAssociateDocuments,
} from "./book-shipment"
import {
    isMexicoOffshore,
    isUnsupportedCountryState,
} from "../components/util/internationalUtils"

const RELOAD_DOCUMENTS_DELAY = 5000

export const QUOTE_LOAD = "QUOTE_LOAD"
export const QUOTE_RESULT = "QUOTE_RESULT"
export const QUOTE_ADD_LIST_ITEM = "QUOTE_ADD_LIST_ITEM"

export const RATE_LOAD = "RATE_LOAD"
export const INVALID_ACTIVE_RATE = "INVALID_ACTIVE_RATE"

export function addQuoteListItem(quote) {
    return { type: QUOTE_ADD_LIST_ITEM, quote }
}

export function addActiveRate(quote) {
    return { type: RATE_LOAD, payload: quote }
}

export function invalidActiveRates() {
    return { type: INVALID_ACTIVE_RATE }
}

export function populateActiveRate(shipmentId) {
    return async (dispatch, getState) => {
        try {
            const response = await goFetchV2(
                `/quotes/${shipmentId}`,
                {
                    method: "GET",
                    credentials: "same-origin",
                },
                true
            )

            let adjustedRate = { ...response.data }

            adjustedRate.result.ratesError = filterRatesError(
                adjustedRate?.result?.rateQuotes,
                adjustedRate?.result?.ratesError
            )
            return dispatch(addActiveRate(adjustedRate))
        } catch (error) {
            throw error
        }
    }
}

const preparePayload = values => {
    const {
        selectedLocation,
        preferredCurrencyCode,
        preferredSystemOfMeasurement,
        pickupDate,
        userProvidedTotalLinearFeet,
        totalLinearFeet,
        capLoadTotalLinearFeet,
        volumeTotalLinearFeet,
        isOverLTLLimit,
        isInBondShipment,
        protectFromFreezing,
        origin,
        destination,
        handlingUnits,
        isQuickRate,
        mode,
        includeThirdParty,
        includeVolume,
        isFreightDirectReturns,
        isFreightDirect,
        associatedOrder,
        identifiers,
        paymentType,
        paymentAccount,
        secondSelectedLocation,
        isFedexFileEEI,
        itns,
    } = values

    let { pickupAccessorials, deliveryAccessorials } = values

    if (typeof pickupAccessorials === "string") {
        pickupAccessorials = pickupAccessorials.split(",")
    }
    if (typeof deliveryAccessorials === "string") {
        deliveryAccessorials = deliveryAccessorials.split(",")
    }

    return {
        includeThirdParty,
        includeVolume,
        locationId: selectedLocation?._id,
        cpg: selectedLocation?.cpgCode,
        secondCpg: secondSelectedLocation?.cpgCode,
        preferredCurrencyCode,
        preferredSystemOfMeasurement,
        pickupDate: moment(pickupDate).format("YYYY-MM-DD"),
        userProvidedTotalLinearFeet,
        totalLinearFeet,
        capLoadTotalLinearFeet,
        volumeTotalLinearFeet,
        isOverLTLLimit,
        isInBondShipment,
        protectFromFreezing,
        origin: {
            contact: origin?.contact,
            pickupContact: origin?.pickupContact,
            shippingAddress: origin?.shippingAddress,
            readyTime: origin?.readyTime,
            closeTime: origin?.closeTime,
        },
        destination: {
            contact: destination?.contact,
            note: destination?.note,
            shippingAddress: destination?.shippingAddress,
        },
        identifiers,
        mode,
        handlingUnits,
        pickupAccessorials:
            isFreightDirectReturns || isFreightDirect
                ? ["DOCKPU"]
                : pickupAccessorials,
        deliveryAccessorials:
            isFreightDirectReturns || isFreightDirect
                ? ["DOCKDEL"]
                : deliveryAccessorials,
        isQuickRate,
        isFreightDirectReturn: isFreightDirectReturns,
        isFreightDirect,
        associatedOrder,
        paymentType,
        paymentAccount,
        isFedexFileEEI: isFedexFileEEI,
        itns: itns,
    }
}

export function requestQuote(values, featureToBookShipment) {
    return async (dispatch, getState) => {
        const state = getState()
        const location = {
            originState: values.origin?.shippingAddress?.address?.state,
            originCountry: values.origin?.shippingAddress?.address?.country,
            destinationState:
                values.destination?.shippingAddress?.address?.state,
            destinationCountry:
                values.destination?.shippingAddress?.address?.country,
        }

        dispatch(quoteLoad())
        const isUpdate =
            state?.quotes?.active?.isLoaded && !values?.isReturnMode
        const shipmentId = state?.identifiers?.internalTrackingNumber
        const QRshipmentId =
            state?.form?.quickRate?.values?.identifiers?.internalTrackingNumber
        let url = isUpdate ? `/quotes/${shipmentId}` : "/quotes"
        if (featureToBookShipment === "quickRate" && !isUpdate) {
            url = url.concat(
                `/${QRshipmentId}?keepRates=true&convertFromQuickRate=true`
            )
        }

        let adjustedRate
        try {
            const response = await goFetchV2(
                url,
                {
                    method: isUpdate ? "PUT" : "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: preparePayload(values),
                },
                true
            )
            adjustedRate = { ...response.data }

            adjustedRate.result.ratesError = filterRatesError(
                adjustedRate?.result?.rateQuotes,
                adjustedRate?.result?.ratesError
            )

            if (values.isQuickRate) {
                dispatch(loadQuickQuoteResult(adjustedRate))
            } else {
                dispatch(loadBSQuoteResult(adjustedRate))
            }
        } catch (err) {
            if (
                isUnsupportedCountryState(
                    location.originCountry,
                    location.originState,
                    location.destinationCountry,
                    location.destinationState
                ) ||
                isMexicoOffshore(
                    location.originCountry,
                    location.originState,
                    location.destinationCountry,
                    location.destinationState
                )
            ) {
                throw unsupportedCountryRateRequestError(location)
            } else {
                throw rateRequestError
            }
        }
        if (!adjustedRate?.result?.rateQuotes)
            throw new SubmissionError({ _error: noRateResults })
        dispatch(invalidateItemList())
    }
}

const contactPayload = ({ name, phone, email }) =>
    cleanupObject({ name, phone, email })

const addressPayload = ({
    postalCode,
    street1,
    street2,
    city,
    state,
    country,
} = {}) => cleanupObject({ postalCode, street1, street2, city, state, country })

export const timePayload = (time, date) => {
    if (!time) return moment(date).format("HH:mm")
    return moment(time, "HH:mm A").format("HH:mm")
}

const transformAlerts = (alerts, selectedRecipients) => ({
    preferences: alerts?.user,
    share:
        selectedRecipients?.map(recipient => {
            const preferences = alerts[recipient.label]
            const preferencesWithoutLanguage = clone(preferences)
            delete preferencesWithoutLanguage?.language
            return {
                name: recipient.label,
                email: recipient.value.value,
                language: preferences?.language,
                preferences: preferencesWithoutLanguage,
            }
        }) ?? [],
})
function endpointPayload(
    endpoint,
    date,
    appointmentNeeded = false,
    isFreightDirectReturns,
    selectedRate
) {
    let adjustedReadyTime = endpoint?.readyTime
    let adjustedCloseTime = endpoint?.closeTime

    if (
        isFreightDirectReturns &&
        (selectedRate?.serviceLevel?.code === "BASIC_APPT_PICKUP" ||
            selectedRate?.serviceLevel?.code === "STANDARD_PICKUP")
    ) {
        if (endpoint?.pickUpWindow) {
            let pickUpWindowArray = endpoint?.pickUpWindow
                .split("-")
                .map(time => time.trim())
            adjustedReadyTime = pickUpWindowArray[0]
            adjustedCloseTime = pickUpWindowArray[1]
        }
    }
    return cleanupObject({
        _id: endpoint?.shippingAddress._id || endpoint?._id,
        contactId: endpoint?.contact?._id,
        name: endpoint?.shippingAddress?.name || endpoint?.name,
        address: addressPayload(
            endpoint?.shippingAddress?.address || endpoint?.address
        ),
        contact: endpoint?.contact ? contactPayload(endpoint?.contact) : null,
        appointment:
            appointmentNeeded &&
            (endpoint?.pickupContact ||
                endpoint?.pickupInformation?.pickupContact)
                ? contactPayload(
                      endpoint?.pickupContact ||
                          endpoint?.pickupInformation?.pickupContact
                  )
                : null,
        note: endpoint?.note,
        accessorials: endpoint?.accessorials,
        date: moment.utc(date).format("YYYY-MM-DD"),
        startTime: timePayload(adjustedReadyTime, date),
        endTime: timePayload(adjustedCloseTime, date),
        isSaveAddress: !!(endpoint?.isSaveAddress || endpoint?._id),
        taxIdentification: endpoint?.taxIdentification,
    })
}

const cleanupBroker = (broker = {}) => {
    const { selectBroker, updatedByUserId, updatedAt, ...rest } = broker
    let cleanedBroker = cleanupObjectDeep(rest)
    if (
        !cleanedBroker?.address?.postalCode &&
        !cleanedBroker?.address?.postal_code
    ) {
        cleanedBroker.address = undefined
    }
    return cleanedBroker
}

function createShipmentPayload(values) {
    return cleanupObject({
        origin: endpointPayload(
            values?.origin,
            values?.pickupDate,
            !values?.pickupLater,
            values?.isFreightDirectReturns,
            values?.selectedRate
        ),
        destination: endpointPayload(
            values?.destination,
            values?.selectedRate?.deliveryDateTime
        ),
        carrier: values?.selectedRate?.carrierCode,
        cf7512: values?.cf7512,
        isPickupLater: values?.pickupLater ?? false,
        ...values?.identifiers,
        handlingUnits: values?.handlingUnits,
        alternateQuoteIndex: values?.selectedRate?.alternateQuote,
        selectedRate: values?.selectedRate?._id,
        shipMode: values?.selectedRate?.mode,
        paymentType: values?.selectedRate?.paymentType,
        paymentAccount: values?.paymentAccount,
        direction: values?.selectedRate?.direction,
        appointment: values?.appointment && contactPayload(values?.appointment),
        emergency: values?.hazmatEmergency,
        exportCustomsBrokerProfile: values?.exportCustomsBrokerProfile
            ? cleanupBroker(values?.exportCustomsBrokerProfile)
            : null,
        importCustomsBrokerProfile: values?.importCustomsBrokerProfile
            ? cleanupBroker(values?.importCustomsBrokerProfile)
            : null,
        CAMXimportCustomsBrokerProfile: values?.CAMXimportCustomsBrokerProfile
            ? cleanupBroker(values?.CAMXimportCustomsBrokerProfile)
            : null,
        alerts: transformAlerts(values?.alerts, values?.selectedRecipients),
        commercialInvoice: values?.requiresCommercialInvoice
            ? values?.commercialInvoice
            : null,
        signatureImage: values?.signatureImage ?? null,
        itemComments: values?.itemComments,
        purposeOfShipment: values?.purposeOfShipment,
        declaredValueOptions: values?.declaredValueOptions,
    })
}

const buildCreateShipmentPayload = (order, isPickupLater) => {
    const payload = cleanupObject({
        origin: endpointPayload(
            order?.search?.origin || order?.origin,
            order?.pickupDate,
            isPickupLater,
            order?.isFreightDirectReturns,
            order?.selectedRate
        ),
        destination: endpointPayload(
            order?.search?.destination || order?.destination,
            order?.selectedRate?.deliveryDateTime
        ),
        carrier: order?.selectedRate?.carrierCode,
        cf7512: order?.cf7512,
        // To test LS-3343, isPickupLater must be set to true
        isPickupLater: isPickupLater,
        // isPickupLater: order?.pickupLater ?? false,
        ...order?.identifiers,
        handlingUnits: order?.handlingUnits,
        alternateQuoteIndex: order?.selectedRate?.alternateQuote,
        selectedRate: order?.selectedRate?._id,
        shipMode: order?.selectedRate?.mode,
        paymentType: order?.selectedRate?.paymentType,
        paymentAccount: order?.paymentAccount,
        direction: order?.selectedRate?.direction,
        appointment: order?.appointment
            ? contactPayload(order?.appointment)
            : null,
        emergency: order?.hazmatEmergency,
        exportCustomsBrokerProfile: order?.exportCustomsBrokerProfile
            ? cleanupBroker(order?.exportCustomsBrokerProfile)
            : null,
        importCustomsBrokerProfile: order?.importCustomsBrokerProfile
            ? cleanupBroker(order?.importCustomsBrokerProfile)
            : null,
        CAMXimportCustomsBrokerProfile: order?.CAMXimportCustomsBrokerProfile
            ? cleanupBroker(order?.CAMXimportCustomsBrokerProfile)
            : null,
        alerts: transformAlerts(order?.alerts, order?.selectedRecipients),
        commercialInvoice: order?.requiresCommercialInvoice
            ? order?.commercialInvoice
            : null,
        signatureImage: order?.signatureImage ?? null,
        itemComments: order?.itemComments,
        purposeOfShipment: order?.purposeOfShipment,
        declaredValueOptions: order?.declaredValueOptions,
    })
    return payload
}

function internalCreateShipment(values) {
    return async dispatch => {
        if (!values?.selectedRate) throw new Error("No rate selected")
        const { data: shipment } = await goFetch(
            "/shipments",
            {
                method: "POST",
                credentials: "same-origin",
                headers: {
                    "cache-control": "no-cache",
                },
                data: createShipmentPayload(values),
            },
            true
        )
        dispatch(requestContactAddresses())
        dispatch(addShipment(shipment))
        return shipment
    }
}

export const internalBulkCreateShipments = async (payload, isPickupLater) => {
    try {
        if (!payload?.selectedRate) throw new Error("Trouble selecting rate.")
        const { data: shipment } = await goFetch(
            "/shipments",
            {
                method: "POST",
                credentials: "same-origin",
                headers: {
                    "cache-control": "no-cache",
                },
                data: buildCreateShipmentPayload(payload, isPickupLater),
            },
            true
        )
        return shipment
    } catch (error) {
        const errorMessage =
            JSON.parse(JSON.stringify(error, null, 2)).message ||
            JSON.parse(JSON.stringify(error, null, 2)).response?.message ||
            error
        throw { message: errorMessage }
    }
}

export const internalBulkCreatePickupShipments = async payload => {
    try {
        if (!payload || payload?.internalTrackingNumbers?.length == 0)
            throw new Error("Trouble creating pick up.")
        const { data } = await goFetch(
            "/shipments/bulk/pickup",
            {
                method: "POST",
                credentials: "same-origin",
                headers: {
                    "cache-control": "no-cache",
                },
                data: payload,
            },
            true
        )
        return data
    } catch (error) {
        const errorMessage =
            JSON.parse(JSON.stringify(error, null, 2)).message ||
            JSON.parse(JSON.stringify(error, null, 2)).response?.message ||
            error
        throw { message: errorMessage }
    }
}

export async function bulkCreateShipments(payload, isPickupLater = true) {
    try {
        console.log("attemping internalBulk createShipments")
        const shipmentResult = await internalBulkCreateShipments(
            payload,
            isPickupLater
        )
        return shipmentResult
    } catch (error) {
        throw error
    }
}

export function createShipment(values) {
    return async dispatch => {
        try {
            await Promise.all([
                dispatch(updateUserHazMatPreferences(values?.hazmatEmergency)),
                dispatch(
                    updateUserCustomsBrokeragePreferences(
                        values?.exportCustomsBrokerageInfo,
                        values?.importCustomsBrokerageInfo
                    )
                ),
                dispatch(
                    updateUserDeclaredValueExpandedPreference(
                        values?.declaredValueExpanded
                    )
                ),
            ])
            const shipmentResult = await dispatch(
                internalCreateShipment(values)
            )

            dispatch(invalidateItemList())
            if (shipmentResult?.response?.message?.errorMessage) {
                throw new Error(shipmentResult?.response?.message?.errors?.[0])
            }
            dispatch(postShipmentActions(shipmentResult, values))
            return shipmentResult
        } catch (error) {
            let errorMessage =
                error?.response?.message?.errors?.[0]?.diagnostic ??
                error?.response?.message?.errors?.[0]?.message ??
                error?.response?.diagnostic ??
                error?.response?.message ??
                "Error"
            throw errorMessage
        }
    }
}

function postShipmentActions(shipment, values) {
    return dispatch => {
        const shipmentId = shipment?.identifiers?.internalTrackingNumber
        if (shipmentId && values?.requiresCertificateOfOrigin) {
            dispatch(
                createCertificateOfOrigin(
                    shipmentId,
                    values?.certificateOfOrigin
                )
            )
        }

        if (values?.attachedDocuments?.length) {
            setTimeout(
                async () => dispatch(populateTrackForm(shipmentId)),
                RELOAD_DOCUMENTS_DELAY
            )
        }

        if (
            values?.selectedCustomsDocuments?.length ||
            (shipmentId && values?.requiresUSMCA)
        ) {
            let selectedDocuments = values?.selectedCustomsDocuments ?? []
            if (shipmentId && values?.requiresUSMCA) {
                const usmcaDocument = {
                    documentCategory: "USMCA",
                    shipmentOnly: true,
                    customFilename: "USMCA",
                    locationId: values?.selectedLocation?._id,
                    internalTrackingNumber: shipmentId,
                    ...values?.usmca,
                }
                selectedDocuments = [usmcaDocument, ...selectedDocuments]
            }
            dispatch(
                createAndAssociateDocuments(
                    values?.selectedLocation?._id,
                    selectedDocuments,
                    shipmentId
                )
            )
        }
        dispatch(invalidActiveRates())
    }
}

export function loadBSQuoteResult(quote) {
    return dispatch => {
        dispatch(addActiveRate(quote))
        const basePath = "/book"
        const shipmentId = quote?.identifiers?.internalTrackingNumber
        const path = shipmentId ? `${basePath}/${shipmentId}` : `${basePath}`
        dispatch(changePath(path))
    }
}

export function loadQuickQuoteResult(quote) {
    return dispatch => {
        dispatch(addActiveRate(quote))
        const basePath = "/quickRate"
        const shipmentId = quote?.identifiers?.internalTrackingNumber
        const path = shipmentId ? `${basePath}/${shipmentId}` : `${basePath}`
        dispatch(changePath(path))
    }
}

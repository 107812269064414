import createSelector from "re-reselect"
import { isFedExCarrier } from "../../../misc"
import moment from "moment"
import { weightUnitSingularCapital } from "../../util/units"

export const createHandlingUnitInitialValues = (
    handlingUnits = [],
    unitOfMass
) =>
    handlingUnits.flatMap(hu =>
        hu.items.map(item => ({
            description: item?.description,
            numberOfPackages: hu?.count,
            netWeight: {
                amount: (item?.weight ?? 0) * (item?.unit ?? item?.pieces ?? 1),
                unitOfMass,
            },
            grossWeight: {
                amount: (item?.weight ?? 0) * (item?.unit ?? item?.pieces ?? 1),
                unitOfMass,
            },
            countryOfManufacture: item?.countryOfManufacture,
        }))
    )

export const generateInitialValues = createSelector(
    (state, props) =>
        state?.shipment?.activeShipment?.identifiers?.internalTrackingNumber ===
        props?.shipmentId
            ? state?.shipment?.activeShipment
            : state?.shipment?.list[props.shipmentId],
    (state, props) => props.previousDocument,
    (shipment, previousDocument) => {
        if (previousDocument?.documentFields) {
            return previousDocument.documentFields
        }

        if (!shipment) {
            return null
        }

        const preferredSystemOfMeasurement =
            shipment?.query?.preferredSystemOfMeasurement ?? "IMPERIAL"

        const certificateOfOriginLineItems = createHandlingUnitInitialValues(
            shipment?.handlingUnits,
            weightUnitSingularCapital(preferredSystemOfMeasurement)
        )

        return {
            internalTrackingNumber:
                shipment?.identifiers?.internalTrackingNumber,
            trackingNumber: shipment?.identifiers?.proNumber,
            carrier: isFedExCarrier(shipment?.shipment?.carrier)
                ? "FXFR"
                : shipment?.shipment?.carrier,
            shipDate: moment(shipment?.shipment?.origin?.date),
            billOfLading: shipment?.identifiers?.bolNumber,
            exporter: {
                contactName: shipment?.shipment?.origin?.contact?.name,
                companyName: shipment?.shipment?.origin?.name,
                address: { ...shipment?.shipment?.origin?.address },
            },
            consignee: {
                companyName: shipment?.shipment?.destination?.name,
                address: { ...shipment?.shipment?.destination?.address },
            },
            datedAt: shipment?.shipment?.origin?.address?.city,
            datedOn: moment(),
            certificateOfOriginLineItems,
            preferredSystemOfMeasurement,
        }
    }
)((state, props) => props.shipmentId)

import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { getTranslationObject } from "../../translations/getTranslations"
import { Edit } from "@material-ui/icons"
import EditTranslations from "./EditTranslations"

const fedexOrange = "#FF6600"

const Internationalization = () => {
    const [translations, setTranslations] = useState([])
    const [editKey, setEditKey] = useState("")
    const [editEnglish, setEditEnglish] = useState("")
    const [editSpanish, setEditSpanish] = useState("")
    const [editFrench, setEditFrench] = useState("")

    useEffect(() => {
        const getTranslations = () => {
            return getTranslationObject()
        }
        const data = getTranslations()
        setTranslations(data)
    }, [])

    const editText = obj => {
        setTranslations([])
        setEditKey(obj?.key)
        setEditEnglish(obj?.en_us)
        setEditSpanish(obj?.es_mx)
        setEditFrench(obj?.fr_ca)
    }

    const cancelEdit = obj => {
        setEditKey("")
        const getTranslations = () => {
            return getTranslationObject()
        }
        const data = getTranslations()
        setTranslations(data)
    }

    return (
        <Box
            sx={{ display: "flex", justifyContent: "center", padding: "50px" }}
        >
            {editKey ? (
                <EditTranslations
                    cancelEdit={cancelEdit}
                    setEditKey={setEditKey}
                    editKey={editKey}
                    editFrench={editFrench}
                    setEditFrench={setEditFrench}
                    editEnglish={editEnglish}
                    setEditEnglish={setEditEnglish}
                    editSpanish={editSpanish}
                    setEditSpanish={setEditSpanish}
                />
            ) : null}
            {translations.length > 1 ? (
                <Box component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography style={{ fontWeight: "bold" }}>
                                        Key
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography style={{ fontWeight: "bold" }}>
                                        English
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography style={{ fontWeight: "bold" }}>
                                        Spanish
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography style={{ fontWeight: "bold" }}>
                                        French
                                    </Typography>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {translations.map(obj => {
                                return (
                                    <TableRow key={obj.key}>
                                        <TableCell>
                                            <Typography variant="body1">
                                                {obj.key}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                backgroundColor:
                                                    obj.en_us === ""
                                                        ? fedexOrange
                                                        : "white",
                                            }}
                                        >
                                            <Typography>{obj.en_us}</Typography>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                backgroundColor:
                                                    obj.es_mx === ""
                                                        ? fedexOrange
                                                        : "white",
                                            }}
                                        >
                                            <Typography>{obj.es_mx}</Typography>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                backgroundColor:
                                                    obj.fr_ca === ""
                                                        ? fedexOrange
                                                        : "white",
                                            }}
                                        >
                                            <Typography>{obj.fr_ca}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => {
                                                    editText(obj)
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Box>
            ) : null}
        </Box>
    )
}

export default Internationalization

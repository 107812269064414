import React, { useEffect, useState } from "react"
import {
    Button,
    Grid,
    Paper,
    Typography,
    withStyles,
    Modal,
} from "@material-ui/core"
import { FormattedMessage, injectIntl } from "react-intl"
import { connect } from "react-redux"
import { constructLabel, constructSubLabel } from "../util"
import { trackGAEvent } from "../../actions/user"
import CreateManifestModal from "./createManifestModal"
import ShipmentsTable from "./shipmentsTable"
import { reset } from "redux-form"
import {
    clearCurrentManifest,
    createManifest,
    getShipmentsForManifest,
} from "../../actions/trailerManifestActions"
import { useAttachmentsContext } from "../../context/providers/attachmentsProvider"
import GlobalSpinner from "../common/GlobalSpinner"
import { attachmentOptions } from "../../misc"
import { useSnackbarContext } from "../../context/providers/snackbarProvider"

const GACategory = "Trailer Manifest"

const styles = theme => ({
    mainContainer: {
        padding: "3% 5% 5% 5%",
    },
    header: {
        paddingBottom: "15px",
        alignItems: "flex-end",
        flexDirection: "row",
        flexWrap: "nowrap",
    },
    toggleFilter: {
        height: "35px",
        minWidth: "327px",
    },
    selectedFilter: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: "white !important",
        height: "inherit",
        pointerEvents: "none",
    },
    baseFilter: {
        color: "black",
        height: "inherit",
    },
    input__field: {
        cursor: "pointer",
    },
    side_button: {
        marginLeft: "10px",
    },
})

const TrailerManifestPage = ({
    classes,
    trackGA,
    formattedLocations,
    tableShipments,
    locations,
    selectedLocation,
    getCreatedShipments,
    shipmentCount,
    createTrailerManifest,
    currentManifestFile,
    resetForm,
    shipmentsLoading,
    clearManifest,
}) => {
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [creating, setCreating] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [checkedShipments, setCheckedShipments] = useState([])
    const [formValues, setFormValues] = useState({})

    const {
        openDialog,
        setIsFromTrailerManifestPage,
        manageDialogOpen,
    } = useAttachmentsContext()
    const { openSnackbar } = useSnackbarContext()

    useEffect(() => {
        setIsFromTrailerManifestPage(true)
        trackGA(GACategory, "Page Mount")
        return () => {
            setIsFromTrailerManifestPage(false)
            clearManifest()
        }
    }, [])

    useEffect(() => {
        if (!creating) {
            getCreatedShipments({ rowsPerPage, pageNumber: 1 })
        }
    }, [creating])

    useEffect(() => {
        if (currentManifestFile) {
            setCreating(false)
            resetForm()
        }
    }, [currentManifestFile])

    const onContinue = values => {
        const originZipcode = findLocation(values.billToLocation.value)
            ?.shipperLoadCount?.address?.postalCode
        setFormValues({
            ...values,
            originZipcode,
        }),
            setCreateModalOpen(false)
        setCreating(true)
        setPageNumber(1)
        trackGA(GACategory, "Create Modal - Continue")
        getCreatedShipments({
            pageSize: rowsPerPage,
            pageNumber: 1,
            originZipcode,
        })
    }

    const findLocation = cpg =>
        locations.find(location => location.cpgCode === cpg)

    const onChangePage = (event, page) => {
        trackGA(GACategory, "Change Page", page)
        if (!page) {
            setPageNumber(1)
            getCreatedShipments({
                pageSize: rowsPerPage,
                pageNumber: 1,
                originZipcode: creating ? formValues.originZipcode : null,
            })
            return
        }
        setPageNumber(page + 1)
        getCreatedShipments({
            pageSize: rowsPerPage,
            pageNumber: page + 1,
            originZipcode: creating ? formValues.originZipcode : null,
        })
    }

    const onChangeRowsPerPage = event => {
        trackGA(GACategory, "Change Rows per Page", event?.target?.value)
        setRowsPerPage(parseInt(event?.target?.value, 10))
        setPageNumber(1)

        getCreatedShipments({
            pageSize: parseInt(event?.target?.value, 10),
            pageNumber: 1,
            originZipcode: creating ? formValues.originZipcode : null,
        })
    }

    const trailerManifestFile = {
        attachmentFileName: null,
        attachmentFileType: "trailerManifest",
        isSystem: true,
        isTrackingDocument: false,
        isTradingDocument: false,
        label: (
            <FormattedMessage
                id="trailerManifest.general"
                defaultMessage="Trailer Manifest"
            />
        ),
        supportedFormats: ["PDF", " PNG"],
        originalFileName: `Manifest${Date.now()}`,
        mimeType: "application/pdf",
    }

    const categoryLabel = attachmentOptions.find(
        entry => trailerManifestFile.attachmentFileType === entry.value
    )

    return (
        <div>
            <Grid item container className={classes.mainContainer}>
                {shipmentsLoading && !manageDialogOpen ? (
                    <GlobalSpinner />
                ) : null}

                <Modal
                    open={createModalOpen}
                    onClose={() => setCreateModalOpen(false)}
                >
                    <CreateManifestModal
                        formattedLocations={formattedLocations}
                        setCreateModalOpen={setCreateModalOpen}
                        onContinue={onContinue}
                        gaCategory={GACategory}
                    />
                </Modal>
                <Grid
                    item
                    container
                    pb={2}
                    justify="space-between"
                    className={classes.header}
                >
                    <Typography variant="h4">
                        <FormattedMessage
                            id="trailerManifest.general"
                            defaultMessage="Trailer Manifest"
                        />
                    </Typography>
                    {creating ? (
                        <div>
                            <Button
                                className={classes.side_button}
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    trackGA(
                                        GACategory,
                                        "Create Manifest Cancel Click"
                                    )
                                    setCreating(false)
                                    resetForm()
                                }}
                            >
                                <FormattedMessage
                                    id="generalTerms__cancel"
                                    defaultMessage="Cancel"
                                />
                            </Button>
                            <Button
                                className={classes.side_button}
                                variant="contained"
                                color="primary"
                                disabled={checkedShipments.length <= 0}
                                onClick={() => {
                                    createTrailerManifest(
                                        {
                                            cpg:
                                                formValues.billToLocation.value,
                                            shipmentIds: checkedShipments,
                                            trailerId: formValues.trailerId,
                                        },
                                        openSnackbar
                                    )
                                    setCreating(false)
                                    trackGA(
                                        GACategory,
                                        "Finalize Manifest Button Click",
                                        selectedLocation
                                    )
                                    openDialog(
                                        checkedShipments[0],
                                        "manage",
                                        trailerManifestFile,
                                        categoryLabel,
                                        undefined,
                                        GACategory
                                    )
                                }}
                            >
                                <FormattedMessage
                                    id="trailerManifest.finalizeManifest"
                                    defaultMessage="Finalize Manifest"
                                />
                            </Button>
                        </div>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                trackGA(GACategory, "Create Button Click")
                                setCreateModalOpen(true)
                            }}
                        >
                            <FormattedMessage
                                id="trailerManifest.create"
                                defaultMessage="Create"
                            />
                        </Button>
                    )}
                </Grid>
                <Grid
                    item
                    container
                    justify="space-between"
                    className={classes.header}
                ></Grid>
                <Grid
                    item
                    container
                    component={Paper}
                    style={{ minWidth: "1064px" }}
                >
                    <ShipmentsTable
                        shipments={tableShipments}
                        userLocations={locations}
                        creating={creating}
                        onChangePage={onChangePage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        pageNumber={pageNumber}
                        rowsPerPage={rowsPerPage}
                        shipmentCount={shipmentCount}
                        checkedShipments={checkedShipments}
                        setCheckedShipments={setCheckedShipments}
                        gaCategory={GACategory}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    const locations = state?.user?.profile?.locations ?? []
    const formattedLocations = locations
        .filter(
            item =>
                item?.users[0]?.permissions?.createRates?.value &&
                !item?.users[0]?.permissions?.suspended?.value &&
                !item?.isPending &&
                item?.locationType !== "FEDEX_FREIGHT_BOX"
        )
        .filter(item => item.shipperLoadCount?.isEnableTrailerManifest === true)
        .map(item => {
            const { city, state: addressState } =
                item?.shippingAddress?.address ?? {}
            const defaultNickname = `${item?.shippingAddress?.name} - ${city}, ${addressState}`
            return {
                label: constructLabel(item?.cpgName, defaultNickname),
                subLabel: constructSubLabel(
                    item?.fedexFreightAccount,
                    item?.fedexBillToAccount,
                    props?.intl,
                    item?.locationType === "FEDEX_DIRECT" ||
                        item?.locationType === "FEDEX_DIRECT_RETURNS"
                ),
                value: item?.cpgCode,
                fedexBillToAccount: item?.fedexBillToAccount,
                fedexFreightAccount: item?.fedexFreightAccount,
                isDefault: item?.isDefault,
                isFreightDirect: item?.locationType === "FEDEX_DIRECT",
                isFreightDirectReturns:
                    item?.locationType === "FEDEX_DIRECT_RETURNS",
                isFreightBox: item?.locationType === "FEDEX_FREIGHT_BOX",
            }
        })

    const tableShipments = state?.trailerManifest?.tableShipments?.list
    const shipmentCount = state?.trailerManifest?.tableShipments?.totalCount
    const currentManifestFile = state?.trailerManifest?.activeManifestFile
    const shipmentsLoading = state?.trailerManifest?.shipmentsLoading
    const trailerManifestLoading =
        state?.trailerManifest?.trailerManifestLoading
    return {
        locations,
        formattedLocations,
        tableShipments,
        shipmentCount,
        currentManifestFile,
        shipmentsLoading,
        trailerManifestLoading,
    }
}

const mapDispatchToProps = dispatch => ({
    trackGA: (category, action, label) =>
        dispatch(trackGAEvent(category, action, label)),
    getCreatedShipments: params => dispatch(getShipmentsForManifest(params)),
    createTrailerManifest: (parameters, openSnackbar) =>
        dispatch(createManifest(parameters, openSnackbar)),
    resetForm: () => dispatch(reset("trailerManifest")),
    clearManifest: () => dispatch(clearCurrentManifest()),
})

export default withStyles(styles)(
    injectIntl(
        connect(mapStateToProps, mapDispatchToProps)(TrailerManifestPage)
    )
)

import React, { useState } from "react"
import {
    IconButton,
    Paper,
    Tab,
    Tabs,
    Typography,
    withStyles,
} from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import AddAttachedSignature from "./AddAttachedSignature"
import CloseIcon from "@material-ui/icons/Close"

const styles = theme => ({
    root: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        top: "50%",
        left: "50%",
        width: "900px",
        maxHeight: "700px",
        overflowY: "scroll",
        flexGrow: 1,
    },
    paper: {
        flexGrow: 1,
        display: "none",
    },
    info__container: {
        paddingBottom: "8px",
    },
})

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <React.Fragment>{children}</React.Fragment>}
        </div>
    )
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    }
}

const AddSignatureModal = props => {
    const { classes, handleAddClose, changeField, title } = props
    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Grid className={classes.root}>
            <Grid item container xs={12} justify="space-between">
                <Typography variant="h5">{title}</Typography>
                <IconButton onClick={() => handleAddClose()}>
                    <CloseIcon />
                </IconButton>
            </Grid>
            <Grid
                item
                container
                xs={12}
                direction="column"
                className={classes.info__container}
            >
                <Paper className={classes.paper}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="simple  tabs example"
                        centered
                        className={classes.tabs}
                    >
                        <Tab
                            disabled
                            label={
                                <FormattedMessage
                                    id="locations.signatureManagment__upload"
                                    defaultMessage="Upload"
                                />
                            }
                            {...a11yProps(0)}
                        />
                    </Tabs>
                </Paper>

                <TabPanel value={value} index={0}>
                    <AddAttachedSignature
                        mode="upload"
                        handleAddClose={handleAddClose}
                        changeField={changeField}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <AddAttachedSignature
                        mode="type"
                        handleAddClose={handleAddClose}
                    />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <AddAttachedSignature
                        mode="draw"
                        handleAddClose={handleAddClose}
                    />
                </TabPanel>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(AddSignatureModal)

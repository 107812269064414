import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import moment from "moment"
import { get } from "lodash"
import { connect } from "react-redux"
import { getAccessorialText } from "../../actions/accessorial"
import { formSelector } from "./selectors"
import { FormattedMessage } from "react-intl"

function getShipDate(pickupDate) {
    return [
        <FormattedMessage
            id="generalTerms__pickupDate"
            defaultMessage="Pickup Date"
        />,
        ": ",
        moment(pickupDate).format("L"),
    ]
}

const QuoteDetailsContainer = ({
    commodityAccessorials,
    mode,
    classes,
    pickupDate,
    originCity,
    originState,
    destinationCity,
    destinationState,
    deliveryAccessorials,
    pickupAccessorials,
    deliveryAccessorialsList,
    pickupAccessorialsList,
}) => (
    <Grid
        item
        container
        direction="column"
        className={classes.quote__details}
        justify="center"
        alignItems="center"
        xs={12}
        wrap={mode === "row" && "nowrap"}
    >
        <Grid item container alignItems="center">
            <Typography variant="caption">
                {getShipDate(pickupDate)}
                {originCity && (
                    <Typography variant="caption">
                        {" "}
                        <FormattedMessage
                            id="generalTerms__from"
                            defaultMessage="From"
                        />
                        : {originCity}, {originState}{" "}
                    </Typography>
                )}
                {destinationCity && (
                    <Typography variant="captin">
                        {" "}
                        <FormattedMessage
                            id="generalTerms__to"
                            defaultMessage="To"
                        />
                        : {destinationCity}, {destinationState}{" "}
                    </Typography>
                )}
            </Typography>
        </Grid>
        {(pickupAccessorials?.length > 0 ||
            deliveryAccessorials?.length > 0) && (
            <Grid item container alignItems="center" justify="center">
                {pickupAccessorials?.length > 0 &&
                    pickupAccessorialsList?.length > 0 && (
                        <Grid item container>
                            <Typography variant="caption">
                                {pickupAccessorials.length > 0 && (
                                    <Typography variant="caption">
                                        {pickupAccessorials.map(
                                            (accessorial, index) => (
                                                <Typography
                                                    variant="caption"
                                                    key={index}
                                                >
                                                    {getAccessorialText(
                                                        accessorial,
                                                        pickupAccessorialsList
                                                    )}
                                                </Typography>
                                            )
                                        )}
                                    </Typography>
                                )}
                            </Typography>
                        </Grid>
                    )}
                {deliveryAccessorials?.length > 0 &&
                    deliveryAccessorialsList?.length > 0 && (
                        <Grid item container>
                            <Typography variant="caption">
                                {deliveryAccessorials?.length > 0 && (
                                    <Typography variant="caption">
                                        {deliveryAccessorials.map(
                                            (accessorial, index) => (
                                                <Typography
                                                    variant="caption"
                                                    key={index}
                                                >
                                                    {getAccessorialText(
                                                        accessorial,
                                                        deliveryAccessorialsList
                                                    )}
                                                </Typography>
                                            )
                                        )}
                                    </Typography>
                                )}
                            </Typography>
                        </Grid>
                    )}
                {commodityAccessorials &&
                    commodityAccessorials.some(acc => acc.value === "PFZ") && (
                        <Typography variant="caption">
                            <FormattedMessage
                                id="accessorials.protectFromFreezing"
                                defaultMessage="Protect From Freezing"
                            />
                        </Typography>
                    )}
            </Grid>
        )}
    </Grid>
)

const mapStateToProps = (state, props) => ({
    ...formSelector(state, props),
    pickupAccessorialsList: get(state, "accessorials.list.pickup", []).map(
        entry => ({
            value: entry.value,
            label: entry.label[state.user.profile.preferences.language],
        })
    ),
    deliveryAccessorialsList: get(state, "accessorials.list.delivery", []).map(
        entry => ({
            value: entry.value,
            label: entry.label[state.user.profile.preferences.language],
        })
    ),
})

export const QuoteDetails = connect(mapStateToProps)(QuoteDetailsContainer)

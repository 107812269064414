import React, { Fragment, useState } from "react"
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    Grid,
    IconButton,
} from "@material-ui/core"
import { FormattedMessage, useIntl } from "react-intl"
import {
    change,
    Field,
    FieldArray,
    FormSection,
    formValues,
    reduxForm,
    resetSection,
} from "redux-form"
import { RemoveCircle } from "@material-ui/icons"
import ContactDetails, { contactDetailsFormMessages } from "../ContactDetails"
import FormField from "../../form/form-field"
import FormDatePicker from "../../form/form-datepicker"
import ManageSignature from "../ManageSignature"
import { connect, useDispatch } from "react-redux"
import FormSwitch from "../../form/form-switch"
import formName from "../../util/form-name"
import FormSelect from "../../form/form-select"
import {
    countriesOfOrigin,
    provideLocaleSortedOptionsList,
} from "../../../misc"
import DocumentActions from "../DocumentActions"
import { usmcaFormGenerateInitialValues } from "./selectors"
import { ShipmentOnlyCheck } from "../checks/ShipmentOnlyCheck"
import { documentFieldValidation, usmcaFormValidation } from "./validation"
import { mergeValidations } from "../../../actions/validation"
import { documentFormMessages } from "../upload/UploadSuccessDialog"
import { usmcaFormMessages } from "./messages"
import { useCustomsProfileContext } from "../../../context/providers/CustomsProfileProvider"
import { FilenameCollisionCheckStep } from "../checks/FilenameCollisionCheckStep"
import { useGAContext } from "../../../context/providers/GoogleAnalyticsProvider"

export const countryOfOriginOptions = Object.freeze(
    countriesOfOrigin.filter(x => ["US", "CA", "MX"].includes(x.value))
)

export const originCriterionOptions = Object.freeze([
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
])

export const certificationIndicatorOptions = Object.freeze([
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
])

export const methodOfQualificationOptions = Object.freeze([
    {
        label: (
            <FormattedMessage
                {...usmcaFormMessages.methodOfQualificationNetCost}
            />
        ),
        value: "NC",
    },
    {
        label: (
            <FormattedMessage
                {...usmcaFormMessages.methodOfQualificationTransactionValue}
            />
        ),
        value: "TV",
    },
    {
        label: (
            <FormattedMessage
                {...usmcaFormMessages.methodOfQualificationTariffShift}
            />
        ),
        value: "TS",
    },
])

export const methodOfQualificationNotApplicableList = Object.freeze(["A", "C"])

export const methodOfQualificationNotApplicableOptions = Object.freeze([
    {
        label: (
            <FormattedMessage
                {...usmcaFormMessages.methodOfQualificationNotApplicable}
            />
        ),
        value: "NO",
    },
])

const MiscellaneousInfoFields = ({ shipmentId, gaCategory }) => (
    <Grid item container>
        <Grid item container xs={12} md={3}>
            <Field
                name="customFilename"
                component={FormField}
                type="text"
                label={[
                    <FormattedMessage {...documentFormMessages.userFileName} />,
                ]}
                required
                category={gaCategory}
            />
        </Grid>
        {shipmentId && (
            <Fragment>
                <Grid item container xs={12} md={3}>
                    <ShipmentOnlyCheck gaCategory={gaCategory} />
                </Grid>
            </Fragment>
        )}
    </Grid>
)

let USMCAForm = ({
    locationId,
    onSubmit,
    onBackClick,
    handleSubmit,
    submitting,
    formTitle,
    canAddGoods,
    shipmentId,
    shipmentDocumentList,
}) => {
    const [dialogOpen, setDialogOpen] = useState(false)
    const { checkFilenameCollision } = useCustomsProfileContext()

    const internalSubmit = values => {
        if (dialogOpen) {
            setDialogOpen(false)
            return onSubmit(values)
        }

        const {
            shipmentOnly,
            documentCategory,
            customFilename,
            userFileName,
        } = values
        const shipmentCollision =
            shipmentId &&
            checkFilenameCollision(
                { documentCategory, customFilename, userFileName },
                shipmentDocumentList ?? []
            )
        const profileCollision =
            !shipmentOnly &&
            checkFilenameCollision({
                documentCategory,
                customFilename,
                userFileName,
            })

        if (shipmentCollision || profileCollision) {
            setDialogOpen(true)
            return Promise.reject()
        } else {
            return onSubmit(values)
        }
    }

    return (
        <Fragment>
            <Dialog open={dialogOpen}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FilenameCollisionCheckStep
                        shipmentId={shipmentId}
                        onBack={() => setDialogOpen(false)}
                        onNoCollision={onSubmit}
                        enableProfileOverwrite={false}
                    />
                </form>
            </Dialog>
            <form onSubmit={handleSubmit(internalSubmit)}>
                <USMCAFields canAddGoods={canAddGoods} />
                <Grid item container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <MiscellaneousInfoFields
                                    shipmentId={shipmentId}
                                    locationId={locationId}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <DocumentActions
                        onBackClick={onBackClick}
                        submitting={submitting}
                        documentType={formTitle}
                    />
                </Grid>
            </form>
        </Fragment>
    )
}

const mapStateToProps = (state, props) => ({
    canAddGoods: !props?.shipmentId,
    shipmentDocumentList: props?.shipment?.shipment?.attachments ?? [],
    initialValues: usmcaFormGenerateInitialValues(state, props),
})

USMCAForm = connect(mapStateToProps)(
    reduxForm({
        form: "usmcaForm",
        enableReinitialize: true,
        validate: mergeValidations(
            usmcaFormValidation,
            documentFieldValidation
        ),
    })(USMCAForm)
)

const USMCAFields = ({ canAddGoods, gaCategory }) => {
    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <GeneralInformationCard gaCategory={gaCategory} />
            </Grid>
            <Grid item xs={6}>
                <FormSection name="certifierInfo" component={Fragment}>
                    <CertifierDetailsCard gaCategory={gaCategory} />
                </FormSection>
            </Grid>
            <Grid item xs={6}>
                <FormSection name="producerInfo" component={Fragment}>
                    <ProducerDetailsCard gaCategory={gaCategory} />
                </FormSection>
            </Grid>
            <Grid item xs={6}>
                <FormSection name="exporterInfo" component={Fragment}>
                    <ExporterDetailsCard gaCategory={gaCategory} />
                </FormSection>
            </Grid>
            <Grid item xs={6}>
                <FormSection name="importerInfo" component={Fragment}>
                    <ImporterDetailsCard gaCategory={gaCategory} />
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <GoodsCard canAddGoods={canAddGoods} gaCategory={gaCategory} />
            </Grid>
            <Grid item xs={12}>
                <FormSection name="signatureInfo" component={Fragment}>
                    <SignatureCard gaCategory={gaCategory} />
                </FormSection>
            </Grid>
        </Grid>
    )
}

export const GoodForm = formValues("originCriterion")(
    formName(
        ({
            canAddGoods,
            form,
            sectionPrefix,
            originCriterion,
            fields,
            index,
            gaCategory,
        }) => {
            const intl = useIntl()
            const dispatch = useDispatch()
            const { logGAEvent } = useGAContext()

            const onOriginCriterionChange = (event, newValue, oldValue) => {
                if (methodOfQualificationNotApplicableList.includes(newValue)) {
                    dispatch(
                        change(
                            form,
                            `${sectionPrefix}.methodOfQualification`,
                            "NO"
                        )
                    )
                } else if (
                    methodOfQualificationNotApplicableList.includes(oldValue)
                ) {
                    dispatch(
                        resetSection(
                            form,
                            `${sectionPrefix}.methodOfQualification`
                        )
                    )
                }
            }

            const onRemoveItem = () => {
                if (fields.length === 1) {
                    fields.push()
                }
                fields.remove(index)
                logGAEvent(gaCategory, "Generate Document Goods Remove Click")
            }

            return (
                <Grid item container>
                    <Grid item container xs={11}>
                        <Grid item container md={4}>
                            <Field
                                name="description"
                                required
                                component={FormField}
                                label={
                                    <FormattedMessage
                                        {...usmcaFormMessages.goodDescription}
                                    />
                                }
                                category={gaCategory}
                            />
                        </Grid>
                        <Grid item container md={4}>
                            <Field
                                name="harmonizedCode"
                                required
                                component={FormField}
                                label={
                                    <FormattedMessage
                                        {...usmcaFormMessages.goodHarmonizedTariffSchedule}
                                    />
                                }
                                category={gaCategory}
                            />
                        </Grid>
                        <Grid item container md={4}>
                            <Field
                                name="originCriterion"
                                required
                                component={FormSelect}
                                options={originCriterionOptions}
                                onChange={onOriginCriterionChange}
                                label={
                                    <FormattedMessage
                                        {...usmcaFormMessages.goodOriginCriterion}
                                    />
                                }
                                category={gaCategory}
                            />
                        </Grid>
                        <Grid item container md={4}>
                            <Field
                                component={FormSelect}
                                name="certificationIndicator"
                                options={certificationIndicatorOptions}
                                required
                                label={
                                    <FormattedMessage
                                        {...usmcaFormMessages.goodCertificationIndicator}
                                    />
                                }
                                category={gaCategory}
                            />
                        </Grid>
                        <Grid item container md={4}>
                            <Field
                                name="methodOfQualification"
                                required
                                component={FormSelect}
                                disabled={methodOfQualificationNotApplicableList.includes(
                                    originCriterion
                                )}
                                options={
                                    methodOfQualificationNotApplicableList.includes(
                                        originCriterion
                                    )
                                        ? methodOfQualificationNotApplicableOptions
                                        : methodOfQualificationOptions
                                }
                                label={
                                    <FormattedMessage
                                        {...usmcaFormMessages.goodMethodOfQualification}
                                    />
                                }
                                category={gaCategory}
                            />
                        </Grid>
                        <Grid item container md={4}>
                            <Field
                                name="countryOfOrigin"
                                required
                                component={FormSelect}
                                options={provideLocaleSortedOptionsList(
                                    intl,
                                    countryOfOriginOptions,
                                    ["US", "CA", "MX"]
                                )}
                                label={
                                    <FormattedMessage
                                        {...usmcaFormMessages.goodCountryOfOrigin}
                                    />
                                }
                                category={gaCategory}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        alignContent="center"
                        alignItems="center"
                        xs={1}
                    >
                        <IconButton
                            disabled={fields.length === 1 && !canAddGoods}
                            onClick={onRemoveItem}
                        >
                            <RemoveCircle />
                        </IconButton>
                    </Grid>
                </Grid>
            )
        }
    )
)

const GoodListPresentation = ({
    fields,
    canAddGoods,
    gaCategory,
    ...props
}) => {
    const { logGAEvent } = useGAContext()
    return (
        <Card>
            <CardHeader
                title={
                    <FormattedMessage
                        id="usmca.goods__title"
                        defaultMessage="Goods"
                    />
                }
                action={
                    canAddGoods && (
                        <Button
                            disabled={fields.length >= 11}
                            onClick={() => {
                                fields.push({})
                                logGAEvent(
                                    gaCategory,
                                    "Generate Document - Add another good Click"
                                )
                            }}
                            color="primary"
                        >
                            <FormattedMessage
                                id="usmca.add__good"
                                defaultMessage="Add another good"
                            />
                        </Button>
                    )
                }
            />
            <CardContent>
                <Grid item container>
                    {fields.map((prefix, index) => (
                        <FormSection
                            name={prefix}
                            key={prefix}
                            component={Fragment}
                        >
                            <GoodForm
                                {...props}
                                fields={fields}
                                index={index}
                                canAddGoods={canAddGoods}
                                gaCategory={gaCategory}
                            />
                        </FormSection>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    )
}

export const GoodsCard = props => (
    <FieldArray name="goods" component={GoodListPresentation} {...props} />
)

export const FormSwitchGroup = formName(
    ({ form, sectionPrefix, optionsList, sizeOverride, gaCategory }) => {
        const dispatch = useDispatch()
        const allOptions = optionsList.map(x => x.name)
        return (
            <Fragment>
                {optionsList.map(({ label, name, ...rest }) => (
                    <Grid item container xs={sizeOverride ? sizeOverride : 12}>
                        <Field
                            component={FormSwitch}
                            category={gaCategory}
                            name={name}
                            label={label}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    allOptions.forEach(option => {
                                        if (option !== name) {
                                            dispatch(
                                                change(
                                                    form,
                                                    sectionPrefix
                                                        ? `${sectionPrefix}.${option}`
                                                        : option,
                                                    false
                                                )
                                            )
                                        }
                                    })
                                }
                            }}
                            {...rest}
                        />
                    </Grid>
                ))}
            </Fragment>
        )
    }
)

const GeneralInformationCard = ({ gaCategory }) => (
    <Card>
        <CardHeader
            title={
                <FormattedMessage
                    id="usmca.general__information__title"
                    defaultMessage="General Information"
                />
            }
        />
        <CardContent>
            <Grid item container>
                <Grid item container xs={12} md={3}>
                    <Field
                        name="blanketPeriodFrom"
                        component={FormDatePicker}
                        type="text"
                        dateFormat="ll"
                        noMinDate
                        label={
                            <FormattedMessage
                                id="usmca.blanket__period__from"
                                defaultMessage="Blanket period from"
                            />
                        }
                        required
                        category={gaCategory}
                    />
                </Grid>
                <Grid item container xs={12} md={3}>
                    <Field
                        name="blanketPeriodTo"
                        component={FormDatePicker}
                        type="text"
                        dateFormat="ll"
                        noMinDate
                        label={
                            <FormattedMessage
                                id="usmca.blanket__period__to"
                                defaultMessage="Blanket period to"
                            />
                        }
                        required
                        category={gaCategory}
                    />
                </Grid>
            </Grid>
        </CardContent>
    </Card>
)

const CertifierDetailsCard = ({ gaCategory }) => (
    <Card>
        <CardHeader
            title={
                <FormattedMessage
                    id="usmca.certifier__title"
                    defaultMessage="Certifier"
                />
            }
        />
        <CardContent>
            <Grid item container>
                <ContactDetails includeContact gaCategory={gaCategory} />
            </Grid>
        </CardContent>
    </Card>
)

const ExporterDetailsCard = formValues({
    unknown: "unknown",
    sameAsCertifier: "sameAsCertifier",
})(({ unknown, sameAsCertifier, gaCategory }) => {
    return (
        <Card>
            <CardHeader
                title={
                    <FormattedMessage
                        id="usmca.exporter__title"
                        defaultMessage="Exporter"
                    />
                }
            />
            <CardContent>
                <Grid item container>
                    <FormSwitchGroup
                        gaCategory={gaCategory}
                        optionsList={[
                            {
                                name: "unknown",
                                label: (
                                    <FormattedMessage
                                        id="usmca.party__is__unknown"
                                        defaultMessage="Unknown"
                                    />
                                ),
                            },
                            {
                                name: "sameAsCertifier",
                                label: (
                                    <FormattedMessage
                                        id="usmca.same__as__certifier"
                                        defaultMessage="Same as Certifier"
                                    />
                                ),
                            },
                        ]}
                    />
                    {!unknown && !sameAsCertifier && (
                        <Grid item container>
                            <ContactDetails
                                gaCategory={gaCategory}
                                includeContact
                            />
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    )
})

const ProducerDetailsCard = formValues({
    variousProducers: "variousProducers",
    availableUponRequest: "availableUponRequest",
    sameAsCertifier: "sameAsCertifier",
})(
    ({
        variousProducers,
        availableUponRequest,
        sameAsCertifier,
        gaCategory,
    }) => (
        <Card>
            <CardHeader
                title={
                    <FormattedMessage
                        id="usmca.producer__title"
                        defaultMessage="Producer"
                    />
                }
            />
            <CardContent>
                <Grid item container>
                    <FormSwitchGroup
                        gaCategory={gaCategory}
                        optionsList={[
                            {
                                name: "variousProducers",
                                label: (
                                    <FormattedMessage
                                        id="usmca.various__producers"
                                        defaultMessage="Various"
                                    />
                                ),
                            },
                            {
                                name: "availableUponRequest",
                                label: (
                                    <FormattedMessage
                                        id="usmca.available__upon__request"
                                        defaultMessage="Available upon request"
                                    />
                                ),
                            },
                            {
                                name: "sameAsCertifier",
                                label: (
                                    <FormattedMessage
                                        id="usmca.same__as__certifier"
                                        defaultMessage="Same as Certifier"
                                    />
                                ),
                            },
                        ]}
                    />
                    {!variousProducers &&
                        !availableUponRequest &&
                        !sameAsCertifier && (
                            <Grid item container>
                                <ContactDetails
                                    gaCategory={gaCategory}
                                    includeContact
                                />
                            </Grid>
                        )}
                </Grid>
            </CardContent>
        </Card>
    )
)

const ImporterDetailsCard = formValues("unknown")(({ unknown, gaCategory }) => (
    <Card>
        <CardHeader
            title={
                <FormattedMessage
                    id="usmca.importer__title"
                    defaultMessage="Importer"
                />
            }
        />
        <CardContent>
            <Grid item container>
                <Grid item container sm={12} md={6}>
                    <Field
                        component={FormSwitch}
                        name="unknown"
                        label={
                            <FormattedMessage
                                id="usmca.party__is__unknown"
                                defaultMessage="Unknown"
                            />
                        }
                        category={gaCategory}
                    />
                </Grid>
                {!unknown && (
                    <Grid item container>
                        <ContactDetails
                            gaCategory={gaCategory}
                            includeContact
                        />
                    </Grid>
                )}
            </Grid>
        </CardContent>
    </Card>
))

const SignatureCard = ({ gaCategory }) => (
    <Card>
        <CardHeader
            title={
                <FormattedMessage
                    id="usmca.signature__title"
                    defaultMessage="Signature"
                />
            }
        />
        <CardContent>
            <Grid item container>
                <Grid item container xs={12} md={9}>
                    <Grid item container xs={12} md={3}>
                        <Field
                            name="companyName"
                            component={FormField}
                            type="text"
                            label={
                                <FormattedMessage
                                    {...contactDetailsFormMessages.companyName}
                                />
                            }
                            required
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container xs={12} md={3}>
                        <Field
                            name="contactName"
                            component={FormField}
                            type="text"
                            label={
                                <FormattedMessage
                                    {...contactDetailsFormMessages.contactName}
                                />
                            }
                            required
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container xs={12} md={3}>
                        <Field
                            name="title"
                            component={FormField}
                            type="text"
                            label={
                                <FormattedMessage
                                    {...usmcaFormMessages.signatureTitle}
                                />
                            }
                            required
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container xs={12} md={3}>
                        <Field
                            name="date"
                            component={FormDatePicker}
                            type="text"
                            dateFormat="ll"
                            noMinDate
                            label={
                                <FormattedMessage
                                    {...usmcaFormMessages.signatureDate}
                                />
                            }
                            required
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container xs={12} md={3}>
                        <Field
                            name="phone.phone_number"
                            component={FormField}
                            type="text"
                            label={
                                <FormattedMessage
                                    {...contactDetailsFormMessages.phone}
                                />
                            }
                            required
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container xs={12} md={3}>
                        <Field
                            name="phone.extension"
                            component={FormField}
                            type="text"
                            label={
                                <FormattedMessage
                                    {...contactDetailsFormMessages.extension}
                                />
                            }
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container xs={12} md={3}>
                        <Field
                            name="email.email_address"
                            component={FormField}
                            type="text"
                            label={
                                <FormattedMessage
                                    {...contactDetailsFormMessages.email}
                                />
                            }
                            required
                            category={gaCategory}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                    <ManageSignature
                        fieldName="signatureImage"
                        previewDimensions={{
                            width: 200,
                            height: 25,
                        }}
                        addTitle={
                            <FormattedMessage
                                id="locations.signatureManagment__addSignature"
                                defaultMessage="Add Signature"
                            />
                        }
                        replaceTitle={
                            <FormattedMessage
                                id="locations.signatureManagment__replaceSignature"
                                defaultMessage="Replace Signature"
                            />
                        }
                        gaCategory={gaCategory}
                    />
                </Grid>
            </Grid>
        </CardContent>
    </Card>
)

export { USMCAFields, USMCAForm, MiscellaneousInfoFields }

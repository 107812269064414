import moment from "moment"
import createSelector from "re-reselect"
import { get } from "lodash"
import {
    newQuickQuoteHandlingUnit,
    newFreightDirectHandlingUnit,
} from "../../reducers/search"
import { invalidInbondShipment } from "../../actions/validation"

const shipmentIdSelector = (state, props) => props?.shipmentId ?? ""

const returnModeSelector = (state, props) => props?.isReturnMode ?? false

const quoteSelector = createSelector(
    state => state.quotes.list.items,
    shipmentIdSelector,
    (list, shipmentId) => list[shipmentId]
)(shipmentIdSelector)

const shipmentSelector = createSelector(
    state => state.shipment.list,
    shipmentIdSelector,
    (list, shipmentId) => list[shipmentId]
)(shipmentIdSelector)

const contactIdSelector = (state, props) => get(props, "toContact", "")

const contactSelector = createSelector(
    contactIdSelector,
    state => get(state, "addressBook.addresses"),
    (contactId, contacts) => {
        if (!contactId || !contacts || !contacts.length) return undefined
        return contacts.find(item => get(item, "contact.id") === contactId)
    }
)(contactIdSelector)

export const billToLocationSelector = createSelector(
    quoteSelector,
    state => get(state, "user.profile.locations"),
    state => get(state, "user.profile.preferences.defaultLocationId"),
    returnModeSelector,
    (quote, locations = [], defaultLocationId, isReturnMode) => {
        if (isReturnMode) {
            return locations.find(
                loc => loc.locationType === "FEDEX_DIRECT_RETURNS"
            )
        } else {
            return (
                locations.find(
                    loc => get(loc, "cpgCode", "") === get(quote, "search.cpg")
                ) ||
                locations.find(
                    loc =>
                        get(loc, "_id") === defaultLocationId &&
                        get(loc, "users[0].permissions.createRates.value") &&
                        !get(loc, "users[0].permissions.suspended.value") &&
                        get(loc, "locationType") !== "FEDEX_FREIGHT_BOX"
                ) ||
                locations.find(
                    loc =>
                        get(loc, "users[0].permissions.createRates.value") &&
                        !get(loc, "users[0].permissions.suspended.value")
                )
            )
        }
    }
)(shipmentIdSelector)

export const nextBusinessDay = () => {
    if (moment().day() === 5) {
        return moment.utc(
            moment()
                .add(3, "days")
                .format("YYYY-MM-DD")
        )
    } else if (moment().day() === 6) {
        return moment.utc(
            moment()
                .add(2, "days")
                .format("YYYY-MM-DD")
        )
    } else {
        return moment.utc(
            moment()
                .add(1, "days")
                .format("YYYY-MM-DD")
        )
    }
}

export const formSelector = createSelector(
    quoteSelector,
    shipmentIdSelector,
    billToLocationSelector,
    contactSelector,
    returnModeSelector,
    shipmentSelector,
    (quote, shipmentId, billToLocation, contact, isReturnMode, shipment) => {
        if (!shipmentId) {
            const isShipperEnabled = get(billToLocation, "isShipperEnabled")
            const isFreightDirectReturns =
                billToLocation?.locationType === "FEDEX_DIRECT_RETURNS"
            const originCountry =
                isShipperEnabled && !isFreightDirectReturns
                    ? billToLocation?.shippingAddress?.address?.country ?? "US"
                    : "US"

            const destinationCountry = isFreightDirectReturns
                ? billToLocation?.shippingAddress?.address?.country
                : get(contact, "address.country", "US")

            return {
                origin:
                    isShipperEnabled && !isFreightDirectReturns
                        ? billToLocation?.shippingAddress?.address?.postalCode
                        : null,
                originCity:
                    isShipperEnabled && !isFreightDirectReturns
                        ? billToLocation?.shippingAddress?.address?.city
                        : null,
                originState:
                    isShipperEnabled && !isFreightDirectReturns
                        ? billToLocation?.shippingAddress?.address?.state
                        : null,
                originCountry,
                originStreet:
                    isShipperEnabled && !isFreightDirectReturns
                        ? billToLocation?.shippingAddress?.address?.street1
                        : null,
                originName:
                    isShipperEnabled && !isFreightDirectReturns
                        ? billToLocation?.shippingAddress?.name
                        : null,
                originAddress:
                    isShipperEnabled && !isFreightDirectReturns
                        ? billToLocation?.shippingAddress?._id
                        : null,
                originContactInfo:
                    isShipperEnabled && !isFreightDirectReturns
                        ? billToLocation?.shippingAddress
                        : null,
                destination: isFreightDirectReturns
                    ? billToLocation?.shippingAddress?.address?.postalCode
                    : get(contact, "address.postalCode"),
                destinationCity: isFreightDirectReturns
                    ? billToLocation?.shippingAddress?.address?.city
                    : get(contact, "address.city"),
                destinationState: isFreightDirectReturns
                    ? billToLocation?.shippingAddress?.address?.state
                    : get(contact, "address.state"),
                destinationCountry,
                destinationStreet: isFreightDirectReturns
                    ? billToLocation?.shippingAddress?.address?.street1
                    : get(contact, "address.street1"),
                destinationName: isFreightDirectReturns
                    ? billToLocation?.shippingAddress?.name
                    : get(contact, "name"),
                destinationAddress: isFreightDirectReturns
                    ? billToLocation?.shippingAddress?._id
                    : get(contact, "id"),
                destinationContact: get(contact, "contact.id"),
                destinationContactInfo: isFreightDirectReturns
                    ? billToLocation?.shippingAddress
                    : contact,
                pickupAccessorials: [{ value: "DOCKPU" }],
                deliveryAccessorials: [{ value: "DOCKDEL" }],
                cpg: get(billToLocation, "cpgCode"),
                pickupDate: isFreightDirectReturns
                    ? nextBusinessDay()
                    : moment.utc(moment().format("YYYY-MM-DD")),
                billToLocation: get(billToLocation, "cpgCode"),
                isFreightDirect:
                    billToLocation?.locationType === "FEDEX_DIRECT",
                isFreightDirectReturns,
                billToLocationAddress: get(
                    billToLocation,
                    "shippingAddress._id"
                ),
                handlingUnits: [newFreightDirectHandlingUnit()],
                includeThirdParty: true,
                thirdPartyOnly: !get(billToLocation, "isShipperEnabled"),
                isQuickQuote: false,
                volumeLTL: false,
                totalLinearFeet: 0,
                userProvidedTotalLinearFeet: false,
                capLoadTotalLinearFeet: 0,
                volumeTotalLinearFeet: 0,
                isOverLTLLimit: false,
                preferredCurrencyCode: "USD",
                preferredSystemOfMeasurement: "IMPERIAL",
                isInBondShipment: invalidInbondShipment(
                    originCountry,
                    destinationCountry
                )
                    ? false
                    : null,
            }
        }
        const isQuickQuote = get(quote, "search.isQuickQuote")
        const originAddress = get(quote, "search.originAddress")
        const originContact = get(quote, "search.originContact")
        const originContactInfo = originAddress
            ? {
                  address: get(originAddress, "address"),
                  contact: get(originAddress, "contacts", []).find(
                      item => item._id === originContact
                  ),
                  name: get(originAddress, "name"),
              }
            : undefined
        const destinationAddress = get(quote, "search.destinationAddress")
        const destinationContact = get(quote, "search.destinationContact")
        const destinationContactInfo = destinationAddress
            ? {
                  address: get(destinationAddress, "address"),
                  contact: get(destinationAddress, "contacts", []).find(
                      item => item._id === destinationContact
                  ),
                  name: get(destinationAddress, "name"),
              }
            : undefined
        const handlingUnits = (quote?.handlingUnits ?? []).map(hu => {
            const adjustedHu = isQuickQuote
                ? {
                      ...newFreightDirectHandlingUnit(),
                      weight: hu?.items?.[0]?.weight,
                      freightClass: hu?.items?.[0]?.freightClass,
                  }
                : { ...hu }
            adjustedHu.doNotStack = !adjustedHu.stackable
            return adjustedHu
        })

        const shipmentDestination = shipment?.shipment?.destination
        const shipmentDestinationContactInfo = {
            name: shipmentDestination?.name,
            contact: {
                name: shipmentDestination?.contact?.name,
                phone: {
                    phoneNumber:
                        shipmentDestination?.contact?.phone?.phone_number,
                    extension: shipmentDestination?.contact?.phone?.extension,
                },
                email: {
                    emailAddress:
                        shipmentDestination?.contact?.email?.email_address,
                },
            },
            address: { ...shipmentDestination?.address },
        }

        const originCountry = isReturnMode
            ? quote?.search?.destinationCountry
            : quote?.search?.originCountry

        const destinationCountry = isReturnMode
            ? billToLocation?.shippingAddress?.address?.country
            : quote?.search?.destinationCountry
        return {
            ...get(quote, "search"),
            proNumber: shipment?.identifiers?.proNumber,
            handlingUnits,
            pickupDate: isReturnMode
                ? nextBusinessDay()
                : moment.utc(get(quote, "search.pickupDate")),
            origin: isReturnMode
                ? shipmentDestination?.address?.postalCode
                : quote?.search?.origin,
            originCity: isReturnMode
                ? shipmentDestination?.address?.city
                : quote?.search?.originCity,
            originState: isReturnMode
                ? shipmentDestination?.address?.state
                : quote?.search?.originState,
            originAddress: isReturnMode
                ? shipmentDestination?.address?._id
                : originAddress?._id,
            originName: isReturnMode
                ? shipmentDestination?.name
                : originAddress?.name,
            originCountry,
            originStreet: isReturnMode
                ? shipmentDestination?.address?.street1
                : originAddress?.address?.street1,
            originContactInfo: isReturnMode
                ? shipmentDestinationContactInfo
                : originContactInfo,
            originContact: isReturnMode
                ? shipmentDestination?.contact?._id
                : originContact,
            destination: isReturnMode
                ? billToLocation?.shippingAddress?.address?.postalCode
                : quote?.search?.destination,
            destinationCity: isReturnMode
                ? billToLocation?.shippingAddress?.address?.city
                : quote?.search?.destinationCity,
            destinationState: isReturnMode
                ? billToLocation?.shippingAddress?.address?.state
                : quote?.search?.destinationState,
            destinationAddress: isReturnMode
                ? billToLocation?.shippingAddress?._id
                : destinationAddress?._id,
            destinationName: isReturnMode
                ? billToLocation?.shippingAddress?.name
                : destinationAddress?.name,
            destinationCountry,
            destinationStreet: isReturnMode
                ? billToLocation?.shippingAddress?.address?.street1
                : destinationAddress?.address?.street1,
            destinationContactInfo: isReturnMode
                ? billToLocation?.shippingAddress
                : destinationContactInfo,
            cpg: isReturnMode ? billToLocation?.cpgCode : quote?.search?.cpg,
            billToLocation: isReturnMode
                ? billToLocation?.cpgCode
                : get(quote, "search.cpg"),
            billToLocationAddress: get(billToLocation, "shippingAddress._id"),
            volumeLTL: get(quote, "search.volumeType") === "VOLUME_ONLY",
            thirdPartyOnly: !get(billToLocation, "isShipperEnabled"),
            isFreightDirect:
                billToLocation?.locationType === "FEDEX_DIRECT" ||
                quote?.search?.isFreightDirect,
            isFreightDirectReturns: isReturnMode
                ? true
                : billToLocation?.locationType === "FEDEX_DIRECT_RETURNS" ||
                  quote?.search?.isFreightDirectReturns,
            preferredCurrencyCode: quote?.search?.preferredCurrencyCode,
            preferredSystemOfMeasurement:
                quote?.search?.preferredSystemOfMeasurement,
            isInBondShipment: invalidInbondShipment(
                originCountry,
                destinationCountry
            )
                ? false
                : null,
        }
    }
)(shipmentIdSelector)

export const locationPermissionSelector = createSelector(
    billToLocationSelector,
    billToLocation => ({
        canCreateRates: !!get(
            billToLocation,
            "users[0].permissions.createRates.value"
        ),
        canCreateShipments: !!get(
            billToLocation,
            "users[0].permissions.createShipments.value"
        ),
        canViewRateAmounts: !!get(
            billToLocation,
            "users[0].permissions.viewRateAmounts.value"
        ),
    })
)(shipmentIdSelector)

export const quickQuotesFormSelector = createSelector(
    quoteSelector,
    shipmentIdSelector,
    billToLocationSelector,
    contactSelector,
    (quote, shipmentId, billToLocation, contact) => {
        if (!shipmentId) {
            return {
                origin: billToLocation?.shippingAddress?.address?.postalCode,
                originCity: billToLocation?.shippingAddress?.address?.city,
                originState: billToLocation?.shippingAddress?.address?.state,
                originCountry:
                    billToLocation?.shippingAddress?.address?.country ?? "",
                originStreet: billToLocation?.shippingAddress?.address?.street1,
                originName: billToLocation?.shippingAddress?.name,
                originAddress: billToLocation?.shippingAddress?._id,
                originContactInfo: billToLocation?.shippingAddress,
                destination: contact?.address?.postalCode,
                destinationCity: contact?.address?.city,
                destinationState: contact?.address?.state,
                destinationCountry: contact?.address?.country ?? "US",
                destinationStreet: contact?.address?.street1,
                destinationName: contact?.name,
                destinationAddress: contact?.id,
                destinationContact: contact?.contact?.id,
                destinationContactInfo: contact,
                pickupAccessorials: [{ value: "DOCKPU" }],
                deliveryAccessorials: [{ value: "DOCKDEL" }],
                cpg: get(billToLocation, "cpgCode"),
                pickupDate: moment.utc(moment().format("YYYY-MM-DD")),
                billToLocation: get(billToLocation, "cpgCode"),
                billToLocationAddress: get(
                    billToLocation,
                    "shippingAddress._id"
                ),
                handlingUnits: [newQuickQuoteHandlingUnit()],
                includeThirdParty: true,
                isQuickQuote: true,
                volumeLTL: false,
                totalLinearFeet: 0,
                capLoadTotalLinearFeet: 0,
                volumeTotalLinearFeet: 0,
                isOverLTLLimit: false,
            }
        }
        const originAddress = get(quote, "search.originAddress")
        const originContact = get(quote, "search.originContact")
        const originContactInfo = originAddress
            ? {
                  address: get(originAddress, "address"),
                  contact: get(originAddress, "contacts", []).find(
                      item => item._id === originContact
                  ),
                  name: get(originAddress, "name"),
              }
            : undefined
        const destinationAddress = get(quote, "search.destinationAddress")
        const destinationContact = get(quote, "search.destinationContact")
        const destinationContactInfo = destinationAddress
            ? {
                  address: get(destinationAddress, "address"),
                  contact: get(destinationAddress, "contacts", []).find(
                      item => item._id === destinationContact
                  ),
                  name: get(destinationAddress, "name"),
              }
            : undefined
        return {
            ...get(quote, "search"),
            handlingUnits: get(quote, "handlingUnits"),
            pickupDate: moment.utc(get(quote, "search.pickupDate")),
            originAddress: get(originAddress, "_id"),
            originName: get(originAddress, "name"),
            originContactInfo,
            destinationAddress: get(destinationAddress, "_id"),
            destinationName: get(destinationAddress, "name"),
            destinationContactInfo,
            billToLocation: get(quote, "search.cpg"),
            billToLocationAddress: get(billToLocation, "shippingAddress._id"),
            volumeLTL: get(quote, "search.volumeType") === "NORMAL_AND_VOLUME",
            pickupAccessorials: [{ value: "DOCKPU" }],
            deliveryAccessorials: [{ value: "DOCKDEL" }],
        }
    }
)(shipmentIdSelector)

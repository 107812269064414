import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import { FormattedMessage } from "react-intl"
import { FreightDirectReturnsQuoteCard } from "./freightDirectReturnsQuoteCard"

const styles = theme => ({
    progress: {
        margin: theme.spacing(2),
        top: "50%",
        right: "50%",
        position: "absolute",
    },
    freightDirectQuotesContainer: {
        padding: "105px 0px",
    },
})

const FreightDirectReturnsQuotes = props => {
    const {
        rates,
        isFetching,
        classes,
        isExpired,
        isShipped,
        getBol,
        canCreateShipments,
        isLoaded,
        canViewRateAmounts,
    } = props

    const formatFXFDRates = rates => {
        let formattedRates = {
            basic: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__basicPickup__title"
                        defaultMessage="Basic Pickup"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__basic"
                        defaultMessage="Front door, back door, or from the garage with no signature"
                    />
                ),
                rates: [],
            },
            basicPlus: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__basicPlusPickup__title"
                        defaultMessage="Basic by Appointment Pickup"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__basicPlusPickup"
                        defaultMessage="Front door, back door, or from the garage with signature"
                    />
                ),
                rates: [],
            },
            standard: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__standardPickup__title"
                        defaultMessage="Standard Pickup"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__standardPickup"
                        defaultMessage="From the first ground-level room"
                    />
                ),
                rates: [],
            },
            premium: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__premiumPickup__title"
                        defaultMessage="Premium"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__premiumPickup"
                        defaultMessage="Room of choice and packaging by request"
                    />
                ),
                rates: [],
            },
        }

        rates.forEach(rate => {
            rate.rateQuotes.forEach(individualRate => {
                switch (individualRate.serviceLevel?.code) {
                    case "BASIC_PICKUP":
                        formattedRates.basic.rates.push(individualRate)
                        break
                    case "BASIC_APPT_PICKUP":
                        formattedRates.basicPlus.rates.push(individualRate)
                        break
                    case "STANDARD_PICKUP":
                        formattedRates.standard.rates.push(individualRate)
                        break
                    case "PREMIUM_PICKUP":
                        formattedRates.premium.rates.push(individualRate)
                        break

                    default:
                        break
                }
            })
        })

        return formattedRates
    }

    const formattedRates = rates ? formatFXFDRates(rates) : {}

    if (isFetching)
        return (
            <CircularProgress
                className={classes.progress}
                size={100}
                color="secondary"
            />
        )

    if (isLoaded && (!rates || !rates.length)) {
        return (
            <div className="error-message">
                <FormattedMessage
                    id="rateResults__noRates"
                    defaultMessage="No available rate quote."
                />
            </div>
        )
    }

    return (
        <Grid
            item
            container
            className={classes.freightDirectQuotesContainer}
            alignItems="flex-start"
            justify="center"
        >
            {formattedRates &&
                Object.keys(formattedRates).map((serviceLevel, i) => {
                    return (
                        <Grid item container xs={3}>
                            <FreightDirectReturnsQuoteCard
                                key={i}
                                disabled={
                                    isExpired ||
                                    isShipped ||
                                    !canCreateShipments
                                }
                                canViewRateAmounts={canViewRateAmounts}
                                serviceLevel={formattedRates[serviceLevel]}
                                cardIndex={i}
                                getBol={getBol}
                            />
                        </Grid>
                    )
                })}
        </Grid>
    )
}

export default withStyles(styles)(FreightDirectReturnsQuotes)

const wordSeparatorRegex = /_\w?/g

export const snakeToCamel = str => {
    if (str === undefined || str === null) {
        return str
    }

    return str.replace(wordSeparatorRegex, match =>
        (match[1] || "").toUpperCase()
    )
}

/*
 converts snake_case to camelCase (renaming all properties) recursively
 WARNING: this mutates the object argument
 */
export function snakesToCamelsDeep(obj) {
    if (typeof obj !== "object" || obj === null) {
        return obj
    }
    if (Array.isArray(obj)) {
        obj.forEach(snakesToCamelsDeep)
        return obj
    }
    Object.keys(obj).forEach(name => {
        const propertyValue = obj[name]
        snakesToCamelsDeep(propertyValue)
        if (name.indexOf("_") > 0) {
            delete obj[name]
            obj[snakeToCamel(name)] = propertyValue
        }
        if (name === "_id") {
            delete obj[name]
            obj.id = propertyValue
        }
    })
    return obj
}

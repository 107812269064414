import React from "react"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"
import List from "@material-ui/core/List"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import MenuItem from "@material-ui/core/MenuItem"

function DrawerListItem(props) {
    return (
        <List>
            <MenuItem
                button
                id={`navigationMenu__${props.label?.props.id}`}
                onClick={props.onClick}
                selected={props.selected}
                component={NavLink}
                to={props.url}
            >
                <ListItemIcon>{props.renderIcon()}</ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{ style: { fontSize: "1rem" } }}
                    primary={props.label}
                />
            </MenuItem>
        </List>
    )
}

DrawerListItem.propTypes = {
    renderIcon: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
}

export default DrawerListItem

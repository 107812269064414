import React, { useState, useEffect } from "react"
import {
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { injectIntl, FormattedMessage } from "react-intl"
import { reduxForm, change, reset, getFormValues } from "redux-form"
import { connect } from "react-redux"
import { changePath, checkRestrictionsByPostalCode } from "../../../actions"
import {
    addAccessorial,
    removeAccessorial,
    requestAccessorials,
} from "../../../actions/accessorial"
import { requestItems, searchItems } from "../../../actions/item"
import { constructSubLabel, constructLabel } from "../../util"
import { formSelector } from "./selector"
import {
    sortByPriority,
    formattedPriorityDeliveryAccessorials,
    formattedPriorityPickupAccessorials,
} from "../../quotesPage/accessorials"

import GlobalSpinner from "../../common/GlobalSpinner"
import { searchContacts } from "../../../actions/contact"

import { requestPostalCodeQuickRate } from "../../../actions/postal-code"

import { newFreightDirectHandlingUnit } from "../../../reducers/search"
import {
    populateActiveRate,
    requestQuote,
} from "../../../actions/book-shipment-requests"
import {
    isRequired,
    isRequiredIf,
    isUSDomesticOffshoreShipment,
    itemPropertiesFormValidator,
    usDomesticOffshoreStates,
} from "../../../actions/validation"
import { merge } from "lodash"
import { combineValidators } from "revalidate"
import {
    addressValidator,
    addressQuickRateValidator,
    isRequiredIfAddressEmpty,
    handlingUnitsValidator,
} from "../bookShipment/validators"
import { useSnackbarContext } from "../../../context/providers/snackbarProvider"
import QuickRateForm from "./QuickRateForm"
import Rates from "../bookShipment/steps/Rates"
import { trackGAEvent } from "../../../actions/user"
import QuickRateSummary from "./QuickRateSummary"
import moment from "moment"
import { setIsBookingQuickRate } from "../../../actions/redesign"
import { Button, DialogTitle } from "@material-ui/core"
import { freightClassCheck } from "../../util"
import FreightBoxRestrictionDialog from "../bookShipment/atoms/FreightBoxRestrictionDialog"
import CapLoadWarningDialog from "../bookShipment/atoms/CapLoadWarningDialog"
import { copyShipment } from "../../../actions/quote-request"
import { scroller } from "react-scroll"
import { IntercomAPI } from "react-intercom"
import AlertDialog from "../bookShipment/atoms/AlertDialog"
import { closeModal, openModal } from "../../../actions/modal"
import { getZipcodeSpecialHandling } from "../../../actions/offshoreRating"
import { useFlags } from "launchdarkly-react-client-sdk"
import { isOffshorePRShipmentFedexValid } from "../../util/offshoreUtils."
import {
    customerDeliveryAccessorial,
    customerDeliveryAccessorialOffshore,
    customerPickupAccessorialOffshore,
    deprecatedDeliveryAccessorial,
} from "../../constants/accessorialMessages"

const GACategory = "QuickRate"

const useStyles = makeStyles({
    section: {
        paddingTop: "10px",
    },
    bigSection: {
        paddingTop: "30px",
    },
    baseContainer: {
        paddingTop: "32px",
        paddingBottom: "272px",
    },
    input__field: {
        //backgroundColor: "rgb(242, 242, 242)",
        cursor: "pointer",
        width: "100%",
    },
    textInput__field: {
        cursor: "text",
    },
    workflowContainer: {
        padding: "10px 10% 25px 10%",
    },
    basicInfo: {
        padding: "0 12%",
    },
    getRates: {
        paddingTop: "30px",
    },
    stepContainer: {
        paddingBottom: "45px",
    },
    containerForm: {
        display: "flex",
        width: "100%",
    },
})

const QuickRate = ({
    changeField,
    intl,
    locations,
    formattedLocations,
    loadAccessorials,
    formValues = {},
    initialValues,
    shipmentId,
    activeQuote,
    performContactSearch,
    contactSearchInProgress,
    onPostalCodeChange,
    pickupAccessorialsList,
    deliveryAccessorialsList,
    itemsIsLoaded,
    favoriteItems,
    doSearch,
    loadItems,
    getRatesRequest,
    invalid,
    guaranteedList,
    changeRoute,
    trackGA,
    setIsBookingQR,
    loadRate,
    handleSubmit,
    submitting,
    originCities,
    destinationCities,
    reRunRates,
    quickRateSyncErrors,
    checkRestrictionsByPostalCode,
    openModal,
    closeModal,
    addAccessorial,
    removeAccessorial,
    checkZipcodeSpecialHandling,
    offShoreZipcodeSpecialHandling,
}) => {
    const classes = useStyles()
    const [currentStep, setCurrentStep] = useState(shipmentId ? 1 : 0)
    const [isLoading, setIsLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [isHUOpen, setIsHUOpen] = useState(0)
    const { openSnackbar } = useSnackbarContext()
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [isLoadingRate, setIsLoadingRate] = useState(false)
    const [isFreightBoxDialogOpen, setIsFreightBoxDialogOpen] = useState(false)
    const [selectedMXSATCommodity, setSelectedMXSATCommodity] = useState(null)
    const [isCapLoadDialogOpen, setIsCapLoadDialogOpen] = useState(false)
    const [isPieceWeightDialogOpen, setIsPieceWeightDialogOpen] = useState(
        false
    )
    const {
        multiCarrierSpacePaceWorkflow,
        domesticOffshoreCustomerPud,
    } = useFlags()

    const [requiredAccessorialList, setRequiredAccessorialList] = useState([])

    const { handlingUnits: handlingUnitSyncErrors } = quickRateSyncErrors ?? {}

    const { shouldReset, shippingContact, handlingUnits } = formValues

    const isAK = (country, state) => {
        return country === "US" && state === "AK"
    }

    const isHI = (country, state) => {
        return country === "US" && state === "HI"
    }

    const originCountry = formValues?.origin?.shippingAddress?.address?.country
    const destinationCountry =
        formValues?.destination?.shippingAddress?.address?.country
    const originState = formValues?.origin?.shippingAddress?.address?.state
    const destinationState =
        formValues?.destination?.shippingAddress?.address?.state

    const onClickReRunRates = async () => {
        setIsLoading(true)
        await reRunRates(shipmentId)
        setIsLoading(false)
        scroller.scrollTo("ratesTitle", {
            offset: -140,
        })
    }

    useEffect(() => {
        trackGA(GACategory, "Page Mount")
        IntercomAPI("trackEvent", "quick-rate")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (shipmentId && !activeQuote) {
            const fetchData = async () => {
                setIsLoadingRate(true)
                trackGA(GACategory, "Load Existing Rate")
                await loadRate(shipmentId)
                setIsLoadingRate(false)
            }
            fetchData()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipmentId])

    useEffect(() => {
        if (shouldReset) {
            trackGA(
                GACategory,
                "Reset from Nav Bar",
                `Current Step: ${currentStep}`
            )
            setCurrentStep(0)
            setIsBookingQR(false)
            setEditMode(false)
            setIsHUOpen(0)
            setIsDialogOpen(false)
        }

        changeField("shouldReset", false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldReset])

    useEffect(() => {
        const customerPickupAccessorial = {
            value: "CUSTPU",
            label: {
                "en-us": "Customer Dock Delivery Charge",
                "es-mx": "Cargo de entrega del muelle del cliente",
                "fr-ca": "Frais de livraison au quai du client",
            },
        }
        if (
            formValues?.origin?.shippingAddress?.address?.state === "PR" &&
            !domesticOffshoreCustomerPud
        ) {
            addAccessorial(true, customerPickupAccessorial)
        } else if (hasAccessorial(customerPickupAccessorial.value)) {
            removeAccessorial(customerPickupAccessorial?.value)
        }
    }, [
        formValues?.origin?.shippingAddress?.address?.state,
        domesticOffshoreCustomerPud,
    ])

    const removeOffshoreAccessorials = deliveryAccessorialMainland => {
        const accessorials = [
            deliveryAccessorialMainland,
            customerDeliveryAccessorialOffshore,
            customerPickupAccessorialOffshore,
        ]
        accessorials.forEach(accessorial => {
            if (hasAccessorial(accessorial.value)) {
                removeAccessorial(accessorial.value)
            }
        })
    }
    useEffect(() => {
        const isOffshorePRShipmentFedex = isOffshorePRShipmentFedexValid(
            originCountry,
            originState,
            destinationCountry,
            destinationState
        )

        const isUsDomesticOffshore = isUSDomesticOffshoreShipment(
            originCountry,
            destinationCountry,
            originState,
            destinationState
        )

        if (
            destinationState === "PR" &&
            isOffshorePRShipmentFedex &&
            !domesticOffshoreCustomerPud
        ) {
            addAccessorial(false, deprecatedDeliveryAccessorial)
        } else {
            removeOffshoreAccessorials(deprecatedDeliveryAccessorial)
        }

        if (
            domesticOffshoreCustomerPud &&
            (isUsDomesticOffshore || isOffshorePRShipmentFedex)
        ) {
            if (usDomesticOffshoreStates.includes(destinationState)) {
                addAccessorial(false, customerDeliveryAccessorial)
                addAccessorial(false, customerPickupAccessorialOffshore)
            } else {
                addAccessorial(false, customerDeliveryAccessorialOffshore)
            }
        } else {
            removeOffshoreAccessorials(customerDeliveryAccessorial)
        }
    }, [
        originCountry,
        destinationState,
        destinationCountry,
        originState,
        domesticOffshoreCustomerPud,
    ])

    useEffect(() => {
        loadAccessorials()
        loadItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const zipCodeAKCheck = (zipcode, address, accessorialGroup) => {
        if (
            !offShoreZipcodeSpecialHandling["AK"]?.some(
                zipInfo => zipInfo?.zip === zipcode
            )
        ) {
            checkZipcodeSpecialHandling(zipcode, "AK")
        } else {
            const specialCase = checkLoadedAKSpecialCases(address)
            if (specialCase?.specialHandling === "Will Call Only") {
                addSelectedRequiredAccessorial(accessorialGroup, "DOCKDEL")
            }
        }
    }

    const zipCodeHICheck = (
        zipcode,
        address,
        accessorialGroup,
        requiredAccessorial
    ) => {
        if (
            !offShoreZipcodeSpecialHandling["HI"]?.some(
                zipInfo => zipInfo?.zipcode === zipcode
            )
        ) {
            checkZipcodeSpecialHandling(zipcode, "HI")
        } else {
            const specialHiCase = checkLoadedHISpecialCases(address)
            if (specialHiCase?.specialHandling === "Will Call Only") {
                addSelectedRequiredAccessorial(
                    accessorialGroup,
                    requiredAccessorial
                )
            }
        }
    }

    useEffect(() => {
        const originPostalCode =
            formValues?.origin?.shippingAddress?.address?.postalCode
        const city = formValues?.origin?.shippingAddress?.address?.city

        if (requiredAccessorialList.length > 0) {
            setRequiredAccessorialList(
                requiredAccessorialList.filter(acc => acc !== "DOCKDEL")
            )
        }

        if (originPostalCode && city) {
            if (
                isAK(
                    formValues?.origin?.shippingAddress?.address?.country,
                    formValues?.origin?.shippingAddress?.address?.state
                )
            ) {
                zipCodeAKCheck(
                    originPostalCode,
                    formValues?.origin?.shippingAddress?.address,
                    "deliveryAccessorials"
                )
            } else if (
                isHI(
                    formValues?.origin?.shippingAddress?.address?.country,
                    formValues?.origin?.shippingAddress?.address?.state
                )
            ) {
                zipCodeHICheck(
                    originPostalCode,
                    formValues?.origin?.shippingAddress?.address,
                    "deliveryAccessorials",
                    "DOCKDEL"
                )
            }
        }
    }, [
        formValues?.origin?.shippingAddress?.address?.country,
        formValues?.origin?.shippingAddress?.address?.state,
        formValues?.origin?.shippingAddress?.address?.postalCode,
        formValues?.origin?.shippingAddress?.address?.city,
        offShoreZipcodeSpecialHandling,
    ])

    useEffect(() => {
        const destinationPostalCode =
            formValues?.destination?.shippingAddress?.address?.postalCode
        const city = formValues?.destination?.shippingAddress?.address?.city

        if (requiredAccessorialList.length > 0) {
            setRequiredAccessorialList(
                requiredAccessorialList.filter(acc => acc !== "DOCKPU")
            )
        }
        if (destinationPostalCode && city) {
            if (
                isAK(
                    formValues?.destination?.shippingAddress?.address?.country,
                    formValues?.destination?.shippingAddress?.address?.state
                )
            ) {
                zipCodeAKCheck(
                    destinationPostalCode,
                    formValues?.destination?.shippingAddress?.address,
                    "pickupAccessorials"
                )
            } else if (
                isHI(
                    formValues?.destination?.shippingAddress?.address?.country,
                    formValues?.destination?.shippingAddress?.address?.state
                )
            ) {
                zipCodeHICheck(
                    destinationPostalCode,
                    formValues?.destination?.shippingAddress?.address,
                    "pickupAccessorials",
                    "DOCKPU"
                )
            }
        }
    }, [
        formValues?.destination?.shippingAddress?.address?.country,
        formValues?.destination?.shippingAddress?.address?.state,
        formValues?.destination?.shippingAddress?.address?.postalCode,
        formValues?.destination?.shippingAddress?.address?.city,
        offShoreZipcodeSpecialHandling,
    ])

    const setDefaultCountry = country => {
        if (country === "MX") {
            return "MX"
        } else {
            return "US"
        }
    }

    const addNewHandlingUnit = fields => {
        trackGA(
            GACategory,
            "Add New Handling Unit",
            `Current Length: ${fields.length}`
        )
        fields.push(newFreightDirectHandlingUnit())
        const id = fields.length
        setIsHUOpen(id > -1 ? id : undefined)
    }

    const handleHUPanel = index => {
        setIsHUOpen(isHUOpen === index ? null : index)
    }

    const inputProps = {
        classes: {
            root: classes.input__field,
            input: classes.input__field,
        },
    }

    const textInputProps = {
        classes: {
            root: classes.textInput__field,
            input: classes.textInput__field,
        },
    }

    const onBillToLocationChange = option => {
        const selectedLocation = locations.find(
            location => location.cpgCode === option.value
        )

        trackGA(
            GACategory,
            "BillToLocation Change",
            selectedLocation?.locationType
        )

        const isFreightDirectReturns =
            selectedLocation?.locationType === "FEDEX_DIRECT_RETURNS"

        const isFreightDirect =
            selectedLocation?.locationType === "FEDEX_DIRECT"

        const isFreightBox =
            selectedLocation?.locationType === "FEDEX_FREIGHT_BOX"

        let mode = "thirdParty"
        if (selectedLocation.isShipperEnabled) {
            mode = isFreightDirectReturns ? "inbound" : "outbound"
        }

        const defaultCountry = setDefaultCountry(
            selectedLocation.address.country
        )

        changeField("selectedLocation", selectedLocation)
        changeField("mode", mode)
        changeField("isFreightDirect", isFreightDirect)
        changeField("isFreightDirectReturns", isFreightDirectReturns)
        changeField("isFreightBox", isFreightBox)
        changeField("pickupDate", moment.utc(moment().format("YYYY-MM-DD")))

        const initialDestinationLocation = {
            shippingAddress: {
                address: {
                    country: defaultCountry,
                },
            },
        }
        changeField("destination", initialDestinationLocation)

        if (isFreightBox) {
            if (defaultCountry !== "US") {
                setIsFreightBoxDialogOpen(true)
            }
        }

        onDirectionChange(
            selectedLocation,
            mode,
            true,
            isFreightDirect,
            isFreightDirectReturns
        )
    }

    const onDirectionChange = (
        location,
        mode,
        fromBilltoChange,
        isFreightDirect,
        isFreightDirectReturns
    ) => {
        const switchingFromThirdParty = formValues?.mode === "thirdParty"

        const isFreightBox = location?.locationType === "FEDEX_FREIGHT_BOX"

        if (
            isFreightBox ||
            isFreightDirect ||
            isFreightDirectReturns ||
            mode === "thirdParty"
        ) {
            changeField("paymentType", null)
        }

        if (
            location?.isShipperEnabled &&
            !isFreightDirect &&
            !isFreightBox &&
            !isFreightDirectReturns
        ) {
            if (mode === "inbound") {
                changeField("paymentType", "COLLECT")
            } else if (mode === "outbound") {
                changeField("paymentType", "PREPAID")
            }
        }

        const initialOriginLocation = {
            shippingAddress: {
                address: {
                    country: setDefaultCountry(location.address.country),
                },
            },
        }
        const initialDestinationLocation = {
            shippingAddress: {
                address: {
                    country: setDefaultCountry(location.address.country),
                },
            },
        }

        const locationPickupAccessorials = (
            location?.pickupAccessorials ?? []
        ).map(entry => entry.value) ?? ["DOCKPU"]

        const locationDeliveryAccessorials = (
            location?.deliveryAccessorials ?? []
        ).map(entry => entry.value) ?? ["DOCKDEL"]

        if (mode === "outbound") {
            changeField("origin", { ...location, contact: shippingContact })
            if (
                locationPickupAccessorials.toString() !== ["DOCKPU"].toString()
            ) {
                changeField("origin.showAccessorials", true)
                changeField("pickupAccessorials", locationPickupAccessorials)
            } else {
                changeField("origin.showAccessorials", false)
            }

            changeField("deliveryAccessorials", ["DOCKDEL"])
            if (!fromBilltoChange) {
                changeField(
                    "destination",
                    switchingFromThirdParty && !isFreightDirect
                        ? initialDestinationLocation
                        : formValues?.origin
                )
            }
            if (isFreightDirect) {
                changeField("destination", initialDestinationLocation)
            }
        } else if (mode === "inbound") {
            changeField("destination", {
                ...location,
                contact: shippingContact,
            })
            if (
                locationDeliveryAccessorials.toString() !==
                ["DOCKDEL"].toString()
            ) {
                changeField("destination.showAccessorials", true)
                changeField(
                    "deliveryAccessorials",
                    locationDeliveryAccessorials
                )
            } else {
                changeField("destination.showAccessorials", false)
            }
            changeField("pickupAccessorials", ["DOCKPU"])
            if (!fromBilltoChange) {
                changeField(
                    "origin",
                    switchingFromThirdParty && !isFreightDirectReturns
                        ? initialOriginLocation
                        : formValues?.destination
                )
            }
            if (isFreightDirectReturns) {
                changeField("origin", initialOriginLocation)
            }
        } else if (mode === "thirdParty") {
            changeField("origin", initialOriginLocation)
            changeField("destination", initialDestinationLocation)
            changeField("pickupAccessorials", ["DOCKPU"])
            changeField("deliveryAccessorials", ["DOCKDEL"])
            changeField("origin.showAccessorials", false)
            changeField("destination.showAccessorials", false)
        }

        if (isFreightDirectReturns || isFreightDirect) {
            changeField("pickupAccessorials", [])
            changeField("deliveryAccessorials", [])
        }
    }

    const checkCapacityLoadWarnings = () => {
        if (formValues.showWarning) {
            trackGA(GACategory, "Capacity Load Warning Modal", "Appears")
            setIsCapLoadDialogOpen(true)
            return false
        } else {
            return true
        }
    }

    const hasAccessorial = accessorialCode => {
        return (
            pickupAccessorialsList.some(
                acc => acc?.value === accessorialCode
            ) ||
            deliveryAccessorialsList.some(acc => acc?.value === accessorialCode)
        )
    }

    const getRates = async dismissCapLoadWarning => {
        let proceed = true
        if (!dismissCapLoadWarning) {
            proceed = checkCapacityLoadWarnings()
        }
        if (proceed) await makeRateRequest()
    }

    const makeRateRequest = async () => {
        trackGA(GACategory, "Rate Request", `Editing: ${editMode}`)
        setIsLoading(true)
        let newFormValues = freightClassCheck(
            formValues,
            multiCarrierSpacePaceWorkflow
        )
        try {
            await getRatesRequest(newFormValues)
            trackGA(GACategory, "Rating Success", `Editing: ${editMode}`)
            setCurrentStep(1)
            setEditMode(false)
        } catch (err) {
            if (err?.props?.id && err?.props?.defaultMessage) {
                trackGA(GACategory, "Rating Error", err?.props?.defaultMessage)
                openSnackbar("error", <FormattedMessage {...err?.props} />)
            }
        } finally {
            setIsLoading(false)
        }
    }

    const handleComplete = () => {
        setIsDialogOpen(true)
        trackGA(GACategory, "Dialog Open")
    }

    const confirmDialog = () => {
        setIsBookingQR(true)
        trackGA(GACategory, "Dialog Confirm - Book Shipment Conversion")
        changeRoute("/book")
    }

    const handleEdit = () => {
        trackGA(GACategory, "Begin Edit")
        setCurrentStep(0)
        setEditMode(true)
    }

    const handleCancel = () => {
        trackGA(GACategory, "Cancel Edit")
        setCurrentStep(1)
        setEditMode(false)
    }

    const toggleOnPieceWeight = () => {
        for (let huIndex in handlingUnits) {
            for (let itemIndex in handlingUnits[huIndex].items) {
                changeField(
                    `handlingUnits[${huIndex}].items[${itemIndex}].freightDirectPiecesToggle`,
                    true
                )
            }
        }
    }

    const checkLoadedAKSpecialCases = shippingAddress => {
        return offShoreZipcodeSpecialHandling["AK"]
            ?.find(
                specialInfo => specialInfo?.zip === shippingAddress?.postalCode
            )
            ?.specialInstructions.find(
                specialHandling =>
                    specialHandling.city?.toUpperCase() === "ALL CITIES" ||
                    specialHandling.city?.toUpperCase() ===
                        shippingAddress.city.toUpperCase()
            )
    }

    const checkLoadedHISpecialCases = shippingAddress => {
        return offShoreZipcodeSpecialHandling["HI"]?.find(
            info =>
                info?.zipcode === shippingAddress?.postalCode &&
                (info.city?.toUpperCase() ===
                    shippingAddress.city.toUpperCase() ||
                    info?.aliasCities?.some(
                        city =>
                            city?.toUpperCase() ===
                            shippingAddress.city.toUpperCase()
                    ))
        )
    }

    const addSelectedRequiredAccessorial = (
        accessorialGroup,
        accessorialCode
    ) => {
        changeField(accessorialGroup, accessorialCode)
        changeField(
            accessorialGroup === "pickupAccessorials"
                ? "origin.showAccessorials"
                : "destination.showAccessorials",
            true
        )
        setRequiredAccessorialList(
            Array.from(new Set([...requiredAccessorialList, accessorialCode]))
        )
    }

    const subProps = {
        currentStep,
        inputProps,
        textInputProps,
        initialValues,
        formValues,
        setIsLoading,
        permissions: formValues?.selectedLocation?.users[0].permissions,
        shipmentId,
        activeQuote,
        changeField,
        performContactSearch,
        contactSearchInProgress,
        intl,
        onPostalCodeChange,
        addNewHandlingUnit,
        handleHUPanel,
    }

    if (isLoadingRate) {
        return <GlobalSpinner />
    }

    return (
        <form
            onSubmit={handleSubmit(() => {})}
            className={classes.containerForm}
        >
            <Grid container justify="center" className={classes.baseContainer}>
                <FreightBoxRestrictionDialog
                    open={isFreightBoxDialogOpen}
                    handleClose={() => setIsFreightBoxDialogOpen(false)}
                />
                <AlertDialog
                    open={isPieceWeightDialogOpen}
                    onCloseDialog={() => {
                        setIsPieceWeightDialogOpen(false)
                    }}
                    onContinue={() => {
                        setIsPieceWeightDialogOpen(false)
                        setCurrentStep(0)
                        changeField("isAdvancedItems", true)
                        toggleOnPieceWeight()
                    }}
                    dialogTitle={
                        <FormattedMessage
                            id="book.freightDirect_Pieces_Dialog_title"
                            defaultMessage="Standard and Premium FXFD service levels"
                        />
                    }
                    dialogContentText={
                        <FormattedMessage
                            id="book.FXFDpiece_weight_dialog_contentText"
                            defaultMessage="Standard and Premium FXFD service levels require 
                        piece weight information. Go to line items page to add information?"
                        />
                    }
                    closeDialogButtonText={
                        <FormattedMessage
                            id="generalTerms__cancel"
                            defaultMessage="Cancel"
                        />
                    }
                    confirmButtonText={
                        <FormattedMessage
                            id="book.FXFDpiece_weight_dialog_Lineitems"
                            defaultMessage="OK"
                        />
                    }
                />

                <CapLoadWarningDialog
                    onContinue={() => {
                        setIsCapLoadDialogOpen(false)
                        getRates(true)
                    }}
                    open={isCapLoadDialogOpen}
                    onCloseDialog={() => {
                        setIsCapLoadDialogOpen(false)
                    }}
                />
                <Dialog
                    className={classes.dialog}
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                >
                    <DialogTitle id="alert-dialog-title">
                        <Typography variant="h6">
                            <FormattedMessage
                                id="quickRate__instructionsTitle"
                                defaultMessage="Booking with a Quick Rate"
                            />
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialog__content}>
                        <Typography variant="body2">
                            <FormattedMessage
                                id="quickRate__instructions"
                                defaultMessage="In order to book a shipment based on this quick rate, you will be taken through the Book Shipment 
                            workflow in order to gather additional information. You will not need to re-enter anything you've already entered, but your rates may be subject to change due to the new information.  Continue?"
                            />
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                trackGA(GACategory, "Dialog Cancel")
                                setIsDialogOpen(false)
                            }}
                        >
                            <FormattedMessage
                                id="generalTerms__cancel"
                                defaultMessage="Cancel"
                            />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => confirmDialog()}
                        >
                            <FormattedMessage
                                id="generalTerms__continue"
                                defaultMessage="Continue"
                            />
                        </Button>
                    </DialogActions>
                </Dialog>
                {isLoading && <GlobalSpinner />}
                <Grid item container justify="center">
                    <Typography variant="h6">
                        <FormattedMessage
                            id="bookShipment.quickRate__title"
                            defaultMessage="Tell us about your shipment."
                        />
                    </Typography>
                </Grid>
                <Grid item container className={classes.workflowContainer}>
                    {currentStep === 0 && (
                        <Grid item container className={classes.section}>
                            <QuickRateForm
                                {...{
                                    subProps,
                                    changeField,
                                    onBillToLocationChange,
                                    onDirectionChange,
                                    pickupAccessorialsList,
                                    deliveryAccessorialsList,
                                    formattedLocations,
                                    initialValues,
                                    invalid,
                                    inputProps,
                                    intl,
                                    handleHUPanel,
                                    isHUOpen,
                                    getRates,
                                    itemsIsLoaded,
                                    favoriteItems,
                                    doSearch,
                                    addNewHandlingUnit,
                                    trackGA,
                                    handleCancel,
                                    editMode,
                                    submitting,
                                    originCities,
                                    destinationCities,
                                    handlingUnitSyncErrors,
                                    selectedMXSATCommodity,
                                    setSelectedMXSATCommodity,
                                    checkRestrictionsByPostalCode,
                                    openModal,
                                    closeModal,
                                    checkLoadedAKSpecialCases,
                                    addSelectedRequiredAccessorial,
                                    requiredAccessorialList,
                                    gaCategory: GACategory,
                                    locations,
                                }}
                            />
                        </Grid>
                    )}
                    {currentStep === 1 && (
                        <Grid item container>
                            <Grid
                                item
                                container
                                className={classes.stepContainer}
                            >
                                <QuickRateSummary
                                    handleEdit={handleEdit}
                                    formValues={formValues}
                                    pickupAccessorialsList={
                                        pickupAccessorialsList
                                    }
                                    deliveryAccessorialsList={
                                        deliveryAccessorialsList
                                    }
                                    intl={intl}
                                />
                            </Grid>
                            <Grid
                                item
                                container
                                className={classes.stepContainer}
                            >
                                <Rates
                                    {...{
                                        isQuickRate: true,
                                        guaranteedList,
                                        activeQuote,
                                        changeField,
                                        inputProps,
                                        textInputProps,
                                        permissions:
                                            formValues?.selectedLocation
                                                ?.users[0].permissions,
                                        setIsLoading,
                                        shipmentId,
                                        initialValues,
                                        formValues,
                                        handleComplete,
                                        trackGA,
                                        setEditMode,
                                        onClickReRunRates,
                                        setIsPieceWeightDialogOpen,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </form>
    )
}

const mapStateToProps = (state, props) => {
    const locations = state?.user?.profile?.locations ?? []

    const formValues = getFormValues("quickRate")(state)

    const formattedLocations = locations
        .filter(
            item =>
                item?.users[0]?.permissions?.createRates?.value &&
                !item?.users[0]?.permissions?.suspended?.value &&
                !item.isPending
        )
        .map(item => {
            const { city, state: addressState } =
                item?.shippingAddress?.address ?? {}
            const defaultNickname = `${item?.shippingAddress?.name} - ${city}, ${addressState}`
            return {
                label: constructLabel(item.cpgName, defaultNickname),
                subLabel: constructSubLabel(
                    item.fedexFreightAccount,
                    item.fedexBillToAccount,
                    props.intl,
                    item.locationType === "FEDEX_DIRECT" ||
                        item.locationType === "FEDEX_DIRECT_RETURNS"
                ),
                thirdparty: item.is3rdPartyEnabled,
                value: item.cpgCode,
                fedexBillToAccount: item.fedexBillToAccount,
                fedexFreightAccount: item.fedexFreightAccount,
                isDefault: item.isDefault,
                isFreightDirect: item.locationType === "FEDEX_DIRECT",
                isFreightDirectReturns:
                    item.locationType === "FEDEX_DIRECT_RETURNS",
                isFreightBox: item.locationType === "FEDEX_FREIGHT_BOX",
                fedexFreightAccountType: item?.fedexFreightAccountType,
                fedexBillToAccountType: item?.fedexBillToAccountType,
                carriers: item?.carriers ?? [],
            }
        })
    const shipmentId = props?.computedMatch?.params?.shipmentId
    const language = state?.user?.profile?.preferences?.language

    const contactSearchInProgress = state?.contact?.searchInProgress

    const originCountry = formValues?.origin?.shippingAddress?.address?.country
    const destinationCountry =
        formValues?.destination?.shippingAddress?.address?.country

    const basePickupAccessorialList = state.accessorials.list.pickup.filter(
        x =>
            originCountry !== "MX" ||
            formattedPriorityPickupAccessorials.some(y => y?.value === x?.value)
    )

    const baseDeliveryAccessorialList = state.accessorials.list.delivery.filter(
        x =>
            destinationCountry !== "MX" ||
            formattedPriorityDeliveryAccessorials.some(
                y => y?.value === x?.value
            )
    )

    const quickRateSyncErrors = state?.form?.quickRate?.syncErrors

    return {
        shipmentId,
        contactSearchInProgress,
        locations,
        formattedLocations,
        initialValues: formSelector(state, props),
        formValues,
        quickRateSyncErrors,
        guaranteedList: state.accessorials.list.guaranteed,
        activeQuote: state?.redesign?.activeRates[shipmentId],
        pickupAccessorialsList: sortByPriority(
            basePickupAccessorialList.map(entry => ({
                value: entry?.value,
                label: entry?.label[language],
            })),
            formattedPriorityPickupAccessorials
        ),
        deliveryAccessorialsList: sortByPriority(
            baseDeliveryAccessorialList.map(entry => ({
                value: entry?.value,
                label: entry?.label[language],
            })),
            formattedPriorityDeliveryAccessorials
        ),
        itemsIsLoaded: state.item.isLoaded,
        favoriteItems: (state.item.elements ?? []).slice(1, 51),
        originCities:
            state?.postalCode[
                `${originCountry}-${formValues?.origin?.shippingAddress?.address?.postalCode}`
            ]?.cities,
        destinationCities:
            state?.postalCode[
                `${destinationCountry}-${formValues?.destination?.shippingAddress?.address?.postalCode}`
            ]?.cities,
        offShoreZipcodeSpecialHandling:
            state?.offshoreRating?.zipcodeSpecialHandling,
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    addAccessorial: (isPickup, accessorial) =>
        dispatch(addAccessorial(isPickup, accessorial)),
    removeAccessorial: accessorial => dispatch(removeAccessorial(accessorial)),
    loadRate: shipmentId => dispatch(populateActiveRate(shipmentId)),
    changeField: (field, value) => dispatch(change("quickRate", field, value)),
    getRatesRequest: async payload => dispatch(requestQuote(payload)),
    loadItems: () => dispatch(requestItems()),
    changeRoute: path => dispatch(changePath(path)),
    resetForm: form => dispatch(reset(form)),
    loadAccessorials: () => dispatch(requestAccessorials()),
    performContactSearch: (value, country) =>
        dispatch(searchContacts(value, "quote", undefined, country)),
    onPostalCodeChange: (value, field, country, fetchTimezone) =>
        dispatch(
            requestPostalCodeQuickRate(value, { field, country, fetchTimezone })
        ),
    doSearch: (value, origin, originAddress) =>
        dispatch(searchItems(value, origin, originAddress)),
    trackGA: (category, action, label, value) =>
        dispatch(trackGAEvent(category, action, label, value)),
    setIsBookingQR: value => dispatch(setIsBookingQuickRate(value)),
    reRunRates: async shipmentId =>
        dispatch(
            copyShipment({
                shipmentId,
                queryVersion: "V2",
                shipAgain: false,
                openSnackbar: false,
            })
        ),
    checkRestrictionsByPostalCode: (country, postalCode) =>
        dispatch(checkRestrictionsByPostalCode(country, postalCode)),
    openModal: async node => dispatch(openModal(node)),
    closeModal: () => {
        dispatch(closeModal())
    },
    checkZipcodeSpecialHandling: (zipcode, state) =>
        dispatch(getZipcodeSpecialHandling(zipcode, state)),
})

export const quickRateFormValidation = (values, props) => {
    const mainValidation = combineValidators({
        billToLocation: isRequired({
            field: {
                id: "getRates.form__billToLocation",
                defaultMessage: "Bill to Location",
            },
        }),
        pickupDate: isRequired({
            field: {
                id: "getRates.form__shipDate",
                defaultMessage: "Ship Date",
            },
        }),
        origin: {
            shippingAddress:
                values?.mode === "outbound"
                    ? addressValidator("origin.shippingAddress")
                    : addressQuickRateValidator("origin.shippingAddress"),
            searchContact: isRequiredIfAddressEmpty("origin")({
                field: {
                    id: "bookShipment.form__zipOrCompanyName",
                    defaultMessage: "Search Zip or Company Name",
                },
            }),
        },
        destination: {
            shippingAddress:
                values?.mode === "inbound"
                    ? addressValidator("destination.shippingAddress")
                    : addressQuickRateValidator("destination.shippingAddress"),
            searchContact: isRequiredIfAddressEmpty("destination")({
                field: {
                    id: "bookShipment.form__zipOrCompanyName",
                    defaultMessage: "Search Zip or Company Name",
                },
            }),
        },
        isFedexFileEEI: isRequiredIf(
            values =>
                (values.destination?.shippingAddress?.address?.state === "PR" ||
                    values.origin?.shippingAddress?.address?.state === "PR") &&
                values.mode !== "thirdParty"
        )({
            field: {
                id: "destination.EEI",
                defaultMessage: "Electronic export information choice",
            },
        }),
        totalLinearFeet: isRequiredIf(values => {
            return values?.isAdvancedItems
        })({
            field: {
                id: "getRates.form__estLinearFeet",
                defaultMessage: "Est. Linear Feet",
            },
        }),
        ...handlingUnitsValidator,
    })(values, props)
    return merge({}, mainValidation, itemPropertiesFormValidator(values))
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            // a unique name for the form
            form: "quickRate",
            validate: quickRateFormValidation,
            destroyOnUnmount: false,
            forceUnregisterOnUnmount: true,
            enableReinitialize: true,
        })(QuickRate)
    )
)

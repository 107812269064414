import React, { useEffect } from "react"
import moment from "moment"
import "moment-timezone"

import { schema } from "./validation"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import {
    AppBar,
    Box,
    Button,
    Grid,
    Tab,
    Tabs,
    Typography,
} from "@material-ui/core"
import {
    bulkCreateShipments,
    internalBulkCreatePickupShipments,
    timePayload,
} from "../../../actions/book-shipment-requests"
import { useSnackbarContext } from "../../../context/providers/snackbarProvider"
import { getCloseTime, getReadyTime } from "../../util/time-functions"
import { generateTimeList } from "../../book/pickup/form"
import FormTextField from "../../form/react-hook-form/FormTextField"
import FormSelect from "../../form/react-hook-form/FormSelect"
import FormDatePicker from "../../form/react-hook-form/FormDatePicker"
import { FormattedMessage } from "react-intl"
import { isFedExCarrier } from "../../../misc"
import { addShipment } from "../../../actions/track"
import { useDispatch } from "react-redux"
const SchedulePickupForm = ({
    handleClose,
    submitRef,
    successBookedShipments,
    setSuccessBookedShipments,
    setSendingBulkRequest,
    userLocations,
}) => {
    const dispatch = useDispatch()

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) })
    const { openSnackbar } = useSnackbarContext()

    const groupShipmentsBy = (array, property) => {
        let hash = {},
            props = property.split(".")
        for (let i = 0; i < array.length; i++) {
            let key = props.reduce(function(acc, prop) {
                return acc && acc[prop]
            }, array[i])
            if (!hash[key]) hash[key] = []
            hash[key].push(array[i])
        }
        return hash
    }

    const groupShipmentsByOrigin = shipments => {
        return Object.values(
            shipments.reduce((all, curr) => {
                const key = curr?.shipment
                    ? curr.shipment?.origin?.name +
                      curr.shipment?.origin?.address?.postalCode +
                      curr.shipment?.origin?.address?.state +
                      curr.shipment?.origin?.address?.street1 +
                      curr.shipment?.origin?.address?.street2
                    : curr.search?.origin?.shippingAddress?.name +
                      curr.search?.origin?.shippingAddress?.address
                          ?.postalCode +
                      curr.search?.origin?.shippingAddress?.address?.state +
                      curr.search?.origin?.shippingAddress?.address?.street1 +
                      curr.search?.origin?.shippingAddress?.address?.street2

                ;(all[key] || (all[key] = [])).push(curr)
                return all
            }, {})
        )
    }

    const shipments = successBookedShipments?.map(shipment => {
        if (shipment.cpg) {
            return shipment
        }
        shipment.cpg = shipment?.query.cpg
        return shipment
    })

    let groupedByCpg = Object.values(groupShipmentsBy(shipments, "cpg"))

    const getUniqueId = (indexCpg, indexGroup, indexGroupSize) => {
        return indexCpg * indexGroupSize + indexGroup
    }

    const submitPickupForm = async data => {
        setSendingBulkRequest(true)

        const fedexPickUpProccess = async (index, group) => {
            if (group?.length === 0) return null
            try {
                const payload = {
                    ...data?.schedule[index],
                    pickupDate: moment(data?.schedule[index]?.pickupDate)
                        .tz("UTC")
                        .format("YYYY-MM-DD"),
                    pickupInformation: {
                        readyTime: timePayload(
                            data?.schedule[index]?.pickupInformation?.readyTime
                        ),
                        closeTime: timePayload(
                            data?.schedule[index]?.pickupInformation?.closeTime
                        ),
                    },
                }
                const result = await internalBulkCreatePickupShipments({
                    details: { ...payload },
                    internalTrackingNumbers: group.map(
                        shipment =>
                            shipment?.identifiers?.internalTrackingNumber
                    ),
                    cpg: group[0]?.query?.cpg,
                })
                if (result?.error?.length > 0) {
                    let shipments = group.filter(shipment =>
                        result?.error?.some(shipmentList =>
                            shipmentList?.internalTrackingNumber?.includes(
                                shipment?.identifiers?.internalTrackingNumber
                            )
                        )
                    )
                    return {
                        shipments,
                        message: result?.error.find(shipmentList =>
                            shipmentList?.internalTrackingNumber?.includes(
                                shipments[0]?.identifiers
                                    ?.internalTrackingNumber
                            )
                        )?.message,
                    }
                }
                return null
            } catch (err) {
                return {
                    shipments: group,
                    message: err?.message || err,
                }
            }
        }

        const p44PickUpProccess = async (index, group) => {
            const getBookRequest = async payload => {
                try {
                    const result = await bulkCreateShipments(payload, false)
                    dispatch(addShipment(result))
                    return result
                } catch (error) {
                    if (error?.message) {
                        throw error?.message?.errorMessage || error?.message
                    } else {
                        throw error
                    }
                }
            }

            const bookShipments = async rates => {
                try {
                    const shipmentResults = await Promise.all(
                        rates.map(async order => {
                            try {
                                order.search.origin.readyTime =
                                    data?.schedule[
                                        index
                                    ]?.pickupInformation?.readyTime

                                order.search.origin.closeTime =
                                    data?.schedule[
                                        index
                                    ]?.pickupInformation?.closeTime

                                order.pickupDate =
                                    data?.schedule[index]?.pickupDate

                                order.search.origin.pickupContact = {
                                    ...order?.search.origin?.pickupContact,
                                    ...data?.schedule[index]?.contact,
                                }

                                order.origin.note =
                                    data?.schedule[index]?.remarks

                                const result = await getBookRequest(order)
                                return {
                                    result,
                                    successful: true,
                                }
                            } catch (err) {
                                return {
                                    error: err?.message || err,
                                    successful: false,
                                    rate: order,
                                }
                            }
                        })
                    )

                    return shipmentResults
                } catch (err) {
                    throw err
                }
            }

            try {
                const results = await bookShipments(group)

                const shipmentsWithErrors = results.filter(
                    result => !result?.successful
                )

                if (shipmentsWithErrors.length > 0) {
                    let shipments = group.filter(shipment =>
                        shipmentsWithErrors.some(result =>
                            result?.rate?.identifiers?.internalTrackingNumber.includes(
                                shipment?.identifiers?.internalTrackingNumber
                            )
                        )
                    )
                    return {
                        shipments,
                        message: shipmentsWithErrors[0]?.error,
                    }
                }
                return null
            } catch (err) {
                return {
                    shipments: group,
                    message: err,
                }
            }
        }

        try {
            let pickUpResults = await Promise.all(
                groupedByCpg.map(async (shipments, indexCpg) => {
                    let groupedByOrigin = groupShipmentsByOrigin(shipments)
                    return await Promise.all(
                        groupedByOrigin.flatMap(
                            async (group, indexGroup, list) => {
                                const index = getUniqueId(
                                    indexCpg,
                                    indexGroup,
                                    list.length
                                )

                                //split p44 and fedex

                                let fedexGroupResult = await fedexPickUpProccess(
                                    index,
                                    group.filter(shipment =>
                                        isFedExCarrier(
                                            shipment?.shipment?.carrier
                                        )
                                    )
                                )

                                let p44GroupResult = await p44PickUpProccess(
                                    index,
                                    group.filter(
                                        shipment =>
                                            !shipment?.shipment &&
                                            !isFedExCarrier(
                                                shipment?.selectedRate
                                                    ?.carrierCode
                                            )
                                    )
                                )

                                return [fedexGroupResult, p44GroupResult]
                            }
                        )
                    )
                })
            )

            pickUpResults = pickUpResults
                .flat(Infinity)
                .filter(value => value !== null)
            if (pickUpResults.length == 0) {
                setSuccessBookedShipments(new Array())
                handleClose()
            } else {
                pickUpResults
                    ?.map(result => result?.message)
                    .forEach(message => openSnackbar("error", message))

                setSuccessBookedShipments(
                    pickUpResults?.map(result => result?.shipments).flat()
                )
            }
        } catch (err) {
            throw err
        } finally {
            setSendingBulkRequest(false)
            setTabValue(0)
        }
    }

    const a11yProps = (id, index) => {
        return {
            id: `scrollable-auto-tab-${index}-${id}`,
            "aria-controls": `scrollable-auto-tabpanel-${index}-${id}`,
        }
    }

    const [tabValue, setTabValue] = React.useState(0)

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
    }

    let tabs = []
    groupedByCpg.map(shipments => {
        groupShipmentsByOrigin(shipments).forEach((group, index) => {
            let name = group[0]?.shipment
                ? group[0]?.shipment?.origin?.name
                : group[0]?.search?.origin?.shippingAddress?.name
            tabs.push(<Tab label={`${name}`} {...a11yProps(name, index)} />)
        })
    })

    const TabPanel = props => {
        const { children, value, index, shipment, ...other } = props
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                <Box p={3}>
                    <Grid item alignContent="center" alignItems="center">
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="generalTerms__origin"
                                defaultMessage="Origin"
                            />
                        </Typography>
                        <Typography variant="subtitle1">
                            {`${
                                shipment?.shipment
                                    ? shipment?.shipment?.origin?.address
                                          ?.street1
                                    : shipment?.search?.origin?.shippingAddress
                                          ?.address?.street1
                            } ${
                                shipment?.shipment
                                    ? shipment?.shipment?.origin?.address
                                          ?.street2 ?? ""
                                    : shipment?.search?.origin?.shippingAddress
                                          ?.address?.street2 ?? ""
                            }`}
                        </Typography>
                        <Typography variant="subtitle1">
                            {`${
                                shipment?.shipment
                                    ? shipment?.shipment?.origin?.address?.state
                                    : shipment?.search?.origin?.shippingAddress
                                          ?.address?.state
                            } ${
                                shipment?.shipment
                                    ? shipment?.shipment?.origin?.address
                                          ?.postalCode
                                    : shipment?.search?.origin?.shippingAddress
                                          ?.address?.postalCode
                            }`}
                        </Typography>
                        <br />
                    </Grid>
                    {children}
                </Box>
            </div>
        )
    }

    useEffect(() => {
        for (let i = 0; i < errors?.schedule?.length; i++) {
            if (errors.schedule[i]) {
                setTabValue(i)
            }
        }
    }, [errors])

    return (
        <Box sx={{ width: "500px", mt: "10px", mb: "10px" }}>
            <form noValidate onSubmit={handleSubmit(submitPickupForm)}>
                <AppBar position="static" color="primary">
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="secondary"
                        textColor="white"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {tabs}
                    </Tabs>
                </AppBar>
                {groupedByCpg
                    .map((shipments, indexCpg) => {
                        return groupShipmentsByOrigin(shipments)
                            .map((group, indexGroup, list) => {
                                const index = getUniqueId(
                                    indexCpg,
                                    indexGroup,
                                    list.length
                                )

                                const condition = group[0]?.shipment
                                    ? group[0]?.query?.cpg
                                    : group[0]?.cpg

                                const locationSelected = userLocations.find(
                                    location => location?.cpgCode === condition
                                )
                                return (
                                    <TabPanel
                                        value={tabValue}
                                        index={index}
                                        shipment={group[0]}
                                    >
                                        <React.Fragment>
                                            <FormDatePicker
                                                autoFocus
                                                required
                                                name={`schedule.${index}.pickupDate`}
                                                style={{
                                                    width: "100%",
                                                    paddingRight: "10px",
                                                }}
                                                label={
                                                    <FormattedMessage
                                                        id="orders.pickup.pickupDate"
                                                        defaultMessage="Pickup Date"
                                                    />
                                                }
                                                control={control}
                                                error={!!errors?.pickupDate}
                                                helperText={
                                                    errors?.pickupDate
                                                        ? errors?.pickupDate
                                                              ?.message
                                                        : ""
                                                }
                                                minDate={moment.utc(
                                                    moment()
                                                        .tz("Pacific/Honolulu")
                                                        .format("YYYY-MM-DD")
                                                )}
                                                maxDate={moment
                                                    .utc(
                                                        moment()
                                                            .tz(
                                                                "Pacific/Honolulu"
                                                            )
                                                            .format(
                                                                "YYYY-MM-DD"
                                                            )
                                                    )
                                                    .add(1, "year")}
                                            />
                                            <FormSelect
                                                name={`schedule.${index}.pickupInformation.readyTime`}
                                                control={control}
                                                options={generateTimeList()}
                                                width={50}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 200,
                                                        },
                                                    },
                                                }}
                                                label={
                                                    <FormattedMessage
                                                        id="generalTerms.pickUpInformation__readyTime"
                                                        defaultMessage="Ready Time"
                                                    />
                                                }
                                                defaultValue={
                                                    locationSelected?.readyTime ??
                                                    getReadyTime()
                                                }
                                            />
                                            <FormSelect
                                                name={`schedule.${index}.pickupInformation.closeTime`}
                                                control={control}
                                                options={generateTimeList()}
                                                defaultValue={
                                                    locationSelected?.closeTime ??
                                                    getCloseTime()
                                                }
                                                width={50}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 200,
                                                        },
                                                    },
                                                }}
                                                label={
                                                    <FormattedMessage
                                                        id="generalTerms.pickUpInformation__closeTime"
                                                        defaultMessage="Close Time"
                                                    />
                                                }
                                            />
                                            <FormTextField
                                                control={control}
                                                width={100}
                                                required
                                                name={`schedule.${index}.contact.name`}
                                                label={
                                                    <FormattedMessage
                                                        id="orders.pickup.contactName"
                                                        defaultMessage="Pickup Contact Name"
                                                    />
                                                }
                                                defaultValue={
                                                    locationSelected
                                                        ?.pickupContact?.name ??
                                                    ""
                                                }
                                            />
                                            <FormTextField
                                                control={control}
                                                type="phone"
                                                width={40}
                                                required
                                                name={`schedule.${index}.contact.phone.phone_number`}
                                                label={
                                                    <FormattedMessage
                                                        id="orders.pickup.phone"
                                                        defaultMessage="Pickup Contact Phone"
                                                    />
                                                }
                                                defaultValue={
                                                    locationSelected
                                                        ?.pickupContact?.phone
                                                        .phone_number ?? ""
                                                }
                                            />
                                            <FormTextField
                                                control={control}
                                                width={20}
                                                name={
                                                    "schedule.0.contact.phone.extension"
                                                }
                                                type="ext"
                                                label={
                                                    <FormattedMessage
                                                        id="orders.pickup.extension"
                                                        defaultMessage="Ext."
                                                    />
                                                }
                                                defaultValue={
                                                    locationSelected
                                                        ?.pickupContact?.phone
                                                        ?.extension ?? ""
                                                }
                                            />
                                            <FormTextField
                                                control={control}
                                                width={40}
                                                required
                                                name={`schedule.${index}.contact.email.email_address`}
                                                label={
                                                    <FormattedMessage
                                                        id="orders.pickup.email"
                                                        defaultMessage="Pickup Contact Email"
                                                    />
                                                }
                                                defaultValue={
                                                    locationSelected
                                                        ?.pickupContact?.email
                                                        ?.email_address ?? ""
                                                }
                                            />
                                            <FormTextField
                                                control={control}
                                                width={100}
                                                name={`schedule.${index}.remarks`}
                                                maxLength={200}
                                                label={
                                                    <FormattedMessage
                                                        id="orders.pickup.remarks"
                                                        defaultMessage="Pickup Remarks"
                                                    />
                                                }
                                            />
                                        </React.Fragment>
                                    </TabPanel>
                                )
                            })
                            .flat(1)
                    })
                    .flat(1)}
                {/* This is a hidden submit button because the form needs to live inside of the DialogContent to maintain styles. 
                        It uses a ref that submits the form when the Update button is pushed*/}
                <Button
                    ref={submitRef}
                    style={{ display: "none" }}
                    type="submit"
                />
            </form>
        </Box>
    )
}

export default SchedulePickupForm

import Pusher from "pusher-js"
import PusherBatchAuthorizer from "pusher-js-auth"
import pako from "pako"
import { get } from "lodash"
import { IntercomAPI } from "react-intercom"
import { goFetch, authHeader } from "../http"
import { errorMessage } from "./index"
import { getShareKey } from "../misc"
import { trackGAEvent } from "./user"
import {
    chatForbidden /* chatSubscriptionError */,
} from "../messages/error-constants"
import { retrieveUser, userReceived, forceGetUserCarriers } from "./user"

export const CHAT_RECEIVED = "CHAT_RECEIVED"
export const CHAT_EVENT_RECEIVED = "CHAT_EVENT_RECEIVED"
export const SHIPMENT_EVENT_RECEIVED = "SHIPMENT_EVENT_RECEIVED"
export const CHAT_HISTORY_RECEIVED = "CHAT_HISTORY_RECEIVED"
export const CHAT_QUOTE_RECEIVED = "CHAT_QUOTE_RECEIVED"
export const CHAT_SET_CHANNEL = "CHAT_SET_CHANNEL"
export const CHAT_MARK_READ = "CHAT_MARK_READ"
export const CHAT_MESSAGE_CHANGE = "CHAT_MESSAGE_CHANGE"
export const CHAT_CHANNEL_STATUS_RECEIVED = "CHAT_CHANNEL_STATUS_RECEIVED"
export const CHANNEL_LIST_RECEIVED = "CHANNEL_LIST_RECEIVED"
export const PUSHER_SUBSCRIPTION = "PUSHER_SUBSCRIPTION"
export const CHAT_ADD_PHONE_NUMBER = "CHAT_ADD_PHONE_NUMBER"
export const CHAT_REMOVE_CHANNEL = "CHAT_REMOVE_CHANNEL"
export const ADD_CONTACT_CHANNEL = "ADD_CONTACT_CHANNEL"
export const ON_CHAT_VIEW_CHANGE = "ON_CHAT_VIEW_CHANGE"
export const CHAT_MARK_REMOTE_READ = "CHAT_MARK_REMOTE_READ"
export const CHAT_ONLINE_STATE_CHANGE = "CHAT_ONLINE_STATE_CHANGE"
export const CHAT_SENDING_MESSAGE = "CHAT_SENDING_MESSAGE"
export const TRACKING_EVENTS_RECEIVED = "TRACKING_EVENTS_RECEIVED"
export const ALERT_DISMISS = "ALERT_DISMISS"
export const ALERT_EVENT = "ALERT_EVENT"

const NETWORK_ERROR_RETRY_TIME_MS = 10000

Pusher.logToConsole = window._env_.REACT_APP_ENABLE_LOGGING === "enabled"

let _internalPusher
export function getPusher(state) {
    if (_internalPusher) return _internalPusher
    _internalPusher = new Pusher(window._env_.REACT_APP_PUSHER_APP_KEY, {
        authorizer: PusherBatchAuthorizer,
        authEndpoint: `${window._env_.REACT_APP_BACKEND_URL}${window._env_.REACT_APP_BACKEND_VERSION}/notification/authenticate`,
        auth: { headers: authHeader(getShareKey(state)) },
        encrypted: true,
        authDelay: 200,
    })
    return _internalPusher
}

function isChannelLoaded(state, channel) {
    const channelData = state.chat.channels[channel]
    return channelData && channelData.statusFetched
}

function isPusherSubscribed(state, channel) {
    const channelData = state.chat.channels[channel]
    return channelData && channelData.pusherSubscribed
}

export const showIntercom = () => IntercomAPI("show")

export function transformMessage(state, message, channel, selfId) {
    if (!message || !channel || !state) return undefined
    const user = state.user.profile
    const channelData = state.chat.channels[channel] || {}
    const oldId = (user && user._id) || getShareKey(state)
    const newId = selfId || channelData.selfId
    const me =
        !!message.senderId &&
        ((!!oldId && message.senderId === oldId) ||
            (!!newId && message.senderId === newId))
    return { ...message, me }
}

export function markRead(channel, sendToServer = true) {
    return async (dispatch, getState) => {
        dispatch({ type: CHAT_MARK_READ, channel })
        if (!getState().chat.channels[channel]) return
        const messages = getState().chat.channels[channel].messages
        if (!messages || messages.length === 0) return
        const lastSeen = messages[messages.length - 1].created_at
        if (sendToServer) {
            try {
                await goFetch(
                    `/channel/${channel}`,
                    {
                        method: "PUT",
                        credentials: "same-origin",
                        headers: {
                            "cache-control": "no-cache",
                        },
                        data: { lastSeen },
                    },
                    true,
                    getShareKey(getState())
                )
            } catch (error) {
                errorMessage(error)
            }
        }
    }
}

function messageReceived(channel, message) {
    return (dispatch, getState) => {
        const internalMessage = transformMessage(getState(), message, channel)
        dispatch({
            type: CHAT_RECEIVED,
            channel,
            message: { ...internalMessage, channel },
        })
        if (internalMessage.me) dispatch(markRead(channel, false))
    }
}

const eventReceived = (channel, message) => ({
    type: CHAT_EVENT_RECEIVED,
    channel,
    message,
})

const locationUpdate = (channel, message) => async dispatch => {
    const user = await retrieveUser()
    dispatch(userReceived(user))
}

function shipmentEventReceived(channel, message) {
    return dispatch => {
        const compressedData = get(message, "metadata")
        let status = {}
        if (compressedData) {
            const binaryString = atob(compressedData)
            const arrayBuffer = Uint8Array.from(binaryString.split(""), x =>
                x.charCodeAt(0)
            )
            const inflatedBuffer = pako.inflate(arrayBuffer)
            const jsonString = String.fromCharCode.apply(
                null,
                new Uint16Array(inflatedBuffer)
            )
            status = JSON.parse(jsonString)
            // const statusHistory = get(status, 'statusUpdateHistory', []);
            // dispatch(populateTrackingEvents(channel, statusHistory));
        }
        const msg = get(message, "message")
        dispatch({ type: SHIPMENT_EVENT_RECEIVED, channel, status })
        if (msg) dispatch(eventReceived(channel, msg))
    }
}

function alertEventReceived(channel, message) {
    return dispatch => {
        const compressedData = get(message, "metadata")
        let msg = {}
        if (compressedData) {
            const binaryString = atob(compressedData)
            const arrayBuffer = Uint8Array.from(binaryString.split(""), x =>
                x.charCodeAt(0)
            )
            const inflatedBuffer = pako.inflate(arrayBuffer)
            const jsonString = String.fromCharCode.apply(
                null,
                new Uint16Array(inflatedBuffer)
            )
            msg = JSON.parse(jsonString)
        }
        dispatch({ type: ALERT_EVENT, payload: msg })
    }
}

const alertDismiss = (channel, message) => ({
    type: ALERT_DISMISS,
    payload: message,
})

const quoteReceived = (channel, message) => ({
    type: CHAT_QUOTE_RECEIVED,
    channel,
    message,
})

const channelListReceived = kind => ({ type: CHANNEL_LIST_RECEIVED, kind })

function channelStatusReceived(state, channel, data) {
    const mappedUnread = (data.unread || []).reduce(
        (obj, item) => ({ ...obj, [item._id]: item.count }),
        {}
    )
    const mappedMsg = transformMessage(state, data.message, channel, data._id)
    return {
        type: CHAT_CHANNEL_STATUS_RECEIVED,
        channel,
        unread: mappedUnread,
        message: mappedMsg,
        users: data.users,
        selfId: data._id,
    }
}

function channelReceived(state, channel, messages) {
    const mappedMsgs = (messages || []).map(item =>
        transformMessage(state, item, channel)
    )
    return { type: CHAT_HISTORY_RECEIVED, channel, messages: mappedMsgs }
}

const onlineStateChange = (channel, ids, online) => ({
    type: CHAT_ONLINE_STATE_CHANGE,
    ids,
    online,
    channel,
})

const markRemoteRead = channel => ({ type: CHAT_MARK_REMOTE_READ, channel })

function onPusherSubscription(chnl, channel, members) {
    return dispatch => {
        // We only bind on successful subscription
        const memberIds = []
        members.each(member => memberIds.push(member._id))
        dispatch(onlineStateChange(channel, memberIds, true))
        ;[
            ["pusher:member_added", true],
            ["pusher:member_removed", false],
        ].map(([name, online]) =>
            chnl.bind(name, ({ id }) =>
                dispatch(onlineStateChange(channel, [id], online))
            )
        )
        ;[
            { name: "message", cb: messageReceived },
            { name: "shipment-event", cb: shipmentEventReceived },
            { name: "alert-event", cb: alertEventReceived },
            { name: "alert-dismiss", cb: alertDismiss },
            { name: "location-update", cb: locationUpdate },
            { name: "carrier-updated", cb: forceGetUserCarriers },
            { name: "event", cb: eventReceived },
            { name: "quote", cb: quoteReceived },
            { name: "received", cb: markRemoteRead },
        ].map(({ name, cb }) =>
            chnl.bind(name, msg => {
                dispatch(cb(channel, msg))
            })
        )
    }
}

function onPusherSubscriptionError(chnl, channel, error) {
    return dispatch => {
        chnl.unsubscribe()
        chnl.unbind()
        dispatch({ type: PUSHER_SUBSCRIPTION, channel, value: false })
        if ([408, 503, 0].includes(error)) {
            // Network issue, retry
            setTimeout(
                () => dispatch(attachPusher(channel)),
                NETWORK_ERROR_RETRY_TIME_MS
            )
        } else if (error === 403) {
            dispatch(errorMessage({ message: chatForbidden }))
        } else {
            dispatch(
                trackGAEvent("Chat", "Subscription error", undefined, error)
            )
            // This will be disabled until we can identify what causes the unhandled errors
            // dispatch(errorMessage({ message: chatSubscriptionError }));
        }
    }
}

export function attachPusher(channel) {
    return (dispatch, getState) => {
        const presenceChannel = `presence-${channel}`
        if (isPusherSubscribed(getState(), channel)) return
        const pusher = getPusher(getState())
        let pusherChannel = pusher.channel(presenceChannel)
        if (pusherChannel) {
            pusherChannel.subscribe()
        } else {
            pusherChannel = pusher.subscribe(presenceChannel)
        }
        pusherChannel.bind("pusher:subscription_succeeded", members =>
            dispatch(onPusherSubscription(pusherChannel, channel, members))
        )
        pusherChannel.bind("pusher:subscription_error", status =>
            dispatch(onPusherSubscriptionError(pusherChannel, channel, status))
        )
        dispatch({ type: PUSHER_SUBSCRIPTION, channel, value: true })
    }
}

async function fetchMessages(channel, shareKey) {
    const { data, status } = await goFetch(
        "/message",
        { params: { channel }, validErrorCodes: [404] },
        true,
        shareKey
    )
    return status === 404 ? null : data
}

export function loadChannelHistory(channel) {
    return async (dispatch, getState) => {
        try {
            const state = getState()
            const { historyReceived } = state.chat.channels[channel] || {}
            if (!historyReceived) {
                const messages = await fetchMessages(
                    channel,
                    getShareKey(state)
                )
                dispatch(channelReceived(state, channel, messages))
            }
        } catch (error) {
            dispatch(errorMessage(error))
        }
    }
}

export function loadChannel(channel) {
    return async (dispatch, getState) => {
        try {
            const state = getState()
            if (isChannelLoaded(state, channel)) return
            const { unreadFetched } = state.chat.channels[channel] || {}
            if (unreadFetched) return
            const { data } = await goFetch(
                `/channel/${channel}`,
                {},
                true,
                getShareKey(state)
            )
            dispatch(channelStatusReceived(state, channel, data))
            dispatch(attachPusher(channel))
        } catch (error) {
            dispatch(errorMessage(error))
        }
    }
}

export function getChannelStatuses(type) {
    return async (dispatch, getState) => {
        try {
            const { authFinished } = getState().user
            if (getState().chat.metadata.listFetched[type] || !authFinished)
                return
            const { data } = await goFetch(
                "/channel",
                { params: { type } },
                true
            )
            dispatch(channelListReceived(type))
            await Promise.all(
                Object.keys(data).map(async channel => {
                    dispatch(
                        channelStatusReceived(
                            getState(),
                            channel,
                            data[channel]
                        )
                    )
                    dispatch(attachPusher(channel))
                })
            )
        } catch (error) {
            dispatch(errorMessage(error))
        }
    }
}

export function setChannel(channelName) {
    return async (dispatch, getState) => {
        if (getState().chat.active === channelName) return
        dispatch({ type: CHAT_SET_CHANNEL, channel: channelName })
        if (channelName) {
            await dispatch(loadChannel(channelName))
            await Promise.all([
                dispatch(loadChannelHistory(channelName)),
                dispatch(markRead(channelName)),
            ])
        }
    }
}

export const messageChange = message => ({ type: CHAT_MESSAGE_CHANGE, message })

export function sendMessage(channelName) {
    return async (dispatch, getState) => {
        const state = getState()
        const message = state.chat.current.message
        if (!message.trim()) return
        const data = {
            message,
            firstname: state.user.profile.firstname,
        }
        if (channelName.length === 5 || channelName.indexOf("DM") > -1)
            data.channel = channelName
        if (state.chat.contactChannel.contactId) {
            data.contactId = state.chat.contactChannel.contactId
        }
        if (state.chat.channels.isPhoneValid) {
            data.phoneNumber = state.chat.channels.phoneNumber
        }
        dispatch({ type: CHAT_SENDING_MESSAGE, inProgress: true })
        try {
            const { data: response } = await goFetch(
                "/message",
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data,
                },
                true,
                getShareKey(state)
            )
            const { channel } = response
            if (channelName !== channel) {
                // This ensures we register newly created DM channels
                await dispatch(setChannel(channel))
            }
            dispatch({
                type: CHAT_SENDING_MESSAGE,
                inProgress: false,
                success: true,
            })
        } catch (error) {
            dispatch({
                type: CHAT_SENDING_MESSAGE,
                inProgress: false,
                success: false,
                message,
            })
            errorMessage(error)
        }
    }
}

export const addPhoneNumber = values => (dispatch, getState) => {
    const state = getState()
    const channels = state.chat.channels
    const channel = Object.keys(channels)
        .filter(key => key.indexOf("DM") > -1)
        .find(id => channels[id].channelName === values.phoneNumber)
    dispatch(trackGAEvent("Direct Chat", "Compose Phone Number"))
    if (channel) dispatch(setChannel(channel))
    else {
        dispatch({
            type: CHAT_ADD_PHONE_NUMBER,
            activeChannel: values.phoneNumber,
        })
    }
}

export const removeChannel = channel => ({ type: CHAT_REMOVE_CHANNEL, channel })

export const addContactChannel = contact => (dispatch, getState) => {
    const state = getState()
    const channels = state.chat.channels
    const channel = Object.keys(channels)
        .filter(key => key.indexOf("DM") > -1)
        .find(id =>
            channels[id].users.find(
                user =>
                    user.contactId && user.contactId._id === contact.contact.id
            )
        )

    dispatch(trackGAEvent("Direct Chat", "Compose Contact Name"))
    if (channel) dispatch(setChannel(channel))
    else {
        dispatch({ type: ADD_CONTACT_CHANNEL, contact })
    }
}

export const onChatViewChange = view => ({
    type: ON_CHAT_VIEW_CHANGE,
    active: view,
})

export const onChatView = view => dispatch => {
    if (view === "dm") {
        dispatch(trackGAEvent("Direct Chat", "Compose"))
    }
    if (view === "home") {
        dispatch(trackGAEvent("Direct Chat", "Home"))
    }
    if (view === "track") {
        dispatch(trackGAEvent("Direct Chat", "Events"))
    }
    if (view === "chatStream") {
        dispatch(trackGAEvent("Direct Chat", "Open Existing Chat"))
    }
    return dispatch(onChatViewChange(view))
}

import React, { Fragment } from "react"
import Modal from "@material-ui/core/Modal"
import { useAttachmentsContext } from "../providers/attachmentsProvider"
import { ShareAttachments } from "../../components/track/result/attachments/shareAttachments"
import { ManageAttachmentDialog } from "../../components/track/result/attachments/manageAttachmentDialog"

export const AttachmentsModalsWrapper = () => {
    const {
        openDialog,
        manageDialogOpen,
        closeDialog,
        shareDialogOpen,
        shareInitialValues,
        internalTrackingNumber,
        attachments,
        attachmentsLoading,
        contacts,
        managedFile,
        downloadFile,
        deleteFile,
        printFile,
        isFromTrailerManifestPage,
        gaCategory,
    } = useAttachmentsContext()

    return (
        <Fragment>
            <Modal open={manageDialogOpen} onClose={closeDialog}>
                <ManageAttachmentDialog
                    loading={attachmentsLoading}
                    file={managedFile}
                    downloadFile={downloadFile}
                    deleteFile={deleteFile}
                    shipmentId={internalTrackingNumber}
                    onClose={closeDialog}
                    openDialog={openDialog}
                    printFile={printFile}
                    isFromTrailerManifestPage={isFromTrailerManifestPage}
                    gaCategory={gaCategory}
                />
            </Modal>
            <Modal open={shareDialogOpen} onClose={closeDialog}>
                <ShareAttachments
                    attachments={attachments}
                    shipmentId={internalTrackingNumber}
                    onClose={closeDialog}
                    initialValues={shareInitialValues}
                    loading={attachmentsLoading}
                    contacts={contacts}
                    isFromTrailerManifestPage={isFromTrailerManifestPage}
                    managedFile={managedFile}
                    gaCategory={gaCategory}
                />
            </Modal>
        </Fragment>
    )
}

export const rateSchema = {
    value: 0,
    isDisabled: false,
}

export const chargeSchema = {
    value: 0,
    isPercentageOff: false,
}

export const discountSchema = {
    value: 0,
    isPercentage: false,
}

export const matchSchema = (source, target) => {
    if (target) {
        return Object.keys(source).every(key => target.hasOwnProperty(key))
    }
    return false
}

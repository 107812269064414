import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import Scroll from "react-scroll"
import { connect } from "react-redux"
import { reduxForm, FormSection, formValues, change } from "redux-form"
import { get, merge } from "lodash"
import { Redirect } from "react-router-dom"
import { combineValidators, composeValidators } from "revalidate"
import { Card, Grid, Modal, Button, CircularProgress } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"
import {
    isRequired,
    isRequiredIf,
    isNumeric,
    isConsigneeImporter,
    aesNumberValidator,
    hasHazMatIdPrefix,
    nmfcValidator,
    nmfcSubcodeValidator,
    countryPhoneValidator,
    emailValidator,
    hasLength,
    isInternationalShipment,
    isUSDomesticOffshoreShipmentWithItemCustoms,
    itemPropertiesFormValidator,
    requiredNaftaDeclaration,
} from "../../actions/validation"
import Address, { addressValidator } from "./address"
import { contactValidator } from "./contactForm"
import HeaderForm from "./headerForm"
import HazMatForm from "./hazmatForm"
import CustomsBrokerageForm from "./customsBrokerageForm"
import SupportDocumentationForm from "./SupportDocumentationForm"
import Pickup, { pickupValidator } from "./pickup"
import DeliveryForm from "./deliveryForm"
import HandlingUnitsForm from "./handlingUnitsForm"
import BookLoader from "./loader"
import ShipmentSnackbar from "./snackbar"
import {
    acceptAddressSuggestion,
    createShipment,
    newShipmentFlashMessage,
} from "../../actions/book"
import { calcDeliveryTime } from "../util"
import GlobalSpinner from "../common/GlobalSpinner"
import { changePath } from "../../actions/index"
import { isFedExCarrier } from "../../misc"
import { isGuaranteedQuote } from "../quote/card"
import { goFetch } from "../../http"
import AlertsPreferences from "../alerts/alertsPreferences"
import { bookSelector } from "./selectors"
import InBondCard from "./InBondCard"
import { useBookedShipmentContext } from "../../context/providers/BookedShipmentProvider"
import { createCertificateOfOrigin } from "../../actions/book-shipment"
import HazMatDetailsModal from "./HazMatDetailsModal"
import { trackGAEvent } from "../../actions/user"

const scroll = Scroll.animateScroll
const styles = theme => ({
    grid: {
        justify: "space-between",
        margin: "0",
        marginLeft: "-12px",
    },
    item: {
        width: "100%",
    },
    submit: {
        position: "relative",
        marginLeft: "auto",
    },
    submitLoader: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    book: {
        marginBottom: "30px",
    },
    alerts__card: {
        padding: "15px",
        width: "100%",
    },
})

const determineServiceType = type =>
    type === "EXCL" ? (
        <FormattedMessage
            id="orderDetails.serviceType__volumeExclusive"
            defaultMessage="Exclusive Use"
        />
    ) : (
        type
    )

const headerMapStateToProps = (state, props) => ({
    showDeliveryDate: !!props.showDeliveryDate,
    deliveryTime: calcDeliveryTime(props.deliveryDate),
    isVolume: props.isVolume === "VOLUME_LTL",
    isPickUpAndDestroy: props.serviceCode === "BASIC_PICKUP_AND_DESTROY",
    serviceType: determineServiceType(props.serviceType),
    shouldShowAES: props.shouldShowAES,
    isGuaranteed: isGuaranteedQuote(
        props.serviceCode,
        get(state, "accessorials.list.guaranteed")
    ),
})

const BookHeaderForm = formValues({
    pickupDate: "origin.startDate",
    deliveryDate: "rate.deliveryDateTime",
    carrierCode: "rate.carrierCode",
    showDeliveryDate: "rate.transitDays",
    isVolume: "rate.mode",
    serviceType: "rate.serviceLevel.description",
    serviceCode: "rate.serviceLevel.code",
    quoteId: "rate.capacityProviderQuoteNumber",
    isDomesticOffshore: "rate.isDomesticOffshore",
    mode: "rate.mode",
})(connect(headerMapStateToProps)(HeaderForm))

export const BookPresentation = ({
    handleSubmit,
    computedMatch,
    onDispatchShipment,
    changeField,
    classes,
    isHazMat,
    originFixed,
    destinationFixed,
    submitting,
    invalid,
    isIntl,
    isMXToUSIntl,
    isUSMainlandToMXIntl,
    isUSDomesticOffshore,
    isUSDomesticOffshoreWithItemCustoms,
    pristine,
    shipmentId,
    originCountry,
    destinationCountry,
    error,
    isFetching,
    shipped,
    searchInProgress,
    originCompanyName,
    destinationCompanyName,
    preferredCurrencyCode,
    preferredSystemOfMeasurement,
    terminal,
    handlingUnits,
    mode,
    serviceLevel,
    isInBondShipment,
    changeToTerminal,
    handlingUnitsBolForm,
    carrierCode,
    originState,
    destinationState,
    rateMode,
    pickupDate,
    originTimezone,
    hazmatEmergency,
    commercialInvoice,
    location,
    trackGA2,
}) => {
    const [resiCheckInProgress, setResiCheckInProgress] = useState(false)
    const [shouldShowAES, setShouldShowAES] = useState(false)
    const [shouldShowHazMatDetails, setShouldShowHazMatDetails] = useState(
        false
    )
    const [totalPrice, setTotalPrice] = useState(0)

    useEffect(() => {
        trackGA2("Legacy Rates", "Book Page Mount")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        updateShowAES()
        updateTotalPrice()
    })

    const { setShouldOpen } = useBookedShipmentContext()
    const closeHazMatDetailsModal = () => setShouldShowHazMatDetails(false)

    const onBookShipment = async (values, dispatch, props) => {
        trackGA2("Legacy Rates", "Book Shipment Button Clicked")
        const hasHazMatItems =
            handlingUnitsBolForm.filter(
                hu => hu.items.filter(item => item.isHazMat).length > 0
            ).length > 0
        if (hasHazMatItems) {
            trackGA2("Legacy Rates", "HazMat Details Modal Opened")
            setShouldShowHazMatDetails(true)
        } else {
            return onDispatchShipment(values, dispatch, props, setShouldOpen)
        }
    }

    const onBookShipmentWithOutHazMatCheck = async (
        values,
        dispatch,
        props
    ) => {
        trackGA2("Legacy Rates", "HazMat Details Modal - Proceed to Book")
        return onDispatchShipment(values, dispatch, props, setShouldOpen)
    }

    const updateTotalPrice = () => {
        if (commercialInvoice) {
            setTotalPrice(calculateTotalPrice())
        }
    }

    const calculateTotalPrice = () => {
        return handlingUnitsBolForm.reduce(
            (huSum, hu) =>
                huSum +
                hu.items.reduce(
                    (itemSum, item) =>
                        itemSum +
                        (item.unitPrice == null ? 0 : item.unitPrice) *
                            (item.unit == null
                                ? item.pieces == null
                                    ? 1
                                    : item.pieces
                                : item.unit) *
                            hu.count,
                    0
                ),
            0
        )
    }

    const updateShowAES = e => {
        if (
            (isUSDomesticOffshore === true &&
                (originState === "PR" || destinationState === "PR")) ||
            isUSMainlandToMXIntl === true
        ) {
            const value = calculateTotalPrice()
            if (value > 2500) {
                setShouldShowAES(true)
            } else {
                setShouldShowAES(false)
            }
        } else {
            setShouldShowAES(false)
        }
    }

    return shipped ? (
        <Redirect to={`/track/${shipmentId}`} />
    ) : (
        <div className={classes.root}>
            <BookLoader match={computedMatch} />
            {isFetching && <GlobalSpinner />}
            <ShipmentSnackbar variant="error" content={error} />
            <form onSubmit={handleSubmit(onBookShipment)}>
                <Grid container spacing={3} className={classes.grid}>
                    <BookHeaderForm />
                    <FormSection name="origin" component={Fragment}>
                        <Address
                            title={
                                <FormattedMessage
                                    id="generalTerms__origin"
                                    defaultMessage="Origin"
                                />
                            }
                            readOnly={originFixed}
                            pristine={pristine}
                            searchInProgress={searchInProgress}
                            mode={"origin"}
                            searchTerm={originCompanyName}
                            setResiCheckInProgress={() => {}}
                            pickupDate={pickupDate}
                            originTimezone={originTimezone}
                        />
                    </FormSection>
                    <FormSection name="destination" component={Fragment}>
                        <Address
                            title={
                                <FormattedMessage
                                    id="generalTerms__destination"
                                    defaultMessage="Destination"
                                />
                            }
                            readOnly={destinationFixed}
                            pristine={pristine}
                            searchInProgress={searchInProgress}
                            mode={"destination"}
                            searchTerm={destinationCompanyName}
                            setResiCheckInProgress={setResiCheckInProgress}
                            serviceLevel={serviceLevel}
                            changeToTerminal={changeToTerminal}
                        />
                    </FormSection>
                    <FormSection name="origin" component={Fragment}>
                        <Pickup
                            shipmentId={shipmentId}
                            pristine={pristine}
                            terminal={terminal}
                            mode={mode}
                            country={originCountry}
                            isInBondShipment={isInBondShipment}
                            carrierCode={carrierCode}
                            rateMode={rateMode}
                        />
                    </FormSection>
                    <FormSection name="destination" component={Fragment}>
                        <DeliveryForm
                            shipmentId={shipmentId}
                            mode={mode}
                            serviceLevel={serviceLevel}
                            isInBondShipment={isInBondShipment}
                        />
                    </FormSection>
                    {isMXToUSIntl && (
                        <FormSection
                            name="exportCustomsBrokerageInfo"
                            component={Fragment}
                        >
                            <CustomsBrokerageForm
                                isRequired={isMXToUSIntl}
                                label={
                                    <FormattedMessage
                                        id="orderDetails.customs__export__title"
                                        defaultMessage="Export Customs Brokerage Information"
                                    />
                                }
                                country={originCountry}
                            />
                        </FormSection>
                    )}
                    {(isIntl || isUSDomesticOffshore) && (
                        <FormSection
                            name="importCustomsBrokerageInfo"
                            component={Fragment}
                        >
                            <CustomsBrokerageForm
                                isRequired={isIntl}
                                country={destinationCountry}
                                label={
                                    <FormattedMessage
                                        id="orderDetails.customs__import__title"
                                        defaultMessage="Import Customs Brokerage Information"
                                    />
                                }
                            />
                        </FormSection>
                    )}

                    {(isIntl || isUSDomesticOffshoreWithItemCustoms) &&
                        isFedExCarrier(carrierCode) && (
                            <SupportDocumentationForm
                                changeField={changeField}
                                locationId={location?._id}
                                shipmentId={shipmentId}
                            />
                        )}

                    {isInBondShipment && <InBondCard formName="bolEntry" />}
                    <HandlingUnitsForm
                        isStrict={isIntl || isUSDomesticOffshoreWithItemCustoms}
                        preferredCurrencyCode={preferredCurrencyCode}
                        preferredSystemOfMeasurement={
                            preferredSystemOfMeasurement
                        }
                        handlingUnits={handlingUnits}
                        updateShouldAES={updateShowAES}
                        shouldShowAES={shouldShowAES}
                        shouldShowTotalForCommercial={commercialInvoice}
                        totalPrice={totalPrice}
                    />
                    {isHazMat && (
                        <FormSection
                            name="hazmatEmergency"
                            component={Fragment}
                        >
                            <HazMatForm />
                        </FormSection>
                    )}
                    <Grid item container>
                        <Card className={classes.alerts__card}>
                            <AlertsPreferences
                                share
                                formName="bolEntry"
                                isFreightDirect={
                                    mode === "LTL_DIRECT" ||
                                    mode === "LTL_DIRECT_RETURNS"
                                }
                                location={location}
                            />
                        </Card>
                    </Grid>
                    <div className={classes.submit}>
                        <Button
                            id="book__bookShipment"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            disabled={
                                invalid || submitting || resiCheckInProgress
                            }
                        >
                            <FormattedMessage
                                id="orderDetails.book__submit"
                                defaultMessage="Book Shipment"
                            />
                        </Button>
                        {submitting && (
                            <CircularProgress
                                className={classes.submitLoader}
                                size={24}
                                color="secondary"
                            />
                        )}
                    </div>
                </Grid>
                <Modal
                    open={shouldShowHazMatDetails}
                    onClose={closeHazMatDetailsModal}
                >
                    <HazMatDetailsModal
                        submit={handleSubmit(onBookShipmentWithOutHazMatCheck)}
                        handleClose={closeHazMatDetailsModal}
                        handlingUnits={handlingUnitsBolForm}
                        hazmatEmergency={hazmatEmergency}
                        preferredSystemOfMeasurement={
                            preferredSystemOfMeasurement
                        }
                        changeField={changeField}
                    />
                </Modal>
            </form>
        </div>
    )
}

BookPresentation.propTypes = {
    computedMatch: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    classes: PropTypes.object,
    onDispatchShipment: PropTypes.func.isRequired,
    originFixed: PropTypes.bool.isRequired,
    destinationFixed: PropTypes.bool.isRequired,
    isHazMat: PropTypes.bool.isRequired,
    shipmentErrors: PropTypes.array,
}

BookPresentation.defaultProps = {
    classes: {},
    shipmentErrors: [],
}

function handleResponse(shipmentId, setShouldOpen, carrier) {
    return dispatch => {
        dispatch(changePath(`/track/${shipmentId}`))
        if (carrier === "FXNL" || carrier === "FXFE") {
            setShouldOpen(true)
        }
        return dispatch(newShipmentFlashMessage())
    }
}

function onAddressAccept(dispatch, quoteId) {
    return async addresses => {
        if (addresses) {
            dispatch(acceptAddressSuggestion(addresses[0], addresses[1]))
        }
        const shipment = await dispatch(createShipment())
        if (shipment) dispatch(handleResponse(quoteId))
        else {
            scroll.scrollToTop({
                containerId: "referenceNumber",
                smooth: "true",
                duration: 1000,
                isDynamic: "true",
            })
        }
    }
}

const mapStateToProps = (state, props) =>
    bookSelector(state, props.computedMatch.params)

const mapDispatchToProps = dispatch => ({
    onDispatchShipment: async (values, dispatch, props, setShouldOpen) => {
        const shipment = await dispatch(createShipment(values, props))
        const shipmentId = shipment?.identifiers?.internalTrackingNumber
        if (values?.requiresCertificateOfOrigin) {
            dispatch(
                createCertificateOfOrigin(
                    shipmentId,
                    values?.certificateOfOrigin
                )
            )
        }
        const carrier = shipment?.shipment?.carrier
        if (!shipmentId) return
        dispatch(handleResponse(shipmentId, setShouldOpen, carrier))
    },
    changeField: (field, value) => dispatch(change("bolEntry", field, value)),
    trackGA2: (category, action, label) =>
        dispatch(trackGAEvent(category, action, label)),
})

export const isRequiredIfNonDomesticOrOffshoreWithItemCustoms = isRequiredIf(
    values =>
        isInternationalShipment(
            get(values, "origin.address.country") ??
                get(values, "origin.shippingAddress.address.country"),
            get(values, "destination.address.country") ??
                get(values, "destination.shippingAddress.address.country")
        ) ||
        isUSDomesticOffshoreShipmentWithItemCustoms(
            get(values, "origin.address.country") ??
                get(values, "origin.shippingAddress.address.country"),
            get(values, "destination.address.country") ??
                get(values, "destination.shippingAddress.address.country"),
            get(values, "origin.address.state") ??
                get(values, "origin.shippingAddress.address.state"),
            get(values, "destination.address.state") ??
                get(values, "destination.shippingAddress.address.state")
        )
)

const isRequiredIfIntl = isRequiredIf(values =>
    isInternationalShipment(
        get(values, "origin.address.country") ??
            get(values, "origin.shippingAddress.address.country"),
        get(values, "destination.address.country") ??
            get(values, "destination.shippingAddress.address.country")
    )
)

const isRequiredIfIntlAndSelectGenerateInvoice = isRequiredIf(
    values =>
        (isInternationalShipment(
            get(values, "origin.address.country") ??
                get(values, "origin.shippingAddress.address.country"),
            get(values, "destination.address.country") ??
                get(values, "destination.shippingAddress.address.country")
        ) ||
            isUSDomesticOffshoreShipmentWithItemCustoms(
                get(values, "origin.address.country") ??
                    get(values, "origin.shippingAddress.address.country"),
                get(values, "destination.address.country") ??
                    get(values, "destination.shippingAddress.address.country"),
                get(values, "origin.address.state") ??
                    get(values, "origin.shippingAddress.address.state"),
                get(values, "destination.address.state") ??
                    get(values, "destination.shippingAddress.address.state")
            )) &&
        get(values, "requiresCommercialInvoice")
)

const isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter = isRequiredIf(
    values =>
        (isInternationalShipment(
            get(values, "origin.address.country") ??
                get(values, "origin.shippingAddress.address.country"),
            get(values, "destination.address.country") ??
                get(values, "destination.shippingAddress.address.country")
        ) ||
            isUSDomesticOffshoreShipmentWithItemCustoms(
                get(values, "origin.address.country") ??
                    get(values, "origin.shippingAddress.address.country"),
                get(values, "destination.address.country") ??
                    get(values, "destination.shippingAddress.address.country"),
                get(values, "origin.address.state") ??
                    get(values, "origin.shippingAddress.address.state"),
                get(values, "destination.address.state") ??
                    get(values, "destination.shippingAddress.address.state")
            )) &&
        get(values, "requiresCommercialInvoice") &&
        !isConsigneeImporter(
            get(values, "commercialInvoice.importerIsNotConsignee", false)
        )
)

const isRequiredIfIntlAndSelectGenerateInvoiceAndNaftaDeclaration = isRequiredIf(
    values =>
        (isInternationalShipment(
            get(values, "origin.address.country") ??
                get(values, "origin.shippingAddress.address.country"),
            get(values, "destination.address.country") ??
                get(values, "destination.shippingAddress.address.country")
        ) ||
            isUSDomesticOffshoreShipmentWithItemCustoms(
                get(values, "origin.address.country") ??
                    get(values, "origin.shippingAddress.address.country"),
                get(values, "destination.address.country") ??
                    get(values, "destination.shippingAddress.address.country"),
                get(values, "origin.address.state") ??
                    get(values, "origin.shippingAddress.address.state"),
                get(values, "destination.address.state") ??
                    get(values, "destination.shippingAddress.address.state")
            )) &&
        get(values, "requiresCommercialInvoice") &&
        requiredNaftaDeclaration(
            get(values, "commercialInvoice.naftaDeclaration", false)
        )
)

const isRequiredIfStreet1Missing = isRequiredIf(
    values =>
        !isConsigneeImporter(
            get(values, "commercialInvoice.importerIsNotConsignee", false)
        ) && !get(values, "commercialInvoice.importerOfRecord.address.street1")
)
const isRequiredIfStreet2Missing = isRequiredIf(
    values =>
        !isConsigneeImporter(
            get(values, "commercialInvoice.importerIsNotConsignee", false)
        ) && !get(values, "commercialInvoice.importerOfRecord.address.street2")
)

export const hazmatValidator = countryField => ({
    name: isRequired({
        field: {
            id: "generalTerms__contactName",
            defaultMessage: "Contact Name",
        },
    }),
    phone: {
        phone_number: composeValidators(
            isRequired,
            countryPhoneValidator(countryField)
        )({
            field: {
                id: "generalTerms__contactPhone",
                defaultMessage: "Contact Phone",
            },
        }),
        extension: isNumeric({
            field: {
                id: "generalTerms__contactExtension",
                defaultMessage: "Contact Extension",
            },
        }),
    },
    email: composeValidators(
        isRequired,
        emailValidator
    )({
        field: {
            id: "generalTerms__contactEmail",
            defaultMessage: "Contact Email",
        },
    }),
})

export const customsCOOValidation = () => ({
    chamberOfCommerce: {
        name: isRequiredIf(values =>
            get(values, "certificateOfOrigin.notaryStamp")
        )({
            field: {
                id: "certificateOfOrigin.chamberOfCommerce__name",
                defaultMessage: "Chamber of Commerce Name:",
            },
        }),
        state: isRequiredIf(values =>
            get(values, "certificateOfOrigin.notaryStamp")
        )({
            field: {
                id: "certificateOfOrigin.chamberOfCommerce__state",
                defaultMessage: "Chamber of Commerce State:",
            },
        }),
    },
    datedAt: isRequiredIf(values =>
        get(values, "certificateOfOrigin.notaryStamp")
    )({
        field: {
            id: "certificateOfOrigin.datedAt",
            defaultMessage: "Dated at:",
        },
    }),
    swornDate: isRequiredIf(values =>
        get(values, "certificateOfOrigin.notaryStamp")
    )({
        field: {
            id: "certificateOfOrigin.swornDate",
            defaultMessage: "Sworn on:",
        },
    }),
    shipmentPurpose: isRequiredIf(values =>
        get(values, "certificateOfOrigin.notaryStamp")
    )({
        field: {
            id: "certificateOfOrigin.select_purpose",
            defaultMessage: "Select Purpose Of Shipment:",
        },
    }),
})

const customsCommercialInvoiceValidation = () => ({
    termsOfSale: isRequiredIfIntlAndSelectGenerateInvoice({
        field: {
            id: "orderDetails.support__documentation__termsOfSale",
            defaultMessage: "Terms Of Sale",
        },
    }),
    dutiesPaidBy: isRequiredIfIntlAndSelectGenerateInvoice({
        field: {
            id:
                "orderDetails.support__documentation__select__duties_payable_by",
            defaultMessage: "Duties Payable By",
        },
    }),
    naftaLowValueRole: isRequiredIfIntlAndSelectGenerateInvoiceAndNaftaDeclaration(
        {
            field: {
                id: "orderDetails.support__documentation__naftaLowValueRole",
                defaultMessage: "USMCA Status",
            },
        }
    ),
    importerOfRecord: {
        taxIdentification: {
            type: isRequiredIf(values =>
                get(
                    values,
                    "commercialInvoice.importerOfRecord.taxIdentification.number"
                )
            )({
                field: {
                    id:
                        "orderDetails.support__documentation__importer_select__tax__type",
                    defaultMessage: "Select Importer Tax Identification Type",
                },
            }),
            number: isRequiredIf(values =>
                get(
                    values,
                    "commercialInvoice.importerOfRecord.taxIdentification.type"
                )
            )({
                field: {
                    id:
                        "orderDetails.support__documentation__importer__tax__identification__number",
                    defaultMessage: " Importer Tax Identification Number",
                },
            }),
        },
        name: isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter({
            field: {
                id:
                    "orderDetails.support__documentation__importer__companyName",
                defaultMessage: " Importer Company Name",
            },
        }),
        address: {
            street1: isRequiredIfStreet2Missing({
                field: {
                    id:
                        "orderDetails.support__documentation__importer_address1",
                    defaultMessage: "Importer Address 1",
                },
            }),
            street2: isRequiredIfStreet1Missing({
                field: {
                    id:
                        "orderDetails.support__documentation__importer_address2",
                    defaultMessage: "Importer Address 2",
                },
            }),
            postalCode: isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter(
                {
                    field: {
                        id:
                            "orderDetails.support__documentation__importer_zipPostalCode",
                        defaultMessage: "Importer Zip/Postal Code",
                    },
                }
            ),
            city: isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter(
                {
                    field: {
                        id:
                            "orderDetails.support__documentation__importer__city",
                        defaultMessage: "Importer City",
                    },
                }
            ),
            country: isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter(
                {
                    field: {
                        id:
                            "orderDetails.support__documentation__importer__country",
                        defaultMessage: "Importer Country",
                    },
                }
            ),
            state: isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter(
                {
                    field: {
                        id:
                            "orderDetails.support__documentation__importer__state",
                        defaultMessage: "Importer State/Province",
                    },
                }
            ),
        },
    },
})
const customsBrokerValidation = countryField => ({
    name: isRequiredIfIntl({
        field: {
            id: "generalTerms__companyName",
            defaultMessage: "Company Name",
        },
    }),
    contact: {
        phone: {
            phone_number: composeValidators(
                isRequiredIfIntl,
                countryPhoneValidator(countryField)
            )({
                field: {
                    id: "generalTerms__phoneNumber",
                    defaultMessage: "Phone Number",
                },
            }),
            extension: isNumeric({
                field: {
                    id: "generalTerms__extension",
                    defaultMessage: "Extension",
                },
            }),
        },
        email: {
            email_address: composeValidators(
                isRequiredIfIntl,
                emailValidator
            )({
                field: {
                    id: "generalTerms__emailAddress",
                    defaultMessage: "Email Address",
                },
            }),
        },
    },
})

const itemValidation = {
    "handlingUnits[].items[].nmfcCode": nmfcValidator,
    "handlingUnits[].items[].nmfcSubclass": nmfcSubcodeValidator,
    "handlingUnits[].items[].hazMatPkgGrp": isRequired({
        field: {
            id: "items__packageGroup",
            defaultMessage: "Package Group",
        },
    }),
    "handlingUnits[].items[].hazMatClass": isRequired({
        field: {
            id: "items__hazClass",
            defaultMessage: "Haz Class",
        },
    }),
    "handlingUnits[].items[].hazMatContainer": isRequired({
        field: {
            id: "items__containerType",
            defaultMessage: "Container Type",
        },
    }),
    "handlingUnits[].items[].countryOfManufacture": isRequiredIfNonDomesticOrOffshoreWithItemCustoms(
        {
            field: {
                id: "items__countryOfManufacture",
                defaultMessage: "Country of Manufacture",
            },
        }
    ),
    "handlingUnits[].items[].unitPrice": isRequiredIfNonDomesticOrOffshoreWithItemCustoms(
        {
            field: {
                id: "items_unitPriceNoCurrency",
                defaultMessage: "Unit Price",
            },
        }
    ),
    "handlingUnits[].items[].hazMatUnNumber": composeValidators(
        isRequired,
        hasHazMatIdPrefix(),
        hasLength(6)
    )({
        field: {
            id: "items__unNumber",
            defaultMessage: "ID Numbers",
        },
    }),
    "handlingUnits[].items[].unit": isRequiredIfIntlAndSelectGenerateInvoice({
        field: {
            id: "orderDetails.support__documentation__No__of__units",
            defaultMessage: " {itemName} No. of units",
            values: {
                itemName: "",
            },
        },
    }),
    "handlingUnits[].items[].unitOfMeasure": isRequiredIfIntlAndSelectGenerateInvoice(
        {
            field: {
                id: "orderDetails.support__documentation__unit__of__measure",
                defaultMessage: " {itemName} Unit of measure",
                values: {
                    itemName: "",
                },
            },
        }
    ),
    aesCitationNumber: aesNumberValidator({
        field: {
            id: "orderDetails.label__AES",
            defaultMessage: "AES Citation Number",
        },
    }),
}
const formValidation = (values, props) => {
    const mainValidation = combineValidators({
        origin: {
            address: addressValidator("origin"),
            contact: contactValidator("origin.address.country"),
            ...pickupValidator("origin.address.country"),
            taxIdentification: {
                type: composeValidators(
                    isRequiredIf(values =>
                        get(values, "origin.taxIdentification.number")
                    )
                )({
                    field: {
                        id:
                            "orderDetails.support__documentation__exporter__select__tax__type",
                        defaultMessage:
                            "Select  Exporter Tax Identification Type",
                    },
                }),
                number: composeValidators(
                    isRequiredIf(values =>
                        get(values, "origin.taxIdentification.type")
                    )
                )({
                    field: {
                        id:
                            "orderDetails.support__documentation__exporter__tax__identification__number",
                        defaultMessage: " Exporter Tax Identification Number",
                    },
                }),
            },
        },
        destination: {
            address: addressValidator("destination"),
            contact: contactValidator("destination.address.country"),
            taxIdentification: {
                type: composeValidators(
                    isRequiredIf(values =>
                        get(values, "destination.taxIdentification.number")
                    )
                )({
                    field: {
                        id:
                            "orderDetails.support__documentation__consignee__select__tax__type",
                        defaultMessage:
                            "Select  Consignee  Tax Identification Type",
                    },
                }),
                number: composeValidators(
                    isRequiredIf(values =>
                        get(values, "destination.taxIdentification.type")
                    )
                )({
                    field: {
                        id:
                            "orderDetails.support__documentation__consignee__tax__identification__number",
                        defaultMessage: " Consignee Tax Identification Number",
                    },
                }),
            },
        },
        ...itemValidation,
        "identifiers.bolNumber": isRequired({
            field: {
                id: "orderDetails.label__BOL",
                defaultMessage: "Bill of Lading ID",
            },
        }),
        exportCustomsBrokerageInfo: customsBrokerValidation(
            "origin.address.country"
        ),
        importCustomsBrokerageInfo: customsBrokerValidation(
            "destination.address.country"
        ),
        purposeOfShipment: isRequiredIfIntlAndSelectGenerateInvoice({
            field: {
                id: "orderDetails.support__documentation__select_purpose",
                defaultMessage: "Select Purpose Of Shipment",
            },
        }),
        commercialInvoice: customsCommercialInvoiceValidation(),
        certificateOfOrigin: customsCOOValidation(),
        hazmatEmergency: hazmatValidator("origin.address.country"),
        "identifiers.aesCitationNumber": aesNumberValidator({
            field: {
                id: "orderDetails.label__AES",
                defaultMessage: "AES Citation Number",
            },
        }),
    })(values, props)
    return merge({}, mainValidation, itemPropertiesFormValidator(values))
}

const isProValid = async values => {
    const proNumber = get(values, "identifiers.proNumber")
    const carrierCode = get(values, "rate.carrierCode")
    if (!isFedExCarrier(carrierCode) || !proNumber) return
    const errorMessage = {
        identifiers: { proNumber: "Invalid Tracking ID" },
    }
    if (proNumber.length < 10) throw errorMessage
    let response
    try {
        response =
            (await goFetch(
                "/shipments/validation",
                { params: { pro: proNumber } },
                true
            )) || {}
    } catch (error) {
        throw errorMessage
    }
    if (!get(response, "data.valid")) throw errorMessage
}

export const BookScreen = withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "bolEntry", // a unique identifier for this form
            enableReinitialize: true,
            validate: formValidation,
            asyncValidate: isProValid,
            asyncBlurFields: ["identifiers.proNumber"],
        })(
            formValues({
                shipmentId: "identifiers.internalTrackingNumber",
                originCountry: "origin.address.country",
                destinationCountry: "destination.address.country",
                rateMode: "rate.mode",
            })(BookPresentation)
        )
    )
)

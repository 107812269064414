import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { connect } from "react-redux"
import { FormattedMessage, injectIntl } from "react-intl"

const version = window._app_version_

const styles = theme => ({
    footer__row: {
        margin: "6px 6px 0 6px",
        width: "98%",
    },
    footer__row__disallow: {
        paddingLeft: "15px",
    },
    terms: {
        cursor: "pointer",
    },
})

function FooterRowContainer(props) {
    const currentYear = new Date().getFullYear()
    const { classes, isFinished, disallow, intl } = props
    if (isFinished) {
        return (
            <Grid
                container
                justifyContent="space-between"
                wrap="nowrap"
                className={classes.footer__row}
            >
                <Grid item container xs={2}>
                    <Typography variant="caption">
                        &copy; FedEx. 1995-{currentYear}
                    </Typography>
                </Grid>
                <Grid item container xs={3} justifyContent="flex-start">
                    <Typography variant="caption">{version}</Typography>
                </Grid>
                <Grid item xs={3} />
                <Grid item container xs={4} justifyContent="space-between">
                    <Grid
                        item
                        onClick={() => {
                            window.open(`/#/terms/${intl?.locale}`, "_blank")
                        }}
                        className={classes.terms}
                    >
                        <Typography variant="body2">
                            <FormattedMessage
                                id="footer__termsOfUse"
                                defaultMessage="Terms of Use"
                            />
                        </Typography>
                    </Grid>
                    <a
                        href="static/LTLselect EULA - 2018_08_30.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Typography variant="body2">
                            <FormattedMessage
                                id="footer__securityAndPrivacy"
                                defaultMessage="Security & Privacy"
                            />
                        </Typography>
                    </a>
                </Grid>
            </Grid>
        )
    } else if (disallow) {
        return (
            <Grid
                container
                justifyContent="space-between"
                wrap="nowrap"
                className={classes.footer__row__disallow}
            >
                <Grid item container xs={2}>
                    <Typography variant="caption">
                        &copy; FedEx. 1995-{currentYear}
                    </Typography>
                </Grid>
                <Grid item container xs={3} justifyContent="flex-start">
                    <Typography variant="caption">{version}</Typography>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        )
    } else return null
}

FooterRowContainer.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state, props) => ({
    isFinished: state.authorization.isFinished,
})

export const FooterRow = injectIntl(
    connect(mapStateToProps)(withStyles(styles)(FooterRowContainer))
)

import React from "react"
import { makeStyles } from "@material-ui/styles"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepButton from "@material-ui/core/StepButton"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles({
    root: {
        backgroundColor: "rgb(242, 242, 242)",
    },
    stepper: {
        width: "100%",
        backgroundColor: "rgb(242, 242, 242)",
    },
})

export default function BookStepper({ currentStep, steps, onStepClick }) {
    const classes = useStyles()
    return (
        <Stepper nonLinear activeStep={currentStep} className={classes.stepper}>
            {steps.map((step, index) => (
                <Step key={step.label}>
                    <StepButton
                        id={`${step.label}-step`}
                        classes={{ root: classes.root }}
                        completed={currentStep > index}
                        onClick={() => onStepClick(step.label, index)}
                        disabled={currentStep < index}
                    >
                        <Grid item container justify="center">
                            <Typography variant="body2">
                                {step.label}
                            </Typography>
                        </Grid>
                    </StepButton>
                </Step>
            ))}
        </Stepper>
    )
}

import React from "react"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import { withStyles } from "@material-ui/core/styles"
import ExpandMore from "@material-ui/icons/ExpandMore"
import Button from "@material-ui/core/Button"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import Typography from "@material-ui/core/Typography"
import { get } from "lodash"
import moment from "moment"
import { accessorialsSelector } from "../util/accessorialsDisplay"
import AccessorialsDisplay from "./accessorialsDisplay"
import QuoteHUDisplay from "./quoteHUDisplay"
import { FormattedMessage, injectIntl } from "react-intl"
import { weightUnit } from "../util/units"
import { useSnackbarContext } from "../../context/providers/snackbarProvider"
import { changeQuoteArchiveStatus } from "../../actions/quote-request"

const styles = theme => ({
    parent__container: {
        "min-width": "870px",
        "flex-grow": 1,
        "flex-shrink": 1,
        "flex-basis": "0px",
    },
    panel__summary: {
        "&:hover": {
            backgroundColor: "#F4F4F4",
        },
        minHeight: "84px",
    },
    item__container: {
        overflow: "auto",
    },
    button__info: {
        "margin-right": "15%",
    },
    button__icon: {
        "&button": {
            "&:focus": {
                outline: "none",
            },
        },
    },
    identifiers__container: {
        "white-space": "pre-line",
        display: "flex",
    },
    content__container: {
        "white-space": "pre-line",
        display: "flex",
        padding: "10px",
    },
    address__box__left: {
        "min-width": "35%",
    },
    address__box__right: {
        "min-width": "65%",
    },
    actions: {
        padding: "4px 16px 8px 8px",
    },
    directionIcon: {
        width: "35px",
        height: "35px",
    },
    carrier__logo: {
        height: "51px",
        width: "57px",
    },
    carrier__tag: {
        "margin-left": "16px",
    },
    item__description: {
        "margin-left": "16px",
        display: "flex",
        "flex-direction": "column",
        "align-items": "flex-start",
    },
    item__total: {
        "margin-left": "16px",
        "margin-top": "10px",
    },
    caption_inline: {
        display: "inline",
        "margin-left": "16px",
    },
    hide: {
        display: "none",
        "margin-left": "16px",
    },
    identifiers__box__left: {
        "margin-right": "7px",
    },

    expansionPanelDetails__section: {
        padding: "10px",
    },
    section__title: {
        textDecoration: "underline",
    },
    whiteSpace: {
        whiteSpace: "pre-wrap",
    },
    actionButton: {
        margin: "5px",
    },
})

const AddressPart = ({ data, title }) => {
    return data ? (
        <Grid item container xs={6}>
            <Grid item container justify="flex-start" direction="row" xs={12}>
                <Grid item>
                    <Typography variant="caption">{title}</Typography>
                </Grid>
            </Grid>
            <Grid
                item
                container
                justify="flex-start"
                direction="column"
                xs={12}
            >
                <Grid item>
                    {get(data, "name") && (
                        <Typography variant="body2">{data.name}</Typography>
                    )}
                </Grid>
                <Grid item>
                    {get(data, "address.street1") && (
                        <Typography variant="body2">
                            {get(data, "address.street1")}
                        </Typography>
                    )}
                </Grid>
                <Grid item>
                    {get(data, "address") && (
                        <Typography variant="body2">
                            {get(data, "address.city")},{" "}
                            {get(data, "address.state")}{" "}
                            {get(data, "address.postalCode")}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    ) : null
}

const DashboardQuoteItem = ({
    identifiers = {},
    isOpen,
    search = {},
    classes,
    _id,
    accessorials,
    updated_at,
    shipped,
    onClickDetails,
    onViewQuote,
    shipmentId,
    index,
    result,
    handlingUnits = [],
    totalWeight,
    totalHandlingUnits,
    intl,
    viewArchived,
    changeQuoteArchiveStatus,
    toggleInfo,
}) => {
    const { openSnackbar } = useSnackbarContext()

    const destinationAddress =
        get(search, "destinationAddress.name") ||
        (get(search, "destinationCity")
            ? `${get(search, "destinationCity")}, ${get(
                  search,
                  "destinationState"
              )}`
            : get(search, "destination"))
    const originAddress =
        get(search, "originAddress.name") ||
        (get(search, "originCity")
            ? `${get(search, "originCity")}, ${get(search, "originState")}`
            : get(search, "origin"))
    const isQuickQuote = get(search, "isQuickQuote")
    const isQuickRate = get(search, "isQuickRate")

    const isFreightDirect = result?.rateQuotes.some(
        rate => rate.mode === "LTL_DIRECT"
    )

    const isFreightDirectReturns = result?.rateQuotes.some(
        rate => rate.mode === "LTL_DIRECT_RETURNS"
    )

    return (
        <div className="quote-item">
            <ExpansionPanel
                id={`dashboardQuoteItem__${index}`}
                className={classes.parent__container}
                expanded={isOpen}
            >
                <ExpansionPanelSummary
                    expandIcon={
                        <ExpandMore
                            id={`dashboardQuoteItemExpandMore__${index}`}
                        />
                    }
                    onClick={() => toggleInfo(_id)}
                    className={classes.panel__summary}
                >
                    <Grid container>
                        <Grid
                            item
                            container
                            justify="flex-start"
                            alignItems="center"
                            xs={1}
                        >
                            <img
                                className={classes.carrier__logo}
                                src="static/LTLselect_logo-icon-only.png"
                                alt="LTL Select"
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            className={classes.item__container}
                            direction="column"
                            justifyContent="center"
                            xs={2}
                        >
                            {identifiers?.bolNumber ? (
                                <div className={classes.identifiers__container}>
                                    <span
                                        className={
                                            classes.identifiers__box__left
                                        }
                                    >
                                        <Typography variant="caption">
                                            <FormattedMessage
                                                id="generalTerms__rateId"
                                                defaultMessage="Rate ID"
                                            />
                                            :{" "}
                                        </Typography>
                                    </span>
                                    <span>
                                        <Typography
                                            variant="body2"
                                            id={`dashboardQuoteItem__${index}__bolNumber`}
                                        >
                                            {identifiers.bolNumber}
                                        </Typography>
                                    </span>
                                </div>
                            ) : null}
                            {identifiers?.referenceNumber ? (
                                <div className={classes.identifiers__container}>
                                    <span>
                                        <Typography variant="caption">
                                            <FormattedMessage
                                                id="generalTerms__referenceId"
                                                defaultMessage="Reference ID"
                                            />
                                            :{" "}
                                        </Typography>
                                    </span>
                                    <span>
                                        <Typography variant="body2">
                                            {identifiers?.referenceNumber}
                                        </Typography>
                                    </span>
                                </div>
                            ) : null}
                        </Grid>
                        {
                            <Grid
                                item
                                container
                                justifyContent="center"
                                direction="column"
                                xs={5}
                            >
                                <div className={classes.content__container}>
                                    <span
                                        className={classes.address__box__right}
                                    >
                                        <Typography
                                            variant={"subtitle1"}
                                            id={`dashboardQuoteItem__${index}__originAndDestination`}
                                        >
                                            {originAddress} &#8594;{" "}
                                            {destinationAddress}
                                        </Typography>
                                    </span>
                                </div>
                            </Grid>
                        }
                        <Grid
                            item
                            container
                            justifyContent="center"
                            alignItems="center"
                            xs={2}
                        >
                            {totalHandlingUnits && totalWeight && (
                                <Grid item container justify="center">
                                    <Typography
                                        variant={"subtitle1"}
                                        align="right"
                                        className={classes.whiteSpace}
                                    >
                                        {`${totalHandlingUnits} H/U  |  `}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        align="right"
                                        className={classes.whiteSpace}
                                    >
                                        {totalWeight}
                                        {weightUnit(
                                            search?.preferredSystemOfMeasurement
                                        )}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Grid
                            item
                            container
                            justify="flex-end"
                            alignItems="center"
                            xs={2}
                        >
                            <Grid item container justify="flex-end" xs={12}>
                                {isQuickQuote ? (
                                    <Typography variant={"subtitle1"}>
                                        <FormattedMessage
                                            id="generalTerms__quickQuoteLegacy"
                                            defaultMessage="Quick Quote (Legacy)"
                                        />
                                    </Typography>
                                ) : isQuickRate ? (
                                    <Typography variant={"subtitle1"}>
                                        <FormattedMessage
                                            id="generalTerms__quickRate"
                                            defaultMessage="Quick Rate"
                                        />
                                    </Typography>
                                ) : (
                                    <Typography variant={"subtitle1"}>
                                        <FormattedMessage
                                            id="dashboard.quote__quoted"
                                            defaultMessage="Quoted"
                                        />
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item container justify="flex-end" xs={12}>
                                <Typography
                                    variant="caption"
                                    id={`dashboardQuoteItem__${index}__date`}
                                >
                                    {moment(updated_at).format("M/DD/YY")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                    <Grid container>
                        <Grid
                            item
                            container
                            xs={6}
                            className={classes.expansionPanelDetails__section}
                            alignItems="flex-start"
                        >
                            <Grid item container>
                                <Typography
                                    variant="subtitle1"
                                    className={classes.section__title}
                                >
                                    {" "}
                                    <FormattedMessage
                                        id="dashboard__routingSummary"
                                        defaultMessage="Routing Summary"
                                    />{" "}
                                </Typography>
                            </Grid>
                            <AddressPart
                                data={search?.originAddress}
                                title={`${intl.formatMessage({
                                    id: "generalTerms__from",
                                    defaultMessage: "From",
                                })}:`}
                            />
                            <AddressPart
                                data={search?.destinationAddress}
                                title={`${intl.formatMessage({
                                    id: "generalTerms__to",
                                    defaultMessage: "To",
                                })}:`}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            xs={6}
                            className={classes.expansionPanelDetails__section}
                            alignContent="flex-start"
                        >
                            {!isFreightDirectReturns && accessorials && (
                                <AccessorialsDisplay
                                    accessorials={accessorials}
                                    isFreightDirect={isFreightDirect}
                                    isInBondShipment={search.isInBondShipment}
                                    id={`dashboardQuoteItem__${index}__accesorials`}
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            container
                            xs={6}
                            className={classes.expansionPanelDetails__section}
                        >
                            <QuoteHUDisplay
                                search={search}
                                handlingUnits={handlingUnits}
                            />
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
                <ExpansionPanelActions className={classes.actions}>
                    <Grid container justify="space-between">
                        <Grid item>
                            {search?.isArchived ? (
                                <Button
                                    className={classes.actionButton}
                                    id={`dashboardShipmentItem__${index}__unarchive`}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() =>
                                        changeQuoteArchiveStatus(
                                            shipmentId,
                                            false,
                                            viewArchived,
                                            openSnackbar
                                        )
                                    }
                                >
                                    <FormattedMessage
                                        id="generalTerms__Unarchive"
                                        defaultMessage="Unarchive"
                                    />
                                </Button>
                            ) : (
                                <Button
                                    className={classes.actionButton}
                                    id={`dashboardShipmentItem__${index}__archive`}
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    onClick={() =>
                                        changeQuoteArchiveStatus(
                                            shipmentId,
                                            true,
                                            viewArchived,
                                            openSnackbar
                                        )
                                    }
                                >
                                    <FormattedMessage
                                        id="generalTerms__Archive"
                                        defaultMessage="Archive"
                                    />
                                </Button>
                            )}
                        </Grid>
                        <Grid item>
                            {shipped && (
                                <Button
                                    className={classes.actionButton}
                                    id={`dashboardQuoteItem__${index}____orderDetails`}
                                    onClick={onClickDetails}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                >
                                    <FormattedMessage
                                        id="dashboard.shipment__orderDetails"
                                        defaultMessage="Shipment Details"
                                    />
                                </Button>
                            )}
                            <Button
                                className={classes.actionButton}
                                id={`dashboardQuoteItem__${index}____viewRates`}
                                onClick={() =>
                                    onViewQuote(
                                        shipmentId,
                                        isQuickQuote,
                                        isQuickRate,
                                        search?.queryVersion
                                    )
                                }
                                variant="outlined"
                                color="primary"
                                size="small"
                            >
                                <FormattedMessage
                                    id="dashboard.quote__viewRates"
                                    defaultMessage="View Rates"
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </ExpansionPanelActions>
            </ExpansionPanel>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    const shipmentId = get(props, "identifiers.internalTrackingNumber")
    const viewArchived =
        state?.dashboard?.dashboardFilters?.archiveFilter?.viewArchivedQuotes
    return {
        shipmentId,
        viewArchived,
        accessorials: {
            pickup: [
                ...accessorialsSelector(state, {
                    shipmentId,
                    isPickup: true,
                    includeCalculated: true,
                }),
            ],
            delivery: [...accessorialsSelector(state, { shipmentId })],
        },
    }
}

const mapDispatchToProps = dispatch => ({
    changeQuoteArchiveStatus: (
        shipmentId,
        archive,
        viewArchived,
        openSnackbar
    ) =>
        dispatch(
            changeQuoteArchiveStatus(
                shipmentId,
                archive,
                viewArchived,
                openSnackbar
            )
        ),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(injectIntl(DashboardQuoteItem))
)

import React from "react"
import FormCheckbox from "../../form/form-checkbox"
import { Field } from "redux-form"
import { Grid } from "@material-ui/core"

const TileSelectionColumn = ({ columnTiles, handleCheckboxChange }) => {
    return (
        <Grid item container xs={3} direction="column">
            {columnTiles.map(tile => {
                return (
                    <Field
                        name={tile.value}
                        label={tile.label}
                        component={FormCheckbox}
                        onChange={(a, fieldValue) => {
                            handleCheckboxChange(tile.value, fieldValue)
                        }}
                    />
                )
            })}
        </Grid>
    )
}

const TileSelectionList = ({
    supportedTiles,
    classes,
    handleCheckboxChange,
}) => {
    const getSubArray = column => {
        let start = 0
        let end = Math.ceil(supportedTiles.length / 4)
        let i = 1
        while (i < column) {
            start = end
            end = Math.ceil((supportedTiles.length - end) / (4 - i)) + end
            i++
        }

        return supportedTiles.slice(start, end)
    }

    return (
        <Grid item container className={classes.checkboxes__container}>
            {[1, 2, 3, 4].map(column => (
                <TileSelectionColumn
                    columnTiles={getSubArray(column)}
                    handleCheckboxChange={handleCheckboxChange}
                />
            ))}
        </Grid>
    )
}

export default TileSelectionList

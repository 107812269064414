import { Grid, Typography } from "@material-ui/core"
import React from "react"

const TitleField = ({ title, value }) => {
    return (
        <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            xs={12}
        >
            <Typography variant="body1">{title}</Typography>
            <Typography variant="h5">{value}</Typography>
        </Grid>
    )
}

export default TitleField

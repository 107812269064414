import React, { Fragment } from "react"
import Grid from "@material-ui/core/Grid"

import { FormattedMessage } from "react-intl"

import { Field, formValues } from "redux-form"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import FormSelectAutocomplete from "../../../form/form-select-autocomplete"
import FormSwitch from "../../../form/form-switch"
import FormField from "../../../form/form-field"
import FormSelect from "../../../form/form-select"
import FormSelectCity from "../../../form/form-select-city"
import FormZipCode from "../../../form/form-zip-code"

import {
    formattedDutiesPayableType,
    formattedNaftaStatusType,
    formattedTermsOfSale,
    formattedUnitOfMeasuresType,
} from "../../../book/CommercialInvoiceForm"
import {
    formattedItemName,
    formattedPurposeOfShipment,
    formattedTaxIdentificationType,
} from "../../../book/SupportDocumentationForm"
import { supportedCountryCodes } from "../../../../misc"
import ManageSignature from "../../../documents/ManageSignature"

const useStyles = makeStyles({
    underline: {
        textDecoration: "underline",
        paddingBottom: "10px",
    },
    fields__switches: {
        marginTop: "12px",
    },
    fields__switches_below: {
        marginTop: "6px",
    },
    signature_button: {
        marginTop: "12px",
    },
    mainTitle: {
        padding: "12px 0",
    },
})

const CommercialInvoiceForm = formValues({
    importerCountry: "commercialInvoice.importerOfRecord.address.country",
    importerPostalCode: "commercialInvoice.importerOfRecord.address.postalCode",
    importerIsNotConsignee: "commercialInvoice.importerIsNotConsignee",
    naftaDeclaration: "commercialInvoice.naftaDeclaration",
    originTaxIdentification: "origin.taxIdentification",
    destinationTaxIdentification: "destination.taxIdentification",
    importerTaxIdentification:
        "commercialInvoice.importerOfRecord.taxIdentification",
    handlingUnits: "handlingUnits",
    signature: "signatureImage",
})(
    ({
        importerCountry,
        importerPostalCode,
        importerIsNotConsignee,
        naftaDeclaration,
        originTaxIdentification,
        destinationTaxIdentification,
        importerTaxIdentification,
        handlingUnits,
        gaCategory,
    }) => {
        const classes = useStyles()

        return (
            <Grid xs={12} item>
                <Grid>
                    <Grid
                        item
                        container
                        justify="center"
                        className={classes.mainTitle}
                    >
                        <Typography variant="h6">
                            <FormattedMessage
                                id="orderDetails.commercial__invoice__title"
                                defaultMessage="Commercial Invoice"
                            />
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                component={FormSelectAutocomplete}
                                name={"purposeOfShipment"}
                                options={formattedPurposeOfShipment}
                                required={true}
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__select_purpose"
                                        defaultMessage="Select Purpose Of Shipment"
                                    />
                                }
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>
                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                name={`itemComments[0]`}
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__item__special__instruction"
                                        defaultMessage="Items Special Instructions"
                                    />
                                }
                                component={FormField}
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>
                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                name={`itemComments[1]`}
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__item__special__instruction2"
                                        defaultMessage="Items Special Instructions 2"
                                    />
                                }
                                component={FormField}
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>

                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                component={FormSelectAutocomplete}
                                name={"origin.taxIdentification.type"}
                                options={formattedTaxIdentificationType}
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__exporter__select__tax__type"
                                        defaultMessage="Select  Exporter Tax Identification Type"
                                    />
                                }
                                required={originTaxIdentification?.number}
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>
                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                name="origin.taxIdentification.number"
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__exporter__tax__identification__number"
                                        defaultMessage=" Exporter Tax Identification Number"
                                    />
                                }
                                required={originTaxIdentification?.type}
                                component={FormField}
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>
                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                component={FormSwitch}
                                name="commercialInvoice.partiesRelated"
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__parties"
                                        defaultMessage="Parties to Transaction Related"
                                    />
                                }
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>

                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                name="commercialInvoice.invoiceNumber"
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__invoice__number"
                                        defaultMessage="Invoice #"
                                    />
                                }
                                component={FormField}
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>
                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                name="commercialInvoice.paymentTerms"
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__payment__terms"
                                        defaultMessage="Payment Terms"
                                    />
                                }
                                component={FormField}
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>
                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                component={FormSwitch}
                                name="commercialInvoice.importerIsNotConsignee"
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__importer"
                                        defaultMessage="Importer Is Not Consignee"
                                    />
                                }
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>

                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                component={FormSelectAutocomplete}
                                name={"destination.taxIdentification.type"}
                                options={formattedTaxIdentificationType}
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__consignee__select__tax__type"
                                        defaultMessage="Select  Consignee  Tax Identification Type"
                                    />
                                }
                                required={destinationTaxIdentification?.number}
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>
                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                name="destination.taxIdentification.number"
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__consignee__tax__identification__number"
                                        defaultMessage=" Consignee Tax Identification Number"
                                    />
                                }
                                required={destinationTaxIdentification?.type}
                                component={FormField}
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>
                        {importerIsNotConsignee && (
                            <Fragment>
                                <Grid item container lg={4} md={6} sm={12}>
                                    <Field
                                        name="commercialInvoice.importerOfRecord.name"
                                        required={true}
                                        label={
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer__companyName"
                                                defaultMessage=" Importer Company Name"
                                            />
                                        }
                                        component={FormField}
                                        category={`${gaCategory} : Commercial Invoice`}
                                    />
                                </Grid>
                                <Grid item container lg={4} md={6} sm={12}>
                                    <Field
                                        component={FormSelectAutocomplete}
                                        name={
                                            "commercialInvoice.importerOfRecord.taxIdentification.type"
                                        }
                                        required={
                                            importerTaxIdentification?.number
                                        }
                                        options={formattedTaxIdentificationType}
                                        label={
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer_select__tax__type"
                                                defaultMessage="Select  Importer Tax Identification Type"
                                            />
                                        }
                                        category={`${gaCategory} : Commercial Invoice`}
                                    />
                                </Grid>
                                <Grid item container lg={4} md={6} sm={12}>
                                    <Field
                                        name="commercialInvoice.importerOfRecord.taxIdentification.number"
                                        label={
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer__tax__identification__number"
                                                defaultMessage=" Importer Tax Identification Number"
                                            />
                                        }
                                        required={
                                            importerTaxIdentification?.type
                                        }
                                        component={FormField}
                                        category={`${gaCategory} : Commercial Invoice`}
                                    />
                                </Grid>
                                <Grid item container lg={4} md={6} sm={12}>
                                    <Field
                                        name="commercialInvoice.importerOfRecord.address.country"
                                        required={true}
                                        component={FormSelect}
                                        type="text"
                                        label={[
                                            "* ",
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer__country"
                                                defaultMessage="Importer Country"
                                            />,
                                        ]}
                                        options={supportedCountryCodes}
                                        maxLength="30"
                                        category={`${gaCategory} : Commercial Invoice`}
                                    />
                                </Grid>
                                <Grid item container lg={4} md={6} sm={12}>
                                    <Field
                                        name="commercialInvoice.importerOfRecord.address.postalCode"
                                        required={true}
                                        component={FormZipCode}
                                        type="text"
                                        label={[
                                            "* ",
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer_zipPostalCode"
                                                defaultMessage="Importer Zip/Postal Code"
                                            />,
                                        ]}
                                        country={importerCountry}
                                        category={`${gaCategory} : Commercial Invoice`}
                                    />
                                </Grid>
                                <Grid item container lg={4} md={6} sm={12}>
                                    <Field
                                        name="commercialInvoice.importerOfRecord.address.city"
                                        required={true}
                                        component={FormSelectCity}
                                        label={[
                                            "* ",
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer__city"
                                                defaultMessage="Importer City"
                                            />,
                                        ]}
                                        country={importerCountry}
                                        postalCode={importerPostalCode}
                                        category={`${gaCategory} : Commercial Invoice`}
                                    />
                                </Grid>
                                <Grid item container lg={4} md={6} sm={12}>
                                    <Field
                                        name="commercialInvoice.importerOfRecord.address.state"
                                        required={true}
                                        component={FormField}
                                        type="text"
                                        label={[
                                            "* ",
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer__state"
                                                defaultMessage="Importer State/Province"
                                            />,
                                        ]}
                                        disabled
                                        category={`${gaCategory} : Commercial Invoice`}
                                    />
                                </Grid>
                                <Grid item container lg={4} md={6} sm={12}>
                                    <Field
                                        name="commercialInvoice.importerOfRecord.address.street1"
                                        component={FormField}
                                        type="text"
                                        label={[
                                            "* ",
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer_address1"
                                                defaultMessage="Importer Address 1"
                                            />,
                                        ]}
                                        category={`${gaCategory} : Commercial Invoice`}
                                    />
                                </Grid>
                                <Grid item container lg={4} md={6} sm={12}>
                                    <Field
                                        name="commercialInvoice.importerOfRecord.address.street2"
                                        component={FormField}
                                        type="text"
                                        label={[
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer_address2"
                                                defaultMessage="Importer Address 2"
                                            />,
                                        ]}
                                        category={`${gaCategory} : Commercial Invoice`}
                                    />
                                </Grid>
                            </Fragment>
                        )}
                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                component={FormSelectAutocomplete}
                                name="commercialInvoice.dutiesPaidBy"
                                options={formattedDutiesPayableType}
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__select__duties_payable_by"
                                        defaultMessage="Duties Payable By"
                                    />
                                }
                                required={true}
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>

                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                component={FormSelectAutocomplete}
                                name={"commercialInvoice.termsOfSale"}
                                options={formattedTermsOfSale}
                                required={true}
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__termsOfSale"
                                        defaultMessage="Terms Of Sale"
                                    />
                                }
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>
                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                name="commercialInvoice.insuranceCharge"
                                type="number"
                                component={FormField}
                                label={[
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__insurance_charge"
                                        defaultMessage="Insurance Charge"
                                    />,
                                ]}
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>
                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                name="commercialInvoice.freightCharge"
                                type="number"
                                component={FormField}
                                label={[
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__freight_charge"
                                        defaultMessage="Freight Charge"
                                    />,
                                ]}
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>

                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                name="commercialInvoice.packingCharge"
                                type="number"
                                component={FormField}
                                label={[
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__packing__charge"
                                        defaultMessage="Packing Charge"
                                    />,
                                ]}
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>
                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                name="commercialInvoice.handlingCharge"
                                type="number"
                                component={FormField}
                                label={[
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__handling__charge"
                                        defaultMessage="Handling Charge"
                                    />,
                                ]}
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>
                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                name="commercialInvoice.otherCharge"
                                type="number"
                                component={FormField}
                                label={[
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__other__charge"
                                        defaultMessage="Other Charge"
                                    />,
                                ]}
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>

                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                name="commercialInvoice.specialInstructions"
                                component={FormField}
                                type="text"
                                label={[
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__special__instructions"
                                        defaultMessage="Special Instructions"
                                    />,
                                ]}
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>
                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                className={classes.fields__switches_below}
                                component={FormSwitch}
                                name="commercialInvoice.naftaDeclaration"
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__naftaDeclaration"
                                        defaultMessage="USMCA Low Value Declaration Statement"
                                    />
                                }
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>
                        {naftaDeclaration && (
                            <Grid item container lg={4} md={6} sm={12}>
                                <Field
                                    component={FormSelectAutocomplete}
                                    name={"commercialInvoice.naftaLowValueRole"}
                                    options={formattedNaftaStatusType}
                                    label={
                                        <FormattedMessage
                                            id="orderDetails.support__documentation__naftaLowValueRole"
                                            defaultMessage="USMCA Status"
                                        />
                                    }
                                    required={naftaDeclaration}
                                    category={`${gaCategory} : Commercial Invoice`}
                                />
                            </Grid>
                        )}
                        <Grid item container lg={4} md={6} sm={12}>
                            <Field
                                name="commercialInvoice.customOriginator"
                                component={FormField}
                                type="text"
                                label={[
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__originator"
                                        defaultMessage="Originator (If Not Shipper)"
                                    />,
                                ]}
                                category={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>

                        {handlingUnits.map((hu, index) =>
                            hu.items.map((item, itemIndex) => {
                                const prefix = `handlingUnits[${index}].items[${itemIndex}]`
                                return (
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        key={`invoice${index}${itemIndex}${item?.description}`}
                                    >
                                        <Grid
                                            item
                                            container
                                            lg={4}
                                            md={6}
                                            sm={12}
                                        >
                                            <Field
                                                name={`${prefix}.unit`}
                                                type="number"
                                                required={true}
                                                component={FormField}
                                                label={
                                                    <FormattedMessage
                                                        id="orderDetails.support__documentation__No__of__units"
                                                        defaultMessage=" {itemName} No. Of Units"
                                                        values={{
                                                            itemName: formattedItemName(
                                                                item
                                                            ),
                                                        }}
                                                    />
                                                }
                                                category={`${gaCategory} : Commercial Invoice`}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            lg={4}
                                            md={6}
                                            sm={12}
                                        >
                                            <Field
                                                component={
                                                    FormSelectAutocomplete
                                                }
                                                name={`${prefix}.unitOfMeasure`}
                                                required={true}
                                                options={
                                                    formattedUnitOfMeasuresType
                                                }
                                                label={
                                                    <FormattedMessage
                                                        id="orderDetails.support__documentation__unit__of__measure"
                                                        defaultMessage=" {itemName} Unit of Measure"
                                                        values={{
                                                            itemName: formattedItemName(
                                                                item
                                                            ),
                                                        }}
                                                    />
                                                }
                                                category={`${gaCategory} : Commercial Invoice`}
                                            />
                                        </Grid>
                                    </Grid>
                                )
                            })
                        )}
                        <Grid
                            item
                            container
                            lg={4}
                            md={6}
                            sm={12}
                            className={classes.signature_button}
                        >
                            <ManageSignature
                                fieldName="signatureImage"
                                previewDimensions={{
                                    width: 326,
                                    height: 26,
                                }}
                                addTitle={
                                    <FormattedMessage
                                        id="locations.signatureManagment__addSignature"
                                        defaultMessage="Add Signature"
                                    />
                                }
                                replaceTitle={
                                    <FormattedMessage
                                        id="locations.signatureManagment__replaceSignature"
                                        defaultMessage="Replace Signature"
                                    />
                                }
                                gaCategory={`${gaCategory} : Commercial Invoice`}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
)

export default CommercialInvoiceForm

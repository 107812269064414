import { FormattedMessage } from "react-intl"

export const bookShipmentSteps = [
    {
        label: (
            <FormattedMessage
                id="bookShipment.basicInfoSummary__title"
                defaultMessage="Basic Info"
            />
        ),
        gaLabel: "Basic Info",
    },
    {
        label: (
            <FormattedMessage
                id="bookShipment.originSummary__title"
                defaultMessage="Origin"
            />
        ),
        gaLabel: "Origin",
    },
    {
        label: (
            <FormattedMessage
                id="bookShipment.destinationSummary__title"
                defaultMessage="Destination"
            />
        ),
        gaLabel: "Destination",
    },
    {
        label: (
            <FormattedMessage
                id="bookShipment.itemsSummary__title"
                defaultMessage="Line Items"
            />
        ),
        gaLabel: "Line Items",
    },
    {
        label: (
            <FormattedMessage
                id="bookShipment.rates__title"
                defaultMessage="Rates"
            />
        ),
        gaLabel: "Rates",
    },
    {
        label: (
            <FormattedMessage
                id="bookShipment.finalize__title"
                defaultMessage="Finalize"
            />
        ),
        gaLabel: "Finalize",
    },
    {
        label: (
            <FormattedMessage
                id="bookShipment.reviewAndBook"
                defaultMessage="Review and Book"
            />
        ),
        gaLabel: "Review and Book",
    },
]

import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles({
    bookViewButton: {
        marginRight: "10px",
    },
    headerCell: {
        whiteSpace: "nowrap",
    },
    tableRowAlternating: {
        height: "60px",
        "&:nth-of-type(even)": {
            backgroundColor: "red", // Light grey for odd rows
        },
    },
    expandContainer: {
        borderBottom: "solid 1px #E0E0E0",
    },
    iconButton: {
        color: "gray",
        padding: "3px",
    },
    bulkButtonGroup: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
    },
    buttonGrouping: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
    },
    sticky: left => ({
        position: "sticky",
        left: `${left}em`,
        background: "white",
        zIndex: 2,
    }),
    paginationContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    table: {
        maxHeight: "58vh",
    },
})

import React from "react"
import { Grid } from "@material-ui/core"
import OrderAddressPart from "./OrderAddressPart"
import HandlingUnitsTable from "../track/result/HandlingUnitsTable"
import { makeStyles } from "@material-ui/styles"
import { injectIntl } from "react-intl"
import OrderAccessorialsDisplay from "./AccessorialsDisplay"

const useStyles = makeStyles({
    expandContent: {
        padding: "4% 5% 5% 10%",
    },
    handlingUnits: {
        paddingTop: "2%",
    },

    accessorialsDisplay: {
        paddingLeft: "4%",
    },
})

const AdditionalDetails = ({
    shouldUseLocationAddress,
    normalizedShipment,
    intl,
    location,
    showAccessorials,
}) => {
    const classes = useStyles()
    const {
        origin,
        destination,
        handlingUnits,
        trackingNumber,
        tableName,
        preferredSystemOfMeasurement,
    } = normalizedShipment
    const isFreightDirect = location?.locationType === "FEDEX_DIRECT"
    return (
        <Grid item container className={classes.expandContent}>
            <Grid item container xs={6}>
                <OrderAddressPart
                    data={
                        shouldUseLocationAddress(normalizedShipment, "origin")
                            ? location
                            : origin
                    }
                    title={`${intl.formatMessage({
                        id: "generalTerms__from",
                        defaultMessage: "From",
                    })}:`}
                />
                <OrderAddressPart
                    data={
                        shouldUseLocationAddress(
                            normalizedShipment,
                            "destination"
                        )
                            ? location
                            : destination
                    }
                    title={`${intl.formatMessage({
                        id: "generalTerms__to",
                        defaultMessage: "To",
                    })}:`}
                />
            </Grid>
            {(origin?.accessorials?.length ||
                destination?.accessorials?.length) &&
            !isFreightDirect &&
            showAccessorials ? (
                <Grid
                    item
                    container
                    xs={6}
                    className={classes.accessorialsDisplay}
                >
                    <OrderAccessorialsDisplay
                        originAccessorials={origin.accessorials ?? []}
                        deliveryAccessorials={destination.accessorials ?? []}
                        id={`${tableName}Table__${trackingNumber}__accesorials`}
                    />
                </Grid>
            ) : null}
            {handlingUnits.length ? (
                <Grid item container className={classes.handlingUnits}>
                    <HandlingUnitsTable
                        handlingUnits={handlingUnits}
                        preferredSystemOfMeasurement={
                            preferredSystemOfMeasurement
                        }
                        isOrder={tableName === "orders"}
                    />
                </Grid>
            ) : null}
        </Grid>
    )
}

export default injectIntl(AdditionalDetails)

import React, { Fragment } from "react"
import { MassShareAttachments } from "../../components/track/result/attachments/massShareAttachments"
import Modal from "@material-ui/core/Modal"
import { useMultiAttachmentsContext } from "../providers/multiAttachmentsProvider"

export const MultiAttachmentsModalsWrapper = () => {
    const {
        closeMultiAttDialog,
        shareDialogOpen,
        shareInitialValues,
        internalTrackingNumber,
        attachments,
        attachmentsLoading,
        contacts,
        managedFile,
        isFromTrailerManifestPage,
        gaCategory,
    } = useMultiAttachmentsContext()

    return (
        <Fragment>
            <Modal open={shareDialogOpen} onClose={closeMultiAttDialog}>
                <MassShareAttachments
                    attachments={attachments}
                    selectedShipmentIdList={internalTrackingNumber}
                    onClose={closeMultiAttDialog}
                    initialValues={shareInitialValues}
                    loading={attachmentsLoading}
                    contacts={contacts}
                    isFromTrailerManifestPage={isFromTrailerManifestPage}
                    managedFile={managedFile}
                    gaCategory={gaCategory}
                />
            </Modal>
        </Fragment>
    )
}

import { get } from "lodash"
import { goFetch } from "../http"
import { typedError } from "./index"
import { userReceived, userUpdateOptions } from "./user"

export const TERMS_VERSION_RECEIVED = "TERMS_VERSION_RECEIVED"
export const TERMS_LOAD = "TERMS_LOAD"
export const TERMS_LOAD_ERROR = "TERMS_LOAD_ERROR"
export const TERMS_RECEIVED = "TERMS_RECEIVED"
export const ACCEPT_TERMS_ERROR = "ACCEPT_TERMS_ERROR"

const termsLoad = () => ({ type: TERMS_LOAD })
const termsError = error => typedError(TERMS_LOAD_ERROR, error)
const termsReceived = ({ version, description }) => ({
    type: TERMS_RECEIVED,
    version,
    description,
})
const acceptTermsError = error => typedError(ACCEPT_TERMS_ERROR, error)

export function getTerms(language) {
    return async (dispatch, getState) => {
        const lang =
            language ||
            getState()?.form?.signup?.values?.profile?.preferences?.language ||
            getState()?.user?.profile?.preferences?.language ||
            "en-us"
        if (
            (getState()?.terms?.isFetching || getState()?.terms?.isLoaded) &&
            lang === getState()?.terms?.language
        )
            return
        dispatch(termsLoad())
        try {
            const { data: terms } = await goFetch(`/terms`, {
                params: { lang },
            })
            return dispatch(termsReceived(terms))
        } catch (error) {
            return dispatch(termsError(error))
        }
    }
}

export function acceptTerms() {
    return async (dispatch, getState) => {
        const state = getState()
        const userId = get(state, "user.profile.id")
        const { version } = state.terms
        const body = {
            id: userId,
            termsAndConditions: { acceptedVersion: version },
        }
        try {
            const { data: user } = await goFetch(
                `/users/${userId}`,
                userUpdateOptions(body),
                true
            )
            return dispatch(userReceived(user))
        } catch (error) {
            dispatch(acceptTermsError(error))
            return false
        }
    }
}

const isPresentMatchingErrors = (ratesErrors, substringsToMatch) => {
    if (!ratesErrors) {
        return false
    }
    return ratesErrors
        .filter(x => x?.errorMessages)
        .some(x => {
            return x.errorMessages
                .filter(y => y?.message)
                .some(y =>
                    substringsToMatch.some(substring =>
                        y.message.includes(substring)
                    )
                )
        })
}

export { isPresentMatchingErrors }

import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ReactTable from "react-table"
import Grid from "@material-ui/core/Grid"
import { DownloadCSVButton } from "../util"
import moment from "moment-timezone"

export class StatisticsUserTablePresentation extends Component {
    render() {
        const { data } = this.props

        const columns = [
            { Header: "Full Name", accessor: "name" },
            { Header: "Signup Date", accessor: "signupDate" },
            {
                Header: "Last Login (CST/CDT)",
                accessor: "lastLogin",
            },
            { Header: "CPG", accessor: "cpg" },
            { Header: "Company Name", accessor: "companyName" },
            {
                Header: "Fedex Freight #",
                accessor: "fedExFreightAccount",
            },
            {
                Header: "Fedex Bill-to #",
                accessor: "fedExBillToAccount",
            },
            {
                Header: "Total Shipments",
                accessor: "shipmentCount",
            },
            {
                Header: "Total FedEx Shipments",
                accessor: "fedExShipmentCount",
            },
            {
                Header: "First Shipment (CST/CDT)",
                accessor: "firstShipment",
            },
            {
                Header: "Last Shipment (CST/CDT)",
                accessor: "lastShipment",
            },
            { Header: "Total Quotes", accessor: "quoteCount" },
            {
                Header: "Last Quote (CST/CDT)",
                accessor: "lastQuote",
            },
            { Header: "Email", accessor: "email" },
            { Header: "Phone Number", accessor: "phone" },
        ]

        return (
            <Grid item container>
                <Grid item container style={{ marginBottom: "10px" }}>
                    <DownloadCSVButton
                        data={data}
                        name="user_report"
                        sortBy="lastLogin"
                    />
                </Grid>
                <Grid item container>
                    <ReactTable
                        data={data}
                        columns={columns}
                        defaultPageSize={25}
                        showPagination
                        pageSizeOptions={[10, 25, 50, 100]}
                        defaultSorting={[
                            {
                                id: "lastLogin",
                                desc: true,
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        )
    }
}

StatisticsUserTablePresentation.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
}

const toCST = date => date && moment.tz(date, "America/Chicago").format("L LTS")

const processData = data =>
    data.map(
        ({
            created_at,
            lastLogin,
            firstShipment,
            lastShipment,
            lastQuote,
            cpgCode,
            pickupContact,
            ...item
        }) => ({
            ...item,
            signupDate: toCST(created_at),
            lastLogin: toCST(lastLogin),
            cpg: cpgCode,
            firstShipment: toCST(firstShipment),
            lastShipment: toCST(lastShipment),
            lastQuote: toCST(lastQuote),
            email: pickupContact?.email?.email_address,
            phone: pickupContact?.phone?.phone_number,
        })
    )

const mapStateToProps = (_, { data }) => ({
    data: processData(data),
})

export const StatisticsUserTable = connect(mapStateToProps)(
    StatisticsUserTablePresentation
)

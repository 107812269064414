import { get } from "lodash"
import {
    INIT_BOOK,
    CLEAR_BOOK,
    INIT_BOOK_TIMEZONE,
    SAVE_SHIPMENT_ERROR,
} from "../actions/book"
import { CANCEL_SHIPMENT_ERROR } from "../actions/book-shipment"
import { USER_DISAUTHENTICATED } from "../actions/user"

export function book(
    state = {
        rate: {},
        origin: {
            address: {},
            contact: {},
            pickup: {},
        },
        destination: {
            address: {},
            contact: {},
            pickup: {},
        },
        identifiers: {},
        items: [],
        cpg: undefined,
        cpgName: undefined,
        isHazMat: false,
    },
    action = {}
) {
    switch (action.type) {
        case CLEAR_BOOK:
        case USER_DISAUTHENTICATED:
            return book()
        case INIT_BOOK: {
            const { type, ...payload } = action
            return { ...state, ...payload }
        }
        case INIT_BOOK_TIMEZONE:
            return {
                ...state,
                origin: {
                    ...state.origin,
                    readyTime: action.readyTime,
                    isPastPickup: action.isPastPickup,
                },
            }
        case SAVE_SHIPMENT_ERROR: {
            const message = get(action, "error.response.message")
            if (message) {
                if (message.errors) {
                    return {
                        ...state,
                        errorMessage: message.errors.map(error =>
                            error.diagnostic
                                ? error.diagnostic.split(":")[1]
                                : error.message
                        ),
                        saving: false,
                    }
                }
                return {
                    ...state,
                    errorMessage: [message],
                    saving: false,
                }
            }
            return {
                ...state,
                errorMessage: [`${action.error}`],
                saving: false,
            }
        }

        case CANCEL_SHIPMENT_ERROR: {
            const message = get(action, "error.response.message")
            if (message) {
                if (message.errors) {
                    return {
                        ...state,
                        errorMessage: message.errors.map(error =>
                            error.diagnostic
                                ? error.diagnostic.split(":")[1]
                                : error.message
                        ),
                        saving: false,
                    }
                }
                return {
                    ...state,
                    errorMessage: [message],
                    saving: false,
                }
            }
            return {
                ...state,
                errorMessage: [`${action.error}`],
                saving: false,
            }
        }
        default:
            return state
    }
}

import React, { Fragment, Component } from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { displayFullPhone } from "../../actions/contact"
import PropTypes from "prop-types"

const DisplayContact = ({ contact, disabled, hideEmail }) => {
    const textColor = disabled ? "textSecondary" : undefined
    return (
        <Grid xs={12} item>
            <Typography color={textColor} variant="subtitle1">
                {contact?.name ?? ""}
            </Typography>
            <Typography color={textColor}>
                {displayFullPhone(contact?.phone) ?? ""}
            </Typography>
            {!hideEmail && (
                <Typography variant="body2" color={textColor}>
                    {contact?.email ?? ""}
                </Typography>
            )}
        </Grid>
    )
}
DisplayContact.propTypes = {
    disabled: PropTypes.bool,
    contact: PropTypes.object.isRequired,
}

DisplayContact.defaultProps = {
    disabled: false,
}

export default DisplayContact

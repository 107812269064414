import { Component } from "react"
import ErrorRedirectScreen from "./ErrorRedirectScreen"

export default class InnerErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false, errorMessage: "" }
    }

    componentDidCatch(error) {
        this.setState({ hasError: true, errorMessage: error?.message })
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorRedirectScreen errorMessage={this.state.errorMessage} />
            )
        }
        return this.props.children
    }
}

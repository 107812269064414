import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { FormattedMessage } from "react-intl"
import Dropzone from "react-dropzone"
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
    dropZone: {
        padding: "30px",
        border: "1px dashed #D4D4D4",
        borderRadius: "5px",
        "&:hover": {
            cursor: "pointer",
        },
    },
    dropZone__root: {
        outline: "none",
    },
})

export const UploadDropzone = withStyles(styles)(
    ({
        classes,
        onStage,
        accept = "image/png, application/pdf, image/jpeg",
    }) => (
        <Dropzone
            onDrop={acceptedFiles => onStage(acceptedFiles[0])}
            accept={accept}
            maxFiles={1}
            multiple={false}
            maxSize={5000000}
        >
            {({ getRootProps, getInputProps }) => {
                return (
                    <section style={{ width: "100%" }}>
                        <div
                            {...getRootProps()}
                            className={classes.dropZone__root}
                        >
                            <Grid
                                item
                                container
                                className={classes.dropZone}
                                justify="center"
                            >
                                <input {...getInputProps()} />
                                <Grid item>
                                    <Typography variant="body2">
                                        <FormattedMessage
                                            id="documents__attachmentInstructions"
                                            defaultMessage="Click Here, or Drag and Drop, to add files"
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </section>
                )
            }}
        </Dropzone>
    )
)

import React from "react"
import { FormattedMessage } from "react-intl"
import {
    Box,
    Button,
    Checkbox,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    makeStyles,
} from "@material-ui/core"
import { useOrdersContext } from "../../context/providers/OrdersProvider"
import { ArrowDownward } from "@material-ui/icons"
import OrdersRatesHeaders from "./OrdersRatesHeaders"
const useStyles = makeStyles(theme => ({
    centered: {
        textAlign: "center",
    },
    expandCell: {
        minWidth: "60px",
    },

    orderDetails: {
        backgroundColor: "white",
        borderRight: true,
    },
    ratesTableCells: {
        height: "100%",
        width: "234px",
        padding: "4px 0px",
        borderBottom: "none",
        backgroundColor: "white",
    },
    ratesTableColumnHeaders: {
        padding: "4px 0px",
        backgroundColor: "white",
    },
    ratesTableColumnHeadersText: {
        fontWeight: "bold",
        textAlign: "center",
    },
    stickyHead: {
        position: "sticky",
        top: 0,
        zIndex: 5,
        backgroundColor: "white",
    },
}))

const OrdersTableHead = ({
    carriers,
    bulkOrders,
    displayBulkActionButton,
    ordersList,
    orderStatus,
    selectAllClicked,
    selectAllOrders,
    tableColumns,
    selectAllRates,
    deselectableRates,
    deselectAllRates,
}) => {
    const classes = useStyles()
    const { ordersRatesHeaders } = useOrdersContext()

    return (
        <TableHead className={classes.stickyHead}>
            <TableRow className={classes.stickyHead}>
                {tableColumns.map((column, index) => (
                    <TableCell
                        key={index}
                        align={column.align}
                        padding={
                            column?.type === "expand" ||
                            column?.type === "checkbox"
                                ? "none"
                                : "normal"
                        }
                        className={
                            column.type === "expand" ? classes.expandCell : null
                        }
                    >
                        {column?.label ? (
                            <Typography variant="subtitle1">
                                <FormattedMessage {...column.label} />
                            </Typography>
                        ) : column?.type === "checkbox" &&
                          orderStatus !== "all" ? (
                            <Checkbox
                                disabled={ordersList.length === 0}
                                onClick={() => selectAllOrders(ordersList)}
                                checked={selectAllClicked}
                                indeterminate={
                                    bulkOrders.length >= 1 &&
                                    ordersList.length !== bulkOrders.length
                                }
                            />
                        ) : column?.type === "expand" &&
                          orderStatus !== "all" ? (
                            displayBulkActionButton(bulkOrders, orderStatus)
                        ) : null}
                    </TableCell>
                ))}
                {orderStatus === "readyToBook"
                    ? ordersRatesHeaders.map(obj => {
                          return (
                              <TableCell align="center" key={obj.key}>
                                  {deselectableRates.includes(obj?.key) ? (
                                      <Button
                                          style={{ margin: "5px" }}
                                          variant="outlined"
                                          color="secondary"
                                          onClick={() => {
                                              deselectAllRates(obj?.key)
                                          }}
                                      >
                                          <Box sx={{ pr: "2px" }}>
                                              <FormattedMessage
                                                  defaultMessage="Deselect"
                                                  id="orders.deselect"
                                              />
                                          </Box>
                                          <ArrowDownward fontSize="small" />
                                      </Button>
                                  ) : (
                                      <Button
                                          style={{ margin: "5px" }}
                                          variant="outlined"
                                          color="secondary"
                                          onClick={() => {
                                              selectAllRates(obj?.key)
                                          }}
                                      >
                                          <Box sx={{ pr: "2px" }}>
                                              <FormattedMessage
                                                  defaultMessage="Select"
                                                  id="orders.selectAll"
                                              />
                                          </Box>
                                          <ArrowDownward fontSize="small" />
                                      </Button>
                                  )}
                              </TableCell>
                          )
                      })
                    : null}
            </TableRow>
            {orderStatus === "readyToBook" ? (
                <OrdersRatesHeaders
                    carriers={carriers}
                    tableColumns={tableColumns}
                />
            ) : null}
        </TableHead>
    )
}

export default OrdersTableHead

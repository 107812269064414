import React, { useEffect, useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { Collapse, IconButton, Typography, Checkbox } from "@material-ui/core"
import { defineMessages, FormattedMessage, injectIntl } from "react-intl"
import { get } from "lodash"
import { TablePagination } from "@material-ui/core"
import { connect } from "react-redux"
import { makeStyles } from "@material-ui/styles"
import moment from "moment"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { useAttachmentsContext } from "../../context/providers/attachmentsProvider"
import AdditionalDetails from "../orders/AdditionalDetails"
import ConfirmActionModal from "../common/ConfirmActionModal"
import { closeModal, openModal } from "../../actions/modal"
import { currencyLabel } from "../util/currencyLabel"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const useStyles = makeStyles({
    bookViewButton: {
        marginRight: "10px",
    },
    headerCell: {
        whiteSpace: "nowrap",
    },
    tableRow: {
        borderBottom: "none",
    },
    expandCell: {
        minWidth: "60px",
    },
    expandContainer: {
        borderBottom: "solid 1px #E0E0E0",
    },
    iconButton: {
        color: "gray",
        padding: "3px",
    },
    bulkButtonGroup: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
    },
    buttonGrouping: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "row",
        alignItems: "center",
    },
})

const messages = defineMessages({
    proNumber: {
        id: "trailerManifest.proNumber",
        defaultMessage: "Tracking ID",
    },
    locationName: {
        id: "orders.locationName",
        defaultMessage: "Location",
    },
    bolNumber: {
        id: "trailerManifest.bolNumber",
        defaultMessage: "BOL",
    },
    shipmentDate: {
        id: "trailerManifest.shipmentDate",
        defaultMessage: "Shipment Date",
    },
    originCompanyName: {
        id: "orders.originCompanyName",
        defaultMessage: "Origin",
    },
    destinationCompanyName: {
        id: "orders.destinationCompanyName",
        defaultMessage: "Destination",
    },
    rate: {
        id: "trailerManifest.rate",
        defaultMessage: "Rate",
    },
    createdDate: {
        id: "trailerManifest.createdAt",
        defaultMessage: "Status & Date",
    },
    viewShipment: {
        id: "generalTerms__view",
        defaultMessage: "View",
    },
    blankHeader: {
        id: "generalTerms__blankSpace",
        defaultMessage: " ",
    },
})

const ShipmentsTable = ({
    userLocations,
    gaCategory,
    shipments = [],
    creating,
    onChangePage,
    onChangeRowsPerPage,
    pageNumber,
    rowsPerPage,
    shipmentCount = 0,
    checkedShipments,
    setCheckedShipments,
    openModal,
    closeModal,
}) => {
    const classes = useStyles()
    const { logGAEvent } = useGAContext()

    const [isExpanded, setIsExpanded] = useState(null)

    useEffect(() => {
        if (!creating) {
            setCheckedShipments([])
        }
    }, [creating])

    const findLocation = locationId => {
        const activeLocation = userLocations.find(
            location => location._id === locationId
        )

        return activeLocation
    }

    const tableColumns = [
        { label: "", id: "checkbox", type: "checkbox", align: "center" },
        {
            label: "",
            id: "collapse",
            type: "expand",
            align: "left",
        },
        {
            label: messages.proNumber,
            value: "identifiers.proNumber",
            align: "left",
            padding: "text",
        },
        {
            label: messages.bolNumber,
            value: "identifiers.bolNumber",
            align: "left",
            padding: "text",
        },
        {
            label: messages.locationName,
            value: "query.locationId",
            type: "locationName",
            align: "left",
        },
        {
            label: messages.shipmentDate,
            value: "query.pickupDate",
            type: "date",
            align: "left",
        },
        {
            label: messages.originCompanyName,
            value: "shipment.origin.name",
            type: "companyNameOrigin",
            align: "left",
        },
        {
            label: messages.destinationCompanyName,
            value: "shipment.destination.name",
            type: "companyNameDestination",
            align: "left",
        },
        {
            label: messages.rate,
            valueAlt: "shipment.rate.rateQuoteDetail.total",
            value: "shipment.rate.rateQuoteDetail.totalInPreferredCurrency",
            currencyCode: "shipment.rate.preferredCurrencyCode",
            align: "left",
            type: "currency",
            padding: "text",
        },
        {
            label: messages.createdDate,
            value: "created_at",
            type: "dateAndStatus",
            align: "left",
        },
    ]

    const ShipmentCellContent = ({ shipment, column }) => {
        switch (column.type) {
            case "date":
                const date = get(shipment, column.value)
                return date ? (
                    <Typography variant="body2">
                        {moment(date).format("MM/DD/YYYY")}
                    </Typography>
                ) : null
            case "dateAndStatus":
                const dateForStatus = get(shipment, column.value)
                return dateForStatus ? (
                    <div>
                        <Typography variant="body2">Created</Typography>
                        <Typography variant="body2">
                            {moment(dateForStatus).format("MM/DD/YYYY")}
                        </Typography>
                    </div>
                ) : null
            case "locationName":
                const activeLocation = findLocation(get(shipment, column.value))
                return (
                    <Typography variant="body2">
                        {activeLocation?.billingContact?.companyName}
                    </Typography>
                )
            case "companyNameOrigin":
                return (
                    <Typography variant="body2">
                        {get(shipment, column.value)}
                    </Typography>
                )

            // eslint-disable-next-line
            case "companyNameDestination":
                return (
                    <Typography variant="body2">
                        {get(shipment, column.value)}
                    </Typography>
                )

            // eslint-disable-next-line
            case "currency":
                return (
                    <Typography variant="body2">
                        {get(shipment, column.value) ||
                        get(shipment, column.valueAlt)
                            ? currencyLabel(
                                  get(shipment, column.currencyCode),
                                  get(shipment, column.value) ??
                                      get(shipment, column.valueAlt)
                              )
                            : null}
                    </Typography>
                )
            case "expand":
                return (
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            if (
                                isExpanded ===
                                shipment?.identifiers?.internalTrackingNumber
                            ) {
                                setIsExpanded(null)
                                logGAEvent(gaCategory, "Item Collapsed")
                            } else {
                                setIsExpanded(
                                    shipment?.identifiers
                                        ?.internalTrackingNumber
                                )
                                logGAEvent(gaCategory, "Item Expanded")
                            }
                        }}
                    >
                        {isExpanded === shipment?.id ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                )
            case "checkbox":
                return creating ? (
                    <Checkbox
                        checked={checkedShipments.includes(
                            shipment?.identifiers?.internalTrackingNumber
                        )}
                        onClick={() => {
                            logGAEvent(gaCategory, "Item Selected")
                            if (
                                checkedShipments.includes(
                                    shipment?.identifiers
                                        ?.internalTrackingNumber
                                )
                            ) {
                                removeCheckedShiment(shipment)
                            } else {
                                if (
                                    !shipment?.shipment?.attachments.find(
                                        item =>
                                            item.attachmentFileType ===
                                            "trailerManifest"
                                    )
                                ) {
                                    addCheckedShipment(shipment)
                                } else {
                                    openModal(
                                        <ConfirmActionModal
                                            message={
                                                <FormattedMessage
                                                    id="trailerManifest.actionModal_message"
                                                    defaultMessage="A manifest containing this shipment has previously been created. Remove from previous manifest and add to current?"
                                                />
                                            }
                                            title={
                                                <FormattedMessage
                                                    id="trailerManifest.actionModal_title"
                                                    defaultMessage="Confirm Manifest Shipment Add"
                                                />
                                            }
                                            handleConfirm={async () => {
                                                addCheckedShipment(shipment)
                                                closeModal()
                                            }}
                                            gaCategory={gaCategory}
                                        />
                                    )
                                }
                            }
                        }}
                    />
                ) : null
            default:
                return (
                    <Typography variant="body2">
                        {get(shipment, column.value)}
                    </Typography>
                )
        }
    }

    const removeCheckedShiment = shipment => {
        const tempCopy = [...checkedShipments]
        tempCopy.splice(
            checkedShipments.indexOf(
                shipment?.identifiers?.internalTrackingNumber
            ),
            1
        )
        setCheckedShipments(tempCopy)
    }

    const addCheckedShipment = shipment => {
        setCheckedShipments([
            ...checkedShipments,
            shipment?.identifiers?.internalTrackingNumber,
        ])
    }

    const shouldUseLocationAddress = (shipment, leg) => {
        const location = findLocation(get(shipment, "locationId"))
        const isShipperAcct = Boolean(
            !location?.fedexBillToAccount && location?.fedexFreightAccount
        )

        if (leg === "origin") {
            return isShipperAcct && shipment.mode === "OUTBOUND"
        } else if (leg === "destination") {
            return isShipperAcct && shipment.mode === "INBOUND"
        }
    }

    return (
        <Table size="large" aria-label="Shipments Table">
            <TableHead>
                <TableRow>
                    {tableColumns.map(column => (
                        <TableCell
                            align={column.align}
                            padding={
                                column?.type === "expand" ||
                                column?.type === "checkbox"
                                    ? "none"
                                    : "normal"
                            }
                            className={
                                column.type === "expand"
                                    ? classes.expandCell
                                    : null
                            }
                        >
                            {column?.label ? (
                                <Typography variant="subtitle1">
                                    <FormattedMessage {...column.label} />
                                </Typography>
                            ) : null}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {shipments.map(shipment => {
                    const location = findLocation(
                        get(shipment, "query.locationId")
                    )
                    return (
                        <>
                            <TableRow>
                                {tableColumns.map(column => (
                                    <TableCell
                                        align={column.align}
                                        className={classes.tableCell}
                                        padding={
                                            column.type === "expand" ||
                                            column.type === "checkbox"
                                                ? "none"
                                                : "normal"
                                        }
                                        classes={{ root: classes.tableRow }}
                                    >
                                        <ShipmentCellContent
                                            shipment={shipment}
                                            column={column}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                            <tr className={classes.expandContainer}>
                                <TableCell
                                    style={{
                                        paddingBottom: 0,
                                        paddingTop: 0,
                                    }}
                                    colSpan={10}
                                >
                                    <Collapse
                                        in={
                                            isExpanded ===
                                            shipment?.identifiers
                                                ?.internalTrackingNumber
                                        }
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <AdditionalDetails
                                            shouldUseLocationAddress={
                                                shouldUseLocationAddress
                                            }
                                            normalizedShipment={{
                                                ...shipment,
                                                origin:
                                                    shipment.shipment.origin,
                                                destination:
                                                    shipment.shipment
                                                        .destination,
                                                preferredSystemOfMeasurement:
                                                    shipment.query
                                                        .preferredSystemOfMeasurement,
                                                trackingNumber:
                                                    shipment?.identifiers
                                                        ?.internalTrackingNumber,
                                            }}
                                            showAccessorials={false}
                                            location={location}
                                            tableName={"trailerManifest"}
                                        />
                                    </Collapse>
                                </TableCell>
                            </tr>
                        </>
                    )
                })}
                <TablePagination
                    onChangePage={onChangePage}
                    page={pageNumber - 1}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 25, 50]}
                    count={shipmentCount}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    labelRowsPerPage={
                        <FormattedMessage
                            id="orders.rows"
                            defaultMessage="Rows Per Page"
                        />
                    }
                />
            </TableBody>
        </Table>
    )
}

function mapStateToProps(state) {
    const userLocations = state?.user?.profile?.locations
    return {
        userLocations,
    }
}

function mapDispatchToProps(dispatch, props) {
    return {
        openModal: async node => dispatch(openModal(node)),
        closeModal: async () => dispatch(closeModal()),
    }
}

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(ShipmentsTable)
)

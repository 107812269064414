import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import {
    change,
    formValues,
    touch,
    untouch,
    resetSection,
    clearSubmitErrors,
    ReduxFormContext,
} from "redux-form"
import { combineValidators } from "revalidate"
import { get } from "lodash"
import { hasError } from "revalidate/assertions"
import { defineMessages } from "react-intl"
import AddressForm from "./form"
import AddressDialog from "./dialog"
import { isRequired, isRequiredIf } from "../../../actions/validation"
import { reduxFormAction, reduxFormProps } from "../../../misc"
import { fetchAddressSuggestions } from "../../../actions/address"
import { requestPostalCode } from "../../../actions/postal-code"
import { connect } from "react-redux"
import { searchContacts } from "../../../actions/contact"
import { contactValidator } from "../contactForm"
import ResiCheckDialog from "../../quotesPage/dialog/resiCheckDialog"
import {
    updateRateResiCheck,
    resiAddressRequest,
} from "../../../actions/quote-request"
import { sensibleReadyTime } from "../selectors"

export const messages = defineMessages({
    nameField: {
        id: "generalTerms__companyName",
        defaultMessage: "Company Name",
    },
    address1OrAddress2Field: {
        id: "generalTerms__address1OrAddress2",
        defaultMessage: "Address 1 or Address 2",
    },
    cityField: { id: "generalTerms__city", defaultMessage: "City" },
    stateField: { id: "generalTerms__state", defaultMessage: "State" },
    postalCodeField: {
        id: "generalTerms__postalCode",
        defaultMessage: "Postal Code",
    },
})

const isRequiredIfStreet1Missing = (prefix = "") =>
    isRequiredIf(
        values =>
            get(
                values,
                prefix ? `${prefix}.address.street1` : "address.street1"
            ) === ""
    )

const isRequiredIfStreet2Missing = (prefix = "") =>
    isRequiredIf(
        values =>
            get(
                values,
                prefix ? `${prefix}.address.street2` : "address.street2"
            ) === ""
    )

export const addressValidator = (prefix = "") => ({
    name: isRequired({ field: messages.nameField }),
    street1: isRequiredIfStreet2Missing(prefix)({
        field: messages.address1OrAddress2Field,
    }),
    street2: isRequiredIfStreet1Missing(prefix)({
        field: messages.address1OrAddress2Field,
    }),
    city: isRequired({ field: messages.cityField }),
    state: isRequired({ field: messages.stateField }),
    postalCode: isRequired({ field: messages.postalCodeField }),
})

const validator = combineValidators({
    address: addressValidator(),
    contact: contactValidator("address.country"),
})

class Address extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dialogOpen: false,
            suggestions: null,
            selectedName: "",
            resiTimeoutId: null,
            isResiDialogOpen: false,
            hasBeenIgnored: false,
        }
        this.fields = [
            "name",
            "street1",
            "street2",
            "city",
            "state",
            "country",
            "postalCode",
        ]
            .map(key => `address.${key}`)
            .concat(
                ["name", "phone.phone_number", "phone.extension", "email"].map(
                    key => `contact.${key}`
                )
            )
    }

    componentDidMount() {
        const { form, sectionPrefix } = reduxFormProps(this.context)
        this.form = form
        this.sectionPrefix = sectionPrefix
    }

    checkIfValid(newProps) {
        const { address, contact } = newProps || this.props
        return !hasError(validator({ address, contact }))
    }

    componentWillUnmount() {
        clearTimeout(this?.state?.resiTimeoutId)
    }

    componentWillReceiveProps(newProps) {
        if (
            this.props?.address?.postalCode !== newProps?.address?.postalCode ||
            this.props?.address?.country !== newProps?.address?.country
        ) {
            this.props.requestPostalCode(
                newProps?.address?.postalCode,
                newProps?.address?.country
            )
        }
    }

    handleEditMode(editMode) {
        this.setState({ editMode })
    }

    handleOnCancel() {
        reduxFormAction(this.context, ["address", "contact"], this.props.cancel)
        reduxFormAction(this.context, this.fields, this.props.untouch)
        this.setState({ editMode: this.state.initEditMode })
    }

    handleDialogCancel() {
        this.setState({ suggestions: null, dialogOpen: false })
    }

    handleDialogClose() {
        if (!this.state.dialogOpen) return
        this.setState({ suggestions: null, dialogOpen: false })
        this.handleEditMode(false)
    }

    async fetchSuggestions() {
        const { address } = this.props
        const suggestions = await fetchAddressSuggestions(address, true)
        if (!suggestions) {
            this.setState({ editMode: false })
            this.handleEditMode(false)
        } else {
            this.setState({ dialogOpen: true, suggestions })
        }
    }

    handleDialogClick(address) {
        const fields = ["street1", "street2", "city", "state", "postalCode"]
        reduxFormAction(
            this.context,
            fields,
            this.props.changeField,
            address,
            "address"
        )
        this.handleDialogClose()
    }

    handleOnDone() {
        reduxFormAction(this.context, this.fields, this.props.touch)
        const valid = this.checkIfValid(this.props)
        if (valid && !this.props.readOnly) this.fetchSuggestions()
    }

    handleAddressSelect = (mode, item) => {
        const {
            changeField,
            clearSubmitErrors,
            readOnly,
            setResiCheckInProgress,
            pickupDate,
            originTimezone,
        } = this.props

        this.setState({ selectedName: get(item, "name") })
        if (!readOnly) {
            changeField(
                "bolEntry",
                `${mode}.address.city`,
                get(item, "address.city")
            )
            changeField(
                "bolEntry",
                `${mode}.address.street1`,
                get(item, "address.street1")
            )
            changeField(
                "bolEntry",
                `${mode}.address.street2`,
                get(item, "address.street2")
            )
        }
        changeField("bolEntry", `${mode}.address.name`, get(item, "name"))
        changeField(
            "bolEntry",
            `${mode}.contact.name`,
            get(item, "contact.name")
        )
        changeField(
            "bolEntry",
            `${mode}.contact.phone.phone_number`,
            item?.contact?.phone?.phoneNumber
        )
        changeField(
            "bolEntry",
            `${mode}.contact.phone.extension`,
            item?.contact?.phone?.extension
        )
        changeField(
            "bolEntry",
            `${mode}.contact.email`,
            get(item, "contact.email.emailAddress")
        )
        if (mode === "origin" && item?.contact?.pickupContact) {
            changeField(
                "bolEntry",
                `${mode}.pickup.name`,
                item?.contact?.pickupContact?.name
            )
            changeField(
                "bolEntry",
                `${mode}.pickup.phone.phone_number`,
                item?.contact?.pickupContact?.phone?.phoneNumber
            )
            changeField(
                "bolEntry",
                `${mode}.pickup.phone.extension`,
                item?.contact?.pickupContact?.phone?.extension
            )
            changeField(
                "bolEntry",
                `${mode}.pickup.email`,
                item?.contact?.pickupContact?.email?.emailAddress
            )

            changeField(
                "bolEntry",
                `${mode}.pickup.email`,
                item?.contact?.pickupContact?.email?.emailAddress
            )

            changeField(
                "bolEntry",
                `${mode}.closeTime`,
                item?.contact?.pickupContact?.closeTime
            )

            changeField(
                "bolEntry",
                `${mode}.readyTime`,
                sensibleReadyTime(
                    item?.contact?.pickupContact?.readyTime,
                    pickupDate,
                    originTimezone
                )
            )
        }
        clearSubmitErrors()

        clearTimeout(this?.state?.resiTimeoutId)
        const resiTimeoutId = setTimeout(this.runResiCheck, 1000)
        this.setState({ resiTimeoutId })
        if (mode !== "origin") {
            setResiCheckInProgress(true)
        }
    }

    runResiCheck = async () => {
        const {
            setResiCheckInProgress,
            mode,
            deliveryAccessorials,
            currentDestination,
        } = this.props
        if (mode === "origin") {
            return
        }
        setResiCheckInProgress(false)
        this.setState({ resiTimeoutId: null })

        let needsResiModal = false

        if (
            !deliveryAccessorials.includes("RESDEL") &&
            currentDestination?.address
        ) {
            try {
                const addr = currentDestination?.address
                const isResidentialAddress = await resiAddressRequest(addr)
                if (isResidentialAddress) {
                    needsResiModal = true
                }
            } catch (err) {
                //err for resi check
            }
        }

        if (needsResiModal) {
            this.setState({ isResiDialogOpen: true })
        } else {
            try {
                // await this.submitForm({ ...values, resiAccessorials: [] })
            } catch (err) {
                // if (err?.props?.id && err?.props?.defaultMessage) {
                //     setSnackbar(
                //         "error",
                //         <FormattedMessage {...err?.props} />
                //     )
                // }
            }
        }
    }

    handleResiCheck = () => {
        const {
            setResiCheckInProgress,
            carrierCode,
            isFreightDirect,
            mode,
        } = this.props
        if (
            !this.state.hasBeenIgnored &&
            (carrierCode === "FXNL" || carrierCode === "FXFE") &&
            !isFreightDirect &&
            mode !== "origin"
        ) {
            clearTimeout(this?.state?.resiTimeoutId)
            const resiTimeoutId = setTimeout(this.runResiCheck, 1500)
            this.setState({ resiTimeoutId })
            setResiCheckInProgress(true)
        }
    }

    closeResiDialog = () => {
        this.setState({ isResiDialogOpen: false, hasBeenIgnored: true })
    }

    render() {
        const {
            readOnly,
            address,
            searchInProgress,
            searchTerm,
            performContactSearch,
            cities,
            isNameDirty,
            currentName,
            setResiCheckInProgress,
            deliveryAccessorials,
            resiChips,
            updateRate,
        } = this.props
        const { city, state, postalCode, country } = address || {}
        const { selectedName } = this.state

        return (
            <Fragment>
                <AddressForm
                    {...this.props}
                    {...{ city, state, postalCode, country }}
                    onCancel={() => this.handleOnCancel()}
                    onDone={() => this.handleOnDone()}
                    readOnly={readOnly}
                    searchInProgress={searchInProgress}
                    searchTerm={searchTerm}
                    performContactSearch={performContactSearch}
                    handleAddressSelect={this.handleAddressSelect}
                    cities={cities}
                    isNameDirty={isNameDirty && selectedName !== currentName}
                    setResiCheckInProgress={setResiCheckInProgress}
                    handleAddressChange={this.handleResiCheck}
                    handleAddressFocus={this.handleResiCheck}
                />
                <AddressDialog
                    open={this.state.dialogOpen}
                    suggestions={this.state.suggestions}
                    address={address}
                    onCancel={() => this.handleDialogCancel()}
                    onClose={() => this.handleDialogClose()}
                    onClick={value => this.handleDialogClick(value)}
                />
                <ResiCheckDialog
                    isResiDialogOpen={this.state.isResiDialogOpen}
                    getRateSubmit={updateRate}
                    currentForm={"bolEntry"}
                    onIgnore={this.closeResiDialog}
                    deliveryAccessorials={deliveryAccessorials}
                    resiChips={resiChips}
                    resiChipsPrefix={"destination.selectedResiAccessorials"}
                />
            </Fragment>
        )
    }
}

Address.propTypes = {
    readOnly: PropTypes.bool,
    pristine: PropTypes.bool,
}

Address.defaultProps = {
    readOnly: false,
}

Address.contextType = ReduxFormContext

const mapStateToProps = (state, props) => ({
    deliveryAccessorials: state?.form?.bolEntry?.values?.search?.deliveryAccessorials.map(
        accessorial => accessorial.value
    ),
    cities: get(
        state,
        `postalCode[${props?.address?.country}-${props?.address?.postalCode}].cities`,
        [get(props, "address.city")]
    ),
    isNameDirty:
        get(state, `form.bolEntry.initial.${props.mode}.address.name`) !==
        get(state, `form.bolEntry.values.${props.mode}.address.name`),
    currentName: get(state, `form.bolEntry.values.${props.mode}.address.name`),
    currentDestination: state?.form?.bolEntry?.values?.destination,
    resiChips:
        state?.form?.bolEntry?.values?.destination?.selectedResiAccessorials,
    carrierCode: state?.form?.bolEntry?.values?.rate?.carrierCode,
    isFreightDirect: state?.form?.bolEntry?.values?.rate?.mode === "LTL_DIRECT",
})

const mapDispatchToProps = (dispatch, props) => ({
    cancel: (form, section) => dispatch(resetSection(form, section)),
    changeField: (form, field, value) => dispatch(change(form, field, value)),
    clearSubmitErrors: () => dispatch(clearSubmitErrors("bolEntry")),
    touch: (form, field) => dispatch(touch(form, field)),
    untouch: (form, field) => dispatch(untouch(form, field)),
    performContactSearch: value =>
        dispatch(
            searchContacts(
                value,
                "bolEntry",
                props?.address?.postalCode,
                props?.address?.country
            )
        ),
    requestPostalCode: (postalCode, country) =>
        dispatch(requestPostalCode(postalCode, { country })),
    updateRate: async values => dispatch(updateRateResiCheck()),
})

export default formValues({ address: "address", contact: "contact" })(
    connect(mapStateToProps, mapDispatchToProps)(Address)
)

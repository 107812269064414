import React from "react"
import { withStyles } from "@material-ui/styles"
import { Grid, Typography, Dialog } from "@material-ui/core"
import {
    resetCustomPricing,
    updateCustomHawaiiPricing,
} from "../../../../../actions/offshoreRating"
import { connect } from "react-redux"
import { change, reduxForm } from "redux-form"
import { FormattedMessage } from "react-intl"
import NavigationDropdown from "../NavigationDropdown"
import { styles } from "../styles"
import PricingFormSimple from "../PricingFormSimple"
import PricingForm from "../PricingForm"

const HawaiiPricingModal = ({
    classes,
    open,
    setOpen,
    defaultPricing,
    customPricing,
    saveCustomPricing,
    resetCustomPricing,
    location,
    activeFilter,
    setActiveFilter,
    trackGA,
}) => {
    return (
        <Dialog maxWidth="lg" fullWidth={true} open={open}>
            <Grid className={classes.modal}>
                <Grid item container justifyContent="center">
                    <Grid item style={{ paddingBottom: "20px" }}>
                        <Typography variant="h5" align="center">
                            <FormattedMessage
                                id="locations.offShore_CustomDiscountsTitle"
                                defaultMessage="Enter your custom list rates and discounts"
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container justifyContent="flex-start" xs={12}>
                    <Grid item container xs={6}>
                        <NavigationDropdown
                            activeFilter={activeFilter}
                            setActiveFilter={setActiveFilter}
                            classes={classes}
                            offshoreState={"HI"}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        xs={6}
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        <Typography variant="h6" align="center">
                            <FormattedMessage
                                id="generalTerms__offshoreStateHI"
                                defaultMessage="Hawaii"
                            />
                        </Typography>
                    </Grid>
                </Grid>

                {activeFilter === "oceanCharges" ? (
                    <PricingForm
                        offshoreLocation="Hawaii"
                        trackGA={trackGA}
                        classes={classes}
                        setOpen={setOpen}
                        defaultValues={[defaultPricing?.oceanCharges]}
                        customPricing={
                            customPricing?.oceanCharges
                                ? [customPricing.oceanCharges]
                                : null
                        }
                        resetCustomPricing={resetCustomPricing}
                        saveCustomPricing={saveCustomPricing}
                        location={location}
                        activeFilter={activeFilter}
                        showRateCheckbox={false}
                        dataFields={[
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.grossDiscount"
                                        defaultMessage="Gross Discount"
                                    />
                                ),
                                ref: "grossDiscount",
                                type: "discount",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.minimumCharge"
                                        defaultMessage="Minimum Charge"
                                    />
                                ),
                                ref: "minimumCharge",
                                type: "charge",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.ratePerCuFt"
                                        defaultMessage="Rate per CuFt"
                                    />
                                ),
                                ref: "ratePerCuFt",
                                type: "rate",
                            },
                        ]}
                    />
                ) : null}
                {activeFilter === "hazardousMaterialsFee" ? (
                    <PricingForm
                        trackGA={trackGA}
                        offshoreLocation="Hawaii"
                        titleField={"name"}
                        setOpen={setOpen}
                        classes={classes}
                        defaultValues={[
                            {
                                name: "PUD Charge",
                                ...defaultPricing?.hazardousMaterialsCharge,
                            },
                            {
                                name: "Ocean Charge",
                                ...defaultPricing?.hazardousMaterialsOceanCharge,
                            },
                        ]}
                        customPricing={
                            customPricing?.hazardousMaterialsCharge ||
                            customPricing?.hazardousMaterialsOceanCharge
                                ? [
                                      {
                                          name: "PUD Charge",
                                          ...customPricing?.hazardousMaterialsCharge,
                                      },
                                      {
                                          name: "Ocean Charge",
                                          ...customPricing?.hazardousMaterialsOceanCharge,
                                      },
                                  ]
                                : null
                        }
                        saveCustomPricing={saveCustomPricing}
                        resetCustomPricing={resetCustomPricing}
                        activeFilter={activeFilter}
                        location={location}
                        showRateCheckbox={false}
                        dataFields={[
                            {
                                label: (
                                    <FormattedMessage
                                        id="domesticOffShore.filters.HazardousMaterials"
                                        defaultMessage="Hazardous Materials"
                                    />
                                ),
                                ref: "name",
                                type: "title",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.grossDiscount"
                                        defaultMessage="Gross Discount"
                                    />
                                ),
                                ref: "oceanGrossDiscount",
                                type: "discount",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.minimumCharge"
                                        defaultMessage="Minimum Charge"
                                    />
                                ),
                                ref: "minimumCharge",
                                type: "charge",
                            },
                        ]}
                    />
                ) : null}
                {activeFilter === "interIslandTransferFee" ? (
                    <PricingForm
                        trackGA={trackGA}
                        offshoreLocation="Hawaii"
                        titleField={"island"}
                        classes={classes}
                        setOpen={setOpen}
                        defaultValues={defaultPricing?.interIslandTransferFee}
                        customPricing={customPricing?.interIslandTransferFee}
                        resetCustomPricing={resetCustomPricing}
                        saveCustomPricing={saveCustomPricing}
                        activeFilter={activeFilter}
                        location={location}
                        showRateCheckbox={false}
                        dataFields={[
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.island"
                                        defaultMessage="Island"
                                    />
                                ),
                                ref: "island",
                                type: "title",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.grossDiscount"
                                        defaultMessage="Gross Discount"
                                    />
                                ),
                                ref: "grossDiscount",
                                type: "discount",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.minimumCharge"
                                        defaultMessage="Minimum Charge"
                                    />
                                ),
                                ref: "minimumCharge",
                                type: "charge",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.ratePerCuFt"
                                        defaultMessage="Rate per CuFt"
                                    />
                                ),
                                ref: "ratePerCuFt",
                                type: "rate",
                            },
                        ]}
                    />
                ) : null}
                {activeFilter === "insideDeliveryPickupCharge" ? (
                    <PricingForm
                        trackGA={trackGA}
                        offshoreLocation="Hawaii"
                        setOpen={setOpen}
                        classes={classes}
                        defaultValues={[
                            defaultPricing?.insideDeliveryPickupCharge,
                        ]}
                        customPricing={
                            customPricing?.insideDeliveryPickupCharge
                                ? [customPricing.insideDeliveryPickupCharge]
                                : null
                        }
                        saveCustomPricing={saveCustomPricing}
                        resetCustomPricing={resetCustomPricing}
                        activeFilter={activeFilter}
                        location={location}
                        showRateCheckbox={false}
                        dataFields={[
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.grossDiscount"
                                        defaultMessage="Gross Discount"
                                    />
                                ),
                                ref: "grossDiscount",
                                type: "discount",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.ratePerCwt"
                                        defaultMessage="Rate per CWT"
                                    />
                                ),
                                ref: "rate",
                                type: "rate",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.minimumCharge"
                                        defaultMessage="Minimum Charge"
                                    />
                                ),
                                ref: "minimumValue",
                                type: "charge",
                            },
                        ]}
                    />
                ) : null}

                {activeFilter === "dockDeliveryPickupCharge" ? (
                    <PricingForm
                        trackGA={trackGA}
                        offshoreLocation="Hawaii"
                        setOpen={setOpen}
                        classes={classes}
                        defaultValues={[
                            defaultPricing?.dockDeliveryPickupCharge,
                        ]}
                        customPricing={
                            customPricing?.dockDeliveryPickupCharge
                                ? [customPricing.dockDeliveryPickupCharge]
                                : null
                        }
                        saveCustomPricing={saveCustomPricing}
                        resetCustomPricing={resetCustomPricing}
                        activeFilter={activeFilter}
                        location={location}
                        showRateCheckbox={false}
                        dataFields={[
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.grossDiscount"
                                        defaultMessage="Gross Discount"
                                    />
                                ),
                                ref: "grossDiscount",
                                type: "discount",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.ratePerCuFt"
                                        defaultMessage="Rate per CuFt"
                                    />
                                ),
                                ref: "rate",
                                type: "rate",
                            },
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.minimumCharge"
                                        defaultMessage="Minimum Charge"
                                    />
                                ),
                                ref: "minimumValue",
                                type: "charge",
                            },
                        ]}
                    />
                ) : null}
                {activeFilter === "otherFees" ? (
                    <PricingFormSimple
                        trackGA={trackGA}
                        offshoreLocation="Hawaii"
                        setOpen={setOpen}
                        classes={classes}
                        defaultValues={{
                            limitedAccessSurcharge:
                                defaultPricing?.limitedAccessSurcharge,
                            privateResidenceSurcharge:
                                defaultPricing?.privateResidenceSurcharge,
                        }}
                        initialFormValues={{
                            limitedAccessSurcharge:
                                customPricing?.limitedAccessSurcharge,
                            privateResidenceSurcharge:
                                customPricing?.privateResidenceSurcharge,
                        }}
                        saveCustomPricing={saveCustomPricing}
                        resetCustomPricing={resetCustomPricing}
                        activeFilter={activeFilter}
                        location={location}
                        dataFields={[
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.limitedAccessSurcharge"
                                        defaultMessage="Limited Access Surcharge"
                                    />
                                ),
                                ref: "limitedAccessSurcharge",
                                type: "charge",
                            },

                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.privateResidenceSurcharge"
                                        defaultMessage="Private Residence Surcharge"
                                    />
                                ),
                                ref: "privateResidenceSurcharge",
                                type: "charge",
                            },
                        ]}
                    />
                ) : null}
                {activeFilter === "absoluteMinimumCharge" ? (
                    <PricingFormSimple
                        trackGA={trackGA}
                        offshoreLocation="Hawaii"
                        setOpen={setOpen}
                        classes={classes}
                        defaultValues={defaultPricing?.absoluteMinimumCharge}
                        initialFormValues={customPricing?.absoluteMinimumCharge}
                        saveCustomPricing={saveCustomPricing}
                        resetCustomPricing={resetCustomPricing}
                        activeFilter={activeFilter}
                        location={location}
                        dataFields={[
                            {},
                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.absoluteMinimumCharge"
                                        defaultMessage="Custom Absolute Minimum Charge"
                                    />
                                ),
                                ref: "charge",
                                type: "charge",
                            },

                            {
                                label: (
                                    <FormattedMessage
                                        id="location.offshore.grossDiscount"
                                        defaultMessage="Gross Discount"
                                    />
                                ),
                                ref: "grossPercentageDiscount",
                                type: "discount",
                            },
                        ]}
                    />
                ) : null}
            </Grid>
        </Dialog>
    )
}

const mapStateToProps = (state, props) => {
    return {
        formValues: state?.form?.offshoreDiscountsHI?.values,
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    saveCustomPricing: (updatedPricing, locationId) =>
        dispatch(
            updateCustomHawaiiPricing(
                updatedPricing,
                locationId,
                props?.activeFilter
            )
        ),
    resetCustomPricing: locationId => {
        dispatch(resetCustomPricing("hawaii", locationId, props?.activeFilter))
    },
})

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "offshoreDiscountsHI", // a unique identifier for this form
            enableReinitialize: true,
        })(HawaiiPricingModal)
    )
)

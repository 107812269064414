import React from "react"
import Grid from "@material-ui/core/Grid"
import { connect } from "react-redux"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import { FormattedMessage } from "react-intl"
import { CarrierLogo } from "../../util"
import { quoteSelectRow, quoteSelect } from "../../../actions/quote"
import { isRateSelectedForShare } from "../../../misc"
import { QuoteCardHeader } from "./carousel/quoteCard"

const styles = theme => ({
    main__container: {
        paddingRight: theme.spacing(2),
        paddingBottom: "20px",
        paddingLeft: "10px",
        display: "block",
    },
    paper__book: {
        padding: "10px",
        marginTop: "1px",
        borderRadius: 0,
    },
    gridListTile: {
        paddingTop: "10px",
        cursor: "pointer",
    },
    card__header: {
        minHeight: "100px",
        paddingLeft: "12px",
    },
    book__container: {
        minHeight: "48px",
    },
    book__button: {
        width: "100%",
    },
    blank_panel: {
        paddingLeft: "0px",
        height: "196px",
        opacity: 0,
    },
})

export const NoRateOnlyDispatchCard = ({
    classes,
    isThirdParty,
    disabled,
    carrier,
    shareOpen,
    rowSelected,
    handleRowSelect,
    onClick,
    index,
    getBol,
    bolNumber,
    isShare,
}) => {
    const isDomesticOffshore = carrier?.rateQuotes?.[0]?.isDomesticOffshore
    return (
        <Grid
            item
            container
            direction="column"
            className={classes.main__container}
        >
            {!isShare && isDomesticOffshore && (
                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="rateResults__fedExDomesticOffshore"
                        defaultMessage="FedEx Domestic Offshore"
                    />
                </Typography>
            )}
            {!isDomesticOffshore && (
                <QuoteCardHeader
                    fastest={false}
                    carrier={carrier}
                    classes={classes}
                    isThirdParty={isThirdParty}
                />
            )}
            <Paper className={classes.paper}>
                <Grid item container>
                    <Grid
                        item
                        container
                        xs={8}
                        className={classes.gridListTile}
                    >
                        <Grid className={classes.card__header}>
                            <CarrierLogo
                                carrierCode={carrier.carrierCode}
                                isDomesticOffshore={isDomesticOffshore}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container alignItems="flex-end" xs={4}>
                        {isThirdParty && (
                            <Typography
                                variant="caption"
                                className={classes.third__party__label}
                            >
                                <FormattedMessage
                                    id="rateResults__thirdPartyPricing"
                                    defaultMessage="3rd Party Pricing"
                                />
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Paper>
            <Paper className={classes.paper__book}>
                <Grid
                    item
                    container
                    alignItems="center"
                    className={classes.book__container}
                >
                    {shareOpen && (
                        <Grid item xs={1} alignItems="center">
                            {shareOpen && (
                                <Grid
                                    item
                                    container
                                    alignItems="flex-start"
                                    justify="center"
                                >
                                    {" "}
                                    <Checkbox
                                        checked={rowSelected}
                                        onClick={e => e.stopPropagation()}
                                        onChange={() =>
                                            handleRowSelect(
                                                carrier.rateQuotes,
                                                carrier.carrierCode
                                            )
                                        }
                                        className={classes.share__checkbox}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    )}
                    {!isShare && (
                        <Grid
                            item
                            container
                            justify="center"
                            alignItems="center"
                            xs={shareOpen ? 11 : 12}
                        >
                            <Button
                                disabled={disabled}
                                color="primary"
                                className={classes.book__button}
                                onClick={e => {
                                    e.stopPropagation()
                                    if (!disabled && !shareOpen && onClick) {
                                        onClick(
                                            bolNumber,
                                            carrier.carrierCode,
                                            carrier.rateQuotes[0].index,
                                            carrier.rateQuotes[0].mode,
                                            carrier.rateQuotes[0].direction,
                                            {
                                                rateIndex: 1,
                                                cardIndex: index + 1,
                                            }
                                        )
                                        getBol()
                                    }
                                }}
                            >
                                <FormattedMessage
                                    id="rateResults__bookNow"
                                    defaultMessage="Book Now"
                                />
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Paper>
            <Grid className={classes.blank_panel} />
        </Grid>
    )
}

const mapStateToProps = (state, ownProps) => ({
    bolNumber: state.identifiers.bolNumber,
    shareOpen: state.share.isOpen,
    rowSelected: ownProps.carrier.rateQuotes.every(rate =>
        isRateSelectedForShare(
            state.share.selections,
            rate,
            ownProps.carrier.carrierCode
        )
    ),
    shippedRateQuote: state.quotes.active.shippedRateQuote,
})

const mapDispatchToProps = dispatch => ({
    handleRowSelect: (rates, carrierCode) =>
        dispatch(quoteSelectRow(rates, carrierCode)),
    onClick: (bolNumber, carrier, quoteId, mode, direction, metadata) => {
        dispatch(
            quoteSelect(bolNumber, carrier, quoteId, mode, direction, metadata)
        )
    },
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(NoRateOnlyDispatchCard)
)

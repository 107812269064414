import React, { Fragment, useState, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import ReactIdleTimer from "react-idle-timer"
import { hashCode, reloadApp, useIntervalImmediate } from "../../misc"
import { flagNewVersionAvailable } from "../../actions"
import { ReloadNotificationContainer } from "./reload-notification"

const releaseTypeRegExp = /_soft_release_\s*=\s*true/

export const DeploymentReloadContainer = ({ delay }) => {
    const newVersionAvailable = useSelector(state => state.newVersionAvailable)
    const [forceReload, setForceReload] = useState(false)
    const [hash, setHash] = useState()
    const dispatch = useDispatch()

    const fetchSource = useCallback(async () => {
        if (window._env_.NODE_ENV !== "production") return null
        const rawResp = await fetch(
            `${window._env_.REACT_APP_FRONTEND_URL}/index.html`
        )
        const html = await rawResp.text()
        const newHash = hashCode(html)
        if (!hash) {
            setHash(() => newHash)
            return
        }
        if (hash !== newHash) {
            setHash(() => newHash)
            const rawResp2 = await fetch(
                `${window._env_.REACT_APP_FRONTEND_URL}/version.js`
            )
            const versionFile = await rawResp2.text()
            const isSoftRelease = releaseTypeRegExp.test(versionFile)
            if (isSoftRelease) {
                dispatch(flagNewVersionAvailable())
            } else {
                setForceReload(true)
            }
        }
    }, [hash, dispatch])

    useIntervalImmediate(fetchSource, delay)

    return (
        <Fragment>
            {forceReload && (
                <ReloadNotificationContainer isOpen type="deployment" />
            )}
            {newVersionAvailable && (
                <ReactIdleTimer
                    timeout={20 * 60 * 1000}
                    onIdle={() => dispatch(reloadApp())}
                />
            )}
        </Fragment>
    )
}

DeploymentReloadContainer.propTypes = {
    delay: PropTypes.number.isRequired,
}

DeploymentReloadContainer.defaultProps = {
    delay: 10 * 60 * 1000,
}

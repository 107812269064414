import React, { Fragment } from "react"
import { get } from "lodash"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import PropTypes from "prop-types"
import Avatar from "@material-ui/core/Avatar"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Fade from "@material-ui/core/Fade"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { createGAEvent } from "../../actions"
import { logOut } from "../../actions/authorization"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    avatar: {
        "&:hover": {
            cursor: "pointer",
            "&:hover": {
                "background-color": theme.palette.secondary.main,
            },
            "&:active": {
                "background-color": theme.palette.secondary.light,
            },
        },
    },
    user__details__container: {
        display: "flex",
        "justify-content": "center",
        outline: "none",
    },
    menu__popover: {
        top: "72px !important",
        padding: "5px",
    },
})

class UserMenu extends React.Component {
    state = {
        anchorEl: null,
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
    }

    userPreferences = history => {
        this.setState({ anchorEl: null })
        history.push({ pathname: "/user" })
    }

    systemStatus = () => {
        // <a rel="noopener noreferrer" href="https://p.datadoghq.com/sb/808b01da2-6f6347decae93e056eaca36c5115c488" target="_blank">
    }

    releaseNotes = () => {
        window.open(
            "https://intercom.help/ltlselect/en/collections/3164251-release-notes"
        )
    }

    scheduleDemo = () => {
        window.open("https://meet.intercom.com/ltlselect")
    }

    logout() {
        this.setState({ anchorEl: null })
        this.props.onLogout()
    }

    render() {
        const { classes, history, user } = this.props

        return (
            <Fragment>
                <Avatar
                    onClick={e => this.handleClick(e)}
                    className={classes.avatar}
                    id="navigation__userMenu"
                >
                    {user &&
                        get(user, "firstname", "").charAt(0) +
                            get(user, "lastname", "").charAt(0)}
                </Avatar>
                <Menu
                    id="fade-menu"
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={() => this.handleClose()}
                    TransitionComponent={Fade}
                    classes={{
                        paper: classes.menu__popover,
                    }}
                >
                    <div className={classes.user__details__container}>
                        <Typography variant="caption">
                            {get(user, "email.email_address")}
                        </Typography>
                    </div>
                    <MenuItem id="intercom-trigger">
                        <Typography variant="body2">
                            <FormattedMessage
                                id="navBar.userMenu__technicalSupport"
                                defaultMessage="Technical Support"
                            />
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => this.userPreferences(history)}>
                        <Typography variant="body2">
                            <FormattedMessage
                                id="navBar.userMenu__userPreferences"
                                defaultMessage="User Preferences"
                            />
                        </Typography>
                    </MenuItem>
                    {/* <MenuItem onClick={() => systemStatusOpen()}>
                        <Typography variant="body2">
                            <FormattedMessage
                                id="navBar.userMenu__systemStatus"
                                defaultMessage="System Status"
                            />
                        </Typography>
                    </MenuItem> */}
                    <MenuItem onClick={this.scheduleDemo}>
                        <Typography variant="body2">
                            <FormattedMessage
                                id="navBar.userMenu__scheduleDemo"
                                defaultMessage="Schedule Demo"
                            />
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={this.releaseNotes}>
                        <Typography variant="body2">
                            <FormattedMessage
                                id="navBar.userMenu__releaseNotes"
                                defaultMessage="Release Notes"
                            />
                        </Typography>
                    </MenuItem>

                    <MenuItem
                        id="navigation__userMenu__logout"
                        onClick={() => this.logout()}
                    >
                        <Typography variant="body2">
                            <FormattedMessage
                                id="navBar.userMenu__logout"
                                defaultMessage="Logout"
                            />
                        </Typography>
                    </MenuItem>
                </Menu>
            </Fragment>
        )
    }
}

UserMenu.defaultProps = {
    user: undefined,
}
UserMenu.propTypes = {
    history: PropTypes.object.isRequired,
    onLogout: PropTypes.func.isRequired,
    user: PropTypes.object,
}

const userStateToProps = state => ({ user: state.user.profile })

const mapDispatchToProps = (dispatch, props) => ({
    onLogout: () => {
        dispatch(createGAEvent("Navigation", "Logout"))
        dispatch(logOut())
    },
})

export default connect(
    userStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(styles)(UserMenu)))

import { TextField } from "@material-ui/core"
import React, { useState } from "react"
import { Controller } from "react-hook-form"
import PropTypes from "prop-types"
import { normalizeExt, normalizePhone } from "./normalization"

const getValue = (type, value, phoneCountry) => {
    switch (type) {
        case "phone":
            return normalizePhone(value, "", phoneCountry)
        case "ext":
            return normalizeExt(value)
        default:
            return value
    }
}

const FormTextField = ({
    style = {},
    type,
    width,
    name,
    control,
    label,
    defaultValue = "",
    required,
    rules = {},
    maxLength,
    multiline,
    input,
    placeholder,
    phoneCountry,
    ...props
}) => {
    const [previousValue, setPreviousValue] = useState()
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    placeholder={placeholder}
                    onInput={e => {
                        let value = e.target.value
                        switch (type) {
                            case "phone":
                                value = normalizePhone(
                                    value,
                                    previousValue,
                                    phoneCountry
                                )
                                setPreviousValue(value)
                                break
                            case "ext":
                                value = normalizeExt(value)
                                break
                            default:
                                break
                        }
                        field.onChange(value)
                    }}
                    value={getValue(type, field.value, phoneCountry)}
                    inputProps={{ maxLength: maxLength }}
                    multiline={multiline}
                    style={{
                        width: `${width}%`,
                        paddingRight: "10px",
                        ...style,
                    }}
                    required={required}
                    label={label}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    {...props}
                />
            )}
        />
    )
}

FormTextField.propTypes = {
    style: PropTypes.object,
    type: PropTypes.oneOf(["text", "phone", "ext"]),
    width: PropTypes.number,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    label: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    required: PropTypes.bool,
    rules: PropTypes.object,
    maxLength: PropTypes.number,
    multiline: PropTypes.bool,
    input: PropTypes.object,
    placeholder: PropTypes.string,
    phoneCountry: PropTypes.string,
}

export default FormTextField

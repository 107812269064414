import { Grid, Paper, Typography, withStyles } from "@material-ui/core"
import React, { useEffect } from "react"
import { useTileCountContext } from "../../../context/providers/TileCountProvider"

const styles = theme => ({
    tileContents: {
        padding: "5px",
        minHeight: "74px",
    },
    paper: {
        width: "100%",
        display: "flex",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: "white",
        },
        "&:active": {
            backgroundColor: theme.palette.secondary.dark,
            color: "white",
        },
        minHeight: "50px",
    },
    active: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
})

const Tile = ({
    value,
    label,
    icon,
    active,
    classes,
    activateTile,
    getTileCount,
    shipmentsFetching,
    isShipmentsLoaded,
    trackGA,
}) => {
    const { setTileCount, state } = useTileCountContext()

    useEffect(() => {
        if (!shipmentsFetching && isShipmentsLoaded) {
            const fetchData = async () => {
                try {
                    const result = await getTileCount(value)
                    // setCount(result?.data?.totalCount)
                    setTileCount(value, result?.data?.totalCount)
                } catch (error) {
                    trackGA("Dashboard Tiles", "Tile Load Error")
                }
            }
            fetchData()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipmentsFetching])

    return (
        <Grid
            item
            container
            onClick={() => {
                trackGA("Dashboard Tiles", "Tile Click", value)
                if (active) {
                    trackGA("Dashboard Tiles", "Tile Deactivate", value)
                    activateTile(null)
                } else {
                    trackGA("Dashboard Tiles", "Tile Activate", value)
                    activateTile(value)
                }
            }}
        >
            <Paper className={`${classes.paper} ${active && classes.active}`}>
                <Grid item container className={classes.tileContents}>
                    <Grid
                        item
                        container
                        xs={10}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item container alignItems="center">
                            <Typography variant="body2" color="inherit">
                                {label}
                            </Typography>
                        </Grid>
                        <Grid item container>
                            <Typography variant="subtitle1" color="inherit">
                                {state[value]}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={2}
                        justify="flex-end"
                        alignItems="center"
                    >
                        {icon}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default withStyles(styles)(Tile)

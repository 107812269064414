import React from "react"
import { loadData } from "../../misc"

export const withToken = Comp => {
    return props => {
        const token = loadData("token")
        if (!token) return null
        return <Comp {...props} />
    }
}

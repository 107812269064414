import createSelector from "re-reselect"
import moment from "moment"

export const createGoodInitialValues = (handlingUnits = []) =>
    handlingUnits.flatMap(hu =>
        hu.items.map(item => ({
            description: item?.description,
            harmonizedCode: item?.harmonizedCode,
            countryOfOrigin: item?.countryOfManufacture,
        }))
    )

export const usmcaFormGenerateInitialValues = createSelector(
    (state, props) =>
        state?.shipment?.activeShipment?.identifiers?.internalTrackingNumber ===
        props?.shipmentId
            ? state?.shipment?.activeShipment
            : state?.shipment?.list[props.shipmentId] ??
              (props?.newflow
                  ? state.form?.bookShipment?.values
                  : state.form?.bolEntry?.values),
    (state, props) => props.previousDocument,
    state => state.user?.profile?.locations,
    (state, props) => props.locationId,
    (state, props) => props.shipmentId,

    (shipment, previousDocument, locations, providedLocationId, shipmentId) => {
        if (previousDocument) {
            if (previousDocument[0]?.documentFields) {
                return previousDocument[0].documentFields
            }
        }

        let locationId = providedLocationId

        if (!locationId) {
            locationId = locations.find(
                x => shipment?.query?.cpg === x?.cpgCode
            )?._id
        }

        if (!shipment) {
            return {
                locationId,
                goods: [{}],
                signatureInfo: {
                    date: moment(),
                },
                internalTrackingNumber: shipmentId,
            }
        }

        const goods = createGoodInitialValues(shipment?.handlingUnits)
        return {
            documentCategory: "USMCA",
            internalTrackingNumber:
                shipment?.identifiers?.internalTrackingNumber,
            locationId,
            goods,
            signatureInfo: {
                date: moment(),
            },
        }
    }
)((state, props) => props?.shipmentId ?? "")

import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import { uniqBy } from "lodash"
import { Grid, TextField, createStyles } from "@material-ui/core"
import "react-select/dist/react-select.css"
import FormattedError from "./formatted-error"
import { injectIntl } from "react-intl"
import { Autocomplete, createFilterOptions } from "@material-ui/lab"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const styles = theme => ({
    chip: {
        margin: theme.spacing(0.25),
    },
    form__container: {
        padding: "3px",
        width: "100%",
    },
    option: {
        '&[aria-selected="true"]': {
            fontWeight: "500",
        },
    },
})

const sty = createStyles({
    option: {
        '&[aria-selected="true"]': {
            fontWeight: "500",
        },
    },
})

const SelectWrapped = injectIntl(props => {
    const {
        category,
        logGAEvent,
        input,
        id,
        label,
        required,
        touched,
        noResultsOverride,
        error,
        classes,
        onChange,
        onInputChange,
        options = [],
        intl,
        disabled,
    } = props

    const [selectedOption, setSelectedOption] = useState({
        value: "",
        label: "",
    })
    useEffect(() => {
        if (input.value != selectedOption?.value) {
            if (options.length > 0) {
                setSelectedOption(
                    options.filter(
                        option => option.value === input.value
                    )[0] ?? {
                        value: "",
                        label: "",
                    }
                )
            } else {
                setSelectedOption({
                    value: input.value,
                    label: input.label ?? input.value,
                })
            }
        }
    }, [input.value])

    return (
        <Autocomplete
            id={id}
            classes={{ root: classes.chip }}
            {...input}
            value={selectedOption}
            disableClearable
            onFocus={input.onFocus}
            fullWidth
            getOptionLabel={option => {
                if (typeof option?.label === "string") {
                    return option.label
                } else if (option?.label) {
                    if (option.label.props) {
                        return intl.formatMessage(option.label.props)
                    }
                    return intl.formatMessage(option.label)
                }
                return option.value
            }}
            onInputChange={(event, value, reason) =>
                !!onInputChange ? onInputChange(value) : null
            }
            onChange={(event, newValue) => {
                if (!!onChange) {
                    onChange(newValue)
                } else {
                    setSelectedOption(newValue)
                    input.onChange(newValue.value)
                }
                logGAEvent(
                    category,
                    `Change ${label?.props?.defaultMessage} Input`,
                    newValue?.value
                )
            }}
            getOptionSelected={(option, value) => {
                return option.value === value.value
            }}
            onBlur={(event, newValue) => {
                if (newValue) {
                    setSelectedOption(newValue)
                    input.onBlur(newValue.value)
                }
                input.onBlur()
            }}
            renderInput={params => (
                <TextField
                    error={!!(touched && error)}
                    required={required}
                    {...params}
                    label={label}
                    helperText={
                        error && touched ? <FormattedError error={error} /> : ""
                    }
                ></TextField>
            )}
            noOptionsText={noResultsOverride}
            options={options}
            disabled={disabled}
        />
    )
})

const CreatableSelectWrapped = injectIntl(props => {
    const {
        category,
        logGAEvent,
        input,
        noResultsOverride,
        id,
        label,
        required,
        classes,
        error,
        touched,
        intl,
        options,
        disabled,
    } = props
    const [value, setValue] = useState(null)
    const filter = createFilterOptions()
    useEffect(() => {
        setValue(input.value)
    }, [input.value])

    const selectOptions = [
        ...options,
        {
            label: intl.formatMessage({
                id: "cityoptions.EnterPlaceholder",
                defaultMessage: "Enter",
            }),
            value: "",
        },
    ]

    return (
        <Autocomplete
            id={id}
            {...input}
            value={value}
            classes={classes}
            clearOnBlur
            includeInputInList
            fullWidth={true}
            disabled={disabled}
            onChange={(event, newValue) => {
                if (newValue?.value === "") {
                    setValue({ value: "", label: "" })
                    input.onChange("")
                } else {
                    if (typeof newValue === "string") {
                        setValue({
                            value: newValue,
                        })
                        input.onChange(newValue)
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue({
                            value: newValue.inputValue,
                        })
                        input.onChange(newValue.inputValue)
                    } else {
                        setValue(newValue)
                        input.onChange(newValue.value)
                    }
                }
                logGAEvent(
                    category,
                    `Change ${label?.props?.defaultMessage ??
                        input.name} Input`,
                    newValue?.value
                )
            }}
            forcePopupIcon={true}
            handleHomeEndKeys
            autoSelect
            options={selectOptions}
            filterOptions={(options, params) => {
                const filtered = filter(options, params)

                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                    filtered.push({
                        inputValue: params.inputValue,
                        label: `Enter "${params.inputValue}"`,
                    })
                }

                return filtered
            }}
            getOptionLabel={option => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                    return option
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue
                }
                // Regular option
                return option.value
            }}
            renderOption={option => option.label}
            freeSolo
            noOptionsText={noResultsOverride}
            renderInput={params => (
                <div>
                    <TextField
                        error={!!(touched && error)}
                        required={required}
                        {...params}
                        label={label}
                        helperText={
                            error && touched ? (
                                <FormattedError error={error} />
                            ) : (
                                ""
                            )
                        }
                    ></TextField>
                </div>
            )}
            disableClearable
        />
    )
})

const FormSelectAutocomplete = ({
    category,
    input,
    label,
    options = [],
    classes,
    InputProps,
    InputLabelProps,
    InputHelperProps,
    selectClasses,
    customValue,
    meta: { touched, error },
    required,
    dataTestId,
    onInputChange,
    onChange,
    noResultsOverride,
    disabled,
    ...rest
}) => {
    const { logGAEvent } = useGAContext()

    return (
        <Grid
            item
            className={`${classnames({
                "form-field": true,
                "form-field-touched": touched,
                "form-field-invalid": touched && error,
                "form-field-valid": !error,
            })} ${classes.form__container}`}
        >
            <Grid item container direction="column">
                {customValue ? (
                    <CreatableSelectWrapped
                        logGAEvent={logGAEvent}
                        category={category}
                        input={input}
                        noResultsOverride={noResultsOverride}
                        id={input.name}
                        touched={touched}
                        error={error}
                        label={label}
                        required={required}
                        classes={classes}
                        disabled={disabled}
                        options={
                            customValue && options.length > 0
                                ? uniqBy(
                                      [
                                          ...options,
                                          {
                                              value: input.value,
                                              label: input.value,
                                          },
                                      ],
                                      "value"
                                  )
                                : options
                        }
                    ></CreatableSelectWrapped>
                ) : (
                    <SelectWrapped
                        logGAEvent={logGAEvent}
                        category={category}
                        input={input}
                        noResultsOverride={noResultsOverride}
                        id={input.name}
                        label={label}
                        required={required}
                        touched={touched}
                        onChange={onChange}
                        onInputChange={onInputChange}
                        error={error}
                        classes={classes}
                        disabled={disabled}
                        options={
                            customValue && options.length > 0
                                ? uniqBy(
                                      [
                                          ...options,
                                          {
                                              value: input.value,
                                              label: input.value,
                                          },
                                      ],
                                      "value"
                                  )
                                : options
                        }
                    ></SelectWrapped>
                )}
            </Grid>
        </Grid>
    )
}

FormSelectAutocomplete.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FormSelectAutocomplete)

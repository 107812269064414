import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { get } from "lodash"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import {
    FormControl,
    InputLabel,
    FormHelperText,
    Select,
    Input,
    MenuItem,
    Typography,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import FormattedError from "./formatted-error"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const styles = theme => ({
    form__container: {
        padding: "3px",
        width: "100%",
        overflow: "-moz-hidden-unscrollable",
    },
    "@global": {
        ".Select-menu-outer": {
            zIndex: 999,
        },
    },
    form__control: {
        width: "100%",
    },
})

const FormSelect = ({
    input,
    label,
    options,
    classes,
    meta: { touched, error },
    required,
    id,
    formControlId,
    InputProps,
    InputLabelProps,
    InputHelperProps,
    selectClasses,
    billTo,
    menuprops,
    variant,
    dataTestId,
    category,
    ...rest
}) => {
    const { logGAEvent } = useGAContext()
    return (
        <Grid
            item
            container
            className={`${classnames({
                "form-field": true,
                "form-field-touched": touched,
                "form-field-invalid": touched && error,
                "form-field-valid": !error,
            })} ${classes.form__container}`}
        >
            <Grid item container direction="column">
                <FormControl
                    error={!!(touched && error)}
                    className={classes.form__control}
                    id={id}
                >
                    <InputLabel
                        htmlFor={input.name}
                        {...InputLabelProps}
                        required={required}
                    >
                        {label}
                    </InputLabel>
                    <Select
                        variant={variant}
                        classes={selectClasses}
                        input={
                            <Input
                                placeholder=""
                                id={input.name}
                                inputProps={{ dataTestId }}
                                {...InputProps}
                                {...input}
                                menuprops={menuprops}
                            />
                        }
                        {...input}
                        {...rest}
                        menuprops={menuprops}
                        onChange={e => {
                            if (input.onChange) {
                                input.onChange(e)
                            }
                            logGAEvent(
                                category,
                                `Change: ${label?.props?.defaultMessage ??
                                    input.name}`,
                                e.target.value
                            )
                        }}
                    >
                        {options.map((option, index) =>
                            billTo ? (
                                <MenuItem
                                    key={index}
                                    value={option.value}
                                    disabled={option.disabled}
                                    style={{
                                        whiteSpace: "nowrap",
                                    }}
                                    dataTestId={`formSelectOption-${index}`}
                                >
                                    <Grid
                                        item
                                        container
                                        justify="space-between"
                                        alignItems="center"
                                        alignContent="center"
                                        className={classes.billTo}
                                    >
                                        <Typography
                                            color="inherit"
                                            id="billTo__label"
                                            className={classes.billTo__label}
                                        >{`${option.label}`}</Typography>
                                        <Typography
                                            color="inherit"
                                            id="billTo__subLabel"
                                            variant="caption"
                                        >{`${get(
                                            option,
                                            "subLabel",
                                            ""
                                        )}`}</Typography>
                                    </Grid>
                                </MenuItem>
                            ) : (
                                <MenuItem
                                    key={index}
                                    value={option.value}
                                    disabled={option.disabled}
                                    id={`${id}-${option.value}`}
                                >
                                    {get(option, "label.id") ? (
                                        <FormattedMessage {...option.label} />
                                    ) : (
                                        option.label
                                    )}
                                </MenuItem>
                            )
                        )}
                    </Select>
                    {touched && error && (
                        <FormHelperText {...InputHelperProps}>
                            <FormattedError error={error} />
                        </FormHelperText>
                    )}
                </FormControl>
            </Grid>
        </Grid>
    )
}

FormSelect.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    label: PropTypes.string,
    meta: PropTypes.shape({
        // eslint-disable-next-line react/no-unused-prop-types
        touched: PropTypes.bool,
        // eslint-disable-next-line react/no-unused-prop-types
        error: PropTypes.string,
    }),
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.object,
    category: PropTypes.string,
}

FormSelect.defaultProps = {
    meta: {},
    classes: {},
    label: undefined,
    input: undefined,
}

export default withStyles(styles)(FormSelect)

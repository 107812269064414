import { Box, IconButton, Paper } from "@material-ui/core"
import { Delete, Edit } from "@material-ui/icons"
import React from "react"
import TextDataDisplay from "./TextDataDisplay"
import ChipDataDisplay from "./ChipDataDisplay"
import { connect } from "react-redux"
import { getAccessorialsList } from "../../actions/accessorial"
import {
    formatPhoneNumber,
    getCityStateZipFromAddress,
} from "../util/formatters"
import { FormattedMessage } from "react-intl"

const ContactCard = ({
    contact,
    getAccessorialsList,
    deleteContact,
    setEditModalOpen,
    setEditContact,
}) => {
    const handleEdit = () => {
        setEditContact(contact)
        setEditModalOpen(true)
    }

    return (
        <Box
            component={Paper}
            sx={{
                width: "100%",
                padding: "12px 24px",
                marginTop: "5px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    width: "93%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <TextDataDisplay
                    title={
                        <FormattedMessage
                            id="contacts.card.header.address"
                            defaultMessage="Address"
                        />
                    }
                    textFields={[
                        { key: "companyName", value: contact?.name },
                        {
                            key: "street1",
                            value: contact?.address?.street1,
                        },
                        {
                            key: "street2",
                            value: contact?.address?.street2,
                        },
                        {
                            key: "cityStateZip",
                            value: getCityStateZipFromAddress(contact?.address),
                        },
                    ]}
                />
                <TextDataDisplay
                    title={
                        <FormattedMessage
                            id="contacts.card.header.primaryContact"
                            defaultMessage="Primary Contact"
                        />
                    }
                    textFields={[
                        {
                            key: "contact",
                            value: contact?.contact?.name,
                        },
                        {
                            key: "email",
                            value: contact?.contact?.email?.email_address,
                        },
                        {
                            key: "phone",
                            value: formatPhoneNumber(
                                contact?.contact?.phone?.phone_number,
                                contact?.contact?.phone?.extension
                            ),
                        },
                    ]}
                />

                <ChipDataDisplay
                    title={
                        <FormattedMessage
                            id="contacts.card.header.accessorials"
                            defaultMessage="Accessorials"
                        />
                    }
                    chipData={getAccessorialsList(
                        contact?.pickupAccessorials,
                        contact?.deliveryAccessorials
                    )}
                    listTrimCutoff={3}
                />
                <TextDataDisplay
                    title={
                        <FormattedMessage
                            id="contacts.card.header.pickupWindow"
                            defaultMessage="Pickup Window"
                        />
                    }
                    textFields={[
                        {
                            key: "email",
                            value: (
                                <FormattedMessage
                                    id="contacts.card.pickupWindow.readyTime"
                                    defaultMessage="Ready: {readyTime}"
                                    values={{
                                        readyTime:
                                            contact?.contact?.pickupContact
                                                ?.readyTime ?? "6:00 AM",
                                    }}
                                />
                            ),
                        },
                        {
                            key: "phone",
                            value: (
                                <FormattedMessage
                                    id="contacts.card.pickupWindow.closeTime"
                                    defaultMessage="Close: {closeTime}"
                                    values={{
                                        closeTime:
                                            contact?.contact?.pickupContact
                                                ?.closeTime ?? "1:00 PM",
                                    }}
                                />
                            ),
                        },
                    ]}
                />

                {contact?.contact?.pickupContact?.name ? (
                    <TextDataDisplay
                        title={
                            <FormattedMessage
                                id="contacts.card.header.pickupContact"
                                defaultMessage="Pickup Contact"
                            />
                        }
                        textFields={[
                            {
                                key: "contact",
                                value: contact?.contact?.pickupContact?.name,
                            },
                            {
                                key: "email",
                                value:
                                    contact?.contact?.pickupContact?.email
                                        ?.email_address,
                            },
                            {
                                key: "phone",
                                value: formatPhoneNumber(
                                    contact?.contact?.pickupContact?.phone
                                        ?.phone_number,
                                    contact?.contact?.pickupContact?.phone
                                        ?.extension
                                ),
                            },
                        ]}
                    />
                ) : (
                    <Box style={{ width: "20%" }}></Box>
                )}
            </Box>
            <Box
                sx={{
                    width: "7%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                }}
            >
                <IconButton size="small">
                    <Edit onClick={handleEdit} />
                </IconButton>
                <IconButton
                    onClick={() => deleteContact(contact?.contact?._id)}
                    size="small"
                >
                    <Delete />
                </IconButton>
            </Box>
        </Box>
    )
}

const mapDispatchToProps = dispatch => ({
    getAccessorialsList: (pickupAccessorials, deliveryAccessorials) =>
        dispatch(getAccessorialsList(pickupAccessorials, deliveryAccessorials)),
})

export default connect(null, mapDispatchToProps)(ContactCard)

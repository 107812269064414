import React, { useState, useEffect } from "react"
import { Grid, Button, Paper, Typography } from "@material-ui/core"
import GlobalSpinner from "../../common/GlobalSpinner"
import TablePagination from "@material-ui/core/TablePagination"
import AdminTableSearch from "./AdminTableSearch"
import AdminUploadModal from "./AdminUploadModal"
import AdminTable from "./AdminTable"
import { useSnackbarContext } from "../../../context/providers/snackbarProvider"
import { useStyles } from "./styles"
import { goFetch } from "../../../http"

const AdminPage = ({
    title,
    urlPath,
    sampleFile,
    tableHeaders,
    enableAccountNumbers = true,
}) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [isLoading, setLoading] = useState(false)
    const [tableData, setTableData] = useState([])
    const [filteredTableData, setFilteredTableData] = useState([])
    const [isSearch, setIsSearch] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [uploadModalOpen, setUploadModalOpen] = useState(false)

    const { openSnackbar } = useSnackbarContext()
    const classes = useStyles()
    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        setLoading(true)
        try {
            const { data, status } = await goFetch(
                `/admin2/${urlPath}`,
                {
                    method: "GET",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                },
                true
            )
            if (status !== 200) {
                openSnackbar("error", "Data failed to load")
            }
            setTableData(data)
        } catch (error) {
            return openSnackbar("error", `Data failed to load: ${error}`)
        }
        setLoading(false)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleModal = bool => {
        setUploadModalOpen(bool)
    }
    const onSearch = value => {
        const filtered = tableData.filter(account =>
            account?.accountNumber?.includes(value)
        )
        setIsSearch(true)
        setFilteredTableData(filtered)
    }
    const handleResetDiscounts = search => {
        setFilteredTableData(tableData)
        if (search) {
            setSearchValue(search)
        } else {
            setSearchValue("")
        }
        setIsSearch(false)
    }
    const discountsToMap = isSearch ? filteredTableData : tableData
    return isLoading ? (
        <GlobalSpinner />
    ) : (
        <Grid
            container
            justifyContent="center"
            alignContent="center"
            className={classes.root}
        >
            <AdminUploadModal
                {...{
                    classes,
                    handleModal,
                    uploadModalOpen,
                    sampleFile,
                    fetchData,
                    urlPath,
                }}
            />
            <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                className={classes.title}
            >
                <Typography variant="h6" align="center">
                    {title} {enableAccountNumbers ? "- Manage Accounts" : null}
                </Typography>
            </Grid>

            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.input__grid}
            >
                <Grid item xs={6}>
                    {enableAccountNumbers && (
                        <AdminTableSearch
                            {...{
                                setSearchValue,
                                searchValue,
                                onSearch,
                                handleResetDiscounts,
                            }}
                        />
                    )}
                </Grid>

                <Grid item xs={6} container justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleModal(true)}
                    >
                        Upload
                    </Button>
                </Grid>
            </Grid>
            <Grid
                xs={12}
                item
                container
                justifyContent="center"
                alignContent="center"
            >
                <Paper className={classes.paper}>
                    <AdminTable
                        className={classes.table}
                        {...{
                            classes,
                            discountsToMap,
                            page,
                            rowsPerPage,
                            classes,
                            isSearch,
                            tableHeaders,
                        }}
                    />
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={discountsToMap.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}

export default AdminPage

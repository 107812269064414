import React, { createContext, useContext, useEffect, useState } from "react"
import { connect } from "react-redux"
import { defaultLanguage } from "../../components/constants/preferenceLanguageOptions"
import { FormattedMessage } from "react-intl"
import { populateTrackForm } from "../../actions/track"
import { reset } from "redux-form"
import { trackGAEvent } from "../../actions/user"
import { useOrdersContext } from "./OrdersProvider"

const Context = createContext({
    internalTrackingNumber: null,
    openMultiAttDialog: () => {},
    closeMultiAttDialog: () => {},
    managedFile: {},
    stagedFile: {},
    setStagedFile: () => {},
    shareInitialValues: {
        documents: { labels: true },
        language: defaultLanguage,
    },
    attachments: [],
    contacts: [],
    setInternalTrackingNumber: () => {},
    createFile: () => {},
    getShipmentAttachments: () => {},
    printFile: () => {},
    printMultiLabels: () => {},
})

export const useMultiAttachmentsContext = () => {
    const multiAttachmentsContext = useContext(Context)
    if (!multiAttachmentsContext) {
        throw new Error(
            "Cannot use attachment context outside of MultiAttachmentsProvider"
        )
    }
    return multiAttachmentsContext
}

const MultiAttachmentsProviderContext = ({
    children,
    state = {},
    resetForm,
    authFinished,
    userIsLoaded,
}) => {
    const [internalTrackingNumber, setInternalTrackingNumber] = useState(null)
    const [manageDialogOpen, setManageDialogOpen] = useState(false)
    const [shareDialogOpen, setShareDialogOpen] = useState(false)
    const [managedFile, setManagedFile] = useState({})
    const [stagedFile, setStagedFile] = useState({})
    const language =
        state?.user?.profile?.preferences?.language || defaultLanguage
    const [shareInitialValues, setShareInitialValues] = useState({
        documents: { labels: true },
        language,
    })
    const [contacts, setContacts] = useState([])
    const [attachments, setAttachments] = useState([])
    const [isFromTrailerManifestPage, setIsFromTrailerManifestPage] = useState(
        false
    )

    const { bulkOrders } = useOrdersContext()

    const getShipmentAttachments = () => {
        return [
            {
                value: "labels",
                label: (
                    <FormattedMessage
                        id="documents__labels"
                        defaultMessage="Labels"
                    />
                ),
            },
            {
                value: "bol",
                label: (
                    <FormattedMessage
                        id="documents__BOL"
                        defaultMessage="Paper BOL"
                    />
                ),
            },
        ]
    }

    useEffect(() => {
        bulkOrders.map(selectedOrder => {
            if (
                selectedOrder?.associatedShipmentTrackingNumber &&
                authFinished &&
                userIsLoaded
            ) {
                const shipmentAttachments = getShipmentAttachments()

                setAttachments(shipmentAttachments)
                setContacts(mapRecipients(state?.addressBook?.addresses))
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bulkOrders])

    const mapRecipients = contacts => {
        const recipients = []
        contacts.forEach(contact => {
            const contactInfo = contact?.contact
            recipients.push({
                values: [
                    {
                        type: "phone",
                        value: contactInfo?.phone?.phoneNumber || "",
                    },
                    {
                        type: "email",
                        value: contactInfo?.email?.emailAddress || "",
                    },
                ],
                label: contactInfo?.name,
            })
        })
        return recipients
    }

    const openMultiAttDialog = (list, file = {}, label) => {
        closeMultiAttDialog()
        setManagedFile({ ...file, label })
        setInternalTrackingNumber(
            list.map(item => item?.identifers?.internalTrackingNumber)
        )

        setShareInitialValues({
            documents: { bol: true, labels: true },
            language: state?.user?.profile?.preferences?.language,
        })

        setShareDialogOpen(true)
    }

    const closeMultiAttDialog = type => {
        setStagedFile({})
        setShareDialogOpen(false)
        setManageDialogOpen(false)
        if (type === "success") {
            resetForm()
        }
    }

    return (
        <Context.Provider
            value={{
                internalTrackingNumber,
                openMultiAttDialog,
                closeMultiAttDialog,
                manageDialogOpen,
                shareDialogOpen,
                stagedFile,
                managedFile,
                shareInitialValues,
                attachments,
                setAttachments,
                contacts,
                setInternalTrackingNumber,
                getShipmentAttachments,
                isFromTrailerManifestPage,
                setIsFromTrailerManifestPage,
            }}
        >
            {children}
        </Context.Provider>
    )
}

const mapStateToProps = state => {
    return {
        state,
        authFinished: state?.authorization?.isFinished,
        userIsLoaded: state?.user?.preferences?.isLoaded,
    }
}

const mapDispatchToProps = dispatch => ({
    loadShipment: internalTrackingNumber =>
        dispatch(populateTrackForm(internalTrackingNumber)),
    resetForm: () => dispatch(reset("massShareAttachments")),
    GAEvent: (category, action) => dispatch(trackGAEvent(category, action)),
})

export const MultiAttachmentsProvider = connect(
    mapStateToProps,
    mapDispatchToProps
)(MultiAttachmentsProviderContext)

import { errorMessage, uniqueRequest } from "."
import { goFetch } from "../http"

export const ADD_PICKUP_ACCESSORIAL = "ADD_PICKUP_ACCESSORIAL"
export const ADD_DELIVERY_ACCESSORIAL = "ADD_DELIVERY_ACCESSORIAL"
export const REMOVE_ACCESSORIAL = "REMOVE_ACCESSORIAL"
export const ACCESSORIALS_LOAD_ERROR = "ACCESSORIALS_LOAD_ERROR"
export const ACCESSORIALS_RESULT = "ACCESSORIALS_RESULT"
export const ACCESSORIALS_LOAD = "ACCESSORIALS_LOAD"

export const addAccessorial = (isPickup, accessorialToAdd) => {
    if (isPickup) {
        return {
            type: ADD_PICKUP_ACCESSORIAL,
            accessorialToAdd,
        }
    } else {
        return { type: ADD_DELIVERY_ACCESSORIAL, accessorialToAdd }
    }
}

export const removeAccessorial = accessorialToRemove => {
    return { type: REMOVE_ACCESSORIAL, accessorialToRemove }
}

export function getAccessorialText(item, list) {
    return item.label || (list.find(it => it.value === item.value) || {}).label
}

export const getAccessorialsList = (
    pickupAccessorials = "DOCKPU",
    deliveryAccessorials = "DOCKDEL"
) => {
    return (dispatch, getState) => {
        const state = getState()
        const language = state?.user?.profile?.preferences?.language
        const list = []
        if (pickupAccessorials) {
            for (let acc of pickupAccessorials) {
                const accessorial = state.accessorials?.list?.pickup?.find(
                    item => item?.value === acc.value
                )
                list.push(accessorial?.label[language ?? "en-us"])
            }
        } else {
            const accessorial = state.accessorials?.list?.pickup?.find(
                item => item.value === "DOCKPU"
            )
            list.push(accessorial?.label[language ?? "en-us"])
        }

        if (deliveryAccessorials) {
            for (let acc of deliveryAccessorials) {
                const accessorial = state.accessorials?.list?.delivery?.find(
                    item => item?.value === acc.value
                )
                list.push(accessorial?.label[language ?? "en-us"])
            }
        } else {
            const accessorial = state.accessorials?.list?.delivery?.find(
                item => item.value === "DOCKDEL"
            )
            list.push(accessorial?.label[language ?? "en-us"])
        }

        return list
    }
}

const shouldRequestAccessorials = state => {
    return !state.accessorials.isLoaded && !state.accessorials.isFetching
}

const accessorialsError = error => {
    return dispatch => {
        dispatch(errorMessage(error))
        dispatch({
            type: ACCESSORIALS_LOAD_ERROR,
            error,
        })
    }
}

const accessorialsResult = accessorials => ({
    type: ACCESSORIALS_RESULT,
    accessorials,
})

export const requestAccessorials = () => {
    return async (dispatch, getState) => {
        if (!shouldRequestAccessorials(getState())) return
        const processData = async () => {
            dispatch({ type: ACCESSORIALS_LOAD })
            try {
                const { data: accessorials } = await goFetch(
                    "/accessorial/",
                    { method: "GET", credentials: "same-origin" },
                    true
                )
                dispatch(accessorialsResult(accessorials))
            } catch (err) {
                dispatch(accessorialsError(err))
            }
        }
        return dispatch(uniqueRequest("accessorials", processData))
    }
}

import React, { useEffect, useState } from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { FormattedMessage, useIntl } from "react-intl"
import { Field, formValues, change, clearFields } from "redux-form"
import { makeStyles } from "@material-ui/styles"
import FormCheckbox from "../../../form/form-checkbox"
import CommercialInvoiceForm from "./CommercialInvoiceForm"
import CertificateOfOriginBOLForm from "./CertificateOfOriginBOLForm"
import { useCustomsProfileContext } from "../../../../context/providers/CustomsProfileProvider"
import { Chip, Dialog, Divider } from "@material-ui/core"
import { CustomsDocumentList } from "../../../locations/components/customsProfile/CustomsDocumentsCard"
import { UploadDropzone } from "../../../documents/upload/UploadDropzone"
import { SupportDocumentationUploadSuccess } from "./SupportDocumentationUploadSuccess"
import { UploadErrorDialog } from "../../../documents/upload/UploadErrorDialog"
import { goFetch } from "../../../../http"
import { _arrayBufferToBase64 } from "../../../track/result/attachments/attachmentsCard"
import USMCAFormFinalize from "../../../documents/USMCA/USMCAFormFinalize"
import {
    attachmentIntrMexicoOptions,
    attachmentOptions,
    attachmentOptionsMessages,
    attachmentPROptions,
} from "../../../../misc"
import formName from "../../../util/form-name"
import { useDispatch } from "react-redux"
import { useGAContext } from "../../../../context/providers/GoogleAnalyticsProvider"

const useStyles = makeStyles({
    underline: {
        textDecoration: "underline",
        paddingBottom: "10px",
    },
    sectionSmall: {
        padding: "25px 15% 0 15%",
    },
    sectionMedium: {
        padding: "25px 8% 0 8%",
    },
    scrolling__list: {
        maxHeight: "333px",
        overflowY: "auto",
    },
    chip: {
        margin: "16px 8px",
    },
    chipTitle: {
        marginLeft: "16px",
    },
    divider: {
        margin: "16px 0",
    },
    newDocumentButton: {
        marginTop: "8px",
        marginBottom: "8px",
    },
    section: {
        paddingTop: "12px",
    },

    sectionError: {
        paddingTop: "12px",
        borderStyle: "dashed",
        borderWidth: "1px",
        borderColor: "red",
    },
    dropZone: {
        width: "100%",
        paddingTop: "12px",
    },
    dropZone: {
        width: "100%",
        paddingTop: "12px",
    },
    info: {
        paddingTop: "12px",
        paddingBottom: "24px",
    },
    attachDialog: {
        minWidth: "600px",
    },
    linkDocuments: {
        paddingTop: "12px",
        paddingBottom: "12px",
    },
    formsContainer: {
        paddingTop: "24px",
        paddingBottom: "24px",
        borderBottom: "solid 1px #D4D4D4",
    },
})

const SupportDocumentationForm = formValues({
    commercialInvoice: "requiresCommercialInvoice",
    certificateOfOrigin: "requiresCertificateOfOrigin",
    requiresUSMCA: "requiresUSMCA",
    selectedCustomsDocuments: "selectedCustomsDocuments",
    attachedDocuments: "attachedDocuments",
    documentCategory: "documentCategory",
    customFilename: "customFilename",
})(
    ({
        form,
        commercialInvoice,
        certificateOfOrigin,
        locationId,
        shipmentId,
        attachedDocuments,
        selectedCustomsDocuments,
        customFilename,
        documentCategory,
        requiresUSMCA,
        isIntraMexico,
        attachmentErrors = {},
        errorAttachingDocs = false,
        setErrorAttachingDocs,
        intraMXCommercialInvoiceRequired,
        isUSPRDomesticOffshore = false,
        gaCategory,
    }) => {
        const classes = useStyles()
        const dispatch = useDispatch()
        const { logGAEvent } = useGAContext()

        const [newDocuments, setNewDocuments] = useState([])
        const [linkedDocuments, setLinkedDocuments] = useState([])
        const { documentList, setLocationId } = useCustomsProfileContext()
        const [stagedFile, setStagedFile] = useState({})
        const [step, setStep] = useState(null)
        const [loading, setLoading] = useState(false)
        const [usmcaType, setUsmcaType] = useState()
        const [cooType, setCooType] = useState()
        const [commercialInvoiceType, setCommercialInvoiceType] = useState()
        const [attachmentOptionList, setAttachmentOptionList] = useState([])
        const [linkableDocumentList, setLinkableDocumentList] = useState([])
        const [attachmentErrorMsgs, setAttachmentErrorMsgs] = useState([])

        const intl = useIntl()

        const changeField = (...args) => {
            dispatch(change(form, ...args))
        }

        const clearField = (...args) => {
            dispatch(clearFields(form, false, false, ...args))
        }

        useEffect(() => {
            retrieveAttachedDocuments()
        }, [])

        useEffect(() => {
            if (isIntraMexico) {
                let errors = []
                Object.keys(attachmentErrors).forEach(key => {
                    if (attachmentErrors[key]) {
                        const displayName = intl.formatMessage(
                            attachmentOptionsMessages?.[key]
                        )
                        if (displayName) {
                            errors.push(
                                <Grid item container>
                                    <Typography variant="caption">
                                        <FormattedMessage
                                            id="generalTerms.validation__isRequired"
                                            defaultMessage="{field} is required"
                                            values={{
                                                field: `* ${displayName}`,
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                            )
                        }
                    }
                })
                setAttachmentErrorMsgs(errors)
                if (errors.length === 0) {
                    setErrorAttachingDocs(false)
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [attachedDocuments, attachmentErrors])

        useEffect(() => {
            setLocationId(locationId)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [locationId])

        useEffect(() => {
            let newCooType
            if (
                selectedCustomsDocuments?.some(
                    doc => doc.documentCategory === "certificateOfOrigin"
                )
            ) {
                newCooType = "link"
            } else if (
                attachedDocuments?.some(
                    doc => doc.documentCategory === "certificateOfOrigin"
                )
            ) {
                newCooType = "attach"
            } else if (certificateOfOrigin) {
                newCooType = "generate"
            }
            let newUsmcaType
            if (
                selectedCustomsDocuments?.some(
                    doc => doc.documentCategory === "USMCA"
                )
            ) {
                newUsmcaType = "link"
            } else if (
                attachedDocuments?.some(doc => doc.documentCategory === "USMCA")
            ) {
                newUsmcaType = "attach"
            } else if (requiresUSMCA) {
                newUsmcaType = "generate"
            }
            let newCommercialInvoiceType
            if (
                selectedCustomsDocuments?.some(
                    doc => doc.documentCategory === "commercialInvoice"
                )
            ) {
                newCommercialInvoiceType = "link"
            } else if (
                attachedDocuments?.some(
                    doc => doc.documentCategory === "commercialInvoice"
                )
            ) {
                newCommercialInvoiceType = "attach"
            } else if (commercialInvoice) {
                newCommercialInvoiceType = "generate"
            }

            let newSupportInvoiceType
            if (
                attachedDocuments?.some(
                    doc => doc.documentCategory === "supportInvoice"
                )
            ) {
                newSupportInvoiceType = "attach"
            }

            let newAttachmentOptionList
            if (isIntraMexico) {
                newAttachmentOptionList = attachmentIntrMexicoOptions?.filter(
                    x => {
                        if (
                            (x.value === "commercialInvoice" &&
                                newCommercialInvoiceType &&
                                newCommercialInvoiceType !== "attach") ||
                            (x.value === "supportInvoice" &&
                                newSupportInvoiceType &&
                                newSupportInvoiceType !== "attach")
                        ) {
                            return false
                        }
                        return true
                    }
                )
            } else if (isUSPRDomesticOffshore) {
                newAttachmentOptionList = attachmentPROptions?.filter(x => {
                    if (
                        x.value === "commercialInvoice" &&
                        newCommercialInvoiceType &&
                        newCommercialInvoiceType !== "attach"
                    ) {
                        return false
                    }
                    return true
                })
            } else {
                newAttachmentOptionList = attachmentOptions?.filter(x => {
                    if (
                        (x.value === "USMCA" &&
                            newUsmcaType &&
                            newUsmcaType !== "attach") ||
                        (x.value === "commercialInvoice" &&
                            newCommercialInvoiceType &&
                            newCommercialInvoiceType !== "attach") ||
                        (x.value === "certificateOfOrigin" &&
                            newCooType &&
                            newCooType !== "attach")
                    ) {
                        return false
                    }
                    return true
                })
            }

            const newLinkableDocumentList = documentList?.filter(x => {
                if (
                    (x.documentCategory === "USMCA" &&
                        newUsmcaType &&
                        newUsmcaType !== "link") ||
                    (x.documentCategory === "commercialInvoice" &&
                        newCommercialInvoiceType &&
                        newCommercialInvoiceType !== "link") ||
                    (x.documentCategory === "certificateOfOrigin" &&
                        newCooType &&
                        newCooType !== "link")
                ) {
                    return false
                }
                return true
            })

            setCooType(newCooType)
            setUsmcaType(newUsmcaType)
            setCommercialInvoiceType(newCommercialInvoiceType)
            setAttachmentOptionList(newAttachmentOptionList)
            setLinkableDocumentList(newLinkableDocumentList)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            selectedCustomsDocuments,
            attachedDocuments,
            certificateOfOrigin,
            requiresUSMCA,
            commercialInvoice,
            documentList,
        ])

        const onLinkToShipment = (document, isNew = false) => {
            if (!selectedCustomsDocuments?.length) {
                changeField("deferredTradeDocuments", true)
            }
            changeField("selectedCustomsDocuments", [
                ...(selectedCustomsDocuments ?? []),
                document,
            ])
            if (isNew) {
                setNewDocuments(prev => [...prev, document])
            } else {
                setLinkedDocuments(prev => [...prev, document])
            }

            logGAEvent(gaCategory, "Link Existing Document Clicked")
        }

        const onUnlinkFromShipment = (document, isNew = false) => {
            const newSelected = selectedCustomsDocuments?.filter(
                x =>
                    !(
                        x.documentCategory === document.documentCategory &&
                        x.customFilename === document.customFilename
                    )
            )
            if (newSelected.length === 0) {
                changeField("deferredTradeDocuments", false)
            }
            changeField("selectedCustomsDocuments", newSelected)
            const localDocuments = (isNew
                ? newDocuments
                : linkedDocuments
            )?.filter(
                x =>
                    !(
                        x.documentCategory === document.documentCategory &&
                        x.customFilename === document.customFilename
                    )
            )
            if (isNew) {
                setNewDocuments(localDocuments)
            } else {
                setLinkedDocuments(localDocuments)
            }
            logGAEvent(gaCategory, "UnLink Existing Document Clicked")
        }

        const onStageFile = file => {
            if (file) {
                const reader = new FileReader()
                reader.onabort = () => console.log("file reading was aborted")
                reader.onerror = () => console.log("file reading has failed")
                reader.onload = () => {
                    const result = reader.result
                    const tempStagedFile = {
                        content: _arrayBufferToBase64(result),
                        mimeType: file.type,
                        originalFileName: file.name,
                    }

                    setStagedFile(tempStagedFile)
                }

                reader.readAsArrayBuffer(file)
                setStep("uploadStaged")
                logGAEvent(gaCategory, "File Selected Staged")
            } else {
                setStep("uploadError")
            }
        }

        const retrieveAttachedDocuments = async () => {
            setLoading(true)
            try {
                const { data } = await goFetch(
                    `documents/attachment/${shipmentId}/stage`,
                    {
                        method: "GET",
                        credentials: "same-origin",
                        headers: { "cache-control": "no-cache" },
                        data: {
                            content: stagedFile.content,
                            attachmentType: documentCategory,
                            mimeType: stagedFile.mimeType,
                            originalFileName: stagedFile.originalFileName,
                            userFileName: customFilename,
                        },
                    },
                    true
                )
                const initialAttachedDocuments = (data ?? []).map(document => ({
                    ...document,
                    documentCategory: document.attachmentType,
                    customFilename: document.userFileName,
                }))
                changeField("attachedDocuments", initialAttachedDocuments)
            } finally {
                setLoading(false)
            }
        }

        const onSubmitAttachedDocument = async () => {
            setLoading(true)
            const { data } = await goFetch(
                `documents/attachment/${shipmentId}/stage`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: { "cache-control": "no-cache" },
                    data: {
                        content: stagedFile.content,
                        attachmentType: documentCategory,
                        mimeType: stagedFile.mimeType,
                        originalFileName: stagedFile.originalFileName,
                        userFileName: customFilename,
                    },
                },
                true
            )

            changeField("attachedDocuments", [
                ...attachedDocuments,
                {
                    documentCategory,
                    customFilename,
                    ...data,
                },
            ])
            clearField("documentCategory", "customFilename")
            setLoading(false)
            setStep(null)
        }

        const onDeleteAttachedDocument = async attachedDocument => {
            try {
                await goFetch(
                    `documents/attachment/${shipmentId}/stage/${attachedDocument.id}`,
                    {
                        method: "DELETE",
                        credentials: "same-origin",
                        headers: { "cache-control": "no-cache" },
                    },
                    true
                )
                changeField(
                    "attachedDocuments",
                    attachedDocuments.filter(x => x.id !== attachedDocument.id)
                )
            } catch (error) {}
        }

        return (
            <React.Fragment>
                <Grid container item className={classes.sectionMedium}>
                    <Grid item container justify="center">
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="orderDetails.support__documentation__title"
                                defaultMessage="Supporting Documentation"
                            />
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} className={classes.info}>
                        <Typography variant="caption">
                            {!isIntraMexico ? (
                                <FormattedMessage
                                    id="documents.tradeDocumentationInfoPreBook"
                                    defaultMessage="Trade documentation will be sent to Customs on your behalf as a single document after a signature & notary stamp have been applied. FedEx requires trade documentation to be signed. You can add your signature by uploading it directly or by printing the trade documents first, signing them manually, and then uploading the signed trade documents to LTL Select. Your documents will automatically be shared with FedEx."
                                />
                            ) : intraMXCommercialInvoiceRequired ? (
                                <FormattedMessage
                                    id="documents.tradeDocumentationInfoPreBookMexico"
                                    defaultMessage="To comply with regulations, shipments originating in the border region must include documentation establishing merchandise value and origin. Please upload any relevant documents such as a commercial invoice and/or customs form (pedimento)."
                                />
                            ) : (
                                <FormattedMessage
                                    id="documents.tradeDocumentationInfoPreBookMexicoOptional"
                                    defaultMessage="Please attach any documents that you would like FedEx to issue along with the invoice to the payor."
                                />
                            )}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        className={
                            errorAttachingDocs
                                ? classes.sectionError
                                : classes.section
                        }
                    >
                        <Grid item container justify={"center"}>
                            <Typography variant="body2">
                                <FormattedMessage
                                    id="documents.tradeDocumentationUploadTitle"
                                    defaultMessage="Upload Document"
                                />
                            </Typography>
                        </Grid>
                        {attachmentErrorMsgs?.length > 0 ? (
                            <Grid item container className={classes.info}>
                                {attachmentErrorMsgs.map(msg => msg)}
                            </Grid>
                        ) : null}
                        <Grid item container className={classes.dropZone}>
                            <UploadDropzone onStage={onStageFile} />
                            <Dialog
                                open={step}
                                onClose={() => setStep(null)}
                                fullWidth
                            >
                                {step === "uploadStaged" ? (
                                    <SupportDocumentationUploadSuccess
                                        onSubmit={onSubmitAttachedDocument}
                                        onClose={() => {
                                            setStep(null)
                                            clearField(
                                                "documentCategory",
                                                "customFilename"
                                            )
                                        }}
                                        stagedFile={stagedFile}
                                        shipmentId={shipmentId}
                                        documentCategory={documentCategory}
                                        loading={loading}
                                        attachmentOptionList={
                                            attachmentOptionList
                                        }
                                        gaCategory={gaCategory}
                                    />
                                ) : null}
                                {step === "uploadError" ? (
                                    <UploadErrorDialog
                                        onClose={() => setStep(null)}
                                    />
                                ) : null}
                            </Dialog>
                        </Grid>
                        <Grid item container className={classes.section}>
                            <Grid item container xs={12}>
                                {attachedDocuments?.map(
                                    (selectedDocument, idx) => (
                                        <Chip
                                            key={idx}
                                            color="primary"
                                            className={classes.chip}
                                            label={`${selectedDocument?.documentCategory} - ${selectedDocument?.customFilename}`}
                                            onDelete={() =>
                                                onDeleteAttachedDocument(
                                                    selectedDocument
                                                )
                                            }
                                        />
                                    )
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    {!isIntraMexico &&
                        !isUSPRDomesticOffshore &&
                        (linkableDocumentList?.length > 0 ||
                            newDocuments?.length > 0) && (
                            <React.Fragment>
                                <Grid
                                    container
                                    className={classes.linkDocuments}
                                >
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="subtitle1"
                                            gutterBottom
                                        >
                                            <FormattedMessage
                                                id="documents.linkDocuments"
                                                defaultMessage="Link Existing Documents"
                                            />
                                        </Typography>
                                    </Grid>
                                    {linkableDocumentList?.length > 0 && (
                                        <Grid
                                            item
                                            container
                                            xs={4}
                                            className={classes.scrolling__list}
                                        >
                                            <Grid item xs={12}>
                                                <CustomsDocumentList
                                                    attachable
                                                    customsDocuments={
                                                        linkableDocumentList
                                                    }
                                                    attachedDocuments={
                                                        selectedCustomsDocuments ??
                                                        []
                                                    }
                                                    locationId={locationId}
                                                    onLinkToShipment={
                                                        onLinkToShipment
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid
                                        item
                                        container
                                        xs={documentList?.length ? 8 : 12}
                                        alignContent="flex-start"
                                    >
                                        {linkedDocuments?.length > 0 && (
                                            <React.Fragment>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.chipTitle
                                                    }
                                                >
                                                    <Typography variant="body2">
                                                        <FormattedMessage
                                                            id="documents.linkedDocuments"
                                                            defaultMessage="Linked documents"
                                                        />
                                                    </Typography>
                                                </Grid>
                                                <Grid item container xs={12}>
                                                    {linkedDocuments?.map(
                                                        (
                                                            selectedDocument,
                                                            idx
                                                        ) => (
                                                            <Chip
                                                                key={idx}
                                                                color="primary"
                                                                className={
                                                                    classes.chip
                                                                }
                                                                label={`${selectedDocument?.documentCategory} - ${selectedDocument?.customFilename}`}
                                                                onDelete={() =>
                                                                    onUnlinkFromShipment(
                                                                        selectedDocument,
                                                                        false
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </Grid>
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                </Grid>
                                <Divider
                                    variant="middle"
                                    className={classes.divider}
                                />
                            </React.Fragment>
                        )}
                    {!isIntraMexico && (
                        <Grid container>
                            <Grid
                                item
                                container
                                xs={12}
                                alignItems="center"
                                justify="center"
                            >
                                {!isUSPRDomesticOffshore && (
                                    <Grid
                                        item
                                        container
                                        xs={4}
                                        justify="center"
                                    >
                                        <Field
                                            component={FormCheckbox}
                                            name="requiresUSMCA"
                                            label={
                                                <Typography variant="subtitle1">
                                                    <FormattedMessage
                                                        id="attachmentOptions__USMCA"
                                                        defaultMessage="USMCA / CUSMA / ACEUM / T-MEC"
                                                    />
                                                </Typography>
                                            }
                                            disabled={
                                                usmcaType &&
                                                usmcaType !== "generate"
                                            }
                                            category={gaCategory}
                                        />
                                    </Grid>
                                )}
                                <Grid item container xs={4} justify="center">
                                    <Field
                                        component={FormCheckbox}
                                        name="requiresCommercialInvoice"
                                        label={
                                            <Typography variant="subtitle1">
                                                <FormattedMessage
                                                    id="orderDetails.commercial__invoice__title"
                                                    defaultMessage="Commercial Invoice"
                                                />
                                            </Typography>
                                        }
                                        disabled={
                                            commercialInvoiceType &&
                                            commercialInvoiceType !== "generate"
                                        }
                                        category={gaCategory}
                                    />
                                </Grid>
                                {!isUSPRDomesticOffshore && (
                                    <Grid
                                        item
                                        container
                                        xs={4}
                                        justify="center"
                                    >
                                        <Field
                                            component={FormCheckbox}
                                            name="requiresCertificateOfOrigin"
                                            label={
                                                <Typography variant="subtitle1">
                                                    <FormattedMessage
                                                        id="orderDetails.certificate__of__origin__title"
                                                        defaultMessage="Certificate Of Origin"
                                                    />
                                                </Typography>
                                            }
                                            disabled={
                                                cooType &&
                                                cooType !== "generate"
                                            }
                                            category={gaCategory}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item container className={classes.formsContainer}>
                    {commercialInvoice && (
                        <Grid item className={classes.sectionMedium}>
                            <CommercialInvoiceForm gaCategory={gaCategory} />
                        </Grid>
                    )}

                    {certificateOfOrigin && (
                        <Grid item className={classes.sectionMedium}>
                            <CertificateOfOriginBOLForm
                                gaCategory={gaCategory}
                            />
                        </Grid>
                    )}

                    {requiresUSMCA && (
                        <Grid item container className={classes.sectionMedium}>
                            <USMCAFormFinalize gaCategory={gaCategory} />
                        </Grid>
                    )}
                </Grid>
            </React.Fragment>
        )
    }
)

export default formName(SupportDocumentationForm)

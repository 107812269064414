import React from "react"
import classNames from "classnames"
import { withStyles, SnackbarContent, IconButton } from "@material-ui/core"
import green from "@material-ui/core/colors/green"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"
import CloseIcon from "@material-ui/icons/Close"

const variantIcon = {
    success: CheckCircleIcon,
    error: ErrorIcon,
}

const styles = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    message: {
        display: "flex",
        alignItems: "center",
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
})

const SnackbarContentWrapper = props => {
    const { classes, className, message, onClose, variant, ...other } = props
    const Icon = variantIcon[variant]

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            message={
                <span className={classes.message}>
                    <Icon
                        className={classNames(
                            classes.icon,
                            classes.iconVariant
                        )}
                    />
                    {message}
                </span>
            }
            action={[
                <IconButton
                    id="closeSnackbarShare"
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon
                        id="closeSnackbarShareCloseIcon"
                        className={classes.icon}
                    />
                </IconButton>,
            ]}
            {...other}
        />
    )
}

export default withStyles(styles)(SnackbarContentWrapper)

import React, { useRef, useEffect } from "react"
import Destination from "./Destination"
import FormRadio from "../../form/form-radio"
import { modeOptions } from "../bookShipment/atoms/modeOptions"
import Accessorials from "../bookShipment/atoms/Accessorials"
import FormSwitch from "../../form/form-switch"
import Items from "./Items"
import FormDatePicker from "../../form/form-datepicker"
import FormSelectBillTo from "../../form/form-select-bill-to"
import Origin from "./Origin"
import { Button, Grid, Modal, Typography } from "@material-ui/core"
import { Field } from "redux-form"
import { FormattedMessage } from "react-intl"
import { makeStyles } from "@material-ui/styles"
import LocationLabel from "../../common/LocationLabel"
import {
    allPaymentOptions,
    inboundPaymentOptions,
} from "../bookShipment/atoms/paymentOptions"
import { traverseSyncError } from "../bookShipment/atoms/getCurrentBSStep"
import NextDayPickUpDialog from "../bookShipment/atoms/NextDayPickUpDialog"
import { useState } from "react"
import moment from "moment/moment"
import { useFlags } from "launchdarkly-react-client-sdk"
import FormCheckbox from "../../form/form-checkbox"
import { nextBusinessDay } from "./selector"
import { useSnackbarContext } from "../../../context/providers/snackbarProvider"
import { openEEIConfirmationModal } from "../bookShipment/atoms/ElectronicExportInformation"
import ConfirmActionModal from "../../common/ConfirmActionModal"
import { isValidCountryForFedexShipment } from "../../../actions/validation"
import { dateIsInPFFRange } from "../../util"
import SecondLocationModal from "../bookShipment/atoms/SecondLocationModal"
import {
    confirmAccountTypesForMultiCountry,
    isMultiCountryShipment,
} from "../../util/internationalUtils"
import { accountTypeError } from "../../../messages/error-constants"

const useStyles = makeStyles({
    section: {
        paddingTop: "10px",
    },
    title: {
        paddingBottom: "5px",
    },
    bigSection: {
        paddingTop: "30px",
    },
    baseContainer: {
        paddingTop: "32px",
        paddingBottom: "272px",
    },
    input__field: {
        //backgroundColor: "rgb(242, 242, 242)",
        cursor: "pointer",
        width: "100%",
    },
    textInput__field: {
        cursor: "text",
    },
    workflowContainer: {
        padding: "10px 10% 25px 10%",
    },
    basicInfo: {
        padding: "0 12%",
    },
    getRates: {
        paddingTop: "30px",
    },
    cancelEdit: {
        marginRight: "10px",
    },
    locationLabel: {
        paddingLeft: "3px",
    },
    radioLabels: {
        fontSize: ".835rem",
        color: "#636363",
    },
})

const UNSUPPORTED_STATES = ["AK", "HI", "PR"]
const STATE_MAP = {
    AK: "Alaska",
    HI: "Hawaii",
    PR: "Puerto Rico",
}

export default function QuickRateForm({
    subProps = { formValues: {} },
    onBillToLocationChange,
    onDirectionChange,
    changeField,
    formattedLocations = [],
    initialValues,
    pickupAccessorialsList,
    deliveryAccessorialsList,
    invalid,
    inputProps,
    intl,
    handleHUPanel,
    isHUOpen,
    getRates,
    itemsIsLoaded,
    favoriteItems,
    doSearch,
    addNewHandlingUnit,
    handleCancel,
    editMode,
    trackGA,
    submitting,
    originCities,
    destinationCities,
    handlingUnitSyncErrors,
    selectedMXSATCommodity,
    setSelectedMXSATCommodity,
    checkRestrictionsByPostalCode,
    openModal,
    closeModal,
    checkLoadedAKSpecialCases,
    requiredAccessorialList,
    gaCategory,
    locations,
}) {
    const classes = useStyles()
    const shipDateRef = useRef(null)
    const {
        nextDayPickUpFlag,
        singleAccountWorkflow,
        canadaMexicoLocationModal,
    } = useFlags()
    const {
        origin = {},
        destination = {},
        isFreightDirect,
        isFreightDirectReturns,
        isFreightBox,
        mode,
        selectedLocation = {},
        handlingUnits = [],
        pickupDate,
        isFedexFileEEI,
        itns,
        billToLocationSecondAccount = null,
    } = subProps?.formValues
    const { openSnackbar } = useSnackbarContext()

    const locationPickupAccessorials = (
        selectedLocation?.pickupAccessorials ?? []
    ).map(entry => entry.value) ?? ["DOCKPU"]

    const locationDeliveryAccessorials = (
        selectedLocation?.deliveryAccessorials ?? []
    ).map(entry => entry.value) ?? ["DOCKDEL"]

    const [requireNextDayPickUpModal, setRequireNextDayPickUpModal] = useState(
        false
    )

    const [locationModalOpen, setLocationModalOpen] = useState(false)
    const [
        errorOriginAndDestinationCountry,
        setErrorOriginAndDestinationCountry,
    ] = useState([])

    const isPickUpToday = moment(pickupDate).isSame(moment(), "day")

    // range between october 15 and april 30
    const isPFFAvailable = dateIsInPFFRange(moment(pickupDate))

    const originCountry = origin?.shippingAddress?.address?.country
    const originPostalCode = origin?.shippingAddress?.address?.postalCode

    const isFreightDirectReturnsLocation =
        selectedLocation?.locationType === "FEDEX_DIRECT_RETURNS"

    const isFreightDirectLocation =
        selectedLocation?.locationType === "FEDEX_DIRECT"

    const isFreightBoxLocation =
        selectedLocation?.locationType === "FEDEX_FREIGHT_BOX"

    const isValidSingleAccountFlow =
        singleAccountWorkflow &&
        !isFreightDirectReturnsLocation &&
        !isFreightDirectLocation &&
        !isFreightBoxLocation &&
        selectedLocation?.fedexFreightAccountType !== "FXFM" &&
        selectedLocation?.fedexBillToAccountType !== "FXFM"

    const destinationCountry = destination?.shippingAddress?.address?.country
    const destinationState = destination?.shippingAddress?.address?.state
    const originState = origin?.shippingAddress?.address?.state

    const isAKShipment =
        isValidCountryForFedexShipment(originCountry) &&
        isValidCountryForFedexShipment(destinationCountry) &&
        (originState === "AK" || destinationState === "AK") &&
        !(originState === "AK" && destinationState === "AK")

    const isUnsupportedCountryState = (
        originCountry,
        originState,
        destinationCountry,
        destinationState
    ) => {
        return (
            destinationCountry === "US" &&
            originCountry === "US" &&
            (((originState === "PR" || originState === "HI") &&
                UNSUPPORTED_STATES.includes(destinationState)) ||
                (originState === "AK" &&
                    (destinationState === "PR" || destinationState === "HI")))
        )
    }

    const isMexicoOffshore = (
        originCountry,
        originState,
        destinationCountry,
        destinationState
    ) => {
        const usMxArray = ["US", "MX"]
        return (
            originCountry !== destinationCountry &&
            usMxArray.includes(originCountry) &&
            usMxArray.includes(destinationCountry) &&
            [originState, destinationState].some(state =>
                UNSUPPORTED_STATES.includes(state)
            )
        )
    }

    const onShowAccessorialsChange = value => {
        if (!value) {
            if (mode === "outbound") {
                changeField("pickupAccessorials", ["DOCKPU"])
            } else if (mode === "inbound") {
                changeField("deliveryAccessorials", ["DOCKDEL"])
            }
        } else {
            if (mode === "outbound") {
                changeField("pickupAccessorials", locationPickupAccessorials)
            } else if (mode === "inbound") {
                changeField(
                    "deliveryAccessorials",
                    locationDeliveryAccessorials
                )
            }
        }
    }

    const shouldShowITNInformationModal = () => {
        return isFedexFileEEI === false && itns && !itns[0]
    }

    useEffect(() => {
        if (shipDateRef.current) {
            shipDateRef.current.addEventListener("keypress", event => {
                if (event.code === "Enter") {
                    event.preventDefault()
                    shipDateRef.current.click()
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipDateRef.current])

    useEffect(() => {
        setRequireNextDayPickUpModal(false)
        if (
            originCountry === "MX" &&
            originPostalCode &&
            originPostalCode.length >= 3 &&
            nextDayPickUpFlag
        ) {
            const makeZipcodeApiCall = async () => {
                try {
                    const result = await checkRestrictionsByPostalCode(
                        originCountry,
                        originPostalCode
                    )
                    let restrictions = result?.data ?? []

                    if (
                        restrictions.find(
                            restriction =>
                                restriction?.restriction
                                    ?.requireExtraDayToPickUp === true
                        )
                    ) {
                        setRequireNextDayPickUpModal(true)
                        return result
                    }
                } catch (error) {
                    return
                }
            }
            makeZipcodeApiCall()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originPostalCode, originCountry])

    const determineInvalidHandlingUnit = (
        handlingUnitSyncErrors,
        traverseSyncError
    ) => {
        if (handlingUnitSyncErrors) {
            for (let hu in handlingUnitSyncErrors) {
                if (traverseSyncError(handlingUnitSyncErrors[hu])) {
                    return Number(hu)
                }
            }
        }
    }

    const canadaMexicoCheck = (
        originCountry,
        destinationCountry,
        selectedLocation
    ) => {
        if (
            confirmAccountTypesForMultiCountry(selectedLocation, locations) &&
            canadaMexicoLocationModal
        ) {
            setLocationModalOpen(true)
        } else {
            setErrorOriginAndDestinationCountry([
                originCountry,
                destinationCountry,
            ])
        }
    }

    return (
        <Grid item container className={classes.basicInfo}>
            <NextDayPickUpDialog
                isDialogOpen={requireNextDayPickUpModal && isPickUpToday}
                inputProps={inputProps}
                shipDateRef={shipDateRef}
                gaCategory={gaCategory}
            />
            <Modal
                open={locationModalOpen}
                onClose={() => {
                    setLocationModalOpen(false)
                    if (billToLocationSecondAccount) {
                        changeField("billToLocationSecondAccount", null)
                    }
                    setErrorOriginAndDestinationCountry([])
                }}
            >
                <SecondLocationModal
                    formattedLocations={formattedLocations}
                    mode={mode}
                    originCountry={originCountry}
                    beforeNextStep={() => getRates()}
                    selectedLocation={selectedLocation}
                    changeField={changeField}
                    setLocationModalOpen={setLocationModalOpen}
                    locations={locations}
                    origin={origin}
                    destination={destination}
                    displayAddressMismatchDetails={false}
                />
            </Modal>

            <Grid item container className={classes.title}>
                {mode !== "thirdParty" && (
                    <Typography variant="subtitle1">
                        {mode === "outbound" ? (
                            <FormattedMessage
                                id="bookShipment.basicInfo__origin"
                                defaultMessage="Origin Information"
                            />
                        ) : (
                            <FormattedMessage
                                id="bookShipment.basicInfo__destination"
                                defaultMessage="Destination Information"
                            />
                        )}
                    </Typography>
                )}
            </Grid>
            <Grid
                item
                container
                xs={6}
                justifyContent="center"
                alignItems="center"
            >
                <Field
                    id="billToLocation"
                    component={FormSelectBillTo}
                    name="billToLocation"
                    label={
                        <FormattedMessage
                            id="getRates.form__billToLocation"
                            defaultMessage="Bill to Location"
                        />
                    }
                    onChange={onBillToLocationChange}
                    options={formattedLocations}
                    billTo
                    InputProps={inputProps}
                    category={gaCategory}
                />
            </Grid>

            <Grid item container xs={6}>
                {!isFreightDirectReturns && (
                    <Grid item container className={classes.section}>
                        {selectedLocation?.isShipperEnabled && (
                            <Grid item container justify="flex-end">
                                <Field
                                    name="mode"
                                    component={FormRadio}
                                    options={
                                        selectedLocation?.is3rdPartyEnabled
                                            ? modeOptions.shipperAndThirdParty
                                            : modeOptions.shipperOnly
                                    }
                                    onChange={(e, newMode) =>
                                        onDirectionChange(
                                            selectedLocation,
                                            newMode,
                                            false,
                                            isFreightDirect,
                                            isFreightDirectReturns
                                        )
                                    }
                                    category={gaCategory}
                                    required
                                />
                            </Grid>
                        )}
                        {isAKShipment && isPFFAvailable && (
                            <Grid item container justifyContent="flex-end">
                                <Field
                                    name="protectFromFreezing"
                                    label={
                                        <FormattedMessage
                                            id="getRates.form__freezing"
                                            defaultMessage="Protect From Freezing"
                                        />
                                    }
                                    component={FormCheckbox}
                                    category={gaCategory}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
            {!isValidSingleAccountFlow && (
                <Grid item container className={classes.locationLabel}>
                    <LocationLabel
                        isFreightBox={isFreightBox}
                        isFreightDirect={isFreightDirect}
                        isFreightDirectReturns={isFreightDirectReturns}
                    />
                </Grid>
            )}

            <Grid item container className={classes.section}>
                {isValidSingleAccountFlow && singleAccountWorkflow && (
                    <Grid item container xs={12} alignItems="flex-end">
                        <Field
                            component={FormCheckbox}
                            name="isFreightDirect"
                            label={
                                <FormattedMessage
                                    id="getRates.billToLocation__freightDirect"
                                    defaultMessage="FedEx Freight Direct"
                                />
                            }
                            onChange={(e, option) => {
                                changeField("mode", "outbound")
                                changeField("isFreightDirectReturns", false)
                                onDirectionChange(
                                    selectedLocation,
                                    "outbound",
                                    false,
                                    option,
                                    false
                                )
                            }}
                            category={gaCategory}
                        />
                    </Grid>
                )}

                <Grid item container xs={6} alignItems="flex-end">
                    <Field
                        component={FormDatePicker}
                        name="pickupDate"
                        label={[
                            "* ",
                            <FormattedMessage
                                id="getRates.form__shipDate"
                                defaultMessage="Ship Date"
                            />,
                        ]}
                        id="getRates__pickupDate"
                        placeholder={intl.formatMessage({
                            id: "getRates.form__date",
                            defaultMessage: "Date",
                        })}
                        minDate={
                            isFreightDirectReturns
                                ? nextBusinessDay()
                                : moment.utc(
                                      moment()
                                          .tz("Pacific/Honolulu")
                                          .format("YYYY-MM-DD")
                                  )
                        }
                        maxDate={
                            isFreightDirectReturns
                                ? moment
                                      .utc(
                                          moment()
                                              .tz("Pacific/Honolulu")
                                              .format("YYYY-MM-DD")
                                      )
                                      .add(7, "days")
                                : moment
                                      .utc(
                                          moment()
                                              .tz("Pacific/Honolulu")
                                              .format("YYYY-MM-DD")
                                      )
                                      .add(1, "year")
                        }
                        InputProps={inputProps}
                        inputRef={shipDateRef}
                        category={gaCategory}
                    />
                </Grid>

                {selectedLocation?.isShipperEnabled &&
                    !isFreightDirect &&
                    !isFreightDirectReturns &&
                    mode !== "thirdParty" && (
                        <Grid
                            item
                            container
                            alignItems="flex-end"
                            justify="flex-end"
                            xs={6}
                        >
                            <Field
                                name={
                                    mode === "outbound"
                                        ? `origin.showAccessorials`
                                        : `destination.showAccessorials`
                                }
                                component={FormSwitch}
                                label={
                                    mode === "outbound" ? (
                                        <FormattedMessage
                                            id="bookShipment.basicInfo__addPickupAccessorials"
                                            defaultMessage="Add Pickup Accessorials"
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="bookShipment.basicInfo__addDeliveryAccessorials"
                                            defaultMessage="Add Delivery Accessorials"
                                        />
                                    )
                                }
                                onChange={(e, value) => {
                                    onShowAccessorialsChange(value)
                                }}
                                category={gaCategory}
                            />
                        </Grid>
                    )}
            </Grid>

            <Grid item container>
                {selectedLocation?.isShipperEnabled &&
                    !isFreightDirect &&
                    !isFreightDirectReturns &&
                    mode !== "thirdParty" &&
                    ((mode === "outbound" && origin.showAccessorials) ||
                        (mode === "inbound" &&
                            destination.showAccessorials)) && (
                        <Grid
                            item
                            container
                            alignContent="flex-start"
                            className={classes.section}
                        >
                            <Accessorials
                                list={
                                    mode === "outbound"
                                        ? pickupAccessorialsList
                                        : deliveryAccessorialsList ?? []
                                }
                                name={
                                    mode === "outbound"
                                        ? "pickupAccessorials"
                                        : "deliveryAccessorials"
                                }
                                quickRate
                                formName="quickRate"
                                formValues={subProps?.formValues}
                                permanentList={requiredAccessorialList}
                                category={gaCategory}
                            />
                        </Grid>
                    )}
            </Grid>

            {!isFreightBox &&
            !isFreightDirect &&
            !isFreightDirectReturns &&
            mode !== "thirdParty" ? (
                <Grid item container className={classes.section}>
                    <Grid item container xs={12} alignItems="flex-end">
                        <Field
                            name="paymentType"
                            component={FormRadio}
                            options={
                                mode === "inbound"
                                    ? inboundPaymentOptions
                                    : allPaymentOptions
                            }
                            required
                            label={
                                <Typography
                                    variant="body2"
                                    className={classes.radioLabels}
                                >
                                    <FormattedMessage
                                        id="bookShipment.basicInfo__paymentTerms"
                                        defaultMessage="Payment Terms"
                                    />
                                </Typography>
                            }
                            category={gaCategory}
                        />
                    </Grid>
                </Grid>
            ) : null}

            <Grid item container className={classes.bigSection}>
                {(mode === "inbound" || mode === "thirdParty") && (
                    <Grid item container>
                        <Origin
                            {...{
                                ...subProps,
                                pickupAccessorialsList,
                                cities: originCities,
                                requiredAccessorialList,
                                gaCategory,
                            }}
                        />
                    </Grid>
                )}

                {(mode === "outbound" || mode === "thirdParty") && (
                    <Grid
                        item
                        container
                        className={
                            mode === "thirdParty" ? classes.bigSection : ""
                        }
                    >
                        <Destination
                            {...{
                                ...subProps,
                                deliveryAccessorialsList,
                                cities: destinationCities,
                                requiredAccessorialList,
                                gaCategory,
                            }}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid item container className={classes.bigSection}>
                <Items
                    {...{
                        ...subProps,
                        handleHUPanel,
                        isHUOpen,
                        itemsIsLoaded,
                        favoriteItems,
                        doSearch,
                        addNewHandlingUnit,
                        trackGA,
                        editMode,
                        selectedMXSATCommodity,
                        setSelectedMXSATCommodity,
                        gaCategory,
                    }}
                />
                {errorOriginAndDestinationCountry &&
                    errorOriginAndDestinationCountry.length > 0 && (
                        <Typography
                            variant="body2"
                            className={classes.errorText}
                            display="block"
                            gutterBottom
                        >
                            {accountTypeError(
                                errorOriginAndDestinationCountry[0],
                                errorOriginAndDestinationCountry[1]
                            )}
                        </Typography>
                    )}
            </Grid>

            <Grid item container justify="center" className={classes.getRates}>
                {editMode && (
                    <Button
                        id="quickCancelEdit"
                        color="secondary"
                        variant="outlined"
                        onClick={() => handleCancel()}
                        type="submit"
                        className={classes.cancelEdit}
                    >
                        <FormattedMessage
                            id="generalTerms__back"
                            defaultMessage="Back"
                        />
                    </Button>
                )}
                <Button
                    id="quickRateGetRates"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        if (invalid) {
                            if (handlingUnits.length > 1) {
                                const firstInvalidHU = determineInvalidHandlingUnit(
                                    handlingUnitSyncErrors,
                                    traverseSyncError
                                )

                                if (firstInvalidHU !== isHUOpen) {
                                    handleHUPanel(firstInvalidHU)
                                }
                            }
                        }

                        if (
                            isUnsupportedCountryState(
                                originCountry,
                                originState,
                                destinationCountry,
                                destinationState
                            ) ||
                            isMexicoOffshore(
                                originCountry,
                                originState,
                                destinationCountry,
                                destinationState
                            )
                        ) {
                            {
                                openSnackbar(
                                    "error",
                                    <FormattedMessage
                                        id="origin_destination_intra__error_message"
                                        defaultMessage="Shipments from {originState} to {destinationState} are not supported on LTL Select"
                                        values={{
                                            originState:
                                                originCountry === "MX" ? (
                                                    <FormattedMessage
                                                        id="generalTerms__country__MX"
                                                        defaultMessage="Mexico"
                                                    />
                                                ) : (
                                                    STATE_MAP[originState]
                                                ),
                                            destinationState:
                                                destinationCountry === "MX" ? (
                                                    <FormattedMessage
                                                        id="generalTerms__country__MX"
                                                        defaultMessage="Mexico"
                                                    />
                                                ) : (
                                                    STATE_MAP[destinationState]
                                                ),
                                        }}
                                    />,
                                    3000
                                )
                            }
                        } else if (shouldShowITNInformationModal()) {
                            openEEIConfirmationModal(openModal, () => {
                                closeModal()
                                getRates()
                            })
                        } else if (isAKShipment) {
                            const specialCase =
                                checkLoadedAKSpecialCases(
                                    origin?.shippingAddress?.address
                                ) ??
                                checkLoadedAKSpecialCases(
                                    destination?.shippingAddress?.address
                                )
                            switch (specialCase?.specialHandling) {
                                case "Charter Service Only *":
                                    openModal(
                                        <ConfirmActionModal
                                            message={
                                                <FormattedMessage
                                                    id="origin_specialHandling_charterServiceOnly"
                                                    defaultMessage="Call 800-351-5187 Ext. 6 to book shipment or email offshore-ops@fedex.com."
                                                />
                                            }
                                            title={
                                                <FormattedMessage
                                                    id="origin_specialHandling_charterServiceOnly_title"
                                                    defaultMessage="Charter Service Only"
                                                />
                                            }
                                            handleConfirm={() => closeModal()}
                                        />
                                    )
                                    break
                                case "Not Serviced":
                                    openSnackbar(
                                        "error",
                                        <FormattedMessage
                                            id="origin_specialHandling_notServiced"
                                            defaultMessage="FedEx Freight does not service shipments to this zipcode."
                                        />,
                                        3000
                                    )
                                    break
                                default:
                                    getRates()
                            }
                        } else if (
                            isMultiCountryShipment(
                                originCountry,
                                destinationCountry
                            ) &&
                            !invalid
                        ) {
                            canadaMexicoCheck(
                                originCountry,
                                destinationCountry,
                                selectedLocation
                            )
                        } else if (!invalid) {
                            getRates()
                        }
                    }}
                    type="submit"
                    disabled={submitting}
                >
                    <FormattedMessage
                        id="bookShipment__getRates"
                        defaultMessage="Get Rates"
                    />
                </Button>
            </Grid>
        </Grid>
    )
}

export const styles = theme => ({
    location__container: {},
    checkbox__container: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    textField: {
        width: "50%",
    },
    location__row: {
        padding: "8px 4px",
        borderTop: "1px solid #F2F2F2",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#D4D4D4",
        },
        "&:active": {
            cursor: "pointer",
            backgroundColor: theme.palette.secondary.light,
            color: "white",
        },
    },
    location__row__last: {
        padding: "8px 4px",
        borderTop: "1px solid #F2F2F2",
        borderBottom: "1px solid #F2F2F2",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#D4D4D4",
        },
        "&:active": {
            cursor: "pointer",
            backgroundColor: theme.palette.secondary.light,
            color: "white",
        },
    },
    location__row__noHover: {
        padding: "8px 4px",
        borderTop: "1px solid #F2F2F2",
    },
    location__row__last__noHover: {
        padding: "8px 4px",
        borderTop: "1px solid #F2F2F2",
        borderBottom: "1px solid #F2F2F2",
    },
    location__card__header: {
        paddingBottom: "8px",
    },
    location__section: {
        padding: "1%",
    },
    location__main: {
        height: "70vh",
        overflowY: "auto",
        // borderTop: "1px solid gray",
        // // borderBottom: "1px solid gray",
        // borderRadius: "4px",
        // boxShadow: "inset -1px 1px 3px rgba(0, 0, 0, 0.2)",
    },
    location__card: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "20px 20px",
        minHeight: "401px",
    },
    location__card__wrapper: {
        // padding: "20px 20px",
        minHeight: "401px",
    },
    location__card__top: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "20px 20px",
    },
    location__card__title: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "10px 20px",
    },
    users__container: {
        maxHeight: "308px",
        overflowY: "scroll",
    },
    user__row: {
        padding: "5px 5px",
        margin: "2px 0",
        backgroundColor: "#D4D4D4",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.secondary.light,
        },
        "&:active": {
            cursor: "pointer",
            backgroundColor: "white",
        },
        minHeight: "40px",
    },
    badge: {
        badge: {
            top: 1,
            right: -15,
        },
    },
    submitLoader: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    action__button: {
        marginLeft: "15px",
    },
    textField_right: {
        width: "100%",
    },
    user__status__label: {
        paddingRight: "10px",
    },
    form__wrapper: {
        width: "100%",
    },
    controls__container: {
        height: "100%",
    },
    brokerCard: {
        height: "100%",
    },
    accessorialContainer: {
        padding: "3px",
    },
    numberTextField: {
        margin: "0 5px",
    },
    scrollShadow_bottom: {
        borderRadius: "4px",
        boxShadow: "0px 0 10px rgba(0, 0, 0, 0.8)",
    },
})

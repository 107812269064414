export const CHANGE_PAGE_SIZE = "CHANGE_PAGE_SIZE"
export const CHANGE_PAGE = "CHANGE_PAGE"

export function changePageSize(pageSize) {
    return { type: CHANGE_PAGE_SIZE, pageSize }
}

export function changePage(page) {
    return { type: CHANGE_PAGE, page }
}

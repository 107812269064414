import { Box, TableCell, TableRow, Typography } from "@material-ui/core"
import React from "react"
import { useOrdersContext } from "../../context/providers/OrdersProvider"
import { FormattedMessage } from "react-intl"
import moment from "moment"
import { getCarrierServiceDisplayName } from "../../misc"

const OrdersRatesHeaders = ({ carriers, tableColumns }) => {
    const { ordersRatesHeaders } = useOrdersContext()

    return (
        <TableRow>
            {tableColumns.map(col => (
                <TableCell key={col?.label} />
            ))}
            {ordersRatesHeaders.map(header => (
                <TableCell key={header?.key}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography style={{ fontWeight: "bold" }}>
                            {moment(header.deliveryDate).format("dddd, MMMM D")}
                        </Typography>
                        {header?.mode === "LTL_DIRECT" ? (
                            <Typography style={{ fontWeight: "bold" }}>
                                <FormattedMessage
                                    defaultMessage="FedEx Freight Direct ®"
                                    id="orders.freightDirect"
                                />
                            </Typography>
                        ) : null}
                        {header.mode === "LTL_DIRECT" ? (
                            <Typography style={{ fontWeight: "bold" }}>
                                {header?.serviceLevel}
                            </Typography>
                        ) : (
                            <Typography style={{ fontWeight: "bold" }}>
                                {header?.serviceLevel}
                            </Typography>
                        )}
                        <Typography style={{ fontWeight: "bold" }}>
                            {getCarrierServiceDisplayName(
                                header?.carrierCode,
                                header?.serviceLevel,
                                header?.mode,
                                carriers
                            )}
                        </Typography>
                    </Box>
                </TableCell>
            ))}
        </TableRow>
    )
}

export default OrdersRatesHeaders

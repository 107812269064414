import React, { useContext, useEffect, useState } from "react"
// tools
import { get } from "lodash"
import moment from "moment"
// i18n & styles
import { useStyles } from "./styles"
import { FormattedMessage, injectIntl } from "react-intl"
import { messages } from "./i18n"
// state management
import { useFlags } from "launchdarkly-react-client-sdk"
import { connect } from "react-redux"
import { useDispatch } from "react-redux"
import { changePath } from "../../actions"
import { OrdersContext } from "../../context/providers/OrdersProvider"
import { useSnackbarContext } from "../../context/providers/snackbarProvider"
import { useAttachmentsContext } from "../../context/providers/attachmentsProvider"
import { populateTrackForm } from "../../actions/track"
import { useMultiAttachmentsContext } from "../../context/providers/multiAttachmentsProvider"
// mui components
import {
    TableBody,
    TableCell,
    Grid,
    Collapse,
    IconButton,
    Typography,
    Tooltip,
    Checkbox,
    TableContainer,
    Table,
    TablePagination,
    TableRow,
} from "@material-ui/core"
// icons
import ArchiveIcon from "@material-ui/icons/Archive"
import DeleteIcon from "@material-ui/icons/Delete"
import PrintIcon from "@material-ui/icons/Print"
import UnarchiveIcon from "@material-ui/icons/Unarchive"
import ShareIcon from "@material-ui/icons/Share"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
// components
import AdditionalDetails from "./AdditionalDetails"
import GlobalSpinnerWithMessage from "../common/GlobalSpinnerWithMessage"
import BookOrdersFooter from "./bookOrders/BookOrdersFooter"
import EmptyTable from "./EmptyTable"
import OrdersTableSpinner from "./OrdersTableSpinner"
import OrdersTableHead from "./OrdersTableHead"
import PopOverRatesError from "./PopOverRatesError"
import SchedulePickupDialog from "./SchedulePickup/SchedulePickupDialog"
import BookViewButton from "./BookViewButton"
import OrderActionCellContent from "./OrderActionCellContent"
import OrderRate from "./OrderRate"
import { useBookedShipmentContext } from "../../context/providers/BookedShipmentProvider"

const OrdersTable = ({
    carriers,
    userLocations,
    logGAEvent,
    startDate,
    endDate,
    gaCategory,
    importModalOpen,
    userPermissions,
}) => {
    const {
        ordersList,
        pageNumber,
        rowsPerPage,
        orderCount,
        archiveOrder,
        orderStatus,
        setOrdersToDelete,
        bulkOrders,
        setBulkOrders,
        selectAllClicked,
        setSelectAllClicked,
        archiveBulkOrders,
        isBookingOrder,
        setIsBookingOrder,
        isFetchingRates,
        setIsFetchingRates,
        onPageChange,
        onRowsPerPageChange,
        loading,
        ordersRatesHeaders,
        getRateKey,
        ordersFilterMenuOpen,
        fetchOrdersSummaryData,
        handleOrderStatusChange,
    } = useContext(OrdersContext)
    const {
        generateMultiOrdersToBookList,
        setOrdersToBook,
    } = useBookedShipmentContext()
    const classes = useStyles()
    const { closeSnackbar } = useSnackbarContext()
    const { openDialog, printMultiLabels } = useAttachmentsContext()
    const { openMultiAttDialog } = useMultiAttachmentsContext()

    const { ordersPhaseTwo } = useFlags()
    const dispatch = useDispatch()

    const [isExpanded, setIsExpanded] = useState(null)
    const [isArchiving, setIsArchiving] = useState(false)
    const [printLoading, setPrintLoading] = useState(false)
    const [selectedRates, setSelectedRates] = useState(new Map())
    const [successBookedShipments, setSuccessBookedShipments] = useState([])
    const [sendingBulkRequest, setSendingBulkRequest] = useState(false)
    const [openPickupDialog, setOpenPickupDialog] = useState(false)
    const [deselectableRates, setDeselectedableRates] = useState([])

    const closePickupDialog = selection => {
        fetchOrdersSummaryData({})
        handleOrderStatusChange("closed")
        setOpenPickupDialog(false)
    }
    const ordersContainsError = ordersList?.some(
        order => order?.result?.ratesError?.length > 0
    )

    useEffect(() => {
        updateDeselectableColumns(selectedRates)
    }, [ordersList])

    useEffect(() => {
        // reset selected rates when opening filter menu
        setSelectedRates(new Map())
    }, [ordersFilterMenuOpen, importModalOpen])

    useEffect(() => {
        if (
            !loading &&
            (isFetchingRates || isBookingOrder || sendingBulkRequest)
        ) {
            setIsFetchingRates(false)
            setIsBookingOrder(false)
            setSendingBulkRequest(false)
        }
    }, [loading])

    const findLocation = locationId => {
        const activeLocation = userLocations.find(
            location => location._id === locationId
        )

        return activeLocation
    }

    const isReadyToBook = () => {
        return orderStatus === "readyToBook"
    }

    const handleOrderClick = order => {
        if (order?.isFulfilled) {
            logGAEvent(gaCategory, "View Shipment Clicked")
            dispatch(
                changePath(`/track/${order?.associatedShipmentTrackingNumber}`)
            )
        } else {
            logGAEvent(gaCategory, "Book Order Click")
            setIsBookingOrder(true)
            dispatch(changePath(`/book?order=${order?.id}`))
        }
    }

    const handleArchiveDelete = async order => {
        closeSnackbar()
        if (!order?.isFulfilled) {
            logGAEvent(gaCategory, "Delete Order Icon Click")
            setOrdersToDelete([order])
        } else {
            logGAEvent(gaCategory, "Archive Order Icon Click")
            setIsArchiving(true)
            await archiveOrder(order, startDate, endDate)
            setIsArchiving(false)
            setSelectAllClicked(false)
        }
    }

    const handleOpenLabel = async order => {
        logGAEvent(gaCategory, "View Order Label Click")
        let lookUpShipment = dispatch(
            populateTrackForm(order?.associatedShipmentTrackingNumber)
        )
        lookUpShipment.then(shipment => {
            const { attachments = [] } = shipment?.shipment ?? {}

            let labelCondition = file => file?.attachmentFileType === "labels"

            let labelAttachments = attachments.filter(labelCondition)
            openDialog(
                order?.associatedShipmentTrackingNumber,
                "manage",
                labelAttachments[0],
                labelAttachments[0].label
            )
        })
    }

    const handleSelectRate = (orderId, rateId) => {
        const newSelectedRates = new Map(selectedRates)
        if (
            selectedRates.has(orderId) &&
            selectedRates.get(orderId) === rateId
        ) {
            newSelectedRates.delete(orderId)
        } else {
            newSelectedRates.set(orderId, rateId)
        }
        updateDeselectableColumns(newSelectedRates)
        setSelectedRates(new Map(newSelectedRates))
    }

    const selectAllRates = rateKey => {
        const newSelectedRates = new Map()
        const newOrdersToBook = []
        for (let order of ordersList) {
            if (userPermissions.get(order?.cpg)?.createShipments?.value) {
                const ratesList = order?.rates
                for (let rate of ratesList) {
                    if (getRateKey(rate) === rateKey) {
                        newSelectedRates.set(order?._id, rate?._id)
                        newOrdersToBook.push({ order, rate })
                    }
                }
            }
        }
        for (let entry of selectedRates) {
            if (!newSelectedRates.has(entry[0])) {
                // find uneffected orders/rows and keep them as is.
                newSelectedRates.set(entry[0], entry[1])
            }
        }
        updateDeselectableColumns(newSelectedRates)
        setSelectedRates(newSelectedRates)
        generateMultiOrdersToBookList(newOrdersToBook)
    }

    const deselectAllRates = rateKey => {
        const newSelectedRates = new Map(selectedRates)
        for (let entry of selectedRates) {
            const selectedOrder = ordersList.find(
                order => order._id === entry[0]
            )
            const selectedRate = selectedOrder?.rates.find(
                rate => rate._id === entry[1]
            )
            if (getRateKey(selectedRate) === rateKey) {
                newSelectedRates.delete(entry[0])
            }
            setOrdersToBook(prevState => {
                if (
                    prevState?.get(selectedOrder._id) &&
                    getRateKey(
                        prevState.get(selectedOrder._id).selectedRate
                    ) === rateKey
                ) {
                    let updateMap = new Map(prevState)
                    updateMap.delete(selectedOrder._id)
                    return updateMap
                } else {
                    return new Map(prevState)
                }
            })
        }

        updateDeselectableColumns(newSelectedRates)
        setSelectedRates(newSelectedRates)
    }

    const updateDeselectableColumns = newSelectedRates => {
        const deselectable = []

        for (let entry of newSelectedRates) {
            const selectedOrder = ordersList.find(
                order => order._id === entry[0]
            )
            const selectedRate = selectedOrder?.rates?.find(
                rate => rate._id === entry[1]
            )
            deselectable.push(getRateKey(selectedRate))
        }
        setDeselectedableRates(deselectable)
    }

    const shouldUseLocationAddress = (order, leg) => {
        const location = findLocation(get(order, "locationId"))
        const isShipperAcct = Boolean(
            !location?.fedexBillToAccount && location?.fedexFreightAccount
        )

        if (leg === "origin") {
            return isShipperAcct && order.mode === "OUTBOUND"
        } else if (leg === "destination") {
            return isShipperAcct && order.mode === "INBOUND"
        }
    }

    const displayBulkActionButton = (bulkOrders, orderStatus) => {
        if (bulkOrders.length) {
            if (
                orderStatus === "actionRequired" ||
                orderStatus == "readyToBook"
            ) {
                return (
                    <Grid className={classes.bulkButtonGroup}>
                        <Tooltip
                            title={
                                <FormattedMessage {...messages.deleteOrder} />
                            }
                            placement="top"
                        >
                            <IconButton
                                className={classes.iconButton}
                                onClick={() => {
                                    try {
                                        setOrdersToDelete(bulkOrders)
                                    } catch (e) {
                                        console.error(e)
                                    }
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )
            }
            if (orderStatus === "closed") {
                return (
                    <Grid className={classes.bulkButtonGroup}>
                        <Tooltip
                            title={
                                <FormattedMessage {...messages.archiveOrder} />
                            }
                            placement="top"
                        >
                            <IconButton
                                disabled={isArchiving}
                                className={classes.iconButton}
                                onClick={async () => {
                                    setIsArchiving(true)
                                    try {
                                        await archiveBulkOrders({
                                            ordersToArchive: bulkOrders,
                                            archiveStatus: true,
                                            startDate: startDate,
                                            endDate: endDate,
                                        })
                                    } catch (e) {
                                        console.error(e)
                                    } finally {
                                        setIsArchiving(false)
                                    }
                                }}
                            >
                                <ArchiveIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={
                                <FormattedMessage {...messages.printLabels} />
                            }
                            placement="top"
                        >
                            <IconButton
                                disabled={printLoading}
                                className={classes.iconButton}
                                onClick={async () => {
                                    setPrintLoading(true)
                                    await printMultiLabels(
                                        bulkOrders.map(
                                            order =>
                                                order?.associatedShipmentTrackingNumber
                                        )
                                    )
                                    setPrintLoading(false)
                                }}
                            >
                                <PrintIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={
                                <FormattedMessage {...messages.shareOrder} />
                            }
                            placement="top"
                        >
                            <IconButton
                                className={classes.iconButton}
                                onClick={() => {
                                    openMultiAttDialog(
                                        bulkOrders,
                                        "share",
                                        undefined,
                                        undefined,
                                        true,
                                        undefined
                                    )
                                }}
                            >
                                <ShareIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )
            }
            if (orderStatus === "archived") {
                return (
                    <Grid className={classes.bulkButtonGroup}>
                        <Tooltip
                            title={
                                <FormattedMessage
                                    {...messages.unarchiveOrder}
                                />
                            }
                            placement="top"
                        >
                            <IconButton
                                className={classes.iconButton}
                                disabled={isArchiving}
                                onClick={async () => {
                                    setIsArchiving(true)
                                    try {
                                        await archiveBulkOrders({
                                            ordersToArchive: bulkOrders,
                                            archiveStatus: false,
                                            startDate: startDate,
                                            endDate: endDate,
                                        })
                                    } catch (e) {
                                        console.error(e)
                                    } finally {
                                        setIsArchiving(false)
                                    }
                                }}
                            >
                                <UnarchiveIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={
                                <FormattedMessage {...messages.printLabels} />
                            }
                            placement="top"
                        >
                            <IconButton
                                disabled={printLoading}
                                className={classes.iconButton}
                                onClick={async () => {
                                    setPrintLoading(true)
                                    printMultiLabels(
                                        bulkOrders.map(
                                            order =>
                                                order.associatedShipmentTrackingNumber
                                        )
                                    )
                                    setPrintLoading(false)
                                }}
                            >
                                <PrintIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )
            }
        }
    }

    let tableColumns = ordersPhaseTwo
        ? [
              { label: "", id: "checkbox", type: "checkbox", align: "center" },
              {
                  label: "",
                  id: "collapse",
                  type: "expand",
                  bulkActionButton: displayBulkActionButton,
                  align: "left",
              },
              {
                  label: messages.orderNumber,
                  value: "orderNumber",
                  align: "left",
                  padding: "text",
              },
              {
                  label: messages.locationName,
                  value: "locationId",
                  type: "locationName",
                  align: "left",
              },
              {
                  label: messages.uploadDate,
                  value: "createdAt",
                  type: "date",
                  align: "left",
              },
              {
                  label: messages.originCompanyName,
                  value: "origin.name",
                  type: "companyNameOrigin",
                  align: "left",
              },
              {
                  label: messages.destinationCompanyName,
                  value: "destination.name",
                  type: "companyNameDestination",
                  align: "left",
              },
          ]
        : [
              { label: "", id: "checkbox", type: "checkbox", align: "center" },
              {
                  label: "",
                  id: "collapse",
                  type: "expand",
                  bulkActionButton: displayBulkActionButton,
                  align: "left",
              },
              {
                  label: messages.orderNumber,
                  value: "orderNumber",
                  align: "left",
                  padding: "text",
              },
              {
                  label: messages.locationName,
                  value: "locationId",
                  type: "locationName",
                  align: "left",
              },
              {
                  label: messages.uploadDate,
                  value: "createdAt",
                  type: "date",
                  align: "left",
              },
              {
                  label: messages.originCompanyName,
                  value: "origin.name",
                  type: "companyNameOrigin",
                  align: "left",
              },
              {
                  label: messages.destinationCompanyName,
                  value: "destination.name",
                  type: "companyNameDestination",
                  align: "left",
              },
              {
                  label: messages.blankHeader,
                  Component: OrderActionCellContent,
                  actionViewBook: handleOrderClick,
                  actionArchiveDelete: handleArchiveDelete,
                  actionHandleOpenLabel: handleOpenLabel,
                  type: "custom",
                  align: "left",
              },
          ]

    if (ordersPhaseTwo && orderStatus === "actionRequired") {
        tableColumns.push({
            label: "",
            Component: BookViewButton,
            actionViewBook: handleOrderClick,
            type: "book",
            align: "center",
        })
    }
    if (ordersPhaseTwo && orderStatus === "closed") {
        tableColumns.push({
            label: messages.blankHeader,
            Component: OrderActionCellContent,
            actionViewBook: handleOrderClick,
            actionArchiveDelete: handleArchiveDelete,
            actionHandleOpenLabel: handleOpenLabel,
            type: "custom",
            align: "left",
        })
    }

    if (ordersPhaseTwo && orderStatus === "readyToBook") {
        tableColumns = tableColumns.filter(c => c?.value != "locationId")
        if (ordersContainsError) {
            tableColumns.push({
                label: messages.rates,
                Component: PopOverRatesError,
                type: "custom",
                align: "center",
            })
        }
    }

    const OrderCellContent = ({ order, column }) => {
        switch (column.type) {
            case "date":
                const date = get(order, column.value)
                return date ? (
                    <Typography variant="body2">
                        {moment(date).format("MM/DD/YYYY")}
                    </Typography>
                ) : null
            case "custom":
                return (
                    <column.Component
                        order={order}
                        column={column}
                        isArchiving={isArchiving}
                        setIsArchiving={setIsArchiving}
                    />
                )
            case "locationName":
                const activeLocation = findLocation(get(order, column.value))
                return (
                    <Typography variant="body2">
                        {activeLocation?.billingContact?.companyName}
                    </Typography>
                )
            case "companyNameOrigin":
                if (shouldUseLocationAddress(order, "origin")) {
                    const location = findLocation(get(order, "locationId"))
                    return (
                        <Typography variant="body2">
                            {location?.billingContact?.companyName}
                        </Typography>
                    )
                } else {
                    return (
                        <Typography variant="body2">
                            {get(order, column.value)}
                        </Typography>
                    )
                }
            // eslint-disable-next-line
            case "companyNameDestination":
                if (shouldUseLocationAddress(order, "destination")) {
                    const location = findLocation(get(order, "locationId"))
                    return (
                        <Typography variant="body2">
                            {location?.billingContact?.companyName}
                        </Typography>
                    )
                } else {
                    return (
                        <Typography variant="body2">
                            {get(order, column.value)}
                        </Typography>
                    )
                }
            // eslint-disable-next-line
            case "expand":
                return (
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            if (isExpanded === order?.id) {
                                setIsExpanded(null)
                            } else {
                                setIsExpanded(order?.id)
                            }
                        }}
                    >
                        {isExpanded === order?.id ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                )
            case "checkbox":
                if (orderStatus === "all") {
                    return null
                }
                return (
                    <Checkbox
                        checked={bulkOrders.includes(order)}
                        onClick={() => {
                            if (bulkOrders.includes(order)) {
                                setBulkOrders(
                                    bulkOrders.filter(
                                        bulkOrder => bulkOrder !== order
                                    )
                                )
                            } else {
                                setBulkOrders([...bulkOrders, order])
                            }
                        }}
                    />
                )
            case "book":
                return <BookViewButton column={column} order={order} />

            default:
                return (
                    <Typography variant="body2">
                        {get(order, column.value)}
                    </Typography>
                )
        }
    }

    const selectAllOrders = orders => {
        if (bulkOrders === orders || bulkOrders.length) {
            setSelectAllClicked(false)
            return setBulkOrders([])
        }
        setBulkOrders(orders)
        setSelectAllClicked(true)
    }

    return (
        <>
            <TableContainer className={classes.table}>
                {loading ? (
                    <OrdersTableSpinner
                        ordersPhaseTwo={ordersPhaseTwo}
                        tableColumns={tableColumns}
                    />
                ) : (
                    <Table
                        stickyHeader
                        style={{ minWidth: "max-content" }}
                        size="medium"
                        aria-label="Orders Table"
                    >
                        <OrdersTableHead
                            carriers={carriers}
                            deselectAllRates={deselectAllRates}
                            deselectableRates={deselectableRates}
                            selectAllRates={selectAllRates}
                            bulkOrders={bulkOrders}
                            displayBulkActionButton={displayBulkActionButton}
                            isReadyToBook={isReadyToBook}
                            loading={loading}
                            ordersList={ordersList}
                            ordersPhaseTwo={ordersPhaseTwo}
                            orderStatus={orderStatus}
                            selectAllClicked={selectAllClicked}
                            selectAllOrders={selectAllOrders}
                            tableColumns={tableColumns}
                        />

                        {ordersPhaseTwo &&
                            ordersList.length === 0 &&
                            !loading &&
                            !isArchiving && (
                                <EmptyTable tableColumns={tableColumns} />
                            )}

                        <TableBody>
                            {ordersList?.map((order, inx) => {
                                const location = findLocation(
                                    get(order, "locationId")
                                )
                                const ordersRatesKeys = []
                                const rates = order?.rates
                                if (rates) {
                                    for (let rate of rates) {
                                        ordersRatesKeys.push(getRateKey(rate))
                                    }
                                }
                                return (
                                    <React.Fragment key={`fragment-${inx}`}>
                                        <TableRow
                                            ordersPhaseTwo={ordersPhaseTwo}
                                            key={`styled-row-${inx}`}
                                            classes={{
                                                root:
                                                    classes.tableRowAlternating,
                                            }}
                                        >
                                            {tableColumns.map(
                                                (column, index) => (
                                                    <TableCell
                                                        key={`column-${index}`}
                                                        left={index * 4}
                                                        style={{
                                                            marginRight: 15,
                                                        }}
                                                        align={column.align}
                                                        className={
                                                            classes.tableCell
                                                        }
                                                        padding={
                                                            column.type ===
                                                                "expand" ||
                                                            column.type ===
                                                                "checkbox"
                                                                ? "none"
                                                                : "normal"
                                                        }
                                                    >
                                                        <OrderCellContent
                                                            order={order}
                                                            column={column}
                                                            isArchiving={
                                                                isArchiving
                                                            }
                                                        />
                                                    </TableCell>
                                                )
                                            )}
                                            {ordersPhaseTwo && isReadyToBook()
                                                ? ordersRatesHeaders.map(
                                                      header => {
                                                          return (
                                                              <TableCell
                                                                  key={
                                                                      header.key
                                                                  }
                                                                  align="center"
                                                              >
                                                                  {ordersRatesKeys.includes(
                                                                      header?.key
                                                                  ) ? (
                                                                      <OrderRate
                                                                          canShip={
                                                                              userPermissions.get(
                                                                                  order?.cpg
                                                                              )
                                                                                  ?.createShipments
                                                                                  ?.value
                                                                          }
                                                                          order={
                                                                              order
                                                                          }
                                                                          orderId={
                                                                              order?._id
                                                                          }
                                                                          selectedRate={
                                                                              selectedRates.has(
                                                                                  order?._id
                                                                              )
                                                                                  ? selectedRates.get(
                                                                                        order?._id
                                                                                    )
                                                                                  : null
                                                                          }
                                                                          handleSelectRate={
                                                                              handleSelectRate
                                                                          }
                                                                          rate={order?.rates?.find(
                                                                              rate =>
                                                                                  getRateKey(
                                                                                      rate
                                                                                  ) ===
                                                                                  header?.key
                                                                          )}
                                                                      />
                                                                  ) : (
                                                                      "---"
                                                                  )}
                                                              </TableCell>
                                                          )
                                                      }
                                                  )
                                                : null}
                                        </TableRow>
                                        <TableRow
                                            ordersPhaseTwo={ordersPhaseTwo}
                                            className={classes.expandContainer}
                                        >
                                            <TableCell
                                                style={{
                                                    paddingBottom: 0,
                                                    paddingTop: 0,
                                                }}
                                                colSpan={tableColumns.length}
                                            >
                                                <Collapse
                                                    in={
                                                        isExpanded === order?.id
                                                    }
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <AdditionalDetails
                                                        shouldUseLocationAddress={
                                                            shouldUseLocationAddress
                                                        }
                                                        showAccessorials={true}
                                                        normalizedShipment={
                                                            order
                                                        }
                                                        location={location}
                                                        tableName={"orders"}
                                                        trackingNumber={
                                                            order.orderNumber
                                                        }
                                                    />
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )
                            })}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
            <Grid item container className={classes.paginationContainer}>
                <TablePagination
                    onPageChange={onPageChange}
                    page={pageNumber - 1}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 25]}
                    count={orderCount}
                    onRowsPerPageChange={onRowsPerPageChange}
                    labelRowsPerPage={
                        <FormattedMessage
                            id="orders.rows"
                            defaultMessage="Rows Per Page"
                        />
                    }
                />
            </Grid>
            {(isBookingOrder || isFetchingRates || sendingBulkRequest) && (
                <GlobalSpinnerWithMessage
                    sendingBulkRequest={sendingBulkRequest}
                />
            )}
            {selectedRates.size > 0 && (
                <BookOrdersFooter
                    selectedRates={selectedRates}
                    setSelectedRates={setSelectedRates}
                    setOpenPickUpModel={setOpenPickupDialog}
                    setSuccessBookedShipments={setSuccessBookedShipments}
                />
            )}
            <SchedulePickupDialog
                handleClose={closePickupDialog}
                open={openPickupDialog}
                successBookedShipments={successBookedShipments}
                setSuccessBookedShipments={setSuccessBookedShipments}
                setSendingBulkRequest={setSendingBulkRequest}
                sendingBulkRequest={sendingBulkRequest}
                userLocations={userLocations}
            />
        </>
    )
}

const mapStateToProps = (state, props) => {
    const userLocations = state?.user?.profile?.locations
    const shipment = state?.shipment?.list[props?.shipmentId] ?? {}
    const shipmentId = state?.shipment?.list?.alert?.shipmentId
    const carriers = state?.carriers?.carriers
    const userPermissions = new Map()
    const locations = state?.user?.profile?.locations

    for (let location of locations) {
        const userLocation = location?.users?.find(
            user => user?.user?.id === state?.user?.profile?.id
        )
        userPermissions.set(location?.cpgCode, userLocation?.permissions)
    }

    return {
        userLocations,
        shipment,
        shipmentId,
        carriers,
        state,
        userPermissions,
    }
}

export default injectIntl(connect(mapStateToProps, null)(OrdersTable))

import React, { Component } from "react"
import { Grid, Typography, Button } from "@material-ui/core"
import { connect } from "react-redux"
import { get } from "lodash"
import { withStyles } from "@material-ui/core/styles"
import { Field, SubmissionError, reduxForm } from "redux-form"
import AddRecipients from "./addRecipients"
import { addUsersToLocation, trackGAEvent } from "../../../actions/user"
import Permissions from "./permissions"
import RoleCopy from "./roleCopy"
import FormSelect from "../../form/form-select"
import CircularProgress from "@material-ui/core/CircularProgress"
import { combineValidators } from "revalidate"
import { emailValidator, isRequired } from "../../../actions/validation"
import { FormattedMessage } from "react-intl"
import { defineMessages } from "react-intl"
import {
    preferenceLanguageOptions,
    defaultLanguage,
} from "../../constants/preferenceLanguageOptions"

const styles = theme => ({
    paper: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        top: "50%",
        left: "50%",
        width: "900px",
        maxHeight: "700px",
        overflowY: "scroll",
    },
    location__information: {
        padding: "10px 0",
    },
    addUser__permissions: {
        padding: "15px 5px 5px 5px",
        minHeight: "325px",
    },
    submitLoader: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    addUser__error: {
        color: "red",
    },
})

const roleOptionsMessages = defineMessages({
    chooseARole: {
        id: "locations.users__chooseARole",
        defaultMessage: "Choose a role",
    },
    owner: { id: "locations.users__owner", defaultMessage: "Owner" },
    companyUser: {
        id: "locations.users__companyUser",
        defaultMessage: "Company User",
    },
    thirdPartyVendor: {
        id: "locations.users__thirdPartyVendor",
        defaultMessage: "Third Party Vendor",
    },
})

const roleOptions = [
    {
        value: undefined,
        label: roleOptionsMessages.chooseARole,
        disabled: true,
    },
    {
        value: "owner",
        label: roleOptionsMessages.owner,
    },
    {
        value: "member",
        label: roleOptionsMessages.companyUser,
    },
    {
        value: "thirdParty",
        label: roleOptionsMessages.thirdPartyVendor,
    },
]

class AddUser extends Component {
    render() {
        const {
            classes,
            handleClose,
            confirmAddUser,
            index,
            information,
            currentRole,
            handleSubmit,
            submitting,
            form,
            error,
            gaCategory,
            trackGA,
        } = this.props

        return (
            <Grid className={classes.paper}>
                <form>
                    <Grid item container>
                        <Typography variant="h5">
                            <FormattedMessage
                                id="locations.addUserModal__title"
                                defaultMessage="Add user(s) to location"
                            />
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        justify="space-between"
                        className={classes.location__information}
                    >
                        <Typography variant="subtitle1">{`${get(
                            information,
                            "shippingAddress.name"
                        )}`}</Typography>
                        <Typography variant="subtitle1">
                            {`${information?.shippingAddress?.address?.street1}, ${information?.shippingAddress?.address?.city} ${information?.shippingAddress?.address?.state} ${information?.shippingAddress?.address?.postalCode}`}
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <AddRecipients
                            formName={form}
                            gaCategory={gaCategory}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        className={classes.addUser__permissions}
                        justify="space-between"
                    >
                        <Grid
                            item
                            container
                            xs={4}
                            id="addUser__selectRole__container"
                        >
                            <Field
                                component={FormSelect}
                                name="role"
                                id={"addUser__selectRole"}
                                label={[
                                    "* ",
                                    <FormattedMessage
                                        id="locations.addUserModal__role"
                                        defaultMessage="Role"
                                    />,
                                ]}
                                options={roleOptions}
                                category={gaCategory}
                            />
                            <RoleCopy currentRole={currentRole} />
                        </Grid>
                        <Grid item container xs={7}>
                            <Permissions
                                classes={classes}
                                index={index}
                                gridSize={12}
                                defaultMode
                                location={information}
                                gaCategory={gaCategory}
                            />
                            <Grid item container xs={6}>
                                <Field
                                    name="language"
                                    label={
                                        <FormattedMessage
                                            id="userPreferences.generalInformation__languagePreference"
                                            defaultMessage="Language Preference"
                                        />
                                    }
                                    component={FormSelect}
                                    options={preferenceLanguageOptions}
                                    category={gaCategory}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        justify="space-between"
                        className={classes.button__container}
                    >
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                handleClose()
                            }}
                            className={classes.leftButton}
                        >
                            <FormattedMessage
                                id="generalTerms__cancel"
                                defaultMessage="Cancel"
                            />
                        </Button>
                        {error && (
                            <Typography
                                variant="body2"
                                className={classes.addUser__error}
                            >
                                {error}
                            </Typography>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit(confirmAddUser)}
                            id="addUser__confirm"
                        >
                            <FormattedMessage
                                id="locations.addUserModal__confirm"
                                defaultMessage="Add User(s)"
                            />
                        </Button>
                        {submitting && (
                            <CircularProgress
                                className={classes.submitLoader}
                                size={48}
                                color="secondary"
                            />
                        )}
                    </Grid>
                </form>
            </Grid>
        )
    }
}

const userValidation = combineValidators({
    role: isRequired({
        field: {
            id: "locations.addUserModal__role",
            defaultMessage: "Role",
        },
    }),
    newEmailRecipient: emailValidator({
        field: {
            id: "generalTerms__emailAddress",
            defaultMessage: "Email Address",
        },
    }),
})

const mapStateToProps = (state, props) => {
    const formName = "addUser"

    return {
        permissions: get(state, ["form", formName, "values", "permissions"]),
        role: get(state, ["form", formName, "values", "role"]),
        initialValues: {
            selectedRecipients: [],
            language:
                state?.user?.profile?.preferences?.language || defaultLanguage,
        },
        currentRole: get(state, "form.addUser.values.role", ""),
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    trackGA: (category, action, value) =>
        dispatch(trackGAEvent(category, action, value)),
    confirmAddUser: async values => {
        const { information } = props
        const {
            selectedRecipients,
            permissions,
            role,
            newEmailRecipient,
            language,
        } = values

        if (!selectedRecipients.length) {
            throw new SubmissionError({
                _error: (
                    <FormattedMessage
                        id="locations.addUserModal__noUsersAddedError"
                        defaultMessage="Please add at least one user"
                    />
                ),
            })
        }
        if (get(newEmailRecipient, "length")) {
            throw new SubmissionError({
                newEmailRecipient: (
                    <FormattedMessage
                        id="locations.addUserModal__lastEmailError"
                        defaultMessage="It looks like you forgot to add the last email address"
                    />
                ),
            })
        }
        await dispatch(
            addUsersToLocation({
                selectedRecipients,
                permissions,
                role,
                locationId: information._id,
                language,
            })
        )
        dispatch(
            trackGAEvent(props.gaCategory, "Add User Modal - Add User Click")
        )
        props.handleClose()
    },
})

export const AddUserModal = withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "addUser", // a unique identifier for this form
            enableReinitialize: true,
            validate: userValidation,
        })(AddUser)
    )
)

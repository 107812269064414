import React from "react"
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    TextField,
    Typography,
} from "@material-ui/core"

const EditTranslations = ({
    cancelEdit,
    editKey,
    editEnglish,
    editFrench,
    editSpanish,
    setEditKey,
    setEditEnglish,
    setEditFrench,
    setEditSpanish,
}) => {
    return (
        <Card open={editKey}>
            <CardContent style={{ width: "650px" }}>
                <Box
                    sx={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h5">Key: </Typography>

                    <Typography align="left" variant="body1">
                        {editKey}
                    </Typography>
                </Box>
                <Divider />
                <Box
                    sx={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography style={{ width: "25%" }} variant="h5">
                        English:
                    </Typography>
                    <TextField
                        style={{ width: "75%", fontFamily: "sans-serif" }}
                        value={editEnglish}
                        onChange={evt => {
                            setEditEnglish(evt.target.value)
                        }}
                    />
                </Box>
                <Divider />
                <Box
                    sx={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography style={{ width: "25%" }} variant="h5">
                        Spanish:
                    </Typography>
                    <TextField
                        style={{ width: "75%", fontFamily: "sans-serif" }}
                        value={editSpanish}
                        minRows={3}
                        maxRows={3}
                        onChange={evt => {
                            setEditSpanish(evt.target.value)
                        }}
                    />
                </Box>
                <Divider />
                <Box
                    sx={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography style={{ width: "25%" }} variant="h5">
                        French:
                    </Typography>
                    <TextField
                        style={{ width: "75%", fontFamily: "sans-serif" }}
                        value={editFrench}
                        onChange={evt => {
                            setEditFrench(evt.target.value)
                        }}
                    />
                </Box>
            </CardContent>
            <CardActions>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Button
                        onClick={cancelEdit}
                        variant="outlined"
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained">
                        Save
                    </Button>
                </Box>
            </CardActions>
        </Card>
    )
}

export default EditTranslations

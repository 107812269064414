import React from "react"
import { Field, formValues } from "redux-form"
import { FormattedMessage, useIntl } from "react-intl"
import { attachmentOptionsMessages } from "../../../misc"
import FormCheckbox from "../../form/form-checkbox"
import { usmcaFormMessages } from "../USMCA/messages"

export const shipmentOverwriteCheckDocumentTypes = Object.freeze([
    "commercialInvoice",
])

export const ShipmentOverwriteCheck = formValues("documentCategory")(
    ({ documentCategory, gaCategory }) => {
        const intl = useIntl()
        const displayName = attachmentOptionsMessages?.[documentCategory]
        return (
            <Field
                name="confirmOverwrite"
                label={
                    <FormattedMessage
                        {...usmcaFormMessages.confirmOverwrite}
                        values={{
                            displayName:
                                displayName && intl.formatMessage(displayName),
                        }}
                    />
                }
                component={FormCheckbox}
                category={gaCategory}
            />
        )
    }
)

import { combineReducers } from "redux"
import { omit, keyBy } from "lodash"
import {
    QUOTE_INVALIDATE,
    QUOTE_RESET,
    SET_EDIT_MODE,
    QUOTE_DELETED,
    INIT_QUOTE,
} from "../actions/quote"
import {
    QUOTE_RESULT,
    QUOTE_LOAD,
    QUOTE_LOAD_ERROR,
    QUOTE_ADD_LIST_ITEM,
    QUOTE_LIST_LOAD,
    QUOTE_LIST_ERROR,
    QUOTE_LIST_RESULT,
    QUOTE_EXPIRED,
    QUEUE_QUOTE_SEARCH,
    QUOTE_LIST_RESULT_FILTER_APPLIED,
    QUOTE_SEARCH_RESULT,
    ARCHIVE_QUOTE,
    UNARCHIVE_QUOTE,
    BACKFILL_QUOTE_LIST,
    REMOVE_QUOTE,
} from "../actions/quote-request"
import { USER_DISAUTHENTICATED } from "../actions/user"
import { ADD_SHIPMENT } from "../actions/track"
import { CHANGE_PAGE_SIZE, CHANGE_PAGE } from "../actions/pagination"

export function active(
    state = {
        isInvalidated: false,
        isFetching: false,
        isLoaded: false,
        isError: false,
        isEditMode: true,
        isViewMode: true,
        isExpired: false,
        rateQuotes: [],
        id: "",
        ratesError: [],
        shipped: false,
        shippedRateQuote: {},
    },
    action = {}
) {
    switch (action.type) {
        case QUOTE_RESET:
        case USER_DISAUTHENTICATED:
        case INIT_QUOTE:
            return active()
        case QUOTE_INVALIDATE:
            return { ...state, isInvalidated: true }
        case QUOTE_LOAD:
            return { ...state, isFetching: true, isError: false }
        case QUOTE_LOAD_ERROR:
            return { ...state, isFetching: false, isError: true }
        case SET_EDIT_MODE:
            return { ...state, isEditMode: action.mode }
        case QUOTE_EXPIRED:
            return { ...state, isExpired: action.isExpired }
        case QUOTE_RESULT:
            return {
                ...state,
                rateQuotes: action.result.rateQuotes,
                ratesError: action.result.ratesError || [],
                shipped: action.shipped,
                shippedRateQuote: action.shippedRateQuote || {},
                id: action._id,
                isFetching: false,
                isInvalidated: false,
                isError: false,
                isLoaded: true,
            }
        default:
            return state
    }
}

export function pagination(
    state = {
        pageSize: 10,
        lastId: null,
        page: 1,
    },
    action = {}
) {
    switch (action.type) {
        case CHANGE_PAGE_SIZE:
            return {
                ...state,
                pageSize: action.pageSize,
            }
        case CHANGE_PAGE:
            return {
                ...state,
                page: action.page,
            }
        case QUOTE_LIST_RESULT: {
            const lastIndex = action.quotes.list.length - 1
            const lastElement = action.quotes.list.length
                ? action.quotes.list[lastIndex]
                : {}
            return {
                ...state,
                lastId: lastElement._id,
            }
        }
        case QUOTE_LIST_RESULT_FILTER_APPLIED: {
            const lastIndex = action.quotes.list.length - 1
            const lastElement = action.quotes.list.length
                ? action.quotes.list[lastIndex]
                : {}
            return {
                ...state,
                lastId: lastElement._id,
            }
        }
        default:
            return state
    }
}

export function list(
    state = {
        isFetching: false,
        isLoaded: false,
        totalCount: 0,
        items: {},
        search: {},
        searchInProgress: undefined,
    },
    action = {}
) {
    switch (action.type) {
        case REMOVE_QUOTE:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                items: Object.keys(state.items)
                    .filter(
                        quoteId =>
                            quoteId.toString() !== action.quoteId.toString()
                    )
                    .reduce((newObj, quoteId) => {
                        newObj[quoteId] = state.items[quoteId]
                        return newObj
                    }, {}),
            }
        case BACKFILL_QUOTE_LIST:
            return {
                ...state,
                items: {
                    ...state.items,
                    ...keyBy(
                        action.quotes.list,
                        "identifiers.internalTrackingNumber"
                    ),
                },
            }
        case ARCHIVE_QUOTE:
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.quoteId]: {
                        ...state.items[action.quoteId],
                        search: {
                            ...state.items[action.quoteId].search,
                            isArchived: true,
                        },
                    },
                },
            }
        case UNARCHIVE_QUOTE:
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.quoteId]: {
                        ...state.items[action.quoteId],
                        search: {
                            ...state.items[action.quoteId].search,
                            isArchived: false,
                        },
                    },
                },
            }
        case QUOTE_ADD_LIST_ITEM:
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.quote.identifiers.internalTrackingNumber]:
                        action.quote,
                },
            }
        case QUOTE_LIST_LOAD:
            return { ...state, isFetching: true }
        case QUOTE_LIST_ERROR:
            return { ...state, isFetching: false }
        case QUOTE_LIST_RESULT:
            return {
                ...state,
                items: {
                    ...state.items,
                    ...keyBy(
                        action.quotes.list,
                        "identifiers.internalTrackingNumber"
                    ),
                },
                totalCount: state.totalCount || action.quotes.totalCount,
                isFetching: false,
                isLoaded: true,
            }
        case QUOTE_LIST_RESULT_FILTER_APPLIED:
            return {
                ...state,
                items: {
                    ...keyBy(
                        action.quotes.list,
                        "identifiers.internalTrackingNumber"
                    ),
                },
                totalCount: action.quotes.totalCount,
                isFetching: false,
                isLoaded: true,
            }
        case QUOTE_DELETED:
            return {
                ...state,
                items: omit(state.items, "action.shipmentId"),
            }
        case USER_DISAUTHENTICATED:
            return list()
        case QUOTE_SEARCH_RESULT:
            return {
                ...state,
                searchInProgress: undefined,
                search: {
                    ...state.search,
                    [action.value]: action.data,
                },
            }
        case QUEUE_QUOTE_SEARCH:
            return { ...state, searchInProgress: action.id }
        default:
            return state
    }
}

export const quotes = combineReducers({ active, list, pagination })

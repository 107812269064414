import { goFetch } from "../http"
import { typedError } from "./index"

export const DISCOUNTS_RESULT = "DISCOUNTS_RESULT"
export const DISCOUNTS_LOAD_ERROR = "DISCOUNTS_LOAD_ERROR"

const discountsResult = result => ({
    type: DISCOUNTS_RESULT,
    result,
})

export function importDiscounts(file) {
    return async dispatch => {
        const formData = new FormData()
        formData.append("file", file)
        return goFetch(
            "/admin/freightdirect/discounts/bulk",
            {
                method: "POST",
                credentials: "same-origin",
                headers: {
                    "cache-control": "no-cache",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data: formData,
            },
            true
        )
    }
}

const discountsLoadError = error => typedError(DISCOUNTS_LOAD_ERROR, error)

export function fetchDiscounts() {
    return async dispatch => {
        try {
            const { data: results, status } = await goFetch(
                `/admin/freightdirect/discounts`,
                {
                    method: "GET",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                },
                true
            )
            if (status !== 200)
                return dispatch(
                    discountsLoadError("fetching discounts failed.")
                )
            return dispatch(discountsResult(results))
        } catch (error) {
            return dispatch(discountsLoadError(error))
        }
    }
}

import { ERROR_MESSAGE, DISMISS_ERROR, DISMISS_ALL_ERRORS } from "../actions"

function invalidError(action) {
    return (
        !action.error ||
        (action.error.response && action.error.response.code === 404)
    )
}

export function error(state = { list: [] }, action = {}) {
    switch (action.type) {
        case ERROR_MESSAGE:
            if (invalidError(action)) return state
            return { ...state, list: [...state.list, action.error] }
        case DISMISS_ERROR: {
            const list = state.list.slice()
            list.splice(action.index, 1)
            return { ...state, list }
        }
        case DISMISS_ALL_ERRORS:
            return error()
        default:
            return state
    }
}

import * as yup from "yup"
import { isValidPhoneNumber } from "../../actions/validation"

yup.addMethod(yup.string, "isValidPhoneNumber", function(message) {
    return this.test("isValidPhoneNumber", message, function(value) {
        if (!value) {
            return true
        }
        return (
            isValidPhoneNumber(value, "US") ||
            isValidPhoneNumber(value, "CA") ||
            isValidPhoneNumber(value, "MX")
        )
    })
})

export const schema = yup
    .object()
    .shape({
        name: yup
            .string()
            .required()
            .label("Company Name"),
        address: yup.object().shape({
            city: yup
                .string()
                .required()
                .label("City"),
            country: yup
                .string()
                .required()
                .label("Country"),
            state: yup
                .string()
                .required()
                .length(2)
                .label("State"),
            postalCode: yup
                .string()
                .required()
                .matches(
                    /^(?:\d{5}(-\d{4})?|[ABCEGHJKLMNPRSTVXY]\d[A-Z] \d[A-Z]\d|\d{5})$/,
                    "Invalid Postal Code"
                )
                .label("Zip/Postal Code"),
            street1: yup
                .string()
                .required()
                .label("Address 1"),
            street2: yup.string().label("Address 2"),
        }),
        contact: yup.object().shape({
            name: yup
                .string()
                .required()
                .label("Name"),
            email: yup
                .string()
                .email()
                .required()
                .label("Email"),

            phone: yup.object().shape({
                extension: yup
                    .string()
                    .matches(/^(?:[1-9]\d{0,4}|)$/, "Invalid Extension")
                    .label("Ext."),
                phoneNumber: yup
                    .string()
                    .isValidPhoneNumber()
                    .required()
                    .label("Phone Number"),
            }),
        }),
        pickupInformation: yup.object().shape({
            readyTime: yup.string(),
            closeTime: yup.string(),
            name: yup.string(),
            phone: yup.object().shape({
                extension: yup
                    .string()
                    .matches(/^(?:[1-9]\d{0,4}|)$/, "Invalid Extension"),
                phoneNumber: yup
                    .string()
                    .isValidPhoneNumber()
                    .label("Phone Number"),
            }),
            email: yup
                .string()
                .email()
                .label("Email"),
        }),
    })
    .required()

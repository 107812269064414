import React from "react"
import { useOrdersContext } from "../../../context/providers/OrdersProvider"
import {
    Drawer,
    Grid,
    Typography,
    Button,
    Box,
    ClickAwayListener,
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { Field } from "redux-form"
import { FormattedMessage, useIntl } from "react-intl"
import ExpandableListFilter from "./ExpandableListFilter"
import moment from "moment"
import OrdersFormDatePicker from "./OrdersFormDatePicker"
import { useFlags } from "launchdarkly-react-client-sdk"

const styles = theme => ({
    mainContainer: {
        width: 400,
        height: "100%",
    },
    titleContainer: {
        padding: 10,
        backgroundColor: theme.palette.primary.main,
        color: "white",
        height: 64,
    },
    filtersContainer: {
        height: "calc(100% - 110px)",
        overflowY: "scroll",
    },
    buttonContainer: {
        height: "46px",
        padding: "5px",
        borderTop: "Solid 2px #D4D4D4",
    },
    panelSummary: {
        "&:hover": {
            backgroundColor: "#D4D4D4",
        },
        width: "100%",
        paddingTop: 5,
        paddingTBottom: 5,
    },
    expansionPanelRoot: {
        width: "100%",
    },
    badgePadding: {
        paddingRight: "15px !important",
    },
    datePickerPadding: {
        paddingTop: "15px",
    },
})

const FiltersMenu = ({
    classes,
    open,
    setOpen,
    formattedLocations,
    orderStatus,
    startDateFilter,
    endDateFilter,
    setStartDateFilter,
    setEndDateFilter,
}) => {
    const {
        setLocationsFilters,
        locationsFilters,
        serviceLevelFilters,
        setServiceLevelFilters,
        deliveryTimeFilters,
        setDeliveryTimeFilters,
        applyFilters,
        clearFilters,
    } = useOrdersContext()
    const { ordersPhaseTwo } = useFlags()
    const intl = useIntl()

    const serviceLevelOptions = [
        { value: "fxnl", label: "Economy" },
        { value: "fxfe", label: "Priority" },
        { value: "basic-fxnl", label: "Basic Economy" },
        { value: "basic-fxfe", label: "Basic Priority" },
        { value: "basic_appt-fxnl", label: "Basic by Appointment Economy" },
        { value: "basic_appt-fxfe", label: "Basic by Appointment Priority" },
        { value: "standard-fxfe", label: "Standard" },
        { value: "premium-fxfe", label: "Premium" },
    ]
    const deliveryTimeOptions = [
        { value: "morning", label: "Morning" },
        { value: "endofDay", label: "End of Day" },
    ]

    const handleClickAway = () => {
        setOpen(false)
    }

    return (
        <Drawer anchor="right" open={open}>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Grid
                    item
                    container
                    className={classes.mainContainer}
                    alignContent="flex-start"
                >
                    <Grid
                        item
                        container
                        className={classes.titleContainer}
                        justify="center"
                        alignItems="center"
                    >
                        <Typography variant="h6" color="inherit">
                            <FormattedMessage
                                id="dashboard.filters__filters"
                                defaultMessage="Filters"
                            />
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        className={classes.filtersContainer}
                        alignContent="flex-start"
                    >
                        <Box
                            sx={{
                                p: "20px",
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-evenly",
                            }}
                        >
                            <Box sx={{ width: "150px", pr: "10px" }}>
                                <Field
                                    component={OrdersFormDatePicker}
                                    name="startDate"
                                    label={intl.formatMessage({
                                        id: "orders.dateFilter.startDate",
                                        defaultMessage: "Upload Start Date",
                                    })}
                                    InputProps={startDateFilter}
                                    maxDate={moment(endDateFilter)}
                                    noMinDate
                                    setDateFilter={setStartDateFilter}
                                />
                            </Box>
                            <Box sx={{ width: "150px", pr: "10px" }}>
                                <Field
                                    component={OrdersFormDatePicker}
                                    name="endDate"
                                    label={intl.formatMessage({
                                        id: "orders.dateFilter.endDate",
                                        defaultMessage: "Upload End Date",
                                    })}
                                    minDate={moment(startDateFilter)}
                                    maxDate={moment(
                                        moment()
                                            .tz(moment.tz.guess())
                                            .add(1, "days")
                                    )}
                                    InputProps={endDateFilter}
                                    setDateFilter={setEndDateFilter}
                                />
                            </Box>
                        </Box>
                        <ExpandableListFilter
                            title={
                                <FormattedMessage
                                    id="dashboard.filters__location"
                                    defaultMessage="Location"
                                />
                            }
                            isLocationFilter={true}
                            selectedFilters={locationsFilters}
                            setSelectedFilters={setLocationsFilters}
                            options={formattedLocations}
                            classes={classes}
                        />
                        {orderStatus === "readyToBook" && ordersPhaseTwo && (
                            <>
                                <ExpandableListFilter
                                    title={
                                        <FormattedMessage
                                            id="orders.filter.serviceLeve"
                                            defaultMessage="Service Level"
                                        />
                                    }
                                    isLocationFilter={false}
                                    selectedFilters={serviceLevelFilters}
                                    setSelectedFilters={setServiceLevelFilters}
                                    options={serviceLevelOptions}
                                    classes={classes}
                                />
                                <ExpandableListFilter
                                    title={
                                        <FormattedMessage
                                            id="orders.filter.deliveryTime"
                                            defaultMessage="Delivery Time"
                                        />
                                    }
                                    isLocationFilter={false}
                                    selectedFilters={deliveryTimeFilters}
                                    setSelectedFilters={setDeliveryTimeFilters}
                                    options={deliveryTimeOptions}
                                    classes={classes}
                                />
                            </>
                        )}
                    </Grid>
                    <Grid
                        item
                        container
                        justify="space-between"
                        className={classes.buttonContainer}
                    >
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={clearFilters}
                        >
                            <FormattedMessage
                                id="dashboard.filters__clear"
                                defaultMessage="Clear"
                            />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={applyFilters}
                        >
                            <FormattedMessage
                                id="dashboard.filters__apply"
                                defaultMessage="Apply"
                            />
                        </Button>
                    </Grid>
                </Grid>
            </ClickAwayListener>
        </Drawer>
    )
}

export default withStyles(styles)(FiltersMenu)

import React from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"
import WarningIcon from "@material-ui/icons/Warning"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"
import ContactForm from "./contactForm"

const styles = theme => ({
    card: {
        height: "100%",
    },
    content: {
        "margin-left": "60px",
    },
})

const HazMatForm = ({ classes }) => (
    <Grid xs={12} item>
        <Card className={classes.card}>
            <CardHeader
                className={classes.header}
                title={
                    <Typography variant="h5">
                        <FormattedMessage
                            id="orderDetails.hazmat__title"
                            defaultMessage="HazMat Information"
                        />
                    </Typography>
                }
                avatar={
                    <Avatar>
                        <WarningIcon />
                    </Avatar>
                }
            />
            <CardContent className={classes.content}>
                <ContactForm />
            </CardContent>
        </Card>
    </Grid>
)

HazMatForm.propTypes = {
    classes: PropTypes.object,
}

HazMatForm.defaultProps = {
    classes: {},
}

export default withStyles(styles)(HazMatForm)

import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Input from "@material-ui/core/Input"
import MenuItem from "@material-ui/core/MenuItem"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import {
    Grid,
    FormControl,
    InputLabel,
    FormHelperText,
} from "@material-ui/core"
import Select from "react-select"
import "react-select/dist/react-select.css"
import FormattedError from "./formatted-error"
import { FormattedMessage } from "react-intl"

class Option extends React.Component {
    handleClick = event => {
        event.preventDefault()
        this.props.onSelect(this.props.option, event)
    }

    render() {
        const { children, isFocused, isSelected, onFocus, index } = this.props

        return (
            <MenuItem
                onFocus={onFocus}
                selected={isFocused}
                onClick={this.handleClick}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    display: "block",
                }}
                dataTestId={`formSelectAccessorialOption-${index}`}
            >
                {children}
            </MenuItem>
        )
    }
}

function SelectWrapped(props) {
    const { classes, label, options, ...other } = props

    const adjustedOptions = options.filter(entry => entry)

    return (
        <Fragment>
            <Select
                optionComponent={Option}
                noResultsText={
                    <Typography variant="body2">
                        <FormattedMessage
                            id="generalTerms__noResults"
                            defaultMessage="No results found"
                        />
                    </Typography>
                }
                arrowRenderer={arrowProps =>
                    arrowProps.isOpen ? (
                        <ArrowDropUpIcon />
                    ) : (
                        <ArrowDropDownIcon />
                    )
                }
                valueComponent={valueProps => <span />}
                options={adjustedOptions}
                {...other}
            />
        </Fragment>
    )
}

const ITEM_HEIGHT = 48

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    chip: {
        margin: theme.spacing(0.25),
    },
    form__container: {
        padding: "1px",
        width: "100%",
    },
    form__control: {
        width: "100%",
    },
    "@global": {
        ".Select-control": {
            display: "flex",
            alignItems: "center",
            border: 0,
            height: "auto",
            cursor: "pointer",
            background: "transparent",
            "&:hover": {
                boxShadow: "none",
            },
        },
        ".Select-multi-value-wrapper": {
            flexGrow: 1,
            display: "flex",
            flexWrap: "wrap",
        },
        ".Select--multi .Select-input": {
            margin: 0,
        },
        ".Select.has-value.is-clearable.Select--single > .Select-control .Select-value": {
            padding: 0,
        },
        ".Select-noresults": {
            padding: theme.spacing(2),
        },
        ".Select-input": {
            display: "inline-flex !important",
            padding: 0,
            height: "auto",
        },
        ".Select--single > .Select-control .Select-value": {
            paddingLeft: "0px",
        },
        ".Select-input input": {
            background: "transparent",
            border: 0,
            padding: 0,
            cursor: "default",
            display: "inline-block",
            fontFamily: "inherit",
            fontSize: "inherit",
            margin: 0,
            outline: 0,
            color: "black",
        },
        ".Select-placeholder, .Select--single .Select-value": {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.pxToRem(16),
            padding: 0,
        },
        ".Select-placeholder": {
            opacity: 0.42,
            color: theme.palette.common.black,
            display: "none",
        },
        ".Select-menu-outer": {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
            position: "absolute",
            left: 0,
            top: `calc(100% + ${theme.spacing(1)}px)`,
            width: "100%",
            zIndex: 9999,
            maxHeight: ITEM_HEIGHT * 5.5,
        },
        ".Select.is-focused:not(.is-open) > .Select-control": {
            boxShadow: "none",
        },
        ".Select-menu": {
            maxHeight: ITEM_HEIGHT * 5.5,
            overflowY: "auto",
        },
        ".Select-menu div": {
            boxSizing: "content-box",
        },
        ".Select-arrow-zone, .Select-clear-zone": {
            color: theme.palette.action.active,
            cursor: "pointer",
            height: 21,
            width: 21,
            zIndex: 1,
        },
        // Only for screen readers. We can't use display none.
        ".Select-aria-only": {
            position: "absolute",
            overflow: "hidden",
            clip: "rect(0 0 0 0)",
            height: 1,
            width: 1,
            margin: -1,
        },
        ".Select.is-disabled > .Select-control": {
            backgroundColor: "transparent",
        },
        ".Select.is-disabled.has-value.Select--single > .Select-control .Select-value .Select-value-label": {
            color: "currentColor",
        },
    },
})

const FormSelectAutocomplete = ({
    category,
    input,
    label,
    options = [],
    classes,
    placeholder,
    meta: { touched, error },
    dataTestId,
    ...rest
}) => (
    <Grid
        item
        container
        className={`${classnames({
            "form-field": true,
            "form-field-touched": touched,
            "form-field-invalid": touched && error,
            "form-field-valid": !error,
        })} ${classes.form__container}`}
    >
        <Grid item container direction="column">
            <FormControl error={!!(touched && error)}>
                <InputLabel htmlFor={input.name} id={`${input.name}-label`}>
                    {label}
                </InputLabel>
                <Input
                    inputComponent={SelectWrapped}
                    id={input.name}
                    inputProps={{
                        id: input.name,
                        classes,
                        simpleValue: true,
                        clearable: false,
                        multi: true,
                        options,
                        placeholder,
                        dataTestId,
                        instanceId: input.name,
                    }}
                    {...input}
                    {...rest}
                    onBlur={() => input.onBlur(input?.value)}
                    dataTestId={dataTestId}
                />
                {touched && error && (
                    <FormHelperText>
                        <FormattedError error={error} />
                    </FormHelperText>
                )}
            </FormControl>
        </Grid>
    </Grid>
)

FormSelectAutocomplete.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FormSelectAutocomplete)

import { Card, CardContent, CardHeader, Typography } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import React from "react"

const TitleCard = ({ bol, trackingNumber, formTitle }) => (
    <Card>
        <CardHeader
            title={
                <FormattedMessage
                    id="certificateOfOrigin.title"
                    defaultMessage="{formTitle} Form - BOL #: {bol} - Tracking ID #: {trackingNumber}"
                    values={{
                        formTitle,
                        bol,
                        trackingNumber,
                    }}
                />
            }
        />
        <CardContent>
            <Typography variant="caption">
                <FormattedMessage
                    id="documents.tradeDocumentationInfoPreBook"
                    defaultMessage="Trade documentation will be sent to Customs on your behalf as a single document after a signature & notary stamp have been applied. FedEx requires trade documentation to be signed. You can add your signature by uploading it directly or by printing the trade documents first, signing them manually, and then uploading the signed trade documents to LTL Select. Your documents will automatically be shared with FedEx."
                />
            </Typography>
        </CardContent>
    </Card>
)

export default TitleCard

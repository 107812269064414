import React, { useEffect, useCallback, useState } from "react"
import PropTypes from "prop-types"
import { reduxForm, Field, formValues } from "redux-form"
import { isEmpty, get } from "lodash"
import { FormattedMessage } from "react-intl"
import moment from "moment"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import { connect } from "react-redux"
import FormDatePicker from "../form/form-datepicker"
import FormRadio from "../form/form-radio"
import { goFetch } from "../../http"
import GlobalSpinner from "../common/GlobalSpinner"
import { DownloadCSVButton } from "../util"
import { Report } from "./report"
import { StatisticsUserTable } from "./userTable"
import FormSelectAutoComplete from "../form/form-select-autocomplete"
import FileSaver from "file-saver"
import { combineValidators, composeValidators } from "revalidate"
import { isRequired } from "../../actions/validation"

const styles = theme => ({
    root__container: {
        padding: "5%",
    },
    report__container: {
        margin: "10px 0",
    },
    button__container: {
        margin: "10px 0",
    },
    paper__root: {
        width: "100%",
    },
    tabs__container: {
        marginBottom: "20px",
    },
    subtitle1: {
        marginTop: "40px",
    },
})

const extensionRegExp = /\.([^.]+?)$/

const modeOptions = [
    { value: "annually", label: "Year" },
    { value: "quarterly", label: "Quarter" },
    { value: "monthly", label: "Month" },
    { value: "weekly", label: "Week" },
    { value: "daily", label: "Day" },
]

export const StatisticsPresentation = props => {
    const { mode, handleSubmit, submitting, classes } = props
    const [stats, setStats] = useState([])
    const [userStats, setUserStats] = useState([])
    const [reportFiles, setReportFiles] = useState([])
    const [reportOptions, setReportOptions] = useState([])
    const [value, setValue] = useState(0)

    useEffect(() => {
        const fetchReport = async () => {
            try {
                const { data } = await goFetch(
                    "/metrics/reports/filenames",
                    { method: "GET" },
                    true
                )
                setReportFiles(data)
                setReportOptions(
                    data.map((x, i) => ({ label: x.displayName, value: i }))
                )
            } catch (error) {
                return error
            }
        }
        fetchReport()
    }, [])

    const submit = useCallback(async values => {
        const { mode } = values
        const startDate = get(values, "startDate")
            ? moment(values.startDate).format("YYYY-MM-DD")
            : undefined
        const endDate = get(values, "endDate")
            ? moment(values.endDate).format("YYYY-MM-DD")
            : undefined
        try {
            const { data } = await goFetch(
                "/metrics",
                {
                    params: {
                        startDate,
                        endDate,
                        type: mode.toUpperCase(),
                    },
                },
                true
            )
            setStats(data)
        } catch (error) {
            return error
        }
    }, [])

    const submitUser = useCallback(async () => {
        try {
            const { data } = await goFetch("/metrics/users", {}, true)
            setUserStats(data)
        } catch (error) {
            return error
        }
    }, [])

    const customReport = useCallback(async values => {
        try {
            const { reportStartDate, reportEndDate } = values
            const params = {
                endDate: moment(reportEndDate).format("YYYY-MM-DD"),
                startDate: moment(reportStartDate).format("YYYY-MM-DD"),
            }
            const contentType =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            const { data } = await goFetch(
                "/reports",
                {
                    params,
                    method: "GET",
                    credentials: "same-origin",
                    responseType: "blob",
                    headers: { Accept: contentType },
                },
                true
            )
            const blob = new Blob([data], {
                type: contentType,
            })
            FileSaver.saveAs(
                blob,
                `custom_report_${params.startDate}_${params.endDate}.xlsx`
            )
            return blob
        } catch (error) {
            return error
        }
    }, [])

    const downloadReport = useCallback(
        async values => {
            try {
                const { reportMonth } = values
                const fileInfo = reportFiles[reportMonth]
                const params = {
                    filename: fileInfo?.originalFileName,
                    type: fileInfo?.attachmentType,
                    format: extensionRegExp.exec(
                        fileInfo?.originalFileName
                    )?.[1],
                }
                const { data } = await goFetch(
                    "/documents",
                    {
                        params,
                        method: "GET",
                        credentials: "same-origin",
                        responseType: "blob",
                        headers: { Accept: fileInfo?.mimeType },
                    },
                    true
                )
                const blob = new Blob([data], {
                    type: fileInfo?.mimeType,
                })
                FileSaver.saveAs(blob, fileInfo?.userFileName)
                return blob
            } catch (error) {
                return error
            }
        },
        [reportFiles]
    )

    return (
        <Grid container className={classes.root__container}>
            <Tabs
                value={value}
                onChange={(event, value) => setValue(value)}
                className={classes.tabs__container}
            >
                <Tab
                    label={
                        <FormattedMessage
                            id="statistics__activity_data"
                            defaultMessage="Activity data"
                        />
                    }
                    disabled={submitting}
                />
                <Tab
                    label={
                        <FormattedMessage
                            id="statistics__user_profiles"
                            defaultMessage="User Profiles"
                        />
                    }
                    disabled={submitting}
                />
                <Tab
                    label={
                        <FormattedMessage
                            id="statistics__monthly_report"
                            defaultMessage="Monthly Report"
                        />
                    }
                    disabled={submitting}
                />
            </Tabs>
            {value === 0 && (
                <Grid item container>
                    <Grid item container>
                        <Grid item container>
                            <Field
                                name="mode"
                                component={FormRadio}
                                label={
                                    <FormattedMessage
                                        id="statistics__time_period"
                                        defaultMessage="Choose time period"
                                    />
                                }
                                options={modeOptions}
                                disabled={submitting}
                                onChange={() => setStats([])}
                                required
                            />
                        </Grid>
                        <Grid item container xs={3}>
                            <Field
                                component={FormDatePicker}
                                name="startDate"
                                label={
                                    <FormattedMessage
                                        id="statistics__start_date"
                                        defaultMessage="Start Date"
                                    />
                                }
                                minDate="2017-01-01"
                                maxDate={moment()}
                                required
                            />
                        </Grid>
                        <Grid item container xs={3}>
                            <Field
                                component={FormDatePicker}
                                name="endDate"
                                label={
                                    <FormattedMessage
                                        id="statistics__end_date"
                                        defaultMessage="End Date"
                                    />
                                }
                                minDate="2017-01-02"
                                maxDate={moment()}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        justify="space-between"
                        className={classes.button__container}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit(submit)}
                            disabled={submitting}
                        >
                            <FormattedMessage
                                id="statistics__generate_statitics"
                                defaultMessage="Generate Statistics"
                            />
                        </Button>
                        {!isEmpty(stats) && (
                            <DownloadCSVButton
                                data={stats}
                                name="ltl_select_report"
                            />
                        )}
                    </Grid>
                    {submitting && <GlobalSpinner global={false} />}
                    {!isEmpty(stats) && (
                        <Grid
                            item
                            container
                            className={classes.report__container}
                        >
                            <Paper className={classes.paper__root}>
                                <Report
                                    data={stats}
                                    mode={
                                        modeOptions.find(e => e.value === mode)
                                            .label
                                    }
                                />
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            )}
            {value === 1 && (
                <Grid item container>
                    <Grid
                        item
                        container
                        justify="space-between"
                        className={classes.button__container}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit(submitUser)}
                            disabled={submitting}
                        >
                            <FormattedMessage
                                id="statistics__generate_statitics"
                                defaultMessage="Generate Statistics"
                            />
                        </Button>
                    </Grid>
                    {submitting && <GlobalSpinner global={false} />}
                    {!isEmpty(userStats) && (
                        <StatisticsUserTable data={userStats} />
                    )}
                </Grid>
            )}
            {value === 2 && (
                <Grid item container>
                    <Grid item container>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="statistics__custom_report"
                                defaultMessage="Custom Report"
                            />
                        </Typography>
                    </Grid>
                    <Grid item container xs={3}>
                        <Field
                            component={FormDatePicker}
                            name="reportStartDate"
                            label={
                                <FormattedMessage
                                    id="statistics__start_date"
                                    defaultMessage="Start Date"
                                />
                            }
                            minDate="2017-01-01"
                            defaultValue={moment().startOf("month")}
                            maxDate={moment()}
                            required
                        />
                    </Grid>
                    <Grid item container xs={3}>
                        <Field
                            component={FormDatePicker}
                            name="reportEndDate"
                            label={
                                <FormattedMessage
                                    id="statistics__end_date"
                                    defaultMessage="End Date"
                                />
                            }
                            minDate="2017-01-02"
                            defaultValue={moment()}
                            maxDate={moment()}
                            required
                        />
                    </Grid>
                    <Grid item container>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit(customReport)}
                            disabled={submitting}
                        >
                            <FormattedMessage
                                id="statistics__generate_custom_report"
                                defaultMessage="Generate Custom Report"
                            />
                        </Button>
                    </Grid>
                    <Grid item container>
                        <Typography
                            className={classes.subtitle1}
                            variant="subtitle1"
                        >
                            <FormattedMessage
                                id="statistics__monthly_report"
                                defaultMessage="Monthly Report"
                            />
                        </Typography>
                    </Grid>
                    <Grid item container xs={3}>
                        <Field
                            name="reportMonth"
                            component={FormSelectAutoComplete}
                            type="text"
                            label={
                                <FormattedMessage
                                    id="generalTerms__month"
                                    defaultMessage="Month"
                                />
                            }
                            options={reportOptions}
                        />
                    </Grid>
                    <Grid item container className={classes.button__container}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit(downloadReport)}
                            disabled={submitting}
                        >
                            <FormattedMessage
                                id="statistics__download_report"
                                defaultMessage="Download Report"
                            />
                        </Button>
                    </Grid>
                    {submitting && <GlobalSpinner global={false} />}
                </Grid>
            )}
        </Grid>
    )
}

StatisticsPresentation.propTypes = {
    activities: PropTypes.object.isRequired,
    loadStats: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    ...state.statistics,
    initialValues: {
        mode: "daily",
        startDate: moment()
            .subtract({ days: 90 })
            .format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        reportStartDate: moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
        reportEndDate: moment().format("YYYY-MM-DD"),
    },
})

const isDateRangeValid = (startFieldName, maxDays) => (
    field,
    fieldValue,
    formValues
) => {
    const endDate = moment(fieldValue)
    const startDate = moment(formValues[startFieldName])
    const dayRange = moment.duration(endDate.diff(startDate)).asDays()
    if (dayRange <= maxDays) {
        return false
    } else {
        return (
            <FormattedMessage
                id="statistics__validation__dateRangeTooLarge"
                defaultMessage="Date range cannot be larger than {maxDays} days"
                values={{ maxDays }}
            />
        )
    }
}

const validator = combineValidators({
    startDate: isRequired({
        field: {
            id: "statistics__start_date",
            defaultMessage: "Start Date",
        },
    }),
    endDate: isRequired({
        field: {
            id: "statistics__end_date",
            defaultMessage: "End Date",
        },
    }),
    reportStartDate: isRequired({
        field: {
            id: "statistics__start_date",
            defaultMessage: "Start Date",
        },
    }),
    reportEndDate: composeValidators(
        isRequired,
        isDateRangeValid("reportStartDate", 31)
    )({
        field: {
            id: "statistics__end_date",
            defaultMessage: "End Date",
        },
    }),
})

export const Statistics = withStyles(styles)(
    connect(mapStateToProps)(
        reduxForm({
            form: "statistics",
            validate: validator,
            enableReinitialize: true,
        })(formValues("mode")(StatisticsPresentation))
    )
)

import React from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form"
import { Typography } from "@material-ui/core"
import FormField from "../../form/form-field"
import { Grid } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

let BillingInformation = ({ location = {}, editable, gaCategory }) => (
    <Grid item container>
        <Grid item container alignContent="flex-start">
            <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>
                    <FormattedMessage
                        id="locations.billingInformation__title"
                        defaultMessage="Billing Information"
                    />
                </Typography>
            </Grid>
            <Grid item container xs={6} justify="flex-end"></Grid>
        </Grid>
        <Grid item container>
            <Field
                name="billingContact.companyName"
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__companyName"
                        defaultMessage="Company Name"
                    />,
                ]}
                component={FormField}
                category={gaCategory}
            />
        </Grid>
        <Grid item container xs={6}>
            <Field
                name="address.street1"
                component={FormField}
                type="text"
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__address1"
                        defaultMessage="Address 1"
                    />,
                ]}
                category={gaCategory}
            />
        </Grid>
        <Grid item container xs={6}>
            <Field
                name="address.street2"
                component={FormField}
                type="text"
                label={
                    <FormattedMessage
                        id="generalTerms__address2"
                        defaultMessage="Address 2"
                    />
                }
                category={gaCategory}
            />
        </Grid>
        <Grid item container xs={6}>
            <Field
                name="address.postalCode"
                component={FormField}
                type="text"
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__zipPostalCode"
                        defaultMessage="Zip/Postal Code"
                    />,
                ]}
                category={gaCategory}
            />
        </Grid>
        <Grid item container xs={6}>
            <Field
                name="address.city"
                component={FormField}
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__city"
                        defaultMessage="City"
                    />,
                ]}
                category={gaCategory}
            />
        </Grid>
        <Grid item container xs={6}>
            <Field
                name="address.state"
                component={FormField}
                type="text"
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__stateProvince"
                        defaultMessage="State/Province"
                    />,
                ]}
                category={gaCategory}
            />
        </Grid>
        <Grid item container xs={6}>
            <Field
                name="address.country"
                component={FormField}
                type="text"
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__country"
                        defaultMessage="Country"
                    />,
                ]}
                category={gaCategory}
            />
        </Grid>
    </Grid>
)

BillingInformation.propTypes = {
    location: PropTypes.object.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    gaCategory: PropTypes.string,
}

export default BillingInformation

import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"
import {
    Field,
    FormSection,
    formValues,
    resetSection,
    ReduxFormContext,
} from "redux-form"
import { compact } from "lodash"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage, injectIntl } from "react-intl"
import { CarrierLogo } from "../util"
import FormField from "../form/form-field"
import FormCheckbox from "../form/form-checkbox"
import { reduxFormProps } from "../../misc"

const styles = theme => ({
    card: {
        height: "100%",
    },
    content: {
        "margin-left": "60px",
    },
    error__icon: {
        color: theme.palette.error,
    },
    serviceLevel_text: {
        color: "green",
    },
    left_shift: {
        marginLeft: "-24 px",
    },
    deliveryTime: {
        paddingLeft: "3px",
    },
})

class HeaderForm extends Component {
    handleProSwitch(value) {
        if (value) return
        const { sectionPrefix, form } = reduxFormProps(this.context)
        const fieldName = compact([
            sectionPrefix,
            "identifiers.proNumber",
        ]).join(".")
        this.props.cancel(form, fieldName)
    }

    render() {
        const {
            intl,
            pickupDate,
            deliveryDate,
            deliveryTime,
            showDeliveryDate,
            isVolume,
            serviceType,
            isGuaranteed,
            carrierCode,
            quoteId,
            proEnabled,
            classes,
            isDomesticOffshore,
            mode,
            isPickUpAndDestroy,
        } = this.props
        return (
            <Grid xs={12} item>
                <Card className={classes.card}>
                    <CardContent className={classes.content}>
                        <Grid container>
                            <Grid xs={6} item>
                                <Typography
                                    variant="body2"
                                    className={classes.left_shift}
                                    gutterBottom
                                >
                                    <CarrierLogo
                                        carrierCode={
                                            isDomesticOffshore
                                                ? "domestic_offshore"
                                                : carrierCode
                                        }
                                    />
                                </Typography>
                                {isVolume && (
                                    <Fragment>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                        >
                                            <FormattedMessage
                                                id="generalTerms__quoteId"
                                                defaultMessage="Quote ID"
                                            />
                                            {`: ${quoteId}`}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                        >
                                            <FormattedMessage
                                                id="orderDetails.header__serviceType"
                                                defaultMessage="Service Type"
                                            />
                                            {`: ${serviceType}`}
                                        </Typography>
                                    </Fragment>
                                )}
                                <Typography variant="body2" gutterBottom>
                                    <FormattedMessage
                                        id="orderDetails.header__shipDate"
                                        defaultMessage="Ship Date"
                                    />
                                    {`: ${moment.utc(pickupDate).format("L")}`}
                                </Typography>
                                {!isPickUpAndDestroy &&
                                showDeliveryDate &&
                                !isDomesticOffshore ? (
                                    <Fragment>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                        >
                                            <FormattedMessage
                                                id="orderDetails.header__deliveryDate"
                                                defaultMessage="Delivery Date"
                                            />
                                            {`: ${moment
                                                .utc(deliveryDate)
                                                .format("L")}`}
                                        </Typography>
                                        {mode !== "LTL_DIRECT" && (
                                            <Grid item container>
                                                <Typography
                                                    variant="body2"
                                                    gutterBottom
                                                >
                                                    <FormattedMessage
                                                        id="generalTerms__time"
                                                        defaultMessage="Time"
                                                    />
                                                </Typography>
                                                :
                                                <Typography
                                                    variant="body2"
                                                    className={
                                                        classes.deliveryTime
                                                    }
                                                >
                                                    {deliveryTime}
                                                </Typography>
                                            </Grid>
                                        )}
                                        {isGuaranteed && (
                                            <Typography
                                                gutterBottom
                                                variant="caption"
                                                className={
                                                    classes.serviceLevel_text
                                                }
                                            >
                                                <FormattedMessage
                                                    id="generalTerms__guaranteed"
                                                    defaultMessage="Guaranteed"
                                                />
                                            </Typography>
                                        )}
                                    </Fragment>
                                ) : !isPickUpAndDestroy ? (
                                    <Typography variant="body2" gutterBottom>
                                        <FormattedMessage
                                            id="orderDetails.header__unknownDeliveryDate"
                                            defaultMessage="Call carrier for delivery date"
                                        />
                                    </Typography>
                                ) : null}
                            </Grid>
                            <Grid xs={6} item>
                                <FormSection
                                    name="identifiers"
                                    component={Fragment}
                                >
                                    {carrierCode !== "FXFE" &&
                                        carrierCode !== "FXNL" && (
                                            <Field
                                                name="proEnabled"
                                                label={
                                                    <FormattedMessage
                                                        id="generalTerms__trackingId__pre"
                                                        defaultMessage="Pre-assign Tracking ID"
                                                    />
                                                }
                                                component={FormCheckbox}
                                                enableSwitch
                                                onChange={value =>
                                                    this.handleProSwitch(value)
                                                }
                                            />
                                        )}
                                    {carrierCode !== "FXFE" &&
                                        carrierCode !== "FXNL" && (
                                            <Field
                                                name="proNumber"
                                                label={
                                                    <FormattedMessage
                                                        id="generalTerms__trackingId"
                                                        defaultMessage="Tracking ID"
                                                    />
                                                }
                                                placeholder="Enter Tracking ID"
                                                component={FormField}
                                                disabled={!proEnabled}
                                            />
                                        )}
                                    <Field
                                        name="bolNumber"
                                        label={
                                            <FormattedMessage
                                                id="orderDetails.label__BOL"
                                                defaultMessage="Bill of Lading ID"
                                            />
                                        }
                                        placeholder={intl.formatMessage({
                                            id: "orderDetails.placeholder__BOL",
                                            defaultMessage:
                                                "Enter Bill of Lading ID",
                                        })}
                                        component={FormField}
                                    />
                                    <Field
                                        name="orderNumber"
                                        label={[
                                            <FormattedMessage
                                                id="generalTerms__PO"
                                                defaultMessage="PO"
                                            />,
                                            " #",
                                        ]}
                                        placeholder={
                                            intl.formatMessage({
                                                id:
                                                    "orderDetails.placeholder__PO",
                                                defaultMessage: "Enter PO",
                                            }) + " #"
                                        }
                                        component={FormField}
                                    />
                                    <Field
                                        name="referenceNumber"
                                        label={
                                            <FormattedMessage
                                                id="generalTerms__referenceId"
                                                defaultMessage="Reference ID"
                                            />
                                        }
                                        placeholder={intl.formatMessage({
                                            id:
                                                "orderDetails.placeholder__referenceId",
                                            defaultMessage:
                                                "Enter Reference ID",
                                        })}
                                        component={FormField}
                                    />
                                </FormSection>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        )
    }
}

HeaderForm.contextType = ReduxFormContext

HeaderForm.propTypes = {
    classes: PropTypes.object,
}

HeaderForm.defaultProps = {
    classes: {},
}

const mapDispatchToProps = dispatch => ({
    cancel: (form, section) => dispatch(resetSection(form, section)),
})

export default withStyles(styles)(
    formValues({
        proEnabled: "identifiers.proEnabled",
    })(connect(undefined, mapDispatchToProps)(injectIntl(HeaderForm)))
)

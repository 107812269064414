import {
    SELECT_CONTACT,
    CONTACT_FIELD_CHANGE,
    CONTACT_ADDRESS_RESULT,
} from "../actions/address"
import { USER_DISAUTHENTICATED } from "../actions/user"
import { FIELD_VALIDATION } from "../actions/validation"
import { SELECT_ADDRESS_WITH_CONTACT, QUOTE_RESET } from "../actions/quote"

const selectRecord = (state, id) => {
    let record = state?.addresses[0]
    if (id?.length > 0) {
        record = state?.addresses?.find(a => a?.contact?.id === id)
    }
    if (!record) return {}

    return {
        id: record.contact.id,
        addressId: record.id,
        street1: record.address.street1,
        street2: record.address.street2,
        postalCode: record.address.postalCode,
        state: record.address.state,
        city: record.address.city,
        country: record.address.country,
        pickupAccessorials: record?.pickupAccessorials?.length
            ? record?.pickupAccessorials.map(entry => entry.value)
            : [],
        deliveryAccessorials: record?.deliveryAccessorials?.length
            ? record?.deliveryAccessorials.map(entry => entry.value)
            : [],
        companyName: record.name,
        name: record.contact.name,
        phone: record.contact.phone.phoneNumber,
        extension: record.contact.phone.extension,
        mobile: record.contact.phone.mobile,
        mail: record.contact.email.emailAddress,
        pickupContact: {
            ...record.contact?.pickupContact,
            phone: {
                phone_number: record.contact?.pickupContact?.phone?.phoneNumber,
                extension: record.contact?.pickupContact?.phone?.extension,
            },
            email: {
                email_address:
                    record.contact?.pickupContact?.email?.emailAddress,
            },
        },
    }
}

export function addressBook(
    state = {
        contactsLoaded: false,
        addressesLoaded: false,
        rawAddresses: [],
        addresses: [],
        selected: {
            contactChanged: false,
            addressChanged: false,
            validation: {},
        },
        contactToShipTo: {},
        editable: false,
    },
    action = {}
) {
    switch (action.type) {
        case SELECT_CONTACT:
            return {
                ...state,
                editable: false,
                selected: {
                    ...selectRecord(state, action.selected),
                },
            }
        case SELECT_ADDRESS_WITH_CONTACT:
            return {
                ...state,
                contactToShipTo: {
                    [action.kind]: action.postalCode || state[action.kind],
                    [`${action.kind}City`]: action.city || "",
                    [`${action.kind}State`]: action.state || "",
                    [`${action.kind}Address`]: action.addressId,
                    [`${action.kind}AddressInfo`]: action.addressInfo,
                    [`${action.kind}Contact`]: action.contactId,
                    [`${action.kind}ContactInfo`]: action.contactInfo,
                },
            }
        case QUOTE_RESET: {
            return {
                ...state,
                contactToShipTo: {},
            }
        }
        case CONTACT_FIELD_CHANGE:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    [action.field]: action.value,
                    [`${action.prefix}Changed`]: true,
                },
            }
        case CONTACT_ADDRESS_RESULT:
            return {
                ...state,
                addresses: action.result,
                addressesLoaded: true,
            }
        case USER_DISAUTHENTICATED:
            return addressBook()
        case FIELD_VALIDATION:
            if (action.form !== "addressBook") return state
            return {
                ...state,
                selected: {
                    ...state.selected,
                    validation: action.validation,
                },
            }
        default:
            return state
    }
}

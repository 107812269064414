import React, { useEffect, useState } from "react"
import {
    Checkbox,
    Grid,
    FormControl,
    Input,
    InputLabel,
    FormHelperText,
    FormControlLabel,
    Typography,
} from "@material-ui/core"
import { validateInput, stringToNumber } from "./AlaskaUtils"
import { FormattedMessage } from "react-intl"

const AlaskaDiscountField = ({
    index,
    defaultValue,
    label,
    initialValue,
    currentFormValues,
    fieldRef,
    handleStaticFieldChange,
}) => {
    const [checked, setChecked] = useState(false)
    const [input, setInput] = useState()

    useEffect(() => {
        if (initialValue?.isPercentage) {
            setChecked(true)
        }
        if (initialValue?.value || initialValue?.value === 0) {
            setInput(initialValue.value)
        }
    }, [initialValue])

    const onInputChange = evt => {
        const inputValue = validateInput(evt.target.value)
        setInput(inputValue)
        const oceanGrossDiscount = {
            value: evt.target.value !== "" ? stringToNumber(inputValue) : null,
            isPercentage: currentFormValues[fieldRef]
                ? currentFormValues[fieldRef].isPercentage
                : false,
        }
        const newFormValues = { ...currentFormValues, oceanGrossDiscount }
        handleStaticFieldChange(index, newFormValues)
    }

    const onCheckboxChange = () => {
        setChecked(!checked)
        setInput("")
        const oceanGrossDiscount = {
            value: null,
            isPercentage: !checked,
        }
        const newFormValues = { ...currentFormValues, oceanGrossDiscount }
        handleStaticFieldChange(index, newFormValues)
    }

    return (
        <Grid style={{ paddingRight: "20px" }} item container>
            <Grid item container direction="column">
                <FormControl>
                    <InputLabel>{label}</InputLabel>
                    <Input
                        value={input}
                        onChange={onInputChange}
                        autoComplete={false}
                        inputProps={{
                            min: 0,
                            style: { textAlign: checked ? "right" : "left" },
                            inputMode: "decimal",
                        }}
                        endAdornment={
                            checked ? (
                                <Typography style={{ paddingLeft: "4px" }}>
                                    %
                                </Typography>
                            ) : null
                        }
                        startAdornment={
                            !checked ? (
                                <Typography style={{ paddingRight: "4px" }}>
                                    $
                                </Typography>
                            ) : null
                        }
                    />

                    <FormHelperText>
                        <FormattedMessage
                            id="location.offshore.default"
                            defaultMessage="Default: ${value}"
                            values={{ value: defaultValue }}
                        />
                    </FormHelperText>
                </FormControl>
                <FormControl style={{ width: "150px" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={initialValue?.isPercentage}
                                onClick={onCheckboxChange}
                                value={checked}
                            />
                        }
                        label={
                            <FormattedMessage
                                id="location.offshore.percentageAmount"
                                defaultMessage="% amount"
                            />
                        }
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default AlaskaDiscountField

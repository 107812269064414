import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Route, withRouter, Redirect } from "react-router-dom"
import { TermsAndConditionsPanel } from "../util/terms-and-conditions"
import { initUserRecord, loggedIn } from "../../actions/authorization"
import { withStyles } from "@material-ui/core/styles"
import GlobalSpinner from "../common/GlobalSpinner"
import { disauthenticated } from "../../actions/user"
import {
    removeStorageChangeListener,
    setUpStorageChangeListener,
} from "../../misc"
import { getTerms } from "../../actions/terms"
import { withLDConsumer } from "launchdarkly-react-client-sdk"

export const componentType = () =>
    PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.element,
        PropTypes.instanceOf(Component),
    ])

const routePropTypes = {
    Comp: componentType().isRequired,
    user: PropTypes.object.isRequired,
    props: PropTypes.object,
}

export const userActivated = user =>
    user.preferences.isLoaded && user.preferences.accountActive

export const acceptedTerms = (user, terms) => {
    const { acceptedVersion } =
        (user.profile && user.profile.termsAndConditions) || {}
    return terms.version === acceptedVersion
}

export const renderTermsIfNeeded = (user, terms) =>
    !acceptedTerms(user, terms) && (
        <TermsAndConditionsPanel containerClassName={""} />
    )

export const Private = ({ Comp, user, props, terms }) => {
    if (userActivated(user)) {
        return renderTermsIfNeeded(user, terms) || <Comp {...props} />
    }
    return <Redirect to={{ pathname: "/user/signup" }} />
}

Private.propTypes = {
    ...routePropTypes,
    terms: PropTypes.object.isRequired,
}

Private.defaultProps = {
    props: { terms: {} },
}

export const Signup = ({ Comp, user, props }) => {
    if (userActivated(user)) {
        return <Redirect to={{ pathname: "/home" }} />
    }
    return <Comp {...props} />
}

Signup.propTypes = routePropTypes

Signup.defaultProps = {
    props: {},
}

const styles = theme => ({
    progress: {
        margin: theme.spacing(2),
        top: "50%",
        right: "50%",
        position: "absolute",
    },
})

class TokenWatcherPresentation extends Component {
    componentDidMount() {
        this.listener = setUpStorageChangeListener(
            "token",
            token => !token && this.props.onTokenExpire()
        )
    }

    componentWillUnmount() {
        if (this.listener) removeStorageChangeListener(this.listener)
    }

    render() {
        return this.props.children
    }
}

const tokenMapDispatchToProps = dispatch => ({
    onTokenExpire: () => dispatch(disauthenticated()),
})

const TokenWatcher = connect(
    undefined,
    tokenMapDispatchToProps
)(TokenWatcherPresentation)

export class RoutePresentation extends Component {
    componentDidMount() {
        this.props.onInit()
    }

    componentWillReceiveProps(newProps) {
        if (
            newProps?.location?.pathname !== this.props?.location?.pathname &&
            newProps?.newVersionAvailable
        ) {
            window.location.reload()
        }
    }

    render() {
        const {
            authorization,
            Type,
            component,
            user,
            classes,
            terms,
            ...rest
        } = this.props
        if (!loggedIn()) {
            return <Redirect to={{ pathname: "/" }} />
        }
        const termsVersion = terms.version
        if (!authorization.isFinished || !termsVersion) return <GlobalSpinner />
        return (
            <TokenWatcher>
                <Type
                    Comp={component}
                    user={user}
                    props={rest}
                    terms={terms}
                    authorization={authorization}
                />
            </TokenWatcher>
        )
    }
}

RoutePresentation.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.element,
        PropTypes.instanceOf(Component),
    ]).isRequired,
    user: PropTypes.object,
    terms: PropTypes.object,
    authorization: PropTypes.object.isRequired,
    Type: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.element,
        PropTypes.instanceOf(Component),
    ]).isRequired,
    isAuthorized: PropTypes.func.isRequired,
}

RoutePresentation.propTypes = {
    component: componentType().isRequired,
    user: PropTypes.object,
    terms: PropTypes.object,
    authorization: PropTypes.object.isRequired,
    Type: componentType().isRequired,
}

RoutePresentation.defaultProps = {
    component: undefined,
    user: {},
    terms: {},
}

const mapStateToProps = ({
    user,
    authorization,
    terms,
    newVersionAvailable,
}) => ({
    user,
    authorization,
    terms,
    newVersionAvailable,
})
const mapDispatchToProps = (dispatch, props) => ({
    onInit: async () => {
        await dispatch(initUserRecord(props?.ldClient))
        dispatch(getTerms())
    },
})

const InnerRoute = withLDConsumer()(
    withRouter(
        withStyles(styles)(
            connect(mapStateToProps, mapDispatchToProps)(RoutePresentation)
        )
    )
)

let CommonRoute = ({ component, Type, ...rest }) => (
    <Route
        {...rest}
        render={() => (
            <InnerRoute component={component} {...rest} Type={Type} />
        )}
    />
)

CommonRoute.propTypes = {
    component: componentType().isRequired,
    Type: componentType().isRequired,
}

export class InnerLandingRoutePresentation extends Component {
    componentDidMount() {
        this.props.onInit()
    }

    render() {
        const { user, authorization, terms } = this.props
        if (!loggedIn() || !authorization?.initFinished) return null
        if (!terms?.version || !authorization?.isFinished)
            return <GlobalSpinner />
        const pathname = userActivated(user) ? "/home" : "/user/signup"
        return <Redirect to={{ pathname }} />
    }
}

const InnerLandingRoute = withRouter(
    withStyles(styles)(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(InnerLandingRoutePresentation)
    )
)

export const PrivateRoute = props => <CommonRoute {...props} Type={Private} />
export const SignupRoute = props => <CommonRoute {...props} Type={Signup} />
export const LandingRoute = props => (
    <Route {...props} render={() => <InnerLandingRoute {...props} />} />
)

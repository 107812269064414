import React from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { FormattedMessage } from "react-intl"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
    buttonContainer: {
        paddingTop: "30px",
    },
    cancelButton: {
        marginRight: "10px",
    },
})

export default function SummaryButtons({
    handleCancelEdit,
    handleUpdate,
    invalid,
}) {
    const classes = useStyles()

    return (
        <Grid
            item
            container
            justify="center"
            className={classes.buttonContainer}
        >
            <Button
                id="cancelButton"
                color="secondary"
                variant="outlined"
                onClick={() => handleCancelEdit()}
                className={classes.cancelButton}
            >
                <FormattedMessage
                    id="generalTerms__back"
                    defaultMessage="Back"
                />
            </Button>
            <Button
                id="updateButton"
                color="secondary"
                variant="contained"
                onClick={() => handleUpdate(invalid)}
                disabled={invalid}
            >
                <FormattedMessage
                    id="generalTerms__update"
                    defaultMessage="Update"
                />
            </Button>
        </Grid>
    )
}

import React from "react"
import PropTypes from "prop-types"
import { get, compact } from "lodash"
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Typography from "@material-ui/core/Typography"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Button from "@material-ui/core/Button"
import { FormattedMessage } from "react-intl"

const styles = theme => ({})

const formatAddress = address => {
    const addr = compact([
        address?.street1,
        address?.street2,
        address?.city,
        address?.state,
    ]).join(", ")
    return address?.postalCode ? `${addr} ${address.postalCode}` : addr
}

const AddressDialog = ({
    suggestions,
    address,
    classes,
    onClose,
    onClick,
    onCancel,
    ...others
}) => (
    <Dialog className={classes.dialog} onClose={() => onClose()} {...others}>
        <DialogContent className={classes.dialog__content}>
            <Typography variant="h5" gutterBottom>
                <FormattedMessage
                    id="orderDetails.dialog__addressConfirmation"
                    defaultMessage="Address confirmation"
                />
            </Typography>
            <Typography gutterBottom>
                <FormattedMessage
                    id="orderDetails.dialog__address"
                    defaultMessage="You entered the following address: {address}"
                    values={{
                        address: formatAddress(address),
                    }}
                />
            </Typography>
            <Typography gutterBottom>
                {suggestions && suggestions.length ? (
                    <FormattedMessage
                        id="orderDetails.dialog__suggestionFound"
                        defaultMessage="We found these address suggestions for you. Please select an address or confirm the current one."
                    />
                ) : (
                    <FormattedMessage
                        id="orderDetails.dialog__noSuggestionFound"
                        defaultMessage="We found no relevant suggestions. Please confirm you entered the correct address."
                    />
                )}
            </Typography>
            <List className={classes.dialog__list}>
                {suggestions &&
                    suggestions.map((item, i) => (
                        <ListItem
                            button
                            key={i}
                            onClick={() => onClick(item.raw)}
                        >
                            <Typography variant="body2">
                                {get(item, "formatted") ||
                                    formatAddress(item.raw)}
                            </Typography>
                        </ListItem>
                    ))}
            </List>
        </DialogContent>
        <DialogActions>
            <Button color="primary" onClick={() => onCancel()}>
                <FormattedMessage
                    id="orderDetails.dialog__editAddress"
                    defaultMessage="Edit current address"
                />
            </Button>
            <Button color="primary" onClick={() => onClose()}>
                <FormattedMessage
                    id="orderDetails.dialog__useAddress"
                    defaultMessage="Use current address"
                />
            </Button>
        </DialogActions>
    </Dialog>
)

AddressDialog.propTypes = {
    classes: PropTypes.object,
    suggestions: PropTypes.array,
}

AddressDialog.defaultProps = {
    classes: {},
    suggestions: [],
}

export default withStyles(styles)(AddressDialog)

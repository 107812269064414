import {
    ACCESSORIALS_LOAD,
    ACCESSORIALS_LOAD_ERROR,
    ACCESSORIALS_RESULT,
    ADD_DELIVERY_ACCESSORIAL,
    ADD_PICKUP_ACCESSORIAL,
    REMOVE_ACCESSORIAL,
} from "../actions/accessorial"

export function accessorials(
    state = {
        isFetching: false,
        isLoaded: false,
        list: {
            pickup: [],
            delivery: [],
            nonspecific: [],
            // TODO: this should be loaded from DB
            excessiveLength: [
                {
                    value: "ELS",
                    label: "Excessive Length Shipment",
                },
                {
                    value: "ELS12",
                    label: "Excessive Length, Over 12ft",
                },
                {
                    value: "ELS12_20",
                    label: "Excessive Length, 12 to 20ft",
                },
                {
                    value: "ELS12_24",
                    label: "Excessive Length, 12 to 24ft",
                },
                {
                    value: "ELS13_27",
                    label: "Excessive Length, 13 to 27ft",
                },
                {
                    value: "ELS14",
                    label: "Excessive Length, Over 14ft",
                },
                {
                    value: "ELS14_24",
                    label: "Excessive Length, 14 to 24ft",
                },
                {
                    value: "ELS14_26",
                    label: "Excessive Length, 14 to 26ft",
                },
                {
                    value: "ELS15",
                    label: "Excessive Length, Over 15ft",
                },
                {
                    value: "ELS15_27",
                    label: "Excessive Length, 15 to 27ft",
                },
                {
                    value: "ELS20",
                    label: "Excessive Length, Over 20ft",
                },
                {
                    value: "ELS20_28",
                    label: "Excessive Length, 20 to 28ft",
                },
                {
                    value: "ELS21",
                    label: "Excessive Length, Over 21ft",
                },
                {
                    value: "ELS24",
                    label: "Excessive Length, Over 24ft",
                },
                {
                    value: "ELS27",
                    label: "Excessive Length, Over 27ft",
                },
                {
                    value: "ELS28",
                    label: "Excessive Length, Over 28ft",
                },
                {
                    value: "ELS30",
                    label: "Excessive Length, Over 30ft",
                },
                {
                    value: "ELS8_12",
                    label: "Excessive Length, 8 to 12ft",
                },
            ],
            guaranteed: [
                {
                    value: "EXPEDITE17",
                    time: { hour: 17, minute: 0, second: 0 },
                },
                {
                    value: "EXPEDITEAM",
                    time: { hour: 12, minute: 0, second: 0 },
                },
                {
                    value: "GS8",
                    time: { hour: 8, minute: 0, second: 0 },
                },
                {
                    value: "GS9",
                    time: { hour: 9, minute: 0, second: 0 },
                },
                {
                    value: "GS10",
                    time: { hour: 10, minute: 0, second: 0 },
                },
                {
                    value: "GS11",
                    time: { hour: 11, minute: 0, second: 0 },
                },
                {
                    value: "GSAM",
                    time: { hour: 12, minute: 0, second: 0 },
                },
                {
                    value: "GS14",
                    time: { hour: 14, minute: 0, second: 0 },
                },
                {
                    value: "GS15",
                    time: { hour: 15, minute: 0, second: 0 },
                },
                {
                    value: "GS1530",
                    time: {
                        hour: 15,
                        minute: 30,
                        second: 0,
                    },
                },
                // NOTE: what time values should we use for these?
                {
                    value: "EXPEDITE",
                    time: { hour: 0, minute: 0, second: 0 },
                },
                {
                    value: "GURWIN",
                    time: { hour: 0, minute: 0, second: 0 },
                },
                {
                    value: "GSSING",
                    time: { hour: 0, minute: 0, second: 0 },
                },
                {
                    value: "GSMUL",
                    time: { hour: 0, minute: 0, second: 0 },
                },
                {
                    value: "GSFM",
                    time: { hour: 0, minute: 0, second: 0 },
                },
                {
                    value: "GUR",
                    time: { hour: 0, minute: 0, second: 0 },
                },
            ],
        },
    },
    action
) {
    switch (action.type) {
        case ACCESSORIALS_LOAD:
            return Object.assign({}, state, { isFetching: true })
        case ACCESSORIALS_RESULT:
            return Object.assign({}, state, {
                list: Object.assign(
                    {},
                    state.list,
                    action.accessorials.reduce((obj, element) => {
                        const innerObj = obj
                        if (!obj[element.category])
                            innerObj[element.category] = []
                        innerObj[element.category].push({
                            label: element.name,
                            value: element.key,
                        })
                        return innerObj
                    }, {})
                ),
                isFetching: false,
                isLoaded: true,
            })
        case ACCESSORIALS_LOAD_ERROR:
            return Object.assign({}, state, { isFetching: false })
        case ADD_PICKUP_ACCESSORIAL:
            return {
                ...state,
                list: {
                    ...state.list,
                    pickup: [action.accessorialToAdd, ...state.list.pickup],
                },
            }
        case ADD_DELIVERY_ACCESSORIAL:
            return {
                ...state,
                list: {
                    ...state.list,
                    delivery: [action.accessorialToAdd, ...state.list.delivery],
                },
            }
        case REMOVE_ACCESSORIAL:
            return {
                ...state,
                list: {
                    ...state.list,
                    delivery: state.list.delivery.filter(
                        a => a?.value !== action?.accessorialToRemove
                    ),
                    pickup: state.list.pickup.filter(
                        a => a?.value !== action?.accessorialToRemove
                    ),
                },
            }
        default:
            return state
    }
}

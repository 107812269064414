import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { FormattedMessage } from "react-intl"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import GlobalSpinner from "../../common/GlobalSpinner"
import Button from "@material-ui/core/Button"
import React, { Fragment, useEffect, useState } from "react"
import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { useCustomsProfileContext } from "../../../context/providers/CustomsProfileProvider"
import { withStyles } from "@material-ui/core/styles"
import { attachmentOptions } from "../../../misc"

const styles = theme => ({
    previewImage: {
        width: "100%",
    },
    previewImage__container: {
        height: "470px",
        borderLeft: "solid 1px #D4D4D4",
        padding: "5px",
    },
    details__container: {
        padding: "20px 10px 20px 0",
    },
    dialog__content: {
        overflowY: "auto",
    },
})

const PreviewContent = withStyles(styles)(
    ({ classes, document, imgSrc, isLoading, isError }) => {
        const categoryLabel = attachmentOptions.find(
            entry => document?.documentCategory === entry.value
        )

        const label = categoryLabel ? (
            <FormattedMessage {...categoryLabel.label} />
        ) : (
            document.documentCategory
        )
        return (
            <Fragment>
                <Grid
                    item
                    container
                    xs={4}
                    className={classes.details__container}
                >
                    <Grid item container>
                        <Grid item container direction="column">
                            <Grid item>
                                <Typography variant="h6">{label}</Typography>
                            </Grid>
                            {document?.customFilename && (
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        {document?.customFilename}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    xs={8}
                    justify="center"
                    className={classes.previewImage__container}
                >
                    {!isLoading && imgSrc && !isError && (
                        <Grid item container>
                            <img
                                className={classes.previewImage}
                                src={imgSrc}
                                alt={
                                    <FormattedMessage
                                        id="documents__unavailable"
                                        defaultMessage="Unavailable"
                                    />
                                }
                            />
                        </Grid>
                    )}
                    {!isLoading && (!imgSrc || isError) && (
                        <FormattedMessage
                            id="documents__unavailable"
                            defaultMessage="Unavailable"
                        />
                    )}
                    {isLoading && <GlobalSpinner isGlobal={false} />}
                </Grid>
            </Fragment>
        )
    }
)

const PreviewActions = ({ printFile }) => (
    <Grid item container justify="flex-end">
        <Grid item>
            <Button color="primary" variant="contained" onClick={printFile}>
                <FormattedMessage
                    id="documents.manage__print"
                    defaultMessage="Print"
                />
            </Button>
        </Grid>
    </Grid>
)

export const PreviewDocument = withStyles(styles)(
    ({ classes, onClose, locationId, baseDocument }) => {
        const [isLoading, setIsLoading] = useState(false)
        const [isError, setIsError] = useState(false)
        const [document, setDocument] = useState(null)
        const [imgSrc, setImgSrc] = useState(null)
        const { getDocument, printFile } = useCustomsProfileContext()

        useEffect(() => {
            const loadDocument = async () => {
                setIsLoading(true)
                try {
                    const document = await getDocument(
                        baseDocument?.documentCategory,
                        baseDocument?.customFilename,
                        "png"
                    )
                    setDocument(document)
                    if (document?.content !== null) {
                        setImgSrc(`data:image/png;base64,${document?.content}`)
                    }
                } catch (error) {
                    setIsError(true)
                } finally {
                    setIsLoading(false)
                }
            }
            loadDocument()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [locationId, baseDocument])

        const onPrintFile = () =>
            printFile(
                baseDocument?.documentCategory,
                baseDocument?.customFilename
            )

        return (
            <Fragment>
                <DialogTitle>
                    <Grid
                        item
                        container
                        justify="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h4">
                            <FormattedMessage
                                id="documents.manage__title"
                                defaultMessage="Manage Document"
                            />
                        </Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.dialog__content}>
                    <Grid item container>
                        <PreviewContent
                            document={baseDocument}
                            imgSrc={imgSrc}
                            isError={isError}
                            isLoading={isLoading}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <PreviewActions
                        document={document}
                        printFile={onPrintFile}
                    />
                </DialogActions>
            </Fragment>
        )
    }
)

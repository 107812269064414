import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import PersonPinIcon from "@material-ui/icons/PersonPin"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    person__pin: {
        width: "30px",
        height: "30px",
    },
    serviceCenter: {
        paddingRight: "4px",
    },
    terminalInfo__container: {
        paddingBottom: "10px",
    },
})

const TerminalInfo = ({
    terminal,
    classes,
    reverse,
    isIntraMexico,
    dayOfTheWeek,
    isPickup,
}) => (
    <Grid
        item
        container
        alignItems="center"
        justifyContent={reverse ? "flex-end" : "flex-start"}
        className={classes.terminalInfo__container}
    >
        {!reverse && (
            <Grid item alignItems="center">
                <PersonPinIcon className={classes.person__pin} />
            </Grid>
        )}
        <Grid item alignContent="center">
            <Grid
                item
                container
                alignItems="center"
                justifyContent={reverse ? "flex-end" : "flex-start"}
            >
                <Typography variant="caption" className={classes.serviceCenter}>
                    {isPickup ? (
                        <FormattedMessage
                            id="generalTerms__pickupCenter"
                            defaultMessage="Pickup Service Center"
                        />
                    ) : (
                        <FormattedMessage
                            id="generalTerms__destinationCenter"
                            defaultMessage="Destination Service Center"
                        />
                    )}
                    :
                </Typography>
                <Typography variant="body2">
                    {" "}
                    {` ${terminal.location}`}{" "}
                </Typography>
            </Grid>
            <Grid
                item
                container
                alignItems="center"
                justifyContent={reverse ? "flex-end" : "flex-start"}
            >
                {isIntraMexico && reverse && (
                    <Typography variant="body2">
                        <FormattedMessage
                            id="generalTerms__pickupCenter__intramexico_short"
                            defaultMessage="US 866.232.2942 | MX 800.232.2940"
                        />
                    </Typography>
                )}
                {isIntraMexico && !reverse && dayOfTheWeek !== 6 && (
                    <Typography variant="body2">
                        <FormattedMessage
                            id="generalTerms__pickupCenter__intramexico"
                            defaultMessage="1.866.232.2942 (from the U.S.)
                            or 01.800.232.2940 (from Mexico)"
                        />
                    </Typography>
                )}

                {isIntraMexico && !reverse && dayOfTheWeek === 6 && (
                    <Typography variant="body2">
                        <FormattedMessage
                            id="generalTerms__pickupCenter__intramexico_saturday"
                            defaultMessage="To schedule a pickup for Saturday, please contact customer service at 1.866.232.2942 (from the U.S.) or 01.800.232.2940 (from Mexico)"
                        />
                    </Typography>
                )}

                {!isIntraMexico && (
                    <Typography variant="body2">
                        {" "}
                        {terminal.contact?.phone} Ext. 3{" "}
                    </Typography>
                )}
            </Grid>
        </Grid>
        {reverse && (
            <Grid item alignItems="center" justifyContent="center">
                <PersonPinIcon className={classes.person__pin} />
            </Grid>
        )}
    </Grid>
)

export default withStyles(styles)(TerminalInfo)

import React from "react"
import PropTypes from "prop-types"
import { Controller } from "react-hook-form"
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormHelperText,
} from "@material-ui/core"

const FormSelect = ({
    width = 100,
    required,
    name,
    control,
    label,
    defaultValue = "",
    rules = {},
    options = [],
    ...props
}) => {
    return (
        <Controller
            defaultValue={defaultValue}
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState: { error } }) => (
                <FormControl
                    error={!!error}
                    style={{ width: `${width}%`, paddingRight: "10px" }}
                    fullWidth
                >
                    <InputLabel>{label}</InputLabel>
                    <Select
                        required={required}
                        defaultValue={defaultValue}
                        {...field}
                        label={label}
                        {...props}
                    >
                        {options.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                        {error ? error.message : ""}
                    </FormHelperText>
                </FormControl>
            )}
        />
    )
}

FormSelect.propTypes = {
    options: PropTypes.array.isRequired,
    width: PropTypes.number,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    label: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    required: PropTypes.bool,
    rules: PropTypes.object,
}

export default FormSelect

import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import {
    FormLabel,
    FormHelperText,
    FormControlLabel,
    FormControl,
    RadioGroup,
    Radio,
} from "@material-ui/core"
import FormattedError from "./formatted-error"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const FormRadio = ({
    input,
    label,
    options,
    meta: { touched, error },
    disabled,
    dataTestId,
    required,
    radioClasses,
    formControlLabelClasses,
    category,
    ...rest
}) => {
    const { logGAEvent } = useGAContext()
    return (
        <div
            className={classnames({
                "radio-field": true,
                "form-field-touched": touched,
                "form-field-invalid": touched && error,
                "form-field-valid": !error,
            })}
        >
            <FormControl
                component="fieldset"
                error={!!(touched && error)}
                disabled={disabled}
            >
                <FormLabel
                    component="legend"
                    id={`${input.name}-label`}
                    style={{ fontSize: "12px", paddingLeft: "3px" }}
                >
                    {label}
                </FormLabel>
                <RadioGroup
                    {...input}
                    {...rest}
                    row
                    style={{ paddingLeft: "3px" }}
                    onChange={(e, v) => {
                        if (input.onChange) {
                            input.onChange(e, v)
                        }
                        logGAEvent(
                            category,
                            `Radio Field Update: ${label?.props?.name ??
                                input.name}`,
                            v
                        )
                    }}
                >
                    {options.map((option, index) => (
                        <FormControlLabel
                            key={`${option.label}-${index}`}
                            value={option.value}
                            classes={formControlLabelClasses}
                            control={
                                <Radio
                                    disabled={option.disabled}
                                    inputProps={{ dataTestId }}
                                    checked={option.checked}
                                    id={`${input.name}-radio-${index}`}
                                    classes={radioClasses}
                                />
                            }
                            id={`${input.name}-radio-label-${index}`}
                            label={option.label}
                        />
                    ))}
                </RadioGroup>
                {touched && error && (
                    <FormHelperText>
                        <FormattedError error={error} />
                    </FormHelperText>
                )}
            </FormControl>
        </div>
    )
}

FormRadio.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    meta: PropTypes.shape({
        // eslint-disable-next-line react/no-unused-prop-types
        touched: PropTypes.bool,
        // eslint-disable-next-line react/no-unused-prop-types
        error: PropTypes.string,
    }),
    options: PropTypes.array.isRequired,
    category: PropTypes.string,
}

FormRadio.defaultProps = {
    meta: {},
    label: undefined,
    input: undefined,
}

export default FormRadio

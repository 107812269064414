import { makeStyles } from "@material-ui/core"

export const styles = theme => ({
    itemPage__container: {
        padding: theme.spacing(4),
        paddingRight: theme.spacing(6),
        paddingLeft: theme.spacing(6),
        minWidth: "898px",
        maxWidth: "1600px",
        margin: "0 auto",
    },

    itemPage__title: {
        marginBottom: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
})

export const useStyles = makeStyles({
    table_row: {
        height: "60px",
    },
})

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { reset, change } from "redux-form"
import {
    Drawer,
    IconButton,
    Grid,
    Typography,
    Toolbar,
    AppBar,
    Card,
    Button,
    Modal,
    SnackbarContent,
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import ListIcon from "@material-ui/icons/List"
import ContactsIcon from "@material-ui/icons/Contacts"
import ShipmentIcon from "@material-ui/icons/LocalShipping"
import DashboardIcon from "@material-ui/icons/Dashboard"
import BusinessIcon from "@material-ui/icons/Business"
import FolderOpenIcon from "@material-ui/icons/FolderOpen"
import Badge from "@material-ui/core/Badge"
import Popover from "@material-ui/core/Popover"
import NotificationsIcon from "@material-ui/icons/Notifications"
import { get } from "lodash"
import Iframe from "react-iframe"
import { connect } from "react-redux"
import DrawerListItem from "./drawerListItem"
import UserMenu from "./userMenu"
import NavAlert from "../alerts/navAlert"
import GlobalSearch from "../search"
import { withToken } from "../util"
import GlobalSpinner from "../common/GlobalSpinner"
import { changePath, checkAPIConnection, pushPath } from "../../actions"
import { dismissAlert, fetchAlerts } from "../../actions/alerts"
import { FormattedMessage } from "react-intl"
import { withRouter } from "react-router-dom"
import { trackGAEvent } from "../../actions/user"
import moment from "moment"
import { useFlags } from "launchdarkly-react-client-sdk"
import { requestShipmentsWithFilters } from "../../actions/track"
import { requestQuotesWithFilters } from "../../actions/quote-request"
import { OfflineBolt, Receipt } from "@material-ui/icons"

const GACategory = "Navigation"

function TabContainer({ children }) {
    return (
        <Typography variant="body2" component="div" style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    )
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
}

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    submitLoader: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    hidden: {
        display: "none",
    },
    appFrame: {
        marginTop: "64px",
        paddingTop: "8px",
    },
    appBar: {
        paddingLeft: "10px",
    },
    nav_tabs: {
        marginLeft: "10px",
    },
    logo_tab: {
        opacity: "1 !important",
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 px",
        ...theme.mixins.toolbar,
    },
    quote_text: {
        color: "white",
    },
    rate__button: {
        marginLeft: "18px",
    },
    notifications__icon: {
        fontSize: "33px",
        "&:hover": {
            color: theme.palette.secondary.main,
            cursor: "pointer",
        },
        "&:active": {
            color: theme.palette.secondary.light,
            cursor: "pointer",
        },
    },
    impersonation_warning: {
        backgroundColor: theme.palette.error.dark,
        position: "absolute",
        top: "42px",
        left: "35%",
    },
    badge: {
        badge: {
            top: 1,
            right: -15,
        },
    },
    alerts__Popover: {
        width: "520px",
        maxHeight: "605px",
        "overflow-y": "scroll",
    },
    hide: {
        visibility: "hidden",
    },
    paper: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1),
        top: "50%",
        left: "50%",
        width: "80%",
        height: "80%",
    },
    "@media print": {
        root: {
            display: "none",
        },
    },
})

const LTLSelectNavigation = ({
    classes,
    onHome,
    path,
    dismissAlert,
    alertList,
    profile,
    shouldDisplay,
    isAccountActive,
    impersonatedEmail,
    onQuickRate,
    onBookShipment,
    onTrailerManifest,
    onAlert,
    onRateAndShip,
    onDisplay,
    trackGA,
}) => {
    const [state, setState] = useState({
        drawerOpen: false,
        anchorEl: null,
        systemStatusModalOpen: false,
        systemStatusLoaderShow: false,
        apiConnected: true,
    })

    const { getRatesCutoffDate, createTrailerManifest } = useFlags()

    const handleAlertsOpen = event => {
        setState(prevState => ({
            ...prevState,
            anchorEl: event.currentTarget,
        }))
    }

    const handleAlertsClose = () => {
        setState(prevState => ({
            ...prevState,
            anchorEl: null,
        }))
    }

    const handleDrawerOpen = () => {
        setState(prevState => ({
            ...prevState,
            drawerOpen: true,
        }))
    }

    const handleDrawerClose = () => {
        setState(prevState => ({
            ...prevState,
            drawerOpen: false,
        }))
    }

    const systemStatusOpen = () => {
        setState(
            ...prevState => ({
                ...prevState,
                systemStatusModalOpen: true,
                systemStatusLoaderShow: true,
            })
        )
    }

    const systemStatusClose = () => {
        setState(prevState => ({
            ...prevState,
            systemStatusModalOpen: false,
            systemStatusLoaderShow: false,
        }))
    }

    const statusLoaderHide = () => {
        setState(prevState => ({ ...prevState, systemStatusLoaderShow: false }))
    }

    const mapUrlToValue = url => {
        if (/^\/home/.test(url)) {
            return 0
        }
        if (/^\/book/.test(url)) {
            return 1
        }
        if (/^\/quickRate/.test(url)) {
            return 2
        }

        if (/^\/trailerManifest/.test(url)) {
            return 3
        }

        if (/^\/orders/.test(url)) {
            return 4
        }

        if (/^\/items/.test(url)) {
            return 5
        }
        if (/^\/address/.test(url)) {
            return 6
        }
        if (/^\/locations/.test(url)) {
            return 7
        }
        return false
    }

    useEffect(() => {
        const determineDisplay = async () => {
            if (!shouldDisplay) {
                const apiConnected = await onDisplay()
                setState({ apiConnected })
            }
        }

        determineDisplay()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldDisplay])

    const handleAlertClick = shipmentId => {
        onAlert(shipmentId)
        handleAlertsClose()
    }

    const {
        anchorEl,
        systemStatusModalOpen,
        systemStatusLoaderShow,
        apiConnected,
        drawerOpen,
    } = state
    if (!shouldDisplay) return null
    const userCreatedDate =
        moment(profile?.created_at).format("YYYY-MM-DD") ?? "2018-01-01"
    const open = Boolean(anchorEl)
    const selectedIndex = mapUrlToValue(path)
    const locations = get(profile, "locations", [])
    const canCreateRates =
        isAccountActive &&
        (locations || []).filter(
            loc =>
                get(loc, "users[0].permissions.createRates.value") &&
                !get(loc, "users[0].permissions.suspended.value")
        ).length > 0

    const canCreateShipments =
        isAccountActive &&
        (locations || []).filter(
            loc =>
                get(loc, "users[0].permissions.createShipments.value") &&
                !get(loc, "users[0].permissions.suspended.value")
        ).length > 0

    const canManageOrders =
        isAccountActive &&
        (locations || []).filter(
            loc =>
                get(loc, "users[0].permissions.manageOrders.value") &&
                !get(loc, "users[0].permissions.suspended.value")
        ).length > 0

    const drawerList = [
        {
            label: (
                <FormattedMessage
                    id="navBar.menu__dashboard"
                    defaultMessage="Dashboard"
                />
            ),
            renderIcon: () => <DashboardIcon />,
            url: "/home",
            onClick: () => {
                handleDrawerClose()
                onHome(false)
            },
        },
        {
            label: (
                <FormattedMessage
                    id="navBar.menu__bookShipment"
                    defaultMessage="Book Shipment"
                />
            ),
            disabled: !canCreateRates || !canCreateShipments,
            renderIcon: () => <ShipmentIcon />,
            url: "/book",
            onClick: () => {
                handleDrawerClose()
                onBookShipment(false)
            },
        },
        {
            label: (
                <FormattedMessage
                    id="navBar__quickRate"
                    defaultMessage="Quick Rate"
                />
            ),
            disabled: !canCreateRates,
            renderIcon: () => <OfflineBolt />,
            url: "/quickRate",
            onClick: () => {
                handleDrawerClose()
                onQuickRate(false)
            },
        },
        {
            label: (
                <FormattedMessage
                    id="navBar.menu__trailerManifest"
                    defaultMessage="Trailer Manifest"
                />
            ),
            disabled: !createTrailerManifest,
            renderIcon: () => <Receipt />,
            url: "/trailerManifest",
            onClick: () => {
                handleDrawerClose()
                onTrailerManifest(false)
            },
        },
        {
            label: (
                <FormattedMessage id="navBar__orders" defaultMessage="Orders" />
            ),
            disabled: !canManageOrders,
            renderIcon: () => <FolderOpenIcon />,
            url: "/orders",
            onClick: () => {
                trackGA(GACategory, "Side Nav Click", "Orders")
                handleDrawerClose()
            },
        },
        {
            label: (
                <FormattedMessage
                    id="navBar.menu__items"
                    defaultMessage="Items"
                />
            ),
            renderIcon: () => <ListIcon />,
            url: "/items",
            onClick: () => {
                handleDrawerClose()
                trackGA(GACategory, "Side Nav Click", "Items")
            },
        },
        {
            label: (
                <FormattedMessage
                    id="navBar.menu__contacts"
                    defaultMessage="Contacts"
                />
            ),
            renderIcon: () => <ContactsIcon />,
            url: "/address",
            onClick: () => {
                handleDrawerClose()
                trackGA(GACategory, "Side Nav Click", "Contacts")
            },
        },
        {
            label: (
                <FormattedMessage
                    id="navBar.menu__locations"
                    defaultMessage="Locations"
                />
            ),
            renderIcon: () => <BusinessIcon />,
            url: "/locations",
            onClick: () => {
                handleDrawerClose()
                trackGA(GACategory, "Side Nav Click", "Locations")
            },
        },
    ]

    return (
        <div>
            <div className={classes.root}>
                {!apiConnected && (
                    <Grid xs={12} item className={classes.appFrame}>
                        <Typography variant="h5" color="error" align="center">
                            <FormattedMessage
                                id="navBar__error"
                                defaultMessage="The website is not able to connect to the server. Please check your Internet connection or contact your administrator."
                            />
                        </Typography>
                    </Grid>
                )}
                {path !== "/user/signup" && (
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar>
                            {isAccountActive > 0 && (
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    id="navigationMenu"
                                    onClick={handleDrawerOpen}
                                >
                                    <MenuIcon />
                                </IconButton>
                            )}
                            <Drawer
                                onClose={handleDrawerClose}
                                open={drawerOpen}
                            >
                                <div
                                    onClick={handleDrawerClose}
                                    onKeyDown={handleDrawerClose}
                                >
                                    <div className={classes.drawerHeader}>
                                        <Card
                                            tyle={{
                                                width: "100%",
                                            }}
                                        />
                                    </div>
                                    {drawerList.map((item, index) => {
                                        if (item.disabled) {
                                            return <div key={index} />
                                        }
                                        return (
                                            <DrawerListItem
                                                key={`drawer-list-item-${index}`}
                                                renderIcon={item.renderIcon}
                                                label={item.label}
                                                url={item.url}
                                                onClick={item.onClick}
                                                selected={
                                                    selectedIndex === index
                                                }
                                            />
                                        )
                                    })}
                                </div>
                            </Drawer>
                            <Grid container>
                                <Grid item container xs={2}>
                                    <Button
                                        onClick={() => onHome(true)}
                                        id="navigation__homeButton"
                                    >
                                        <img
                                            alt="LTL Select"
                                            src="static/LTLselect-logo-type-new.png"
                                        />
                                    </Button>
                                </Grid>
                                <Grid item container xs={5} alignItems="center">
                                    {canCreateRates && canCreateShipments && (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => onBookShipment(true)}
                                            className={classes.rate__button}
                                            id="bookShipmentButton"
                                        >
                                            <FormattedMessage
                                                id="navBar__bookShipment"
                                                defaultMessage="Book Shipment"
                                            />
                                        </Button>
                                    )}
                                    {canCreateRates && (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => onQuickRate(true)}
                                            className={classes.rate__button}
                                            id="quickRateButton"
                                        >
                                            <FormattedMessage
                                                id="navBar__quickRate"
                                                defaultMessage="Quick Rate"
                                            />
                                        </Button>
                                    )}
                                    {createTrailerManifest && (
                                        <Button
                                            onClick={() =>
                                                onTrailerManifest(true)
                                            }
                                            variant="contained"
                                            color="secondary"
                                            className={classes.rate__button}
                                            id="createTrailerManifestButton"
                                        >
                                            <FormattedMessage
                                                id="navBar__createManifest"
                                                defaultMessage="Create Manifest"
                                            />
                                        </Button>
                                    )}
                                    {moment(userCreatedDate).isBefore(
                                        getRatesCutoffDate
                                    ) && canCreateRates ? (
                                        <Button
                                            variant="text"
                                            color="secondary"
                                            onClick={() => onRateAndShip(true)}
                                            className={classes.rate__button}
                                            id="getRatesButton"
                                        >
                                            <FormattedMessage
                                                id="navBar__legacyRates"
                                                defaultMessage="Get Rates"
                                            />
                                        </Button>
                                    ) : null}
                                </Grid>

                                <Grid
                                    item
                                    container
                                    xs={3}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    {isAccountActive && <GlobalSearch />}
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={1}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Badge
                                        badgeContent={alertList.length}
                                        color="secondary"
                                        classes={{
                                            badge: classes.badge,
                                        }}
                                        className={
                                            !(alertList.length > 0) &&
                                            classes.hide
                                        }
                                        overlap="rectangular"
                                    >
                                        <NotificationsIcon
                                            className={
                                                classes.notifications__icon
                                            }
                                            onClick={e => handleAlertsOpen(e)}
                                        />
                                    </Badge>
                                    <Popover
                                        open={open && alertList.length > 0}
                                        onClose={handleAlertsClose}
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center",
                                        }}
                                    >
                                        <Grid
                                            className={classes.alerts__Popover}
                                        >
                                            {alertList.map((alert, index) => (
                                                <NavAlert
                                                    dismissAlert={dismissAlert}
                                                    onClick={handleAlertClick}
                                                    alert={alert}
                                                />
                                            ))}
                                        </Grid>
                                    </Popover>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={1}
                                    alignItems="center"
                                    justifyContent="flex-end"
                                >
                                    {isAccountActive && (
                                        <UserMenu
                                            systemStatusOpen={systemStatusOpen}
                                        />
                                    )}
                                </Grid>
                                {impersonatedEmail && (
                                    <SnackbarContent
                                        className={
                                            classes.impersonation_warning
                                        }
                                        message={
                                            <FormattedMessage
                                                id="warning__impersonation"
                                                defaultMessage="Impersonating {impersonatedEmail}, please take extra care when performing actions with side effects"
                                                values={{
                                                    impersonatedEmail,
                                                }}
                                            />
                                        }
                                    />
                                )}
                            </Grid>
                        </Toolbar>
                    </AppBar>
                )}
                <Modal open={systemStatusModalOpen} onClose={systemStatusClose}>
                    <div className={classes.paper}>
                        {systemStatusLoaderShow && <GlobalSpinner />}
                        <Grid item container justifyContent="flex-end">
                            <Grid item>
                                <IconButton onClick={systemStatusClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Iframe
                            url="https://p.datadoghq.com/sb/808b01da2-6f6347decae93e056eaca36c5115c488"
                            id="systemHealth"
                            display="initial"
                            onLoad={() => statusLoaderHide()}
                            position="relative"
                            allowFullScreen
                        />
                    </div>
                </Modal>
            </div>
        </div>
    )
}

LTLSelectNavigation.propTypes = {
    classes: PropTypes.object.isRequired,
    // theme: PropTypes.object.isRequired,
}

const mapStateToProps = (state, props) => ({
    path: get(props, "history.location.pathname"),
    alertList: get(state, "alerts.alertList"),
    impersonatedEmail: get(state, "impersonation.email"),
    profile: get(state, "user.profile", null),
    shouldDisplay:
        !get(state, "authorization.initFinished") ||
        get(state, "authorization.isFinished") ||
        get(state, "shareStatus.shareKey"),
    isAccountActive: get(state, "user.preferences.accountActive"),
})

const mapDispatchToProps = (dispatch, props) => ({
    onRateAndShip: isNavBar => {
        dispatch(changePath("/rate"))
        if (props.path === "/rate") dispatch(reset("rate"))
        if (isNavBar) {
            dispatch(trackGAEvent(GACategory, "Legacy Rates"))
        }
    },
    onQuickRate: isNavBar => {
        dispatch(pushPath("/quickRate"))
        if (props?.history?.location?.pathname === "/quickRate") {
            dispatch(reset("quickRate"))
            dispatch(change("bookShipment", "shouldReset", true))
            dispatch(change("quickRate", "shouldReset", true))
        }
        dispatch(reset("bookShipment"))
        if (isNavBar) {
            dispatch(trackGAEvent(GACategory, "Top Nav Click", "Quick Rate"))
        } else {
            dispatch(trackGAEvent(GACategory, "Side Nav Click", "Quick Rate"))
        }
    },
    onBookShipment: isNavBar => {
        dispatch(pushPath("/book"))
        if (props?.history?.location?.pathname === "/book") {
            dispatch(reset("bookShipment"))
            dispatch(change("bookShipment", "shouldReset", true))
            dispatch(change("quickRate", "shouldReset", true))
        }
        dispatch(reset("quickRate"))
        if (isNavBar) {
            dispatch(trackGAEvent(GACategory, "Top Nav Click", "Book Shipment"))
        } else {
            dispatch(
                trackGAEvent(GACategory, "Side Nav Click", "Book Shipment")
            )
        }
    },
    onTrailerManifest: isNavBar => {
        dispatch(pushPath("/trailerManifest"))
        if (isNavBar) {
            dispatch(
                trackGAEvent(GACategory, "Top Nav Click", "Trailer Manifest")
            )
        } else {
            dispatch(
                trackGAEvent(GACategory, "Side Nav Click", "Trailer Manifest")
            )
        }
    },
    onItems: () => {
        dispatch(changePath("/items"))
    },
    onContacts: () => {
        dispatch(changePath("/address"))
    },
    onHome: isNavBar => {
        if (props?.history?.location?.pathname !== "/home") {
            dispatch(requestShipmentsWithFilters())
            dispatch(requestQuotesWithFilters())
        }
        dispatch(changePath("/home"))
        if (isNavBar) {
            if (props?.history?.location?.pathname === "/home") {
                dispatch(
                    trackGAEvent(
                        GACategory,
                        "LTL Select Home Button Click From Dashboard (No Refetch)"
                    )
                )
            } else {
                dispatch(
                    trackGAEvent(
                        GACategory,
                        "LTL Select Home Button Click (Refetch Shipments and Quotes)"
                    )
                )
            }
        } else {
            dispatch(trackGAEvent(GACategory, "Side Nav Click", "Dashboard"))
        }
    },
    onAlert: shipmentId => {
        dispatch(changePath(`/track/${shipmentId}`))
    },
    onDisplay: async () => {
        dispatch(fetchAlerts())
        return dispatch(checkAPIConnection())
    },
    dismissAlert: (shipmentId, alertId) =>
        dispatch(dismissAlert(shipmentId, alertId)),
    trackGA: (category, action, value) =>
        dispatch(trackGAEvent(category, action, value)),
})

export const NavAppBar = withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(withToken(LTLSelectNavigation))
    )
)

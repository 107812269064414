import React from "react"
import AdminPage from "../components"

const title = "Offshore Gateway Table"
const urlPath = `offshoregateway`
const sampleFile = "import_offshoregateway_sample.xlsx"

const tableHeaders = [
    {
        id: "destination",
        type: "text",
        align: "left",
        label: "Destination",
    },
    {
        id: "serviceCenter",
        type: "text",
        align: "right",
        label: "Service Center",
    },
    {
        id: "priority",
        type: "text",
        align: "right",
        label: "Priority Gateway",
    },
    {
        id: "economy",
        type: "text",
        align: "right",
        label: "Economy Gateway",
    },
]

const OffshoreGatewayAdmin = () => {
    return (
        <>
            <AdminPage
                title={title}
                urlPath={urlPath}
                sampleFile={sampleFile}
                tableHeaders={tableHeaders}
                enableAccountNumbers={false}
            />
        </>
    )
}

export default OffshoreGatewayAdmin

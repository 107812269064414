import React, { Fragment } from "react"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import { Chip, Button } from "@material-ui/core"
import { Field } from "redux-form"
import FormSelect from "../form/form-select"
import FormSwitch from "../form/form-switch"
import { FormattedMessage } from "react-intl"
import { preferenceLanguageOptions } from "../constants/preferenceLanguageOptions"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const styles = theme => ({
    alert__icon: {
        marginTop: "15px",
    },
    fields__container: {
        padding: "8px",
        marginTop: "5px",
    },
    fields__container__alternate: {
        padding: "8px",
        marginTop: "16px",
    },
    selectAll__button: {
        marginLeft: "5px",
    },
    chip: {
        margin: "10px 5px",
    },
    fields__switches: {
        marginTop: "10px",
    },
})

function AlertsRecipientFields(props) {
    const {
        classes,
        share,
        chooseWarnings,
        subject,
        handleDelete,
        index,
        handleSelectAll,
        disableAlertPanel,
        disabledFields,
        isFedExShipment,
        gaCategory,
    } = props
    const size = chooseWarnings ? 6 : 11
    const { logGAEvent } = useGAContext()

    return (
        <Grid container className={classes.fields__container__alternate}>
            <Grid item container alignItems="center">
                {share && (
                    <Grid item container alignItems="center">
                        <Grid item container alignItems="center" spacing={3}>
                            <Chip
                                label={subject}
                                onDelete={() => {
                                    logGAEvent(
                                        gaCategory,
                                        "Notification: Recipient Remove Click"
                                    )
                                    handleDelete(index, subject)
                                }}
                                className={classes.chip}
                                disabled={disableAlertPanel}
                            />
                            <Button
                                className={classes.selectAll__button}
                                variant="outlined"
                                color="secondary"
                                size="small"
                                onClick={() => {
                                    logGAEvent(
                                        gaCategory,
                                        "Notification-Recipient: Select All Click"
                                    )
                                    handleSelectAll(subject)
                                }}
                                disabled={
                                    disableAlertPanel ||
                                    disabledFields.disableSelectAll
                                }
                            >
                                <FormattedMessage
                                    id="alertsPreferences__all"
                                    defaultMessage="All"
                                />
                            </Button>
                        </Grid>
                    </Grid>
                )}
                <Grid
                    item
                    container
                    xs={size}
                    className={classes.fields__switches}
                >
                    <Field
                        name={`alerts["${subject}"].language`}
                        component={FormSelect}
                        label={
                            <FormattedMessage
                                id="alertsPreferences__language"
                                defaultMessage="Language"
                            />
                        }
                        options={preferenceLanguageOptions}
                        category={gaCategory}
                    />
                </Grid>
                {isFedExShipment && (
                    <Grid
                        item
                        container
                        xs={size}
                        className={classes.fields__switches}
                    >
                        <Field
                            name={`alerts["${subject}"].fedExDirectNotifications`}
                            component={FormSwitch}
                            label={
                                <FormattedMessage
                                    id="alertsPreferences__fedExNotifications"
                                    defaultMessage="FedEx Notification Emails"
                                />
                            }
                            disabled={
                                disableAlertPanel ||
                                disabledFields.disableDispatched
                            }
                            category={gaCategory}
                        />
                    </Grid>
                )}
                <Grid
                    item
                    container
                    xs={size}
                    className={classes.fields__switches}
                >
                    <Field
                        name={`alerts["${subject}"].dispatched`}
                        component={FormSwitch}
                        label={
                            <FormattedMessage
                                id="alertsPreferences__dispatched"
                                defaultMessage="Dispatched"
                            />
                        }
                        disabled={
                            disableAlertPanel ||
                            disabledFields.disableDispatched
                        }
                        category={gaCategory}
                    />
                </Grid>
                {chooseWarnings && (
                    <Fragment>
                        <Grid
                            item
                            container
                            xs={size}
                            className={classes.fields__switches}
                        >
                            <Field
                                name={`alerts["${subject}"].warning`}
                                component={FormSwitch}
                                label={
                                    <FormattedMessage
                                        id="alertsPreferences__alerts"
                                        defaultMessage="Alerts"
                                    />
                                }
                                disabled={
                                    disableAlertPanel ||
                                    disabledFields.disableAlerts
                                }
                                category={gaCategory}
                            />
                        </Grid>
                    </Fragment>
                )}
            </Grid>
            <Grid item container>
                <Grid
                    item
                    container
                    xs={size}
                    className={classes.fields__switches}
                >
                    <Field
                        name={`alerts["${subject}"].pickedUp`}
                        component={FormSwitch}
                        label={
                            <FormattedMessage
                                id="alertsPreferences__pickedUp"
                                defaultMessage="Picked Up"
                            />
                        }
                        disabled={
                            disableAlertPanel || disabledFields.disablePickedUp
                        }
                        category={gaCategory}
                    />
                </Grid>
            </Grid>
            <Grid item container>
                <Grid
                    item
                    container
                    xs={size}
                    className={classes.fields__switches}
                >
                    <Field
                        name={`alerts["${subject}"].outForDelivery`}
                        component={FormSwitch}
                        label={
                            <FormattedMessage
                                id="alertsPreferences__outForDelivery"
                                defaultMessage="Out For Delivery"
                            />
                        }
                        disabled={
                            disableAlertPanel ||
                            disabledFields.disableOutForDelivery
                        }
                        category={gaCategory}
                    />
                </Grid>
            </Grid>
            <Grid item container>
                <Grid
                    item
                    container
                    xs={size}
                    className={classes.fields__switches}
                >
                    <Field
                        name={`alerts["${subject}"].delivered`}
                        component={FormSwitch}
                        label={
                            <FormattedMessage
                                id="alertsPreferences__delivered"
                                defaultMessage="Delivered"
                            />
                        }
                        disabled={
                            disableAlertPanel || disabledFields.disableDelivered
                        }
                        category={gaCategory}
                    />
                </Grid>
            </Grid>
            <Grid item container>
                <Grid
                    item
                    container
                    xs={size}
                    className={classes.fields__switches}
                >
                    <Field
                        name={`alerts["${subject}"].podImageAvailable`}
                        component={FormSwitch}
                        label={
                            <FormattedMessage
                                id="alertsPreferences__podImageAvailable"
                                defaultMessage="POD Image Available"
                            />
                        }
                        disabled={disableAlertPanel}
                        category={gaCategory}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(AlertsRecipientFields)

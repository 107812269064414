import { goFetch } from "../http"

export const getFastestFedexQuote = async payload => {
    const { data } = await goFetch(
        "/share/fedex?fastest=true",
        {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "cache-control": "no-cache",
            },
            data: payload,
        },
        true
    )
    return data
}

import React, { useEffect, useRef, useState } from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import OriginSummary from "../summaryCards/OriginSummary"
import SummaryButtons from "../atoms/SummaryButtons"
import NextButton from "../atoms/NextButton"
import { Field, reduxForm } from "redux-form"
import FormField from "../../../form/form-field"
import { FormattedMessage } from "react-intl"
import { makeStyles } from "@material-ui/styles"
import Typography from "@material-ui/core/Typography"
import { supportedCountryCodes } from "../../../../misc"
import { Map } from "../../../util"
import Accessorials from "../atoms/Accessorials"
import { Popper } from "@material-ui/core"
import { SuggestionBox } from "../atoms/SuggestionBox"
import { Element, scroller } from "react-scroll"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import FormZipCode from "../../../form/form-zip-code"
import { bookShipmentFormValidation } from "../validators"
import InBondDialog from "../atoms/InBondDialog"
import normalizePhone from "../../../util/normalizePhone"
import moment from "moment"
import NextDayPickUpDialog from "../atoms/NextDayPickUpDialog"
import formSelectAutocomplete from "../../../form/form-select-autocomplete"
import {
    addAccessorial,
    removeAccessorial,
} from "../../../../actions/accessorial"
import { connect } from "react-redux"
import { useSnackbarContext } from "../../../../context/providers/snackbarProvider"
import { closeModal, openModal } from "../../../../actions/modal"
import ConfirmActionModal from "../../../common/ConfirmActionModal"
import { useFlags } from "launchdarkly-react-client-sdk"

const useStyles = makeStyles({
    section: {
        paddingTop: "10px",
    },
    accessorialsContainer: {
        paddingLeft: "8px",
    },
    mapContainer: {
        minHeight: "284px",
        maxHeight: "388px",
    },
    formContainer: {
        paddingRight: "8px",
    },
    mainInformation: {
        paddingBottom: "14px",
    },
    pickupInformation: {
        minHeight: "213px",
    },
    popper: {
        zIndex: 100,
    },
    searchContact: {
        paddingRight: "15px",
    },
})

const GA_CATEGORY = "Book Shipment - Origin"

const Origin = ({
    handleComplete,
    handleEdit,
    handleUpdate,
    handleCancelEdit,
    currentStep,
    editMode,
    formValues = {},
    performContactSearch,
    contactSearchInProgress,
    changeField,
    initialValues,
    cities = [],
    pickupAccessorialsList,
    invalid,
    textInputProps,
    isNotAllowedInBondCountryModal,
    proccedWithOutInbondModal,
    cancelInbondModal,
    invalidInBondCountry,
    isBookingQuickRate,
    isControlledFlow,
    setIsFreightBoxDialogOpen,
    IntercomAPI,
    requireNextDayPickUpModal,
    inputProps,
    addAccessorial,
    removeAccessorial,
    checkLoadedAKSpecialCases,
    openModal,
    closeModal,
    alaskaSpecialHandlingMessageMap,
    requiredAccessorialList,
}) => {
    const { enableGoogleMapRating, domesticOffshoreCustomerPud } = useFlags()

    useEffect(() => {
        if (currentStep === 1) {
            IntercomAPI("trackEvent", "book-shipment-origin")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const customerPickupAccessorial = {
            value: "CUSTPU",
            label: {
                "en-us": "Customer Dock Delivery Charge",
                "es-mx": "Cargo de entrega del muelle del cliente",
                "fr-ca": "Frais de livraison au quai du client",
            },
        }
        if (
            formValues?.origin?.shippingAddress?.address?.state === "PR" &&
            !domesticOffshoreCustomerPud
        ) {
            addAccessorial(true, customerPickupAccessorial)
        } else if (hasAccessorial(customerPickupAccessorial.value)) {
            removeAccessorial(customerPickupAccessorial?.value)
        }
    }, [
        formValues?.origin?.shippingAddress?.address?.state,
        domesticOffshoreCustomerPud,
    ])

    const {
        origin,
        isFreightDirect,
        isFreightBox,
        isFreightDirectReturns,
        proNumberSearchResult,
        mode,
        pickupDate,
    } = formValues

    const beforeNextStep = () => {}
    const classes = useStyles()
    const [showOverlay, setShowOverlay] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [shouldShowForm, setShouldShowForm] = useState(!!isBookingQuickRate)
    const originCompanyNameRef = useRef(null)
    const isPickUpToday = moment(pickupDate).isSame(moment(), "day")
    const setDefaultCountry = destination => {
        if (destination === "MX") {
            return "MX"
        } else {
            return "US"
        }
    }
    const isAKShipment =
        origin?.shippingAddress?.address?.state === "AK" &&
        origin?.shippingAddress?.address?.country === "US"

    const { openSnackbar } = useSnackbarContext()

    useEffect(() => {
        if (currentStep === 1) {
            scroller.scrollTo("originTitle", {
                duration: 1000,
                smooth: true,
                offset: -140,
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode])

    useEffect(() => {
        if (
            mode === "outbound" ||
            isBookingQuickRate ||
            !isControlledFlow ||
            initialValues?.isReturnMode
        ) {
            setShouldShowForm(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, isBookingQuickRate, isControlledFlow])

    const handleInputFocus = (field, e) => {
        if (field === "searchContact") {
            setShowOverlay(true)
            setAnchorEl(e.currentTarget)
        }
    }

    const hasAccessorial = accessorialCode => {
        return pickupAccessorialsList.some(
            acc => acc?.value === accessorialCode
        )
    }

    const handleOptionSelect = (option = {}) => {
        if (isFreightBox && option?.address.country !== "US") {
            setIsFreightBoxDialogOpen(true)
            changeField("origin", {
                searchContact: "",
                shippingAddress: {
                    address: {
                        country:
                            initialValues?.origin?.shippingAddress?.address
                                ?.country,
                    },
                },
            })
            setShouldShowForm(true)
            return
        }
        setShowOverlay(false)
        setAnchorEl(null)
        changeField("origin", {
            shippingAddress: {
                address: {
                    country:
                        initialValues?.origin?.shippingAddress?.address
                            ?.country,
                },
            },
        })
        setShouldShowForm(true)
        changeField("origin", {
            shippingAddress: {
                address: {
                    city: option?.address.city,
                    state: option?.address.state,
                    street1: option?.address.street1,
                    street2: option?.address.street2,
                    country: option?.address.country,
                    postalCode: option?.address.postalCode,
                },
                name: option?.name,
            },
            readyTime: option?.contact?.pickupContact?.readyTime,
            closeTime: option?.contact?.pickupContact?.closeTime,
            pickupContact: {
                name: option?.contact?.pickupContact?.name,
                phone: {
                    phone_number:
                        option?.contact?.pickupContact?.phone?.phoneNumber,
                    extension: option?.contact?.pickupContact?.phone?.extension,
                },
                email: {
                    email_address:
                        option?.contact?.pickupContact?.email?.emailAddress,
                },
            },
            contact: {
                name: option?.contact?.name,
                phone: {
                    phone_number: option?.contact?.phone?.phoneNumber,
                    extension: option?.contact?.phone?.extension,
                },
                email: {
                    email_address: option?.contact?.email?.emailAddress,
                },
            },
        })
        if (option?.pickupAccessorials?.length) {
            changeField(
                "pickupAccessorials",
                option?.pickupAccessorials.map(entry => entry.value) ?? []
            )
        } else {
            changeField("pickupAccessorials", [])
        }

        if (originCompanyNameRef.current) {
            originCompanyNameRef.current.focus()
        }
    }

    const handleClickAway = () => {
        if (showOverlay) {
            setShowOverlay(false)
            setAnchorEl(null)
        }
    }

    const disabledNextButton =
        invalidInBondCountry || invalid || !origin?.contact

    if (currentStep === 1) {
        return (
            <Grid item container>
                <InBondDialog
                    isDialogOpen={isNotAllowedInBondCountryModal}
                    proccedWithOutInbondModal={proccedWithOutInbondModal}
                    cancelInbondModal={cancelInbondModal}
                    invalidCountry={origin?.shippingAddress?.address?.country}
                />
                <NextDayPickUpDialog
                    isDialogOpen={requireNextDayPickUpModal && isPickUpToday}
                    inputProps={inputProps}
                />
                <Popper
                    open={showOverlay}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: "scrollParent",
                        },
                    }}
                    className={classes.popper}
                >
                    <ClickAwayListener
                        mouseEvent="onMouseDown"
                        touchEvent="onTouchStart"
                        onClickAway={() => handleClickAway()}
                    >
                        <div>
                            <SuggestionBox
                                searchInProgress={contactSearchInProgress}
                                addressesNeeded
                                field={"origin"}
                                term={origin?.searchContact ?? ""}
                                handleAddressSelect={handleOptionSelect}
                                noCities
                            />
                        </div>
                    </ClickAwayListener>
                </Popper>

                <Grid item container justify="center">
                    <Typography variant="h6">
                        <FormattedMessage
                            id="bookShipment.basicInfo__origin"
                            defaultMessage="Origin Information"
                        />
                    </Typography>
                    <Element name="originTitle" />
                </Grid>
                <Grid item container className={classes.section}>
                    <Grid
                        item
                        container
                        xs={7}
                        className={classes.formContainer}
                    >
                        <Grid
                            item
                            container
                            className={classes.mainInformation}
                            alignContent="flex-start"
                        >
                            {formValues?.mode !== "outbound" && (
                                <Grid item container alignItems="flex-end">
                                    <Grid
                                        item
                                        container
                                        xs={9}
                                        className={classes.searchContact}
                                    >
                                        <Field
                                            name="origin.searchContact"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__mainSearch"
                                                    defaultMessage="Search for a Contact"
                                                />
                                            }
                                            category={GA_CATEGORY}
                                            component={FormField}
                                            onChange={e => {
                                                setShowOverlay(true)
                                                setAnchorEl(e.currentTarget)
                                                performContactSearch(
                                                    e.target.value,
                                                    origin?.shippingAddress
                                                        ?.address?.postalCode
                                                )
                                            }}
                                            autoFocus
                                            onFocus={e =>
                                                handleInputFocus(
                                                    "searchContact",
                                                    e
                                                )
                                            }
                                            InputProps={textInputProps}
                                            disableBrowserAutocomplete
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={3}
                                        justify="center"
                                        alignItems="flex-start"
                                    >
                                        <Button
                                            id={"originAddNewContact"}
                                            color="primary"
                                            variant="outlined"
                                            onClick={() => {
                                                changeField("origin", {
                                                    shippingAddress: {
                                                        address: {
                                                            country: setDefaultCountry(
                                                                formValues
                                                                    ?.selectedLocation
                                                                    ?.address
                                                                    ?.country
                                                            ),
                                                        },
                                                    },
                                                })
                                                changeField(
                                                    "pickupAccessorials",
                                                    []
                                                )
                                                setShouldShowForm(true)
                                            }}
                                        >
                                            <FormattedMessage
                                                id="generalTerms__addNew"
                                                defaultMessage="Add New"
                                            />
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                            {shouldShowForm || proNumberSearchResult ? (
                                <Grid item container>
                                    <Grid item container>
                                        <Field
                                            name="origin.shippingAddress.name"
                                            required
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__companyName"
                                                    defaultMessage="Company Name"
                                                />
                                            }
                                            category={GA_CATEGORY}
                                            component={FormField}
                                            inputRef={originCompanyNameRef}
                                            autoFocus={shouldShowForm}
                                        />
                                    </Grid>
                                    <Grid item container xs={8}>
                                        <Field
                                            name="origin.shippingAddress.address.street1"
                                            required
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__address1"
                                                    defaultMessage="Address 1"
                                                />
                                            }
                                            disabled={
                                                formValues?.mode === "outbound"
                                            }
                                            component={FormField}
                                            category={GA_CATEGORY}
                                        />
                                    </Grid>
                                    <Grid item container xs={4}>
                                        <Field
                                            name="origin.shippingAddress.address.street2"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__address2"
                                                    defaultMessage="Address 2"
                                                />
                                            }
                                            disabled={
                                                formValues?.mode === "outbound"
                                            }
                                            component={FormField}
                                            category={GA_CATEGORY}
                                        />
                                    </Grid>
                                    <Grid item container xs={3}>
                                        <Field
                                            name="origin.shippingAddress.address.postalCode"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__zipPostalCode"
                                                    defaultMessage="Zip/Postal Code"
                                                />
                                            }
                                            disabled={
                                                formValues?.mode === "outbound"
                                            }
                                            component={FormZipCode}
                                            form="bookShipment"
                                            country={
                                                formValues?.origin
                                                    ?.shippingAddress?.address
                                                    ?.country
                                            }
                                        />
                                    </Grid>
                                    <Grid item container xs={5}>
                                        <Field
                                            name="origin.shippingAddress.address.city"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__city__selectOrEnter"
                                                    defaultMessage="City (Select or Enter)"
                                                />
                                            }
                                            component={formSelectAutocomplete}
                                            options={cities.map(c => ({
                                                value: c,
                                                label: c,
                                            }))}
                                            customValue
                                        />
                                    </Grid>
                                    <Grid item container xs={2}>
                                        <Field
                                            name="origin.shippingAddress.address.state"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__state"
                                                    defaultMessage="State"
                                                />
                                            }
                                            disabled={
                                                formValues?.mode === "outbound"
                                            }
                                            component={FormField}
                                            category={GA_CATEGORY}
                                            inputProps={{ maxLength: 2 }}
                                        />
                                    </Grid>

                                    <Grid item container xs={2}>
                                        <Field
                                            component={formSelectAutocomplete}
                                            name="origin.shippingAddress.address.country"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__country"
                                                    defaultMessage="Country"
                                                />
                                            }
                                            disabled={
                                                formValues?.mode ===
                                                    "outbound" || isFreightBox
                                            }
                                            options={supportedCountryCodes}
                                        />
                                    </Grid>
                                    <Grid item container>
                                        <Field
                                            name="origin.contact.name"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__contactName"
                                                    defaultMessage="Contact Name"
                                                />
                                            }
                                            component={FormField}
                                            category={GA_CATEGORY}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item container xs={4}>
                                        <Field
                                            name="origin.contact.phone.phone_number"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__contactPhone"
                                                    defaultMessage="Contact Phone"
                                                />
                                            }
                                            component={FormField}
                                            category={GA_CATEGORY}
                                            normalize={normalizePhone(
                                                formValues?.origin
                                                    ?.shippingAddress?.address
                                                    ?.country
                                            )}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item container xs={2}>
                                        <Field
                                            name="origin.contact.phone.extension"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__extension"
                                                    defaultMessage="Extension"
                                                />
                                            }
                                            component={FormField}
                                            category={GA_CATEGORY}
                                        />
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Field
                                            name="origin.contact.email.email_address"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__contactEmail"
                                                    defaultMessage="Contact Email"
                                                />
                                            }
                                            component={FormField}
                                            category={GA_CATEGORY}
                                            required={true}
                                        />
                                    </Grid>
                                    {!isFreightDirect &&
                                        !isFreightDirectReturns && (
                                            <Grid
                                                item
                                                container
                                                alignContent="flex-start"
                                            >
                                                <Accessorials
                                                    category={GA_CATEGORY}
                                                    list={
                                                        pickupAccessorialsList
                                                    }
                                                    name={"pickupAccessorials"}
                                                    formName="bookShipment"
                                                    formValues={formValues}
                                                    permanentList={
                                                        requiredAccessorialList
                                                    }
                                                />
                                            </Grid>
                                        )}
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={5}
                        className={classes.accessorialsContainer}
                    >
                        <Grid item container className={classes.mapContainer}>
                            {enableGoogleMapRating && (
                                <Map
                                    addresses={[
                                        origin?.shippingAddress?.address,
                                    ]}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                {editMode ? (
                    <SummaryButtons
                        handleCancelEdit={handleCancelEdit}
                        handleUpdate={() => {
                            if (isAKShipment) {
                                const specialCase = checkLoadedAKSpecialCases(
                                    origin?.shippingAddress?.address
                                )
                                switch (specialCase?.specialHandling) {
                                    case "Charter Service Only *":
                                        openModal(
                                            <ConfirmActionModal
                                                message={
                                                    alaskaSpecialHandlingMessageMap[
                                                        specialCase
                                                            .specialHandling
                                                    ]
                                                }
                                                title={
                                                    <FormattedMessage
                                                        id="origin_specialHandling_charterServiceOnly_title"
                                                        defaultMessage="Charter Service Only"
                                                    />
                                                }
                                                handleConfirm={() =>
                                                    closeModal()
                                                }
                                            />
                                        )
                                        break
                                    case "Not Serviced":
                                        openSnackbar(
                                            "error",
                                            alaskaSpecialHandlingMessageMap[
                                                specialCase.specialHandling
                                            ],
                                            3000
                                        )
                                        break
                                    default:
                                        beforeNextStep()
                                        handleComplete()
                                }
                            } else {
                                beforeNextStep()
                                handleUpdate()
                            }
                        }}
                        invalid={disabledNextButton}
                    />
                ) : (
                    <NextButton
                        handleComplete={isInvalidNextButton => {
                            if (isInvalidNextButton) {
                                setShouldShowForm(true)
                                handleComplete(isInvalidNextButton)
                            } else if (isAKShipment) {
                                const specialCase = checkLoadedAKSpecialCases(
                                    origin?.shippingAddress?.address
                                )
                                switch (specialCase?.specialHandling) {
                                    case "Charter Service Only *":
                                        openModal(
                                            <ConfirmActionModal
                                                message={
                                                    alaskaSpecialHandlingMessageMap[
                                                        specialCase
                                                            .specialHandling
                                                    ]
                                                }
                                                title={
                                                    <FormattedMessage
                                                        id="origin_specialHandling_charterServiceOnly_title"
                                                        defaultMessage="Charter Service Only"
                                                    />
                                                }
                                                handleConfirm={() =>
                                                    closeModal()
                                                }
                                            />
                                        )
                                        break
                                    case "Not Serviced":
                                        openSnackbar(
                                            "error",
                                            alaskaSpecialHandlingMessageMap[
                                                specialCase.specialHandling
                                            ],
                                            3000
                                        )
                                        break
                                    default:
                                        beforeNextStep()
                                        handleComplete()
                                }
                            } else {
                                beforeNextStep()
                                handleComplete()
                            }
                        }}
                        invalid={disabledNextButton}
                    />
                )}
            </Grid>
        )
    } else if (currentStep > 1) {
        return (
            <OriginSummary
                pickupAccessorialsList={pickupAccessorialsList}
                handleEdit={handleEdit}
                formValues={formValues}
            />
        )
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    addAccessorial: (isPickup, accessorial) =>
        dispatch(addAccessorial(isPickup, accessorial)),
    removeAccessorial: accessorial => dispatch(removeAccessorial(accessorial)),
    openModal: async node => dispatch(openModal(node)),
    closeModal: async () => dispatch(closeModal()),
})

export default reduxForm({
    // a unique name for the form
    form: "bookShipment",
    validate: bookShipmentFormValidation,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(connect(null, mapDispatchToProps)(Origin))

import React, { useEffect, useState } from "react"
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@material-ui/core"
import numeral from "numeral"
import { get } from "lodash"
import { rateSchema, chargeSchema, discountSchema, matchSchema } from "./schema"

const PricingTableSimple = ({
    classes,
    defaultPricing,
    customPricing,
    rowValues,
}) => {
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        if (!defaultPricing) return
        const tableData = []
        for (let i = 0; i < rowValues.length; i++) {
            const rowData = getRowValues(rowValues[i])
            tableData.push(rowData)
        }
        setTableData(tableData)
    }, [defaultPricing, customPricing])

    const getRowValues = ({ title, value }) => {
        const rowData = [{ value: title, isEdited: false, isDisabled: false }]

        const customValue = get(customPricing, value)
        const defaultValue = get(defaultPricing, value)

        if (customValue) {
            let cellValue = ""
            if (customValue?.isPercentageOff) {
                cellValue = {
                    isPercentageOff: false,
                    value: defaultValue?.value * (1 - customValue.value / 100),
                }
            } else {
                cellValue = customValue
            }
            rowData.push({
                value: formatCellValue(cellValue),
                isDisabled: customValue?.isDisabled,
                isEdited: true,
            })
        } else {
            rowData.push({
                value: formatCellValue(defaultValue),
                isDisabled: false,
                isEdited: false,
            })
        }

        return rowData
    }

    const formatCellValue = cellObj => {
        if (typeof cellObj === "string" || typeof cellObj === "number") {
            return cellObj
        } else if (typeof cellObj === "object") {
            if (matchSchema(rateSchema, cellObj)) {
                if (cellObj?.isDisabled) {
                    return "N/A"
                } else {
                    return numeral(cellObj?.value).format("$0,0.00")
                }
            } else if (matchSchema(chargeSchema, cellObj)) {
                return numeral(cellObj?.value).format("$0,0.00")
            } else if (matchSchema(discountSchema, cellObj)) {
                if (!cellObj?.value) {
                    return "---"
                } else if (cellObj?.isPercentage) {
                    return numeral(cellObj?.value / 100).format("0.0%")
                } else {
                    return numeral(cellObj?.value).format("$0,0.00")
                }
            }
            return "---"
        } else {
            return "---"
        }
    }

    return (
        <Table>
            <TableBody>
                {tableData.map(rowData => {
                    return (
                        <TableRow
                            item
                            container
                            xs={10}
                            className={classes.table_row}
                        >
                            {rowData.map((tableCell, index) => {
                                return (
                                    <TableCell
                                        align={index === 0 ? "left" : "center"}
                                    >
                                        <Typography
                                            variant="body"
                                            className={
                                                tableCell.isEdited
                                                    ? classes.cell_edited
                                                    : null
                                            }
                                        >
                                            {tableCell.value}
                                        </Typography>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}

export default PricingTableSimple

export const styles = theme => ({
    card: {
        minHeight: "401px",
    },
    card__action: {
        margin: 0,
    },
    fixed__dialog: {
        width: "100%",
    },
    card__content: {
        overflowY: "auto",
        height: "333px",
    },

    toggleFilter: {
        paddingBottom: "10px",
        minWidth: "250px",
    },
    selectedFilter: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: "white !important",
        height: "inherit",
        pointerEvents: "none",
    },
    baseFilter: {
        color: "black",
        height: "inherit",
    },

    cell_disabled: {
        background: "lightGray",
        color: "gray",
    },
    cell_edited: {
        color: `${theme.palette.secondary.main}`,
        fontWeight: "bold",
    },
    cell_strikethrough: {
        textDecoration: "line-through",
    },
    table_row: {
        height: "40px",
    },
    modal: {
        padding: theme.spacing(3),
    },
    formControl: {
        minWidth: "200px",
    },
    gatewayFieldContainer: {
        minHeight: "50%",
    },
    fieldRowGateway: {
        paddingBottom: "15px",
        paddingTop: "15px",
        borderBottom: "1px solid grey",
        justifyContent: "space-between",
        flexDirection: "row",
    },
    buttonGroup: {
        display: "flex",
        justifyContent: "space-between",
    },
    input__number: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },
    fieldRow: {
        paddingBottom: "15px",
        paddingTop: "15px",
        justifyContent: "space-between",
        flexDirection: "row",
    },
})

const customAccessorialMessages = {
    deprecatedDeliveryLabelMainland: {
        "en-us": "Customer Dock Pickup Charge",
        "es-mx": "Cargo por recogida en el muelle del cliente",
        "fr-ca": "Frais de ramassage au quai du client",
    },
    deliveryLabelMainland: {
        "en-us": "Customer Dock Drop at US Gateway",
        "es-mx": "Entrega por cliente en el muelle de EE. UU",
        "fr-ca": "Frais de ramassage au quai du client",
    },
    deliveryLabelOffshore: {
        "en-us": "Customer Dock Drop at Offshore Facility",
        "es-mx":
            "Descarga en muelle en instalaciones de alta mar por el cliente",
        "fr-ca": "Frais de dépôt au quai du client",
    },
    pickupLabelOffshore: {
        "en-us": "Customer Dock Pickup at Offshore Facility",
        "es-mx":
            "Recogida en muelle en instalaciones de alta mar por el cliente",
        "fr-ca": "Frais de ramassage au quai du client",
    },
}

export const deprecatedDeliveryAccessorial = {
    value: "CUSTDEL",
    label: customAccessorialMessages.deprecatedDeliveryLabelMainland,
}

export const customerDeliveryAccessorial = {
    value: "CUSTDEL",
    label: customAccessorialMessages.deliveryLabelMainland,
}

export const customerPickupAccessorialOffshore = {
    value: "CUSTPUOFF",
    label: customAccessorialMessages.pickupLabelOffshore,
}

export const customerDeliveryAccessorialOffshore = {
    value: "CUSTDELOFF",
    label: customAccessorialMessages.deliveryLabelOffshore,
}

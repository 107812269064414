import React from "react"
import { Button, Typography } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { FormattedMessage } from "react-intl"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/styles"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    paper: {
        minWidth: "650px",
    },

    select__container: {
        width: "100%",
    },
    dialog__container: {},
    select__field: {
        width: "100%",
    },
    resiChips: {
        paddingTop: "10px",
    },
    resiCheckDescription2: {
        paddingTop: "15px",
    },
    icon__close: {
        marginTop: "-12px",
    },
})

const InBondDialog = ({
    proccedWithOutInbondModal,
    isDialogOpen,
    cancelInbondModal,
    invalidCountry,
}) => {
    const classes = useStyles()
    return (
        <Dialog
            open={isDialogOpen}
            classes={{
                paper: classes.paper,
            }}
        >
            <DialogTitle id="in-bond-dialog-title">
                <Grid container item direction="row">
                    <Grid item container xs={12} justify="space-between">
                        <Grid item>
                            <FormattedMessage
                                id="getRates__in__bond__title"
                                defaultMessage="In-Bond Check"
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            xs={2}
                            justify="flex-end"
                            alignItems="flex-start"
                        >
                            <IconButton
                                id="closeInBondDialog"
                                onClick={cancelInbondModal}
                                className={classes.icon__close}
                            >
                                <Grid alignContent="end">
                                    <CloseIcon id="closeIconInBondDialog" />
                                </Grid>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container item xs={10}></Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container justify="center" direction="row">
                    <Grid item container xs={12}>
                        <Grid item container>
                            <Typography variant="body2">
                                <FormattedMessage
                                    id="getRates__in__bond__description"
                                    defaultMessage="LTLSelect does not support in-bond rates or shipments when shipping to or from {country}."
                                    values={{
                                        country: invalidCountry,
                                    }}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    id="cancelInBondDialog"
                    onClick={cancelInbondModal}
                    color="secondary"
                    variant="outlined"
                >
                    <FormattedMessage
                        id="generalTerms__cancel"
                        defaultMessage="Cancel"
                    />
                </Button>
                <Button
                    id="continueInBondDialog"
                    onClick={proccedWithOutInbondModal}
                    color="primary"
                    variant="contained"
                >
                    <FormattedMessage
                        id="getRates__in__bond__confirm"
                        defaultMessage="Proceed without In-Bond"
                    />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default InBondDialog

import converter from "json-2-csv"
import FileSaver from "file-saver"

export function downloadJSONAsCSV(data, name) {
    return async () => {
        const csvData = await converter.json2csvAsync(data, {
            emptyFieldValue: "",
        })

        const blob = new Blob([csvData], {
            type: "text/csv;charset=utf-8",
        })
        FileSaver.saveAs(blob, `${name || "document"}.csv`)
    }
}

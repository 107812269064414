const PuertoRicoInitialValues = (customPuertoRicoPricing = {}) => {
    const customOceanPricing = customPuertoRicoPricing?.oceanCharges ?? []
    const desiredGatewayOrder = ["JAX", "MDW", "SJU"]
    const defaultGateway = gatewayValue => {
        return {
            gateway: gatewayValue,
            minimumCharge: { value: null, isPercentageOff: false },
            oceanGrossDiscount: { value: null, isPercentage: false },

            ratePerCuFt: { value: null, isDisabled: false },
            ratePerCwt: { value: null, isDisabled: false },
        }
    }
    const nullGateways = desiredGatewayOrder.map(gateway =>
        defaultGateway(gateway)
    )

    const mergedOceanCharges = nullGateways.map(nullGateway => {
        const customPricingMatch = customOceanPricing.find(
            customPricing => customPricing.gateway === nullGateway.gateway
        )
        let customMatchWithDefaults = {}
        if (customPricingMatch) {
            customMatchWithDefaults = {
                ...customPricingMatch,
                minimumCharge: customPricingMatch?.minimumCharge
                    ? customPricingMatch.minimumCharge
                    : nullGateway.minimumCharge,
                oceanGrossDiscount: customPricingMatch?.oceanGrossDiscount
                    ? customPricingMatch?.oceanGrossDiscount
                    : nullGateway.oceanGrossDiscount,
            }
        }

        return customPricingMatch ? customMatchWithDefaults : nullGateway
    })

    return {
        ...customPuertoRicoPricing,
        oceanCharges: mergedOceanCharges,
    }
}

export default PuertoRicoInitialValues

import {
    Button,
    Grid,
    IconButton,
    Typography,
    withStyles,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { FormattedMessage } from "react-intl"
import { Field, reduxForm } from "redux-form"
import { combineValidators, isRequired } from "revalidate"
import formField from "../form/form-field"
import FormSelectBillTo from "../form/form-select-bill-to"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const styles = theme => ({
    paper: {
        position: "absolute",
        left: 0,
        right: 0,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        top: 0,
        bottom: 0,
        width: "900px",
        minHeight: "300px",
        height: "fit-content",
        maxHeight: "700px",
        marginTop: "auto",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "auto",
    },
    section: {
        paddingTop: "10px",
    },
    buttonContainer: {
        flexGrow: 1,
        justifyContent: "flex-end",
    },
    mainContainer: {
        minHeight: "300px",
    },
    errorsContainer: {
        maxHeight: "400px",
        overflowY: "scroll",
        paddingTop: "10px",
    },
    buttonGroup: {
        display: "flex",
        flexDirection: "row",
    },
    continueButton: {
        margin: "0 3px",
    },
    errorEntry: {
        backgroundColor: theme.palette.error.dark,
        color: "white",
        padding: "4px 8px",
        marginBottom: "8px",
        fontWeight: 500,
        borderRadius: "4px",
    },
    causeMessage: {
        whiteSpace: "pre-wrap",
    },
})

const CreateManifestModal = ({
    classes,
    formattedLocations,
    handleSubmit,
    invalid,
    setCreateModalOpen,
    onContinue,
    gaCategory,
}) => {
    const { logGAEvent } = useGAContext()
    return (
        <form onSubmit={handleSubmit(onContinue)}>
            <Grid item container className={classes.paper}>
                <Grid
                    item
                    container
                    direction="column"
                    alignContent="flex-start"
                    className={classes.mainContainer}
                >
                    <Grid item container justify="space-between">
                        <Typography variant="h4">
                            <FormattedMessage
                                id="trailerManifest.createModal__title"
                                defaultMessage="Create Manifest by Location"
                            />
                        </Typography>
                        <Grid item>
                            <IconButton
                                onClick={() => {
                                    setCreateModalOpen(false)
                                    logGAEvent(
                                        gaCategory,
                                        "Create Manifest Modal Close"
                                    )
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.section}>
                        <Field
                            component={FormSelectBillTo}
                            name="billToLocation"
                            label={
                                <FormattedMessage
                                    id="trailerManifest.form__selectLocation"
                                    defaultMessage="Select Location"
                                />
                            }
                            options={formattedLocations}
                            required={true}
                            category={gaCategory}
                        ></Field>
                    </Grid>

                    <Grid item container className={classes.section}>
                        <Field
                            component={formField}
                            name="trailerId"
                            label={
                                <FormattedMessage
                                    id="trailerManifest.form__trailerNumber"
                                    defaultMessage="Trailer Number"
                                />
                            }
                            required
                            category={gaCategory}
                        ></Field>
                    </Grid>

                    <Grid
                        item
                        container
                        alignItems="flex-end"
                        className={classes.buttonContainer}
                        justify="space-between"
                    >
                        <Grid item className={classes.buttonGroup}>
                            <Button
                                type="submit"
                                className={classes.continueButton}
                                variant="contained"
                                color="primary"
                                disabled={invalid}
                            >
                                <FormattedMessage
                                    id="generalTerms__confirm"
                                    defaultMessage="Confirm"
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

const validation = combineValidators({
    trailerId: isRequired({
        field: {
            id: "trailerId",
            defaultMessage: "Trailer Number",
        },
        billToLocation: isRequired({
            field: {
                id: "billToLocation",
                defaultMessage: "Location",
            },
        }),
    }),
})

export default withStyles(styles)(
    reduxForm({
        form: "trailerManifest", // a unique identifier for this form
        enableReinitialize: true,
        validate: validation,
    })(CreateManifestModal)
)

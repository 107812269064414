import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { get } from "lodash"
import { Modal, withStyles, Typography, Grid, Button } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { closeModal } from "../../actions/modal"
import {
    accountCreationSuccess,
    accountCreationPartialSuccess,
} from "../../messages/confirmation-constants"
import { addLocationError } from "../../messages/error-constants"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    button__container: {
        display: "flex",
        "align-items": "center",
        "margin-bottom": "20px",
    },
    button__close: {
        "margin-left": "auto",
        paddingRight: "4%",
    },
    modal__container: {
        top: "25%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
    },
    accountCreationSuccess__title: {
        paddingBottom: "10px",
    },
    text_container: {
        padding: "2%",
    },
})

const scheduleDemo = () => {
    window.open("https://meet.intercom.com/ltlselect")
}
const UserSignedupNotification = ({
    onCloseClick,
    classes,
    open,
    history,
    isShipperActive,
    is3rdPartyActive,
    locationType,
    isQuebecShipperAccount,
}) => (
    <Modal open={open} onClose={onCloseClick}>
        <Grid item xs={12} className={classes.modal__container} container>
            <Grid item container alignItems="center">
                {isShipperActive && is3rdPartyActive ? (
                    <Grid item container justify="center">
                        <Grid
                            item
                            container
                            alignItems="flex-end"
                            justify="flex-end"
                        >
                            <IconButton
                                className={classes.button__close}
                                onClick={e => {
                                    e.stopPropagation()
                                    onCloseClick(history)
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <Grid item container className={classes.text_container}>
                            <Grid
                                item
                                container
                                className={
                                    classes.accountCreationSuccess__title
                                }
                                justify="center"
                            >
                                <Typography variant="subtitle1">
                                    {accountCreationSuccess}
                                </Typography>
                            </Grid>
                            {locationType === "FEDEX_DIRECT" && (
                                <Typography variant="caption">
                                    <FormattedMessage
                                        id="locations__freightDirectMessage"
                                        defaultMessage="The account entered allows you to rate and ship with FedEx Freight Direct only. To be able to get rates with other carriers, please sign up using your FedEx Freight shipper or third party account."
                                    />
                                </Typography>
                            )}
                            {isQuebecShipperAccount && (
                                <Grid
                                    item
                                    container
                                    justify="center"
                                    alignItems="center"
                                >
                                    <Typography
                                        variant="body2"
                                        color="secondary"
                                    >
                                        <FormattedMessage
                                            id="locations__quebecShipperAccountWarning"
                                            defaultMessage="The account entered allows you to rate and ship with FedEx Freight only at this point."
                                        />
                                    </Typography>
                                </Grid>
                            )}
                            <Grid
                                item
                                container
                                justify="center"
                                className={classes.button__container}
                            >
                                <Button
                                    onClick={scheduleDemo}
                                    variant="outlined"
                                    color="primary"
                                >
                                    <FormattedMessage
                                        id="signup_scheduleDemo"
                                        defaultMessage="Schedule Demo"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item container>
                        <Typography variant="body2">
                            {accountCreationPartialSuccess(
                                isShipperActive ? "bill to" : "freight"
                            )}
                        </Typography>
                        <Typography variant="body2">
                            {addLocationError}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Grid>
    </Modal>
)

UserSignedupNotification.propTypes = {
    onCloseClick: PropTypes.func.isRequired,
    classes: PropTypes.object,
    open: PropTypes.bool.isRequired,
    is3rdPartyActive: PropTypes.bool.isRequired,
    isShipperActive: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
}

UserSignedupNotification.defaultProps = {
    classes: {},
}

const mapStateToProps = state => {
    const signupLocation = get(state, "user.profile.locations", []).find(
        location => {
            const userAccess = get(location, "users[0].access", {})
            return (
                userAccess.granted &&
                !userAccess.invited &&
                !userAccess.requested
            )
        }
    )

    const shippingState = signupLocation?.shippingAddress?.address?.state
    const shippingCountry = signupLocation?.shippingAddress?.address?.country

    return {
        open: state.modal.isOpen,
        is3rdPartyActive:
            !get(signupLocation, "fedexBillToAccount") ||
            get(signupLocation, "is3rdPartyEnabled"),
        isShipperActive:
            !get(signupLocation, "fedexFreightAccount") ||
            get(signupLocation, "isShipperEnabled"),
        locationType: signupLocation?.locationType,
        isQuebecShipperAccount:
            signupLocation?.isShipperEnabled &&
            shippingState === "QC" &&
            shippingCountry === "CA",
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    onCloseClick: () => {
        dispatch(closeModal())
    },
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(UserSignedupNotification)
)

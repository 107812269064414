import React from "react"
import { Button } from "@material-ui/core"
import { useStyles } from "./styles"
import { FormattedMessage } from "react-intl"

const BookViewButton = ({ order, column }) => {
    const classes = useStyles()
    const { isFulfilled } = order
    return (
        <Button
            variant="contained"
            className={classes.bookViewButton}
            color={isFulfilled ? "primary" : "secondary"}
            onClick={() => column.actionViewBook(order)}
        >
            {isFulfilled ? (
                <FormattedMessage
                    id="orders.viewShipment"
                    defaultMessage="View"
                />
            ) : (
                <FormattedMessage id="orders.book" defaultMessage="Book" />
            )}
        </Button>
    )
}

export default BookViewButton

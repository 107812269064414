import React from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import PaginationControls from "./PaginationControls"
import GlobalSpinner from "../GlobalSpinner"

export default class TablePagination extends React.Component {
    componentDidMount() {
        this.onPageChange(this.props.page, this.props.pageSize)
    }

    async onPageChange(page, pageSize = this.props.pageSize) {
        const {
            pageSize: oldSize,
            page: oldPage,
            adjustPagination,
        } = this.props
        adjustPagination(page, pageSize)

        try {
            const loadedPages = Math.floor(
                this.props.elements.length / pageSize
            )

            if (page >= loadedPages || page === 1) {
                await this.props.fetchElements(page * pageSize)
            }
        } catch (_) {
            this.setState({ pageSize: oldSize, page: oldPage })
            this.props.adjustPagination(oldPage, oldSize)
        }
    }

    onPageSizeChange(newPageSize) {
        const { pageSize, page } = this.props
        const totalPages = Math.ceil(this.props.totalElements / newPageSize)
        const currentRow = pageSize * page
        const newPage = Math.min(
            Math.ceil(currentRow / newPageSize),
            totalPages
        )
        this.props.savePageSize(newPageSize)
        this.onPageChange(newPage, newPageSize)
    }

    render() {
        const { elements, loadingTable, gaCategory } = this.props
        if (loadingTable)
            return (
                <Grid item xs={12} container>
                    <GlobalSpinner />
                </Grid>
            )
        if (!elements || !elements.length) {
            return this.props.noElement() || null
        }
        const indexOfLast = this.props.page * this.props.pageSize
        const indexOfFirst = indexOfLast - this.props.pageSize
        const elementsToDisplay = elements.slice(indexOfFirst, indexOfLast)
        return (
            <Grid item container>
                {this.props.render(elementsToDisplay, indexOfFirst)}
                <PaginationControls
                    onPageSizeChange={pageSize =>
                        this.onPageSizeChange(pageSize)
                    }
                    onPageChange={page => this.onPageChange(page)}
                    page={this.props.page}
                    pageSize={this.props.pageSize}
                    totalElements={this.props.totalElements}
                    showPageSizeOptions={this.props.showPageSizeOptions}
                    savePage={this.props.savePage}
                />
            </Grid>
        )
    }
}

TablePagination.propTypes = {
    elements: PropTypes.array.isRequired,
    render: PropTypes.func.isRequired,
    fetchElements: PropTypes.func.isRequired,
    savePageSize: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    showPageSizeOptions: PropTypes.bool,
    totalElements: PropTypes.number.isRequired,
    gaCategory: PropTypes.string,
}

TablePagination.defaultProps = {
    showPageSizeOptions: true,
}

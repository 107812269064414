const currencyLabelWithoutAmount = preferredCurrencyCode => {
    switch (preferredCurrencyCode) {
        case "USD":
            return "US$"
        case "CAD":
            return "CA$"
        case "MXN":
            return "MXN$"
        default:
            return "$"
    }
}

const currencyLabel = (preferredCurrencyCode, amount) =>
    currencyLabelWithoutAmount(preferredCurrencyCode) +
    Number(amount).toFixed(2)

export { currencyLabelWithoutAmount, currencyLabel }

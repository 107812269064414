import React from "react"
import Grid from "@material-ui/core/Grid"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"
import WarningIcon from "@material-ui/icons/Warning"
import { withStyles } from "@material-ui/core/styles"
import amber from "@material-ui/core/colors/amber"
import { FormattedMessage } from "react-intl"
import { isFedExCarrier } from "../../../misc"

const styles = theme => ({
    warning: {
        backgroundColor: amber[700],
        maxWidth: "100%",
    },
    icon: {
        backgroundColor: "white",
        color: amber[700],
    },
    warningText: {
        color: "black",
    },
})

const PickupWarning = ({
    isPastPickup,
    pickupLater,
    carrierCode,
    rateMode,
    country,
    classes,
}) => {
    const fedExVolumeWarning =
        isFedExCarrier(carrierCode) && rateMode === "VOLUME_LTL"

    return isPastPickup || (fedExVolumeWarning && pickupLater) ? (
        <SnackbarContent
            className={classes.warning}
            message={
                <Grid container xs={12} spacing={3} alignItems="center">
                    <Grid item>
                        <Avatar className={classes.icon}>
                            <WarningIcon className={classes.icon} />
                        </Avatar>
                    </Grid>
                    <Grid item xs>
                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            className={classes.warningText}
                        >
                            <FormattedMessage
                                id="orderDetails.pickup__Warning"
                                defaultMessage={"Warning"}
                            />
                        </Typography>
                        {isPastPickup && country !== "MX" && (
                            <Typography
                                gutterBottom
                                className={classes.warningText}
                            >
                                <FormattedMessage
                                    id="orderDetails.pickup__3PMWarning"
                                    defaultMessage={
                                        "Because it is after the pickup cutoff time of 3PM local time, " +
                                        "only the Bill of Lading (BOL) information will be processed. " +
                                        "Please ensure you have a pickup scheduled with the carrier."
                                    }
                                />
                            </Typography>
                        )}
                        {isPastPickup && country === "MX" && (
                            <Typography
                                gutterBottom
                                className={classes.warningText}
                            >
                                <FormattedMessage
                                    id="orderDetails.pickup__1PMWarning"
                                    defaultMessage={
                                        "Please note that the current time is now past our standard cutoff time " +
                                        "for scheduling a same-day pickup online: 1:00 PM If you need your shipment " +
                                        "picked up today, please contact international customer service in Mexico at 1-866-232-2942 (from the U.S.) or 800-232-2940 (from Mexico)."
                                    }
                                />
                            </Typography>
                        )}
                        {fedExVolumeWarning && (
                            <Typography
                                variant="body2"
                                className={classes.warningText}
                            >
                                <FormattedMessage
                                    id="orderDetails.pickup__VolumeWarning"
                                    defaultMessage={
                                        "FedEx volume service rates are time-sensitive: a volume service shipment must " +
                                        "be shipped within two business days after the rate request."
                                    }
                                />
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            }
        />
    ) : null
}

export default withStyles(styles)(PickupWarning)

import { combineValidators, composeValidators } from "revalidate"
import {
    countryPhoneValidator,
    emailValidator,
    isNumeric,
    isOneOf,
    isRequired,
    matchesPattern,
    mustBeWithinOneYear,
} from "../../../actions/validation"
import {
    contactDetailsFormMessages,
    contactDetailsValidation,
} from "../ContactDetails"
import { usmcaFormMessages } from "./messages"
import { documentFormMessages } from "../upload/UploadSuccessDialog"

const itemValidation = combineValidators({
    description: isRequired({
        field: usmcaFormMessages.goodDescription,
    }),
    harmonizedCode: composeValidators(
        isRequired,
        matchesPattern(/^\d{4}\.\d{2}(\.\d{2}(\d{2})?)?$/)
    )({
        field: usmcaFormMessages.goodHarmonizedTariffSchedule,
    }),
    originCriterion: isRequired({
        field: usmcaFormMessages.goodOriginCriterion,
    }),
    certificationIndicator: isRequired({
        field: usmcaFormMessages.goodCertificationIndicator,
    }),
    methodOfQualification: isRequired({
        field: usmcaFormMessages.goodMethodOfQualification,
    }),
    countryOfOrigin: isRequired({
        field: usmcaFormMessages.goodCountryOfOrigin,
    }),
})

const signatureValidation = combineValidators({
    companyName: isRequired({
        field: contactDetailsFormMessages.companyName,
    }),
    contactName: isRequired({
        field: contactDetailsFormMessages.contactName,
    }),
    title: isRequired({
        field: usmcaFormMessages.signatureTitle,
    }),
    date: isRequired({
        field: usmcaFormMessages.signatureDate,
    }),
    phone: {
        phone_number: composeValidators(
            isRequired,
            countryPhoneValidator(undefined, undefined, "US")
        )({
            field: contactDetailsFormMessages.phone,
        }),
        extension: isNumeric({
            field: contactDetailsFormMessages.extension,
        }),
    },
    email: {
        email_address: composeValidators(
            isRequired,
            emailValidator
        )({
            field: contactDetailsFormMessages.email,
        }),
    },
})

export const documentFieldValidation = combineValidators({
    documentCategory: isRequired({
        field: documentFormMessages.documentCategory,
    }),
    customFilename: isRequired({
        field: documentFormMessages.userFileName,
    }),
    userFileName: isRequired({
        field: documentFormMessages.userFileName,
    }),
})

export const filenameCollisionValidation = combineValidators({
    overwriteShipmentDocument: isOneOf([true])({
        field: usmcaFormMessages.overwriteShipmentDocument,
    }),
    overwriteProfileDocument: isOneOf([true])({
        field: usmcaFormMessages.overwriteShipmentDocument,
    }),
})

export const usmcaFormValidation = combineValidators({
    certifierInfo: contactDetailsValidation,
    producerInfo: contactDetailsValidation,
    exporterInfo: contactDetailsValidation,
    importerInfo: contactDetailsValidation,
    signatureInfo: signatureValidation,
    blanketPeriodFrom: isRequired({
        field: usmcaFormMessages.blanketPeriodFrom,
    }),
    blanketPeriodTo: composeValidators(
        isRequired,
        mustBeWithinOneYear("blanketPeriodFrom")
    )({
        field: usmcaFormMessages.blanketPeriodTo,
    }),
    "goods[]": itemValidation,
})

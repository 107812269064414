import React, { useState } from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { FormattedMessage } from "react-intl"
import { Field, formValues } from "redux-form"
import { makeStyles } from "@material-ui/styles"
import FormField from "../../../form/form-field"
import FormZipCode from "../../../form/form-zip-code"
import FormSelectAutocomplete from "../../../form/form-select-autocomplete"
import { supportedCountryCodes } from "../../../../misc"
import normalizePhone from "../../../util/normalizePhone"
import { Map } from "../../../util"
import { ClickAwayListener, Popper } from "@material-ui/core"
import { SuggestionBox } from "./SuggestionBox"

const useStyles = makeStyles({
    underline: {
        textDecoration: "underline",
        paddingBottom: "10px",
    },
    sectionMedium: {
        padding: "25px 8% 0 8%",
    },
    scrolling__list: {
        maxHeight: "333px",
        overflowY: "auto",
    },
    chip: {
        margin: "16px 8px",
    },
    chipTitle: {
        marginLeft: "16px",
    },
    divider: {
        margin: "16px 0",
    },
    newDocumentButton: {
        marginTop: "8px",
        marginBottom: "8px",
    },
    section: {
        paddingTop: "10px",
    },
    accessorialsContainer: {
        paddingLeft: "8px",
    },
    mapContainer: {
        minHeight: "284px",
        maxHeight: "388px",
    },
})

const TrueThirdPartyForm = formValues({
    cities: "paymentAccountCities",
    paymentAccountAddress: "paymentAccount.address",
    contactCompanyName: "paymentAccount.name",
})(
    ({
        cities = [],
        paymentAccountAddress = {},
        performContactSearch,
        textInputProps,
        contactSearchInProgress,
        contactCompanyName,
        changeField,
    }) => {
        const classes = useStyles()
        const [showOverlay, setShowOverlay] = useState(false)
        const [anchorEl, setAnchorEl] = useState(null)

        const handleInputFocus = (field, e) => {
            if (field === "searchContact") {
                setShowOverlay(true)
                setAnchorEl(e.currentTarget)
            }
        }

        const handleClickAway = () => {
            if (showOverlay) {
                setShowOverlay(false)
                setAnchorEl(null)
            }
        }

        const handleOptionSelect = (option = {}) => {
            setShowOverlay(false)
            setAnchorEl(null)

            changeField("paymentAccount", {
                address: {
                    city: option?.address.city,
                    state: option?.address.state,
                    street1: option?.address.street1,
                    street2: option?.address.street2,
                    country: option?.address.country,
                    postalCode: option?.address.postalCode,
                },
                name: option?.name,
                contact: {
                    name: option?.contact?.name,
                    phone: {
                        phone_number: option?.contact?.phone?.phoneNumber,
                        extension: option?.contact?.phone?.extension,
                    },
                    email: {
                        email_address: option?.contact?.email?.emailAddress,
                    },
                },
            })
        }
        return (
            <React.Fragment>
                <Popper
                    open={showOverlay}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: "scrollParent",
                        },
                    }}
                >
                    <ClickAwayListener
                        mouseEvent="onMouseDown"
                        touchEvent="onTouchStart"
                        onClickAway={() => handleClickAway()}
                    >
                        <div>
                            <SuggestionBox
                                searchInProgress={contactSearchInProgress}
                                addressesNeeded
                                field={"paymentAccount"}
                                term={contactCompanyName ?? ""}
                                handleAddressSelect={handleOptionSelect}
                                noCities
                            />
                        </div>
                    </ClickAwayListener>
                </Popper>
                <Grid xs={12} item className={classes.sectionSmall}>
                    <Grid>
                        <Grid item container justify="center">
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="orderDetails.true_third_party_title"
                                    defaultMessage="Third Party Information"
                                />
                            </Typography>
                        </Grid>
                        <Grid item container className={classes.section}>
                            <Grid
                                item
                                container
                                className={classes.formContainer}
                            >
                                <Grid
                                    item
                                    container
                                    className={classes.mainInformation}
                                    alignContent="flex-start"
                                >
                                    <Grid item container>
                                        <Grid item container>
                                            <Field
                                                name="paymentAccount.name"
                                                required
                                                label={
                                                    <FormattedMessage
                                                        id="generalTerms__companyName"
                                                        defaultMessage="Company Name"
                                                    />
                                                }
                                                component={FormField}
                                                autoFocus
                                                onChange={e => {
                                                    setShowOverlay(true)
                                                    setAnchorEl(e.currentTarget)
                                                    performContactSearch(
                                                        e.target.value,
                                                        paymentAccountAddress?.postalCode
                                                    )
                                                }}
                                                onFocus={e =>
                                                    handleInputFocus(
                                                        "searchContact",
                                                        e
                                                    )
                                                }
                                                InputProps={textInputProps}
                                                disableBrowserAutocomplete
                                            />
                                        </Grid>

                                        <Grid item container xs={8}>
                                            <Field
                                                name="paymentAccount.address.street1"
                                                required
                                                label={
                                                    <FormattedMessage
                                                        id="generalTerms__address1"
                                                        defaultMessage="Address 1"
                                                    />
                                                }
                                                component={FormField}
                                            />
                                        </Grid>
                                        <Grid item container xs={4}>
                                            <Field
                                                name="paymentAccount.address.street2"
                                                label={
                                                    <FormattedMessage
                                                        id="generalTerms__address2"
                                                        defaultMessage="Address 2"
                                                    />
                                                }
                                                component={FormField}
                                            />
                                        </Grid>
                                        <Grid item container xs={3}>
                                            <Field
                                                name="paymentAccount.address.postalCode"
                                                label={
                                                    <FormattedMessage
                                                        id="generalTerms__zipPostalCode"
                                                        defaultMessage="Zip/Postal Code"
                                                    />
                                                }
                                                component={FormZipCode}
                                                form="bookShipment"
                                                country={
                                                    formValues?.destination
                                                        ?.shippingAddress
                                                        ?.address?.country
                                                }
                                            />
                                        </Grid>
                                        <Grid item container xs={5}>
                                            <Field
                                                name="paymentAccount.address.city"
                                                label={
                                                    <FormattedMessage
                                                        id="generalTerms__city"
                                                        defaultMessage="City"
                                                    />
                                                }
                                                component={
                                                    FormSelectAutocomplete
                                                }
                                                options={cities.map(c => ({
                                                    value: c,
                                                    label: c,
                                                }))}
                                                customValue
                                            />
                                        </Grid>
                                        <Grid item container xs={2}>
                                            <Field
                                                name="paymentAccount.address.state"
                                                label={
                                                    <FormattedMessage
                                                        id="generalTerms__state"
                                                        defaultMessage="State"
                                                    />
                                                }
                                                component={FormField}
                                                inputProps={{ maxLength: 2 }}
                                            />
                                        </Grid>

                                        <Grid item container xs={2}>
                                            <Field
                                                component={
                                                    FormSelectAutocomplete
                                                }
                                                name="paymentAccount.address.country"
                                                label={
                                                    <FormattedMessage
                                                        id="generalTerms__country"
                                                        defaultMessage="Country"
                                                    />
                                                }
                                                options={supportedCountryCodes}
                                            />
                                        </Grid>
                                        <Grid item container>
                                            <Field
                                                name="paymentAccount.contact.name"
                                                label={
                                                    <FormattedMessage
                                                        id="generalTerms__contactName"
                                                        defaultMessage="Contact Name"
                                                    />
                                                }
                                                component={FormField}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item container xs={4}>
                                            <Field
                                                name="paymentAccount.contact.phone.phone_number"
                                                label={
                                                    <FormattedMessage
                                                        id="generalTerms__contactPhone"
                                                        defaultMessage="Contact Phone"
                                                    />
                                                }
                                                component={FormField}
                                                normalize={normalizePhone(
                                                    formValues?.destination
                                                        ?.shippingAddress
                                                        ?.address?.country
                                                )}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item container xs={2}>
                                            <Field
                                                name="paymentAccount.contact.phone.extension"
                                                label={
                                                    <FormattedMessage
                                                        id="generalTerms__extension"
                                                        defaultMessage="Extension"
                                                    />
                                                }
                                                component={FormField}
                                            />
                                        </Grid>
                                        <Grid item container xs={6}>
                                            <Field
                                                name="paymentAccount.contact.email.email_address"
                                                label={
                                                    <FormattedMessage
                                                        id="generalTerms__contactEmail"
                                                        defaultMessage="Contact Email"
                                                    />
                                                }
                                                component={FormField}
                                            />
                                        </Grid>
                                        <Grid item container>
                                            <Field
                                                name="paymentAccount.accountNumber"
                                                label={
                                                    <FormattedMessage
                                                        id="addLocation.billing__fedExThirdPartyAccountNumber"
                                                        defaultMessage="FedEx Third Party Account #:"
                                                    />
                                                }
                                                component={FormField}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                className={classes.accessorialsContainer}
                            >
                                <Grid
                                    item
                                    container
                                    className={classes.mapContainer}
                                >
                                    <Map addresses={[paymentAccountAddress]} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
)

export default TrueThirdPartyForm

import React, { Component } from "react"
import { Grid, Button } from "@material-ui/core"
import DashboardTableFilters from "./filters"
import FilterDrawer from "./filterDrawer"
import CurrentlyAppliedFilters from "./currentlyAppliedFilters"
import { FormattedMessage } from "react-intl"
import { Filter, Filter1, FilterList } from "@material-ui/icons"

const GACategory = "Dashboard"

class DashboardFilters extends Component {
    render() {
        const {
            classes,
            formValues,
            locations,
            carriers,
            tableFilters,
            handleChangeFilter,
            applyDashboardFilters,
            dashboardFilters,
            filterDrawerOpen,
            openFilterDrawer,
            closeFilterDrawer,
            resetFormField,
            fetchElementsOnFilterApply,
            gaCategory,
            trackGA,
        } = this.props

        return (
            <Grid
                item
                container
                alignItems="center"
                className={classes.dashboard__filtersContainer}
            >
                <Grid item container justify="flex-end" alignItems="center">
                    <CurrentlyAppliedFilters
                        dashboardFilters={dashboardFilters}
                        resetFormField={resetFormField}
                        applyDashboardFilters={applyDashboardFilters}
                        locations={locations}
                    />
                    <Grid item className={classes.dashboard__filterChip}>
                        <DashboardTableFilters
                            filters={tableFilters}
                            onChange={handleChangeFilter}
                            gaCategory={GACategory}
                            trackGA={trackGA}
                        />
                    </Grid>
                    <Grid item justify="flex-end" alignItems="center">
                        <Button
                            color="primary"
                            onClick={() => openFilterDrawer()}
                        >
                            <FilterList style={{ marginRight: "5px" }} />
                            <FormattedMessage
                                id="dashboard.filters__filters"
                                defaultMessage="Filters"
                            />
                        </Button>
                    </Grid>
                </Grid>
                <FilterDrawer
                    formValues={formValues}
                    carriers={carriers}
                    resetFormField={resetFormField}
                    open={filterDrawerOpen}
                    dashboardFilters={dashboardFilters}
                    applyDashboardFilters={applyDashboardFilters}
                    locations={locations}
                    openFilterDrawer={openFilterDrawer}
                    closeFilterDrawer={closeFilterDrawer}
                    fetchElementsOnFilterApply={fetchElementsOnFilterApply}
                />
            </Grid>
        )
    }
}

export default DashboardFilters

import { CLOSE_MODAL, OPEN_MODAL } from "../actions/modal"

export function modal(
    state = {
        isOpen: false,
        node: null,
    },
    action = {}
) {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...state,
                isOpen: true,
                node: action.node,
            }
        case CLOSE_MODAL:
            return modal()
        default:
            return state
    }
}

import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Field, FormSection, getFormValues } from "redux-form"
import { Grid, withStyles, Typography } from "@material-ui/core"
import { get, cloneDeep } from "lodash"
import FormField from "../../../form/form-field"
import FormZipCode from "../../../form/form-zip-code"
import { generateTimeList } from "../../../book/pickup/form"
import LocationBillingInformation from "../billing-information"
import {
    updateUserLocation,
    removeUserLocation,
    manageLocationCarrier,
    updateUserOnboardingDNA,
    updateUserDefaultLocation,
    updateCustomLocationPreferences,
    trackGAEvent,
} from "../../../../actions/user"
import { requestPostalCode } from "../../../../actions/postal-code"
import FormSelectAutocomplete from "../../../form/form-select-autocomplete"
import Permissions from "../permissions"
import { UserManagement } from "../userManagement"
import normalizePhone from "../../../util/normalizePhone"
import Carriers from "../carriers"
import { FormattedMessage } from "react-intl"
import { supportedCountryCodes } from "../../../../misc"
import formSelect from "../../../form/form-select"
import { CustomsDocumentsCard } from "../customsProfile/CustomsDocumentsCard"
import Brokers from "../customsProfile/Brokers"
import Accessorials from "../../../redesign/bookShipment/atoms/Accessorials"
import {
    formattedPriorityDeliveryAccessorials,
    formattedPriorityPickupAccessorials,
    sortByPriority,
} from "../../../quotesPage/accessorials"
import GlobalSpinner from "../../../common/GlobalSpinner"
import ShipmentRestrictions from "../ShipmentRestrictions"
import { closeModal, openModal } from "../../../../actions/modal"
import ConfirmActionModal from "../../../../components/common/ConfirmActionModal"
import InvoiceAudit from "../InvoiceAudit"
import { useFlags } from "launchdarkly-react-client-sdk"
import ShipperLoadCount from "../ShipperLoadCount"
import { styles } from "./styles"
import LocationTitleBar from "../LocationTitleBar"
import { useSnackbarContext } from "../../../../context/providers/snackbarProvider"
import PuertoRicoPricingTile from "../DomesticOffshore/PuertoRicoPricingTile"
import AlaskaPricingTile from "../DomesticOffshore/alaska/AlaskaPricingTile"
import HawaiiPricingTile from "../DomesticOffshore/hawaii/HawaiiPricingTile"

const GACategory = "Location"

let LocationAddress = ({ cities, classes, index, city, country }) => (
    <Grid item container>
        <Grid item container xs={4}>
            <Field
                name="shippingAddress.address.street1"
                component={FormField}
                type="text"
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__address1"
                        defaultMessage="Address 1"
                    />,
                ]}
                maxLength="30"
                className={classes.textField_right}
                category={GACategory}
            />
        </Grid>
        <Grid item container xs={2}>
            <Field
                name="shippingAddress.address.postalCode"
                component={FormZipCode}
                cityProp={city}
                country={country}
                type="text"
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__zipPostalCode"
                        defaultMessage="Zip/Postal Code"
                    />,
                ]}
                fieldPrefix="shippingAddress"
                inputProps={{ maxLength: "7" }}
                className={classes.textField_right}
                category={GACategory}
            />
        </Grid>
        <Grid item container xs={3}>
            <Field
                name="shippingAddress.address.city"
                component={FormSelectAutocomplete}
                customValue
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__city"
                        defaultMessage="City"
                    />,
                ]}
                options={[
                    { value: city, label: city },
                    ...cities.map(value => ({
                        value,
                        label: value,
                    })),
                ]}
                category={GACategory}
            />
        </Grid>
        <Grid item container xs={3}>
            <Field
                name="shippingAddress.name"
                label={[
                    "* ",
                    <FormattedMessage
                        id="locations.shippingInformation__shipperName"
                        defaultMessage="Shipper Name"
                    />,
                ]}
                className={classes.textField__right}
                component={FormField}
                category={GACategory}
            />
        </Grid>
        <Grid item container xs={4}>
            <Field
                name="shippingAddress.address.street2"
                component={FormField}
                type="text"
                label={
                    <FormattedMessage
                        id="generalTerms__address2"
                        defaultMessage="Address 2"
                    />
                }
                maxLength="30"
                className={classes.textField_right}
                category={GACategory}
            />
        </Grid>
        <Grid item container xs={2}>
            <Field
                name="shippingAddress.address.state"
                component={FormField}
                type="text"
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__stateProvince"
                        defaultMessage="State/Province"
                    />,
                ]}
                disabled
                category={GACategory}
            />
        </Grid>
        <Grid item container xs={3}>
            <Field
                name="shippingAddress.address.country"
                component={formSelect}
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__country"
                        defaultMessage="Country"
                    />,
                ]}
                options={supportedCountryCodes}
                maxLength="30"
                required
                category={GACategory}
            />
        </Grid>
        <Grid item container xs={3}>
            <Field
                name="cpgName"
                component={FormField}
                type="text"
                label={
                    <FormattedMessage
                        id="locations__nickname"
                        defaultMessage="Nickname"
                    />
                }
                className={classes.textField_right}
                category={GACategory}
            />
        </Grid>
    </Grid>
)

const locationAddressMapStateToProps = (state, props) => {
    return {
        cities: get(
            state,
            `postalCode[${props.country}-${props.postalCode}].cities`,
            [props.city]
        ),
    }
}

const locationAddressMapDispatchToProps = (dispatch, props) => ({
    trackGA: (category, action, value) =>
        dispatch(trackGAEvent(category, action, value)),
})

LocationAddress = connect(
    locationAddressMapStateToProps,
    locationAddressMapDispatchToProps
)(LocationAddress)

const LocationDetails = ({ classes, editable, country }) => (
    <Grid item container>
        <Grid item container>
            <Typography className={classes.title} variant="h6">
                <FormattedMessage
                    id="locations.pickUpInformation__title"
                    defaultMessage="Pick Up Information "
                />
            </Typography>
        </Grid>
        <Grid item container xs={6} id="readyTime">
            <Field
                label={
                    <FormattedMessage
                        id="generalTerms.pickUpInformation__readyTime"
                        defaultMessage="Ready Time"
                    />
                }
                name="readyTime"
                options={generateTimeList()}
                component={FormSelectAutocomplete}
                disabled={!editable}
                category={GACategory}
            />
        </Grid>
        <Grid item container xs={6} id="closeTime">
            <Field
                label={
                    <FormattedMessage
                        id="generalTerms.pickUpInformation__closeTime"
                        defaultMessage="Close Time"
                    />
                }
                name="closeTime"
                options={generateTimeList()}
                component={FormSelectAutocomplete}
                disabled={!editable}
                category={GACategory}
            />
        </Grid>
        <Grid item container>
            <Field
                id="pickupContact__name"
                name="pickupContact.name"
                component={FormField}
                label={
                    <FormattedMessage
                        id="generalTerms.pickUpInformation__pickupName"
                        defaultMessage="Pickup Name"
                    />
                }
                className={classes.textField__right}
                disabled={!editable}
                category={GACategory}
            />
        </Grid>
        <Grid item container>
            <Field
                name="pickupContact.email.email_address"
                id="pickupContact__email"
                component={FormField}
                label={
                    <FormattedMessage
                        id="generalTerms__emailAddress"
                        defaultMessage="Email Address"
                    />
                }
                className={classes.textField__right}
                disabled={!editable}
                category={GACategory}
            />
        </Grid>
        <Grid item container xs={8}>
            <Field
                name="pickupContact.phone.phone_number"
                id="pickupContact__phone"
                component={FormField}
                label={
                    <FormattedMessage
                        id="generalTerms__phoneNumber"
                        defaultMessage="Phone Number"
                    />
                }
                normalize={normalizePhone(country)}
                disabled={!editable}
                category={GACategory}
            />
        </Grid>
        <Grid item container xs={4}>
            <Field
                name="pickupContact.phone.extension"
                id="pickupContact__extension"
                component={FormField}
                label={
                    <FormattedMessage
                        id="generalTerms__ext"
                        defaultMessage="Ext"
                    />
                }
                disabled={!editable}
                category={GACategory}
            />
        </Grid>
    </Grid>
)

const LocationContainer = ({
    form,
    index,
    handleSubmit,
    dirty,
    startedDefault,
    location = {},
    classes,
    handleSnackbarOpen,
    carrierList,
    postalCode,
    trackGA,
    country,
    pickupAccessorialsList = [],
    deliveryAccessorialsList = [],
    formValues = {},
    formDNA,
    userOnboardingDNA,
    language,
    onCarrierManagementClick,
    removeLocation,
    updateUserDefaultLocation,
    handleUpdate,
    handleSuspend,
    openModal,
    closeModal,
    cities,
    updateCustomLocationPreferences,
}) => {
    const [isProcessing, setIsProcessing] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const { openSnackbar } = useSnackbarContext()

    const {
        invoiceVariance,
        createTrailerManifest,
        domesticOffshoreDiscountTile,
        domesticOffshoreDiscountTileAlaska,
        domesticOffshoreDiscountTileHi,
    } = useFlags()

    const carriers = (location?.carriers ?? []).filter(
        it => it.carrier !== "FXFE"
    )
    const locationUser = location?.users?.[0]
    const owner = locationUser?.role === "owner"
    const companyUser = locationUser?.role === "member"
    const isRequested = !owner && locationUser?.role === "requested"
    const isSuspended = locationUser?.permissions?.suspended?.value
    const locationUserCanShip =
        locationUser?.permissions?.createShipments?.value
    const isFreightDirect = location?.locationType === "FEDEX_DIRECT"
    const isFreightBox = location?.locationType === "FEDEX_FREIGHT_BOX"

    const isQuebecShipperAccount = false

    const isThirdPartyOnly =
        location.is3rdPartyEnabled && !location.isShipperEnabled

    useEffect(() => {
        requestPostalCode(postalCode, country)
    }, [location])

    const handleClickOpen = async (carrierCode, accountId) => {
        if (formDNA) {
            updateUserOnboardingDNA()
        }
        try {
            setIsProcessing(true)
            const onboardingUrl = await onCarrierManagementClick(
                location,
                carrierCode,
                accountId,
                language
            )
            window.open(onboardingUrl, "_blank")
            setIsProcessing(false)
            setDialogOpen(false)
            trackGA("Location", "Successful open of carrier onboarding portal")
        } catch {
            handleSnackbarOpen(
                "error",
                <FormattedMessage
                    id="location.carrierOnboardingPortalOpenError"
                    defaultMessage="Unable to open carrier onboarding portal"
                />
            )
            trackGA("Location", "Unable to open carrier onboarding portal")
        } finally {
            setIsProcessing(false)
            setDialogOpen(false)
        }
    }

    const removeLocationProcessing = async () => {
        try {
            setIsProcessing(true)
            trackGA("Location", "Unsubscribe Button Click")
            await removeLocation(location)
            handleSnackbarOpen(
                "success",
                <FormattedMessage
                    id="location.unsubscribeFromLocationSuccess"
                    defaultMessage="Successfully Unsubscribed from Location"
                />
            )
            setIsProcessing(false)
        } catch (error) {
            handleSnackbarOpen(
                "error",
                <FormattedMessage
                    id="location.unsubscribeFromLocationFail"
                    defaultMessage="Unable to Unsubscribe from Location"
                />
            )
            trackGA("Location", "Unsubscribe Error")
            setIsProcessing(false)
        }
    }

    const saveDefault = async isDefaultLocation => {
        if (isDefaultLocation && !startedDefault) {
            await updateUserDefaultLocation(get(location, "_id"))
        } else if (!isDefaultLocation && startedDefault) {
            await updateUserDefaultLocation(null)
        }
    }

    const handleConfirm = async values => {
        try {
            await handleUpdate(values)
            await updateCustomLocationPreferences(values)
            openSnackbar(
                "success",
                <FormattedMessage
                    id="location.saveSuccessfull"
                    defaultMessage="Save Successful"
                />
            )
            closeModal()
        } catch (err) {
            openSnackbar("error", "Unable to save")
            trackGA("Location", "Update Error")
            return err
        }
    }

    const handleSave = async values => {
        setIsProcessing(true)
        trackGA("Location", "Update Button Click")
        try {
            const editingCapacityLength =
                location?.shipmentRestrictions?.capacityShipmentLength !==
                formValues?.shipmentRestrictions?.capacityShipmentLength
            if (editingCapacityLength) {
                openModal(
                    <ConfirmActionModal
                        message={
                            values?.fedexFreightAccountType === "FXFM" ||
                            values?.fedexBillToAccountType === "FXFM" ? (
                                <FormattedMessage
                                    id="locations.capacityShipmentLength_modalBodyFXFM"
                                    defaultMessage="FedEx has a standard capacity shipment length of 32 linear feet for Mexico accounts, please refer to your pricing contract prior to updating."
                                />
                            ) : (
                                <FormattedMessage
                                    id="locations.capacityShipmentLength_modalBody"
                                    defaultMessage="FedEx has a standard capacity shipment length of 15 linear feet, please refer to your pricing contract prior to updating."
                                />
                            )
                        }
                        title={
                            <FormattedMessage
                                id="locations.capacityShipmentLength_modalHeader"
                                defaultMessage="Confirm Capacity Shipment Length"
                            />
                        }
                        handleConfirm={() => {
                            handleConfirm(values)
                        }}
                    />
                )
            } else {
                await saveDefault(get(values, "isDefaultLocation"))
                await handleUpdate(values)
                await updateCustomLocationPreferences(values)
                handleSnackbarOpen("success", "Save Successful")
            }
        } catch (err) {
            const errorMessage =
                err?.response?.message ?? err?.response?.validationErrors ?? []
            const message =
                (Array.isArray(errorMessage) && errorMessage.join("\n")) ||
                errorMessage ||
                "Unable To Save"
            handleSnackbarOpen("error", message)
            trackGA("Location", "Update Error")
            return err
        } finally {
            setIsProcessing(false)
        }
    }

    const handleSuspendProcessing = async (location, value) => {
        setIsProcessing(true)
        await handleSuspend(location, value)
        setIsProcessing(false)
    }
    return (
        <Grid container>
            {isProcessing && <GlobalSpinner />}
            <form className={classes.form__wrapper}>
                <Grid container>
                    <LocationTitleBar
                        classes={classes}
                        isFreightDirect={isFreightDirect}
                        isFreightBox={isFreightBox}
                        isRequested={isRequested}
                        isSuspended={isSuspended}
                        location={location}
                        dirty={dirty}
                        owner={owner}
                        handleSubmit={handleSubmit}
                        handleSave={handleSave}
                        removeLocationProcessing={removeLocationProcessing}
                        handleSuspendProcessing={handleSuspendProcessing}
                        gaCategory={GACategory}
                    />
                    <Grid className={classes.location__main} item container>
                        {!isRequested && (
                            <Grid
                                item
                                container
                                className={classes.location__section}
                            >
                                <Grid
                                    item
                                    container
                                    className={classes.location__card__top}
                                >
                                    <Grid
                                        container
                                        alignContent="flex-start"
                                        alignItems="flex-start"
                                    >
                                        {(owner || companyUser) && (
                                            <Grid item container xs={12}>
                                                <Typography
                                                    className={classes.title}
                                                    gutterBottom
                                                    variant="h6"
                                                >
                                                    <FormattedMessage
                                                        id="locations.shippingInformation__title"
                                                        defaultMessage="Shipping Information"
                                                    />
                                                </Typography>
                                                <LocationAddress
                                                    classes={classes}
                                                    postalCode={postalCode}
                                                    country={country}
                                                    city={get(
                                                        location,
                                                        "shippingAddress.address.city"
                                                    )}
                                                />
                                            </Grid>
                                        )}
                                        {!isFreightDirect &&
                                        !isThirdPartyOnly ? (
                                            <Grid item container>
                                                <Grid
                                                    item
                                                    container
                                                    xs={6}
                                                    className={
                                                        classes.accessorialContainer
                                                    }
                                                >
                                                    <Accessorials
                                                        list={
                                                            pickupAccessorialsList
                                                        }
                                                        name={
                                                            "pickupAccessorials"
                                                        }
                                                        formName="location"
                                                        formValues={formValues}
                                                        pickup
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    container
                                                    xs={6}
                                                    className={
                                                        classes.accessorialContainer
                                                    }
                                                >
                                                    <Accessorials
                                                        list={
                                                            deliveryAccessorialsList
                                                        }
                                                        name={
                                                            "deliveryAccessorials"
                                                        }
                                                        formName="location"
                                                        formValues={formValues}
                                                        delivery
                                                    />
                                                </Grid>
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {location.isShipperEnabled && (
                            <Grid
                                item
                                xs={6}
                                className={classes.location__section}
                            >
                                <Grid
                                    item
                                    container
                                    className={classes.location__card}
                                >
                                    <LocationDetails
                                        editable={owner || companyUser}
                                        classes={classes}
                                        index={index}
                                        country={country}
                                        cities={cities}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {(owner || companyUser) && (
                            <Grid
                                item
                                xs={6}
                                className={classes.location__section}
                            >
                                <Grid
                                    item
                                    container
                                    className={classes.location__card}
                                >
                                    <LocationBillingInformation
                                        editable={owner || companyUser}
                                        location={location}
                                        cpgCode={location.cpgCode}
                                        gaCategory={GACategory}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        <Grid item xs={6} className={classes.location__section}>
                            <Grid
                                item
                                container
                                className={classes.location__card}
                            >
                                {owner ? (
                                    <UserManagement
                                        handleSnackbarOpen={handleSnackbarOpen}
                                        classes={classes}
                                        index={index}
                                        information={location}
                                        gridSize={12}
                                        trackGA={trackGA}
                                    />
                                ) : (
                                    <FormSection name="users[0]">
                                        <Permissions
                                            classes={classes}
                                            gridSize={12}
                                            editMode
                                            checkEditable
                                            information={get(
                                                location,
                                                "users[0]",
                                                {}
                                            )}
                                            me
                                            location={location}
                                            gaCategory={GACategory}
                                        />
                                    </FormSection>
                                )}
                            </Grid>
                        </Grid>
                        {(owner || carriers?.length > 0) &&
                            !isFreightDirect &&
                            !isQuebecShipperAccount && (
                                <Grid
                                    item
                                    xs={6}
                                    className={classes.location__section}
                                >
                                    <Grid
                                        item
                                        container
                                        className={classes.location__card}
                                    >
                                        <Carriers
                                            owner={owner}
                                            handleClickOpen={handleClickOpen}
                                            classes={classes}
                                            handleClose={() => {
                                                setDialogOpen(false)
                                                trackGA(
                                                    GACategory,
                                                    "Add Carrier Modal Close"
                                                )
                                            }}
                                            location={location}
                                            carrierList={carrierList}
                                            dialogOpen={dialogOpen}
                                            userOnboardingDNA={
                                                userOnboardingDNA
                                            }
                                            setDialogOpen={setDialogOpen}
                                            gaCategory={GACategory}
                                            trackGA={trackGA}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        {locationUserCanShip && (
                            <Grid
                                item
                                xs={6}
                                className={classes.location__section}
                            >
                                <CustomsDocumentsCard
                                    locationId={location?._id}
                                    className={classes.location__card__wrapper}
                                    gaCategory={GACategory}
                                />
                            </Grid>
                        )}
                        {locationUserCanShip && (
                            <Grid
                                item
                                xs={6}
                                className={classes.location__section}
                            >
                                <Brokers
                                    locationId={location?._id}
                                    location={location}
                                    classes={classes}
                                    gaCategory={GACategory}
                                    trackGA={trackGA}
                                />
                            </Grid>
                        )}

                        {owner && (
                            <Grid
                                item
                                xs={6}
                                className={classes.location__section}
                            >
                                <Grid
                                    item
                                    container
                                    className={classes.location__card}
                                >
                                    <ShipmentRestrictions
                                        classes={classes}
                                        handleSave={handleSave}
                                        handleSubmit={handleSubmit}
                                        dirty={dirty}
                                        location={location}
                                        gaCategory={GACategory}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {owner && invoiceVariance && (
                            <Grid
                                item
                                xs={6}
                                className={classes.location__section}
                            >
                                <Grid
                                    item
                                    container
                                    className={classes.location__card}
                                >
                                    <InvoiceAudit
                                        classes={classes}
                                        handleSave={handleSave}
                                        handleSubmit={handleSubmit}
                                        dirty={dirty}
                                        location={location}
                                        gaCategory={GACategory}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {owner && createTrailerManifest && (
                            <Grid
                                item
                                xs={6}
                                className={classes.location__section}
                            >
                                <Grid
                                    item
                                    container
                                    className={classes.location__card}
                                >
                                    <ShipperLoadCount
                                        classes={classes}
                                        handleSave={handleSave}
                                        handleSubmit={handleSubmit}
                                        dirty={dirty}
                                        country={country}
                                        city={
                                            location?.shipperLoadCount?.address
                                                .city
                                        }
                                        formValues={formValues}
                                        gaCategory={GACategory}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {owner && domesticOffshoreDiscountTile ? (
                            <Grid
                                item
                                xs={6}
                                className={classes.location__section}
                            >
                                <PuertoRicoPricingTile
                                    locationId={location?._id}
                                    location={location}
                                    classes={classes}
                                    trackGA={trackGA}
                                />
                            </Grid>
                        ) : null}
                        {owner && domesticOffshoreDiscountTileAlaska ? (
                            <Grid
                                item
                                xs={6}
                                className={classes.location__section}
                            >
                                <AlaskaPricingTile
                                    locationId={location?._id}
                                    location={location}
                                    classes={classes}
                                    trackGA={trackGA}
                                />
                            </Grid>
                        ) : null}
                        {owner && domesticOffshoreDiscountTileHi ? (
                            <Grid
                                item
                                xs={6}
                                className={classes.location__section}
                            >
                                <HawaiiPricingTile
                                    locationId={location?._id}
                                    location={location}
                                    classes={classes}
                                    trackGA={trackGA}
                                />
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
            </form>
        </Grid>
    )
}

const mapStateToProps = (state, props) => {
    const formValues = getFormValues(props.form)(state)
    const language = state?.user?.profile?.preferences?.language

    const basePickupAccessorialList = state.accessorials.list.pickup.filter(
        x =>
            formValues?.shippingAddress?.address?.country !== "MX" ||
            formattedPriorityPickupAccessorials.some(y => y?.value === x?.value)
    )

    const baseDeliveryAccessorialList = state.accessorials.list.delivery.filter(
        x =>
            formValues?.shippingAddress?.address?.country !== "MX" ||
            formattedPriorityDeliveryAccessorials.some(
                y => y?.value === x?.value
            )
    )

    return {
        postalCode: formValues?.shippingAddress?.address?.postalCode,
        country: formValues?.shippingAddress?.address?.country,
        cities: get(
            state,
            `postalCode[${formValues?.shippingAddress?.address?.country}-${props.postalCode}].cities`,
            [props.city]
        ),
        carrierList: state?.carriers,
        formDNA: formValues?.formDNA ?? false,
        userOnboardingDNA: state?.user?.preferences?.onboardingDNA ?? false,
        language: state?.user?.profile?.preferences?.language,
        formValues,
        pickupAccessorialsList: sortByPriority(
            basePickupAccessorialList.map(entry => ({
                value: entry?.value,
                label: entry?.label[language],
            })),
            formattedPriorityPickupAccessorials
        ),
        deliveryAccessorialsList: sortByPriority(
            baseDeliveryAccessorialList.map(entry => ({
                value: entry?.value,
                label: entry?.label[language],
            })),
            formattedPriorityDeliveryAccessorials
        ),
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    removeLocation: async location => {
        await dispatch(removeUserLocation(location._id))
    },
    handleUpdate: async location => dispatch(updateUserLocation(location)),
    updateUserDefaultLocation: async value =>
        dispatch(updateUserDefaultLocation(value)),
    updateCustomLocationPreferences: async location =>
        dispatch(updateCustomLocationPreferences(location)),
    handleSuspend: async (location, value) => {
        if (value === true) {
            dispatch(trackGAEvent("Location", "Deactivate Self", "Non-owner"))
        } else if (value === false) {
            dispatch(trackGAEvent("Location", "Reactivate Self", "Non-owner"))
        }
        const newLocation = cloneDeep(location)
        newLocation.users[0].permissions.suspended.value = value
        return await dispatch(updateUserLocation(newLocation))
    },
    requestPostalCode: (postalCode, country) =>
        dispatch(requestPostalCode(postalCode, { country })),
    onCarrierManagementClick: (location, carrierCode, accountId, language) =>
        dispatch(
            manageLocationCarrier(location, carrierCode, accountId, language)
        ),
    updateUserOnboardingDNA: async () => dispatch(updateUserOnboardingDNA()),
    trackGA: (category, action, value) =>
        dispatch(trackGAEvent(category, action, value)),
    openModal: async node => dispatch(openModal(node)),
    closeModal: async () => dispatch(closeModal()),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(LocationContainer)
)

import { toInteger } from "lodash"
import * as yup from "yup"

export const schema = yup
    .object()
    .shape({
        measurementSystem: yup
            .string()
            .required()
            .label("Measurment System"),
        description: yup
            .string()
            .required()
            .label("Description"),
        packageType: yup
            .string()
            .required()
            .label("Package Type"),
        weight: yup
            .number()
            .transform((value, originalValue) =>
                Number.isNaN(value) && originalValue === ""
                    ? null
                    : toInteger(originalValue)
            )
            .positive()
            .required()
            .label("Weight"),
        length: yup
            .number()
            .transform((value, originalValue) =>
                Number.isNaN(value) && originalValue === ""
                    ? null
                    : toInteger(originalValue)
            )
            .positive()
            .required()
            .typeError("Length must be a number")
            .label("Length"),
        width: yup
            .number()

            .transform((value, originalValue) =>
                Number.isNaN(value) && originalValue === ""
                    ? null
                    : toInteger(originalValue)
            )
            .positive()
            .when("$measurementSystem", (measurementSystem, schema) => {
                const meas = measurementSystem[0] === "IMPERIAL" ? "in" : "cm"
                const maxWidth = measurementSystem[0] === "IMPERIAL" ? 93 : 263
                return schema.max(
                    maxWidth,
                    `Width must be less than or equal to ${maxWidth} ${meas}`
                )
            })
            .required()
            .label("Width"),
        height: yup
            .number()
            .transform((value, originalValue) =>
                Number.isNaN(value) && originalValue === ""
                    ? null
                    : toInteger(originalValue)
            )
            .positive()
            .when("$measurementSystem", (measurementSystem, schema) => {
                const meas = measurementSystem[0] === "IMPERIAL" ? "in" : "cm"
                const maxHeight =
                    measurementSystem[0] === "IMPERIAL" ? 107 : 271
                return schema.max(
                    maxHeight,
                    `Height must be less than or equal to ${maxHeight} ${meas}`
                )
            })
            .required()
            .label("Height"),
        pieces: yup
            .number()
            .transform((value, originalValue) =>
                Number.isNaN(value) && originalValue === ""
                    ? null
                    : toInteger(originalValue)
            )
            .positive()
            .required()
            .label("Pieces"),
        freightClass: yup
            .string()
            .required()
            .label("Freight Class"),
        nmfc1: yup
            .string()
            .transform(value => (value === "" ? null : value))
            .nullable()
            .matches(/^\d+$/, "NMFC Code be numeric")
            .max(6, "NMFC Code may not be longer than 6 numbers"),
        nmfc2: yup
            .string()
            .transform(value => (value === "" ? null : value))
            .nullable()
            .matches(/^\d+$/, "NMFC Subcode be numeric")
            .max(2, "NMFC SubCode may not be longer than 2 numbers"),
        unNumber: yup
            .string()
            .when("$isHazmat", (isHazmat, schema) => {
                if (isHazmat[0]) {
                    return schema.required()
                }
                return schema.transform(value => (value === "" ? null : value))
            })
            .nullable()
            .matches(
                /^(UN|NA|ID)\d{4}$/,
                "UN Numbers require a prefix of ‘UN or NA or ID’ before the 4 numeral code"
            ),
        hazContainer: yup
            .string()
            .when("$isHazmat", (isHazmat, schema) => {
                if (isHazmat[0]) {
                    return schema.required()
                }
                return schema.transform(value => (value === "" ? null : value))
            })
            .nullable()
            .label("Hazmat Container"),
        hazClass: yup
            .string()
            .when("$isHazmat", (isHazmat, schema) => {
                if (isHazmat[0]) {
                    return schema.required()
                }
                return schema.transform(value => (value === "" ? null : value))
            })
            .nullable()
            .label("Hazmat Class"),
        pkgGrp: yup
            .string()
            .when("$isHazmat", (isHazmat, schema) => {
                if (isHazmat[0]) {
                    return schema.required()
                }
                return schema.transform(value => (value === "" ? null : value))
            })
            .nullable()
            .label("Package Group"),
    })
    .required()

import { FormattedMessage } from "react-intl"
import { errorMessage } from "."
import { goFetch } from "../http"

export const GET_CREATE_STATUS_SHIPMENTS = "GET_CREATE_STATUS_SHIPMENTS"
export const CREATED_MANIFEST_RECIEVED = "CREATED_MANIFEST_RECIEVED"
export const TABLE_SHIPMENTS_LOADING_CHANGE = "TABLE_SHIPMENTS_LOADING_CHANGE"
export const TRAILER_MANIFEST_LOADING_CHANGE = "TRAILER_MANIFEST_LOADING_CHANGE"
export const CLEAR_ACTIVE_MANIFEST = "CLEAR_ACTIVE_MANIFEST"

function createdShipmentsLoad(shipments = []) {
    return { type: GET_CREATE_STATUS_SHIPMENTS, shipments }
}

function createdManifestReceived(file) {
    return { type: CREATED_MANIFEST_RECIEVED, file }
}

function shipmentsLoading(isLoading) {
    return { type: TABLE_SHIPMENTS_LOADING_CHANGE, isLoading }
}
function trailerManifestLoading(isLoading) {
    return { type: TRAILER_MANIFEST_LOADING_CHANGE, isLoading }
}

function clearManifest() {
    return { type: CLEAR_ACTIVE_MANIFEST }
}

export function getShipmentsForManifest(startParams) {
    return async dispatch => {
        const userTimezoneOffset = new Date().getTimezoneOffset()
        const params = {
            ...startParams,
            status: "CREATED",
            userTimezoneOffset: userTimezoneOffset,
            includeExternalShipments: false,
            scac: ["FXFE", "FXNL"],
        }
        dispatch(shipmentsLoading(true))
        try {
            const { data, status } = await goFetch(
                "/shipments",
                {
                    method: "GET",
                    credentials: "same-origin",
                    params,
                    validErrorCodes: [404],
                },
                true
            )
            await dispatch(createdShipmentsLoad(data))
            dispatch(shipmentsLoading(false))
        } catch (error) {
            dispatch(errorMessage(error))
            dispatch(shipmentsLoading(false))
        }
    }
}

export function createManifest(createParameters, openSnackbar) {
    return async dispatch => {
        dispatch(trailerManifestLoading(true))
        dispatch(clearManifest())
        try {
            const { data } = await goFetch(
                "/documents/manifest",
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: createParameters,
                    headers: { Accept: ["application/pdf", "image/png"] },
                    responseType: "blob",
                },
                true
            )
            const result = await new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(data)
                reader.onloadend = () => resolve(reader.result)
                reader.onerror = reject
            })
            dispatch(createdManifestReceived(result))
            dispatch(trailerManifestLoading(false))
        } catch (error) {
            dispatch(trailerManifestLoading(false))
            dispatch(errorMessage(error))
            openSnackbar(
                "error",
                <FormattedMessage
                    id="trailerManifest.error"
                    defaultMessage="Failed to generate manifest"
                />
            )
        }
    }
}

export function clearCurrentManifest() {
    return async dispatch => {
        dispatch(clearManifest())
    }
}

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import DashboardTable from "./shipment/DashboardTable"
import { changeDashboardTab, createGAEvent, removeSearch } from "../../actions"
import { anyShipmentException } from "./shipment/util"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const styles = theme => ({
    dashboard__itemContainer: {
        "overflow-y": "scroll",
        "overflow-x": "auto",
        "max-height": "700px",
    },
    dashboard__mainContainer: {
        margin: "10px",
    },
    dashboard_tabs: {
        paddingBottom: "20px",
        "margin-left": "50px !important",
    },
})

export const Dashboard = ({ classes }) => {
    const { logGAEvent } = useGAContext()

    useEffect(() => {
        logGAEvent("Dashboard", "Dashboard Page Viewed")
    }, [])

    return (
        <div id="section-heading">
            <DashboardTable />
        </div>
    )
}

Dashboard.propTypes = {
    active: PropTypes.string,
    activeIndex: PropTypes.number,
    shipmentException: PropTypes.bool,
    onTabClick: PropTypes.func.isRequired,
    searchList: PropTypes.array,
    onRemoveSearch: PropTypes.func.isRequired,
}

Dashboard.defaultProps = {
    active: "",
    activeIndex: null,
    searchList: [],
    shipmentException: false,
}

export const mapStateToProps = state => {
    const baseProps = {
        shipmentException: anyShipmentException(state.shipment.list),
        searchList: state.dashboard.search.list,
    }
    const { activeIndex, active } = state.dashboard
    const tab = active || "shipment"
    return {
        ...baseProps,
        active:
            tab === "search" && !Number.isInteger(activeIndex)
                ? "shipment"
                : tab,
        activeIndex,
    }
}

const mapDispatchToProps = dispatch => ({
    onTabClick: (tab, index) => {
        dispatch(createGAEvent("Dashboard", `${tab}Tab`))
        dispatch(changeDashboardTab(tab, index))
    },
    onRemoveSearch: index => dispatch(removeSearch(index)),
})

export const DashboardContainer = withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(Dashboard)
)

import React, { useState, useContext, createContext } from "react"

const Context = createContext({ state: {}, useState: () => {} })

export const useTileCountContext = () => {
    const tileCountContext = useContext(Context)
    if (!tileCountContext) {
        throw new Error(
            "Cannot use tile shipment context ouside of tileCountProvider"
        )
    }
    return tileCountContext
}

const TileCountProviderContext = ({ children }) => {
    const [state, setState] = useState({})

    const getSelectedTiles = () => Object.keys(state)

    const getTileCount = tile => state[tile]

    const incrementTileCount = tile => {
        setState(prevState => ({
            ...prevState,
            [tile]: prevState[tile] + 1,
        }))
    }

    const decrementTileCount = tile => {
        setState(prevState => ({
            ...prevState,
            [tile]: prevState[tile] - 1,
        }))
    }

    const setTileCount = (tile, count) => {
        setState(prevState => ({
            ...prevState,
            [tile]: count,
        }))
    }

    return (
        <Context.Provider
            value={{
                state,
                setState,
                getSelectedTiles,
                setTileCount,
                getTileCount,
                decrementTileCount,
                incrementTileCount,
            }}
        >
            {children}
        </Context.Provider>
    )
}

export const TileCountProvider = TileCountProviderContext

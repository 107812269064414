import { defineMessages } from "react-intl"

export const usmcaFormMessages = defineMessages({
    methodOfQualificationNetCost: {
        id: "usmca.method__of__qualification__net__cost",
        defaultMessage: "NC - Net Cost",
    },
    methodOfQualificationTransactionValue: {
        id: "usmca.method__of__qualification__transaction__value",
        defaultMessage: "TV - Transaction Value",
    },
    methodOfQualificationTariffShift: {
        id: "usmca.method__of__qualification__tariff__shift",
        defaultMessage: "TS - Tariff Shift",
    },
    methodOfQualificationNotApplicable: {
        id: "usmca.method__of__qualification__not__applicable",
        defaultMessage: "NO",
    },
    goodDescription: {
        id: "usmca.description",
        defaultMessage: "Description of good",
    },
    goodHarmonizedTariffSchedule: {
        id: "usmca.harmonized__tariff__schedule",
        defaultMessage: "Harmonized tariff schedule",
    },
    goodOriginCriterion: {
        id: "usmca.origin__criterion",
        defaultMessage: "Origin criterion",
    },
    goodCertificationIndicator: {
        id: "usmca.certificationIndicator",
        defaultMessage: "Certification indicator",
    },
    goodMethodOfQualification: {
        id: "usmca.method__of__qualification",
        defaultMessage: "Method of qualification",
    },
    goodCountryOfOrigin: {
        id: "usmca.country__of__origin",
        defaultMessage: "Country of origin",
    },
    signatureTitle: {
        id: "usmca.contact__title",
        defaultMessage: "Title",
    },
    signatureDate: {
        id: "usmca.date",
        defaultMessage: "Date",
    },
    blanketPeriodFrom: {
        id: "usmca.blanket__period__from",
        defaultMessage: "Blanket period from",
    },
    blanketPeriodTo: {
        id: "usmca.blanket__period__to",
        defaultMessage: "Blanket period to",
    },
    shipmentOnly: {
        id: "document.shipment__only",
        defaultMessage: "Create for shipment only",
    },
    confirmOverwrite: {
        id: "documents.upload__shouldConfirm",
        defaultMessage: "Overwrite Current {displayName}?",
    },
    overwriteShipmentDocument: {
        id: "document.overwrite__shipment__document",
        defaultMessage: "Overwrite shipment document",
    },
    overwriteProfileDocument: {
        id: "document.overwrite__profile__document",
        defaultMessage: "Overwrite profile document",
    },
})

import React, { useEffect } from "react"
import {
    Typography,
    withStyles,
    Grid,
    IconButton,
    Button,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import CloseIcon from "@material-ui/icons/Close"
import FormField from "../form/form-field"
import { Field } from "redux-form"
import { weightUnit } from "../util/units"

const styles = theme => ({
    root: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        top: "50%",
        left: "50%",
        width: "980px",
        maxHeight: "700px",
        overflowY: "scroll",
        flexGrow: 1,
    },
    info__container: {
        marginTop: "8px",
        paddingBottom: "8px",
    },
    icon__close: {
        marginTop: "-10px",
    },
})

const HazMatDetailsModal = props => {
    const {
        classes,
        handleClose,
        handlingUnits = [],
        preferredSystemOfMeasurement,
        changeField,
        hazmatEmergency,
        submit,
    } = props

    const buildDescription = (item, hazmatEmergency) => {
        return `${item?.hazMatContainer},${item?.hazMatUnNumber},${
            item?.description
        },${item?.hazMatClass},${
            item?.hazMatPkgGrp === "NONE" ? "" : "PG" + item?.hazMatPkgGrp + ","
        }Emergency Contact:${
            hazmatEmergency?.phone?.phone_number
        },ERI Provider: ${hazmatEmergency?.name}`
    }

    const submitRequest = () => {
        handleClose()
        submit()
    }

    useEffect(() => {
        handlingUnits.forEach((hu, index) =>
            hu.items.forEach((item, itemIndex) => {
                const field = `handlingUnits[${index}].items[${itemIndex}].hazMatDescription`
                changeField(field, buildDescription(item, hazmatEmergency))
            })
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid className={classes.root}>
            <Grid item container xs={12} justify="space-between">
                <Grid item>
                    <Typography variant="h5">
                        <FormattedMessage
                            id="orderDetails.hazmat__info"
                            defaultMessage="Review and Confirm HazMat Description"
                        />
                    </Typography>
                </Grid>
                <Grid item justify="flex-start" alignContent="flex-start">
                    <IconButton
                        onClick={() => handleClose()}
                        className={classes.icon__close}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid>
                <Typography variant="caption">
                    <FormattedMessage
                        id="orderDetails.hazmat__details"
                        defaultMessage="Please verify that the below hazardous materials shipment description is compliant with DOT/PHMSA regulations. Make edits by adding other required information, including but not limited to, subsidiary hazard class(es), chemical group/technical name(s), hazard zone, DOT special permit number, EX or FC number, net explosive weight, and reportable quantity or marine pollutant or inhalation hazard designation."
                    />
                </Typography>
            </Grid>
            <Grid
                item
                container
                xs={12}
                direction="row"
                className={classes.info__container}
            >
                {handlingUnits.map((hu, index) =>
                    hu.items.map((item, itemIndex) => {
                        const prefix = `handlingUnits[${index}].items[${itemIndex}]`
                        if (item.isHazMat) {
                            return (
                                <Grid
                                    item
                                    container
                                    alignItems="center"
                                    justify="flex-start"
                                    className={classes.info__container}
                                >
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1">
                                            {`${item.description}, Class ${
                                                item.freightClass
                                            }, ${item.weight}${weightUnit(
                                                preferredSystemOfMeasurement
                                            )}`}
                                        </Typography>
                                        <Field
                                            name={`${prefix}.hazMatDescription`}
                                            label={[
                                                <FormattedMessage
                                                    id="orderDetails.hazmat__info__item"
                                                    defaultMessage="Adjust HazMat Descriptions"
                                                />,
                                            ]}
                                            component={FormField}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        }
                        return null
                    })
                )}
            </Grid>
            <Grid
                item
                container
                alignItems="center"
                justify="space-between"
                className={classes.info__container}
            >
                <Button
                    id="book__bookShipment"
                    type="submit"
                    variant="outlined"
                    color="secondary"
                    onClick={handleClose}
                >
                    <FormattedMessage
                        id="generalTerms__cancel"
                        defaultMessage="Cancel"
                    />
                </Button>

                <Button
                    id="book__bookShipment"
                    variant="contained"
                    color="primary"
                    onClick={submitRequest}
                >
                    <FormattedMessage
                        id="generalTerms__confirm"
                        defaultMessage="Confirm"
                    />
                </Button>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(HazMatDetailsModal)

import React, { Fragment, Component } from "react"
import PropTypes from "prop-types"
import { add, get, pick } from "lodash"
import { Field, getFormValues, reduxForm } from "redux-form"
import { connect } from "react-redux"
import {
    Snackbar,
    Typography,
    withStyles,
    Button,
    Grid,
    Modal,
    IconButton,
} from "@material-ui/core"
import { Map } from "../../util"
import GlobalSpinner from "../../common/GlobalSpinner"
import FormField from "../../form/form-field"
import FormZipCode from "../../form/form-zip-code"
import FormSelect from "../../form/form-select"
import FormSelectAutocomplete from "../../form/form-select-autocomplete"
import normalizePhone from "../../util/normalizePhone"
import { saveSelectedContact, deleteContact } from "../../../actions/contact"
import {
    emailValidator,
    countryPhoneValidator,
    isRequired,
    isNumeric,
    isValidCountryPostalCode,
} from "../../../actions/validation"
import {
    contactSaveError,
    contactDeleteError,
} from "../../../messages/error-constants"
import {
    contactSaveSuccess,
    contactDeleteSuccess,
} from "../../../messages/confirmation-constants"
import { ErrorSnackbarContentWrapper } from "../../errorSnackbar"
import {
    combineValidators,
    composeValidators,
    createValidator,
} from "revalidate"
import { FormattedMessage } from "react-intl"
import {
    supportedCountryCodes,
    supportedLanguagesCountryMap,
} from "../../../misc"
import { generateTimeList } from "../../book/pickup/form"
import CloseIcon from "@material-ui/icons/Close"
import moment from "moment"
import Accessorials from "../../redesign/bookShipment/atoms/Accessorials"
import {
    formattedPriorityDeliveryAccessorials,
    formattedPriorityPickupAccessorials,
    sortByPriority,
} from "../../quotesPage/accessorials"
import { trackGAEvent } from "../../../actions/user"

const styles = theme => ({
    contact__information__container: {
        padding: "10px 5px 10px 5px",
        "margin-left": "10px",
        width: "100%",
        height: "100%",
    },
    main__grid: {
        height: "100%",
    },
    company__information__form: {
        padding: "5px 5px 5px 5px",
        marginRight: "20px",
    },
    map__container: {
        padding: 10,
        margin: 10,
        width: "100%",
        height: "550px",
    },
    form__actions: {
        paddingBottom: "8px",
    },
    cancel__button: {
        marginRight: "10px",
    },
    contact_tittle: {
        textDecoration: "underline",
    },
    map_button: {
        marginTop: "15px",
        marginLeft: "10px",
    },
    modal__container: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
        width: "800px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        overflowY: "scroll",
        height: "600px",
    },
    button__close: {
        "margin-left": "auto",
    },
})

class ContactForm extends Component {
    state = {
        sbOpen: false,
        sbVariant: "",
        sbMessage: "",
        loading: false,
        modalMap: false,
    }

    handleCloseMap = () => {
        this.setState({ modalMap: false })
    }

    handleOpenMap = () => {
        this.setState({ modalMap: true })
    }

    handleDelete = async () => {
        this.setState({ loading: true })
        try {
            await this.props.onClickDelete()
            this.setState({
                sbOpen: true,
                sbMessage: contactDeleteSuccess,
                sbVariant: "success",
            })
            this.props.trackGA("Contacts Page", "Delete Contact Success")
        } catch (err) {
            this.setState({
                sbOpen: true,
                sbMessage: contactDeleteError,
                sbVariant: "error",
            })
            this.props.trackGA("Contacts Page", "Delete Contact Failure")
        }
        this.setState({ loading: false })
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        this.setState({ sbOpen: false })
    }

    submitForm = async () => {
        this.setState({ loading: true })
        try {
            await this.props.onClickSave()
            this.setState({
                sbOpen: true,
                sbMessage: contactSaveSuccess,
                sbVariant: "success",
            })
            this.props.trackGA("Contacts Page", "Save Contact Success")
        } catch (error) {
            this.setState({
                sbOpen: true,
                sbMessage: contactSaveError,
                sbVariant: "error",
            })
            this.props.trackGA("Contacts Page", "Save Contact Failure")
        }
        this.setState({ loading: false })
    }

    render() {
        const {
            street1,
            street2,
            city,
            state,
            country,
            cities = [],
            handleSubmit,
            reset,
            pristine,
            invalid,
            classes,
            submitting,
            formValues,
            pickupAccessorialsList,
            deliveryAccessorialsList,
        } = this.props

        return (
            <Fragment>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={this.state.sbOpen}
                    autoHideDuration={6000}
                    onClose={this.handleSnackbarClose}
                >
                    <ErrorSnackbarContentWrapper
                        variant={this.state.sbVariant}
                        onClose={this.handleSnackbarClose}
                        message={
                            <span>
                                <Typography variant="body2">
                                    {this.state.sbMessage}
                                </Typography>
                            </span>
                        }
                    />
                </Snackbar>
                {this.state.loading && <GlobalSpinner />}
                <form
                    className={classes.contact__information__container}
                    onSubmit={handleSubmit(this.submitForm)}
                >
                    <Grid container className={classes.form__actions}>
                        <Grid item container xs={5}>
                            <Typography
                                variant="h5"
                                color="inherit"
                                className={classes.contact_tittle}
                            >
                                <FormattedMessage
                                    id="contacts.title"
                                    defaultMessage="Contact Information"
                                />
                            </Typography>
                        </Grid>
                        <Grid item container justify="flex-end" xs={7}>
                            <Grid item className={classes.cancel__button}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="medium"
                                    onClick={() => this.handleDelete()}
                                >
                                    <FormattedMessage
                                        id="generalTerms__delete"
                                        defaultMessage="Delete"
                                    />
                                </Button>
                            </Grid>
                            <Grid item className={classes.cancel__button}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="medium"
                                    disabled={pristine || submitting}
                                    onClick={reset}
                                >
                                    <FormattedMessage
                                        id="generalTerms__cancel"
                                        defaultMessage="Cancel"
                                    />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    type="submit"
                                    disabled={pristine || invalid || submitting}
                                >
                                    <FormattedMessage
                                        id="generalTerms__save"
                                        defaultMessage="Save"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.form__actions}>
                        <Grid item xs={12}>
                            <Field
                                name="companyName"
                                component={FormField}
                                type="text"
                                label={[
                                    "* ",
                                    <FormattedMessage
                                        id="generalTerms__companyName"
                                        defaultMessage="Company Name"
                                    />,
                                ]}
                                maxLength="30"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            direction={"row"}
                            className={classes.form__actions}
                        >
                            <Grid item xs={7}>
                                <Field
                                    name="street1"
                                    component={FormField}
                                    type="text"
                                    label={[
                                        "* ",
                                        <FormattedMessage
                                            id="generalTerms__address1"
                                            defaultMessage="Address 1"
                                        />,
                                    ]}
                                    maxLength="30"
                                />
                            </Grid>

                            <Grid item xs={5}>
                                <Field
                                    name="street2"
                                    component={FormField}
                                    type="text"
                                    label={
                                        <FormattedMessage
                                            id="generalTerms__address2"
                                            defaultMessage="Address 2"
                                        />
                                    }
                                    maxLength="30"
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            container
                            direction={"row"}
                            className={classes.form__actions}
                        >
                            <Grid item xs={3}>
                                <Field
                                    name="postalCode"
                                    component={FormZipCode}
                                    type="text"
                                    label={[
                                        "* ",
                                        <FormattedMessage
                                            id="generalTerms__zipPostalCode"
                                            defaultMessage="Zip/Postal Code"
                                        />,
                                    ]}
                                    form="contact"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Field
                                    name="city"
                                    component={FormSelectAutocomplete}
                                    label={[
                                        "* ",
                                        <FormattedMessage
                                            id="generalTerms__city__selectOrEnter"
                                            defaultMessage="City (Select or Enter)"
                                        />,
                                    ]}
                                    options={cities.map(c => ({
                                        value: c,
                                        label: c,
                                    }))}
                                    customValue
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Field
                                    name="state"
                                    component={FormField}
                                    type="text"
                                    label={[
                                        "* ",
                                        <FormattedMessage
                                            id="generalTerms__stateProvince"
                                            defaultMessage="State/Province"
                                        />,
                                    ]}
                                    maxLength="3"
                                    inputProps={{ maxLength: 3 }}
                                />
                            </Grid>

                            <Grid item xs={2}>
                                <Field
                                    name="country"
                                    component={FormSelect}
                                    type="text"
                                    label={[
                                        "* ",
                                        <FormattedMessage
                                            id="generalTerms__country"
                                            defaultMessage="Country"
                                        />,
                                    ]}
                                    options={supportedCountryCodes}
                                    maxLength="30"
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    className={classes.map_button}
                                    onClick={this.handleOpenMap}
                                >
                                    <FormattedMessage
                                        id="contacts.map"
                                        defaultMessage="Map"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid container item xs={12}>
                                <Grid item xs={6}>
                                    <Field
                                        name="name"
                                        component={FormField}
                                        type="text"
                                        label={[
                                            "* ",
                                            <FormattedMessage
                                                id="contacts.form__contact"
                                                defaultMessage="Contact"
                                            />,
                                        ]}
                                        maxLength="30"
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Field
                                        name="mail"
                                        component={FormField}
                                        type="email"
                                        label={[
                                            "* ",
                                            <FormattedMessage
                                                id="contacts.form__email"
                                                defaultMessage="Email"
                                            />,
                                        ]}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                container
                                direction={"row"}
                                className={classes.form__actions}
                            >
                                <Grid item xs={4}>
                                    <Field
                                        name="phone"
                                        component={FormField}
                                        type="text"
                                        label={[
                                            "* ",
                                            <FormattedMessage
                                                id="contacts.form__phone"
                                                defaultMessage="Phone"
                                            />,
                                        ]}
                                        normalize={normalizePhone(country)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        name="extension"
                                        component={FormField}
                                        type="text"
                                        label={
                                            <FormattedMessage
                                                id="contacts.form__extension"
                                                defaultMessage="Extension"
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        name="mobile"
                                        component={FormField}
                                        type="text"
                                        label={
                                            <FormattedMessage
                                                id="contacts.form__mobile"
                                                defaultMessage="Mobile"
                                            />
                                        }
                                        normalize={normalizePhone(country)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.form__actions}>
                        <Grid item container>
                            <Typography
                                variant="h5"
                                className={classes.contact_tittle}
                            >
                                <FormattedMessage
                                    id="generalTerms__accessorials"
                                    defaultMessage="Accessorials"
                                />
                            </Typography>
                        </Grid>
                        <Grid item container xs={6}>
                            <Accessorials
                                list={pickupAccessorialsList}
                                name={"pickupAccessorials"}
                                formName="contact"
                                formValues={formValues}
                            />
                        </Grid>
                        <Grid item container xs={6}>
                            <Accessorials
                                list={deliveryAccessorialsList}
                                name={"deliveryAccessorials"}
                                formName="contact"
                                formValues={formValues}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.form__actions}>
                        <Grid
                            item
                            container
                            xs={8}
                            className={classes.form__actions}
                        >
                            <Typography
                                variant="h5"
                                color="inherit"
                                className={classes.contact_tittle}
                            >
                                <FormattedMessage
                                    id="contacts.pickUp__title"
                                    defaultMessage="Pick Up Information (Optional)"
                                />
                            </Typography>
                        </Grid>
                        <Grid item container>
                            <Grid item xs={6} className={classes.form__actions}>
                                <Field
                                    name="pickupContact.readyTime"
                                    component={FormSelectAutocomplete}
                                    label={
                                        <FormattedMessage
                                            id="generalTerms.pickUpInformation__readyTime"
                                            defaultMessage="Ready Time"
                                        />
                                    }
                                    options={generateTimeList()}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="pickupContact.closeTime"
                                    component={FormSelectAutocomplete}
                                    label={
                                        <FormattedMessage
                                            id="generalTerms.pickUpInformation__closeTime"
                                            defaultMessage="Close Time"
                                        />
                                    }
                                    options={generateTimeList()}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.form__actions}
                            >
                                <Field
                                    name="pickupContact.name"
                                    component={FormField}
                                    label={
                                        <FormattedMessage
                                            id="generalTerms.pickUpInformation__pickupName"
                                            defaultMessage="Pickup Name"
                                        />
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.form__actions}
                            >
                                <Field
                                    name="pickupContact.email.email_address"
                                    component={FormField}
                                    label={
                                        <FormattedMessage
                                            id="generalTerms__emailAddress"
                                            defaultMessage="Email Address"
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={8} className={classes.form__actions}>
                                <Field
                                    name="pickupContact.phone.phone_number"
                                    component={FormField}
                                    label={
                                        <FormattedMessage
                                            id="generalTerms__phoneNumber"
                                            defaultMessage="Phone Number"
                                        />
                                    }
                                    normalize={normalizePhone(country)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    name="pickupContact.phone.extension"
                                    component={FormField}
                                    label={
                                        <FormattedMessage
                                            id="generalTerms__extension"
                                            defaultMessage="Extension"
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <ModalMap
                    open={this.state.modalMap}
                    handleClose={this.handleCloseMap}
                    street1={street1}
                    street2={street2}
                    city={city}
                    state={state}
                    country={country}
                    style={classes}
                />
            </Fragment>
        )
    }
}

ContactForm.propTypes = {
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    cities: PropTypes.array,
    reset: PropTypes.func.isRequired,
    onClickSave: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    classes: PropTypes.object,
}

ContactForm.defaultProps = {
    street1: "",
    street2: "",
    city: "",
    state: "",
    pristine: false,
    invalid: false,
    submitting: false,
    classes: {},
    cities: [],
}

const isValidPostalCode = (field, fieldValue, formValues) => {
    const country = get(formValues, "country")
    if (isValidCountryPostalCode(fieldValue, country)) {
        return false
    } else {
        return (
            <FormattedMessage
                id="getRates.form__invalidPostalCode"
                defaultMessage="Invalid {field} postal code"
                values={{ field: country }}
            />
        )
    }
}

const pickupWindowValidator = createValidator(
    message => (value, allValues) => {
        const time = get(allValues, "pickupContact.readyTime")
        if (!time) {
            return undefined
        }
        const readyTime = moment(time, "HH:mm A")
        const closeTime = moment(value, "HH:mm A")
        const minutes = closeTime.diff(readyTime, "minutes")
        return minutes >= 90 ? undefined : message
    },
    field => ({
        ...{
            id: "orderDetails.validation__pickupWindow",
            defaultMessage:
                "{field} should be at least 90 minutes after Ready Time",
        },
        values: { field },
    })
)

const contactValidation = combineValidators({
    name: isRequired({
        field: {
            id: "generalTerms__name",
            defaultMessage: "Name",
        },
    }),
    mail: composeValidators(
        isRequired,
        emailValidator
    )({
        field: {
            id: "generalTerms__emailAddress",
            defaultMessage: "Email Address",
        },
    }),
    phone: composeValidators(
        isRequired,
        countryPhoneValidator("country")
    )({
        field: {
            id: "generalTerms__phoneNumber",
            defaultMessage: "Phone Number",
        },
    }),
    extension: isNumeric({
        field: {
            id: "generalTerms__extension",
            defaultMessage: "Extension",
        },
    }),
    mobile: countryPhoneValidator("country")({
        field: {
            id: "contacts.form__mobile",
            defaultMessage: "Mobile",
        },
    }),
    companyName: isRequired({
        field: {
            id: "generalTerms__companyName",
            defaultMessage: "Company Name",
        },
    }),
    street1: isRequired("Address"),
    city: isRequired({
        field: {
            id: "generalTerms__city",
            defaultMessage: "City",
        },
    }),
    state: isRequired({
        field: {
            id: "generalTerms__state",
            defaultMessage: "State",
        },
    }),
    country: isRequired({
        field: {
            id: "generalTerms__country",
            defaultMessage: "Country",
        },
    }),
    postalCode: composeValidators(
        isRequired,
        isValidPostalCode
    )({
        field: {
            id: "generalTerms__postalCode",
            defaultMessage: "Postal Code",
        },
    }),
    pickupContact: {
        email: {
            email_address: composeValidators(emailValidator)({
                field: {
                    id: "generalTerms__emailAddress",
                    defaultMessage: "Email Address",
                },
            }),
        },
        phone: {
            phone_number: composeValidators(countryPhoneValidator("country"))({
                field: {
                    id: "generalTerms__phoneNumber",
                    defaultMessage: "Phone Number",
                },
            }),
            extension: isNumeric({
                field: {
                    id: "generalTerms__extension",
                    defaultMessage: "Extension",
                },
            }),
        },
        closeTime: composeValidators(pickupWindowValidator)({
            field: {
                id: "generalTerms.pickUpInformation__closeTime",
                defaultMessage: "Close Time",
            },
        }),
    },
})

const mapStateToProps = state => {
    const formValues = getFormValues("contact")(state)
    let initialValues = {
        ...state?.addressBook?.selected,
        pickupContact: {
            ...state?.addressBook?.selected?.pickupContact,
            readyTime:
                state?.addressBook?.selected?.pickupContact?.readyTime ||
                "6:00 AM",
            closeTime:
                state?.addressBook?.selected?.pickupContact?.closeTime ||
                "1:00 PM",
        },
    }
    if (!initialValues?.id) {
        initialValues = {
            country:
                supportedLanguagesCountryMap[
                    state?.user?.profile?.preferences?.language
                ] ?? "US",
            pickupAccessorials: [],
            deliveryAccessorials: [],
            pickupContact: {
                readyTime: "6:00 AM",
                closeTime: "1:00 PM",
            },
        }
    }

    const language = state?.user?.profile?.preferences?.language

    const basePickupAccessorialList = state.accessorials.list.pickup.filter(
        x =>
            formValues?.country !== "MX" ||
            formattedPriorityPickupAccessorials.some(y => y?.value === x?.value)
    )

    const baseDeliveryAccessorialList = state.accessorials.list.delivery.filter(
        x =>
            formValues?.country !== "MX" ||
            formattedPriorityDeliveryAccessorials.some(
                y => y?.value === x?.value
            )
    )

    const citiesByPostalCode =
        state?.postalCode?.[
            formValues?.postalCode
                ? formValues?.country + "-" + formValues?.postalCode
                : ""
        ]?.cities

    const addressSelectedCity = state?.addressBook?.selected?.city
        ? [state?.addressBook?.selected?.city]
        : []

    return {
        initialValues,
        ...pick(formValues ?? {}, [
            "city",
            "street1",
            "street2",
            "state",
            "country",
        ]),
        cities: citiesByPostalCode ?? addressSelectedCity,
        formValues,
        pickupAccessorialsList: sortByPriority(
            basePickupAccessorialList.map(entry => ({
                value: entry?.value,
                label: entry?.label[language],
            })),
            formattedPriorityPickupAccessorials
        ),
        deliveryAccessorialsList: sortByPriority(
            baseDeliveryAccessorialList.map(entry => ({
                value: entry?.value,
                label: entry?.label[language],
            })),
            formattedPriorityDeliveryAccessorials
        ),
    }
}

const mapDispatchToProps = dispatch => ({
    onClickSave: async () => await dispatch(saveSelectedContact()),
    onClickDelete: async () => await dispatch(deleteContact()),
    trackGA: (category, action, label) =>
        dispatch(trackGAEvent(category, action, label)),
})

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "contact", // a unique identifier for this form
            enableReinitialize: true,
            validate: contactValidation,
        })(ContactForm)
    )
)

const ModalMap = ({
    open,
    handleClose,
    street1,
    street2,
    city,
    state,
    country,
    style,
}) => (
    <Modal open={open} onClose={handleClose}>
        <Grid
            item
            container
            xs={8}
            className={style.modal__container}
            direction="row"
        >
            <Grid item container xs={12}>
                <Grid
                    container
                    item
                    xs={12}
                    justify="flex-end"
                    alignItems="flex-start"
                >
                    <IconButton
                        className={style.button__close}
                        onClick={e => {
                            e.stopPropagation()
                            handleClose()
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Map
                    addresses={[
                        {
                            street1,
                            street2,
                            city,
                            state,
                            country,
                        },
                    ]}
                />
            </Grid>
        </Grid>
    </Modal>
)

import React from "react"
import {
    Link,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
    container: {
        padding: "5px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        padding: "10px",
    },
})

export default function CapLoadWarningDialog({
    open,
    onCloseDialog,
    onContinue,
}) {
    const classes = useStyles()

    return (
        <Dialog
            className={classes.container}
            open={open}
            onClose={onCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <FormattedMessage
                    id="book.capLoad__dialogTitle"
                    defaultMessage="Warning: Potential Capacity Load Charge"
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage
                        id="book.capLoad__dialogContent.first"
                        defaultMessage="This shipment could incur additional charges based on the size and your account-specific rates. Please contact Volume Services at 1.888.465.5646 to understand the precise cost of your shipment. For more information: "
                    />
                    <Link
                        href="https://www.fedex.com/content/dam/fedex/us-united-states/services/FXF_100_Series_Rules_Tariff.pdf#page=16"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <FormattedMessage
                            id="book.capLoad__dialogContent.second"
                            defaultMessage="FXF 100 Series Rules Tariff "
                        />
                    </Link>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.buttonContainer}>
                <Button
                    color="secondary"
                    onClick={onCloseDialog}
                    autoFocus
                    variant="outlined"
                >
                    Change Line Items
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={onContinue}
                >
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    )
}

import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Field } from "redux-form"
import { Typography, Grid, withStyles } from "@material-ui/core"
import { updateMarkup } from "../../../actions/quote"
import FormField from "../../form/form-field"
import FormCheckbox from "../../form/form-checkbox"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    form__container: {
        marginBottom: theme.spacing(5),
        backgroundColor: "white",
    },
    input__container: {
        marginBottom: "15px",
        marginLeft: "10px",
    },
    icon__container: {
        maxWidth: "32px",
    },
    icon: {
        fontSize: "32px",
    },
    markup__container: {
        marginTop: "10px",
    },
    checkbox__container: {
        marginTop: "-15px",
        marginRight: "5px",
    },
})

const MarkupPresentation = ({
    markupAmount,
    onChange,
    markupType,
    onMarkupBlur,
    classes,
    currencyCode,
}) => (
    <Grid
        item
        container
        xs={12}
        className={classes.form__container}
        alignItems="center"
        spacing={2}
    >
        <Grid item xs={2}>
            <Typography variant="h5">
                <FormattedMessage
                    id="rateResults.share__upcharge"
                    defaultMessage="Upcharge"
                />
            </Typography>
        </Grid>
        <Grid item xs={2} className={classes.input__container}>
            <Field
                name="markupAmount"
                label=""
                type="number"
                inputProps={{ min: 0 }}
                component={FormField}
            />
        </Grid>
        <Grid item container xs={4} className={classes.markup__container}>
            <Grid item xs={1}>
                <span>{currencyCode}</span>
            </Grid>
            <Grid item container className={classes.checkbox__container} xs={2}>
                <Field
                    name="markupType"
                    component={FormCheckbox}
                    enableSwitch
                />
            </Grid>
            <Grid item xs={1}>
                <span>%</span>
            </Grid>
        </Grid>
    </Grid>
)

MarkupPresentation.propTypes = {
    markupAmount: PropTypes.number,
    markupType: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onMarkupBlur: PropTypes.func.isRequired,
}

MarkupPresentation.defaultProps = {
    markupAmount: 0,
    markupType: "markupAmount",
}

const mapDispatchToProps = dispatch => ({
    onMarkupBlur: () => dispatch(updateMarkup()),
})

export const Markup = connect(
    undefined,
    mapDispatchToProps
)(withStyles(styles)(MarkupPresentation))

import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import { FreightDirectQuoteCard } from "./freightDirectQuoteCard"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        // backgroundColor: theme.palette.background.paper,
    },
    paper_root: {
        ...theme.mixins.gutters(),
        paddingTop: "10px", // theme.spacing.unit * 2,
        paddingBottom: "10px", // theme.spacing.unit * 2,
    },
    quoteResult__container: {
        padding: "10px 50px",
        minHeight: "500px",
        height: "100%",
    },
    prevArrow__container: {
        left: "-60px",
        top: "50%",
        position: "absolute",
        transform: "translateY(-50%)",
        background: "LightGrey",
        "&.disabled": {
            opacity: ".3",
        },
    },
    arrow__icon: {
        width: "30px",
        height: "30px",
        color: "white",
    },
    nextArrow__container: {
        right: "-60px",
        top: "50%",
        position: "absolute",
        transform: "translateY(-50%)",
        background: "LightGrey",
        "&.disabled": {
            opacity: ".3",
        },
    },
    progress: {
        margin: theme.spacing(2),
        top: "50%",
        right: "50%",
        position: "absolute",
    },
    largeIcon: {
        width: 40,
        height: 40,
    },
    freightDirectQuotesContainer: {
        padding: "105px 0px",
    },
})

class FreightDirectQuotes extends Component {
    formatFXFDRates(rates) {
        let formattedRates = {
            basic: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirect__basic__title"
                        defaultMessage="Basic"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirect__basic"
                        defaultMessage="Front door, back door, or to the garage with no signature"
                    />
                ),
                rates: [],
            },
            basicPlus: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirect__basicPlus__title"
                        defaultMessage="Basic by Appointment"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirect__basicPlus"
                        defaultMessage="Front door, back door, or to the garage with signature"
                    />
                ),
                rates: [],
            },
            standard: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirect__standard__title"
                        defaultMessage="Standard"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirect__standard"
                        defaultMessage="To the first ground-level room"
                    />
                ),
                rates: [],
            },
            premium: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirect__premium__title"
                        defaultMessage="Premium"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirect__premium"
                        defaultMessage="Room of choice and packaging removal by request"
                    />
                ),
                rates: [],
            },
            assembly: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirect__assembly__title"
                        defaultMessage="Assembly"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirect__assembly"
                        defaultMessage="Room of choice, assembly and packaging removal by request"
                    />
                ),
                rates: [],
            },
        }

        rates.forEach(rate => {
            rate.rateQuotes.forEach(individualRate => {
                switch (individualRate.serviceLevel?.code) {
                    case "BASIC":
                        formattedRates.basic.rates.push(individualRate)
                        break
                    case "BASIC_APPT":
                        formattedRates.basicPlus.rates.push(individualRate)
                        break
                    case "STANDARD":
                        formattedRates.standard.rates.push(individualRate)
                        break
                    case "PREMIUM":
                        formattedRates.premium.rates.push(individualRate)
                        break
                    case "ASSEMBLY":
                        formattedRates.assembly.rates.push(individualRate)
                        break
                    default:
                        break
                }
            })
        })

        return formattedRates
    }
    render() {
        const {
            rates,
            isFetching,
            classes,
            isExpired,
            isShipped,
            getBol,
            canCreateShipments,
            isLoaded,
            canViewRateAmounts,
        } = this.props
        if (isFetching)
            return (
                <CircularProgress
                    className={classes.progress}
                    size={100}
                    color="secondary"
                />
            )
        if (isLoaded && (!rates || !rates.length)) {
            return (
                <div className="error-message">
                    <FormattedMessage
                        id="rateResults__noRates"
                        defaultMessage="No available rate quote."
                    />
                </div>
            )
        }

        const formattedRates = rates
            ? this.formatFXFDRates(rates)
            : this.state.formattedRates

        return (
            <Grid
                item
                container
                className={classes.freightDirectQuotesContainer}
                alignItems="flex-start"
            >
                {formattedRates &&
                    Object.keys(formattedRates).map((serviceLevel, i) => {
                        if (formattedRates[serviceLevel]?.rates.length === 0)
                            return null
                        return (
                            <Grid item container xs={3}>
                                <FreightDirectQuoteCard
                                    key={i}
                                    disabled={
                                        isExpired ||
                                        isShipped ||
                                        !canCreateShipments
                                    }
                                    canViewRateAmounts={canViewRateAmounts}
                                    serviceLevel={formattedRates[serviceLevel]}
                                    cardIndex={i}
                                    getBol={getBol}
                                />
                            </Grid>
                        )
                    })}
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({})

export default withStyles(styles)(
    connect(undefined, mapDispatchToProps)(FreightDirectQuotes)
)

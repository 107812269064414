import React, { Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"
import { Field } from "redux-form"
import { generateTimeList } from "../book/pickup/form"
import FormField from "../form/form-field"
import FormSelectAutocomplete from "../form/form-select-autocomplete"
import normalizePhone from "../util/normalizePhone"
import { FormattedMessage } from "react-intl"

const styles = theme => ({})

const PickUpInformation = ({ classes, country }) => (
    <Fragment>
        <Grid item container>
            <Grid item container xs={12}>
                <Typography className={classes.push__up} variant="h6">
                    <FormattedMessage
                        id="signUp.pickup__title"
                        defaultMessage="Pick Up Information "
                    />
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Field
                    name="location.readyTime"
                    component={FormSelectAutocomplete}
                    label={
                        <FormattedMessage
                            id="generalTerms.pickUpInformation__readyTime"
                            defaultMessage="Ready Time"
                        />
                    }
                    options={generateTimeList()}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    name="location.closeTime"
                    component={FormSelectAutocomplete}
                    label={
                        <FormattedMessage
                            id="generalTerms.pickUpInformation__closeTime"
                            defaultMessage="Close Time"
                        />
                    }
                    options={generateTimeList()}
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    name="location.pickupContact.name"
                    component={FormField}
                    label={
                        <FormattedMessage
                            id="generalTerms.pickUpInformation__pickupName"
                            defaultMessage="Pickup Name"
                        />
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    name="location.pickupContact.email.email_address"
                    component={FormField}
                    label={
                        <FormattedMessage
                            id="generalTerms__emailAddress"
                            defaultMessage="Email Address"
                        />
                    }
                />
            </Grid>
            <Grid item xs={8}>
                <Field
                    name="location.pickupContact.phone.phone_number"
                    component={FormField}
                    label={
                        <FormattedMessage
                            id="generalTerms__phoneNumber"
                            defaultMessage="Phone Number"
                        />
                    }
                    normalize={normalizePhone(country)}
                />
            </Grid>
            <Grid item xs={4}>
                <Field
                    name="location.pickupContact.phone.extension"
                    component={FormField}
                    label={
                        <FormattedMessage
                            id="generalTerms__extension"
                            defaultMessage="Extension"
                        />
                    }
                />
            </Grid>
        </Grid>
    </Fragment>
)

export default withStyles(styles)(PickUpInformation)

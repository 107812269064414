import React from "react"

import { Grid, Button } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

const ModalButtons = ({
    handleCancel,
    handleSave,
    disableSave,
    handleReset,
    trackGA,
    offshoreLocation,
    activeFilter,
}) => {
    return (
        <Grid
            style={{ marginTop: "16px" }}
            container
            justifyContent="space-between"
        >
            <Grid>
                <Button
                    onClick={() => {
                        handleReset()
                        trackGA(
                            `${offshoreLocation} / ${activeFilter}`,
                            "Edit Pricing",
                            "Reset"
                        )
                    }}
                    color="primary"
                    variant="outlined"
                >
                    <FormattedMessage
                        id="generalTerms__reset"
                        defaultMessage="Reset to Default"
                    />
                </Button>
            </Grid>
            <Grid>
                <Button
                    style={{ marginRight: "12px" }}
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                        handleCancel()
                        trackGA(
                            `${offshoreLocation} / ${activeFilter}`,
                            "Edit Pricing",
                            "Cancel"
                        )
                    }}
                >
                    <FormattedMessage
                        id="generalTerms__cancel"
                        defaultMessage="Cancel"
                    />
                </Button>
                <Button
                    disabled={disableSave}
                    onClick={() => {
                        handleSave()
                        trackGA(
                            `${offshoreLocation} / ${activeFilter}`,
                            "Edit Pricing",
                            "Save"
                        )
                    }}
                    color="primary"
                    variant="contained"
                >
                    <FormattedMessage
                        id="generalTerms__save"
                        defaultMessage="Save"
                    />
                </Button>
            </Grid>
        </Grid>
    )
}

export default ModalButtons

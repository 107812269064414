import React from "react"
import { Typography } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { FormattedMessage, useIntl } from "react-intl"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/styles"
import formDatepicker from "../../../form/form-datepicker"
import { useRef } from "react"
import { useEffect } from "react"
import { Field } from "redux-form"
import { nextBusinessDay } from "../selector"
import moment from "moment/moment"

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    paper: {
        minWidth: "650px",
    },

    select__container: {
        width: "100%",
    },
    dialog__container: {},
    select__field: {
        width: "100%",
    },
    resiChips: {
        paddingTop: "10px",
    },
    resiCheckDescription2: {
        paddingTop: "15px",
    },
    icon__close: {
        marginTop: "-12px",
    },
})

const NextDayPickUpDialog = ({
    isDialogOpen,
    inputProps,
    shipDateRef,
    gaCategory,
}) => {
    const classes = useStyles()
    const intl = useIntl()
    return (
        <Dialog
            open={isDialogOpen}
            classes={{
                paper: classes.paper,
            }}
        >
            <DialogTitle id="in-bond-dialog-title">
                <Grid container item direction="row">
                    <Grid item container xs={12} justify="space-between">
                        <Grid item>
                            <FormattedMessage
                                id="getRates__next_day__title"
                                defaultMessage="PickUp Date Check"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={10}></Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container justify="center" direction="row">
                    <Grid item container xs={12}>
                        <Grid item container>
                            <Typography variant="body2">
                                <FormattedMessage
                                    id="getRates__mexico__indirect"
                                    defaultMessage="Same day pickup is not available for this location, please update your pickup date: "
                                />
                            </Typography>
                        </Grid>
                        <Field
                            component={formDatepicker}
                            name="pickupDate"
                            label={[
                                "* ",
                                <FormattedMessage
                                    id="bookShipment.basicInfo__shipDate"
                                    defaultMessage="Ship Date"
                                />,
                            ]}
                            placeholder={intl.formatMessage({
                                id: "getRates.form__date",
                                defaultMessage: "Date",
                            })}
                            minDate={nextBusinessDay()}
                            maxDate={moment
                                .utc(
                                    moment()
                                        .tz("Pacific/Honolulu")
                                        .format("YYYY-MM-DD")
                                )
                                .add(1, "year")}
                            InputProps={inputProps}
                            inputRef={shipDateRef}
                            category={gaCategory}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default NextDayPickUpDialog

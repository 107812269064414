import {
    EMAIL_CONTACT_FIELD_CHANGE,
    EDIT_EMAIL_CONTACT,
    EMAIL_CONTACT_SELECT,
    SAVE_EMAIL_CONTACT,
    REMOVE_EMAIL_CONTACT,
    IMAGE_EMAIL_SENDING,
    IMAGE_EMAIL_SENT,
} from "../actions/email-image"

function emailItem(
    state = {
        name: "",
        email: "",
        id: "",
        editable: true,
    },
    action = {}
) {
    switch (action.type) {
        case EMAIL_CONTACT_FIELD_CHANGE:
            return {
                ...state,
                id: undefined,
                [action.field]: action.value,
            }
        case EDIT_EMAIL_CONTACT:
            return { ...state, editable: true }
        case EMAIL_CONTACT_SELECT:
            return {
                ...state,
                id: action.contact._id,
                name: action.contact.name,
                email: action.contact.email.emailAddress,
                editable: false,
            }
        case SAVE_EMAIL_CONTACT:
            return { ...state, editable: false }
        default:
            return state
    }
}

export function emailImage(
    state = {
        recipients: [emailItem()],
        sendInProgress: false,
    },
    action
) {
    switch (action.type) {
        case EMAIL_CONTACT_FIELD_CHANGE:
        case EDIT_EMAIL_CONTACT:
            return {
                ...state,
                recipients: state.recipients.map((val, i) =>
                    i === action.index ? emailItem(val, action) : val
                ),
            }
        case EMAIL_CONTACT_SELECT:
        case SAVE_EMAIL_CONTACT: {
            const retVal = {
                ...state,
                recipients: state.recipients.map((val, i) =>
                    i === action.index ? emailItem(val, action) : val
                ),
            }
            return retVal.recipients.every(it => it.editable === false)
                ? {
                      ...retVal,
                      recipients: [...retVal.recipients, emailItem()],
                  }
                : retVal
        }
        case REMOVE_EMAIL_CONTACT:
            return {
                ...state,
                recipients: state.recipients.filter(
                    (_, i) => i !== action.index
                ),
            }
        case IMAGE_EMAIL_SENDING:
            return { ...state, sendInProgress: true }
        case IMAGE_EMAIL_SENT:
            return { ...state, sendInProgress: false }
        default:
            return state
    }
}

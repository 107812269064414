import React, { useState } from "react"
import {
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    Table,
} from "@material-ui/core"
import * as sortFunctions from "../../util/sort-functions"
import { Typography } from "@material-ui/core"
import moment from "moment/moment"

const AdminTable = ({
    discountsToMap,
    page,
    rowsPerPage,
    classes,
    isSearch,
    tableHeaders,
}) => {
    const [order, setOrder] = useState("asc")
    const [orderBy, setOrderBy] = useState("accountType")

    const createSortHandler = property => event => {
        handleRequestSort(property, event)
    }

    const handleRequestSort = property => {
        const isAsc = orderBy === property && order === "asc"
        const setOrderValue = isAsc ? "desc" : "asc"
        setOrder(setOrderValue)
        setOrderBy(property)
    }

    const formatText = (text, type) => {
        switch (type) {
            case "text":
                return text
            case "$":
                return "$" + text
            case "%":
                return text + "%"
            case "date":
                return moment(text).format("YYYY-MM-DD")
            default:
                return text
        }
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {tableHeaders.map(headCell => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align}
                            sortDirection={
                                orderBy === headCell.id ? order : "asc"
                            }
                            className={
                                headCell.id === "accountNumber"
                                    ? classes.fixed__column
                                    : ""
                            }
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={
                                    orderBy === headCell.id ? order : "asc"
                                }
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {discountsToMap.length !== 0 &&
                    sortFunctions
                        .stableSort(
                            discountsToMap,
                            sortFunctions.getComparator(order, orderBy)
                        )
                        .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`
                            return (
                                <TableRow
                                    tabIndex={-1}
                                    key={row.accountNumber ?? index}
                                >
                                    <TableCell
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                        className={classes.fixed__column}
                                        align={tableHeaders[0].align}
                                    >
                                        {row.accountNumber ??
                                            row[tableHeaders[0].id]}
                                    </TableCell>
                                    {tableHeaders.slice(1).map((cell, j) => {
                                        const obj = tableHeaders[j + 1]
                                        let formattedText = "---"
                                        if (row[obj.id]) {
                                            formattedText = formatText(
                                                row[obj.id],
                                                obj.type
                                            )
                                        } else if (row[obj.altId]) {
                                            formattedText = formatText(
                                                row[obj.altId],
                                                obj.altType
                                            )
                                        }
                                        return (
                                            <TableCell align={obj.align}>
                                                {formattedText}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                {!discountsToMap.length && isSearch && (
                    <TableRow>
                        <TableCell align="left" variant="footer">
                            <Typography variant="body2" color="secondary">
                                No results
                            </Typography>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    )
}

export default AdminTable

import React, { Fragment } from "react"
import { get } from "lodash"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import Paper from "@material-ui/core/Paper"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ContactSearchItem from "./contactSearchItem"
import QuoteSearchItem from "./quoteSearchItem"
import ShipmentSearchItem from "./shipmentSearchItem"
import { changePath } from "../../actions"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    paper: {
        maxHeight: 500,
        minWidth: 450,
        overflow: "scroll",
        padding: "12px",
    },
})

const minLength = (item, length = 1) => item && item.length >= length

const Result = ({
    term,
    shipments,
    contacts,
    quotes,
    classes,
    searchInProgress,
    selectItem,
    onSelect,
}) => {
    if (!minLength(term, 3)) return null
    if (searchInProgress) {
        return (
            <Paper className={classes.paper}>
                <CircularProgress color="secondary" />
            </Paper>
        )
    }
    if (!minLength(shipments) && !minLength(quotes) && !minLength(contacts)) {
        return (
            <Paper className={classes.paper}>
                <Typography variant="body2">
                    <FormattedMessage
                        id="generalTerms__noResults"
                        defaultMessage="No results found"
                    />
                </Typography>
            </Paper>
        )
    }

    const dataPoints = [
        {
            data: shipments,
            Comp: ShipmentSearchItem,
            onClick: value => {
                selectItem("track", value)
                onSelect()
            },
        },
        {
            data: quotes,
            Comp: QuoteSearchItem,
            onClick: (version, value) => {
                if (version === "V1") {
                    selectItem("rate", value)
                } else {
                    selectItem("book", value)
                }
                onSelect()
            },
        },
        {
            data: contacts,
            Comp: ContactSearchItem,
            onClick: value => {
                selectItem("address", value)
                onSelect()
            },
        },
    ]
    return (
        <Paper className={`${classes.paper} search-result`}>
            {dataPoints.map(({ data, Comp, onClick }, i) =>
                data && data.length ? (
                    <Fragment key={i}>
                        {data.map((item, key) => (
                            <Comp
                                {...item}
                                term={term}
                                key={key}
                                onClick={onClick}
                            />
                        ))}
                    </Fragment>
                ) : null
            )}
        </Paper>
    )
}

Result.propTypes = {
    term: PropTypes.string,
    shipments: PropTypes.array,
    quotes: PropTypes.array,
    contacts: PropTypes.array,
}

Result.defaultProps = {
    term: undefined,
    shipments: undefined,
    quotes: undefined,
    contacts: undefined,
}

const mapStateToProps = (state, props) => ({
    shipments: get(state, `shipment.search.${get(props, "term")}`),
    contacts: get(state, `contact.search.dashboard.${get(props, "term")}`),
    quotes: get(state, `quotes.list.search.${get(props, "term")}`),
})

const mapDispatchToProps = dispatch => ({
    selectItem: (resource, id) => dispatch(changePath(`/${resource}/${id}`)),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(Result)
)

import moment from "moment"
import "moment-timezone"

export const getReadyTime = () => {
    let now = moment.tz("America/New_York")
    let m = now.minutes()
    if (m > 0 && m <= 30) {
        now.minutes(30)
    } else if (m > 30) {
        now.add(1, "hour").minutes(0)
    } else if (m === 0) {
        now.minutes(0)
    }
    return now.format("h:mm A")
}

export const getCloseTime = () => {
    let now = moment.tz("America/New_York")
    let minutes = now.minutes()
    if (minutes > 0 && minutes <= 30) {
        now.minutes(30)
    } else if (minutes > 30) {
        now.add(1, "hour").minutes(0)
    } else if (minutes === 0) {
        now.minutes(0)
    }
    now.add(90, "minutes")
    let onePM = moment
        .tz("America/New_York")
        .hours(13)
        .minutes(0)
        .seconds(0)
    return now.isAfter(onePM) ? now.format("h:mm A") : onePM.format("h:mm A")
}

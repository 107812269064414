import React from "react"
import { connect } from "react-redux"
import { findLastIndex } from "lodash"
import createSelector from "re-reselect"
import PropTypes from "prop-types"
import moment from "moment"
import { withStyles } from "@material-ui/core/styles"
import {
    Stepper,
    Step,
    StepLabel,
    Grid,
    Card,
    Typography,
} from "@material-ui/core"
import { isGuaranteedQuote } from "../../quote/card/util"
import { FormattedMessage } from "react-intl"
import { weightUnit } from "../../util/units"

const styles = theme => ({
    root: {
        width: "100%",
        padding: "10px",
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    timeline_date: {
        fontWeight: "bold",
    },
    timeline: {
        height: "100%",
        width: "100%",
    },
    dashboard: {
        "box-shadow": "none",
    },
    serviceLevel_text: {
        color: "green",
    },
})

const customImageStyles = { width: "30px" }

const customImages = {
    oneQuarter: () => (
        <img style={customImageStyles} src="static/1_4.png" alt="oneQuarter" />
    ),
    half: () => (
        <img style={customImageStyles} src="static/1_2.png" alt="half" />
    ),
    threeQuarters: () => (
        <img
            style={customImageStyles}
            src="static/3_4.png"
            alt="threeQuarters"
        />
    ),
    complete: () => (
        <img style={customImageStyles} src="static/full.png" alt="complete" />
    ),
    warning: () => (
        <img style={customImageStyles} src="static/orange.png" alt="warning" />
    ),
}

const MasterTimeline = ({
    classes,
    shipment,
    isDashboard,
    isGuaranteed,
    statusSteps,
    activeIndex,
    identifiers,
    statusCount,
    totalWeight,
    preferredSystemOfMeasurement,
    pickupDate,
    isChildLeg,
}) => {
    const determineStepIconComponent = percentCompleted => {
        if (percentCompleted > 0 && percentCompleted <= 25) {
            return customImages.oneQuarter
        } else if (percentCompleted > 25 && percentCompleted <= 50) {
            return customImages.half
        } else if (percentCompleted > 50 && percentCompleted < 100) {
            return customImages.threeQuarters
        } else if (percentCompleted >= 100 || percentCompleted === 0) {
            return null
        }
    }

    const constructTimeLabel = (earliest, latest, isDelivered) => {
        if (!earliest && !latest) {
            return undefined
        }
        if (isDelivered) {
            if (moment(earliest).isSame(moment(latest))) {
                return moment.utc(latest).format("dddd MMM Do")
            } else {
                return `${moment.utc(earliest).format("MMM Do")} - ${moment
                    .utc(latest)
                    .format("MMM Do")}`
            }
        } else {
            return moment.utc(latest).format("dddd MMM Do")
        }
    }

    const isFreightDirect = shipment?.rate?.mode === "LTL_DIRECT"
    const serviceLevel = shipment?.rate?.serviceLevel

    return (
        <Card
            className={isDashboard ? classes.dashboard : classes.root}
            elevation={2}
            style={{ marginTop: "10px" }}
        >
            <Grid item container justifyContent="space-between">
                <Grid>
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="tracking__shipmentStatus"
                            defaultMessage="Shipment Status"
                        />
                    </Typography>
                    {isChildLeg ? (
                        <Typography>
                            ({shipment?.origin?.address?.country} →{" "}
                            {shipment?.destination?.address?.country})
                        </Typography>
                    ) : null}
                </Grid>
                <Grid>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="tracking__totalShipmentWeight"
                            defaultMessage="Total Shipment Weight"
                        />
                        {`: ${totalWeight}${weightUnit(
                            preferredSystemOfMeasurement
                        )}`}
                    </Typography>
                </Grid>
            </Grid>
            <Stepper
                alternativeLabel
                activeStep={
                    activeIndex === statusSteps.length - 1
                        ? statusSteps.length
                        : activeIndex
                }
                className={classes.timeline}
            >
                {statusSteps.map((item, index) => {
                    const {
                        statusIdentifier = "",
                        status,
                        count,
                        earliestEventTime,
                        latestEventTime,
                        completed,
                    } = item || {}
                    let statusTitle = ""
                    if (statusIdentifier === "DELIVERED" && count === 0) {
                        statusTitle = (
                            <FormattedMessage
                                id="generalTerms__expectedDelivery"
                                defaultMessage="EXPECTED DELIVERY"
                            />
                        )
                    } else if (statusIdentifier === "CANCELED") {
                        statusTitle = (
                            <FormattedMessage
                                id="generalTerms__canceled"
                                defaultMessage="CANCELED"
                            />
                        )
                    } else {
                        statusTitle = status
                    }

                    const percentCompleted = (item.count / statusCount) * 100
                    const isDelivered = statusIdentifier === "DELIVERED"
                    const timeLabel = constructTimeLabel(
                        earliestEventTime,
                        latestEventTime,
                        isDelivered
                    )

                    return (
                        <Step key={index} completed={completed}>
                            <StepLabel
                                StepIconComponent={determineStepIconComponent(
                                    percentCompleted
                                )}
                            >
                                <Grid
                                    item
                                    container
                                    direction="column"
                                    justify="center"
                                >
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        justify="center"
                                    >
                                        <Typography justify="center">
                                            {statusTitle}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justify="center"
                                        direction="row"
                                    >
                                        <Grid
                                            item
                                            container
                                            direction="row"
                                            xs={12}
                                            justify="center"
                                        >
                                            {timeLabel &&
                                                statusIdentifier !==
                                                    "DELIVERED" && (
                                                    <Typography
                                                        variant="caption"
                                                        justify="center"
                                                        className={
                                                            classes.timeline_date
                                                        }
                                                    >
                                                        {timeLabel}
                                                    </Typography>
                                                )}
                                        </Grid>
                                        {index === 0 &&
                                            !!identifiers?.pickupNumber && (
                                                <Grid
                                                    item
                                                    container
                                                    justify="center"
                                                >
                                                    <Typography variant="caption">
                                                        [
                                                        <FormattedMessage
                                                            id="tracking__PU"
                                                            defaultMessage="PU"
                                                        />
                                                        , {" #: "},
                                                        {
                                                            identifiers?.pickupNumber
                                                        }
                                                        ]
                                                    </Typography>
                                                </Grid>
                                            )}
                                    </Grid>
                                    {statusIdentifier === "DELIVERED" && (
                                        <Grid
                                            item
                                            container
                                            justify="center"
                                            xs={12}
                                        >
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                xs={12}
                                                justify="center"
                                            >
                                                {!shipment?.rate
                                                    ?.isDomesticOffshore && (
                                                    <Typography
                                                        variant="caption"
                                                        justify="center"
                                                        className={
                                                            classes.timeline_date
                                                        }
                                                    >
                                                        {timeLabel && (
                                                            <Typography
                                                                variant="caption"
                                                                justify="center"
                                                                className={
                                                                    classes.timeline_date
                                                                }
                                                            >
                                                                {timeLabel}
                                                            </Typography>
                                                        )}
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                    {!completed &&
                                        statusIdentifier === "DELIVERED" && (
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                xs={12}
                                                justify="center"
                                            >
                                                {isGuaranteed && (
                                                    <Typography
                                                        justify="center"
                                                        variant={"caption"}
                                                        className={
                                                            classes.serviceLevel_text
                                                        }
                                                    >
                                                        <FormattedMessage
                                                            id="tracking__guaranteed"
                                                            defaultMessage="Guaranteed"
                                                        />
                                                    </Typography>
                                                )}
                                                {isFreightDirect && (
                                                    <Typography
                                                        justify="center"
                                                        variant={"caption"}
                                                        color="primary"
                                                    >
                                                        {
                                                            serviceLevel?.description
                                                        }
                                                    </Typography>
                                                )}
                                            </Grid>
                                        )}
                                    {statusIdentifier === "PICKED UP" &&
                                    count === 0 ? (
                                        <Grid
                                            item
                                            container
                                            direction="row"
                                            xs={12}
                                            justify="center"
                                        >
                                            <Typography
                                                variant="caption"
                                                justify="center"
                                                className={
                                                    classes.timeline_date
                                                }
                                            >
                                                <FormattedMessage
                                                    id="scheduled___pickUpDate"
                                                    defaultMessage="Scheduled "
                                                />
                                            </Typography>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                xs={12}
                                                justify="center"
                                            >
                                                <Typography
                                                    variant="caption"
                                                    justify="center"
                                                    className={
                                                        classes.timeline_date
                                                    }
                                                >
                                                    {moment
                                                        .utc(pickupDate)
                                                        .format("dddd MMM Do")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                    {percentCompleted !== 100 &&
                                        percentCompleted !== 0 &&
                                        statusIdentifier !== "CANCELED" && (
                                            <Grid
                                                item
                                                container
                                                justify="center"
                                            >
                                                <Typography variant="caption">
                                                    <FormattedMessage
                                                        id="tracking__units"
                                                        defaultMessage="{count}/{statusCount} units"
                                                        values={{
                                                            count,
                                                            statusCount,
                                                        }}
                                                    />
                                                </Typography>
                                            </Grid>
                                        )}
                                </Grid>
                            </StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </Card>
    )
}

MasterTimeline.propTypes = {
    classes: PropTypes.object,
}

export const statusStepSelector = createSelector(
    (state, { shipmentId }) =>
        state?.shipment?.list?.[shipmentId]?.shipment ??
        state?.shipment?.activeShipment?.shipment,
    (state, { handlingUnits }) =>
        [].concat(
            ...(handlingUnits?.map(
                hu =>
                    hu?.details?.map(details => ({
                        ...hu,
                        ...details,
                    })) ?? []
            ) ?? [])
        ),
    (shipment, flatHandlingUnits) => {
        // For FedEx shipments this will be H/U size (because of assigned PROs), for other shipments it will be 1
        // we dont count repro Hus
        const statusCount =
            flatHandlingUnits.filter(hu => !hu?.rePro).length ?? 1
        let status = flatHandlingUnits?.[0]?.status
        let firstStepName
        if (shipment?.isPickupLater) {
            firstStepName = (
                <FormattedMessage
                    id="tracking__created"
                    defaultMessage="CREATED"
                />
            )
        } else if (status?.currentStatus?.code === "CANCELED") {
            firstStepName = (
                <FormattedMessage
                    id="tracking__canceled"
                    defaultMessage="CANCELED"
                />
            )
        } else {
            firstStepName = (
                <FormattedMessage
                    id="tracking__dispatched"
                    defaultMessage="DISPATCHED"
                />
            )
        }

        const bookedAt = shipment?.booked_at

        let steps = {
            [firstStepName]: {
                status: firstStepName,
                statusIdentifier: firstStepName?.props?.defaultMessage,
                latestEventTime: moment.utc(bookedAt),
                completed: true,
                count: statusCount,
            },
            PICKED_UP: {
                status: (
                    <FormattedMessage
                        id="tracking__pickedUp"
                        defaultMessage="PICKED UP"
                    />
                ),
                statusIdentifier: "PICKED UP",
                count: 0,
            },
            IN_TRANSIT: {
                status: (
                    <FormattedMessage
                        id="tracking__inTransit"
                        defaultMessage="IN TRANSIT"
                    />
                ),
                statusIdentifier: "IN TRANSIT",
                count: 0,
            },
            OUT_FOR_DELIVERY: {
                status: (
                    <FormattedMessage
                        id="tracking__outForDelivery"
                        defaultMessage="OUT FOR DELIVERY"
                    />
                ),
                statusIdentifier: "OUT FOR DELIVERY",
                count: 0,
            },
            DELIVERED: {
                status: (
                    <FormattedMessage
                        id="tracking__delivered"
                        defaultMessage="DELIVERED"
                    />
                ),
                statusIdentifier: "DELIVERED",
                count: 0,
            },
        }

        let activeIndex = 0

        if (shipment) {
            flatHandlingUnits
                .map(x => x.status)
                .forEach(status => {
                    const history = [
                        status?.currentStatus,
                        ...(status?.statusUpdateHistory ?? []),
                    ].filter(x => x)
                    const stepTracker = {
                        hit: {},
                    }

                    history.forEach(event => {
                        let mappedCode = event?.code
                        const step = steps[mappedCode]
                        if (!step) return
                        let eventTime =
                            mappedCode !== "DELIVERED" &&
                            moment.utc(event.timestamp)
                        stepTracker.hit[mappedCode] = true //mark down that this step (PICKED_UP, etc) has been hit for this handling unit
                        // if we have no earliest or latest times, add this event's time
                        // if we find a event time for a given step which is earlier or later than our current earliest or latest, update
                        // this way, for each step, we will have the earliest and latest time a tracking event was received for a particular tracking event - doesn't matter which handling unit
                        if (
                            moment(steps[mappedCode].earliestEventTime).isAfter(
                                moment(eventTime)
                            ) ||
                            !steps[mappedCode].earliestEventTime
                        ) {
                            steps[mappedCode].earliestEventTime = eventTime
                        }
                        if (
                            moment(steps[mappedCode].latestEventTime).isBefore(
                                moment(eventTime)
                            ) ||
                            !steps[mappedCode].latestEventTime
                        ) {
                            steps[mappedCode].latestEventTime = eventTime
                        }
                    })
                    Object.keys(steps).forEach(step => {
                        //go through our steps, and count up how many handling units hit each step
                        // do not count the new extra Hus
                        if (stepTracker.hit[step]) {
                            steps[step].count += 1
                        }
                    })

                    if (shipment?.rate?.transitDays > 0) {
                        let deliveryDateTime =
                            status?.actualDeliveryDateTime ||
                            status?.estimatedDeliveryDateTime ||
                            (shipment?.rate?.deliveryDateRange
                                ? moment.utc(
                                      shipment?.rate.deliveryDateRange
                                          ?.earliestEstimatedDate
                                  )
                                : moment.utc(shipment?.rate?.deliveryDateTime))

                        // this means that for a given handling unit, its delivery date time is the estimated or actual one from the tracking if we have it, otherwise its the scheduled one from the quote

                        //for each handling unit, the delivery date time is either the estimated or actual delivery time on the status
                        if (
                            moment(steps.DELIVERED.earliestEventTime).isAfter(
                                moment(deliveryDateTime)
                            ) ||
                            !steps.DELIVERED.earliestEventTime
                        ) {
                            steps.DELIVERED.earliestEventTime = deliveryDateTime
                        }

                        if (shipment?.rate?.deliveryDateRange) {
                            deliveryDateTime = moment.utc(
                                shipment?.rate.deliveryDateRange
                                    ?.latestEstimatedDate
                            )
                        }
                        if (
                            moment(steps.DELIVERED.latestEventTime).isBefore(
                                moment(deliveryDateTime)
                            ) ||
                            !steps.DELIVERED.latestEventTime
                        ) {
                            steps.DELIVERED.latestEventTime = deliveryDateTime
                        }
                    }
                })

            Object.keys(steps).forEach(step => {
                // count original Hus. discarded reproHu
                //go through our steps, and determine if the step is complete
                const percentCompleted = (steps[step].count / statusCount) * 100
                if (percentCompleted >= 100) {
                    steps[step].completed = true
                }
            })
        }

        const statusSteps = [
            steps[firstStepName],
            steps["PICKED_UP"],
            steps["IN_TRANSIT"],
            steps["OUT_FOR_DELIVERY"],
            steps["DELIVERED"],
        ] //make an array out of the status steps object
        activeIndex = findLastIndex(statusSteps, item => item?.completed)
        // active index is the further step to the right which has been completed, used for dashboard display
        return { statusSteps, activeIndex, statusCount }
    }
)((state, { shipmentId }) => `${shipmentId}`)

const isGuaranteedSelector = createSelector(
    (state, { shipmentId }) =>
        state?.shipment?.list?.[shipmentId]?.shipment?.rate?.serviceLevel?.code,
    state => state?.accessorials?.list?.guaranteed ?? [],
    (serviceCode, accessorials) => isGuaranteedQuote(serviceCode, accessorials)
)(
    (state, { shipmentId }) =>
        state?.shipment?.list?.[shipmentId]?.shipment?.rate?.serviceLevel
            ?.code ?? ""
)

const mapStateToProps = (state, props) => ({
    isGuaranteed: isGuaranteedSelector(state, props),
    ...statusStepSelector(state, props),
})

export default withStyles(styles)(connect(mapStateToProps)(MasterTimeline))

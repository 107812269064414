import React, { useState } from "react"
import {
    Button,
    Collapse,
    Grid,
    IconButton,
    Paper,
    Typography,
} from "@material-ui/core"
import {
    RateDeliveryDate,
    RateDeliveryTime,
    RateFreightDirectServiceLevel,
    RateGuaranteed,
    rateTotalAmount,
} from "../summaryCards/RatesSummary"
import { makeStyles } from "@material-ui/styles"
import { CarrierLogo } from "../../../util"
import { FormattedMessage } from "react-intl"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import RateBreakdown from "../../../dashboard/components/rateBreakdown"
import { containsCombinedOtherRatesAndFedexOceanRates } from "../../../util/carrierLogoUtils"

const useStyles = makeStyles({
    paper: {
        width: "100%",
        display: "flex",
        padding: "15px",
        flexWrap: "wrap",
    },
    rateDetails: {
        padding: "12px 0",
    },
    serviceLevel_text: {
        color: "green",
    },
    priceDetails: {
        borderTop: "solid 1px #D4D4D4",
        padding: "12px 0 0px 0",
    },
    rateBreakdown: {
        paddingTop: "12px",
    },
})

export default function BookSquare({
    trackGA,
    selectedRate = { rateQuoteDetail: {}, serviceLevel: {} },
    isFreightDirect,
    isFreightDirectReturns,
    canViewRateAmounts,
    disabled,
    onBookShipment,
    isExpanded,
    setIsExpanded,
    destination,
}) {
    const classes = useStyles()

    const { preferredCurrencyCode, capacityProviderQuoteNumber } = selectedRate
    const rateTotal = rateTotalAmount(selectedRate, canViewRateAmounts)
    const hasDetailedCharges =
        selectedRate?.rateQuoteDetail?.charges?.length > 0

    return (
        <Paper className={classes.paper}>
            <Grid item container alignContent="flex-start">
                <Grid item container justify="center">
                    <Button
                        id="bookSubmit"
                        color="primary"
                        variant="contained"
                        disabled={disabled}
                        type="submit"
                        onClick={onBookShipment}
                    >
                        <FormattedMessage
                            id="orderDetails.book__submit"
                            defaultMessage="Book Shipment"
                        />
                    </Button>
                </Grid>
                <Grid
                    item
                    container
                    className={classes.rateDetails}
                    alignItems="center"
                >
                    <Grid item container>
                        <Grid
                            item
                            container
                            xs={8}
                            direction="column"
                            justify="flex-end"
                        >
                            <RateFreightDirectServiceLevel
                                rate={selectedRate}
                                isFreightDirect={isFreightDirect}
                                isFreightDirectReturns={isFreightDirectReturns}
                                variant="subtitle1"
                            />
                            <RateDeliveryDate rate={selectedRate} />
                            <RateDeliveryTime
                                rate={selectedRate}
                                isFreightDirect={isFreightDirect}
                            />
                            <RateGuaranteed
                                rate={selectedRate}
                                canViewRateAmounts={canViewRateAmounts}
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <CarrierLogo
                                carrierCode={selectedRate?.carrierCode}
                                mode={selectedRate?.mode}
                                serviceLevel={selectedRate?.serviceLevel}
                                containsCombinedOtherRatesAndFedexOceanRates={containsCombinedOtherRatesAndFedexOceanRates(
                                    selectedRate
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {!!rateTotal && (
                    <Grid item container className={classes.priceDetails}>
                        <Grid
                            item
                            container
                            justify="flex-end"
                            className={classes.rateBreakdown}
                        >
                            <Grid
                                item
                                container
                                justify="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    variant="subtitle1"
                                    color="secondary"
                                >
                                    {rateTotal}
                                </Typography>
                                {hasDetailedCharges && (
                                    <Grid item>
                                        <IconButton
                                            onClick={() => {
                                                setIsExpanded(!isExpanded)
                                                trackGA(
                                                    "Book Shipment - Review",
                                                    isExpanded
                                                        ? "Unexpand Pricing"
                                                        : "Expand Pricing"
                                                )
                                            }}
                                        >
                                            {isExpanded ? (
                                                <ExpandLessIcon id="expandLessIconSelectedReate" />
                                            ) : (
                                                <ExpandMoreIcon id="expandMoreIconSelectedReate" />
                                            )}
                                        </IconButton>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid
                                item
                                container
                                justify="flex-end"
                                className={classes.rateBreakdown}
                            >
                                <Collapse in={isExpanded}>
                                    <RateBreakdown
                                        listView={false}
                                        charges={
                                            selectedRate?.rateQuoteDetail
                                                ?.charges
                                        }
                                        usePreferredCurrency
                                        totalInPreferredCurrency={
                                            selectedRate?.rateQuoteDetail
                                                ?.totalInPreferredCurrency
                                                ? selectedRate?.rateQuoteDetail
                                                      ?.totalInPreferredCurrency
                                                : selectedRate?.rateQuoteDetail
                                                      ?.total
                                        }
                                        preferredCurrencyCode={
                                            preferredCurrencyCode
                                        }
                                        gridSize={12}
                                        quoteID={capacityProviderQuoteNumber}
                                    />
                                </Collapse>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Paper>
    )
}

import React, { useContext, useState, createContext } from "react"
import { goFetch } from "../../http"
import printJS from "print-js"
import FileSaver from "file-saver"

const Context = createContext({
    documentList: [],
    locationId: null,
    uploadFile: () => {},
    createFile: () => {},
    getDocument: () => {},
    printFile: () => {},
    refreshDocumentList: () => {},
    setLocationId: () => {},
    associateDocumentsToShipment: () => {},
    checkFilenameCollision: () => {},
})

export const useCustomsProfileContext = () => {
    const customsProfileContext = useContext(Context)
    if (!customsProfileContext) {
        throw new Error(
            "Cannot use customs profile context outside of CustomsProfileProvider"
        )
    }
    return customsProfileContext
}

const documentCategories = Object.freeze([
    {
        documentCategory: "USMCA",
        urlPrefix: "usmca",
        uploadUrl: `/documents/generated/usmca/upload`,
    },
    {
        documentCategory: "certificateOfOrigin",
        urlPrefix: "certificateOfOrigin",
        uploadUrl: `/documents/generated/certificateOfOrigin/profile/upload`,
    },
    //{ documentCategory: "commercialInvoice", urlPrefix: "commercialInvoice" },
])

const getDocumentTypePrefix = documentCategory => {
    return documentCategories.find(x => x.documentCategory === documentCategory)
        ?.urlPrefix
}

const getDocumentTypeUploadUrl = documentCategory => {
    return documentCategories.find(x => x.documentCategory === documentCategory)
        ?.uploadUrl
}

const blobPdfFromBase64String = base64String => {
    const byteArray = Uint8Array.from(
        atob(base64String)
            .split("")
            .map(char => char.charCodeAt(0))
    )
    return new Blob([byteArray], { type: "application/pdf" })
}

const getDocumentList = async locationId => {
    if (!locationId) return

    return (
        await Promise.all(
            documentCategories.map(async ({ documentCategory, urlPrefix }) => {
                const url = `/documents/versioned/${urlPrefix}/profile/location/${locationId}`

                try {
                    const { data } = await goFetch(
                        url,
                        {
                            method: "GET",
                            credentials: "same-origin",
                            headers: {
                                "cache-control": "no-cache",
                            },
                        },
                        true
                    )
                    return data?.map(x => ({ documentCategory, ...x })) ?? []
                } catch (e) {
                    return []
                }
            })
        )
    ).reduce((prev, current) => [...prev, ...current], [])
}

const getDocument = async (
    locationId,
    documentCategory,
    customFilename,
    format
) => {
    const documentTypePrefix = getDocumentTypePrefix(documentCategory)
    if (!locationId || !documentTypePrefix) return

    const url = `/documents/versioned/${documentTypePrefix}/profile/image/locationId/${locationId}/customFilename/${customFilename}`

    const { data } = await goFetch(
        url,
        {
            params: { format },
            method: "GET",
            credentials: "same-origin",
            headers: {
                "cache-control": "no-cache",
            },
        },
        true
    )
    return { documentCategory, ...data }
}

const uploadFile = async (locationId, documentCategory, values) => {
    if (!locationId) return

    const { data } = await goFetch(
        getDocumentTypeUploadUrl(documentCategory),
        {
            data: values,
            method: "POST",
            credentials: "same-origin",
            headers: {
                "cache-control": "no-cache",
            },
        },
        true
    )
    return { documentCategory, ...data }
}

export const createFile = async (documentCategory, values) => {
    const documentTypePrefix = getDocumentTypePrefix(documentCategory)
    if (!documentTypePrefix) return

    const { data } = await goFetch(
        `/documents/generated/${documentTypePrefix}`,
        {
            data: values,
            method: "POST",
            credentials: "same-origin",
            headers: {
                "cache-control": "no-cache",
            },
        },
        true
    )
    return { documentCategory, ...data }
}

const printFile = async (locationId, documentCategory, customFilename) => {
    try {
        const document = await getDocument(
            locationId,
            documentCategory,
            customFilename,
            "pdf"
        )
        const blobURIData = URL.createObjectURL(
            blobPdfFromBase64String(document?.content)
        )
        printJS({
            printable: blobURIData,
            type: "pdf",
            onError: () =>
                FileSaver.saveAs(
                    blobURIData,
                    `${documentCategory}-${customFilename}.pdf`
                ),
        })
    } catch (error) {}
}

export const associateDocumentsToShipment = async (
    locationId,
    selectedDocuments,
    shipmentId
) => {
    const documentsByType = selectedDocuments.reduce((prev, x) => {
        prev[x.documentCategory] = [...(prev[x.documentCategory] ?? []), x]
        return prev
    }, {})
    await Promise.all(
        Object.keys(documentsByType).map(async documentCategory => {
            const documentTypePrefix = getDocumentTypePrefix(documentCategory)
            if (!locationId || !documentTypePrefix) return

            const values = {
                internalTrackingNumber: shipmentId,
                documentIdList: documentsByType[documentCategory].map(
                    x => x.documentId
                ),
            }

            await goFetch(
                `/documents/associate/${documentTypePrefix}/profile/location/${locationId}`,
                {
                    data: values,
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                },
                true
            )
        })
    )
}

const checkForFilenameCollision = (documentRecord, documentList) => {
    const filenameList = [
        documentRecord?.userFileName,
        documentRecord?.customFilename,
    ]
    const categoryList = [
        documentRecord?.documentCategory,
        documentRecord?.attachmentFileType,
    ]
    return documentList?.some(
        x =>
            ((x.documentCategory &&
                categoryList.includes(x.documentCategory)) ||
                (x.attachmentFileType &&
                    categoryList.includes(x.attachmentFileType))) &&
            ((x.userFileName && filenameList.includes(x.userFileName)) ||
                (x.customFilename && filenameList.includes(x.customFilename)))
    )
}

export const CustomsProfileProvider = ({ children }) => {
    const [locationId, setLocationId] = useState(null)
    const [documentList, setDocumentList] = useState([])
    const [documentListLoading, setDocumentListLoading] = useState(false)

    return (
        <Context.Provider
            value={{
                uploadFile: (...values) => uploadFile(locationId, ...values),
                createFile,
                getDocument: (...values) => getDocument(locationId, ...values),
                refreshDocumentList: async () => {
                    setDocumentListLoading(true)
                    setDocumentList(await getDocumentList(locationId))
                    setDocumentListLoading(false)
                },
                documentList,
                documentListLoading,
                setLocationId: async innerLocationId => {
                    setLocationId(innerLocationId)
                    if (!innerLocationId) {
                        setDocumentList([])
                    } else if (
                        innerLocationId &&
                        locationId !== innerLocationId
                    ) {
                        setDocumentList([])
                        setDocumentListLoading(true)
                        setDocumentList(await getDocumentList(innerLocationId))
                        setDocumentListLoading(false)
                    }
                },
                locationId,
                printFile: (...values) => printFile(locationId, ...values),
                associateDocumentsToShipment: (...values) =>
                    associateDocumentsToShipment(locationId, ...values),
                checkFilenameCollision: (documentRecord, documentListParam) =>
                    checkForFilenameCollision(
                        documentRecord,
                        documentListParam ?? documentList
                    ),
            }}
        >
            {children}
        </Context.Provider>
    )
}

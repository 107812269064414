import React from "react"
import { FormattedMessage } from "react-intl"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import WarningIcon from "@material-ui/icons/Warning"
import StarIcon from "@material-ui/icons/Star"
import CancelIcon from "@material-ui/icons/Cancel"
import RightArrowIcon from "@material-ui/icons/KeyboardArrowRight"
import LeftArrowIcon from "@material-ui/icons/KeyboardArrowLeft"
import PersonIcon from "@material-ui/icons/Person"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import CreateIcon from "@material-ui/icons/Create"
import SnoozeIcon from "@material-ui/icons/Snooze"
import ReceiptIcon from "@material-ui/icons/Receipt"

export const supportedTiles = [
    {
        value: "starred",
        label: (
            <FormattedMessage
                id="dashboard.tiles__starred"
                defaultMessage="Starred"
            />
        ),
        icon: <StarIcon />,
        isAlertRelated: false,
    },
    {
        value: "pickedUp",
        label: (
            <FormattedMessage
                id="dashboard.tiles__pickedUp"
                defaultMessage="Picked Up"
            />
        ),
        icon: <ArrowUpwardIcon />,
        isAlertRelated: false,
    },
    {
        value: "pickingUpToday",
        label: (
            <FormattedMessage
                id="dashboard.tiles__pickingUpToday"
                defaultMessage="Picking Up Today"
            />
        ),
        icon: <ArrowUpwardIcon />,
        isAlertRelated: false,
    },
    {
        value: "pickingUpTomorrow",
        label: (
            <FormattedMessage
                id="dashboard.tiles__pickingUpTomorrow"
                defaultMessage="Picking Up Tomorrow"
            />
        ),
        icon: <ArrowUpwardIcon />,
        isAlertRelated: false,
    },
    {
        value: "inTransit",
        label: (
            <FormattedMessage
                id="dashboard.tiles__inTransit"
                defaultMessage="In Transit"
            />
        ),
        icon: <LocalShippingIcon />,
        isAlertRelated: false,
    },
    {
        value: "outForDelivery",
        label: (
            <FormattedMessage
                id="dashboard.tiles__outForDelivery"
                defaultMessage="Out For Delivery"
            />
        ),
        icon: <LocalShippingIcon />,
        isAlertRelated: false,
    },
    {
        value: "delivered",
        label: (
            <FormattedMessage
                id="dashboard.tiles__delivered"
                defaultMessage="Delivered"
            />
        ),
        icon: <CheckCircleIcon />,
        isAlertRelated: false,
    },
    {
        value: "deliveredToday",
        label: (
            <FormattedMessage
                id="dashboard.tiles__deliveredToday"
                defaultMessage="Delivered Today"
            />
        ),
        icon: <CheckCircleIcon />,
        isAlertRelated: false,
    },

    {
        value: "canceled",
        label: (
            <FormattedMessage
                id="dashboard.tiles__canceled"
                defaultMessage="Canceled"
            />
        ),
        icon: <CancelIcon />,
        isAlertRelated: false,
    },
    {
        value: "outbound",
        label: (
            <FormattedMessage
                id="dashboard.tiles__outbound"
                defaultMessage="Outbound"
            />
        ),
        icon: <RightArrowIcon />,
        isAlertRelated: false,
    },
    {
        value: "inbound",
        label: (
            <FormattedMessage
                id="dashboard.tiles__inbound"
                defaultMessage="Inbound"
            />
        ),
        icon: <LeftArrowIcon />,
        isAlertRelated: false,
    },
    {
        value: "thirdParty",
        label: (
            <FormattedMessage
                id="dashboard.tiles__thirdParty"
                defaultMessage="Third Party"
            />
        ),
        icon: <PersonIcon />,
        isAlertRelated: false,
    },
    {
        value: "warning",
        label: (
            <FormattedMessage
                id="dashboard.tiles__warning"
                defaultMessage="Warning"
            />
        ),
        icon: <WarningIcon />,
        isAlertRelated: true,
    },
    {
        value: "clearanceDelay",
        label: (
            <FormattedMessage
                id="dashboard.tiles__clearanceDelay"
                defaultMessage="Clearance Delay"
            />
        ),
        icon: <WarningIcon />,
        isAlertRelated: true,
    },
    {
        value: "damage",
        label: (
            <FormattedMessage
                id="dashboard.tiles__damage"
                defaultMessage="Damage"
            />
        ),
        icon: <WarningIcon />,
        isAlertRelated: true,
    },
    {
        value: "reweigh",
        label: (
            <FormattedMessage
                id="dashboard.tiles__reweigh"
                defaultMessage="Reweigh"
            />
        ),
        icon: <WarningIcon />,
        isAlertRelated: true,
    },
    {
        value: "delay",
        label: (
            <FormattedMessage
                id="dashboard.tiles__delay"
                defaultMessage="Delay"
            />
        ),
        icon: <WarningIcon />,
        isAlertRelated: true,
    },
    {
        value: "deliveryOptionRequested",
        label: (
            <FormattedMessage
                id="dashboard.tiles__deliveryRequested"
                defaultMessage="Delivery Requested"
            />
        ),
        icon: <WarningIcon />,
        isAlertRelated: true,
    },
    {
        value: "missedDelivery",
        label: (
            <FormattedMessage
                id="dashboard.tiles__missedDelivery"
                defaultMessage="Missed Delivery"
            />
        ),
        icon: <WarningIcon />,
        isAlertRelated: true,
    },
    {
        value: "refusedDelivery",
        icon: <WarningIcon />,
        label: (
            <FormattedMessage
                id="dashboard.tiles__refusedDelivery"
                defaultMessage="Refused Delivery"
            />
        ),
        isAlertRelated: true,
    },
    {
        value: "created",
        label: (
            <FormattedMessage
                id="dashboard.tiles__created"
                defaultMessage="Created"
            />
        ),
        icon: <CreateIcon />,
        isAlertRelated: false,
    },
    {
        value: "delayedPickup",
        label: (
            <FormattedMessage
                id="dashboard.tiles__delayed_pickup"
                defaultMessage="Pickup Pending"
            />
        ),
        icon: <SnoozeIcon />,
        isAlertRelated: false,
    },
    {
        value: "viewInvoicedVariant",
        label: (
            <FormattedMessage
                id="dashboard.tiles__invoice"
                defaultMessage="Invoice Variance"
            />
        ),
        icon: <ReceiptIcon />,
        isAlertRelated: false,
    },
]

import React from "react"
import PropTypes from "prop-types"
import Scroll from "react-scroll"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { get, isEqual, last } from "lodash"
import qs from "qs"
import QuotesDrawer from "./quotesDrawer"
import ChooseItemDialog from "./dialog/chooseItemDialog"
import QuoteStepper from "../quote/stepper"
import { changePath } from "../../actions"
import { QuoteLoaderContainer } from "../quote/loader"
import { copyShipment, fetchAllRates } from "../../actions/quote-request"
import QuotesResult from "./quotesResult"
import { QuoteDetails } from "./quoteDetails"
import { BookScreen } from "../book"
import FavoriteItems from "./favoriteItems"
import Accessorials from "./accessorials"
import { requestItems } from "../../actions/item"
import {
    newHandlingUnit,
    newFreightDirectHandlingUnit,
    newItem,
    newFreightDirectItem,
} from "../../reducers/search"
import { arrayPush, arrayRemove, change } from "redux-form"
import { formSelector } from "./selectors"
import { mapItemToHandlingUnit, mapItemToHUItem } from "../../actions/quote"
import { isEmptyDeep } from "../../actions/validation"
import { ErrorSnackbarContentWrapper } from "../errorSnackbar"
import Snackbar from "@material-ui/core/Snackbar"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    loader: {
        position: "absolute",
        top: "50%",
        left: "50%",
        "z-index": "9999",
    },
    hidden__drawer: {
        display: "none",
    },
    sticky_stepper: {
        height: "86px",
        backgroundColor: "#F2F2F2",
        position: "fixed",
        zIndex: 10,
        marginTop: "-8px",
        left: 0,
        right: 0,
        minWidth: "312px",
        width: "100%",
        padding: "0 4%",
    },
    quote__content: {
        //marginTop: "78px",
    },
    carousel__container: {
        width: "100%",
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    snackbar_message: {
        display: "inline",
    },
    main__container: {
        // overflow: 'scroll',
        padding: "0 4%",
    },
    quote__details: {
        padding: "4px 8px 4px 8px",
    },
    quote__entry__left__container: {
        maxWidth: "38%",
        flexBasis: "38%",
    },
    quote__entry__right__container: {
        paddingLeft: "25px",
        maxWidth: "62%",
        flexBasis: "62%",
    },
    quote__entry__right__element: {},
    pushdown: {
        marginTop: "78px",
    },
    notice__container: {
        backgroundColor: theme.palette.secondary.light,
        padding: "3px",
    },
    notice__container__2: {
        backgroundColor: theme.palette.secondary.light,
        padding: "6px 3px",
    },
})

class QuotesPage extends React.Component {
    state = {
        isOpen: 0,
        openAddItemDialog: false,
        openChooseItemDialog: false,
        itemBeingAdded: null,
        errorHandlingUnits: [],
        step: 0,
        stepIndex: 0,
        run: true,
        isInAddHandlingUnitMode: true,
        sbOpen: false,
        sbVariant: "error",
        sbMessage: "",
    }

    constructor(props) {
        super(props)
        this.onPropChange({}, props)
    }

    onPropChange(prevProps, newProps) {
        const params = get(newProps, "match.params")

        if (Object.keys(params).length > 2) this.onChangeStep(2, newProps)
        else if (
            newProps.shipmentId &&
            params.mode !== "edit" &&
            params.mode !== "return"
        )
            this.onChangeStep(1, newProps)
        else if (!newProps.shipmentId) this.onChangeStep(0, newProps)
    }

    componentDidUpdate(prevProps) {
        if (
            get(prevProps, "syncErrors.handlingUnits") &&
            get(this.props, "syncErrors.handlingUnits")
        ) {
            const errorHandlingUnits = []
            if (
                !isEqual(
                    prevProps.syncErrors.handlingUnits,
                    this.props.syncErrors.handlingUnits
                )
            ) {
                const { syncErrors } = this.props

                syncErrors.handlingUnits.forEach(
                    (handlingUnitErrors, index) => {
                        if (!isEmptyDeep(handlingUnitErrors)) {
                            errorHandlingUnits.push(index)
                        }
                    }
                )

                this.setState(state => ({ errorHandlingUnits }))
            }
        }
    }

    componentDidMount = () => {
        const scroll = Scroll.animateScroll

        scroll.scrollToTop({
            smooth: "true",
            duration: 10,
        })
    }

    componentWillReceiveProps = newProps => {
        this.onPropChange(this.props, newProps)
    }

    getQuotes = () => {
        this.onChangeStep(1, this.props)
    }

    getBol = () => {
        this.onChangeStep(2, this.props)
    }

    handlePanel = index => {
        this.setState(state => ({
            isOpen: this.state.isOpen === index ? null : index,
        }))
    }

    onChangeStep = (stepNumber, props) => {
        const { shipmentId, isQuickQuote, changePath, match } = props

        if (shipmentId) {
            if (match.params.mode === "return") {
            } else {
                if (isQuickQuote && stepNumber > 0) {
                    changePath(`/qrate/${shipmentId}`)
                } else {
                    this.setState({ step: stepNumber })
                    if (stepNumber === 1) {
                        changePath(`/rate/${shipmentId}`)
                    } else if (stepNumber === 0) {
                        changePath(`/rate/${shipmentId}/edit`)
                    }
                }
            }
        } else {
            this.setState({ step: 0 })
        }
    }

    addNewHandlingUnit = fields => {
        const { errorHandlingUnits } = this.state

        if (errorHandlingUnits.length > 0) {
            this.setState(state => ({
                openAddItemDialog: true,
                isOpen: errorHandlingUnits[0],
            }))
        } else {
            fields.push(newFreightDirectHandlingUnit())
            const id = fields.length
            this.setState(state => ({
                isOpen: id > -1 ? id : undefined,
                isInAddHandlingUnitMode: true,
            }))
        }
    }

    handleAddItemDialogClose = () => {
        this.setState(state => ({
            openAddItemDialog: false,
        }))
    }

    onItemClick = item => {
        const { syncErrors, values } = this.props
        const { isOpen, isInAddHandlingUnitMode } = this.state

        if (isInAddHandlingUnitMode) {
            // If handling unit is untouched, proceed adding it in place
            // Otherwise check if everything passes validation (including all items)
            // If not, the user can replace the whole H/U with the new one
            // If handling unit passes validation, add a new one to the list
            const lastHuIndex = get(values, "handlingUnits.length", 1) - 1
            const lastHu = last(values.handlingUnits) || {}

            const modifiedLastHu = {
                ...lastHu,
                items: (lastHu?.items ?? []).map(item => {
                    const { pieces, freightDirectPieces, ...rest } = item
                    return {
                        pieces: 1,
                        ...rest,
                    }
                }),
            }

            const { totalHUWeight, isMultiClass, ...rest } = modifiedLastHu
            const {
                isMultiClass: newHuIsMultiClass,
                ...newHu
            } = newHandlingUnit()

            if (
                !isEqual(rest, newHu) &&
                !isEmptyDeep(
                    get(syncErrors, `handlingUnits[${lastHuIndex}]`, [])
                )
            ) {
                this.setState(state => ({
                    openChooseItemDialog: true,
                    itemBeingAdded: item,
                }))
            } else {
                // Fill in both the handling unit and first item information
                this.setState(state => ({
                    isOpen: lastHuIndex,
                }))
                this.addHandlingUnit(item)
            }
        } else {
            // Check if last item passes is untouched, or passes validation
            // If last item is invalid, offer replacement
            // If last item is untouched, add item in place
            // If last item is valid, just push a new item to the end
            const currentHu =
                values.handlingUnits[isOpen] ||
                values.handlingUnits[values.handlingUnits.length - 1]
            const lastItem = last(get(currentHu, "items", []))
            const lastItemIndex = get(currentHu, "items", []).length - 1
            if (
                !isEqual(lastItem, newFreightDirectItem()) &&
                !isEmptyDeep(
                    get(
                        syncErrors,
                        `handlingUnits[${isOpen}].items[${lastItemIndex}]`,
                        []
                    )
                )
            ) {
                this.setState(state => ({
                    openChooseItemDialog: true,
                    itemBeingAdded: item,
                }))
            } else {
                // Only fill the item information
                const lastHuIndex = values.handlingUnits.length - 1
                this.setState(state => ({
                    isOpen: lastHuIndex,
                }))
                const {
                    packageType,
                    length,
                    width,
                    height,
                    isMultiClass,
                } = currentHu
                const huUntouched =
                    packageType === "" &&
                    length === "" &&
                    width === "" &&
                    height === ""
                if (huUntouched && isMultiClass) {
                    this.fillChosenItem(item, isOpen, true)
                } else {
                    this.fillChosenItem(item, isOpen)
                }
            }
        }
    }

    addHandlingUnit = item => {
        const {
            addHandlingUnitToStore,
            removeHandlingUnitFromIndex,
            values,
        } = this.props
        const lastHuIndex = get(values, "handlingUnits.length", 1) - 1

        const lastHu = last(values.handlingUnits) ?? {}
        const lastMultiClass = lastHu?.isMultiClass

        const modifiedLastHu = {
            ...lastHu,
            isIndividualHUWeight: false,
            items: (lastHu?.items ?? []).map(item => {
                const { pieces, freightDirectPieces, ...rest } = item
                return {
                    pieces: 1,
                    ...rest,
                }
            }),
        }

        const { totalHUWeight, isMultiClass, ...rest } = modifiedLastHu

        const { isMultiClass: newHuIsMultiClass, ...newHu } = newHandlingUnit()

        if (isEqual(rest, newHu)) {
            removeHandlingUnitFromIndex(lastHuIndex)
        } else {
            this.setState(state => ({
                isOpen: lastHuIndex + 1,
            }))
        }
        const handlingUnit = mapItemToHandlingUnit(item, lastMultiClass)
        addHandlingUnitToStore(handlingUnit)
    }

    fillChosenItem = (item, huIndex, addHuFields) => {
        const {
            removeItemFromIndex,
            addItemToStore,
            values,
            changeField,
        } = this.props
        const adjustedHuIndex = huIndex
            ? huIndex
            : values.handlingUnits.length - 1
        const currentHu = values.handlingUnits[adjustedHuIndex]

        const lastItem = last(currentHu.items)
        if (isEqual(lastItem, newItem())) {
            const itemIndex = get(currentHu, "items", []).length - 1
            removeItemFromIndex(adjustedHuIndex, itemIndex)
        }

        const huItem = mapItemToHUItem(item, currentHu?.isMultiClass)
        addItemToStore(huItem, adjustedHuIndex)

        if (addHuFields) {
            changeField(
                "quote",
                `handlingUnits[${adjustedHuIndex}].packageType`,
                item.packageType
            )
            changeField(
                "quote",
                `handlingUnits[${adjustedHuIndex}].length`,
                item.length
            )
            changeField(
                "quote",
                `handlingUnits[${adjustedHuIndex}].width`,
                item.width
            )
            changeField(
                "quote",
                `handlingUnits[${adjustedHuIndex}].height`,
                item.height
            )
        }
    }

    handleChooseItemDialogClose = selection => {
        const {
            removeItemFromIndex,
            addItemToStore,
            removeHandlingUnitFromIndex,
            addHandlingUnitToStore,
            values,
        } = this.props
        const { isInAddHandlingUnitMode, itemBeingAdded, isOpen } = this.state
        if (selection) {
            if (isInAddHandlingUnitMode) {
                const huIndex = values.handlingUnits.length - 1
                removeHandlingUnitFromIndex(huIndex)
                const handlingUnit = mapItemToHandlingUnit(
                    itemBeingAdded,
                    false
                )
                addHandlingUnitToStore(handlingUnit)
                this.setState(state => ({
                    isOpen: huIndex > -1 ? huIndex : 0,
                }))
            } else {
                const currentHu = values.handlingUnits[isOpen]
                const itemIndex = get(currentHu, "items", []).length - 1
                removeItemFromIndex(isOpen, itemIndex)
                const huItem = mapItemToHUItem(
                    itemBeingAdded,
                    currentHu?.isMultiClass
                )
                addItemToStore(huItem, isOpen)
            }
        }
        this.setState(state => ({
            openChooseItemDialog: false,
            itemBeingAdded: null,
        }))
    }

    setHandlingUnitMode = isInAddHandlingUnitMode => {
        this.setState({
            isInAddHandlingUnitMode,
        })
    }

    openErrorSections = () => {
        this.setState(() => ({
            isOpen:
                this.state.errorHandlingUnits.length > 0
                    ? this.state.errorHandlingUnits[0]
                    : null,
        }))
    }

    closeSnackbar = () => {
        this.setState({ sbOpen: false })
    }

    setSnackbar = (sbVariant, sbMessage) => {
        this.setState({ sbVariant, sbMessage, sbOpen: true })
    }

    render() {
        const { to: contactId } = qs.parse(get(this.props, "location.search"), {
            ignoreQueryPrefix: true,
        })
        const {
            classes,
            shipmentId,
            isExpired,
            shipped,
            onClickRefreshRates,
            match,
            isLoaded,
            isQuickQuote,
            onClickGetRates,
            values,
        } = this.props
        const {
            isOpen,
            isInAddHandlingUnitMode,
            openAddItemDialog,
            openChooseItemDialog,
            step,
            stepIndex,
            run,
            sbOpen,
            sbVariant,
            sbMessage,
        } = this.state

        const isFreightDirect = values?.isFreightDirect
        const isFreightDirectReturns = values?.isFreightDirectReturns

        const isReturnMode = match?.params?.mode === "return"

        return (
            <Grid container className={classes.main__container}>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={sbOpen}
                    autoHideDuration={6000}
                    onClose={this.closeSnackbar}
                >
                    <ErrorSnackbarContentWrapper
                        variant={sbVariant}
                        onClose={this.closeSnackbar}
                        message={
                            <Typography variant="body2">{sbMessage}</Typography>
                        }
                    />
                </Snackbar>
                <QuoteLoaderContainer match={match} />
                <Grid item container xs={12} className={classes.sticky_stepper}>
                    {(!isExpired && !shipped) || isReturnMode ? (
                        <QuoteStepper
                            onStepClick={i => this.onChangeStep(i, this.props)}
                            currentStep={step}
                            isLoaded={isLoaded}
                            isQuickQuote={isQuickQuote}
                            isReturnMode={isReturnMode}
                        >
                            <QuoteDetails
                                classes={classes}
                                shipmentId={shipmentId}
                            />
                        </QuoteStepper>
                    ) : (
                        <QuoteDetails
                            mode="row"
                            classes={classes}
                            shipmentId={shipmentId}
                        />
                    )}
                </Grid>
                <Grid item container className={classes.pushdown} />
                <Grid
                    container
                    direction="row"
                    className={classes.quote__content}
                >
                    {step < 1 && (
                        <Grid item container xs={12} wrap="nowrap">
                            <Grid
                                item
                                container
                                id="drawerContainer"
                                className={
                                    classes.quote__entry__left__container
                                }
                            >
                                <QuotesDrawer
                                    handleAddItemDialogClose={
                                        this.handleAddItemDialogClose
                                    }
                                    openAddItemDialog={openAddItemDialog}
                                    openErrorSections={this.openErrorSections}
                                    isOpen={isOpen}
                                    addNewHandlingUnit={this.addNewHandlingUnit}
                                    handlePanel={this.handlePanel}
                                    shipmentId={shipmentId}
                                    getQuotes={this.getQuotes}
                                    isLoaded={isLoaded}
                                    toContact={contactId}
                                    setHandlingUnitMode={
                                        this.setHandlingUnitMode
                                    }
                                    setSnackbar={this.setSnackbar}
                                    isReturnMode={isReturnMode}
                                />
                            </Grid>
                            <Grid
                                item
                                wrap="nowrap"
                                justify="flex-start"
                                className={
                                    classes.quote__entry__right__container
                                }
                            >
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    className={
                                        classes.quote__entry__right__element
                                    }
                                >
                                    <Accessorials
                                        isFreightDirect={isFreightDirect}
                                        isFreightDirectReturns={
                                            isFreightDirectReturns
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    id="favoriteItemsContainer"
                                >
                                    {!isFreightDirectReturns && (
                                        <FavoriteItems
                                            onItemClick={this.onItemClick}
                                            isFreightDirect={isFreightDirect}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <ChooseItemDialog
                                handleClose={this.handleChooseItemDialogClose}
                                isInAddHandlingUnitMode={
                                    isInAddHandlingUnitMode
                                }
                                open={openChooseItemDialog}
                            />
                        </Grid>
                    )}
                    <Grid item container direction="column" xs={12}>
                        {step === 1 && (
                            <QuotesResult
                                getBol={this.getBol}
                                shipmentId={shipmentId}
                                onClickRefreshRates={onClickRefreshRates}
                                onClickGetRates={onClickGetRates}
                            />
                        )}
                        {step === 2 && (
                            <BookScreen
                                computedMatch={match}
                                shipmentId={shipmentId}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

QuotesPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state, props) => ({
    ...state.form.quote,
    ...state.quotes.active,
    shipmentId: get(props, "computedMatch.params.shipmentId"),
    isQuickQuote: get(
        formSelector(state, {
            shipmentId: get(props, "computedMatch.params.shipmentId"),
        }),
        "isQuickQuote"
    ),
    share: state.share,
    match: props.computedMatch,
    bookFormValues: state?.form?.bolEntry?.values,
})

const mapDispatchToProps = dispatch => ({
    onClickRefreshRates: shipmentId =>
        dispatch(
            copyShipment({
                shipmentId,
                queryVersion: "V1",
                shipAgain: false,
                openSnackbar: false,
            })
        ),
    onClickGetRates: (query, shipmentId) =>
        dispatch(fetchAllRates(query, shipmentId)),
    changeField: (quote, field, value) => dispatch(change(quote, field, value)),
    changePath: path => dispatch(changePath(path)),
    loadItems: () => dispatch(requestItems()),
    addHandlingUnitToStore: handlingUnit =>
        dispatch(arrayPush("quote", `handlingUnits`, handlingUnit)),
    addItemToStore: (item, huIndex) =>
        dispatch(arrayPush("quote", `handlingUnits[${huIndex}].items`, item)),
    removeItemFromIndex: (huIndex, itemIndex) =>
        dispatch(
            arrayRemove("quote", `handlingUnits[${huIndex}].items`, itemIndex)
        ),
    removeHandlingUnitFromIndex: huIndex =>
        dispatch(arrayRemove("quote", `handlingUnits`, huIndex)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(QuotesPage))

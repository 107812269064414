import React from "react"
import Grid from "@material-ui/core/Grid"

import Typography from "@material-ui/core/Typography"

const DisplayAddress = ({ address, showName = true }) => {
    const street1 = address?.street1 ?? ""
    const street2 = address?.street2 ?? ""
    const postalCode = address?.postalCode ?? ""
    const city = address?.city ?? ""
    const state = address?.state ?? ""
    return (
        <Grid xs={12} item>
            {showName && (
                <Typography variant="subtitle1">{address?.name}</Typography>
            )}
            <Typography variant="body2">{street1}</Typography>
            {street2 && <Typography variant="body2">{street2}</Typography>}
            <Typography gutterBottom variant="body2">
                {city && state && `${city}, ${state} ${postalCode}`}
            </Typography>
        </Grid>
    )
}

export default DisplayAddress

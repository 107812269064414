import React, { Fragment, useState } from "react"
import { connect } from "react-redux"
import csv from "csvtojson"
import { composeValidators, createValidator } from "revalidate"
import { get } from "lodash"
import {
    Grid,
    Button,
    withStyles,
    Typography,
    Input,
    Tooltip,
    Dialog,
} from "@material-ui/core"
import { Table, Column, AutoSizer } from "react-virtualized"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import CircularProgress from "@material-ui/core/CircularProgress"
import FileUpload from "../util/file-upload"
import {
    countryPhoneValidator,
    emailValidator,
    hasLength,
    isRequired,
    isNumeric,
    isOneOf,
    isValidCountryPostalCode,
} from "../../actions/validation"
import {
    contactsImportCSVLoadError,
    contactsImportCSVTooLargeError,
    contactsImportError,
} from "../../messages/error-constants"
import { importContacts } from "../../actions/contact"
import { FormattedMessage, injectIntl } from "react-intl"
import { supportedCountryCodes } from "../../misc"
import { generateTimeList } from "../book/pickup/form"
import moment from "moment"
import { styles } from "./styles"

const headers = [
    "name",
    "phone",
    "ext",
    "mobile",
    "email",
    "address_name",
    "address_1",
    "address_2",
    "city",
    "state",
    "postal_code",
    "country",
    "pickupContact_readyTime",
    "pickupContact_closeTime",
    "pickupContact_name",
    "pickupContact_email",
    "pickupContact_phone",
    "pickupContact_extension",
]

const DataTableField = ({
    value,
    column,
    row,
    error,
    classes,
    intl,
    ...rest
}) => {
    const [isOpen, setOpen] = useState(false)
    const [canBeOpen, setCanBeOpen] = useState(false)

    const handleTooltipOpen = () => {
        if (error) {
            setOpen(true)
            setCanBeOpen(true)
        }
    }

    const handleTooltipClose = () => {
        if (error) {
            setOpen(false)
            setCanBeOpen(false)
        }
    }

    return (
        <Tooltip
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen}
            title={
                error?.id && error?.defaultMessage ? (
                    <FormattedMessage {...error} />
                ) : (
                    ""
                )
            }
            placement="bottom"
            open={isOpen}
        >
            <Input
                placeholder={
                    error
                        ? intl.formatMessage({
                              id: "generalTerms__error",
                              defaultMessage: "Error",
                          })
                        : ""
                }
                classes={{ input: classes.data__input }}
                className={error ? classes.error__field : classes.data__field}
                margin="none"
                fullWidth
                disableUnderline
                value={value ? value : ""}
                {...rest}
            />
        </Tooltip>
    )
}

const DataTableCell = ({ value, column, classes, row, intl, ...rest }) => {
    let error = get(column, "validator") && column.validator(value, row)

    return (
        <DataTableField
            {...rest}
            intl={intl}
            column={column}
            value={value}
            error={error}
            classes={classes}
            inputProps={{ style: { fontSize: "smaller" } }}
        />
    )
}

const DataTable = ({
    data,
    columns,
    onValueChange,
    onDeleteRow,
    classes,
    intl,
}) => {
    return (
        <AutoSizer>
            {({ height, width }) => (
                <Table
                    height={height}
                    width={width}
                    headerHeight={40}
                    rowCount={data.length}
                    rowGetter={({ index }) => data[index]}
                    rowHeight={24}
                    rowClassName={classes.table__row}
                >
                    {columns.map(column => (
                        <Column
                            columnData={column}
                            flexGrow={1}
                            width={width}
                            key={column.field}
                            dataKey={column.field}
                            headerRenderer={({ columnData }) => (
                                <span className={classes.table__header}>
                                    {columnData.title?.id ? (
                                        <Typography
                                            variant="body2"
                                            className={
                                                classes.headingTableTitle
                                            }
                                        >
                                            <FormattedMessage
                                                {...columnData.title}
                                            />
                                        </Typography>
                                    ) : (
                                        <Typography
                                            variant="body2"
                                            className={
                                                classes.headingTableTitle
                                            }
                                        >
                                            {columnData.title}
                                        </Typography>
                                    )}
                                </span>
                            )}
                            cellRenderer={({
                                columnData,
                                rowData,
                                dataKey,
                                cellData,
                                rowIndex,
                            }) =>
                                columnData.title === "" ? (
                                    <IconButton
                                        size="small"
                                        aria-label="Delete"
                                        className={classes.delete__button}
                                        onClick={() => onDeleteRow(rowIndex)}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                ) : (
                                    <DataTableCell
                                        column={columnData}
                                        row={rowData}
                                        value={cellData || ""}
                                        classes={classes}
                                        onChange={e =>
                                            onValueChange(
                                                rowIndex,
                                                dataKey,
                                                e.target.value
                                            )
                                        }
                                        intl={intl}
                                    />
                                )
                            }
                        />
                    ))}
                </Table>
            )}
        </AutoSizer>
    )
}

const postalCodeValidator = (value, row) => {
    if (!isValidCountryPostalCode(value, row.country)) {
        return {
            id: "contacts.import__invalidZipPostalCode",
            defaultMessage: "Invalid zip/postal code",
        }
    }
}

const pickupWindowValidator = createValidator(
    message => (value, allValues) => {
        const time = get(allValues, "pickupContact_readyTime")
        if (!time) {
            return undefined
        }
        const readyTime = moment(time, "HH:mm A")
        const closeTime = moment(value, "HH:mm A")
        const minutes = closeTime.diff(readyTime, "minutes")
        return minutes >= 90 ? undefined : message
    },
    field => ({
        ...{
            id: "orderDetails.validation__pickupWindow",
            defaultMessage:
                "{field} should be at least 90 minutes after Ready Time",
        },
        values: { field },
    })
)

const ImportCSVModal = ({
    open,
    setOpen,
    classes,
    language = "en-us",
    intl,
    onContactImport,
    gaCategory,
    logGAEvent,
}) => {
    const [importing, setImporting] = useState(false)
    const [imported, setImported] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [result, setResult] = useState(false)
    const [invalidRows, setInvalidRows] = useState([])
    const [fixedCount, setFixedCount] = useState(0)
    const [validRows, setValidRows] = useState([])
    const [sbError, setSbError] = useState(false)
    const [sbMessage, setSbMessage] = useState("")

    const columnDef = [
        {
            field: "name",
            title: {
                id: "contacts.import__name",
                defaultMessage: "Name",
            },
            width: 140,
            validator: isRequired({
                field: intl.formatMessage({
                    id: "contacts.import__name",
                    defaultMessage: "Name",
                }),
            }),
        },
        {
            field: "phone",
            title: {
                id: "contacts.import__phoneNumber",
                defaultMessage: "Phone Number",
            },
            validator: composeValidators(
                isRequired,
                countryPhoneValidator("country")
            )({
                field: intl.formatMessage({
                    id: "contacts.import__phoneNumber",
                    defaultMessage: "Phone Number",
                }),
            }),
            width: 150,
        },
        {
            field: "ext",
            title: {
                id: "contacts.import__ext",
                defaultMessage: "Ext.",
            },
            width: 40,
            validator: isNumeric({
                field: intl.formatMessage({
                    id: "contacts.import__ext",
                    defaultMessage: "Ext.",
                }),
            }),
        },
        {
            field: "mobile",
            title: {
                id: "contacts.import__mobile",
                defaultMessage: "Mobile",
            },
            validator: countryPhoneValidator("country")({
                field: intl.formatMessage({
                    id: "contacts.import__mobile",
                    defaultMessage: "Mobile",
                }),
            }),
        },
        {
            field: "email",
            title: {
                id: "contacts.import__emailAddress",
                defaultMessage: "Email Address",
            },
            width: 150,
            validator: composeValidators(
                isRequired,
                emailValidator
            )({
                field: intl.formatMessage({
                    id: "contacts.import__emailAddress",
                    defaultMessage: "Email Address",
                }),
            }),
        },
        {
            field: "address_name",
            title: {
                id: "contacts.import__companyName",
                defaultMessage: "Company Name",
            },
            width: 140,
            validator: isRequired({
                field: intl.formatMessage({
                    id: "contacts.import__companyName",
                    defaultMessage: "Company Name",
                }),
            }),
        },
        {
            field: "address_1",
            title: {
                id: "contacts.import__address",
                defaultMessage: "Address",
            },
            width: 140,
            validator: isRequired({
                field: intl.formatMessage({
                    id: "contacts.import__address",
                    defaultMessage: "Address",
                }),
            }),
        },
        {
            field: "address_2",
            title: {
                id: "contacts.import__address2",
                defaultMessage: "Address 2",
            },
        },
        {
            field: "city",
            title: {
                id: "contacts.import__city",
                defaultMessage: "City",
            },
            validator: isRequired({
                field: intl.formatMessage({
                    id: "contacts.import__city",
                    defaultMessage: "City",
                }),
            }),
        },
        {
            field: "state",
            title: {
                id: "contacts.import__state",
                defaultMessage: "State",
            },
            width: 40,
            validator: composeValidators(
                isRequired,
                hasLength(2)
            )({
                field: intl.formatMessage({
                    id: "contacts.import__state",
                    defaultMessage: "State",
                }),
            }),
        },
        {
            field: "postal_code",
            title: {
                id: "contacts.import__postalCode",
                defaultMessage: "Postal Code",
            },
            width: 110,
            validator: postalCodeValidator,
        },
        {
            field: "country",
            title: {
                id: "contacts.import__country",
                defaultMessage: "Country",
            },
            width: 40,
            validator: composeValidators(
                isRequired,
                isOneOf(supportedCountryCodes.map(x => x.value))
            )({
                field: intl.formatMessage({
                    id: "contacts.import__country",
                    defaultMessage: "Country",
                }),
            }),
        },
        {
            field: "pickupContact_readyTime",
            title: {
                id: "generalTerms.pickUpInformation__readyTime",
                defaultMessage: "Ready Time",
            },
            width: 110,
            validator: composeValidators(
                isOneOf(generateTimeList().map(x => x.value))
            )({
                field: intl.formatMessage({
                    id: "generalTerms.pickUpInformation__readyTime",
                    defaultMessage: "Ready Time",
                }),
            }),
        },
        {
            field: "pickupContact_closeTime",
            title: {
                id: "generalTerms.pickUpInformation__closeTime",
                defaultMessage: "Close Time",
            },
            width: 110,
            validator: composeValidators(
                isOneOf(generateTimeList().map(x => x.value)),
                pickupWindowValidator
            )({
                field: intl.formatMessage({
                    id: "generalTerms.pickUpInformation__closeTime",
                    defaultMessage: "Close Time",
                }),
            }),
        },
        {
            field: "pickupContact_name",
            title: {
                id: "generalTerms.pickUpInformation__pickupName",
                defaultMessage: "Pickup Name",
            },
            width: 140,
        },
        {
            field: "pickupContact_email",
            title: {
                id: "generalTerms__emailAddress",
                defaultMessage: "Email Address",
            },
            width: 150,
            validator: composeValidators(emailValidator)({
                field: intl.formatMessage({
                    id: "generalTerms__emailAddress",
                    defaultMessage: "Email Address",
                }),
            }),
        },
        {
            field: "pickupContact_phone",
            title: {
                id: "generalTerms__phoneNumber",
                defaultMessage: "Phone Number",
            },
            width: 150,
            validator: composeValidators(countryPhoneValidator("country"))({
                field: intl.formatMessage({
                    id: "generalTerms__phoneNumber",
                    defaultMessage: "Phone Number",
                }),
            }),
        },
        {
            field: "pickupContact_extension",
            title: {
                id: "generalTerms__extension",
                defaultMessage: "Extension",
            },
            width: 40,
            validator: composeValidators(isNumeric)({
                field: intl.formatMessage({
                    id: "generalTerms__extension",
                    defaultMessage: "Extension",
                }),
            }),
        },
        {
            title: "",
            width: 60,
        },
    ]

    const validateRow = row => {
        const rowErrors = columnDef
            .map(column => {
                const revalidateError =
                    column.validator &&
                    column.validator(get(row, column.field), row)
                if (revalidateError) {
                    return revalidateError
                } else return undefined
            })
            .filter(x => x)

        return rowErrors.length ? { id: row.id, errors: rowErrors } : undefined
    }

    const submitData = async data => {
        setSubmitting(true)
        try {
            const { data: importResult } = await onContactImport(data)
            setResult(importResult)
        } catch (err) {
            setSbError(false)
            setSbMessage(contactsImportError)
        } finally {
            setSubmitting(false)
        }
    }

    const handleCSVLoad = async file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = event => resolve(event.target.result)
            reader.onerror = reject
            reader.readAsText(file)
        })
    }

    const handleImport = async file => {
        logGAEvent(gaCategory, "Upload File Clicked")
        setImporting(true)
        setImported(false)
        setFixedCount(0)
        setInvalidRows([])
        setValidRows([])
        setResult(null)
        setSbError(false)
        setSbMessage("")
        try {
            const csvString = await handleCSVLoad(file)
            const rows = await csv({
                headers,
                checkColumn: false,
                noheader: false,
                ignoreEmpty: true,
            }).fromString(csvString)
            if (rows.length > 1000) {
                setSbMessage(contactsImportCSVTooLargeError)
                setSbError(true)
                return
            }
            const { newValidRows, newInvalidRows } = rows
                .filter(row => headers.some(key => !!row[key]))
                .map(row => {
                    const rowLength = get(row, "postal_code.length", 0)

                    if (rowLength === 0 || rowLength === 5 || rowLength === 7)
                        return row
                    return {
                        ...row,
                        postal_code: row.postal_code.padStart(5, "0"),
                    }
                })
                .reduce(
                    (prev, row, id) => {
                        const isInvalid = validateRow(row)
                        if (isInvalid) {
                            prev.newInvalidRows.push({
                                ...row,
                                id,
                            })
                        } else {
                            prev.newValidRows.push({
                                ...row,
                                id,
                            })
                        }
                        return prev
                    },
                    { newValidRows: [], newInvalidRows: [] }
                )

            setInvalidRows(newInvalidRows)
            setValidRows(newValidRows)
            setImported(true)
            setImporting(false)

            if (!newInvalidRows.length && newValidRows.length) {
                await submitData(validRows)
            }
        } catch (err) {
            setSbError(true)
            setSbMessage(contactsImportCSVLoadError(err.message))
        } finally {
            setImporting(false)
        }
    }

    const handleSubmit = async () => {
        const allData = [...validRows, ...invalidRows]
        setValidRows(allData)
        setImported(false)
        return submitData(allData)
    }

    const handleDeleteRow = id => {
        const newInvalidRows = [
            ...invalidRows.slice(0, id),
            ...invalidRows.slice(id + 1),
        ]
        const targetRow = invalidRows[id]
        const rowInvalid = validateRow(targetRow)
        if (!rowInvalid) setFixedCount(fixedCount - 1)
        setInvalidRows(newInvalidRows)
    }

    const handleValueChange = (id, field, value) => {
        const newRow = { ...invalidRows[id], [field]: value }
        const newInvalidRows = [
            ...invalidRows.slice(0, id),
            newRow,
            ...invalidRows.slice(id + 1),
        ]
        const oldValidation = validateRow(invalidRows[id])
        const newValidation = validateRow(newRow)
        if (oldValidation && !newValidation) {
            setFixedCount(fixedCount + 1)
        } else if (!oldValidation && newValidation) {
            setFixedCount(fixedCount - 1)
        }

        setInvalidRows(newInvalidRows)
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            maxWidth={invalidRows.length === 0 ? "sm" : "lg"}
        >
            <Grid container className={classes.content}>
                <Grid
                    direction="column"
                    item
                    container
                    xs={12}
                    className={classes.header}
                >
                    <Typography variant="h5">
                        <FormattedMessage
                            id="contacts.import.title"
                            defaultMessage="Import Contacts"
                        />
                    </Typography>
                    <Typography variant="body2">
                        <FormattedMessage
                            id="contacts.import__instructions"
                            defaultMessage="To import your address book, please look at the sample file below. Your upload file needs to be in the .csv format and use the same column headers."
                        />
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {
                            window.open(
                                `/static/import_contacts_sample__${language}.csv`,
                                "_blank"
                            )
                            logGAEvent(gaCategory, "Download Sample clicked")
                        }}
                    >
                        <FormattedMessage
                            id="contacts.import__downloadSample"
                            defaultMessage="Download sample"
                        />
                    </Button>
                    {importing && (
                        <CircularProgress size={30} color="secondary" />
                    )}
                    <FileUpload
                        labelText={
                            <FormattedMessage
                                id="contacts.import__uploadFile"
                                defaultMessage="Upload file"
                            />
                        }
                        onSubmit={file => handleImport(file)}
                        accept=".csv"
                        allowSameFile
                    />
                </Grid>
                {sbMessage && (
                    <Grid item container>
                        <Typography
                            variant="body2"
                            color={sbError ? "error" : undefined}
                        >
                            {sbMessage}
                        </Typography>
                    </Grid>
                )}
                {imported && (
                    <Grid item container direction="column">
                        <Grid item className={classes.summary}>
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="contacts.import__summary"
                                    defaultMessage="Summary:"
                                />
                            </Typography>
                            <Typography variant="body2">
                                <FormattedMessage
                                    id="contacts.import__validRows"
                                    defaultMessage="Valid records: {validRows}, invalid records: {invalidRows}"
                                    values={{
                                        validRows:
                                            validRows.length + fixedCount,
                                        invalidRows:
                                            invalidRows.length - fixedCount,
                                    }}
                                />
                            </Typography>
                            {invalidRows.length > 0 && (
                                <Typography variant="body2">
                                    <FormattedMessage
                                        id="contacts.import__invalidRows"
                                        defaultMessage="Please correct the validation errors below, or remove the invalid items."
                                    />
                                </Typography>
                            )}
                        </Grid>
                        {invalidRows.length > 0 && (
                            <div className={classes.table__container}>
                                <DataTable
                                    data={invalidRows}
                                    onValueChange={(...args) =>
                                        handleValueChange(...args)
                                    }
                                    onDeleteRow={index =>
                                        handleDeleteRow(index)
                                    }
                                    columns={columnDef}
                                    classes={classes}
                                    intl={intl}
                                />
                            </div>
                        )}
                        <Grid item container direction="row-reverse">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        logGAEvent(
                                            gaCategory,
                                            "Import Modal Submit Click"
                                        )
                                        handleSubmit()
                                    }}
                                    disabled={invalidRows.length !== fixedCount}
                                >
                                    <FormattedMessage
                                        id="contacts.import__submit"
                                        defaultMessage="Submit"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {submitting && (
                    <Grid item container direction="column" alignItems="center">
                        <Grid item>
                            <CircularProgress size={30} color="secondary" />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="contacts.import__pleaseWait"
                                    defaultMessage="Importing contacts. Please wait and do not close this window..."
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {result && (
                    <Grid item container direction="column" alignItems="center">
                        {get(result, "error.length") ? (
                            <Fragment>
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        <FormattedMessage
                                            id="contacts.import__finished"
                                            defaultMessage="Finished importing contacts."
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        <FormattedMessage
                                            id="contacts.import__result"
                                            defaultMessage="Created {successful} new contacts, {duplicate} already existed."
                                            values={{
                                                successful: get(
                                                    result,
                                                    "successful.length",
                                                    0
                                                ),
                                                duplicate: get(
                                                    result,
                                                    "duplicate.length",
                                                    0
                                                ),
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" gutterBottom>
                                        <FormattedMessage
                                            id="contacts.import__unableToImport"
                                            defaultMessage="Unfortunately we were not able to import the following contacts:"
                                        />
                                    </Typography>
                                </Grid>
                                {get(result, "error", []).map(
                                    ({ index, error }, key) => (
                                        <Grid item>
                                            <Typography variant="body2">
                                                <FormattedMessage
                                                    id="contacts.import__rowError"
                                                    defaultMessage="Name: {name}, Company name: {companyName} - {error}"
                                                    values={{
                                                        name: get(
                                                            validRows,
                                                            `[${index}].name`,
                                                            ""
                                                        ),
                                                        companyName: get(
                                                            validRows,
                                                            `[${index}].name`,
                                                            ""
                                                        ),
                                                        error,
                                                    }}
                                                />
                                            </Typography>
                                        </Grid>
                                    )
                                )}
                                <Grid item>
                                    <Typography variant="body2">
                                        <FormattedMessage
                                            id="contacts.import__finalMessage"
                                            defaultMessage="You may now close this window or upload a different file."
                                        />
                                    </Typography>
                                </Grid>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        <FormattedMessage
                                            id="contacts.import__success"
                                            defaultMessage="Successfully finished importing contacts."
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        <FormattedMessage
                                            id="contacts.import__result"
                                            defaultMessage="Created {successful} new contacts, {duplicate} already existed."
                                            values={{
                                                successful: get(
                                                    result,
                                                    "successful.length",
                                                    0
                                                ),
                                                duplicate: get(
                                                    result,
                                                    "duplicate.length",
                                                    0
                                                ),
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        <FormattedMessage
                                            id="contacts.import__finalMessage"
                                            defaultMessage="You may now close this window or upload a different file."
                                        />
                                    </Typography>
                                </Grid>
                            </Fragment>
                        )}
                    </Grid>
                )}
            </Grid>
        </Dialog>
    )
}

const mapStateToProps = state => ({
    language: state?.user?.profile?.preferences?.language,
})

const mapDispatchToProps = dispatch => ({
    onContactImport: async data => dispatch(importContacts(data)),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(injectIntl(ImportCSVModal))
)

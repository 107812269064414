import {
    isUSDomesticOffshoreShipment,
    isValidCountryForFedexShipment,
} from "../../actions/validation"

export const isOffshorePRShipmentFedexValid = (
    originCountry,
    originState,
    destinationCountry,
    destinationState
) => {
    const isUSDomesticOffshore = isUSDomesticOffshoreShipment(
        originCountry,
        destinationCountry,
        originState,
        destinationState
    )

    const isUSPRDomesticOffshore =
        isUSDomesticOffshore === true &&
        (originState === "PR" || destinationState === "PR")

    const isShipmentFedexValidPR =
        isValidCountryForFedexShipment(originCountry) &&
        isValidCountryForFedexShipment(destinationCountry) &&
        (originState === "PR" || destinationState === "PR") &&
        originState !== destinationState
    return isUSPRDomesticOffshore || isShipmentFedexValidPR
}

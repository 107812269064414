import {
    CONTACT_SEARCH_RESULT,
    QUEUE_CONTACT_SEARCH,
    CONTACT_SEARCH_RETURN,
} from "../actions/contact"

export function contact(
    state = {
        searchInProgress: undefined,
        search: {
            quote: {},
            bolEntry: {},
            email_name: {},
            email_email: {},
            dashboard: {},
            book_origin: {},
            book_destination: {},
        },
    },
    action = {}
) {
    switch (action.type) {
        case CONTACT_SEARCH_RESULT:
            return {
                ...state,
                searchInProgress: undefined,
                search: {
                    ...state.search,
                    [action.kind]: {
                        [action.value]: action.contacts,
                    },
                },
            }
        case QUEUE_CONTACT_SEARCH:
            return {
                ...state,
                searchInProgress: true,
                timeoutId: action.id,
            }
        case CONTACT_SEARCH_RETURN:
            return { ...state, searchInProgress: undefined }
        default:
            return state
    }
}

import React, { Fragment, useEffect, useState } from "react"
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import LinkButtonImage from "@material-ui/icons/Link"
import { AddDocumentWizard } from "../../../documents/upload/AddDocumentWizard"
import { useCustomsProfileContext } from "../../../../context/providers/CustomsProfileProvider"
import { useGAContext } from "../../../../context/providers/GoogleAnalyticsProvider"
import { withStyles } from "@material-ui/core/styles"
import { PreviewDocument } from "../../../documents/manage/PreviewDocument"
import GlobalSpinner from "../../../common/GlobalSpinner"
import { destroy } from "redux-form"
import { useSnackbarContext } from "../../../../context/providers/snackbarProvider"
import { attachmentOptions } from "../../../../misc"

const styles = theme => ({
    card: {
        minHeight: "401px",
    },
    card__action: {
        margin: 0,
    },
    fixed__dialog: {
        width: "100%",
    },
    card__content: {
        overflowY: "auto",
        height: "333px",
    },
})

let CustomsDocumentList = ({
    classes,
    customsDocuments = [],
    attachedDocuments = [],
    attachable,
    previewable = true,
    selectable,
    selectedDocuments = [],
    onSelect,
    onLinkToShipment,
    locationId,
}) => {
    const [previewDocument, setPreviewDocument] = useState(null)
    const [previewDialogOpen, setPreviewDialogOpen] = useState(false)
    const onOpenPreviewDialog = async document => {
        setPreviewDialogOpen(true)
        setPreviewDocument(document)
    }

    const onClosePreviewDialog = async () => {
        setPreviewDocument(null)
        setPreviewDialogOpen(false)
    }

    return (
        <Fragment>
            {previewable && (
                <Dialog
                    open={previewDialogOpen}
                    onClose={onClosePreviewDialog}
                    maxWidth="lg"
                    classes={{ paper: classes.fixed__dialog }}
                >
                    <PreviewDocument
                        baseDocument={previewDocument}
                        locationId={locationId}
                        onClose={onClosePreviewDialog}
                    />
                </Dialog>
            )}
            <List dense>
                {customsDocuments?.map((document, index) => {
                    const categoryLabel = attachmentOptions.find(
                        entry => document.documentCategory === entry.value
                    )

                    const label = categoryLabel ? (
                        <FormattedMessage {...categoryLabel.label} />
                    ) : (
                        document.documentCategory
                    )
                    return (
                        <Fragment key={index}>
                            {index !== 0 && <Divider />}
                            <ListItem
                                button
                                disabled={
                                    previewable &&
                                    attachedDocuments?.some(
                                        x =>
                                            x?.documentId ===
                                            document?.documentId
                                    )
                                }
                                selected={
                                    selectable &&
                                    selectedDocuments.some(
                                        x =>
                                            x?.documentId ===
                                            document?.documentId
                                    )
                                }
                                onClick={() => {
                                    if (previewable) {
                                        onOpenPreviewDialog(document)
                                    } else if (onSelect) {
                                        onSelect(document)
                                    }
                                }}
                            >
                                <ListItemText
                                    primary={document.customFilename}
                                    secondary={label}
                                />
                                {attachable && (
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            disabled={attachedDocuments?.some(
                                                x =>
                                                    x.documentCategory ===
                                                        document.documentCategory &&
                                                    x.customFilename ===
                                                        document.customFilename
                                            )}
                                            onClick={() =>
                                                onLinkToShipment(document)
                                            }
                                        >
                                            <LinkButtonImage />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                )}
                            </ListItem>
                        </Fragment>
                    )
                })}
            </List>
        </Fragment>
    )
}

let CustomsDocumentsCard = ({
    classes,
    locationId,
    onLinkToShipment,
    gaCategory,
}) => {
    const [documentModalOpen, setDocumentModalOpen] = useState(false)

    const { openSnackbar } = useSnackbarContext()
    const { logGAEvent } = useGAContext()

    const {
        documentList,
        documentListLoading,
        setLocationId,
        refreshDocumentList,
        uploadFile,
        createFile,
    } = useCustomsProfileContext()

    useEffect(() => {
        setLocationId(locationId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId])

    const onCloseDocumentDialog = successful => {
        setDocumentModalOpen(false)
        destroy("newCustomsDocument")
        if (successful) {
            refreshDocumentList()
        }
        logGAEvent(gaCategory, "Document Modal Close")
    }

    const onDocumentGenerate = async values => {
        let payload = {
            customFilename: values?.customFilename,
            shipmentOnly: false,
            ...(values?.documentCategory === "USMCA" && values?.usmca),
            locationId,
        }
        try {
            await createFile(values?.documentCategory, payload)
            logGAEvent(gaCategory, "Document Create")
            openSnackbar(
                "success",
                <FormattedMessage
                    id="documents.manage__createSuccess"
                    defaultMessage="File successfully created"
                />
            )
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="documents.manage__createError"
                    defaultMessage="Unable to create file"
                />
            )
        }
        onCloseDocumentDialog(true)
    }

    const onDocumentUpload = async (values, stagedFile) => {
        const { documentCategory, customFilename } = values
        const payload = {
            shipmentOnly: false,
            locationId,
            customFilename,
            ...stagedFile,
        }
        try {
            await uploadFile(documentCategory, payload)
            logGAEvent(gaCategory, "Document Uploaded")
            openSnackbar(
                "success",
                <FormattedMessage
                    id="documents.manage__addSuccess"
                    defaultMessage="File successfully added"
                />
            )
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="documents.manage__addError"
                    defaultMessage="Unable to add file"
                />
            )
        }
        onCloseDocumentDialog(true)
    }

    return (
        <Fragment>
            <Dialog
                open={documentModalOpen}
                onClose={onCloseDocumentDialog}
                maxWidth="lg"
            >
                <AddDocumentWizard
                    locationId={locationId}
                    onGenerate={onDocumentGenerate}
                    onUpload={onDocumentUpload}
                    onClose={onCloseDocumentDialog}
                    profileDocs={true}
                    gaCategory={gaCategory}
                />
            </Dialog>
            <Card className={classes.card}>
                <CardHeader
                    classes={{ action: classes.card__action }}
                    action={
                        <Button
                            color="primary"
                            onClick={() => {
                                setDocumentModalOpen(true)
                                logGAEvent(gaCategory, "Add New Document Click")
                            }}
                            id="customsDocumentCard__openDocumentDialog"
                        >
                            <FormattedMessage
                                id="locations.customsDocumentCard__addNew"
                                defaultMessage="Add New"
                            />
                        </Button>
                    }
                    title={
                        <Typography variant="h6">
                            <FormattedMessage
                                id="locations.customsDocumentCard__title"
                                defaultMessage="Customs/Trade Documents"
                            />
                        </Typography>
                    }
                />
                <CardContent classes={{ root: classes.card__content }}>
                    {documentListLoading ? (
                        <GlobalSpinner isGlobal={false} />
                    ) : (
                        <CustomsDocumentList
                            customsDocuments={documentList}
                            onLinkToShipment={onLinkToShipment}
                            locationId={locationId}
                        />
                    )}
                </CardContent>
            </Card>
        </Fragment>
    )
}

CustomsDocumentsCard = withStyles(styles)(CustomsDocumentsCard)
CustomsDocumentList = withStyles(styles)(CustomsDocumentList)

export { CustomsDocumentsCard, CustomsDocumentList }

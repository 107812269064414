import moment from "moment"

export const statusMap = {
    AT_PICKUP: { progress: 1, error: false },
    PICKED_UP: { progress: 1, error: false },
    PICKUP_MISSED: { progress: 0, error: true },
    PICKUP: { progress: 1, error: false },
    INTERLINE: { progress: 2, error: false },
    INTERLINE_MISSED: { progress: 2, error: true },
    AT_LOCATION: { progress: 2, error: false },
    DEPARTED: { progress: 2, error: false },
    DEPARTURE_MISSED: { progress: 2, error: true },
    DEPARTURE: { progress: 2, error: false },
    IN_TRANSIT: { progress: 2, error: false },
    HELD: { progress: 2, error: true },
    DELAYED: { progress: 2, error: true },
    INFO: { progress: 2, error: false },
    OUT_FOR_DELIVERY: { progress: 3, error: false },
    DELIVERY_MISSED: { progress: 3, error: true },
    DELIVERY_APPOINTMENT: { progress: 3, error: false },
    AT_DELIVERY: { progress: 3, error: false },
    DELIVERED: { progress: 4, error: false },
    INTERLINE_DELIVERED: { progress: 4, error: false },
    DELIVERED_EXCEPTION: { progress: 4, error: true },
    DELIVERY: { progress: 4, error: false },
    EXCEPTION: { progress: 0, error: true },
    CANCELED: { progress: 0, error: false },
}

const getStatus = item =>
    item &&
    item.status &&
    item.status.currentStatus &&
    item.status.currentStatus.code

const isShipmentException = item => {
    const statusCode = getStatus(item)
    if (!statusCode) return false
    const status = statusMap[statusCode]
    return !status || status.error
}

export const anyShipmentException = list =>
    Object.keys(list).some(key => isShipmentException(list[key]))

import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { currencyLabel } from "../util/currencyLabel"
import { Box, Typography } from "@material-ui/core"

function OrdersRateBreakdown(props) {
    const {
        charges,
        totalInPreferredCurrency,
        preferredCurrencyCode,
        quoteId,
    } = props

    return (
        <Box sx={{ p: "7px" }}>
            {charges.length === 0 ? (
                <Typography variant="caption">
                    <FormattedMessage
                        defaultMessage="No charges to show."
                        id="orders.rateBreakdown.noCharges"
                    />
                </Typography>
            ) : null}
            {charges?.map(entry => {
                if (
                    entry?.description === "OTHER: Other" &&
                    entry?.code === "DSC"
                ) {
                    let description = "Freight Discount:Other"
                    if (entry.rate) {
                        description = description + ` ${entry.rate}%`
                    }
                    entry.description = description
                }
                return entry.amountInPreferredCurrency || entry.amount ? (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box
                            sx={{
                                pr: "30px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Typography variant="caption" align="right">
                                {entry.description}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                flexDirection: "row",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Typography variant="caption" align="right">
                                {currencyLabel(
                                    preferredCurrencyCode,
                                    entry?.amountInPreferredCurrency ??
                                        entry?.amount
                                )}
                            </Typography>
                        </Box>
                    </Box>
                ) : null
            })}
            <Box sx={{ borderBottom: "1px solid #d4d4d4" }} />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bold" }}
                        align={
                            preferredCurrencyCode === "MXN" ? "center" : "right"
                        }
                    >
                        <FormattedMessage
                            id="rateBreakdown__total"
                            defaultMessage="Total"
                        />
                        {":"}
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        style={{ fontWeight: "bold" }}
                        variant="subtitle2"
                        align="right"
                    >
                        {currencyLabel(
                            preferredCurrencyCode,
                            totalInPreferredCurrency
                        )}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                }}
            >
                <Box sx={{ pr: "5px" }}>
                    <Typography
                        style={{ fontWeight: "bold" }}
                        variant="caption"
                        align="right"
                    >
                        <FormattedMessage
                            id="orders.rateBreakdown.quoteId"
                            defaultMessage="Quote ID#"
                        />
                        {":"}
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        style={{ fontWeight: "bold" }}
                        variant="caption"
                        align="right"
                    >
                        {quoteId}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

OrdersRateBreakdown.propTypes = {
    charges: PropTypes.array.isRequired,
    totalInPreferredCurrency: PropTypes.string,
    preferredCurrencyCode: PropTypes.string,
    quoteId: PropTypes.string,
}

export default OrdersRateBreakdown

import React, { Fragment } from "react"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import { Chip } from "@material-ui/core"
import { Field } from "redux-form"
import FormSelect from "../form/form-select"
import { FormattedMessage } from "react-intl"
import FormSwitch from "../form/form-switch"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const styles = theme => ({
    alert__icon: {
        marginTop: "15px",
    },
    fields__container: {
        padding: "8px",
        marginTop: "5px",
    },
    fields__container__alternate: {
        padding: "8px",
        marginTop: "16px",
    },
    selectAll__button: {
        marginLeft: "5px",
    },
    chip: {
        margin: "10px 5px",
    },
    fields__switches: {
        marginTop: "10px",
    },
})

const alertPreferencesOptions = [
    {
        value: "email",
        label: (
            <FormattedMessage
                id="alertsPreferences__email"
                defaultMessage="Email"
            />
        ),
    },
    {
        value: "application",
        label: (
            <FormattedMessage
                id="alertsPreferences__inApp"
                defaultMessage="In App"
            />
        ),
    },
    {
        value: "both",
        label: (
            <FormattedMessage
                id="alertsPreferences__both"
                defaultMessage="Email & In App"
            />
        ),
    },
    {
        value: "none",
        label: (
            <FormattedMessage
                id="alertsPreferences__none"
                defaultMessage="None"
            />
        ),
    },
]

function AlertsUserFields(props) {
    const {
        classes,
        share,
        chooseWarnings,
        subject,
        disableAlertPanel,
        disabledFields,
        isFedExShipment,
        gaCategory,
    } = props
    const size = chooseWarnings ? 6 : 11
    const { logGAEvent } = useGAContext()

    return (
        <Grid container className={classes.fields__container}>
            <Grid item container alignItems="center">
                {share && (
                    <Grid item container alignItems="center">
                        <Chip
                            label={
                                <FormattedMessage
                                    id="alertsPreferences__myNotifications"
                                    defaultMessage="My Notifications"
                                />
                            }
                            className={classes.chip}
                        />
                    </Grid>
                )}
                {isFedExShipment && (
                    <Grid
                        item
                        container
                        xs={size}
                        className={classes.fields__switches}
                    >
                        <Field
                            name={`alerts["${subject}"].fedExDirectNotifications`}
                            component={FormSwitch}
                            label={
                                <FormattedMessage
                                    id="alertsPreferences__fedExNotifications"
                                    defaultMessage="FedEx Notification Emails"
                                />
                            }
                            disabled={
                                disableAlertPanel ||
                                disabledFields.disableDispatched
                            }
                            category={gaCategory}
                        />
                    </Grid>
                )}
                <Grid item container xs={size}>
                    <Field
                        name={`alerts[${subject}].dispatched`}
                        component={FormSelect}
                        label={
                            <FormattedMessage
                                id="alertsPreferences__dispatched"
                                defaultMessage="Dispatched"
                            />
                        }
                        options={alertPreferencesOptions}
                        disabled={
                            disableAlertPanel ||
                            disabledFields.disableDispatched
                        }
                        category={gaCategory}
                    />
                </Grid>
                {chooseWarnings && (
                    <Fragment>
                        <Grid item container xs={size}>
                            <Field
                                name={`alerts[${subject}].warning`}
                                component={FormSelect}
                                label={
                                    <FormattedMessage
                                        id="alertsPreferences__alerts"
                                        defaultMessage="Alerts"
                                    />
                                }
                                options={alertPreferencesOptions}
                                disabled={
                                    disableAlertPanel ||
                                    disabledFields.disableAlerts
                                }
                                category={gaCategory}
                            />
                        </Grid>
                    </Fragment>
                )}
            </Grid>
            <Grid item container>
                <Grid item container xs={size}>
                    <Field
                        name={`alerts[${subject}].pickedUp`}
                        component={FormSelect}
                        label={
                            <FormattedMessage
                                id="alertsPreferences__pickedUp"
                                defaultMessage="Picked Up"
                            />
                        }
                        options={alertPreferencesOptions}
                        disabled={
                            disableAlertPanel || disabledFields.disablePickedUp
                        }
                        category={gaCategory}
                    />
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item container xs={size}>
                    <Field
                        name={`alerts[${subject}].outForDelivery`}
                        component={FormSelect}
                        label={
                            <FormattedMessage
                                id="alertsPreferences__outForDelivery"
                                defaultMessage="Out For Delivery"
                            />
                        }
                        options={alertPreferencesOptions}
                        disabled={
                            disableAlertPanel ||
                            disabledFields.disableOutForDelivery
                        }
                        category={gaCategory}
                    />
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item container xs={size}>
                    <Field
                        name={`alerts[${subject}].delivered`}
                        component={FormSelect}
                        label={
                            <FormattedMessage
                                id="alertsPreferences__delivered"
                                defaultMessage="Delivered"
                            />
                        }
                        options={alertPreferencesOptions}
                        disabled={
                            disableAlertPanel || disabledFields.disableDelivered
                        }
                        category={gaCategory}
                    />
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item container xs={size}>
                    <Field
                        name={`alerts[${subject}].podImageAvailable`}
                        component={FormSelect}
                        label={
                            <FormattedMessage
                                id="alertsPreferences__podImageAvailable"
                                defaultMessage="POD Image Available"
                            />
                        }
                        options={alertPreferencesOptions}
                        disabled={disableAlertPanel}
                        category={gaCategory}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(AlertsUserFields)

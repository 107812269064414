import React, { Fragment } from "react"
import { connect } from "react-redux"
import {
    withStyles,
    Grid,
    Typography,
    List,
    ListItem,
    Divider,
    Paper,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { calculateMarkupPrice } from "../../../../quote/card/util"
import {
    RateDeliveryDate,
    RateDeliveryTime,
    RateFreightDirectServiceLevel,
    RateGuaranteed,
} from "../../summaryCards/RatesSummary"
import { isFedExCarrier } from "../../../../../misc"
import { containsCombinedOtherRatesAndFedexOceanRates } from "../../../../util/carrierLogoUtils"

const styles = theme => ({
    form__container: {
        padding: theme.spacing(2),
    },
    carrier__logo: {
        height: "41px",
        width: "71px",
    },
    carrier__tag: {},
    original__price: {
        textDecoration: `line-through ${theme.palette.text.secondary}`,
    },
    list: {
        backgroundColor: theme.palette.background.paper,
    },
})

const QuotesPanel = ({
    selections = [],
    carriers,
    classes,
    markup,
    currencyCode,
}) => (
    <Paper className={classes.form__container}>
        <Typography variant="h5" gutterBottom>
            <FormattedMessage
                id="rateResults.share__ratesHeading"
                defaultMessage="Share the following quotes:"
            />
        </Typography>
        <List className={classes.list} dense disablePadding>
            {selections.map((quote = {}, i) => {
                const originalPrice =
                    quote?.rateQuoteDetail?.totalInPreferredCurrency ??
                    quote?.rateQuoteDetail?.total
                const markupPrice = calculateMarkupPrice(originalPrice, markup)
                const isFreightDirect = quote?.mode === "LTL_DIRECT"
                const isFreightDirectReturns =
                    quote?.mode === "LTL_DIRECT_RETURNS"
                const containsCombinedOtherRatesAndFedexOceanRatesFlag = containsCombinedOtherRatesAndFedexOceanRates(
                    quote
                )
                const carrierName = carriers[quote?.carrierCode]?.name
                const carrierNameStr = containsCombinedOtherRatesAndFedexOceanRatesFlag
                    ? carrierName + " - Fedex"
                    : carrierName
                return (
                    <Fragment>
                        {i !== 0 && <Divider />}
                        <ListItem>
                            <Grid
                                item
                                xs={12}
                                container
                                spacing={2}
                                alignItems="center"
                                key={i}
                            >
                                <Grid
                                    item
                                    justify="flex-start"
                                    alignItems="center"
                                    xs={2}
                                >
                                    {isFedExCarrier(quote?.carrierCode) ? (
                                        <img
                                            className={classes.carrier__logo}
                                            src="https://cloud.p-44.com/img/carriers/FXFElogo.png"
                                            alt="FXNL"
                                        />
                                    ) : (
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.carrier__tag}
                                        >
                                            {carrierNameStr}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    <RateDeliveryDate rate={quote} />
                                </Grid>
                                {!quote?.isNonRateable && !isFreightDirect && (
                                    <Grid item xs={2}>
                                        <RateDeliveryTime
                                            rate={quote}
                                            isFreightDirect={isFreightDirect}
                                        />
                                    </Grid>
                                )}

                                {(isFreightDirect ||
                                    isFreightDirectReturns) && (
                                    <Grid item xs={2}>
                                        <RateFreightDirectServiceLevel
                                            rate={quote}
                                            isFreightDirect={isFreightDirect}
                                            isFreightDirectReturns={
                                                isFreightDirectReturns
                                            }
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={2}>
                                    <RateGuaranteed
                                        rate={quote}
                                        canViewRateAmounts
                                        variant={"body2"}
                                    />
                                </Grid>
                                {quote?.isDomesticOffshore && (
                                    <Grid item xs={3}>
                                        <Typography variant="caption">
                                            <FormattedMessage
                                                id="generalTerms__domesticOffshore"
                                                defaultMessage="Domestic Offshore"
                                            />
                                        </Typography>
                                    </Grid>
                                )}
                                {originalPrice && (
                                    <Grid item>
                                        {originalPrice !== markupPrice ? (
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                className={
                                                    classes.original__price
                                                }
                                            >
                                                {`${currencyCode}${originalPrice.toFixed(
                                                    2
                                                )}`}
                                            </Typography>
                                        ) : (
                                            <Typography variant="body2">{`${currencyCode}${originalPrice.toFixed(
                                                2
                                            )}`}</Typography>
                                        )}
                                    </Grid>
                                )}
                                {originalPrice && (
                                    <Grid item>
                                        {originalPrice !== markupPrice && (
                                            <Typography variant="body2">{`${currencyCode}${markupPrice.toFixed(
                                                2
                                            )}`}</Typography>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </ListItem>
                    </Fragment>
                )
            })}
        </List>
    </Paper>
)

const mapStateToProps = state => ({
    carriers: state.carriers.carriers,
    guaranteedList: state.accessorials.list.guaranteed,
})

export default withStyles(styles)(connect(mapStateToProps)(QuotesPanel))

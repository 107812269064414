import React from "react"
import { Grid, FormControlLabel, Checkbox, Box } from "@material-ui/core"

const ListItem = ({ item, handleToggle, checked }) => {
    return (
        <Box
            sx={{
                borderBottom: "solid 1px #d4d4d4",
                width: "100%",
            }}
        >
            <Grid item container>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            value={item.value}
                            onChange={() => handleToggle(item.value)}
                        />
                    }
                    label={item.label}
                />
            </Grid>
        </Box>
    )
}

export default ListItem

export const weightUnitPluralCapital = preferredSystemOfMeasurement =>
    preferredSystemOfMeasurement === "METRIC" ? "KG" : "LB"

export const weightUnitSingularCapital = preferredSystemOfMeasurement =>
    preferredSystemOfMeasurement === "METRIC" ? "KG" : "LB"

export const weightUnitSingular = preferredSystemOfMeasurement =>
    preferredSystemOfMeasurement === "METRIC" ? "kg" : "lb"

export const weightUnit = preferredSystemOfMeasurement =>
    preferredSystemOfMeasurement === "METRIC" ? "kg" : "lb"

export const lengthUnit = preferredSystemOfMeasurement =>
    preferredSystemOfMeasurement === "METRIC" ? "cm" : "in"

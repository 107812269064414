import React, { useEffect, useState } from "react"
import Checkbox from "@material-ui/core/Checkbox"
import { makeStyles } from "@material-ui/core/styles"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Table from "@material-ui/core/Table"
import Typography from "@material-ui/core/Typography"
import { Skeleton } from "@material-ui/lab"
import { FormattedMessage } from "react-intl"

const useStyles = makeStyles(theme => ({
    centered: {
        textAlign: "center",
    },
    checkbox: {
        width: "30px",
    },
    expandCell: {
        minWidth: "60px",
    },
    row: {
        backgroundColor: theme.palette.action.hover,
    },
    table: {
        height: "536px",
    },
}))

const OrdersTableSpinner = ({ tableColumns }) => {
    const classes = useStyles()
    const [columns, setColumns] = useState([])

    useEffect(() => {
        setColumns(tableColumns)
    }, [])
    const rows = Array(10)
        .fill(0)
        .map((_, index) => {
            return (
                <TableRow key={`spinner-${index}`} height={40}>
                    <TableCell className={classes.checkbox}>
                        <Checkbox disabled={true} />
                    </TableCell>
                    {columns.map((item, index) => {
                        return (
                            <TableCell key={`cell-${index}`}>
                                <Skeleton style={{ height: "30px" }} />
                            </TableCell>
                        )
                    })}
                </TableRow>
            )
        })

    return (
        <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.checkbox}>
                        <Checkbox disabled={true} />
                    </TableCell>
                    {columns.map((column, index) => (
                        <TableCell
                            key={`table-spinner-column-${index}`}
                            className={classes.expandCell}
                        >
                            <Skeleton style={{ height: "30px" }} />
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
        </Table>
    )
}

export default OrdersTableSpinner

import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from "@material-ui/core"
import { FormattedMessage, injectIntl } from "react-intl"
import GlobalSpinner from "../common/GlobalSpinner"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { schema } from "./validation"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"
import {
    freightClasses,
    provideAllPackageTypes,
    provideHazMatClasses,
    provideHazMatContainers,
    provideHazMatPkgGrp,
    provideMeasurementSystem,
} from "./constants"
import { useItemsContext } from "../../context/providers/ItemsProvider"

const gaCategory = "Items"

const AddEditItemModal = ({ open, onClose, intl, item }) => {
    const {
        saveItem,
        updateItem,
        isHazmat,
        setIsHazmat,
        loading,
    } = useItemsContext()
    const { logGAEvent } = useGAContext()
    const submitRef = useRef(null)
    const {
        handleSubmit,
        register,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        context: { isHazmat },
    })

    useEffect(() => {
        reset()
        if (item) {
            setIsHazmat(item.isHazMat)
        }
    }, [open])

    const measurementSystem = watch(
        "measurementSystem",
        item?.measurementSystem ?? "IMPERIAL"
    )

    const saveAndClose = async data => {
        if (item) {
            await updateItem({ ...data, isHazMat: isHazmat }, item._id)
        } else {
            await saveItem({ ...data, isHazMat: isHazmat }, isHazmat)
        }
        onClose()
        setIsHazmat(false)
    }

    const handleClose = () => {
        logGAEvent("Items", "Cancel Add")
        onClose()
        setIsHazmat(false)
    }

    const logGAField = fieldName => {
        logGAEvent(gaCategory, `Add/Edit Field Text`, fieldName)
    }

    const logGADropdown = (fieldName, label) => {
        logGAEvent(gaCategory, `Add/Edit Dropdown`, `${fieldName}: ${label}`)
    }

    const handleExternalSubmit = () => {
        if (submitRef.current) {
            submitRef.current.click()
        }
    }

    return (
        <Box>
            {loading ? <GlobalSpinner /> : null}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="items-add-dialog"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
                scroll="paper"
            >
                <DialogTitle id="items-add-dialog-title">
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        {item ? (
                            <FormattedMessage
                                id="items.edit.title"
                                defaultMessage="Edit item"
                            />
                        ) : (
                            <FormattedMessage
                                id="items.add.title"
                                defaultMessage="Add a new item"
                            />
                        )}
                        <FormControl
                            style={{
                                width: "30%",
                            }}
                        >
                            <InputLabel>
                                <FormattedMessage
                                    id="items.measurementSystem"
                                    defaultMessage="Measurement System"
                                />
                            </InputLabel>
                            <Select
                                {...register("measurementSystem")}
                                defaultValue={
                                    item?.measurementSystem ?? "IMPERIAL"
                                }
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                }}
                                variant="standard"
                                labelId="items-measurement-system"
                                id="items-measurement-system"
                                onBlur={evt => {
                                    logGADropdown(
                                        "measurementSystem",
                                        evt.target.value
                                    )
                                }}
                                label={
                                    <FormattedMessage
                                        id="items.measurementSystem"
                                        defaultMessage="Measurement System"
                                    />
                                }
                            >
                                {provideMeasurementSystem(intl).map(time => (
                                    <MenuItem
                                        key={time.value}
                                        value={time.value}
                                    >
                                        {time.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <form noValidate onSubmit={handleSubmit(saveAndClose)}>
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Box
                                style={{
                                    width: "100%",
                                    marginBottom: "24px",
                                }}
                            >
                                <Box>
                                    <TextField
                                        autoComplete="off"
                                        defaultValue={item?.description}
                                        required
                                        style={{
                                            width: "100%",
                                            paddingRight: "10px",
                                        }}
                                        label={
                                            <FormattedMessage
                                                id="items.description"
                                                defaultMessage="Description"
                                            />
                                        }
                                        {...register("description")}
                                        onBlur={() => logGAField("description")}
                                        error={!!errors.description}
                                        helperText={
                                            errors.description
                                                ? errors.description?.message
                                                : ""
                                        }
                                    />
                                </Box>
                                <Box>
                                    <FormControl
                                        error={!!errors.packageType}
                                        required
                                        style={{
                                            width: "30%",
                                            paddingRight: "10px",
                                        }}
                                    >
                                        <InputLabel>
                                            <FormattedMessage
                                                id="items.packageType"
                                                defaultMessage="Package Type"
                                            />
                                        </InputLabel>
                                        <Select
                                            {...register("packageType")}
                                            defaultValue={
                                                item?.packageType ?? ""
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 200, // Set your desired maxHeight here
                                                    },
                                                },
                                            }}
                                            variant="standard"
                                            labelId="items-package-type"
                                            id="items-page-type"
                                            onBlur={evt =>
                                                logGADropdown(
                                                    "packageType",
                                                    evt.target.value
                                                )
                                            }
                                            label={
                                                <FormattedMessage
                                                    id="items.packageType"
                                                    defaultMessage="Package Type"
                                                />
                                            }
                                        >
                                            {provideAllPackageTypes(intl).map(
                                                time => (
                                                    <MenuItem
                                                        key={time.value}
                                                        value={time.value}
                                                    >
                                                        {time.label}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                        {errors?.packageType ? (
                                            <FormHelperText error={true}>
                                                {errors?.packageType?.message}
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>
                                    <FormControl
                                        error={!!errors.freightClass}
                                        required
                                        style={{
                                            width: "25%",
                                            paddingRight: "10px",
                                        }}
                                    >
                                        <InputLabel>
                                            <FormattedMessage
                                                id="items.freightClass"
                                                defaultMessage="Freight Class"
                                            />
                                        </InputLabel>
                                        <Select
                                            defaultValue={
                                                item?.freightClass ?? ""
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 200, // Set your desired maxHeight here
                                                    },
                                                },
                                            }}
                                            variant="standard"
                                            labelId="items-freight-class"
                                            id="items-freight-class"
                                            {...register("freightClass")}
                                            onBlur={evt =>
                                                logGADropdown(
                                                    "freightClass",
                                                    evt.target.value
                                                )
                                            }
                                            label={
                                                <FormattedMessage
                                                    id="items.freightClass"
                                                    defaultMessage="Freight Class"
                                                />
                                            }
                                        >
                                            {freightClasses.map(time => (
                                                <MenuItem
                                                    key={time.value}
                                                    value={time.value}
                                                >
                                                    {time.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors?.freightClass ? (
                                            <FormHelperText error={true}>
                                                {errors?.freightClass?.message}
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>
                                    <TextField
                                        defaultValue={item?.nmfc1}
                                        placeholder="xxxxxx"
                                        autoComplete="off"
                                        inputProps={{
                                            maxLength: 6,
                                        }}
                                        style={{
                                            width: "25%",
                                            paddingRight: "10px",
                                        }}
                                        label={
                                            <FormattedMessage
                                                id="items.nmfc1"
                                                defaultMessage="NMFC Code"
                                            />
                                        }
                                        {...register("nmfc1")}
                                        onBlur={() => logGAField("nmfc1")}
                                        error={!!errors?.nmfc1}
                                        helperText={
                                            errors?.nmfc1
                                                ? errors?.nmfc1?.message
                                                : ""
                                        }
                                    />
                                    <TextField
                                        defaultValue={item?.nmfc2}
                                        placeholder="xx"
                                        inputProps={{
                                            maxLength: 2,
                                        }}
                                        autoComplete="off"
                                        style={{
                                            width: "20%",
                                            paddingRight: "10px",
                                        }}
                                        label={
                                            <FormattedMessage
                                                id="items.nmfc2"
                                                defaultMessage="Subcode"
                                            />
                                        }
                                        {...register("nmfc2")}
                                        onBlur={() => logGAField("nmfc2")}
                                        error={!!errors?.nmfc2}
                                        helperText={
                                            errors?.nmfc2
                                                ? errors?.nmfc2?.message
                                                : ""
                                        }
                                    />

                                    <TextField
                                        autoComplete="off"
                                        required
                                        defaultValue={item?.length}
                                        style={{
                                            width: "20%",
                                            paddingRight: "10px",
                                        }}
                                        label={
                                            <FormattedMessage
                                                id="items.length"
                                                defaultMessage="Length ({measurement})"
                                                values={{
                                                    measurement:
                                                        measurementSystem ===
                                                        "IMPERIAL"
                                                            ? "in"
                                                            : "cm",
                                                }}
                                            />
                                        }
                                        {...register("length")}
                                        onBlur={() => logGAField("length")}
                                        error={!!errors?.length}
                                        helperText={
                                            errors?.length
                                                ? errors?.length?.message
                                                : ""
                                        }
                                    />
                                    <TextField
                                        defaultValue={item?.width}
                                        autoComplete="off"
                                        required
                                        style={{
                                            width: "20%",
                                            paddingRight: "10px",
                                        }}
                                        label={
                                            <FormattedMessage
                                                id="items.width"
                                                defaultMessage="Width ({measurement})"
                                                values={{
                                                    measurement:
                                                        measurementSystem ===
                                                        "IMPERIAL"
                                                            ? "in"
                                                            : "cm",
                                                }}
                                            />
                                        }
                                        {...register("width")}
                                        onBlur={() => logGAField("width")}
                                        error={!!errors?.width}
                                        helperText={
                                            errors?.width
                                                ? errors?.width?.message
                                                : ""
                                        }
                                    />
                                    <TextField
                                        defaultValue={item?.height}
                                        autoComplete="off"
                                        required
                                        style={{
                                            width: "20%",
                                            paddingRight: "10px",
                                        }}
                                        label={
                                            <FormattedMessage
                                                id="items.height"
                                                defaultMessage="Height ({measurement})"
                                                values={{
                                                    measurement:
                                                        measurementSystem ===
                                                        "IMPERIAL"
                                                            ? "in"
                                                            : "cm",
                                                }}
                                            />
                                        }
                                        {...register("height")}
                                        onBlur={() => logGAField("height")}
                                        error={!!errors?.height}
                                        helperText={
                                            errors?.height
                                                ? errors?.height?.message
                                                : ""
                                        }
                                    />
                                    <TextField
                                        defaultValue={item?.weight}
                                        autoComplete="off"
                                        required
                                        style={{
                                            width: "20%",
                                            paddingRight: "10px",
                                        }}
                                        label={
                                            <FormattedMessage
                                                id="items.weight"
                                                defaultMessage="Weight ({measurement})"
                                                values={{
                                                    measurement:
                                                        measurementSystem ===
                                                        "IMPERIAL"
                                                            ? "lb"
                                                            : "kg",
                                                }}
                                            />
                                        }
                                        {...register("weight")}
                                        onBlur={() => logGAField("weight")}
                                        error={!!errors?.weight}
                                        helperText={
                                            errors?.weight
                                                ? errors?.weight?.message
                                                : ""
                                        }
                                    />
                                    <TextField
                                        defaultValue={item?.pieces}
                                        autoComplete="off"
                                        required
                                        style={{
                                            width: "20%",
                                            paddingRight: "10px",
                                        }}
                                        label={
                                            <FormattedMessage
                                                id="items.pieces"
                                                defaultMessage="Pieces"
                                            />
                                        }
                                        {...register("pieces")}
                                        onBlur={() => logGAField("pieces")}
                                        error={!!errors?.pieces}
                                        helperText={
                                            errors?.pieces
                                                ? errors?.pieces?.message
                                                : ""
                                        }
                                    />
                                    <Box sx={{ mt: "20px" }}>
                                        <FormControlLabel
                                            style={{ width: "100%" }}
                                            control={
                                                <Switch
                                                    checked={isHazmat}
                                                    onChange={() => {
                                                        setIsHazmat(!isHazmat)
                                                    }}
                                                    name="isHazmat"
                                                    color="secondary"
                                                />
                                            }
                                            label={
                                                <FormattedMessage
                                                    id="items.hazmat"
                                                    defaultMessage="Hazmat"
                                                />
                                            }
                                        />
                                        {isHazmat ? (
                                            <Box>
                                                <TextField
                                                    required
                                                    defaultValue={
                                                        item?.unNumber
                                                    }
                                                    autoComplete="off"
                                                    style={{
                                                        width: "20%",
                                                        paddingRight: "10px",
                                                    }}
                                                    label={
                                                        <FormattedMessage
                                                            id="items.unNumber"
                                                            defaultMessage="UN #"
                                                        />
                                                    }
                                                    {...register("unNumber")}
                                                    onBlur={() =>
                                                        logGAField("unNumber")
                                                    }
                                                    error={!!errors?.unNumber}
                                                    helperText={
                                                        errors?.unNumber
                                                            ? errors?.unNumber
                                                                  ?.message
                                                            : ""
                                                    }
                                                />
                                                <FormControl
                                                    error={errors.hazClass}
                                                    required
                                                    style={{
                                                        width: "35%",
                                                        paddingRight: "10px",
                                                    }}
                                                >
                                                    <InputLabel>
                                                        <FormattedMessage
                                                            id="items.hazmatClass"
                                                            defaultMessage="Hazmat Class"
                                                        />
                                                    </InputLabel>
                                                    <Select
                                                        defaultValue={
                                                            item?.hazClass ?? ""
                                                        }
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 200, // Set your desired maxHeight here
                                                                },
                                                            },
                                                        }}
                                                        variant="standard"
                                                        labelId="items-hazmat-class"
                                                        id="items-hazmat-class"
                                                        {...register(
                                                            "hazClass"
                                                        )}
                                                        onBlur={evt =>
                                                            logGADropdown(
                                                                "hazClass",
                                                                evt.target.value
                                                            )
                                                        }
                                                        label={
                                                            <FormattedMessage
                                                                id="items.hazmatClass"
                                                                defaultMessage="Hazmat Class"
                                                            />
                                                        }
                                                    >
                                                        {provideHazMatClasses(
                                                            intl
                                                        ).map(time => (
                                                            <MenuItem
                                                                key={time.value}
                                                                value={
                                                                    time.value
                                                                }
                                                            >
                                                                {time.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors?.hazClass ? (
                                                        <FormHelperText
                                                            error={true}
                                                        >
                                                            {
                                                                errors?.hazClass
                                                                    ?.message
                                                            }
                                                        </FormHelperText>
                                                    ) : null}
                                                </FormControl>
                                                <FormControl
                                                    error={errors.pkgGrp}
                                                    required
                                                    style={{
                                                        width: "20%",
                                                        paddingRight: "10px",
                                                    }}
                                                >
                                                    <InputLabel>
                                                        <FormattedMessage
                                                            id="items.packageGroup"
                                                            defaultMessage="Pkg Group"
                                                        />
                                                    </InputLabel>
                                                    <Select
                                                        defaultValue={
                                                            item?.pkgGrp ?? ""
                                                        }
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 200, // Set your desired maxHeight here
                                                                },
                                                            },
                                                        }}
                                                        variant="standard"
                                                        labelId="items-hazmat-package-group"
                                                        id="items-hazmat-package-group"
                                                        {...register("pkgGrp")}
                                                        onBlur={evt =>
                                                            logGADropdown(
                                                                "pkgGrp",
                                                                evt.target.value
                                                            )
                                                        }
                                                        label={
                                                            <FormattedMessage
                                                                id="items.pkgGrp"
                                                                defaultMessage="Pkg Group"
                                                            />
                                                        }
                                                    >
                                                        {provideHazMatPkgGrp(
                                                            intl
                                                        ).map(time => (
                                                            <MenuItem
                                                                key={time.value}
                                                                value={
                                                                    time.value
                                                                }
                                                            >
                                                                {time.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors?.pkgGrp ? (
                                                        <FormHelperText
                                                            error={true}
                                                        >
                                                            {
                                                                errors?.pkgGrp
                                                                    ?.message
                                                            }
                                                        </FormHelperText>
                                                    ) : null}
                                                </FormControl>
                                                <FormControl
                                                    error={errors.hazContainer}
                                                    required
                                                    style={{
                                                        width: "25%",
                                                        paddingRight: "10px",
                                                    }}
                                                >
                                                    <InputLabel>
                                                        <FormattedMessage
                                                            id="items.containerType"
                                                            defaultMessage="Container Type"
                                                        />
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        defaultValue={
                                                            item?.hazContainer ??
                                                            ""
                                                        }
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 200, // Set your desired maxHeight here
                                                                },
                                                            },
                                                        }}
                                                        variant="standard"
                                                        labelId="items-hazmat-package-group"
                                                        id="items-hazmat-package-group"
                                                        {...register(
                                                            "hazContainer"
                                                        )}
                                                        onBlur={evt =>
                                                            logGADropdown(
                                                                "hazContainer",
                                                                evt.target.value
                                                            )
                                                        }
                                                        label={
                                                            <FormattedMessage
                                                                id="items.containerType"
                                                                defaultMessage="Container Type"
                                                            />
                                                        }
                                                    >
                                                        {provideHazMatContainers(
                                                            intl
                                                        ).map(time => (
                                                            <MenuItem
                                                                key={time.value}
                                                                value={
                                                                    time.value
                                                                }
                                                            >
                                                                {time.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors?.hazContainer ? (
                                                        <FormHelperText
                                                            error={true}
                                                        >
                                                            {
                                                                errors
                                                                    ?.hazContainer
                                                                    ?.message
                                                            }
                                                        </FormHelperText>
                                                    ) : null}
                                                </FormControl>
                                            </Box>
                                        ) : null}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {/* This is a hidden submit button because the form needs to live inside of the DialogContent to maintain styles. 
                        It uses a ref that submits the form when the Update button is pushed*/}
                        <Button
                            ref={submitRef}
                            style={{ display: "none" }}
                            type="submit"
                        />
                    </form>
                </DialogContent>
                <DialogActions
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={handleClose}
                        style={{ margin: "6px 12px" }}
                    >
                        <FormattedMessage
                            id="generalTerms__cancel"
                            defaultMessage="Cancel"
                        />
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        style={{ margin: "6px 12px" }}
                        autoFocus
                        onClick={handleExternalSubmit}
                    >
                        <FormattedMessage
                            id="generalTerms__save"
                            defaultMessage="Save"
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

AddEditItemModal.propTypes = {
    intl: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    item: PropTypes.shape({
        freightClass: PropTypes.number,
        weight: PropTypes.number,
        length: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        packageType: PropTypes.string,
        pieces: PropTypes.number,
        description: PropTypes.string,
        nmfc1: PropTypes.string,
        nmfc2: PropTypes.string,
        _id: PropTypes.string,
        measurementSystem: PropTypes.string,
        isHazMat: PropTypes.bool,
        unNumber: PropTypes.string,
        pkgGrp: PropTypes.string,
        hazClass: PropTypes.string,
        hazContainer: PropTypes.string,
    }),
}

export default injectIntl(AddEditItemModal)

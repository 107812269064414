import green from "@material-ui/core/colors/green"

export const styles = theme => ({
    parent__container: {
        margin: "2% 3%",
    },
    details__container: {
        margin: "1% 0%",
        minWidth: "1024px",
    },
    button__left: {
        marginLeft: theme.spacing(1),
    },
    details__content: {
        marginTop: "10px",
        padding: theme.spacing(3),
        "min-width": "870px",
    },
    carrier__logo: {
        height: "41px",
        width: "71px",
    },
    map__container: {
        "min-height": "350px",
    },
    navigation__buttons: {
        width: "70px",
        height: "70px",
    },
    shipment__item__column: {
        padding: "0 10px",
    },
    item__total: {
        "margin-left": "16px",
        "margin-top": "auto",
    },
    caption_inline: {
        display: "inline",
        "margin-left": "16px",
    },
    items_paper: {
        height: "100%",
    },
    items__divider: {
        "border-top": "1px solid black",
        "margin-top": "auto",
        "margin-left": "16px",
        "margin-right": "16px",
    },
    payment_detail: {
        width: "100%",
    },
    progress: {
        margin: theme.spacing(2),
        top: "50%",
        right: "50%",
        position: "absolute",
        zIndex: 9999,
    },
    destination_column: {
        paddingLeft: "20px",
    },
    destination_card: {
        paddingBottom: "20px",
    },
    origin_card: {
        paddingBottom: "20px",
    },
    timeline_column: {
        height: "100%",
    },
    chat: {
        paddingRight: "10px",
    },
    alerts__card: {
        padding: "15px 30px",
        width: "100%",
    },
    alerts__card__container: {
        width: "100%",
    },
    snackBar: {
        backgroundColor: green[600],
    },
    handlingUnitTimeline: {
        paddingBottom: "15px",
    },
    handlingUnitItemDetail: {
        paddingRight: "12px",
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        marginRight: "16px",
    },
    handlingUnitProNumber: {
        textDecoration: "underline",
    },
    masterTimeline__container: {
        paddingBottom: "20px",
    },
    alertsAvatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    trackingAvatar: {
        backgroundColor: theme.palette.primary.main,
    },
    starAvatar: {
        margin: "5px",
        backgroundColor: "#fff",
    },
})

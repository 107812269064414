import React, { useState, useEffect, Fragment } from "react"
import { Button, DialogActions, Typography } from "@material-ui/core"
import { formValues, getFormValues } from "redux-form"
import { FormattedMessage } from "react-intl"
import { useCustomsProfileContext } from "../../../context/providers/CustomsProfileProvider"
import { connect } from "react-redux"
import formName from "../../util/form-name"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"

const BaseFilenameCollisionCheckStep = ({
    allFormValues,
    customFilename,
    userFileName,
    documentCategory,
    shipmentDocumentList,
    onBack,
    onNoCollision,
    onSubmit,
    shipmentId,
    shipmentOnly,
    enableProfileOverwrite = true,
    enableShipmentOverwrite = true,
}) => {
    const [shipmentFilenameCollision, setShipmentFilenameCollision] = useState(
        null
    )
    const [profileFilenameCollision, setProfileFilenameCollision] = useState(
        null
    )
    const { checkFilenameCollision } = useCustomsProfileContext()

    useEffect(() => {
        const shipmentCollision =
            shipmentId &&
            checkFilenameCollision(
                { documentCategory, customFilename, userFileName },
                shipmentDocumentList ?? []
            )
        const profileCollision =
            !shipmentOnly &&
            checkFilenameCollision({
                documentCategory,
                customFilename,
                userFileName,
            })

        setShipmentFilenameCollision(shipmentCollision)
        setProfileFilenameCollision(profileCollision)

        const initialCollision = shipmentCollision || profileCollision

        if (!initialCollision) {
            onNoCollision(allFormValues)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        shipmentId,
        documentCategory,
        customFilename,
        userFileName,
        shipmentDocumentList,
        shipmentOnly,
        onNoCollision,
        allFormValues,
    ])

    if (!shipmentFilenameCollision && !profileFilenameCollision) {
        return null
    }

    const canOverwriteProfile =
        !profileFilenameCollision || enableProfileOverwrite
    const canOverwriteShipment =
        !shipmentFilenameCollision || enableShipmentOverwrite

    const canOverwrite = canOverwriteProfile && canOverwriteShipment

    if (canOverwrite) {
        return (
            <Fragment>
                <DialogTitle id="alert-dialog-title">
                    <FormattedMessage
                        id="documents.overwrite__title"
                        defaultMessage="Confirm overwrite"
                    />
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2">
                        <FormattedMessage
                            id="documents.filename__conflict"
                            defaultMessage="This file name is already used for an existing document."
                        />
                    </Typography>
                    <Typography variant="body2">
                        <FormattedMessage
                            id="documents.overwrite__text"
                            defaultMessage="You can either rename it, or proceed, and overwrite the previous document"
                        />
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={onBack}
                    >
                        <FormattedMessage
                            id="commercialInvoice.back"
                            defaultMessage="Back"
                        />
                    </Button>
                    <Button
                        type={onSubmit ? "button" : "submit"}
                        variant="contained"
                        color="primary"
                        onClick={onSubmit && (() => onSubmit(allFormValues))}
                    >
                        <FormattedMessage
                            id="document.filename__collision__confirm"
                            defaultMessage="Confirm"
                        />
                    </Button>
                </DialogActions>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <DialogTitle id="alert-dialog-title">
                <FormattedMessage
                    id="documents.filename__conflict__title"
                    defaultMessage="Filename already taken"
                />
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2">
                    {!canOverwriteProfile ? (
                        <FormattedMessage
                            id="documents.profile__filename__conflict"
                            defaultMessage="This file name is already present in the location's customs clearance profile.
                                Please choose a different file name, or if possible, create this document only for this shipment.
                                If you want to update the customs clearance profile document, please go to the location page,
                                create the new version, then link it to this shipment."
                        />
                    ) : (
                        <FormattedMessage
                            id="documents.shipment__filename__conflict"
                            defaultMessage="This file name is already present in the created documents.
                                Please choose a different file name."
                        />
                    )}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={onBack}>
                    <FormattedMessage
                        id="document.filename__collision__acknowledge"
                        defaultMessage="OK"
                    />
                </Button>
            </DialogActions>
        </Fragment>
    )
}

const mapStateToProps = (state, props) => ({
    allFormValues: getFormValues(props?.form)(state),
    shipmentDocumentList:
        props.customShipmentList ??
        state?.shipment?.activeShipment?.identifiers?.internalTrackingNumber ===
            props?.shipmentId
            ? state?.shipment?.activeShipment?.shipment?.attachments ?? []
            : state.shipment.list[props?.shipmentId]?.shipment?.attachments ??
              [],
})

let FilenameCollisionCheckStep = formName(
    connect(mapStateToProps)(
        formValues(
            "customFilename",
            "userFileName",
            "documentCategory",
            "shipmentOnly"
        )(BaseFilenameCollisionCheckStep)
    )
)

let FormlessFilenameCollisionCheckStep = connect(mapStateToProps)(
    BaseFilenameCollisionCheckStep
)

export { FilenameCollisionCheckStep, FormlessFilenameCollisionCheckStep }

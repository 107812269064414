import {
    UNARCHIVE_SHIPMENT,
    ARCHIVE_SHIPMENT,
    STAR_SHIPMENT,
    UNSTAR_SHIPMENT,
    ADD_SHIPMENT,
    REMOVE_SHIPMENT,
    ACTIVE_SHIPMENT_DETAILS,
    CHANGE_STARRED_ACTIVE_SHIPMENT,
    SHIPMENT_LIST_LOAD,
    SHIPMENT_LIST_LOAD_ERROR,
    SHIPMENT_LIST_RESULT,
    SHIPMENT_LIST_RESULT_FILTER_APPLIED,
    SHIPMENT_SEARCH_RESULT,
    QUEUE_SHIPMENT_SEARCH,
    BACKFILL_SHIPMENT_LIST,
    DISMISS_WARNING_ACTIVE_SHIPMENT,
} from "../actions/track"
import {
    NEW_SHIPMENT_FLASH_MESSAGE,
    DISMISS_SHIPMENT_FLASH_MESSAGE,
} from "../actions/book"
import {
    SHIPMENT_EVENT_RECEIVED,
    ALERT_DISMISS,
    ALERT_EVENT,
} from "../actions/chat"
import { USER_DISAUTHENTICATED } from "../actions/user"
import { CHANGE_PAGE_SIZE, CHANGE_PAGE } from "../actions/pagination"
import { get } from "lodash"

const defaultShipmentState = {
    isFetching: false,
    isLoaded: false,
    flashMessage: false,
    totalCount: 0,
    list: {},
    search: {},
    searchInProgress: undefined,
    pagination: pagination(),
}

export function shipmentItem(
    state = {
        markers: {},
        important: false,
        importantPending: false,
        status: {
            currentStatus: {},
            statusUpdateHistory: [],
        },
    },
    action = {}
) {
    switch (action.type) {
        case STAR_SHIPMENT:
            return {
                ...state,
                shipment: {
                    ...state.shipment,
                    isFavorite: true,
                },
            }
        case UNSTAR_SHIPMENT:
            return {
                ...state,
                shipment: {
                    ...state.shipment,
                    isFavorite: false,
                },
            }
        case UNARCHIVE_SHIPMENT:
            return {
                ...state,
                shipment: {
                    ...state.shipment,
                    isArchived: false,
                },
            }
        case ARCHIVE_SHIPMENT:
            return {
                ...state,
                shipment: {
                    ...state.shipment,
                    isArchived: true,
                },
            }
        case ADD_SHIPMENT:
            return {
                ...state,
                ...action.result,
                importantPending: false,
            }
        case ACTIVE_SHIPMENT_DETAILS:
            return {
                ...state,
            }
        case SHIPMENT_EVENT_RECEIVED:
            return {
                ...state,
                status: action.status,
            }
        case ALERT_DISMISS:
            return {
                ...state,
                alerts: {
                    ...state.alerts,
                    events: get(state, "alerts.events", []).map(event => {
                        if (event._id === action.payload.alertId) {
                            return {
                                ...event,
                                dismissed: true,
                            }
                        }
                        return event
                    }),
                },
            }
        case ALERT_EVENT:
            if (get(action, "payload.dismissed")) return state
            return {
                ...state,
                alerts: {
                    ...state.alerts,
                    events: [
                        action.payload,
                        ...get(state, "alerts.events", []),
                    ],
                },
            }
        default:
            return state
    }
}

const shipmentList = (state, action, shipmentId = null, strict = true) => {
    const key = shipmentId || action.shipmentId
    if (strict && !state[key]) return state
    return { ...state, [key]: shipmentItem(state[key], action) }
}

export function pagination(
    state = {
        pageSize: 10,
        lastId: null,
        page: 1,
    },
    action = {}
) {
    switch (action.type) {
        case CHANGE_PAGE_SIZE:
            return {
                ...state,
                pageSize: action.pageSize,
            }
        case CHANGE_PAGE:
            return {
                ...state,
                page: action.page,
            }
        case SHIPMENT_LIST_RESULT: {
            const lastIndex = action.result.list.length - 1
            const lastElement = action.result.list.length
                ? action.result.list[lastIndex]
                : {}
            return {
                ...state,
                lastId: lastElement._id,
            }
        }
        case SHIPMENT_LIST_RESULT_FILTER_APPLIED: {
            const lastIndex = action.result.list.length - 1
            const lastElement = action.result.list.length
                ? action.result.list[lastIndex]
                : {}
            return {
                ...state,
                lastId: lastElement._id,
            }
        }
        default:
            return state
    }
}

const parseShipments = (state, action) =>
    action.result.list.reduce(
        (prev, item) =>
            shipmentList(
                prev,
                { type: ADD_SHIPMENT, result: item },
                get(item, "identifiers.internalTrackingNumber"),
                false
            ),
        { ...state.list }
    )

const parseShipmentsFilterApplied = action =>
    action.result.list.reduce(
        (prev, item) =>
            shipmentList(
                prev,
                { type: ADD_SHIPMENT, result: item },
                get(item, "identifiers.internalTrackingNumber"),
                false
            ),
        []
    )

export function shipment(state = defaultShipmentState, action = {}) {
    switch (action.type) {
        case STAR_SHIPMENT:
            return {
                ...state,
                list: shipmentList(state.list, action),
            }
        case UNSTAR_SHIPMENT:
            return {
                ...state,
                list: shipmentList(state.list, action),
            }
        case UNARCHIVE_SHIPMENT:
            return {
                ...state,
                list: shipmentList(state.list, action),
            }
        case ARCHIVE_SHIPMENT:
            return {
                ...state,
                list: shipmentList(state.list, action),
            }
        case ADD_SHIPMENT:
            return {
                ...state,
                flashMessage: false,
                list: shipmentList(
                    state.list,
                    action,
                    get(action, "result.identifiers.internalTrackingNumber"),
                    false
                ),
            }
        case ACTIVE_SHIPMENT_DETAILS:
            return {
                ...state,
                activeShipment: action.shipment,
            }
        case CHANGE_STARRED_ACTIVE_SHIPMENT:
            return {
                ...state,
                activeShipment: {
                    ...state.activeShipment,
                    shipment: {
                        ...state.activeShipment.shipment,
                        isFavorite: action.isFavorite,
                    },
                },
            }
        case DISMISS_WARNING_ACTIVE_SHIPMENT:
            return {
                ...state,
                activeShipment: {
                    ...state.activeShipment,
                    alerts: {
                        ...state.activeShipment.alerts,
                        events: (state.activeShipment.alerts.events ?? []).map(
                            event => {
                                if (event._id === action.payload.alertId) {
                                    return {
                                        ...event,
                                        dismissed: true,
                                    }
                                }
                                return event
                            }
                        ),
                    },
                },
            }

        case REMOVE_SHIPMENT:
            return {
                ...state,
                totalCount: state.totalCount - 1,
                list: Object.keys(state.list)
                    .filter(
                        shipmentId =>
                            shipmentId.toString() !==
                            action.shipmentId.toString()
                    )
                    .reduce((newObj, shipmentId) => {
                        newObj[shipmentId] = state.list[shipmentId]
                        return newObj
                    }, {}),
            }
        case BACKFILL_SHIPMENT_LIST:
            return {
                ...state,
                list: parseShipments(state, action),
            }
        case NEW_SHIPMENT_FLASH_MESSAGE:
            return { ...state, flashMessage: true }
        case DISMISS_SHIPMENT_FLASH_MESSAGE:
            return { ...state, flashMessage: false }
        case ALERT_DISMISS:
            return {
                ...state,
                list: shipmentList(
                    state.list,
                    action,
                    action.payload?.shipmentId
                ),
            }
        case ALERT_EVENT:
            return {
                ...state,
                list: shipmentList(
                    state.list,
                    action,
                    get(action, "payload.shipmentId")
                ),
            }
        case SHIPMENT_EVENT_RECEIVED:
            return {
                ...state,
                list: shipmentList(state.list, action, get(action, "channel")),
            }
        case SHIPMENT_LIST_LOAD:
            return { ...state, isFetching: true }
        case SHIPMENT_LIST_LOAD_ERROR:
            return { ...state, isFetching: false }
        case SHIPMENT_LIST_RESULT:
            return {
                ...state,
                list: parseShipments(state, action),
                totalCount: state.totalCount || action.result.totalCount,
                isFetching: false,
                isLoaded: true,
                pagination: pagination(state.pagination, action),
            }
        case SHIPMENT_LIST_RESULT_FILTER_APPLIED:
            return {
                ...state,
                list: parseShipmentsFilterApplied(action),
                totalCount: action.result.totalCount,
                isFetching: false,
                isLoaded: true,
                pagination: pagination(state.pagination, action),
            }
        case SHIPMENT_SEARCH_RESULT:
            return {
                ...state,
                searchInProgress: undefined,
                search: {
                    ...state.search,
                    [action.value]: action.data,
                },
            }
        case QUEUE_SHIPMENT_SEARCH:
            return { ...state, searchInProgress: action.id }
        case CHANGE_PAGE_SIZE:
            return {
                ...state,
                pagination: pagination(state.pagination, action),
            }
        case CHANGE_PAGE:
            return {
                ...state,
                pagination: pagination(state.pagination, action),
            }
        case USER_DISAUTHENTICATED:
            return shipment()
        default:
            return state
    }
}

import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { get } from "lodash"
import { Typography, withStyles, Paper, Grid } from "@material-ui/core"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import moment from "moment-business-days"
import { FormattedMessage } from "react-intl"
import { quoteSelect } from "../../../../actions/quote"
import { CarrierLogo } from "../../../util"
import RateBreakdown from "../../../dashboard/components/rateBreakdown"
import { trackGAEvent } from "../../../../actions/user"
import QuoteTile from "../QuoteTile"

const styles = theme => ({
    logoTile: {
        maxWidth: 80,
        "padding-right": "20px",
        marginRight: "40px",
        height: "auto !important",
    },
    carrier_logo: {
        height: "90px",
        "& $img": {
            height: "60px",
            padding: "10px !important",
            margin: "0px !important",
        },
    },
    card__container: {
        paddingRight: theme.spacing(2),
        paddingBottom: "20px",
        paddingLeft: "10px",
    },
    gridList: {
        // width: '350px',
    },
    gridListTile: {
        borderBottom: "1px solid lightgrey",
        paddingTop: "10px",
        cursor: "pointer",
    },
    cardContent: {
        paddingTop: "20px",
        paddingLeft: "10px",
    },
    iconButton: {
        height: "auto",
    },
    shareCheckbox: {
        height: "auto",
    },
    popover: {
        zIndex: 100,
    },
    breakdown__container: {
        minWidth: "325px",
    },
    card__header: {
        minHeight: "100px",
        paddingLeft: "12px",
    },
    left_border: {
        borderLeftWidth: "5px",
        borderLeftColor: "black",
    },
    empty_header: {
        minHeight: "24px",
    },
    fastest: {
        paddingRight: "5px",
    },
    expansion_panel: {
        paddingLeft: "0px",
    },
    blank_expansion_panel: {
        paddingLeft: "0px",
        height: "88px",
        opacity: 0,
    },
    summary_content: {
        margin: "0px",
    },
    third__party__label: {
        marginLeft: "3px",
        padding: "3px",
    },
    hrcNote: {
        marginLeft: "3px",
        padding: "3px",
    },
    alternate__quote__card: {
        borderBottom: "solid 1px #D2D2D2",
    },
})

export const QuoteCardHeader = ({
    deliveryDate,
    fastest,
    rates,
    carrier,
    rateIndex,
    previousDate,
    classes,
}) => (
    <Grid item container>
        {rateIndex === 0 ||
        (deliveryDate && carrier.groupDate !== previousDate) ? (
            <Grid item container>
                {fastest && (
                    <Typography
                        variant="subtitle1"
                        color={"secondary"}
                        className={classes.fastest}
                    >
                        <FormattedMessage
                            id="rateResults__fastest"
                            defaultMessage="Fastest"
                        />
                    </Typography>
                )}
                <Typography variant="subtitle1">
                    {deliveryDate.format("dddd MMM-Do")}
                </Typography>
            </Grid>
        ) : (
            <Grid item container className={classes.empty_header}>
                <Typography variant="subtitle1" />
            </Grid>
        )}
        {!deliveryDate && (
            <Grid item container>
                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="orderDetails.header__unknownDeliveryDate"
                        defaultMessage="Call carrier for delivery date"
                    />
                </Typography>
            </Grid>
        )}
    </Grid>
)

QuoteCardHeader.propTypes = {
    deliveryDate: PropTypes.object,
    fastest: PropTypes.bool.isRequired,
}

QuoteCardHeader.defaultProps = {
    deliveryDate: undefined,
}

let QuoteCardCarrier = ({ carrierCode, mode, serviceLevel, classes }) => (
    <Grid className={classes.card__header}>
        <CarrierLogo
            className={classes.carrier_logo}
            carrierCode={carrierCode}
            mode={mode}
            serviceLevel={serviceLevel}
        />
    </Grid>
)

QuoteCardCarrier.propTypes = {
    carrierCode: PropTypes.string,
    mode: PropTypes.string,
    serviceLevel: PropTypes.string,
    onPin: PropTypes.func,
}

QuoteCardCarrier.defaultProps = {
    carrierCode: null,
    serviceLevel: null,
    mode: "LTL",
    onPin: () => {},
}

QuoteCardCarrier = withStyles(styles)(QuoteCardCarrier)

class QuoteCardSelectorPresentation extends Component {
    state = {
        anchorEl: null,
        anchorIndex: null,
    }

    toggleDetails = (e, index) => {
        e.stopPropagation()
        this.setState({ anchorEl: e.currentTarget, anchorIndex: index })
    }

    handleClose = e => {
        e.stopPropagation()
        this.setState({
            anchorEl: null,
        })
    }

    addBlanks(quotes, maxRateLength) {
        const blanks = []
        for (
            let i = get(quotes, "rateQuotes.length", 0);
            i < maxRateLength;
            i++
        )
            blanks.push({ blank: true })
        return blanks
    }

    render() {
        const {
            carrier,
            cardIndex,
            classes,
            getBol,
            disabled,
            previousDate,
            isThirdParty,
            maxRateLength,
            shippedRateQuote,
            canViewRateAmounts,
        } = this.props
        // const { anchorEl, anchorIndex } = this.state;
        const blankTiles = this.addBlanks(carrier, maxRateLength)
        const deliveryDate =
            !isNaN(get(carrier, "rateQuotes[0].transitDays")) &&
            moment(get(carrier, "rateQuotes[0].deliveryDateTime"))
        const summaryClasses = {
            content: classes.summary_content,
        }

        if (carrier.fedexDomesticOffshore) return <div />
        return (
            <Grid
                item
                container
                direction="column"
                className={classes.card__container}
            >
                <QuoteCardHeader
                    deliveryDate={deliveryDate}
                    fastest={carrier.fastest}
                    carrier={carrier}
                    rateIndex={cardIndex - 1}
                    previousDate={previousDate}
                    classes={classes}
                    isThirdParty={isThirdParty}
                />
                <Grid
                    container
                    direction={"column"}
                    className={classes.left_border}
                >
                    <Paper>
                        <Grid item container className={classes.gridListTile}>
                            <Grid item container xs={8}>
                                <QuoteCardCarrier
                                    carrierCode={carrier.carrierCode}
                                    mode={carrier.rateQuotes[0].mode}
                                    serviceLevel={
                                        carrier.rateQuotes[0].serviceLevel
                                            .description
                                    }
                                />
                            </Grid>
                            <Grid item container alignItems="flex-end" xs={4}>
                                {isThirdParty && (
                                    <Typography
                                        variant="caption"
                                        className={classes.third__party__label}
                                    >
                                        <FormattedMessage
                                            id="rateResults__thirdPartyPricing"
                                            defaultMessage="3rd Party Pricing"
                                        />
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                    {carrier.rateQuotes.map((rate, index) =>
                        // const guaranteed = isGuaranteedQuote(rate.serviceLevel.code, guaranteedList);
                        // const quotesMatchingCarrier = share.selections.filter(q => q.name === carrier.carrierCode);
                        // const matchingRate = quotesMatchingCarrier.findIndex(q => (q.alternateRateQuote ===
                        //    rate.index && rate.mode === carrier.rateQuotes[0].mode && q.direction === rate.direction));
                        // const selected = matchingRate !== -1;
                        canViewRateAmounts ? (
                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    className={classes.expansion_panel}
                                    classes={summaryClasses}
                                >
                                    <QuoteTile
                                        getBol={getBol}
                                        gridSize={12}
                                        disabled={disabled}
                                        key={index}
                                        quote={rate}
                                        carrierCode={carrier.carrierCode}
                                        mode={carrier.rateQuotes[0].mode}
                                        cardIndex={cardIndex}
                                        rateIndex={index + 1}
                                        isCarousel
                                        shippedRateQuote={shippedRateQuote}
                                        canViewRateAmounts={canViewRateAmounts}
                                    />
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <RateBreakdown
                                        listView={false}
                                        charges={get(
                                            rate,
                                            "rateQuoteDetail.charges"
                                        )}
                                        totalInPreferredCurrency={
                                            get(
                                                rate,
                                                "rateQuoteDetail.totalInPreferredCurrency"
                                            )
                                                ? get(
                                                      rate,
                                                      "rateQuoteDetail.totalInPreferredCurrency"
                                                  )
                                                : get(
                                                      rate,
                                                      "rateQuoteDetail.total"
                                                  )
                                        }
                                        preferredCurrencyCode={get(
                                            rate,
                                            "preferredCurrencyCode"
                                        )}
                                        gridSize={12}
                                        paddingRight={"3%"}
                                        quoteID={
                                            rate.capacityProviderQuoteNumber
                                        }
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        ) : (
                            <Grid
                                container
                                className={classes.alternate__quote__card}
                            >
                                <QuoteTile
                                    getBol={getBol}
                                    gridSize={12}
                                    disabled={disabled}
                                    key={index}
                                    quote={rate}
                                    carrierCode={carrier.carrierCode}
                                    mode={carrier.rateQuotes[0].mode}
                                    cardIndex={cardIndex}
                                    rateIndex={index + 1}
                                    isCarousel
                                    shippedRateQuote={shippedRateQuote}
                                    canViewRateAmounts={canViewRateAmounts}
                                />
                            </Grid>
                        )
                    )}
                    {blankTiles.map(item => (
                        <Grid className={classes.blank_expansion_panel} />
                    ))}
                </Grid>
            </Grid>
        )
    }
}

QuoteCardSelectorPresentation.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    isViewMode: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    onPin: PropTypes.func.isRequired,
    share: PropTypes.object.isRequired,
    isExpired: PropTypes.bool.isRequired,
    shippedRateQuote: PropTypes.object.isRequired,
    cpg: PropTypes.string.isRequired,
}

QuoteCardSelectorPresentation.defaultProps = {
    quotes: null,
    isViewMode: false,
}

const selectorMapStateToProps = state => ({
    shipmentId: state.identifiers.internalTrackingNumber,
    isExpired: state.quotes.active.isExpired,
    isViewMode: state.quotes.active.shipped,
    shippedRateQuote: state.quotes.active.shippedRateQuote,
    share: state.share,
    cpg: state.search.cpg,
    guaranteedList: state.accessorials.list.guaranteed,
})

const selectorMapDispatchToProps = (dispatch, props) => ({
    onClick: (shipmentId, carrier, quoteId, mode, metadata) => {
        dispatch(quoteSelect(shipmentId, carrier, quoteId, mode, metadata))
        props.getBol()
    },
    onInfoClick: cpg =>
        dispatch(trackGAEvent("Quote", "Rate details popup", cpg)),
})

export const QuoteCardSelector = connect(
    selectorMapStateToProps,
    selectorMapDispatchToProps
)(withStyles(styles)(QuoteCardSelectorPresentation))

import { Box, Typography } from "@material-ui/core"
import React from "react"
import { styles } from "./styles"

const TextDataDisplay = ({ title, textFields }) => {
    return (
        <Box sx={{ width: "20%", padding: "2px" }}>
            <Typography variant="subtitle2" style={styles.conactLabel}>
                {title}
            </Typography>
            {textFields.map(field => {
                return (
                    <Typography variant="body2" key={field.key}>
                        {field.value}
                    </Typography>
                )
            })}
        </Box>
    )
}

export default TextDataDisplay

import React, { useState } from "react"
import { Box, Chip, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import { FormattedMessage } from "react-intl"
import { getFastestFedexQuote } from "../../../../actions/fastestQuote"
import { useSnackbarContext } from "../../../../context/providers/snackbarProvider"
import moment from "moment"

const useStyles = makeStyles({
    chip: {
        cursor: "pointer",
        "&:hover": {
            cursor: "pointer",
        },
    },
})

const FastestPriorityChip = ({ rate, cpg }) => {
    const classes = useStyles()
    const { openSnackbar } = useSnackbarContext()
    const [isShared, setIsShared] = useState(false)

    const getFastestQuote = async payload => {
        setIsShared(true)
        try {
            await getFastestFedexQuote(payload)
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="rateResults__fastest__quote_message__error"
                    defaultMessage="Unable to share quote details"
                />
            )
            setIsShared(false)
        }
    }

    return (
        <Tooltip
            fontSize="small"
            title={
                isShared ? (
                    <FormattedMessage
                        id="rateResults__fastest__quote_message__clicked"
                        defaultMessage="You will get your quote soon"
                    />
                ) : (
                    <FormattedMessage
                        id="rateResults__fastest__quote_message"
                        defaultMessage="Receive an updated quote in 3 hours"
                    />
                )
            }
        >
            <Box
                onClick={() => {
                    if (!isShared) {
                        getFastestQuote({
                            quoteId: rate?.capacityProviderQuoteNumber,
                            cpg,
                            deliveryDate: moment
                                .utc(rate?.deliveryDateTime)
                                .format("YYYY-MM-DD"),
                        })
                    }
                }}
            >
                <Chip
                    icon={<AddCircleIcon />}
                    label={
                        <FormattedMessage
                            id="rateResults__fastest__quote"
                            defaultMessage="Need it faster? Click here"
                        />
                    }
                    color={isShared ? "defaul" : "primary"}
                    className={classes.chip}
                />
            </Box>
        </Tooltip>
    )
}

export default FastestPriorityChip

import React from "react"
import { get } from "lodash"
import { FormattedMessage, injectIntl } from "react-intl"

const FormattedError = ({ error, intl }) => {
    if (!get(error, "id")) return error
    const fieldIsIntl = get(error, "values.field.id")
    if (!fieldIsIntl) return <FormattedMessage {...error} />
    return (
        <FormattedMessage
            {...error}
            values={{
                ...error.values,
                field: <FormattedMessage {...error.values.field} />,
            }}
        />
    )
}

export default injectIntl(FormattedError)

import React, { forwardRef, useContext } from "react"
import { Button, Grid, Typography, withStyles } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { OrdersContext } from "../../context/providers/OrdersProvider"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const styles = theme => ({
    paper: {
        position: "absolute",
        left: 0,
        right: 0,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        top: 0,
        bottom: 0,
        width: "30%",
        maxWidth: "375px",
        minWidth: "350px",
        maxHeight: "175px",
        margin: "auto",
    },
    section: {
        paddingTop: "10px",
    },
    buttonContainer: {
        flexGrow: 1,
    },
    mainContainer: {
        height: "100%",
    },
    uploadButton: {
        paddingLeft: "10px",
    },
})

const DeleteOrderModal = forwardRef((props, ref) => {
    const { setDeleteModalOpen, classes } = props
    const {
        ordersToDelete,
        setOrdersToDelete,
        deleteBulkOrders,
        fetchOrdersSummaryData,
    } = useContext(OrdersContext)

    const { logGAEvent } = useGAContext()

    const handleCancelDelete = () => {
        logGAEvent("Orders", "Cancel order delete")
        setDeleteModalOpen(false)
        setOrdersToDelete(null)
    }

    const handleDeleteOrder = async () => {
        setDeleteModalOpen(false)
        setOrdersToDelete(null)
        await deleteBulkOrders(ordersToDelete)
        await fetchOrdersSummaryData({})
    }

    return (
        <Grid
            item
            container
            className={classes.paper}
            alignContent="flex-start"
        >
            <Grid
                item
                container
                direction="column"
                alignContent="flex-start"
                className={classes.mainContainer}
            >
                <Grid item container>
                    <Typography variant="h6">
                        {ordersToDelete.length > 1 ? (
                            <FormattedMessage
                                id="orders.deleteBulk.title"
                                defaultMessage="Deleting Orders"
                            />
                        ) : (
                            <FormattedMessage
                                id="orders.delete.title"
                                defaultMessage="Deleting Order"
                            />
                        )}
                    </Typography>
                    <Typography variant="body1">
                        {ordersToDelete.length > 1 ? (
                            <FormattedMessage
                                id="orders.deleteBulk.message"
                                defaultMessage="Are you sure you want to delete the {number} selected orders?"
                                values={{
                                    number: ordersToDelete.length,
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                id="orders.delete.message"
                                defaultMessage="Are you sure you want to delete order: {orderId}?"
                                values={{
                                    orderId: ordersToDelete
                                        .map(order => order.orderNumber)
                                        .join(", "),
                                }}
                            />
                        )}
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    alignItems="flex-end"
                    className={classes.buttonContainer}
                    justifyContent="space-between"
                >
                    <Button
                        variant="outlined"
                        color="secondary"
                        htmlFor="contained-button-file"
                        component="label"
                        onClick={() => handleCancelDelete()}
                    >
                        <FormattedMessage
                            id="orders.delete.cancel"
                            defaultMessage="Cancel"
                        />
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDeleteOrder}
                    >
                        <FormattedMessage
                            id="orders.delete.delete"
                            defaultMessage="Delete"
                        />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
})

export default withStyles(styles)(DeleteOrderModal)
